/**
 * Removes metadata keys from the object that aren't references to other resource ids,
 * mutates the object.
 */
function removeDummyKeys(obj) {
    delete obj["id"];
    delete obj["_changeId"];
    delete obj["modifiedAt"];
    delete obj["createdAt"];

    return obj;
}

module.exports = removeDummyKeys;
