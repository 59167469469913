async function processDownloadResp(fetchResp: Response, name: string): Promise<File> {
    const data = await fetchResp.blob();
    return new File([data], name, {
        type: data.type
    });
}

export async function downloadFileFromGDrive({ id, name, accessToken }): Promise<File> {
    const fetchResp = await fetch(`https://www.googleapis.com/drive/v3/files/${id}?alt=media`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return await processDownloadResp(fetchResp, name);
}

export async function downloadFileFromBox({ id, name, accessToken }): Promise<File> {
    const fetchResp = await fetch(`https://api.box.com/2.0/files/${id}/content`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return processDownloadResp(fetchResp, name);
}

export async function downloadFileFromDropbox({ url, name }): Promise<File> {
    const fetchResp = await fetch(url);
    return processDownloadResp(fetchResp, name);
}

export async function downloadFileFromBynder({ url, name }): Promise<File> {
    const fetchResp = await fetch(url);
    return processDownloadResp(fetchResp, name);
}

export async function downloadFileFromOneDrive({ url, name }): Promise<File> {
    const fetchResp = await fetch(url);
    return processDownloadResp(fetchResp, name);
}
