import Api from "../api";
import { TextStyleType } from "../../../common/constants";
import {
    AiGenerativeRequestType,
    GeneratePresentationOutlineRequest,
    GeneratePresentationOutlineResponse,
    GenerateSlideRequest,
    GenerateSlideResponse,
    GenerateWordCloudRequest,
    GenerateWordCloudResponse,
    RewriteTextRequest,
    RewriteTextResponse,
    GenerateNextCollectionItemRequest,
    GenerateNextCollectionItemResponse,
    GenerateImagesResponse,
    ParsedWebPageTheme,
    GenerateSlideNotesRequest,
    GenerateSlideNotesResponse
} from "../../../common/aiConstants";
import { buildElementForCollection } from "./slideModelBuilder";
import { uploadBlobToSignedUrl } from "../utilities/uploadBlobToSignedUrl";
import ApiError from "../../../common/ApiError";

export class InsufficientCreditsError extends Error { }
export class TooManyRequestsError extends Error { }

export interface ApiWrapper {
    post: (data: any) => Promise<any>;
}

export interface GeneratePresentationOutlineRequestWithFiles extends GeneratePresentationOutlineRequest {
    files?: File[];
}

export interface GenerateSlideRequestWithFiles extends GenerateSlideRequest {
    files?: File[];
}

export interface CollectionItemModel {
    text: {
        blocks: Record<string, any>[]
    },
    content_type?: string,
    content_value?: string
}

type RequestWithType<T extends {}> = T & {
    type: AiGenerativeRequestType
}

class AiGenerationService {
    get api(): ApiWrapper {
        return Api.gpt;
    }

    private async _invokeApi(api: ApiWrapper, payload: any) {
        try {
            return await api.post(payload);
        } catch (err) {
            if (err instanceof ApiError) {
                if (err.status === 402) {
                    throw new InsufficientCreditsError(err.message);
                }
                if (err.status === 429) {
                    throw new TooManyRequestsError(err.message);
                }
            }

            throw err;
        }
    }

    private async _uploadFilesToTempAssetStorage(files: File[]): Promise<string[]> {
        return await Promise.all(files.map(async file => {
            const { writeUrl, fileName: rawFileName } = await Api.tempAssets.post({ extension: file.name.split(".").pop() });
            await uploadBlobToSignedUrl(file, writeUrl);
            return rawFileName;
        }));
    }

    public async getCreditsBalance(workspaceId) {
        const { balance } = await Api.aiCredits.get({ workspaceId });
        return balance;
    }

    public async generatePresentationOutline(
        request: GeneratePresentationOutlineRequestWithFiles,
        api: ApiWrapper = this.api
    ) {
        request.files = request.files ?? [];
        request.tempAssetFileNames = request.tempAssetFileNames ?? [];

        if (!request.indexFileName && request.files.length > 0) {
            const fileNames = await this._uploadFilesToTempAssetStorage(request.files);
            request.tempAssetFileNames.push(...fileNames);
        }
        delete request.files;

        const payload: RequestWithType<GeneratePresentationOutlineRequest> = {
            type: AiGenerativeRequestType.GENERATE_PRESENTATION_OUTLINE,
            ...request
        };
        const { response } = await this._invokeApi(api, payload);

        return response as GeneratePresentationOutlineResponse;
    }

    public async generateSlide(
        request: GenerateSlideRequestWithFiles,
        api: ApiWrapper = this.api
    ) {
        request.files = request.files ?? [];
        request.tempAssetFileNames = request.tempAssetFileNames ?? [];

        if (!request.indexFileName && request.files.length > 0) {
            const fileNames = await this._uploadFilesToTempAssetStorage(request.files);
            request.tempAssetFileNames.push(...fileNames);
        }
        delete request.files;

        const payload: RequestWithType<GenerateSlideRequest> = {
            type: AiGenerativeRequestType.GENERATE_SLIDE,
            ...request
        };
        const { response } = await this._invokeApi(api, payload);

        return { slideTitle: request.slideTitle ?? response.slideTitle, ...response } as GenerateSlideResponse;
    }

    public async generateWordCloud(
        request: Omit<GenerateWordCloudRequest, "traceData">,
        api: ApiWrapper = this.api
    ) {
        const payload: RequestWithType<GenerateWordCloudRequest> = {
            type: AiGenerativeRequestType.GENERATE_WORDCLOUD,
            ...request
        };
        const { response } = await this._invokeApi(api, payload);

        return response as GenerateWordCloudResponse;
    }

    public async rewriteText(
        request: Omit<RewriteTextRequest, "traceData">,
        api: ApiWrapper = this.api
    ) {
        const payload: RequestWithType<RewriteTextRequest> = {
            type: AiGenerativeRequestType.REWRITE_TEXT,
            ...request
        };
        const { response } = await this._invokeApi(api, payload);

        return response as RewriteTextResponse;
    }

    public async generateSlideNotes(
        request: GenerateSlideNotesRequest,
        api: ApiWrapper = this.api
    ) {
        const payload: RequestWithType<GenerateSlideNotesRequest> = {
            type: AiGenerativeRequestType.GENERATE_SLIDE_NOTES,
            ...request
        };
        const { response } = await this._invokeApi(api, payload);

        return response as GenerateSlideNotesResponse;
    }

    public async generateNextCollectionItem(
        request: { element: any },
        api: ApiWrapper = this.api
    ) {
        const { element } = request;

        const headerBlock = element.canvas.model.elements.header?.text.blocks.find(b => b.textStyle == TextStyleType.HEADING);

        const needsImage = element.itemCollection[0].hasOwnProperty("content_value");
        const existingItems = element.itemCollection.map(item => item.text.blocks.find(b => b.textStyle == TextStyleType.TITLE)?.html || "");

        const payload: RequestWithType<GenerateNextCollectionItemRequest> = {
            type: AiGenerativeRequestType.GENERATE_ITEM,
            slideTitle: headerBlock ? headerBlock.html : "",
            existingItems,
            needsImage
        };
        const { response } = await this._invokeApi(api, payload);

        const itemModel: CollectionItemModel = await buildElementForCollection(response as GenerateNextCollectionItemResponse);
        return itemModel;
    }

    public async analyzeWebpage(url: string, api: ApiWrapper = this.api) {
        const payload: RequestWithType<{ url: string }> = {
            type: AiGenerativeRequestType.ANALYZE_WEBPAGE,
            url
        };
        const { response } = await this._invokeApi(api, payload);

        return response as ParsedWebPageTheme;
    }

    public async generateImages(prompt: string, imagesCount: number, api: ApiWrapper = this.api) {
        const payload: RequestWithType<{ prompt: string, imagesCount: number }> = {
            type: AiGenerativeRequestType.GENERATE_IMAGES,
            prompt,
            imagesCount
        };
        const { response, remainingCreditsBalance } = await this._invokeApi(api, payload);

        return { response, remainingCreditsBalance } as { response: GenerateImagesResponse, remainingCreditsBalance: number };
    }
}

const aiGenerationService = new AiGenerationService();
export default aiGenerationService;
