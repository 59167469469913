import getLogger from "js/core/logger";

const logger = getLogger();

// IMPORTANT: if you're making changes to any of the asynchronous imports below, make sure you test
// them locally with the string-replace-loader loader disabled in webpack.dev.config.js

const bundleGetters = {
    9: () => import(/* webpackChunkName: "legacy-canvasV9" */ "./v9"),
    10: () => import(/* webpackChunkName: "legacy-canvasV10" */ "./v10")
};

const availableBundleVersions = Object.keys(bundleGetters).map(version => parseInt(version)).sort((a, b) => a - b);

/**
 * Fetches canvas bundle for the given version
 * @param {number} version numeric slide version
 */
export function getCanvasBundle(version) {
    if (!availableBundleVersions.includes(version)) {
        let closestVersion = availableBundleVersions.find(availableVersion => availableVersion >= version);
        if (!closestVersion) {
            throw new Error("Requested version is too high!");
        }

        logger.warn(`getCanvasBundle() cannot find bundle version ${version}, will use closest version ${closestVersion}`, { version, closestVersion });
        return bundleGetters[closestVersion]();
    }

    return bundleGetters[version]();
}
