import React, { Component } from "reactn";
import StackGrid from "react-stack-grid";
import sizeMe from "react-sizeme";

import { NoMatchNotice } from "js/react/components/Notice";

class ImageThumbnailGrid extends Component {
    constructor(props) {
        super(props);

        this.gridRef = React.createRef();
    }

    updateLayout() {
        if (this.gridRef?.current) {
            this.gridRef.current.updateLayout();
        }
    }

    render() {
        const { size, columns, children } = this.props;

        let colCount = columns ?? 3;
        if (size.width > 1100) {
            colCount++;
        }
        const colWidth = (size.width - colCount * 20) / colCount;

        if (children.length > 0) {
            return (
                <StackGrid
                    ref={this.gridRef}
                    columnWidth={colWidth}
                    gutterWidth={20}
                    gutterHeight={20}
                    duration={0}
                >
                    {React.Children.map(children, child =>
                        React.cloneElement(child, { width: colWidth, updateLayout: () => this.updateLayout() })
                    )}
                </StackGrid>
            );
        } else {
            return (
                <NoMatchNotice />
            );
        }
    }
}

export default sizeMe({ monitorWidth: true })(ImageThumbnailGrid);
