import db from "js/db";
import React from "reactn";
import { buildNumber, isDevelopment, isStaging } from "js/config";
import { ShowDialog, ShowSnackBar } from "js/react/components/Dialogs/BaseDialog";
import RefreshTimerDialog from "js/react/components/Dialogs/RefreshTimerDialog";
import MandatoryUpdateDialog from "js/react/components/Dialogs/MandatoryUpdateDialog";
import getLocalStorage from "js/core/utilities/localStorage";

const localStorage = getLocalStorage();

let isInitialized = false;

// Reload browser when new version is pushed.
export function initializeForceReloadDialog() {
    if (isInitialized) {
        return;
    }
    isInitialized = true;

    let reloadTimeoutId = null;

    db("force-reload").on("value", function(snap) {
        const val = snap.val();
        const snackBarContent = (
            <p>
                <span>A new version of Beautiful.ai is available </span>
                <span className="action_item" onClick={() => window.location.reload()}>REFRESH</span>
            </p>
        );
        const mandatoryUpdateMessage = (
            "This browser tab is running an old version of the application and must\n" +
            "                be refreshed. Your work will be saved."
        );
        //do nothing if there is no value or the build numbers are the same,
        // or is local development.
        if (val == null || buildNumber >= val.buildNumber || (isDevelopment && !isStaging)) {
            clearTimeout(reloadTimeoutId);
            return;
        }

        //Check if we've already tried to force reload recently.
        //This will prevent an infinite refresh loop due to caching or any other weirdness
        //(eg: a build number that doesn't actually exist).
        const prevForceReloadTime = localStorage.getItem("lastForceReloadTime");
        const forceReloadTime = (new Date()).getTime();
        const cooldown = 1000 * 60 * 5; // 5 minutes
        if (prevForceReloadTime && forceReloadTime - parseInt(prevForceReloadTime) < cooldown) {
            reloadTimeoutId = setTimeout(() => {
                if (val.forceUpdate) {
                    ShowDialog(RefreshTimerDialog);
                } else if (val.mandatoryUpdate) {
                    ShowDialog(MandatoryUpdateDialog, { message: mandatoryUpdateMessage });
                } else if (!val.maintenance) {
                    ShowSnackBar({
                        message: snackBarContent,
                        autoHideDuration: null,
                    });
                }
            }, 1000 * 60 * 6); //try again in 6 minutes.
        } else {
            if (val.forceUpdate) {
                localStorage.setItem("lastForceReloadTime", forceReloadTime);
                ShowDialog(RefreshTimerDialog);
            } else if (val.mandatoryUpdate) {
                ShowDialog(MandatoryUpdateDialog, { message: mandatoryUpdateMessage });
            } else if (!val.maintenance) {
                ShowSnackBar({
                    message: snackBarContent,
                    autoHideDuration: null,
                });
            }
        }
    });
}
