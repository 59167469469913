import { MONTH_ABBREVIATIONS } from "common/constants";
import { _ } from "js/vendor";
import moment from "moment";

function date(val) {
    if (!(this instanceof date)) {
        return new date(val);
    }
    this.val = this.parse(val);
}

date.prototype = {
    parse: function(val) {
        if (_.isNumber(val)) {
            return new Date(val);
        } else {
            return Date.parse(val);
        }
    },

    toMoment: function() {
        return moment(this.val).utc();
    },

    format: function(format) {
        return this.toMoment().format(format);
    },

    isValid: function() {
        return this.toMoment().isValid();
    },

    valueOf: function() {
        return this.toMoment().valueOf();
    }
};

/*
    The function normalizeMonthAbbreviation is designed to replace abbreviated month names
    in a given date string with their full month names.
    This can be useful for standardizing date formats, especially when dealing
    with user input that might include various abbreviations for months.
*/
export const normalizeMonthAbbreviation = dateString => {
    for (const [fullMonth, abbreviations] of Object.entries(MONTH_ABBREVIATIONS)) {
        for (const abbreviation of abbreviations) {
            const regex = new RegExp(`\\b${abbreviation}\\b`, "gi");
            dateString = dateString.replace(regex, fullMonth);
        }
    }
    return dateString;
};

export default date;
