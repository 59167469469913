import React, { Component } from "reactn";
import { _ } from "js/vendor";
import { incUserProps } from "js/analytics";
import { app } from "js/namespaces";
import { trackActivity } from "js/core/utilities/utilities";
import getLogger, { LogGroup } from "js/core/logger";
import { getStaticUrl } from "js/config";
import {
    Section,
    UIPane,
    UIPaneContents,
    UIPaneHeader,
    BlueButton
} from "js/react/components/UiComponents";
import { BlueSwitch } from "js/react/components/Switch";
import { Gap10, Gap30 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import { ShowDialogAsync, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import TextArea from "js/react/components/TextArea";
import { createInviteSuggestionContext } from "js/react/components/InviteInput/InviteContext";
import { ConfirmInviteDialog } from "js/react/components/InviteInput/ConfirmInviteDialog";
import { InviteTagsInput } from "js/react/components/InviteInput/InviteTagsInput";
import { normalizePermissionForDisplay } from "js/core/utilities/permissions";
import { FeatureType } from "common/features";
import AppController from "js/core/AppController";
import PresentationLibraryController from "js/controllers/PresentationLibraryController";
import PermissionsList from "./PermissionsList";
import permissionsDS from "../dataservices/PermissionsDataService";

const logger = getLogger(LogGroup.COLLABORATION);

export default class CollaboratePane extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inviteContext: null,
            customMessage: "",
            isNotificationsSettingsFetching: true,
            notificationsSettings: {
                notifyOnEdit: props.presentation.get("userId") === app.user.id,
                notifyOnAssignedSlideChange: true,
                notifyOnComment: true,
                notifyOnCommentMentioningMe: true,
            },
        };
    }

    componentDidMount() {
        const { presentation } = this.props;
        const presentationNotificationsSettings = app.user.get("presentationNotificationsSettings");

        let { notificationsSettings } = this.state;
        if (presentationNotificationsSettings && presentationNotificationsSettings[presentation.id]) {
            notificationsSettings = { ...notificationsSettings, ...presentationNotificationsSettings[presentation.id] };
        }

        // Retrieve workspace prohibited flags
        let prohibitExternalWorkspaceCollaboration = app.user.features.isFeatureEnabled(
            FeatureType.PROHIBIT_EXTERNAL_WORKSPACE_COLLABORATION,
            AppController.orgId
        );

        let inviteContext = createInviteSuggestionContext({
            includeFolders: true,
            presentationId: presentation.id,
            prohibitExternalWorkspaceCollaboration,
            saveContext: inviteContext => this.setState({ inviteContext }),
        });

        this.setState({
            inviteContext,
            notificationsSettings,
            isNotificationsSettingsFetching: false
        });
    }

    componentWillUnmount() {
        this.state.inviteContext.destroy();
    }

    async handleNotificationsSettingsChange(changedKey, changedValue) {
        const { presentation } = this.props;
        const { notificationsSettings } = this.state;

        const presentationId = presentation.id;
        const oldNotificationsSettings = notificationsSettings;

        const updatedNotificationsSettings = { ...notificationsSettings, [changedKey]: changedValue };

        try {
            app.user.update({ presentationNotificationsSettings: { [presentationId]: updatedNotificationsSettings } });
            await app.user.updatePromise;
            this.setState({ notificationsSettings: updatedNotificationsSettings });
            const props = {
                current_slide_count: PresentationLibraryController.getSlideCount(presentation.getWorkspaceId()),
                setting_changed: changedKey,
                old_value: oldNotificationsSettings[changedKey],
                new_value: changedValue,
                scope: "presentation",
                presentation_id: presentationId,
                workspace_id: presentation.getWorkspaceId()
            };
            trackActivity("Notification", "SettingChanged", null, null, props, { audit: true });
        } catch (err) {
            logger.error(err, "[CollaboratePane] handleNotificationsSettingsChange() error updating user's notifications settings");
            this.setState({ notificationsSettings: oldNotificationsSettings });
        }
    }

    handleAccept = async () => {
        const { presentation } = this.props;
        const {
            inviteContext,
            customMessage,
        } = this.state;

        const presentationId = presentation.id;

        try {
            const users = inviteContext.getSelectedUniqueUsers();
            const selection = inviteContext.getSelectionWithRedundanciesRemoved();

            await ShowDialogAsync(ConfirmInviteDialog, {
                hasPermissionEditing: true,
                users,
                callback: async permission => {
                    const emails = [];
                    const folders = [];
                    const asyncInvites = [];

                    const displayPerm = normalizePermissionForDisplay(permission);

                    selection.forEach(item => {
                        switch (item.type) {
                            case "folder":
                                // Skip folders that wouldn't be changing permissions
                                if (item.permissionType !== displayPerm) {
                                    folders.push({
                                        id: item.id,
                                        name: item.name,
                                    });
                                    asyncInvites.push(permissionsDS.inviteTeam(item.id, permission, presentationId, false));
                                }
                                break;
                            case "user":
                            default:
                                emails.push(item.email);
                                break;
                        }
                    });

                    // If we have individual user emails, give them permissions separate from the teams
                    if (emails.length) {
                        const payload = { emails, permission, customMessage };
                        asyncInvites.push(permissionsDS.createPermission(payload, presentationId, true));
                    }

                    await Promise.all(asyncInvites);
                    await permissionsDS.fetchPermissions(this.props.presentation.id);

                    incUserProps({
                        invites: emails.length
                    });
                    const props = {
                        presentation_id: presentationId,
                        old_value: null,
                        new_value: permission,
                        team_folders: folders,
                        recipient_emails: emails,
                        sender_email: app.user.getEmail(),
                        location: "settings",
                    };
                    trackActivity("Collab", "Invite", null, null, props, { audit: true });

                    let message = emails[0];
                    if (emails.length > 1) {
                        message += ` and ${emails.length - 1} other${emails.length > 2 ? "s" : ""}`;
                    }
                    this.setGlobal({ alertMessage: `Email sent to ${message}` });

                    await inviteContext.init();
                },
            });
        } catch (err) {
            logger.error(err, "CollaboratePane handleAccept() failed", { presentationId });
            ShowErrorDialog({
                error: "Oops!",
                message: err.message
            });
        }
    }

    handleChangeCustomMessage = customMessage => {
        this.setState({
            customMessage
        });
    }

    render() {
        const {
            inviteContext,
            customMessage,
            isNotificationsSettingsFetching,
            notificationsSettings: {
                notifyOnEdit,
                notifyOnAssignedSlideChange,
                notifyOnComment,
                notifyOnCommentMentioningMe,
            },
        } = this.state;
        const { presentation } = this.props;
        const isOrgWorkspace = presentation.getWorkspaceId() !== "personal";

        return (
            <UIPane className="collaborate-pane">
                <UIPaneHeader>Collaborate{isOrgWorkspace ? " with your team" : ""}</UIPaneHeader>
                <UIPaneContents>
                    {
                        inviteContext &&
                        <Section title="Invite Collaborators">
                            <InviteTagsInput
                                inviteContext={inviteContext}
                                sourceName="CollaboratePane"
                            />

                            <Gap10 />
                            <TextArea
                                isVisible
                                text={customMessage}
                                onChange={customMessage => this.handleChangeCustomMessage(customMessage)}
                                placeholder="Add a note..."
                            />

                            <Gap10 />
                            <div style={{ textAlign: "right" }}>
                                {!!inviteContext.selection.length && (
                                    <BlueButton
                                        disabled={!inviteContext.selection.length}
                                        onClick={this.handleAccept}
                                    >
                                        Send Invite
                                    </BlueButton>
                                )}
                            </div>
                        </Section>
                    }

                    <Section title="Who has access">
                        <PermissionsList
                            presentation={presentation}
                            orgId={presentation.get("orgId")}
                            onDelete={() => inviteContext?.init()}
                            disallowSelfRemoval
                        />
                    </Section>

                    <Section title="Notify me when:">
                        <BlueSwitch
                            checked={notifyOnEdit}
                            onChange={() => this.handleNotificationsSettingsChange("notifyOnEdit", !notifyOnEdit)}
                            disabled={isNotificationsSettingsFetching}
                            label="Presentation is changed"
                        />
                        <BlueSwitch
                            checked={notifyOnAssignedSlideChange}
                            onChange={() => this.handleNotificationsSettingsChange("notifyOnAssignedSlideChange", !notifyOnAssignedSlideChange)}
                            disabled={isNotificationsSettingsFetching}
                            label="Slides I own are changed"
                        />
                        <BlueSwitch
                            checked={notifyOnComment}
                            onChange={() => this.handleNotificationsSettingsChange("notifyOnComment", !notifyOnComment)}
                            disabled={isNotificationsSettingsFetching}
                            label="Comments are added"
                        />
                        <BlueSwitch
                            checked={notifyOnCommentMentioningMe}
                            onChange={() => this.handleNotificationsSettingsChange("notifyOnCommentMentioningMe", !notifyOnCommentMentioningMe)}
                            disabled={isNotificationsSettingsFetching}
                            label="Comments that involve me are added"
                        />

                        <Gap30 />
                        <FlexBox left middle gap="15px">
                            <img src={getStaticUrl("/images/slack.svg")} style={{ width: 24, height: 24 }} />
                            <span>
                                Get notifications right away. <a href="https://www.beautiful.ai/integrations/slack" target="_blank" rel="noopener noreferrer">Add Beautiful.ai to Slack</a>
                            </span>
                        </FlexBox>
                    </Section>
                </UIPaneContents>
            </UIPane>
        );
    }
}
