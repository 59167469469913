import React, { Component } from "react";
import styled from "styled-components";
import {
    DialogTitle,
    DialogActions,
    Button,
    TextField
} from "@material-ui/core";

import { BeautifulDialog, DialogContent, ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap20 } from "js/react/components/Gap";
import DropDown from "js/react/components/DropDown";
import { FlexBox } from "js/react/components/LayoutGrid";
import AddTagsDialog from "js/react/views/UserOptions/dialogs/AddTagsDialog";
import { Field } from "js/react/components/UiComponents";
import TagsList from "js/react/components/TagsList";

const SubText = styled.span`
    font-weight: 500;
    font-size: 16px;
`;

const ContentItem = styled.label`
    display: block;
    font-weight: 600;
    font-size: 18px;
`;

export default class CreateTeamAssetDialog extends Component {
    constructor(props) {
        super(props);

        const { type = "image", name = "", tags = [] } = props;

        this.state = { type, name, tags };
    }

    handleSelectType = async value => {
        this.setState({
            type: value,
        });

        return true;
    }

    handleRemoveTag = tag => {
        const { tags } = this.state;
        let index = tags.indexOf(tag);
        if (index > -1) {
            tags.splice(index, 1);
            this.setState({
                tags,
            });
        }
    }

    handleAddTag = () => {
        let {
            existingTags,
        } = this.props;
        let {
            tags,
        } = this.state;

        ShowDialog(AddTagsDialog, {
            activeTags: tags,
            existingTags,
            acceptCallback: newTags => {
                this.setState({
                    tags: newTags
                });
            },
        });
    }

    handleAccept = () => {
        let {
            callback,
            closeDialog,
        } = this.props;

        !!callback && callback(this.state);
        closeDialog();
    }

    render() {
        let {
            closeDialog,
        } = this.props;

        let {
            type,
            name,
            tags,
        } = this.state;

        return (
            <BeautifulDialog closeDialog={closeDialog}>
                <DialogTitle>
                    <div>Share this asset with your team?</div>
                    <div>
                        <SubText>Update this asset at any time and it will update everywhere it's used.</SubText>
                    </div>
                </DialogTitle>

                <DialogContent
                    style={{
                        paddingLeft: 80,
                        paddingRight: 80,
                        overflowY: "hidden",
                    }}
                >
                    <ContentItem>
                        <FlexBox left>
                            <div>Asset Type</div>
                            <Gap10 />
                            <DropDown
                                variant="outlined"
                                onSelect={this.handleSelectType}
                                items={[
                                    {
                                        label: "Image",
                                        value: "image",
                                    },
                                    {
                                        label: "Logo",
                                        value: "logo",
                                    },
                                ]}
                                type="transparent"
                                aboveAll
                            />
                        </FlexBox>
                    </ContentItem>
                    <Gap20 />
                    <Field title="Asset Name">
                        <TextField
                            autoFocus
                            id="assetName"
                            value={name}
                            placeholder="Asset name..."
                            fullWidth
                            onChange={event => this.setState({ name: event.target.value })}
                        />
                    </Field>
                    <Field title="Add tags to facilitate search (optional)">
                        <TagsList
                            tags={tags}
                            onRemove={this.handleRemoveTag}
                            onClick={this.handleAddTag}
                        />
                    </Field>
                </DialogContent>

                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={this.handleAccept} color="primary">Share</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
