import { IGenericModelFields } from "./IGenericFields";

export enum ProvisioningSourceAuthType {
    OAUTH2 = "OAUTH2"
}

export enum ProvisioningSourceGrantType {
    AUTHORIZATION_CODE = "authorization_code",
    REFRESH_TOKEN = "refresh_token"
}

export interface IProvisioningSourceAuthConfig {
    authType: ProvisioningSourceAuthType;
    clientId: string;
    clientSecret: string;
    grants: ProvisioningSourceGrantType[];
    redirectURIs: string[];
}
export interface IProvisioningSourceUserSubscriptionConfig {
    priceId: string;
    trialDurationDays: number;
    couponId?: string;
}

export interface IProvisioningSourceAnalyticsConfig {
    enabled: boolean;
    tokenEndpoint: {
        url: string;
        clientId: string;
        clientSecret: string;
    }
    analyticsEndpoint: {
        url: string;
        method: "POST" | "PUT" | "PATCH";
        bodyTemplate: string;
    }
    supportedEventTypes: ProvisioningSourceAnalyticsEventType[];
}

export enum ProvisioningSourceAnalyticsEventType {
    ACCOUNT_CREATED = "account_created",
    ACCOUNT_VERIFIED = "account_verified",
    TRIAL_STARTED = "trial_started",
    TRIAL_ENDED = "trial_ended",
    SUBSCRIBED = "subscribed",
    UNSUBSCRIBED = "unsubscribed",
    ACCOUNT_DELETED = "account_deleted"
}

export interface IProvisioningSourceAnalyticsEvent extends IGenericModelFields {
    createdAt: number;
    type: ProvisioningSourceAnalyticsEventType;
    sourceId: string;
    sid: string;
    uid: string;
    dataSentAt?: number;
    modifiedAt: number;
}

export interface IProvisioningSource extends IGenericModelFields {
    createdAt: number;
    modifiedAt: number;
    name: string;
    authConfig: IProvisioningSourceAuthConfig;
    userSubscriptionConfig: IProvisioningSourceUserSubscriptionConfig;
    analyticsConfig: IProvisioningSourceAnalyticsConfig;
    enabled: boolean;
}
