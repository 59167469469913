import React, { Component } from "react";
import styled from "styled-components";

import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { getStaticUrl } from "js/config";
import * as boxAuth from "js/core/oauth/boxAuth";
import { downloadFileFromBox } from "js/core/utilities/downloadFile";
import { FileType, fileTypesToExtensions } from "js/core/utilities/fileSourcesAndTypes";
import { PopupBlockedError } from "js/core/utilities/selfClosingWindow";
import { trackActivity } from "js/core/utilities/utilities";
import { BlueButton, TextButton } from "js/react/components/UiComponents";
import Spinner from "js/react/components/Spinner";
import { ShowDialog, ShowErrorDialog, ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import BoxFilePickerDialog from "js/react/components/Dialogs/BoxFilePickerDialog";
import { Gap10, Gap30 } from "js/react/components/Gap";

const logger = getLogger(LogGroup.DATA_SOURCE);

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #A9A9A9;
    padding: 30px 50px;
    margin-bottom: 30px;
`;

interface Props {
    fileTypes: FileType[],
    handleImportedFile: (file: File) => void,
}

interface State {
    userHasBoxOfflineAccess: boolean,
    userBoxLinkFetching: boolean,
    userBoxAccessToken: string,
    disconnecting: boolean,
}

export default class BoxPicker extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            userHasBoxOfflineAccess: false,
            userBoxLinkFetching: false,
            userBoxAccessToken: "",
            disconnecting: false,
        };
    }

    componentDidMount() {
        this.fetchBoxAuthTokens();
    }

    fetchBoxAuthTokens = async () => {
        this.setState({ userBoxLinkFetching: true });

        try {
            const { hasTokens, accessToken } = await Api.getBoxTokens.get();
            this.setState({ userBoxLinkFetching: false, userHasBoxOfflineAccess: hasTokens, userBoxAccessToken: accessToken });
        } catch (err) {
            logger.error(err, "[BoxPicker] Api.getBoxTokens.get() failed");
            this.setState({ userBoxLinkFetching: false });
        }
    }

    handleSelectedFile = async file => {
        try {
            const fileData = await downloadFileFromBox({
                id: file.id, name: file.name, accessToken: this.state.userBoxAccessToken
            });
            this.props.handleImportedFile(fileData);
        } catch (err) {
            logger.error(err, "[BoxPicker] this.fetchSpreadsheetData() failed", { fileId: file?.id });
            ShowErrorDialog({
                error: "Sorry, we could not process your request",
                message: err.message
            });
        }
    }

    handleBoxAuth = async () => {
        const { userHasBoxOfflineAccess } = this.state;

        try {
            if (!userHasBoxOfflineAccess) {
                trackActivity("Box", "DataLinkGrantAccess", null, null, {}, { audit: true, skipAmplitude: false });

                const tokens = await boxAuth.grantOfflineAccess();

                this.setState({ userHasBoxOfflineAccess: true, userBoxAccessToken: tokens.accessToken });
            }

            this.openPickerUI();
        } catch (err) {
            if (err instanceof PopupBlockedError) {
                // User cancelled, ignore
                return;
            }

            logger.error(err, "[BoxPicker] boxAuth.grantOfflineAccess() failed");
        }
    }

    handleDisconnect = async () => {
        if (this.state.disconnecting) return;

        ShowConfirmationDialog({
            title: "Disconnect Account?",
            message: (
                <span>
                    Any linked data will no longer automatically update.
                    <br /><br />
                    You can link to this account again at any time.
                </span>
            ),
            buttonOptions: { acceptButtonColor: "red" },
            okButtonLabel: "Disconnect",
            acceptCallback: async () => {
                this.setState({ disconnecting: true });

                try {
                    logger.info("[BoxPicker] Deleting credentials from server");
                    await Api.unlinkBoxAuth.delete();

                    this.setState({
                        disconnecting: false,
                        userHasBoxOfflineAccess: false,
                        userBoxAccessToken: ""
                    });
                } catch (err) {
                    logger.error(err, "[BoxPicker] handleDisconnect() failed");
                    this.setState({ disconnecting: false });

                    ShowErrorDialog({
                        error: "Sorry, we could not process your request",
                        message: err.message
                    });
                }
            }
        });
    }

    openPickerUI = () => {
        const accessToken = this.state.userBoxAccessToken;

        const { fileTypes } = this.props;
        const fileExtensions = fileTypesToExtensions(fileTypes);

        if (accessToken) {
            ShowDialog(BoxFilePickerDialog, {
                token: accessToken,
                handleSelectedFile: this.handleSelectedFile,
                fileExtensions,
            });
        }
    }

    render() {
        const { userHasBoxOfflineAccess, userBoxLinkFetching, disconnecting } = this.state;

        return (
            <Container>
                <img src={getStaticUrl("/images/data-linking/box.svg")} width={50} height={50} />
                <Gap30 />
                {!userBoxLinkFetching && (<>
                    <BlueButton /** @ts-ignore */
                        onClick={this.handleBoxAuth}
                    >
                        {userHasBoxOfflineAccess ? "Select File from Box" : "Connect to Box"}
                    </BlueButton>
                    {userHasBoxOfflineAccess && (
                        <>
                            <Gap10 />
                            <TextButton /** @ts-ignore */
                                onClick={this.handleDisconnect} color="secondary"
                            >
                                {disconnecting && <Spinner />}
                                {!disconnecting && "Disconnect Account"}
                            </TextButton>
                        </>
                    )}
                </>)}
            </Container>
        );
    }
}
