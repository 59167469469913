import * as DOMPurify from "dompurify";

export function sanitizeSvg(svg) {
    return DOMPurify.sanitize(svg, { NAMESPACE: "http://www.w3.org/2000/svg", USE_PROFILES: { svg: true, svgFilters: true, html: true } });
}

export function sanitizeHtml(html) {
    return DOMPurify.sanitize(html, { USE_PROFILES: { html: true, svg: true },
        // Extending the default config to allow 'bai' "protocol"
        // Do not overuse to avoid security issues XXS attacks
        ALLOWED_URI_REGEXP: /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|cid|xmpp|xxx|bai):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i });
}

export function sanitizeIframe(iframe) {
    return DOMPurify.sanitize(iframe, { ADD_TAGS: ["iframe"], ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"] });
}
