import { Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { PaymentElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";

import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import withElements from "js/react/views/UserOptions/Billing/withElements";
import withStripe from "js/react/views/UserOptions/Billing/withStripe";

import "css/billing.scss";

const logger = getLogger(LogGroup.BILLING);

function ChangePaymentMethodDialog(props) {
    const { elements, closeDialog, stripe, orgId, callback = () => { } } = props;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const handleUpdatePaymentMethod = async orgId => {
        if (isSubmitting) return;

        setIsSubmitting(true);
        setError(null);

        const { error: submitError } = await elements.submit();
        if (submitError) {
            setError(submitError.message);
            setIsSubmitting(false);
            return;
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({ elements });
        if (error) {
            setIsSubmitting(false);
            setError(error.message);
            logger.error(error, "stripe.createPaymentMethod() failed", { orgId });
            callback(false);
            setIsSubmitting(false);
            return;
        }

        try {
            const { requiresAction, clientSecret } = await Api.paymentMethods.post({
                payment_method_id: paymentMethod.id,
                organization_id: orgId
            });

            if (!requiresAction) {
                callback(true);
                closeDialog();
                return;
            }

            const { error } = await stripe.handleCardPayment(clientSecret, {});
            if (error) {
                throw error;
            }
            callback(true);
            closeDialog();
        } catch (err) {
            logger.error(err, "Api.paymentMethods.post() failed", { paymentMethodId: paymentMethod?.id, orgId });
            setError(err.message);
            callback(false);
        }

        setIsSubmitting(false);
    };

    const hadleCloseDialog = () => {
        callback(false);
        closeDialog();
    };

    return (
        <BeautifulDialog className="update_payment_form" closeDialog={hadleCloseDialog}>
            <FetchingClickShield visible={isSubmitting} backgroundColor="white" />
            <DialogTitle>Update Payment Method</DialogTitle>
            <DialogContent>
                <PaymentElement onChange={({ error }) => setError(error?.message ?? null)} />
                {error && <div className="card-error">{error}</div>}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={hadleCloseDialog}>
                    Cancel
                </Button>
                <Button color="primary" onClick={() => handleUpdatePaymentMethod(orgId)}>
                    Update Payment Method
                </Button>
            </DialogActions>
        </BeautifulDialog>
    );
}

export default withStripe(withElements(ChangePaymentMethodDialog));
