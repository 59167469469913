import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { _ } from "js/vendor";

const ActionButton = styled(Button)`
  background-color: rgba(0,0,0,.05) !important;
  font-size: 13px !important;
  color: #777 !important;
`;

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    justify-content: center;
    gap: 10px;
    margin-right: 10px;
`;

const shortCuts = [
    {
        action: "list",
        icon: "list",
        name: "Lists"
    },
    {
        action: "structure",
        icon: "star",
        name: "Structure"
    },
    {
        action: "data",
        icon: "trending_up",
        name: "Data & Charts"
    },
    {
        action: "impact",
        icon: "camera",
        name: "Visual Impact"
    },
    {
        action: "diagram",
        icon: "widgets",
        name: "Diagrams"
    },
    {
        action: "comparison",
        icon: "view_column",
        name: "Comparison"
    },
    {
        action: "people",
        icon: "face",
        name: "People"
    },
];

export function SearchShortCuts({ handleShortCut }) {
    return (
        <Container>
            {
                shortCuts.map(({ name, action }) => {
                    return (
                        <ActionButton key={_.uniqueId()} onClick={() => handleShortCut(action)}>
                            {name}
                        </ActionButton>
                    );
                })
            }
        </Container>
    );
}
