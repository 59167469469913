import type { Model as BackboneModel } from "backbone";

import { slides as slidesApi } from "apis/callables";
import applyCommands from "common/commands/applyCommands";
import deriveSimpleModel from "common/commands/deriveSimpleModel";
import { SLIDE_COMMAND_BLACKLIST } from "common/constants";
import { IPresentation, ISlide } from "common/interfaces/models";
import getLogger, { LogGroup } from "js/core/logger";

import Adapter from "./adapter";
import ApiAdapter, { ShouldUseFallbackAdapterError } from "./apiAdapter";
import CommandsAdapter from "./commandsAdapter";

const logger = getLogger(LogGroup.ADAPTER);

class SlidesAdapter extends ApiAdapter<ISlide, { userId: string }> {
    async _createApi(model: ISlide): Promise<ISlide> {
        return await slidesApi.createSlide(model);
    }

    async _updateApi(changesetUpdates: Partial<ISlide>, changesetOriginal: Partial<ISlide>): Promise<Partial<ISlide>> {
        const { modifiedAt, _changeId } = await slidesApi.updateSlide({
            id: this.id,
            changesetUpdates,
            changesetOriginal,
            _changeId: this.model.attributes._changeId
        });
        return { modifiedAt, _changeId };
    }

    async _getApi(): Promise<ISlide> {
        const presentation = (this.model as unknown as { presentation: BackboneModel<IPresentation & { isDummy: boolean, link: { id: string } }> }).presentation;

        const { slide, migrated } = await slidesApi.getSlide({
            id: this.id,
            presentationId: (presentation && !presentation.get("isDummy")) ? presentation.id as string : null,
            presentationLinkId: presentation && presentation.get("link")?.id as string,
        });

        if (!migrated) {
            throw new ShouldUseFallbackAdapterError("Slide was not migrated, use fallback adapter");
        }

        return slide;
    }

    _getPusherChannelId(): string {
        return `private-slide-${this.id}`;
    }

    _composeModel(baseModel: Partial<ISlide> | (ISlide & { cmds: any, snap: any })): ISlide {
        let slideModel: ISlide;
        if ((baseModel as ISlide & { cmds: any, snap: any }).cmds) {
            logger.warn(`[SlidesAdapter] _initialize() received initial data with commands, this should never happen, will migrate the model`, { id: this.id });
            slideModel = this._composeSlideModel(baseModel as ISlide & { cmds: any, snap: any });
        } else {
            slideModel = { ...baseModel, id: this.id } as ISlide;
        }

        // Force non-empty states
        // It can be nulled by StorageModel on update because mergeChanges()
        // removes empty objects and arrays to mimic Firebase behaviour
        if (!slideModel.states) {
            slideModel.states = [{}];
        }

        return slideModel;
    }

    _composeSlideModel(firebaseSlide: ISlide & { cmds: any, snap: any }) {
        const commands = Object.entries(firebaseSlide.cmds)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((cmd: any) => {
                cmd[1].id = cmd[0];
                return cmd[1];
            })
            .filter(command => command.id > firebaseSlide.snap.id);

        const updates = commands.reduce((list, cmd) => list.concat(JSON.parse(cmd.updates)), []);
        const commandsModel = applyCommands(JSON.parse(firebaseSlide.snap.model || "{}"), updates);

        // Merging commands fields and plain fields
        const slideModel = { ...firebaseSlide, ...deriveSimpleModel(commandsModel), id: this.id };
        delete slideModel.cmds;
        delete slideModel.snap;

        return slideModel as ISlide;
    }

    _switchToFallbackAdapter(): void {
        this._useFallbackAdapter = true;
        this._fallbackAdapter = new CommandsAdapter({
            writablePlainProperties: ["presentationId"],
            plainProperties: SLIDE_COMMAND_BLACKLIST,
            autoSync: this.autoSync,
            readonly: this.readonly,
            userId: this._additionalProps.userId
        });

        // This will trigger the "initialize" event upon connection
        this._fallbackAdapter.connect({
            root: "slides",
            id: this.id,
            // @ts-ignore
            onRemoteChange: (changeType: number, data: Partial<ISlide & { migrationFinishedAt: number }>) => {
                // If the slide was migrated, then switch to Mongo
                if ([Adapter.TYPE.replace, Adapter.TYPE.update, Adapter.TYPE.initialize].includes(changeType) && data.migrationFinishedAt) {
                    logger.info("[SlidesAdapter] onRemoteChange() slide was migrated, switching to Mongo", { id: this.id });
                    this._fallbackAdapter.disconnect();
                    this._fallbackAdapter = null;
                    this._useFallbackAdapter = false;
                    // Reinitializing
                    this._initialize(null);
                    return;
                }

                return this._onRemoteChange(changeType, data);
            },
            onRemoteError: this._onRemoteError
        });
    }
}

export default SlidesAdapter;
