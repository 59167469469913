function delay(timeMs) {
    if (timeMs === 0) {
        return Promise.resolve();
    }
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, timeMs);
    });
}

function delayUntil(filter, interval = 10, timeout = 1000) {
    return new Promise(async (resolve, reject) => {
        const start = Date.now();
        // Check if the condition passes
        while (!filter()) {
            // Quit if we exceed the timeout
            if (Date.now() - start > timeout) {
                // Failure
                reject();
                return;
            }
            // Wait the interval and try again
            await delay(interval);
        }
        // Success
        resolve();
    });
}

function timeoutRace(time, promise) {
    return Promise.race([delay(time), promise]);
}

function loadImage(url, timeout, crossOrigin = null) {
    if (!url) {
        return Promise.reject(new Error("Image url not defined."));
    }

    const image = new Image();
    if (crossOrigin) {
        image.crossOrigin = crossOrigin;
    }
    const promise = new Promise((resolve, reject) => {
        image.onload = () => {
            resolve(image);
        };
        image.onerror = () => {
            reject(new Error(`Could not load ${url}`));
        };
        image.src = url;
    });

    if (timeout) {
        return timeoutRace(timeout, promise);
    } else {
        return promise;
    }
}

function loadFileAs(file, readerMethod) {
    if (!file) {
        return Promise.reject(new Error("File not defined."));
    }
    if (!readerMethod) {
        return Promise.reject(new Error("Reader method required."));
    }
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onabort = () => {
            reject(new Error("File load aborted"));
        };
        reader.onerror = () => {
            reject(new Error("File failed to load"));
        };
        reader[readerMethod](file);
    });
}

function loadFileAsDataUrl(file) {
    return loadFileAs(file, "readAsDataURL");
}

function loadFileAsArrayBuffer(file) {
    return loadFileAs(file, "readAsArrayBuffer");
}

function createAndLoadSvgImage(parent, url) {
    return new Promise((resolve, reject) => {
        const image = parent.image(url);
        image.loaded(() => {
            resolve(image);
        });
        image.error(() => {
            reject(new Error(`Could not load ${url}`));
        });
    });
}

export {
    delay,
    delayUntil,
    timeoutRace,
    loadImage,
    createAndLoadSvgImage,
    loadFileAsDataUrl,
    loadFileAsArrayBuffer
};
