const clientName = window.clientName;
const serverApi = window.serverApi;
const isDevelopment = window.isDevelopment;
const isLocalDev = window.staticUrl?.includes("localhost");
const isStaging = window.isStaging;
const isProduction = window.isProduction;
const isOfflinePlayer = window.isOfflinePlayer;
const enableTestingApi = window.enableTestingApi;
// Combo of a commit hash and the build number.
const buildVersion = window.buildVersion ?? "dev";
// Circle ci build number in sequential order.
const buildNumber =
    buildVersion !== "dev"
        ? parseInt(window.buildVersion.split("-")[1])
        : Number.POSITIVE_INFINITY;

const serverContext = window.serverContext ?? {};
const electronAppVersion = window.electronAppVersion ?? null;
const appSearchHost = window.appSearchHost;
const appSearchNames = window.appSearchNames ?? {};
const pusherKey = window.pusherKey;
const pusherCluster = window.pusherCluster;
const isPPTAddin = window.isPPTAddin ?? false;
const isDropboxEnabled = !!window.Dropbox;
// Related to rendering a thumnail
const isRenderer = window.isRenderer ?? false;
const appVersion = window.appVersion;
const isCypress = !!window.Cypress;
const requirePlayerPassword = window.requirePlayerPassword ?? false;
const disablePusher = window.disablePusher ?? false;
const serverUrl = window.serverUrl;

function isInternalBAIUser(email) {
    return email?.endsWith("@beautiful.ai");
}

function getStaticUrl(path, isAbsolute) {
    if (window.staticUrl) {
        if (window.manifest[path]) {
            if (isAbsolute && window.manifest[path].startsWith("http")) {
                return window.manifest[path];
            } else {
                return window.staticUrl + window.manifest[path];
            }
        }
        return window.staticUrl + path;
    } else {
        return path;
    }
}

export {
    clientName,
    serverApi,
    serverUrl,
    serverContext,
    isDevelopment,
    isLocalDev,
    isStaging,
    isProduction,
    isOfflinePlayer,
    isRenderer,
    enableTestingApi,
    electronAppVersion,
    buildVersion,
    buildNumber,
    getStaticUrl,
    isInternalBAIUser,
    appSearchHost,
    appSearchNames,
    pusherKey,
    pusherCluster,
    isPPTAddin,
    isDropboxEnabled,
    appVersion,
    isCypress,
    requirePlayerPassword,
    disablePusher,
};
