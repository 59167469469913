import { EndpointDefinition } from "../_sdk/types/common";

export const apiName = "clientLogs";

export const endpoints: { [key: string]: EndpointDefinition } = {
    writeLogs: {
        route: "/clientLogs",
        method: "POST"
    }
};
