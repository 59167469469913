import React, { Component } from "reactn";
import styled from "styled-components";
import {
    DialogTitle,
    DialogActions,
    Button
} from "@material-ui/core";

import { app } from "js/namespaces";
import { FeatureType } from "common/features";
import AppController from "js/core/AppController";
import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import { BlueSwitch } from "js/react/components/Switch";

const ToggleOptions = styled.div`
    padding: 0 25px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .toggle-option {
        margin: 0 10px;
    }
`;

const Description = styled.div`
    padding: 0 0 0 65px;
    font-size: 12px;
`;

export default class AllTeamMembersFolderSettingsDialog extends Component {
    constructor(props) {
        super(props);

        const {
            restrictAccess,
            hideFolder,
        } = app.user.features.getFeatureProps(FeatureType.WORKSPACE_ALL_TEAM_MEMBERS_FOLDER_SETTINGS, AppController.orgId);

        this.state = {
            restrictAccess,
            hideFolder,
        };
    }

    handleAccept = () => {
        const {
            restrictAccess,
            hideFolder,
        } = this.state;

        AppController.currentTeam.update({
            workspaceSettings: {
                allTeamMembersFolderSettings: {
                    restrictAccess,
                    hideFolder,
                },
            },
        });
        this.props.closeDialog();
    };

    handleChange = stateDelta => {
        this.setState(stateDelta);
        return true;
    }

    render() {
        const {
            restrictAccess,
            hideFolder,
        } = this.state;

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>All Team Members Folder Settings</DialogTitle>

                <ToggleOptions>

                    <div className="toggle-option large-radio" onClick={this.handleImagesClick}>
                        <BlueSwitch
                            inheritFont
                            checked={restrictAccess}
                            onChange={() => this.handleChange({ restrictAccess: !restrictAccess })}
                            label="Restrict Access"
                        />

                        <Description>
                            Only Librarians and Owners can add and remove presentations from this folder.
                        </Description>
                    </div>

                    <div className="toggle-option large-radio" onClick={this.handleImagesClick}>
                        <BlueSwitch
                            inheritFont
                            checked={hideFolder}
                            onChange={() => this.handleChange({ hideFolder: !hideFolder })}
                            label="Hide Folder"
                        />

                        <Description>
                            Hide folder from all team members. Presentations currently in this folder will not be deleted, but some members may lose access.
                        </Description>
                    </div>

                </ToggleOptions>
                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Cancel</Button>
                    <Button onClick={this.handleAccept} color="primary">OK</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
