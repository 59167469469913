import { LogGroup, LogLevel, LoggerTransportEntryData, LoggerTransportContext, LogEntryContext } from "js/core/logger/types";

class LoggerTransport {
    protected _context: LoggerTransportContext

    constructor(context: LoggerTransportContext) {
        this._context = context;
    }

    public log(context: LogEntryContext, group: LogGroup, level: LogLevel, message: string, data: LoggerTransportEntryData, error?: Error) {
        throw new Error("Not implemented");
    }

    public dispose() {
    }
}

export default LoggerTransport;
