import React, { Component } from "react";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import { sanitizeHtml } from "js/core/utilities/dompurify";
import Loadable from "js/react/components/Loadable";
import Api from "js/core/api";

export default class PreviewEmailDialog extends Component {
    state = {
        isLoading: true
    }

    async componentDidMount() {
        const response = await Api.renderPresentationLinkEmail.post({
            presentationId: this.props.presentation.id,
            customMessage: sanitizeHtml(this.props.customMessage)
        });

        this.setState({
            isLoading: false,
            previewHTML: response.html
        });
    }

    render() {
        const { isLoading, previewHTML } = this.state;

        return (
            <BeautifulDialog maxWidth="md" fullWidth={false} closeDialog={this.props.closeDialog}>
                <Loadable isLoading={isLoading}>
                    <DialogContent
                        dangerouslySetInnerHTML={{ __html: sanitizeHtml(previewHTML) }}
                        style={{ padding: "20px" }}
                    ></DialogContent>
                </Loadable>
            </BeautifulDialog>
        );
    }
}
