import { app } from "js/namespaces";
import StorageModel from "../storage/storageModel";
import ReferenceCollection from "js/core/storage/referenceCollection";
import Api from "js/core/api";
import { Backbone } from "../../vendor";
import { trackActivity } from "js/core/utilities/utilities";

const LibraryItem = StorageModel.extend({
    root: "libraryItems",

    update: function(attrs, options = {}) {
        const updates = options.skipModifiedAt ? attrs : {
            ...attrs,
            modifiedAt: new Date().getTime(),
            modifiedBy: app.user.id
        };
        return StorageModel.prototype.update.call(this, updates, options);
    },

    destroy(options) {
        //Call Backbone's destroy instead of the StorageModel's destroy so we can call our delete api instead.
        //This removes the libraryItem from the team's shared resources
        Backbone.Model.prototype.destroy.call(this, options);
        this.destroyed = true;
        this.adapter.disconnect();
        //delete the libraryItem via an endpoint.
        if (!options || !options.remoteChange) {
            return Api.libraryItems.delete({ id: this.id, isSlideTemplate: options.isSlideTemplate });
        } else {
            return Promise.resolve({});
        }
    },
});

const LibraryItems = ReferenceCollection.extend({
    model: LibraryItem,
    ignoreErrors: true,
    referenceRoot: "teams",

    getReferenceId: function() {
        return `${this.teamId}/sharedResources/1`;
    },

    initialize: function(models, options) {
        this.type = "LibraryItems";
        this.teamId = options.teamId;
    },

    createLibraryItem: async function(model, slide) {
        model.teamId = this.teamId;
        await Api.libraryItems.post({ model, isSlideMigrated: slide.isMigrated() });
        const props = {
            slide_id: slide.id,
            template_name: slide.get("template_id"),
        };
        trackActivity("SharedSlideLibrary", "SlideAdded", null, null, props);
    },

});

export { LibraryItems, LibraryItem };
