export enum SupportedFirebaseTables {
    Organizations = "organizations",
    Teams = "teams",
    UserFolders = "userFolders",
    PresentationLinks = "presentationLinks",
    Presentations = "presentations",
    Users = "users",
}

export enum SupportedOperationTypes {
    Create = "create",
    Update = "update",
    Delete = "delete",
}

export interface IMongoMigration {
    id: string;
    mongoId: string;
    firebaseId: string;
    firebaseTable: SupportedFirebaseTables;
    history: {
        lastSyncedAt: Number,
        firebaseData: any,
        mongoData: any,
    }[],
    latestMongoData: any,
    latestFirebaseData: any,
    lastSyncedAt: number;
    createdAt: number;
    modifiedAt: number;
}
