import { Grid, Icon, Button } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";

import { getStaticUrl } from "legacy-js/config";

const blueCheckIcon = getStaticUrl("/images/onboarding/check.png");

const PlanBoxBorder = css`
    border: 1px solid #CCC;
    border-radius: 1px;
`;

const PlanBox = styled.div`
    color: #485159;
    flex-grow: 1;
    ${props => props.noBorder ? `` : PlanBoxBorder}
    min-height: 100%;
`;

const PlanIntent = styled.div`
    &&& { 
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
        background: ${props => props.focus ? "#d6eff9" : "#F7FAFC" };
        text-transform: uppercase; 
        letter-spacing: 0.8px;
        font-size: 13px;

        > .material-icons {
            margin-right: 5px;
            color: #11a9e2;
            height: 24px;
        }
    }
`;

const PlanDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px;
`;

const PlanBenefits = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px 20px;
    `;

const PlanName = styled.h2`
    color: #222;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2; 
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-align: center;
    margin: 0;
`;

const PlanInfo = styled.div`
    text-align: center;
`;

const PlanPrice = styled.div`
    font-size: 14px;
    text-align: center;
`;

const PlanMore = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
`;

const FeatureList = styled.div`
    font-size: 14px;
    margin: 0;
    gap: 8px;
    display: flex;
    flex-direction: column;

    div::before {
        content: "";
        background-image: url(${blueCheckIcon});
        background-position: 0 3px;
        background-size: auto 11px;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        padding-right: 3px;
    }
`;

const FeatureListItem = styled.div`
    display: flex;
`;

const EmphasizedStyle = css`
    border: 2px solid #11a9e2;
    border-radius: 1px;
`;

const DefaultStyle = css`
    padding: 10px 0;
`;

const StyledGrid = styled(Grid)`
    flex: 1;
    ${props => props.emphasize ? EmphasizedStyle : DefaultStyle}
`;

const PaddedButton = styled(Button)`
    padding: 6px 30px;

    .MuiButton-label {
        letter-spacing: 0.8px;
    }
`;

const PlanCard = ({ hide, emphasize, icon, title, planName, planPrice, buttonId, buttonOnClick, buttonText, planMore, features }) => (
    !hide && <StyledGrid item emphasize={emphasize}>
        <PlanBox noBorder={emphasize}>
            <PlanIntent focus={emphasize}>
                <Icon>{icon}</Icon>
                <span>{title}</span>
            </PlanIntent>
            <PlanDescription>
                <PlanName>{planName}</PlanName>
                <PlanPrice>{planPrice}</PlanPrice>
            </PlanDescription>
            <PlanInfo>
                <PaddedButton
                    id={buttonId}
                    variant="contained"
                    color="primary"
                    onClick={buttonOnClick}
                >
                    {buttonText}
                </PaddedButton>
            </PlanInfo>
            <PlanBenefits>
                <PlanMore>{planMore}</PlanMore>
                <FeatureList>
                    {features.map((feature, index) => (<FeatureListItem key={index}>{feature}</FeatureListItem>))}
                </FeatureList>
            </PlanBenefits>
        </PlanBox>
    </StyledGrid>
);

export default PlanCard;
