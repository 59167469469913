import React, { Component } from "react";
import styled from "styled-components";
import { Icon, MenuItem } from "@material-ui/core";

import Api from "js/core/api";
import { ds } from "js/core/models/dataService";
import Loadable from "js/react/components/Loadable";
import { UIPane, RoundIconButton } from "js/react/components/UiComponents";
import { trackActivity } from "js/core/utilities/utilities";
import { PERMISSION_RESOURCE_TYPE } from "common/constants";
import { defaultSimpleTheme } from "common/themes";
import { ShowMessageDialog } from "js/react/components/Dialogs/BaseDialog";
import { app } from "js/namespaces";
import { Breadcrumb } from "js/Components/Breadcrumb";
import { SearchBarContainer, UIPaneResultsContainer } from "js/react/views/AddSlide/Panes/Components/SearchBox";
import { ThumbnailGrid } from "js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import { ThemeThumbnail } from "js/editor/ThemeEditor/components/ThemeThumbnail";
import { IconMenu } from "js/react/components/UiComponents";
import { ShowDialog, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import InputConfirmationDialog from "js/react/components/Dialogs/InputConfirmationDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import AppController from "js/core/AppController";

const Container = styled.div`
    background: white;
    padding: 20px;
`;

export default class TeamSharedThemePane extends Component {
    state = {
        isThemeLoading: true,
        isSharedThemeHovered: false,
        defaultTeam: null,
        defaultTeamThemeId: null
    };

    componentDidMount = async () => {
        await this.loadData();
    };

    getDefaultTeamTheme() {
        const orgId = AppController.orgId;
        const defaultTeam = ds.teams.defaultTeamForOrg(orgId);
        const defaultTeamThemeId = defaultTeam.get("sharedThemeId");
        const theme = ds.sharedThemes.findWhere({ id: defaultTeam.get("sharedThemeId") });
        return { defaultTeam, theme, defaultTeamThemeId };
    }

    async loadData() {
        // other themes are loaded by ds.sharedThemes
        const { defaultTeam, theme: defaultTeamTheme, defaultTeamThemeId } = this.getDefaultTeamTheme();
        await defaultTeamTheme.loadTheme();

        this.setState({
            isThemeLoading: false,
            defaultTeam,
            defaultTeamTheme,
            defaultTeamThemeId
        });
    }

    async finishEditing(theme) {
        const { organizationId } = this.props;
        const props = {
            workspace_id: organizationId,
            theme_id: theme.id
        };
        trackActivity("OrgTheme", "Edited", null, null, props);

        await theme.loadTheme();
        this.forceUpdate();
    }

    handleEdit = async theme => {
        AppController.showThemeEditor({ themeOrPresentationId: theme.id });
    }

    handleCreate = async () => {
        const orgId = AppController.orgId;
        const team = this.state.defaultTeam;

        AppController.showThemeEditor({ themeOrPresentationId: "new-team-theme" });
    }

    handleDuplicate = async theme => {
        const orgId = AppController.orgId;
        const team = this.state.defaultTeam;

        const name = "Copy of " + theme.get("name");
        await app.themeManager.createTeamTheme(theme.attributes, orgId, team, name);

        this.loadData();
    }

    handleRename = theme => {
        ShowDialog(InputConfirmationDialog, {
            title: "Rename Theme",
            message: null,
            input: {
                value: theme.get("name")
            },
            acceptCallback: value => {
                try {
                    theme.update({ name: value.trim() });
                    this.loadData();
                } catch (err) {
                    ShowErrorDialog({
                        title: "Unable to rename theme",
                        message: err.message
                    });
                }
            },
        });
    };

    handleDelete = async theme => {
        if (await ShowConfirmationDialog({
            title: "Are you sure you want to delete this theme?",
            message: "This action can not be undone.",
            buttonOptions: { cancelButtonVariant: "outlined", acceptButtonColor: "red" },
            okButtonLabel: "Delete",
        })) {
            const progressDialog = ShowDialog(ProgressDialog, {
                title: `Preparing to delete theme...`,
            });

            const res = await Api.teamThemes.get({
                teamId: this.state.defaultTeam.id,
                sharedThemeId: theme.id
            });

            progressDialog.props.closeDialog();

            if (res.count > 0) {
                const title = "Sorry you can't delete a Team Theme that is currently being used by presentations.";
                const message = `This theme is used in ${res.count} presentation${res.count > 1 ? "s" : ""}.`;
                if (theme.get("hidden")) {
                    ShowMessageDialog({
                        title,
                        message
                    });
                    return;
                } else {
                    const willHide = await ShowConfirmationDialog({
                        title,
                        message: message + " If you want to prevent this Team Theme from being used in additional presentations, you can hide it.",
                        okButtonLabel: "Hide",
                    });
                    if (willHide) {
                        this.handleHide(theme);
                    }
                    return;
                }
            }

            await app.themeManager.deleteTeamTheme(this.state.defaultTeam, theme.id);
            this.loadData();
        }
    };

    handleHide = theme => {
        try {
            theme.update({ hidden: true });
            this.loadData();
        } catch (err) {
            ShowErrorDialog({
                title: "Unable to hide theme",
                message: err.message
            });
        }
    };

    handleUnhide = theme => {
        try {
            theme.update({ hidden: false });
            this.loadData();
        } catch (err) {
            ShowErrorDialog({
                title: "Unable to unhide theme",
                message: err.message
            });
        }
    };

    render() {
        const { isThemeLoading, defaultTeamTheme } = this.state;

        const team = this.state.defaultTeam;
        if (!team) {
            return null;
        }
        const sharedThemeIds = (team.get("sharedResources") ?? {})[PERMISSION_RESOURCE_TYPE.SHARED_THEME] ?? {};
        const sharedThemes = Object.keys(sharedThemeIds).map(id => ds.sharedThemes.get(id));
        const teamThemes = [defaultTeamTheme, ...sharedThemes].filter(Boolean);

        return (
            <UIPane>
                <SearchBarContainer className="search-container">
                    <Breadcrumb>Team Themes</Breadcrumb>
                    <RoundIconButton onClick={this.handleCreate}><Icon>add</Icon></RoundIconButton>
                </SearchBarContainer>
                <UIPaneResultsContainer>
                    <Loadable isLoading={isThemeLoading}>
                        <Container>
                            {teamThemes.length > 0 && (
                                <ThumbnailGrid
                                    items={teamThemes}
                                    columns={4}
                                    selectedItem={false}
                                    renderThumbnail={child => {
                                        const isDefault = child.item.get("id") === this.state.defaultTeamThemeId;
                                        return (
                                            <ThemeThumbnail
                                                item={child.item}
                                                isDefault={isDefault && teamThemes.length > 1}
                                                menu={(
                                                    <IconMenu>
                                                        <MenuItem onClick={() => this.handleEdit(child.item)}><Icon>edit</Icon>Edit Theme...</MenuItem>
                                                        <MenuItem onClick={() => this.handleRename(child.item)}><Icon>label</Icon>Rename</MenuItem>
                                                        <MenuItem onClick={() => this.handleDuplicate(child.item)}><Icon>content_copy</Icon>Duplicate</MenuItem>
                                                        <hr key="divider" />
                                                        {child.item.get("hidden")
                                                            ? <MenuItem key="unhide" onClick={() => this.handleUnhide(child.item)}><Icon>visibility_on</Icon>Show</MenuItem>
                                                            : <MenuItem key="hide" onClick={() => this.handleHide(child.item)}><Icon>visibility_off</Icon>Hide</MenuItem>
                                                        }
                                                        {!isDefault && (
                                                            [
                                                                /* <MenuItem key="make-default" onClick={() => alert('todo: make default')}><Icon>check</Icon>Make Default</MenuItem>, */
                                                                <MenuItem key="delete" onClick={() => this.handleDelete(child.item)}><Icon>delete</Icon>Delete...</MenuItem>
                                                            ]
                                                        )}
                                                    </IconMenu>
                                                )}
                                                onDoubleClick={() => this.handleEdit(child.item)}
                                            />
                                        );
                                    }}
                                />
                            )}

                        </Container>
                    </Loadable>
                </UIPaneResultsContainer>
            </UIPane>
        );
    }
}
