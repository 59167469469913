function getNumber(end) {
    if (end === "") {
        return 0;
    }
    const match = /^\((\d+)\)$/.exec(end);
    if (match) {
        return parseInt(match[1]);
    } else {
        return -1;
    }
}

function getParts(name) {
    const match = /^(.*) (\(\d+\))$/.exec(name);
    if (match) {
        return [match[1], match[2]];
    } else {
        return [name, ""];
    }
}

export default function(name, names) {
    const parts = getParts(name);
    let number = getNumber(parts[1]);

    names.forEach(existingName => {
        if (!existingName || existingName.indexOf(parts[0]) !== 0) {
            return;
        }
        const end = existingName.substring(parts[0].length + 1);
        const currentNumber = getNumber(end);

        number = Math.max(number, currentNumber + 1);
    });

    if (number > 0) {
        name = `${parts[0]} (${number})`;
    }

    return name;
}
