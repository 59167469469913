import sanitize from "sanitize-filename";

import getLogger, { LogGroup } from "js/core/logger";
import { blobToDataURL, filenameForExport } from "js/core/utilities/utilities";
import { $, _, PptxGenJS } from "js/vendor";
import { app } from "js/namespaces";
import { ShowDialog, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import ExportToPPTWarningDialog from "js/react/views/PresentationSettings/dialogs/ExportToPPTWarningDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { loadFileAsDataUrl } from "js/core/utilities/promiseHelper";
import { presentations as presentationsApi } from "apis/callables";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

// issues
// ---------------------------------------------------------
// color alpha is not supported (text fontColor opacity, etc.)

const logger = getLogger(LogGroup.EXPORT);

class ExportToPPT {
    static EXPORT_DESTINATION = {
        local: "local",
        base64: "base64",
        blob: "blob"
    }

    constructor(options = {}) {
        this.options = options;
    }

    async export({
        slideCanvases = null,
        slide,
        debug = false,
        destination = ExportToPPT.EXPORT_DESTINATION.local,
        includeSkippedSlides,
        showDialogs = true,
        exportSlidesAsImages = false
    }) {
        const pptx = new PptxGenJS();
        pptx.setLayout("LAYOUT_WIDE");

        let progressDialog;
        if (showDialogs) {
            progressDialog = ShowDialog(ProgressDialog, {
                title: "Exporting to PPTX",
                progress: 0,
                onCancel: () => {
                    this.isCanceled = true;
                    progressDialog.props.closeDialog();
                }
            });
        }

        const failedSlideIndexes = [];
        const exportWarnings = [];

        // make sure the canvas_bounds is not display:none or svg nodes won't have a bbox
        const canvasBoundsDisplay = $("#canvas_bounds").css("display");
        if (canvasBoundsDisplay === "none") {
            $("#canvas_bounds").css("display", "block");
        }

        // Slide canvases that we'll be exporting
        let slideCanvasesToExport;

        if (slideCanvases) {
            slideCanvasesToExport = slideCanvases;
        } else {
            slideCanvasesToExport = [];
            progressDialog?.setTitle("Preparing slides for export...");
            const canvasControllers = Object.values(PresentationEditorController.getCanvasControllers());

            for (const canvasController of canvasControllers) {
                progressDialog?.setTitle(`Preparing slide ${canvasControllers.indexOf(canvasController) + 1} of ${canvasControllers.length} for export...`);
                progressDialog?.setProgress((canvasControllers.indexOf(canvasController) / canvasControllers.length) * 100);
                await canvasController.renderCanvas();

                slideCanvasesToExport.push(canvasController.canvas);
            }
        }

        // isExport is used to prevent animated connectors from refreshing canvas
        // and pictures from reloading during export
        slideCanvasesToExport.forEach(slideCanvas => slideCanvas.isExport = true);

        const presentation = PresentationEditorController.presentation;

        // check if filtering out
        if (!includeSkippedSlides) {
            if (slideCanvasesToExport.some(canvas => !canvas.slide.loaded)) {
                // some slide models aren't loaded so we have to pull their metadata from the api
                const slidesMetadata = await presentationsApi.getSlidesMetadata({ id: presentation.id });
                slideCanvasesToExport = slideCanvasesToExport.filter(canvas => !slidesMetadata[canvas.slide.id]?.isSkipped);
            } else {
                slideCanvasesToExport = slideCanvasesToExport.filter(canvas => !presentation.isSlideSkipped(canvas.slide));
            }
        }

        progressDialog?.setTitle("Exporting Slides...");
        for (let i = 0; i < slideCanvasesToExport.length; i++) {
            if (this.isCanceled) {
                break;
            }

            const slideCanvas = slideCanvasesToExport[i];

            try {
                if (this.isCanceled) {
                    break;
                }

                progressDialog?.setTitle("Exporting Slide " + (i + 1) + " of " + slideCanvasesToExport.length);
                progressDialog?.setProgress((slideCanvasesToExport.indexOf(slideCanvas) / slideCanvasesToExport.length) * 100);

                if (exportSlidesAsImages) {
                    const urls = await slideCanvas.dataModel.generateJpegs();
                    for (const url of urls) {
                        const imageBlob = await fetch(url)
                            .then(response => response.blob());
                        const base64image = await loadFileAsDataUrl(imageBlob);

                        const slide = pptx.addNewSlide();
                        slide.addImage({
                            data: base64image,
                            x: 0,
                            y: 0,
                            w: 1280 * 13.33 / 1280,
                            h: 720 * 13.33 / 1280,
                        });
                    }
                } else {
                    if (slideCanvas.playbackStages.length > 0) {
                        const currentPlaybackStage = slideCanvas.currentPlaybackStage;
                        await slideCanvas.resetPlayback(false);
                        for (const stage of slideCanvas.playbackStages) {
                            const slide = pptx.addNewSlide();
                            const canvasExporter = slideCanvas.getExporter(pptx);
                            await canvasExporter.export(
                                slide,
                                exportWarnings,
                                debug
                            );
                            await slideCanvas.playNextStage(false);
                        }
                        await slideCanvas.goToStage(currentPlaybackStage, false);
                    } else {
                        const slide = pptx.addNewSlide();
                        const canvasExporter = slideCanvas.getExporter(pptx);
                        await canvasExporter.export(
                            slide,
                            exportWarnings,
                            debug
                        );
                    }
                }
            } catch (err) {
                failedSlideIndexes.push(i);
                logger.error(err, "ExportToPPT failed to export slide", { slideId: slideCanvas.dataModel?.id, presentation: presentation.id });
            }
        }

        const finishExport = () => {
            slideCanvasesToExport.forEach(slideCanvas => slideCanvas.isExport = false);
            return PresentationEditorController.getCurrentCanvasController().canvas.refreshCanvas();
        };

        const response = {
            failedSlideIndexes,
            exportWarnings,
            isCanceled: false
        };

        if (this.isCanceled) {
            await finishExport();
            return { ...response, isCanceled: true };
        }

        if (failedSlideIndexes.length !== slideCanvasesToExport.length) {
            const showWarningDialogs = () => {
                if (failedSlideIndexes.length > 0 || exportWarnings.length > 0) {
                    ShowDialog(ExportToPPTWarningDialog, { failedSlideIndexes, exportWarnings });
                }
            };
            const presentationName = sanitize(presentation.get("name"));

            const slideIdx = presentation.slides.models.findIndex(s => slide && (s.id === slide.id));
            const slideNum = (slideIdx >= 0 ? slideIdx + 1 : null);
            const fileName = filenameForExport({ name: presentationName, assetType: "pptx", slideNum });
            const userName = app.user.getDisplayName();

            pptx.setTitle(presentationName);
            pptx.setSubject(presentationName);
            pptx.setAuthor(userName);
            pptx.setCompany("Beautiful.ai");

            if (destination === ExportToPPT.EXPORT_DESTINATION.local) {
                await new Promise(resolve => pptx.save(fileName, resolve));
                if (showDialogs) {
                    showWarningDialogs();
                }
            } else if (destination === ExportToPPT.EXPORT_DESTINATION.base64) {
                const blob = await new Promise(resolve => pptx.save("jszip", resolve, "blob"));
                response.base64Data = await blobToDataURL(blob);
                if (showDialogs) {
                    showWarningDialogs();
                }
            } else if (destination === ExportToPPT.EXPORT_DESTINATION.blob) {
                response.blob = await new Promise(resolve => pptx.save("jszip", resolve, "blob"));
                response.fileName = fileName;
                if (showDialogs) {
                    showWarningDialogs();
                }
            }
        } else {
            if (showDialogs) {
                ShowErrorDialog({
                    error: "Unable to export presentation",
                    message: "Sorry, something has gone really wrong exporting this presentation. Please contact us at" +
                        " support@beautiful.ai for more help."
                });
            } else {
                throw new Error("Failed to export presentation");
            }
        }

        progressDialog?.props.closeDialog();

        await finishExport();

        // restore the canvas_bounds display setting immediately
        $("#canvas_bounds").css("display", canvasBoundsDisplay);

        return response;
    }

    async waitForRender() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 0);
        });
    }
}

export { ExportToPPT };
