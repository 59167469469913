import React from "react";
import Cookies from "js-cookie";

import { auth as fbAuth, ensureSignedIntoAllInstances } from "js/firebase/auth";
import getLogger, { LogGroup } from "js/core/logger";
import db from "js/db";

const FirebaseAuthContext = React.createContext();
export const FirebaseAuthConsumer = FirebaseAuthContext.Consumer;
export const TESTING_Provider = FirebaseAuthContext.Provider;

const logger = getLogger(LogGroup.AUTH);

export class FirebaseAuthProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            initialized: false
        };

        this.auth = null;
        this.unsubscribe = null;
    }

    componentDidMount() {
        const { auth = fbAuth() } = this.props;

        this.auth = auth;

        logger.info("[FirebaseAuthProvider] waiting for Firebase Auth to initalize");

        this.unsubscribe = this.auth.onAuthStateChanged(user => {
            logger.info("[FirebaseAuthProvider] firebase Auth state changed");

            Cookies.set("logged-in", `${!!user}`);

            (async () => {
                if (user) {
                    // Have to wait for this
                    await ensureSignedIntoAllInstances()
                        .catch(err => logger.error(err, "[FirebaseAuthProvider] failed to ensure signed into all instances"));

                    // No need to wait for this
                    const userRef = db("users").child(user.uid);
                    userRef.child("id").once("value")
                        .then(snap => snap.exists())
                        .then(userRecordIdExists => {
                            if (userRecordIdExists) {
                                return userRef.child("lastSignedInAt").set(Date.now());
                            }
                        })
                        .catch(err => logger.error(err, "[FirebaseAuthProvider] failed to update user model"));
                }

                this.setState({ initialized: true });
            })();
        });
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    }

    render() {
        const { children } = this.props;
        const { initialized } = this.state;

        return (
            <FirebaseAuthContext.Provider
                value={initialized ? this.auth : null}
            >
                {children}
            </FirebaseAuthContext.Provider>
        );
    }
}

/**
 * Workaround for ad-hoc ReactDOM.render trees within Backbone views,
 * which are unable to access the context tree. You *must* ensure that
 * there's an initalized FirebaseAuthProvider somewhere above this.
 */
export function UNSAFE_FirebaseAuthProvider({ children }) {
    return (
        <FirebaseAuthContext.Provider value={fbAuth()}>
            {children}
        </FirebaseAuthContext.Provider>
    );
}

/**
 * HOC which injects `auth()` instance as `auth` prop. Very rarely needed!
 */
export function withFirebaseAuth(Component) {
    // eslint-disable-next-line react/display-name
    return React.forwardRef((props, ref) => (
        <FirebaseAuthContext.Consumer>
            {auth => <Component {...props} ref={ref} auth={auth} />}
        </FirebaseAuthContext.Consumer>
    ));
}
