// Given updates left, right, find one or more new updates left' such that
// left followed by right is the same as right followed by left'
function commuteUpdate(left, right) {
    if (right.command === "comment" || left.command === "comment") return [left];
    if (right.command === "destroy") return [];
    if (right.command === "restore") return [];
    if (left.command === "destroy") return [left];
    if (left.command === "restore") return [left, right];

    let lp = left.path;
    let rp = right.path;

    let ii = 0;
    while (ii < lp.length && ii < rp.length && lp[ii] === rp[ii]) ii++;
    if (ii < lp.length && ii < rp.length) {
        // paths diverge - the two commands can't conflict
        return [left];
    }

    let leftKey = lp.length > rp.length ? lp[rp.length] : left.key;
    let rightKey = lp.length < rp.length ? rp[lp.length] : right.key;
    if (leftKey !== rightKey) {
        // Two insertions before the same key - reorder so that the earlier (left) item is inserted before the later (right) key.
        if (lp.length === rp.length && left.command === "insertBefore" && right.command === "insertBefore" && left.before === right.before) {
            return [{ path: left.path, id: left.id, command: "insertBefore", key: left.key, before: right.key }];
        }

        // keys differ - the two commands can't conflict
        return [left];
    }

    if (left.command === "insertBefore") {
        if (lp.length === rp.length && right.command === "delete") {
            return [left, right];
        } else {
            return [left];
        }
    }

    if (lp.length > rp.length) {
        // left path is longer, so right path is overwriting an object/array as a simple value
        return [];
    }

    if (left.command === "delete") {
        // double-delete
        if (right.command === "delete" && lp.length === rp.length) return [];

        // here we have the left side deleting an array item that is
        // further edited by right side.  We propagate the delete even
        // though it potentially gets rid of some of right's work,
        // because the "resurrected" array item would lose all its
        // other keys, leaving a probably broken item in the array.
        return [left];
    }

    if (rp.length > lp.length) {
        // the right path is longer, so left path overwrites an
        // object/array to a simple value, and then right
        // un-overwrites it.

        // This change can't be computed incrementally, we have to
        // delete everything in the object and then rebuild it using
        // all relevant operations after the left op. So we retain two
        // commands in this case.
        return [left, right];
    }

    // if we've reached this point, then both commands are operating at the same path and key.

    // Now left is 'set', and right is 'set' or 'delete'. So left is completely overwritten.
    return [];
}

// Commute a list of left updates against a list of right updates
function commute(leftUpdates, rightUpdates) {
    for (let right of rightUpdates) {
        leftUpdates = leftUpdates.map(it => commuteUpdate(it, right)).reduce((l, r) => l.concat(r), []);
    }
    return leftUpdates;
}

module.exports = commute;
