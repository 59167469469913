import React, { Component } from "reactn";
import { getGlobal, setGlobal } from "reactn";
import { SearchAssets } from "js/core/utilities/searchAssets";
import { SearchInput } from "js/react/components";
import Api from "js/core/api";
import {
    UIContainerContext,
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "js/react/components/UiComponents";
import LogoPreview from "js/react/views/AddAssets/Components/LogoPreview";
import ImageThumbnailGrid from "js/react/views/AddAssets/Components/ImageThumbnailGrid";
import Loadable from "js/react/components/Loadable";

export class LogosPane extends Component {
    static contextType = UIContainerContext;

    state = {
        initialLoad: true,
        searchResults: { results: [] },
        searchAssetsUtil: new SearchAssets(),
        stockCategory: null,
        isLoading: true
    };

    componentDidMount = async () => {
        const {
            prefilledSearchQuery,
            onPrefilledSearchQueryComplete
        } = this.props;
        const prefill = prefilledSearchQuery;
        this.setState({ autoSearch: prefill });
        prefill && (await this.doImageSearch(prefill));
        onPrefilledSearchQueryComplete();
    };

    componentDidUpdate = async prevProps => {
        if (
            this.props.prefilledSearchQuery &&
            prevProps.prefilledSearchQuery !== this.props.prefilledSearchQuery
        ) {
            this.setState({ autoSearch: this.props.prefilledSearchQuery });
            await this.doImageSearch(this.props.prefilledSearchQuery);
        }
    };

    doImageSearch = async searchTerm => {
        this.setState({ isLoading: true, searchResults: { results: [] } });
        const results = await Api.searchWebImages.get({ q: searchTerm, type: "logo" });
        const urls = results.map(result => result.url);
        this.setState({
            isLoading: false,
            searchResults: {
                query: searchTerm,
                results: urls,
            },
        });
    };

    render() {
        const { isLoading, autoSearch, searchResults } = this.state;
        const {
            resetPreviouslyViewedState,
            addAssetCallback,
            handleConfirm
        } = this.props;
        const inputFocused = !this.context;

        return (
            <UIPane>
                <UIPaneHeader>
                    <SearchInput
                        handleSubmit={searchTerm =>
                            this.doImageSearch(searchTerm)
                        }
                        placeholder="Search for logos..."
                        handleClearSearch={() => {
                            resetPreviouslyViewedState();
                            this.setState({
                                searchResults: null,
                                stockCategory: ""
                            });
                        }}
                        focus={inputFocused}
                        prefilledSearch={autoSearch}
                    />
                </UIPaneHeader>
                <UIPaneContents style={{ paddingTop: 20 }}>
                    <Loadable isLoading={isLoading}>
                        <ImageThumbnailGrid
                            columns={4}
                        >
                            {searchResults.results.map((dataUri, i) => {
                                return (
                                    <LogoPreview
                                        addAssetCallback={addAssetCallback}
                                        handleConfirm={handleConfirm}
                                        src={dataUri}
                                        name={`${searchResults.query}-${i}`}
                                        key={`${searchResults.query}-${i}`}
                                    />
                                );
                            })}
                        </ImageThumbnailGrid>
                    </Loadable>
                </UIPaneContents>
            </UIPane>
        );
    }
}

export default LogosPane;
