const theme = {
    isBuiltIn: true,
    id: "magazine",
    name: "Magazine",
    showLogo: true,
    logoScale: 1,
    logoPosition: "left",
    logoOffset: 0,
    showMessage: true,
    showPageNum: true,
    logo: null,
    logo_dark: null,
    showFooterByDefault: null,
    palette_name: "magazine",
    defaultSlideColor: "theme",
    defaultBackgroundColor: "background_light",
    styleFonts: 1,
    styleFontWeight: "heavy",
    styleElementStyle: "muted",
    styleEffect: "none",
    styleDesign: 1,
    styleDecoration: "bar_left",
    styleHeaderAlignment: "left",
    styleShape: "none",
    styleColor: "slide",
    styleBackground: "none",
    styleTitleFont: "sourcesanspro",
    styleBodyFont: "sourcesanspro",
    styleWeight: "light",
    styleTitleSlide: "bar_left",
    fontScale: 1,
    iconStyle: "classic",
    cjkFont: "jp",
    fontHeaderFontId: "playfairdisplay",
    fontHeaderWeight: 400,
    fontHeaderLetterSpacing: 0,
    fontHeaderLineHeight: 1.5,
    fontHeaderScaling: 100,
    fontHeaderTextTransform: "auto",
    fontTitleFontId: "roboto",
    fontTitleWeight: 700,
    fontTitleLetterSpacing: 0.1,
    fontTitleLineHeight: 1.9,
    fontTitleScaling: 70,
    fontTitleTextTransform: "uppercase",
    fontBodyFontId: "roboto",
    fontBodyWeight: 400,
    fontBodyLetterSpacing: 0.03,
    fontBodyLineHeight: 2.2,
    fontBodyScaling: 85,
    fontBodyTextTransform: "auto",
};

module.exports = theme;
