const _ = require("lodash");
const { htmlToText } = require("html-to-text");

function getSlideText(elements) {
    function getText(key, value, items) {
        if (key === "text" && typeof value === "string") {
            items.push(value.replace(/\r/g, " "));
        } else if (key === "wordcloud") {
            items.push(JSON.parse(value).map(w => w.word).join(" | "));
        } else if (key === "html") {
            const text = htmlToText(value, {
                wordwrap: false
            });
            items.push(text);
        } else if (Array.isArray(value)) {
            value.forEach(item => {
                getText(key, item, items);
            });
        } else if (_.isObject(value)) {
            for (const subKey in value) {
                const subValue = value[subKey];
                getText(subKey, subValue, items);
            }
        }
    }

    const items = [];

    for (const key in elements) {
        const value = elements[key];
        getText(key, value, items);
    }

    return _.chain(items).compact().uniq().value().join(" | ");
}

module.exports = getSlideText;
