import React, { Component } from "react";
import Icon from "js/react/components/Icon";

export class RealTimeSearchInput extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount = () => {
        const { focus } = this.props;

        if (focus) {
            setTimeout(() => {
                this.inputRef.current.focus();
                this.inputRef.current.select();
            }, 0);
        }
    };

    componentDidUpdate = prevProps => {
        if (prevProps.focus !== this.props.focus) {
            this.props.focus && this.inputRef.current.focus();
        }
    };

    handleTextChange = e => {
        this.handleSearch(e.currentTarget.value);
    };

    handleSearch = async rawInput => {
        const { search, onSearch } = this.props;
        const { results, query } = await search(rawInput);
        onSearch(rawInput, results, query);
    };

    clearSearch = () => {
        this.props.onSearch(null, null, null);
    };

    render() {
        const { searchInput, placeholder } = this.props;

        return (
            <div className="searchbar">
                <i className="micon">search</i>
                <input
                    onChange={e => {
                        this.handleTextChange(e);
                    }}
                    ref={this.inputRef}
                    spellCheck="false"
                    type="text"
                    placeholder={placeholder}
                    value={searchInput || ""}
                />
                {searchInput && <Icon className="clear_search" iconName="cancel" onClick={this.clearSearch} />}
            </div>
        );
    }
}

export default RealTimeSearchInput;
