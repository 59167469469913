import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";

async function withProgressDialog(title: string, message: string, action: () => Promise<void>) {
    const dialog = ShowDialog(ProgressDialog, {
        title,
        message,
    });
    try {
        await action();
    } finally {
        dialog.props.closeDialog();
    }
}

export default withProgressDialog;
