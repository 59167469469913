import Api from "js/core/api";
import { loadImage } from "js/core/utilities/promiseHelper";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.ASSETS);

class Logos {
    static async getSignedUrlAndLoad(id, reloadCache = false) {
        if (window.isOfflinePlayer) {
            const url = `/userData/logos/${id}`;
            await loadImage(url);
            return url;
        }

        const { url } = await Api.logos.get({ id }, reloadCache);
        try {
            await loadImage(url);
        } catch (err) {
            if (reloadCache) {
                throw err;
            }

            logger.info(`getSignedUrlAndLoad() failed to load logo, fetching a new url without cache and trying again`, { id });
            return Logos.getSignedUrlAndLoad(id, true);
        }
        return url;
    }

    static async getWriteSignedUrl(id) {
        const { url } = await Api.logos.get({ id, action: "write" }, true);
        return url;
    }
}

module.exports = Logos;
