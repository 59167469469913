const { simplifyNulls } = require("./utils");

// given a command (the output of diff), apply it to the model
//
// Warning: modifies its argument
function applyCommand(model, command) {
    if (command.command === "destroy") {
        return null;
    } else if (command.command === "restore") {
        return {};
    } else if (command.command === "comment") {
        return model;
    } else if (!model) {
        return model;
    }

    let modelAtPath = model;
    for (let ii = 0; ii < command.path.length; ii++) {
        let key = command.path[ii];

        if (!modelAtPath.hasOwnProperty(key) && (modelAtPath.__array__ || []).indexOf(key) > -1) {
            // deleted array element
            return model;
        }

        if (modelAtPath[key] == null || typeof modelAtPath[key] !== "object") {
            modelAtPath[key] = {};
        }
        modelAtPath = modelAtPath[key];
    }

    if (command.command === "insertBefore") {
        let beforeIndex = command.before ? (modelAtPath.__array__ || []).indexOf(command.before) : (modelAtPath.__array__ || []).length;
        if (beforeIndex === -1) {
            console.error("Attempting to insert before nonexistent key", command.before);
            return model;
        }
        if (!modelAtPath.__array__) {
            modelAtPath.__array__ = [];
        }
        modelAtPath.__array__.splice(beforeIndex, 0, command.key);
        if (!(command.key in modelAtPath)) {
            modelAtPath[command.key] = {};
        }
    } else if (command.command === "delete") {
        delete modelAtPath[command.key];
    } else if (command.command === "set") {
        if (!modelAtPath.hasOwnProperty(command.key) && (modelAtPath.__array__ || []).indexOf(command.key) > -1) {
            return model;
        }
        modelAtPath[command.key] = command.value;
    }

    return simplifyNulls(model, false);
}

// apply commands in sequence to model
function applyCommands(model, commands) {
    return commands.reduce(applyCommand, model);
}

module.exports = applyCommands;
