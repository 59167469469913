import React, { useState, useRef } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { Select, MenuItem, Icon, TextField, Button, Divider } from "@material-ui/core";

import getObjectHash from "common/utils/getObjectHash";
import { themeColors } from "js/react/sharedStyles";
import { AiGeneratedSlideType } from "common/aiConstants";
import * as geom from "js/core/utilities/geom";
import { formatter } from "js/core/utilities/formatter";
// import { ChooseThemeDialog } from "js/editor/dialogs/chooseThemeDialog";
import AppController from "js/core/AppController";
import { FeatureType } from "js/core/models/features";

import { PaneContent, PaneFooter } from "../../../components/PaneComponents";

import GeneratePresentationController, { GeneratePresentationState, SlideOutline } from "../controllers/GeneratePresentationController";

const SLIDE_OUTLINE_HEIGHT_PX = 44;
const SLIDE_OUTLINE_GAP_PX = 10;
const SLIDE_OUTLINE_TRANSITION_DURATION_MS = 500;

const MAX_SLIDE_OUTLINE_COUNT = 15;
const MIN_SLIDE_OUTLINE_COUNT = 2;

const Container = styled.div`
    padding: 0 50px;
    width: 100%;

    display: flex;
    flex-direction: column;
`;

const HeaderContainer = styled.div`
    margin: 30px 0;

    >div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;

        >span.MuiIcon-root {
            margin-right: 10px;
            color: ${themeColors.ui_blue};
        }

        font-weight: 600;
        font-size: 23px;
        line-height: 120%;
        letter-spacing: 0.5px;
        color: ${themeColors.darkGray};
    }

    >div:last-child {
        margin-top: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        color: ${themeColors.mediumGray};
    }
`;

const SlideOutlinesHeadersContainer = styled.div`
    width: 100%;
    margin-bottom: 15px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    >div {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: ${themeColors.mediumGray};
    }

    >div:first-child {
        margin-left: 10px;
    }

    >div:last-child {
        width: 200px;
    }
`;

const SlideOutlinesContainer = styled.div`
    position: relative;
    width: 100%;
`;

const SlideOutlineViewsContainer = styled.div.attrs<{ height: number }>(({ height }) => ({
    style: {
        height: `${height}px`
    }
})) <{ height: number }>`
    position: relative;
    width: 100%;
`;

const SlideOutlineContainer = styled.div.attrs<{ bounds: geom.Rect, isBeingDragged: boolean, shouldTransition: boolean }>(({ bounds, isBeingDragged, shouldTransition }) => ({
    style: {
        transform: `translate(${bounds.left}px, ${bounds.top}px)`,
        boxShadow: isBeingDragged ? "rgba(0, 0, 0, 0.1) 1px 2px 12px 3px" : "1px 2px 5px rgba(0, 0, 0, 0.1)",
        transitionProperty: isBeingDragged ? "box-shadow" : "transform, box-shadow",
        transitionDuration: shouldTransition ? `${SLIDE_OUTLINE_TRANSITION_DURATION_MS}ms` : "0ms",
        zIndex: isBeingDragged ? 10 : undefined
    }
})) <{ bounds: geom.Rect, isBeingDragged: boolean, shouldTransition: boolean }>`
    position: absolute;
    left: 0;
    top: 0;

    transition-timing-function: ease-in-out;

    width: 100%;
    height: ${SLIDE_OUTLINE_HEIGHT_PX}px;
    padding: 0 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background: #FFFFFF;
    border-radius: 3px;

    >div.MuiInputBase-root {
        width: 200px;
    }
`;

const SlideOutlineDragHandleContainer = styled.div.attrs<{ visible: boolean }>(({ visible }) => ({
    style: {
        opacity: visible ? 1 : 0
    }
})) <{ visible: boolean }>`
    position: absolute;
    width: 35px;
    height: ${SLIDE_OUTLINE_HEIGHT_PX}px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-30px, -50%);
    left: -4px;
    top: 50%;
    color: ${themeColors.ui_blue};
    cursor: pointer;
`;

const SlideOutlineRemoveButtonContainer = styled.div.attrs<{ visible: boolean }>(({ visible }) => ({
    style: {
        opacity: visible ? 1 : 0
    }
})) <{ visible: boolean }>`
    position: absolute;
    left: 100%;
    top: 50%;
    width: 40px;
    height: ${SLIDE_OUTLINE_HEIGHT_PX}px;

    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0, -50%);
    color: ${themeColors.ui_blue};
    cursor: pointer;
`;

const SlideOutlinePromptInput = styled(TextField)`
    &&& {
        width: calc(100% - 160px);

        input {
            font-weight: 600;
            font-size: 14px;
            line-height: 125%;       
            color: ${themeColors.darkGray};
        }
    }
`;

const AddSlideOutlineButton = styled(Button)`
    &&& {
        width: 120px;
        margin-top: 15px;

        span.MuiIcon-root {
            color: white;
            background: ${themeColors.ui_blue};
            border-radius: 50%;
        }
    }
`;

const ThemeSelectorContainer = styled.div`
    margin-top: 40px;

    display: flex;
    flex-direction: column;

    >div:first-child {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: ${themeColors.mediumGray};
    }

    >div.MuiInputBase-root {
        width: 300px;
        margin-top: 15px;

        >fieldset {
            border-color: ${themeColors.lightGray};
        }

        >div.MuiSelect-root {
            text-transform: none;
        }
    }
`;

const ChooseThemeDialogContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    background: ${themeColors.lightGray};    
`;

const ChooseThemeDialogWrapper = styled.div`
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    background: white;
    border-radius: 4px;
    width: 760px;
    max-height: 80%;
    height: 100%;

    div.dialog-content {
        height: calc(100% - 178px);
    }
`;

interface SlideOutlineViewProps {
    slideOutline: SlideOutline;
    onChange: (slideOutline: SlideOutline) => void;
    onDelete: (slideOutline: SlideOutline) => void;
    canDelete: boolean;
    onStartDrag: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    isBeingDragged: boolean;
    isOtherOutlineBeingDragged: boolean;
}

const sortedSlideTypes = Object.values(AiGeneratedSlideType).sort((a, b) => a.localeCompare(b));

const SlideOutlineView = React.memo<SlideOutlineViewProps>(
    function SlideOutlineView({
        onChange,
        onDelete,
        canDelete,
        onStartDrag,
        slideOutline,
        isBeingDragged,
        isOtherOutlineBeingDragged
    }: SlideOutlineViewProps) {
        const [isHoveringOver, setHoveringOver] = useState<boolean>(false);

        const handleSlideOutlineTypeChange = (event: React.ChangeEvent<{ value: AiGeneratedSlideType | "" }>) => {
            onChange({ ...slideOutline, type: event.target.value });
        };

        const handleChangePrompt = (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...slideOutline, prompt: event.target.value });
        };

        const handleDeleteSlideOutline = () => {
            onDelete(slideOutline);
        };

        const isActive = !isOtherOutlineBeingDragged && (isBeingDragged || isHoveringOver);

        return (
            <SlideOutlineContainer
                bounds={slideOutline.dragBounds ?? slideOutline.bounds}
                isBeingDragged={isBeingDragged}
                shouldTransition={isBeingDragged || isOtherOutlineBeingDragged}
                onMouseEnter={() => setHoveringOver(true)}
                onMouseLeave={() => setHoveringOver(false)}
            >
                <SlideOutlineDragHandleContainer
                    visible={isActive}
                    onMouseDown={onStartDrag}
                >
                    <Icon>drag_indicator</Icon>
                </SlideOutlineDragHandleContainer>

                <SlideOutlinePromptInput
                    value={slideOutline.prompt ?? `${slideOutline.title}: ${slideOutline.summary}`}
                    onChange={handleChangePrompt}
                    InputProps={{ disableUnderline: true }}
                    placeholder="Describe the topic of your slide..."
                    disabled={isBeingDragged}
                />

                <Select
                    value={slideOutline.type}
                    onChange={handleSlideOutlineTypeChange}
                    disableUnderline
                    displayEmpty
                >
                    <MenuItem value={""}>
                        Auto
                    </MenuItem>
                    <Divider />
                    {sortedSlideTypes.map((type, idx) => (
                        <MenuItem key={idx} value={type}>
                            {` ${type}`.replace(/[\s\-](\w)/g, x => ` ${x[1].toUpperCase()}`).trim()}
                        </MenuItem>
                    ))}
                </Select>

                {canDelete && <SlideOutlineRemoveButtonContainer
                    visible={isActive}
                    onClick={handleDeleteSlideOutline}
                >
                    <Icon>close</Icon>
                </SlideOutlineRemoveButtonContainer>}
            </SlideOutlineContainer>
        );
    },
    (prevProps, nextProps) => getObjectHash(prevProps) === getObjectHash(nextProps)
);

interface SlideOutlinesViewState {
    startDragPoint: geom.Point;
    draggedSlideOutlineId: string;
    originalScrollElementScrollTop: number;
}

const SlideOutlinesView = GeneratePresentationController.withState(
    class SlideOutlinesView extends React.Component<GeneratePresentationState & { scrollElementRef: React.RefObject<HTMLDivElement> }, SlideOutlinesViewState> {
        dragHandledAt: number;

        constructor(props) {
            super(props);

            this.state = {
                startDragPoint: null,
                draggedSlideOutlineId: null,
                originalScrollElementScrollTop: null
            };

            this.dragHandledAt = 0;
        }

        componentDidMount() {
            setTimeout(() => {
                const { slideOutlines: rawSlideOutlines } = this.props;

                // Initialize slide outline bounds
                // and store them in local state
                const slideOutlines: SlideOutline[] = rawSlideOutlines.map((slideOutline, idx) => ({
                    ...slideOutline,
                    bounds: new geom.Rect(0, idx * (SLIDE_OUTLINE_HEIGHT_PX + SLIDE_OUTLINE_GAP_PX), 1000, SLIDE_OUTLINE_HEIGHT_PX)
                }));
                GeneratePresentationController.setSlideOutlines(slideOutlines);
            }, 50);
        }

        handleStartDrag = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, slideOutlineId: string) => {
            const { scrollElementRef } = this.props;

            this.setState({
                draggedSlideOutlineId: slideOutlineId,
                startDragPoint: new geom.Point(event.clientX, event.clientY),
                originalScrollElementScrollTop: scrollElementRef.current.scrollTop
            });

            window.addEventListener("mousemove", this.handleDrag);
            window.addEventListener("mouseup", this.handleEndDrag);
        }

        handleDrag = (event: MouseEvent) => {
            window.requestAnimationFrame(ts => {
                if (this.dragHandledAt === ts) {
                    return;
                }
                this.dragHandledAt = ts;

                const { slideOutlines: oldSlideOutlines, scrollElementRef } = this.props;
                const { draggedSlideOutlineId, startDragPoint, originalScrollElementScrollTop } = this.state;
                if (!draggedSlideOutlineId) {
                    return;
                }

                const offset = new geom.Point(event.clientX, event.clientY).delta(startDragPoint);

                // Check if there is a scrollbar
                const hasVerticalScrollbar = scrollElementRef.current.scrollHeight > scrollElementRef.current.clientHeight;
                if (hasVerticalScrollbar) {
                    const newScrollTop = originalScrollElementScrollTop - offset.y;
                    if (newScrollTop > 0) {
                        scrollElementRef.current.scrollTop = newScrollTop;
                        offset.y *= 2;
                    }
                }

                // Detach references to avoid mutating state and remove drag bounds
                const slideOutlines = oldSlideOutlines.map(slideOutline => ({ ...slideOutline, dragBounds: undefined }));

                // Find dragged outline
                const draggedOutlineIndex = slideOutlines.findIndex(slideOutline => slideOutline.id === draggedSlideOutlineId);
                const draggedOutline = slideOutlines[draggedOutlineIndex];
                draggedOutline.dragBounds = draggedOutline.bounds.offset(-offset.x, -offset.y);

                // Find intersecting bounds
                const intersectingOutlineIndex = slideOutlines.findIndex((slideOutline, idx) => {
                    if (idx === draggedOutlineIndex) {
                        return false;
                    }

                    return draggedOutline.dragBounds.intersects(slideOutline.bounds) &&
                        draggedOutline.dragBounds.intersection(slideOutline.bounds).height > SLIDE_OUTLINE_HEIGHT_PX / 3;
                });

                // Nothing to swap with
                if (intersectingOutlineIndex === -1) {
                    GeneratePresentationController.setSlideOutlines(slideOutlines);
                    return;
                }

                // Swap dragged outline with intersecting outline
                const intersectingOutline = slideOutlines[intersectingOutlineIndex];
                slideOutlines[intersectingOutlineIndex] = draggedOutline;
                slideOutlines[draggedOutlineIndex] = intersectingOutline;

                // Now recalculating bounds
                slideOutlines.forEach((slideOutline, idx) => {
                    if (slideOutline.id === draggedSlideOutlineId) {
                        // Keep the dragged outline bounds
                        return;
                    }
                    slideOutline.bounds = new geom.Rect(0, idx * (SLIDE_OUTLINE_HEIGHT_PX + SLIDE_OUTLINE_GAP_PX), 1000, SLIDE_OUTLINE_HEIGHT_PX);
                });
                GeneratePresentationController.setSlideOutlines(slideOutlines);
            });
        }

        handleEndDrag = () => {
            const { slideOutlines: oldSlideOutlines } = this.props;
            const { draggedSlideOutlineId } = this.state;

            window.removeEventListener("mousemove", this.handleDrag);
            window.removeEventListener("mouseup", this.handleEndDrag);

            // Reset drag state
            this.setState({ draggedSlideOutlineId: null, startDragPoint: null, originalScrollElementScrollTop: null });

            // Move dragged outline to its new position
            const slideOutlines = oldSlideOutlines.map((slideOutline, idx) => {
                if (slideOutline.id === draggedSlideOutlineId) {
                    slideOutline.bounds = new geom.Rect(0, idx * (SLIDE_OUTLINE_HEIGHT_PX + SLIDE_OUTLINE_GAP_PX), 1000, SLIDE_OUTLINE_HEIGHT_PX);
                }
                return { ...slideOutline, dragBounds: undefined };
            });
            GeneratePresentationController.setSlideOutlines(slideOutlines);
        }

        handleSlideOutlineChange = (updatedSlideOutline: SlideOutline) => {
            const { slideOutlines: oldSlideOutlines } = this.props;

            const slideOutlines = oldSlideOutlines.map(slideOutline => {
                if (slideOutline.id === updatedSlideOutline.id) {
                    return { ...updatedSlideOutline };
                }
                return { ...slideOutline };
            });
            GeneratePresentationController.setSlideOutlines(slideOutlines);
        }

        handleSlideOutlineDelete = (deletedSlideOutline: SlideOutline) => {
            const { slideOutlines: oldSlideOutlines } = this.props;

            const slideOutlines = oldSlideOutlines
                .filter(slideOutline => slideOutline.id !== deletedSlideOutline.id)
                .map((slideOutline, idx) => ({
                    ...slideOutline,
                    // Reset bounds
                    bounds: new geom.Rect(0, idx * (SLIDE_OUTLINE_HEIGHT_PX + SLIDE_OUTLINE_GAP_PX), 1000, SLIDE_OUTLINE_HEIGHT_PX)
                }));
            GeneratePresentationController.setSlideOutlines(slideOutlines);
        }

        handleAddSlideOutline = () => {
            const { slideOutlines: oldSlideOutlines } = this.props;

            const slideOutlines: SlideOutline[] = [...oldSlideOutlines, {
                id: uuid(),
                bounds: new geom.Rect(0, oldSlideOutlines.length * (SLIDE_OUTLINE_HEIGHT_PX + SLIDE_OUTLINE_GAP_PX), 1000, SLIDE_OUTLINE_HEIGHT_PX),
                prompt: "",
                title: "",
                summary: "",
                type: ""
            }];

            GeneratePresentationController.setSlideOutlines(slideOutlines);
        }

        handleShowChooseThemeDialog = () => {
            GeneratePresentationController.setShowChooseThemeDialog(true);
        }

        render() {
            const { slideOutlines, theme } = this.props;
            const { draggedSlideOutlineId } = this.state;

            const sortedSlideOutlines = [...slideOutlines].sort((a, b) => a.id.localeCompare(b.id)).filter(slideOutline => !!slideOutline.bounds);
            const height = slideOutlines.length * (SLIDE_OUTLINE_HEIGHT_PX + SLIDE_OUTLINE_GAP_PX) - SLIDE_OUTLINE_GAP_PX;

            return (<SlideOutlinesContainer>
                <SlideOutlineViewsContainer height={height}>
                    {sortedSlideOutlines.map(slideOutline => (
                        <SlideOutlineView
                            key={slideOutline.id}
                            slideOutline={slideOutline}
                            isBeingDragged={slideOutline.id === draggedSlideOutlineId}
                            isOtherOutlineBeingDragged={draggedSlideOutlineId && slideOutline.id !== draggedSlideOutlineId}
                            onStartDrag={event => this.handleStartDrag(event, slideOutline.id)}
                            onChange={this.handleSlideOutlineChange}
                            onDelete={this.handleSlideOutlineDelete}
                            canDelete={slideOutlines.length > MIN_SLIDE_OUTLINE_COUNT}
                        />))}
                </SlideOutlineViewsContainer>
                {slideOutlines.length < MAX_SLIDE_OUTLINE_COUNT &&
                    <AddSlideOutlineButton onClick={this.handleAddSlideOutline}>
                        <Icon>add</Icon>
                        add slide
                    </AddSlideOutlineButton>
                }
                <ThemeSelectorContainer>
                    <div>Presentation Theme</div>
                    <Select
                        value={theme.id}
                        variant="outlined"
                        open={false}
                        onClick={this.handleShowChooseThemeDialog}
                    >
                        <MenuItem value={theme.id}>
                            {theme.get("name") ?? formatter.capitalizeFirstLetter(theme.id)}
                        </MenuItem>
                    </Select>
                </ThemeSelectorContainer>
            </SlideOutlinesContainer>);
        }
    }
);

const EditingOutlineView = AppController.withState(GeneratePresentationController.withState(
    function EditingOutlineView(props: GeneratePresentationState & { user: any, workspaceId: string }) {
        const { slideOutlines, showChooseThemeDialog, user, workspaceId, aiCreditsBalance } = props;

        const scrollElementRef = useRef<HTMLDivElement>(null);

        const handleStartGeneration = () => {
            GeneratePresentationController.generatePresentationFromOutline();
        };

        const handleGoBack = () => {
            GeneratePresentationController.showPrompt();
        };

        const handleCloseChooseThemeDialog = () => {
            GeneratePresentationController.setShowChooseThemeDialog(false);
        };

        const handleThemeSelected = theme => {
            if (theme) {
                GeneratePresentationController.setTheme(theme);
            }
        };

        const allowOnlyTeamThemes = AppController.currentTeam ? !user?.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_CUSTOM_THEMES, workspaceId) : false;
        const canStartGeneration = slideOutlines.every(slideOutline => typeof slideOutline.prompt === "string" ? slideOutline.prompt.length > 0 : (slideOutline.title && slideOutline.summary));

        return (<>
            <PaneContent ref={scrollElementRef}>
                <Container>
                    <HeaderContainer>
                        <div><Icon>edit</Icon> Presentation Outline</div>
                        <div>You may choose to reorder, edit content or switch templates before generating slides.</div>
                    </HeaderContainer>

                    <SlideOutlinesHeadersContainer>
                        <div>Slide Topic</div>
                        <div>Template</div>
                    </SlideOutlinesHeadersContainer>

                    <SlideOutlinesView scrollElementRef={scrollElementRef} />
                </Container>
            </PaneContent>
            <PaneFooter
                actionButtonDisabled={!canStartGeneration}
                actionButtonText="Generate Slides"
                onActionButtonClick={handleStartGeneration}
                showBackButton={true}
                onBackButtonClick={handleGoBack}
                aiCreditsBalance={aiCreditsBalance}
            />
        </>);
    }
));

export default EditingOutlineView;
