import React, { Component } from "react";
import styled from "styled-components";
import { LinearProgress, Icon } from "@material-ui/core";

import { app } from "js/namespaces";
import { FeatureType } from "common/features";
import { JSZip } from "js/vendor";
import { ds } from "js/core/models/dataService";
import getLogger, { LogGroup } from "js/core/logger";
import { TaskState, TaskType, PPTImportWarningType } from "common/constants";
import { Theme } from "js/core/models/theme";
import { FlexBox } from "js/react/components/LayoutGrid";
import { Gap10, Gap30 } from "js/react/components/Gap";
import {
    BlueButton,
    UIPane,
    UIPaneContents
} from "js/react/components/UiComponents";
import { UIController } from "js/editor/dialogs/UIController";
import { ShowErrorDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { uploadFileAndCreateTask } from "js/core/services/tasks";
import { trackActivity } from "js/core/utilities/utilities";
import getPPTTheme from "js/core/utilities/getPPTTheme";
import { ChooseThemeDialog } from "js/editor/ThemeEditor/ChooseThemeDialog";
import { UploadFontsDialog } from "js/react/components/Dialogs/UploadFontsDialog";

const logger = getLogger(LogGroup.PPT_IMPORT);

const InnerPane = styled.div`
    padding: 30px 50px;
    background: white;
    border-radius: 4px;
    width: 820px;

    h1 {
        text-transform: none !important;
        font-size: 22px !important;
        margin: 0 !important;
    }

    p {
        font-size: 15px;
        opacity: 0.8;
    }
`;

const UploadFileInput = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
`;

const CancelButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ChooseThemeDialogWrapper = styled.div`
    position: relative;
    background: white;
    border-radius: 4px;
    width: 760px;
    max-height: 80%;
    height: 100%;
`;

const PPTImportStep = {
    SELECTING_FILE: "selecting-file",
    PARSING_PPT_THEME: "parsing-ppt-theme",
    SELECTING_THEME: "selecting-theme",
    UPLOADING_FONTS: "uploading-fonts",
    IMPORTING_SLIDES: "importing-slides"
};

export class ImportPresentationFromPPTPane extends Component {
    constructor(props) {
        super(props);

        const organizationId = UIController.getOrganizationId();

        let allowOnlyTeamThemes = false;
        if (organizationId) {
            const defaultTeam = ds.teams.defaultTeamForOrg(organizationId);
            if (defaultTeam) {
                allowOnlyTeamThemes = !app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_CUSTOM_THEMES, organizationId);
            }
        }

        this.initialState = {
            currentStep: PPTImportStep.SELECTING_FILE,
            currentStepProgress: 0,
            currentStepMessage: null,
            organizationId,
            allowOnlyTeamThemes
        };

        this.state = { ...this.initialState };

        this.selectedFile = null;
        this.pptThemeModel = null;
        this.selectedThemeModel = null;

        this.isCurrentTaskCancelled = false;
        this.cancelCurrentTask = null;
    }

    componentWillUnmount() {
        if (this.cancelCurrentTask) {
            this.cancelCurrentTask();
        }
    }

    handleError(err) {
        logger.error(err, "ImportPresentationFromPPTPane handleError()");
        ShowErrorDialog({ title: "Error", message: "Sorry, we could not process your file, try another one or contact support at support@beautiful.ai" });
        this.setState({ ...this.initialState });
    }

    handleSelectFile = async file => {
        const { allowOnlyTeamThemes } = this.state;

        if (!file) {
            return;
        }

        const maxFileSizeMB = 100;
        if (file.size > maxFileSizeMB * 1024 * 1024) {
            ShowErrorDialog({ title: `File is bigger than ${maxFileSizeMB}MB`, message: "Please select another presentation or split this one into separate files of smaller sizes" });
            return;
        }

        this.selectedFile = file;
        this.setState({ currentStep: PPTImportStep.PARSING_PPT_THEME });

        if (allowOnlyTeamThemes) {
            // No need to parse ppt theme
            this.pptThemeModel = null;
            this.setState({ currentStep: PPTImportStep.SELECTING_THEME });
            return;
        }

        try {
            const zip = await JSZip.loadAsync(this.selectedFile);
            this.pptThemeModel = await getPPTTheme(zip);
            this.setState({ currentStep: PPTImportStep.SELECTING_THEME });
        } catch (err) {
            this.handleError(err);
        }
    }

    handleThemeSelected = async theme => {
        this.selectedThemeModel = theme?.attributes || theme;

        if (this.selectedThemeModel.needsFontsMap) {
            this.setState({ currentStep: PPTImportStep.UPLOADING_FONTS });
            return;
        }

        trackActivity("Presentation", "Start", null, null, { source: "ppt-template", theme_id: this.selectedThemeModel.id }, { audit: false });

        this.handleImportSlides();
    }

    handleImportSlides = () => {
        const { onCreate } = this.props;
        const { organizationId } = this.state;

        this.setState({ currentStep: PPTImportStep.IMPORTING_SLIDES, currentStepProgress: 0 });

        this.isCurrentTaskCancelled = false;
        uploadFileAndCreateTask(
            this.selectedFile,
            TaskType.PPT_IMPORT,
            task => {
                if (this.isCurrentTaskCancelled) {
                    // Ignoring the change because we requested the task to stop
                    return;
                }

                switch (task.state) {
                    case TaskState.ERROR:
                        this.cancelCurrentTask = null;
                        return this.handleError(task.errorMessage);
                    case TaskState.PREPARING:
                        return this.setState({ currentStepProgress: 10 });
                    case TaskState.PROCESSING:
                        return this.setState({ currentStepProgress: task.stateProgressPercents / 10 * 9 + 10 });
                    case TaskState.FINISHED:
                        this.cancelCurrentTask = null;
                        trackActivity("PPTImport", "Completed", null, null, { slides_imported: task.slideIds.length }, { audit: true });
                        if (task.warnings && task.warnings.length > 0) {
                            const messageElements = [];
                            if (task.warnings.some(warning => warning.type === PPTImportWarningType.ELEMENTS_TRUNCATED)) {
                                messageElements.push(
                                    <p>
                                        Some of your original slides have more elements than we support.
                                        We've imported as much detail as possible.
                                    </p>
                                );

                                const slideIndexes = task.warnings
                                    .filter(warning => warning.type === PPTImportWarningType.ELEMENTS_TRUNCATED)
                                    .map(warning => warning.slideIndex + 1);

                                messageElements.push(
                                    <p className="bold">Imported slide{slideIndexes.length > 1 ? "s" : ""} {slideIndexes.join(" and ")} may be incomplete.</p>
                                );
                            }

                            ShowWarningDialog({
                                title: "Oops! There was an issue with your slides.",
                                message: messageElements
                            });
                        }
                        return onCreate({ type: "ppt-import", presentationId: task.presentationId });
                }
            },
            {
                presentationName: this.selectedFile.name.replace(/\.ppt(x)*$/, ""),
                organizationId,
                theme: this.selectedThemeModel
            })
            .then(task => this.cancelCurrentTask = () => {
                task.cancel().catch(err => logger.error(err, "ImportPresentationFromPPTPane task.cancel() failed"));
                this.cancelCurrentTask = null;
                this.isCurrentTaskCancelled = true;
            })
            .catch(err => this.handleError(err));
    }

    handleCancelButtonClick = () => {
        const { currentStep } = this.state;

        if (this.cancelCurrentTask) {
            this.cancelCurrentTask();
        }

        if (currentStep === PPTImportStep.PARSING_PPT_THEME) {
            this.setState({ ...this.initialState });
        } else if (currentStep === PPTImportStep.IMPORTING_SLIDES) {
            this.setState({ currentStep: PPTImportStep.SELECTING_THEME });
        } else {
            this.setState({ currentStep: PPTImportStep.SELECTING_FILE });
        }
    }

    renderPaneContents() {
        const {
            currentStep,
            currentStepProgress,
            allowOnlyTeamThemes
        } = this.state;

        switch (currentStep) {
            case PPTImportStep.SELECTING_FILE:
                return (
                    <FlexBox center fill>
                        <InnerPane>
                            <div>
                                <h1>Import a PowerPoint Presentation</h1>
                                <Gap10 />
                                <p>
                                    Slides will be imported as images. You can choose to make them editable at any time. You may notice some slight changes when you do.
                                </p>
                                <Gap30 />
                                <BlueButton>
                                    <UploadFileInput
                                        onChange={event => this.handleSelectFile(event.currentTarget.files[0])}
                                        type="file"
                                        accept=".pptx"
                                    />
                                    <Icon>cloud_upload</Icon>
                                    Select File
                                </BlueButton>
                            </div>
                        </InnerPane>
                    </FlexBox>
                );
            case PPTImportStep.SELECTING_THEME:
                return (
                    <FlexBox center fill>
                        <ChooseThemeDialog
                            title="Select a theme to start from"
                            hidePPTImport
                            hideBackdrop
                            showCancelButton
                            preventClose
                            showEditCurrentTheme={false}
                            closeDialog={this.handleCancelButtonClick}
                            closeDialogOnThemeChoose={false}
                            hideBuiltInThemes
                            hideUserThemes={allowOnlyTeamThemes}
                            onlyTeamThemes={allowOnlyTeamThemes}
                            pptThemeModel={this.pptThemeModel}
                            callback={this.handleThemeSelected}
                            WrapperComponent={ChooseThemeDialogWrapper}
                            styles={{ height: "calc(100% - 178px)" }}
                        />
                    </FlexBox>
                );
            case PPTImportStep.UPLOADING_FONTS:
                return (
                    <FlexBox center fill>
                        <UploadFontsDialog
                            themeModel={this.selectedThemeModel}
                            onFontsUploaded={this.handleThemeSelected}
                            WrapperComponent={ChooseThemeDialogWrapper}
                        />
                    </FlexBox>
                );
            case PPTImportStep.PARSING_PPT_THEME:
            case PPTImportStep.IMPORTING_SLIDES:
                return (
                    <FlexBox center fill>
                        <InnerPane>
                            <div>
                                {currentStep === PPTImportStep.PARSING_PPT_THEME && <h1>Processing selected file</h1>}
                                {currentStep === PPTImportStep.IMPORTING_SLIDES && <h1>Importing presentation</h1>}
                                <Gap10 />
                                <p>
                                    Hang tight. This might take a minute or so. Please do not close or refresh the browser.
                                </p>
                                <Gap30 />
                                <LinearProgress
                                    value={currentStepProgress}
                                    variant="determinate"
                                />
                                <Gap30 />
                                <CancelButtonContainer>
                                    <BlueButton onClick={this.handleCancelButtonClick}>
                                        Cancel
                                    </BlueButton>
                                </CancelButtonContainer>
                            </div>
                        </InnerPane>
                    </FlexBox>
                );
        }
    }

    render() {
        return (
            <UIPane style={{ padding: 0 }}>
                <UIPaneContents style={{ padding: 0 }}>
                    {this.renderPaneContents()}
                </UIPaneContents>
            </UIPane >
        );
    }
}
