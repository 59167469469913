import React, { Component } from "react";

import { _ } from "js/vendor";

class SearchInput extends Component {
    constructor(props) {
        super(props);
        const { prefilledSearch } = props;

        this.inputRef = React.createRef();
        this.state = {
            searchInput: prefilledSearch || "",
        };
    }

    componentDidMount() {
        const { focus } = this.props;

        if (focus) {
            this.inputRef.current.focus();
            this.inputRef.current.select();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { onChange, prefilledSearch, focus } = this.props;
        const { searchInput } = this.state;

        if (prefilledSearch && prevProps.prefilledSearch !== prefilledSearch && prefilledSearch !== searchInput) {
            this.setState({ searchInput: prefilledSearch }, () => {
                if (focus) {
                    this.inputRef.current.focus();
                }
            });
        }

        if (prevProps.focus !== focus && focus) {
            this.inputRef.current.focus();
        }

        if (onChange && prevState.searchInput !== searchInput) {
            onChange(searchInput);
        }
    }

    handleKeyUp = e => {
        // trying to submit
        if (e.key === "Enter") {
            // verify that there's input
            const {
                handleSubmit,
                allowEmptySubmit,
            } = this.props;
            const searchInput = _.trim(this.state.searchInput);
            const hasSearch = !!searchInput;
            if (hasSearch || allowEmptySubmit) {
                handleSubmit(searchInput);
            }
        }
    }

    handleOnChange = e => {
        this.setState({
            searchInput: e.currentTarget.value
        });
    };

    clearSearch = () => {
        const { handleClearSearch } = this.props;
        this.setState({
            searchInput: ""
        });
        handleClearSearch && handleClearSearch();
    };

    render() {
        const { className, placeholder, onMouseEnter = () => { }, onMouseLeave = () => { } } = this.props;
        const { searchInput } = this.state;

        return (
            <div className={`searchbar ${className}`}>
                <i className="micon">search</i>
                <input
                    ref={this.inputRef}
                    type="text"
                    spellCheck="false"
                    placeholder={placeholder}
                    value={searchInput}
                    onKeyUp={this.handleKeyUp}
                    onChange={e => this.handleOnChange(e)}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                />
                <i
                    className="micon clear_search"
                    onClick={this.clearSearch}
                    style={{
                        display:
                            searchInput && searchInput !== ""
                                ? "inline"
                                : "none"
                    }}
                >
                    cancel
                </i>
            </div>
        );
    }
}

export default SearchInput;
