import React, { Component } from "react";
import styled from "styled-components";

import { LibraryView } from "common/constants";
import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import { PresentationLibraryPane } from "js/editor/PresentationLibrary/PresentationLibraryPane";
import { PresentationLibraryControllerInitializer } from "js/editor/PresentationLibrary/PresentationLibraryControllerInitializer";
import AppController from "js/core/AppController";
import { ds } from "js/core/models/dataService";

const PresentationLibraryContainer = styled.div`
  display: grid;
  height: 100%;
`;

const StyledDialog = styled(BeautifulDialog)`
    &&& {
        .MuiDialog-paper {
            height: 100% !important;
        }
    }
`;

const StyledPresentationLibraryPane = styled(PresentationLibraryPane)`
    &&& {
        overflow: auto;
        height: 100%;
    }
`;

export class PresentationLibraryDialog extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { onSelectedPresentation, closeDialog, maxWidth = false } = this.props;

        return (
            <StyledDialog onClose={closeDialog} maxWidth={maxWidth}>
                <PresentationLibraryContainer>
                    <PresentationLibraryControllerInitializer
                        workspaceId={ds.selection.presentation.getWorkspaceId()}
                        user={AppController.user}
                        view={LibraryView.LIBRARY}
                        pane={"recent"}
                    >
                        <StyledPresentationLibraryPane
                            workspaceId={ds.selection.presentation.getWorkspaceId()}
                            readOnly={true}
                            showCloseIcon={true}
                            isMyPresentationsOnly={true}
                            onSelectedPresentation={presentationId => {
                                onSelectedPresentation(presentationId);
                                closeDialog();
                            }}
                            onCloseDialog={closeDialog}
                        />
                    </PresentationLibraryControllerInitializer>
                </PresentationLibraryContainer>
            </StyledDialog>
        );
    }
}
