import getLogger, { LogGroup } from "js/core/logger";
import { simplifyNulls, makeSession, makeId, getInitialId, isEqual, getTick } from "common/commands/utils";
import commute from "common/commands/commute";
import deriveSimpleModel from "common/commands/deriveSimpleModel";
import diff from "common/commands/diff";
import applyCommands from "common/commands/applyCommands";
import { processCommands } from "common/commands/processCommands";
import createFromData from "common/commands/createFromData";
import { idbRead, idbWrite } from "./indexedDB";
import db from "js/db";
import isConnected from "../utilities/isConnected";

const logger = getLogger(LogGroup.ADAPTER);

let SNAPSHOT_WINDOW = 50; // how many commands until we create a new snapshot

// A unique ID for this particular browser session, used to break ties on the ID.
let session = makeSession();

// timeOffset - will be updated with the time offset from firebase server, to make more accurate timestamps
let timeOffset = 0;
if (isConnected.connected) {
    db(".info").child("serverTimeOffset").once("value").then(snapshot => timeOffset = snapshot.val());
}

// initial data is set on the special key (all zeroes) so that it comes before all other updates
const specialInitialID = getInitialId();

// Main interface to the commands library - give a model name and ID
// for firebase ref, and onRemoteChange and onRemoteError callbacks for model updates.

class CommandsModel {
    constructor({ root, id, isNew, onRemoteChange, onRemoteError, autoSync, userId }) {
        this.root = root;
        this.model_id = id;
        this.onRemoteChange = onRemoteChange;
        this.onRemoteError = onRemoteError;

        this._transactionFunctions = [];
        this._transactionPromise = Promise.resolve();

        this._commandsInFlight = new Set();
        this.userId = userId;

        this.disconnected = false;
        this.lastModel = isNew ? {} : undefined;
        this.autoSync = autoSync;

        this.loadedPromise = new Promise(resolve => {
            this.resolveLoadedPromise = () => {
                resolve();
                this.resolveLoadedPromise = () => null;
            };
        });
        this._initialize();
    }

    _initialize() {
        if (this.disconnected) return;

        const snapRef = db(this._snapPath());
        function onSnapshot(snapshot) {
            if (this.disconnected) {
                snapRef.off("value", onSnapshot, this);
                return;
            }
            if (!snapshot.val()) {
                return;
            }
            snapRef.off("value", onSnapshot, this);

            let snap = { id: snapshot.val().id, model: snapshot.val().model };
            let model = JSON.parse(snap.model);
            let id = snap.id;
            let commandList = [];
            this._transaction(existing => {
                setTimeout(() => this._syncFrom(id), 0);
                return { snap, model, commandList };
            });
        }
        snapRef.on("value", onSnapshot, this);
    }

    _transaction(fn) {
        this._transactionFunctions.push(fn);
        if (this._transactionFunctions.length === 1) {
            // start a new transaction
            this._transactionPromise = new Promise(resolve => {
                idbRead(this._storagePath(), value => {
                    if (this.disconnected) return resolve();
                    if (value) value = JSON.parse(value);
                    for (let fn of this._transactionFunctions) {
                        value = fn(value);
                    }
                    this._transactionFunctions = [];
                    let triggerRemoteChange = false;
                    if (!this.lastModel || !isEqual(this.lastModel, value.model)) {
                        this.lastModel = value.model;
                        triggerRemoteChange = this.initial_sync_finished;
                    }
                    idbWrite(this._storagePath(), JSON.stringify(value), () => {
                        if (triggerRemoteChange) {
                            this.onRemoteChange(deriveSimpleModel(this.lastModel));
                        }
                        resolve();
                    });
                });
            });
        }
        return this._transactionPromise;
    }

    _syncFrom(start_id) {
        if (this.disconnected) return;

        this._seenCommands = {};

        this.query = db(this._cmdsPath()).orderByKey().startAt(start_id);

        // On initial sync, firebase will first call child_added for
        // all matching children, then value.  To avoid repeated calls
        // to onRemoteChange, we use this flag to wait for the first
        // value call.  After this first sync, each new call to
        // child_added will immediately cause an onRemoteChange.
        this.initial_sync_finished = false;

        this.query.on("child_added", cmd => {
            if (!this.initial_sync_finished) {
                this._seenCommands[cmd.key] = cmd.val();
            }

            // we only want commands _after_ start_id (no way to specify this in firebase query AFAIK)
            if (cmd.key === start_id) return;
            cmd = Object.assign({ id: cmd.key }, cmd.val());

            this._transaction(data => {
                let { commandList, model, snap } = data;
                let matchingCommands = commandList.filter(x => x.id === cmd.id && x.updates === cmd.updates);
                if (matchingCommands.length) {
                    return data;
                }

                // Splice the command into its proper order in the list
                let ii = 0;
                while (ii < commandList.length && commandList[ii].id < cmd.id) ii++;
                commandList.splice(ii, 0, cmd);
                ii++;

                if (cmd.id <= snap.id) {
                    return { commandList, model, snap };
                }
                // commute the updates from the command to the front of the list
                let updates = JSON.parse(cmd.updates);
                for (; ii < commandList.length && updates.length; ii++) {
                    updates = commute(updates, JSON.parse(commandList[ii].updates));
                }

                // apply the commuted updates
                model = applyCommands(model, updates);

                return { commandList, model, snap };
            });
        }, this.onRemoteError);

        this.query.once("value", () => {
            this._transaction(({ commandList, model, snap }) => {
                // maintain invariants

                // 1. every not-queued command should be in the database with the same updates.
                //    if this is not true, throw away the local updates that do not match.
                let missingCommands = commandList.filter(
                    cmd => !cmd.queued && cmd.id > snap.id && (!this._seenCommands[cmd.id] || this._seenCommands[cmd.id].updates !== cmd.updates));
                if (missingCommands.length) {
                    commandList = commandList.filter(cmd => missingCommands.indexOf(cmd) === -1);
                }

                // 2. The commands should be ordered by increasing id. If not, sort them.
                // for (let ii = 0; ii < commandList.length - 1; ii++) {
                //     if (commandList[ii].id > commandList[ii + 1].id) {
                //         logger.error(new Error("Commands were in the wrong order"), "Commands were in the wrong order", { slideId: this.model_id });
                //         commandList.sort((l, r) => l.id < r.id ? -1 : (l.id > r.id ? 1 : 0));
                //         break;
                //     }
                // }

                // remove old commands up to the snapshot id
                while (commandList.length && !commandList[0].queued && commandList[0].id <= snap.id) {
                    commandList.shift();
                }

                // 3. The model should be equal to the result of the commands, applied to the snapshot.
                let commands = commandList.filter(cmd => cmd.id > snap.id).reduce((l, r) => l.concat(JSON.parse(r.updates)), []);
                let newModel = applyCommands(JSON.parse(snap.model), commands);
                if (!isEqual(newModel, model)) {
                    logger.error(new Error("Model is not the same for slide"), "Model is not the same for slide", { slideId: this.model_id });
                    model = newModel;
                }
                return { commandList, model, snap };
            }).then(
                () => {
                    db(this._snapPath()).on("value", data => {
                        if (data.val()) {
                            this._transaction(({ commandList, model, snap }) => ({ commandList, model, snap: data.val() }));
                        }
                    });
                    this.onRemoteChange(deriveSimpleModel(this.lastModel));
                    this.initial_sync_finished = true;

                    this._updateSnapshot();
                    this._sendQueuedUpdates();
                    this.resolveLoadedPromise();
                });
        }, this.onRemoteError);
    }

    disconnect() {
        clearInterval(this._pollForChanges);
        if (this.query) this.query.off();
        this._transactionPromise.then(() => {
            this.disconnected = true;
        });
    }

    updateModel(newModel) {
        let updates = diff(this.lastModel, newModel);
        this.lastModel = applyCommands(this.lastModel, updates);
        let diffCheck = diff(this.lastModel, newModel);

        // after applying the diff, the new diff should be empty
        if (diffCheck.length > 0) {
            logger.error(new Error("Diff check failed"), "Diff check failed", { slideId: this.model_id, diffCheck });
        }
        this._transaction(({ model, commandList, snap }) => {
            model = applyCommands(model, updates);
            let tick = getTick(commandList, snap);
            let id;
            let ts = timeOffset + new Date().getTime();
            let existingIds = new Set(commandList.map(({ id }) => id));
            do {
                id = makeId(tick + 1, ts++, session);
            } while (existingIds.has(id));
            commandList.push({ id, updates: JSON.stringify(updates), queued: true });
            return { model, commandList, snap };
        });
        this._sendQueuedUpdates();
    }

    getCurrentModel() {
        return this.lastModel && deriveSimpleModel(this.lastModel);
    }

    _updateSnapshot() {
        this._transaction(({ model, commandList, snap }) => {
            let nowDate = timeOffset + new Date().getTime();
            let snapCmds = commandList.slice(0);

            // find all commands after the latest snap id
            if (snap.id) {
                while (snapCmds.length && snapCmds[0].id <= snap.id) {
                    snapCmds.shift();
                }
            }

            // we can only update the snapshot up to our first queued command
            for (let ii = 0; ii < snapCmds.length; ii++) {
                if (snapCmds[ii].queued) {
                    snapCmds = snapCmds.slice(0, ii);
                }
            }

            if (snapCmds.length < SNAPSHOT_WINDOW) return { model, commandList, snap }; // snapshot already up to date

            let updates = snapCmds.reduce((lst, cmd) => lst.concat(JSON.parse(cmd.updates)), []);
            let newModel = applyCommands(JSON.parse(snap.model || "{}"), updates);

            let newSnap = { model: JSON.stringify(newModel), id: snapCmds[snapCmds.length - 1].id };
            db(this._snapPath()).set(newSnap);
            return { model, commandList, snap: newSnap };
        });
    }

    _sendCommand(cmd, count) {
        if (this._commandsInFlight.has(cmd.id)) {
            return;
        }
        this._commandsInFlight.add(cmd.id);
        db(this._cmdsPath() + "/" + cmd.id)
            .set({ updates: cmd.updates, userId: this.userId })
            .then(() => {
                this._commandsInFlight.delete(cmd.id);
                this._removeQueued(cmd.id);
            })
            .catch(err => {
                this._commandsInFlight.delete(cmd.id);
                if (err.code === "PERMISSION_DENIED") {
                    // The command can't be saved because its id is earlier than the snapshot in the database.
                    // we will need to "rebase" this command to the head of the command list and try again.

                    // make sure that the command hasn't been persisted
                    db(this._cmdsPath() + "/" + cmd.id).once("value", snap => {
                        if (snap.val()) {
                            this._removeQueued(cmd.id);
                        } else {
                            setTimeout(() => this._rebaseCommand(cmd, count + 1), 200);
                        }
                    });
                } else {
                    this.onRemoteError(err);
                }
            });
    }

    _sendQueuedUpdates() {
        this._transaction(data => {
            let queued = data.commandList.filter(cmd => cmd.queued);
            queued.map(cmd => this._sendCommand(cmd, 0));
            return data;
        });
    }

    _removeQueued(id) {
        this._transaction(data => {
            data.commandList
                .filter(cmd => cmd.id === id)
                .map(cmd => {
                    delete cmd["queued"];
                });
            return data;
        });
    }

    _rebaseCommand(cmd, count) {
        if (count > 5) {
            throw new Error("Too many attempts to rebase command " + cmd.updates);
        }
        if (this.disconnected) return;
        this._transaction(({ commandList, model, snap }) => {
            let myIndex = commandList.map(cmd => cmd.id).indexOf(cmd.id);
            if (myIndex === -1) {
                // command was already rebased
                return { commandList, model, snap };
            }

            commandList.splice(myIndex, 1);

            let updates = JSON.parse(cmd.updates);
            for (; myIndex < commandList.length; myIndex++) {
                updates = commute(updates, JSON.parse(commandList[myIndex].updates));
            }
            let ts = timeOffset + new Date().getTime();
            let tick = getTick(commandList, snap);
            let existingIds = new Set(commandList.map(({ id }) => id));
            let id;
            do {
                id = makeId(tick + 1, ts++, session);
            } while (existingIds.has(id));
            let rebasedCommand = {
                updates: JSON.stringify(updates),
                id,
                queued: true
            };
            commandList.push(rebasedCommand);
            setTimeout(() => this._sendCommand(rebasedCommand, count), 0);
            return { commandList, model, snap };
        });
    }

    _cmdsPath() {
        return `${this.root}/${this.model_id}/cmds/`;
    }

    _snapPath() {
        return `${this.root}/${this.model_id}/snap`;
    }

    _storagePath() {
        return `_commands_/${this.root}/${this.model_id}`;
    }
}

// initialize a model with default data, by setting an initial command and corresponding snap
function writeFromData(root, id, cmd, snapModel, stringifiedSnapModel, userId, skipFirebaseSet) {
    if (!skipFirebaseSet) {
        if (!id) {
            id = db(root).push().key;
        }

        db(`${root}/${id}/cmds/${specialInitialID}`).set({ updates: cmd.updates, userId });

        let snapRef = db(`${root}/${id}/snap/`);
        // we don't use the value but have to subscribe so that wrappedRef will intercept
        snapRef.on("value", () => { });
        snapRef.set({ id: specialInitialID, model: stringifiedSnapModel });
    }

    idbWrite(`_commands_/${root}/${id}`, JSON.stringify({
        snap: { id: specialInitialID, model: stringifiedSnapModel },
        model: snapModel,
        commandList: [cmd]
    }), () => null);
    return id;
}

export {
    CommandsModel,
    diff,
    applyCommands,
    commute,
    deriveSimpleModel,
    isEqual,
    simplifyNulls,
    createFromData,
    writeFromData,
    processCommands,
    getInitialId
};
