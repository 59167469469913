import React from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText
} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { dialogTheme } from "js/react/materialThemeOverrides";

export default function ErrorMessage({ message }) {
    return (
        <MuiThemeProvider theme={dialogTheme}>
            <Dialog
                open={true}
                fullWidth
                maxWidth="sm"
                BackdropProps={{
                    invisible: true,
                }}
                PaperProps={{
                    style: {
                        textAlign: "center",
                        padding: "20px 0"
                    }
                }}
            >
                <DialogTitle>
                    <span style={{
                        textTransform: "uppercase",
                        fontSize: "18px",
                        fontWeight: "normal",
                        letterSpacing: ".125em",
                        color: "#de463f"
                    }}>
                        Hmm...something's not right
                    </span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                    <DialogContentText style={{ marginTop: "1em" }}>
                        <a className="help" href="mailto:support@beautiful.ai">Contact Support</a>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>
    );
}
