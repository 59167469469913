import React from "react";
import styled from "styled-components";

import { Icon } from "@material-ui/core";

import { Gap20 } from "js/react/components/Gap";

import { PaneContent } from "../../../components/PaneComponents";
import GenerationHeader from "../../../components/GenerationHeader";

import GeneratePresentationController from "../controllers/GeneratePresentationController";

const GenerateOptions = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0px;
        width: 300px;
        height: 300px;
        border: solid 1px #ccc;
        background: white;
        padding: 30px;
        transition: 200ms;

        &:hover {
            transform: scale(1.05);
        }

        h1 {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            text-transform: none;
            letter-spacing: 0;
        }

        span {
            font-size: 50px;
            color: #0fa6df;
        }
`;

function ChooseGenerationTypeView() {
    return (
        <>
            <GenerationHeader
                header="Generate a presentation with ai."
            />
            <PaneContent>
                <Gap20 />
                <GenerateOptions>
                    <div onClick={() => GeneratePresentationController.showDocToDeck()}>
                        <Icon>input</Icon>
                        <h1>Ask the AI to convert a doc or webpage into a presentation.</h1>
                    </div>
                    <div onClick={() => GeneratePresentationController.showPrompt()}>
                        <Icon>auto_awesome</Icon>
                        <h1>Ask the AI to create a presentation on a topic or idea.</h1>
                    </div>
                </GenerateOptions>

            </PaneContent>
        </>
    );
}

export default ChooseGenerationTypeView;
