import React, { Component } from "reactn";
import styled from "styled-components";
import classNames from "classnames";
import { Popover, Icon } from "@material-ui/core";

import { AssetType } from "common/constants";
import { downloadFromUrl } from "js/core/utilities/utilities";
import getLogger, { LogGroup } from "js/core/logger";
import { ImageThumbnail } from "js/react/components/ImageThumbnail";
import BackgroundClick from "js/react/components/BackgroundClick";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";
import { VideoPreview } from "./VideoPreview";

const logger = getLogger(LogGroup.ASSETS);

const HiddenBadge = styled.div`
  position:absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  z-index: 100;
  border-radius: 50%;
  background: orangered;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: white;
    font-size: 20px;
  }
`;

const AssetNameContainer = styled.div.attrs(({ visible, isOnDarkBackground }) => ({
    style: {
        opacity: visible ? 1 : 0,
        color: isOnDarkBackground ? "#ddd" : "#333"
    }
}))`
    position: absolute;
    left: 50%;
    bottom: 9px;
    transform: translateX(-50%);
    max-width: 67%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
`;

const AssetContextMenu = ({ anchorEl, children, handleCancel }) => (
    <BackgroundClick onBackgroundClick={handleCancel}>
        <div>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={!!anchorEl}
            >
                {children}
            </Popover>
        </div>
    </BackgroundClick>
);

class AssetPreview extends Component {
    constructor() {
        super();

        this.stockVideoRef = React.createRef();
        this.state = {
            isHoveringOverElement: false,
            selected: false,
            anchorEl: null,
            previewUrl: null,
        };
    }

    onPopoverCancel = () => {
        this.setState({
            anchorEl: null,
            selected: false,
            isHoveringOverElement: false,
            isMenuOpen: false,
        });
    }

    removeAsset = () => {
        const { asset, assetType, removeAssetCallback } = this.props;
        removeAssetCallback(asset, assetType);
    }

    downloadAsset = async () => {
        const { asset } = this.props;

        try {
            const fileName = `${asset.id}.${asset.get("fileType")}`;
            const assetUrl = await asset.getURL("original", true);
            await downloadFromUrl(assetUrl, fileName);
        } catch (err) {
            logger.error(err, "[AssetPreview] downloadAsset() failed");
        }
    }

    hidePlayback = () => {
        this.stockVideoRef?.current && this.stockVideoRef.current.hidePlayback();
    }

    showMenu = e => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            anchorEl: e.currentTarget,
            isMenuOpen: true
        });
    }

    render() {
        const {
            addAssetCallback,
            asset,
            previewProps,
            showContextMenu,
            handleConfirm,
            isMenuOpen,
            width,
            muted,
            toggleMuted,
            hidePlaybacks,
            backgroundVideoOnly = false,
            updateLayout,
            menuItems
        } = this.props;
        const {
            anchorEl,
            isHoveringOverElement,
            selected,
        } = this.state;

        if (!previewProps) {
            // still loading
            return null;
        }

        let assetType = previewProps.type;

        const assetUrl = previewProps.url;
        const attribution = (
            previewProps.assetName ||
            previewProps.attribution
        );

        const styleImage = {
            position: "relative"
        };

        if (assetType == AssetType.LOGO) {
            styleImage.width = width;
            styleImage.height = width;
        }

        if (previewProps.renderDarkBackground) {
            styleImage.background = "#333333";
        }

        let assetName = asset.get("name");
        if (assetName) {
            assetName = assetName.toString().replace(/\.(svg|png|jpg|jpeg|gif)$/i, "");
        }

        const isUploadVideo = assetType === AssetType.VIDEO;
        const isStockVideo = assetType === AssetType.STOCK_VIDEO;
        const isLogo = assetType === AssetType.LOGO;
        const isVideo = isStockVideo || isUploadVideo;

        const isHidden = asset.get("hidden");

        return (
            <>
                {isHidden && <HiddenBadge><Icon>visibility_off</Icon></HiddenBadge>}
                {isVideo && (
                    <VideoPreview
                        ref={this.stockVideoRef}
                        addAssetCallback={addAssetCallback}
                        key={asset.content_value}
                        handleConfirm={handleConfirm}
                        videoProps={previewProps}
                        asset={asset}
                        muted={muted}
                        toggleMuted={toggleMuted}
                        hideAllPlaybacks={hidePlaybacks}
                        showMenu={showContextMenu && this.showMenu}
                        width={width}
                        backgroundVideoOnly={backgroundVideoOnly || assetType === AssetType.STOCK_VIDEO}
                        updateLayout={updateLayout}
                    />
                )}
                {!isVideo && (
                    <>
                        <div
                            data-id="asset-preview"
                            style={styleImage}
                            className={classNames({
                                "previously-used-asset-wrapper": true,
                                "stock-photo-wrapper": !isVideo && attribution,
                                "selected": selected,
                                [`${assetType}-wrapper`]: true
                            })}
                            onMouseEnter={() =>
                                this.setState({ isHoveringOverElement: true, selected: true })
                            }
                            onMouseLeave={() =>
                                !isMenuOpen &&
                                this.setState({ selected: false, isHoveringOverElement: false })
                            }
                            onClick={async event => {
                                if (event.target.dataset.id === "options-icon") {
                                    return;
                                }
                                await addAssetCallback(asset);
                                handleConfirm();
                            }}
                        >
                            <ImageThumbnail
                                src={assetUrl}
                                width={width}
                                autoSize={assetType == AssetType.IMAGE}
                                imgProps={previewProps}
                            />
                            {attribution && (
                                <div
                                    className="attribution"
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtmlText(attribution)
                                    }}
                                    onClick={e => e.stopPropagation()}
                                />
                            )}
                            {!attribution && !isLogo && assetName && (
                                <AssetNameContainer
                                    visible={isHoveringOverElement}
                                    isOnDarkBackground={previewProps.renderDarkBackground}
                                >
                                    {assetName}
                                </AssetNameContainer>
                            )}
                            {showContextMenu && (
                                <div
                                    className="menu-button"
                                    onClick={this.showMenu}
                                    style={{
                                        opacity: isHoveringOverElement || anchorEl ? 1 : 0,
                                        transition: "opacity 200ms"
                                    }}
                                >
                                    <i data-id="options-icon" className="micon menu">more_vert</i>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {showContextMenu && (
                    <AssetContextMenu
                        anchorEl={anchorEl}
                        handleCancel={this.onPopoverCancel}
                    >
                        <div className="popover-button-wrapper">
                            <>
                                {(menuItems ?? []).map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <button
                                                onClick={() => item.onClick(asset)}
                                                className="popover-button"
                                            >
                                                {item.label}
                                            </button>
                                        </div>
                                    );
                                })}
                            </>
                            {!isVideo && (
                                <div>
                                    <button
                                        onClick={this.downloadAsset}
                                        className="popover-button"
                                    >
                                        Download
                                    </button>
                                </div>
                            )}
                        </div>
                    </AssetContextMenu>
                )}
            </>
        );
    }
}

export default AssetPreview;
