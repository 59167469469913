const { merge } = require("lodash");
const { AssetType } = require("../common/constants");

const isObject = a => a instanceof Object;

function migrateIconAssetId(id, theme, app) {
    if (/^icon-/.test(id)) {
        return id;
    } else {
        const style = (theme && theme.get("iconStyle")) || (app && app.currentTheme && app.currentTheme.get("iconStyle")) || "chunky";
        return `icon-${style}-${id}`;
    }
}

const contactInfoMap = {
    contactus: true,
    business_card: true
};

function getAssetIds(elementOrSlideModel, themeBackboneModel) {
    const assetIds = [];
    const isContactInfo = contactInfoMap[elementOrSlideModel.template_id];
    const _getAssetIds = object => {
        if (!object) {
            return;
        }

        if (!isObject(object) && !Array.isArray(object)) {
            return;
        }

        if (isContactInfo) {
            let id;
            switch (object.type) {
                case "phone":
                    id = migrateIconAssetId("phone-call", themeBackboneModel);
                    assetIds.push({ id });
                    break;
                case "email":
                    id = migrateIconAssetId("symbol-at", themeBackboneModel);
                    assetIds.push({ id });
                    break;
                case "fax":
                    id = migrateIconAssetId("printer", themeBackboneModel);
                    assetIds.push({ id });
                    break;
                case "website":
                    id = migrateIconAssetId("internet", themeBackboneModel);
                    assetIds.push({ id });
                    break;
                case "social":
                    id = migrateIconAssetId(object.social_type, themeBackboneModel);
                    assetIds.push({ id });
                    break;
            }
        }

        if (object.content_type && object.content_type !== "map" && object.content_value) {
            if (object.content_type === "icon") {
                //migrate old asset ids
                const id = migrateIconAssetId(object.content_value, themeBackboneModel);
                assetIds.push({ id });
            } else {
                assetIds.push({ id: object.content_value });
            }
        }

        if (object.iconId) {
            const id = migrateIconAssetId(object.iconId, themeBackboneModel);
            assetIds.push({ id });
        }

        if (object.listIconId) {
            const id = migrateIconAssetId(object.listIconId, themeBackboneModel);
            assetIds.push({ id });
        }

        if (object.icon && typeof object.icon === "string") {
            const id = migrateIconAssetId(object.icon, themeBackboneModel);
            // circle icon does not exist but somehow made it into many slide models under the icon key which was unused
            if (id === "icon-chunky-circle" || id === "icon-classic-circle" || id === "icon-doodle-circle") return;
            assetIds.push({ id });
        }

        if (object.assetMapCachingKey && object.assetId) {
            assetIds.push({ id: object.assetId });
        }

        if (object.audioAsset) {
            assetIds.push({ id: object.audioAsset });
        }

        if (object.videoAssetId) {
            assetIds.push({ id: object.videoAssetId });
        }

        if (object.svgAssetId) {
            assetIds.push({ id: object.svgAssetId });
        }

        Object.keys(object).map(key => {
            return _getAssetIds(object[key]);
        });
    };
    _getAssetIds(elementOrSlideModel);
    return assetIds;
}

function getLogoPath(urlOrPath) {
    if (urlOrPath.indexOf("http") === 0) {
        const url = new URL(urlOrPath);
        if (url.host.includes("googleusercontent.com")) {
            // stored by google in their public bucket (g suite avatars)
            return url.href;
        } else if (url.host === "firebasestorage.googleapis.com") {
            // our legacy way of uploading logos and avatars in our private bucket
            // strip out the file name from tokens or other query params
            const pieces = url.pathname.split("/");
            return decodeURIComponent(pieces[pieces.length - 1]).replace("logos/", "");
        } else {
            // error kept to let us know if any other urls might be unaccounted for
            console.error(`getLogoPath() Unable to get logoPath for ${url.host}`);
        }
    } else {
        // new way of storing urls assumes we just store the file name
        return urlOrPath;
    }
}

function mergeMediaElementModelDefaults(...models) {
    merge(
        ...models,
        {
            flipHorizontal: false,
            mediaOpacity: 1,
            aoiLeft: null,
            aoiRight: null,
            aoiTop: null,
            aoiBottom: null,
            scale: null,
            filter: null,
            filterBrightness: null,
            filterContrast: null,
            filterBlur: null,
        },
    );
}

function getElementModelUpdatesForAsset(asset) {
    const {
        type,
        id,
        size,
        assetProps,
        assetName,
        tags,
        fileType,
        hasAlpha,
    } = asset;

    const updates = {
        content_type: type,
        content_url: null,
        content_attribution: asset.attribution,
        assetProps: merge({}, assetProps, {
            originalSize: size && {
                width: asset.w,
                height: asset.h
            }
        }),
        assetName,
        tags,
        content_value: id,
    };

    // If we have a transparent image, remove the background color
    if (
        (
            type === AssetType.IMAGE ||
            type === AssetType.LOGO
        ) &&
        fileType !== "svg" &&
        hasAlpha
    ) {
        updates.cellColor = "none";
    }

    return updates;
}

module.exports = { getAssetIds, migrateIconAssetId, getLogoPath, mergeMediaElementModelDefaults, getElementModelUpdatesForAsset };
