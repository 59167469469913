export class BadRequestError extends Error {
    public readonly status = 400;

    constructor(message: string, public readonly responseBody?: any) {
        super(message);
        this.name = "BadRequestError";
    }
}

export class NotFoundError extends Error {
    public readonly status = 404;

    constructor(message: string, public readonly responseBody?: any) {
        super(message);
        this.name = "NotFoundError";
    }
}

export class InternalServerError extends Error {
    public readonly status = 500;

    constructor(message: string, public readonly responseBody?: any) {
        super(message);
        this.name = "InternalServerError";
    }
}

export class UnauthorizedError extends Error {
    public readonly status = 401;

    constructor(message: string, public readonly responseBody?: any) {
        super(message);
        this.name = "UnauthorizedError";
    }
}

export class ForbiddenError extends Error {
    public readonly status = 403;

    constructor(message: string, public readonly responseBody?: any) {
        super(message);
        this.name = "ForbiddenError";
    }
}

export class TooManyRequestsError extends Error {
    public readonly status = 429;

    constructor(message: string, public readonly responseBody?: any) {
        super(message);
        this.name = "TooManyRequestsError";
    }
}

export class ConflictError extends Error {
    public readonly status = 409;

    constructor(message: string, public readonly responseBody?: any) {
        super(message);
        this.name = "ConflictError";
    }
}
