import React, { Component } from "react";
import styled from "styled-components";
import {
    Icon,
    MenuItem,
    Select,
    FormControlLabel,
    Checkbox,
    Button,
    DialogTitle,
    DialogActions,
    TextField,
    InputAdornment,
    Link
} from "@material-ui/core";

import { _ } from "js/vendor";
import { app } from "js/namespaces";
import { FlexSpacer, Gap10, Gap20, Gap30 } from "js/react/components/Gap";
import {
    BeautifulDialog,
    DialogContent,
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog,
} from "js/react/components/Dialogs/BaseDialog";
import Loadable from "js/react/components/Loadable";
import { IconMenu, ScaryButton } from "js/react/components/UiComponents";
import { FeatureType } from "js/core/models/features";
import { FlexBox, GridBox, ScrollBox } from "js/react/components/LayoutGrid";
import { UserProfile } from "js/react/components/Avatar";
import { TeamFoldersController } from "js/core/dataServices/TeamFoldersDataService";
import { UIController } from "js/editor/dialogs/UIController";
import { createInviteSuggestionContext } from "js/react/components/InviteInput/InviteContext";
import { InviteSuggestions } from "js/react/components/InviteInput/InviteSuggestions";
import { normalizePermissionForDisplay } from "js/core/utilities/permissions";
import { Key } from "js/core/utilities/keys";
import AppController from "js/core/AppController";
import ManageTeamFolderDialog from "js/react/views/TeamResources/dialogs/ManageTeamFolderDialog";

const NoMembers = styled.div`
    font-style: italic;
    color: #666;
`;

const MemberRow = styled.div`
    display: flex;
    width: 100%;
    button {
        opacity: 0;
    }
    &:hover {
        button {
        opacity: 1;
        }
    }
`;

const StyledLink = styled(Link)`
    font-size: 16px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 34px;
`;

const StyledOwnerCanEdit = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3px;
`;

export class ManageTeamFolderMembersDialog extends Component {
    state = {
        inviteContext: null,
        isLoading: true,
        onlyOwnerCanEdit: false
    }

    async componentDidMount() {
        let {
            folder,
        } = this.props;
        let allTeamMembers = await UIController.getAllTeamMembers();
        let members = await TeamFoldersController.getTeamMembersForFolder(folder.id);

        let inviteContext = createInviteSuggestionContext({
            includeFolders: false,
            includeCurrentUser: true,
            allSuggestions: allTeamMembers,
            selection: members,
            saveContext: inviteContext => this.setState({ inviteContext }),
        });

        const onlyOwnerCanEdit = folder.onlyOwnerCanEdit ?? true;
        const canManage = !folder.isDefault && (!onlyOwnerCanEdit || app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, AppController.orgId));

        this.setState({
            inviteContext,
            canManage,
            onlyOwnerCanEdit,
            isLoading: false,
        });
    }

    componentWillUnmount() {
        this.state.inviteContext.destroy();
    }

    handleRemoveMember = async member => {
        let { inviteContext } = this.state;

        let removeMember = async () => {
            if (member.id == app.user.id) {
                this.props.onRemovedSelf && this.props.onRemovedSelf();
            }

            await TeamFoldersController.removeMembersFromTeamFolder(this.props.folder, [member.id], false);

            inviteContext.deselectItems([member]);
        };

        if (inviteContext.selection.length == 1) {
            if (await ShowConfirmationDialog({
                title: "Are you sure you want to remove the only member from this folder?",
                message: "This shared folder will be deleted."
            })) {
                await removeMember();
                this.props.closeDialog();
            }
        } else {
            if (member.id == app.user.id) {
                if (await ShowConfirmationDialog({ title: "Are you sure you want to remove yourself from this folder?" })) {
                    removeMember();
                }
            } else {
                removeMember();
            }
        }
    }

    handleSelectTeamMember = async member => {
        let { folder } = this.props;
        TeamFoldersController.addMembersToTeamFolder(folder, [member.id]);
    }

    handleChangeCanEdit = () => {
        let { onlyOwnerCanEdit } = this.state;
        let { folder } = this.props;
        this.setState({ onlyOwnerCanEdit: !onlyOwnerCanEdit });

        TeamFoldersController.setOnlyOwnerCanEdit(folder, !onlyOwnerCanEdit);
    }

    handleAddAllTeamMembers = async () => {
        let { folder } = this.props;
        let { inviteContext } = this.state;

        inviteContext.setFilter("");
        await TeamFoldersController.addMembersToTeamFolder(folder, inviteContext.selectableSuggestions.map(member => member.id));
        inviteContext.selectItems(inviteContext.selectableSuggestions);
    }

    handleRemoveAllTeamMembers = async () => {
        let { folder } = this.props;
        let { inviteContext } = this.state;

        if (await ShowConfirmationDialog({ title: "Are you sure you want to remove all the members (except yourself) from the folder?" })) {
            let membersToRemove = inviteContext.selection.filter(member => member.id != app.user.id);
            let memberIdsToRemove = membersToRemove.map(member => member.id);
            await TeamFoldersController.removeMembersFromTeamFolder(folder, memberIdsToRemove);
            inviteContext.deselectItems(membersToRemove);
        }
    }

    render() {
        let {
            folder,
            disableSettings,
            disallowSelfRemoval = false,
        } = this.props;
        let {
            inviteContext,
            isLoading,
            canManage,
            onlyOwnerCanEdit,
        } = this.state;

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <Loadable isLoading={isLoading}>
                    <DialogTitle>
                        <FlexBox>
                            {`Who can access ${folder.name}`}
                            <FlexSpacer />
                            {
                                !disableSettings &&
                                canManage &&
                                <IconMenu icon="settings">
                                    {app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, AppController.orgId) &&
                                        <MenuItem divider>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color="primary" size="small" checked={!onlyOwnerCanEdit}
                                                        onChange={this.handleChangeCanEdit} />
                                                }
                                                label="Allow members to manage folder permissions"
                                            />
                                        </MenuItem>
                                    }
                                    <MenuItem onClick={this.handleAddAllTeamMembers}><Icon>group_add</Icon>Add all team
                                        members to folder</MenuItem>
                                    <MenuItem onClick={this.handleRemoveAllTeamMembers}><Icon>group_remove</Icon>Remove
                                        everyone (but me) from folder</MenuItem>
                                </IconMenu>
                            }
                        </FlexBox>
                    </DialogTitle>
                    <DialogContent>
                        <Container>
                            {!canManage && onlyOwnerCanEdit && <StyledOwnerCanEdit>
                                <span>Reach out to a</span>
                                <StyledLink component="button" onClick={() => {
                                    ShowDialog(ManageTeamFolderDialog, {
                                        organizationId: AppController.orgId
                                    });
                                }}>team owner</StyledLink>
                                <span>to add or remove members from this folder.</span>
                            </StyledOwnerCanEdit>}
                            {
                                !!inviteContext &&
                                <>
                                    <ScrollBox fillWidth style={{ maxHeight: "calc(25vh)" }}>
                                        <GridBox rows fillWidth>
                                            {
                                                inviteContext.selection.map(member => (
                                                    <MemberRow key={member.id}>
                                                        <UserProfile profile={member} />
                                                        <FlexSpacer />
                                                        {
                                                            canManage &&
                                                        (
                                                            !disallowSelfRemoval ||
                                                            member.id !== app.user.id
                                                        ) &&
                                                        <ScaryButton
                                                            size="small"
                                                            onClick={() => this.handleRemoveMember(member)}
                                                        >Remove Member</ScaryButton>}
                                                    </MemberRow>
                                                ))
                                            }
                                        </GridBox>
                                        {
                                            !inviteContext.selection.length &&
                                            <NoMembers>No team members can access this folder</NoMembers>
                                        }
                                    </ScrollBox>
                                    {
                                        canManage &&
                                        <>
                                            <TeamMemberFilterableList
                                                inviteContext={inviteContext}
                                                placeholder="Add team members to this folder"
                                                onSelect={this.handleSelectTeamMember}
                                            />
                                        </>
                                    }
                                </>
                            }
                        </Container>
                    </DialogContent>
                </Loadable>
            </BeautifulDialog>
        );
    }
}

export class AddPresentationToFolderDialog extends Component {
    state = {
        members: [],
        isLoading: true,
        presentationPermissions: {},
        validPresentations: [],
        invalidPresentations: [],
        permission: "write",
    }

    validatePresentations = (presentations, presentationPermissions, permission) => {
        permission = normalizePermissionForDisplay(permission);
        let results = {
            validPresentations: presentations.filter(x => presentationPermissions[x.id] !== permission),
            invalidPresentations: presentations.filter(x => presentationPermissions[x.id] === permission),
        };
        return results;
    }

    componentDidMount = async () => {
        let {
            folder,
            presentations,
            isDummy = false
        } = this.props;
        let {
            permission,
        } = this.state;

        await Promise.all(presentations.map(presentation => presentation.getUserPermissions(true)));
        const viewOnlyPresentationList = presentations.filter(presentation => (
            !presentation.permissions.owner &&
            !presentation.permissions.write &&
            !presentation.get("softDeletedAt")
        ));

        if (viewOnlyPresentationList.length) {
            ShowErrorDialog({
                error: `Unable to share ${"presentation".pluralize(presentations.length > 1)} with ${folder.get("name")}.`,
                message: "View only presentations cannot be shared."
            });
            this.props.closeDialog();
        } else {
            let members = await TeamFoldersController.getTeamMembersForFolder(folder.id);

            let presentationPermissions = isDummy ? {} : await TeamFoldersController
                .getPresentationPermissionsForFolder(folder.id, presentations.map(p => p.id));

            this.setState({
                canManage: app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, AppController.orgId),
                members,
                presentationPermissions,
                isLoading: false,
                ...this.validatePresentations(presentations, presentationPermissions, permission),
            });
        }
    }

    handleChangePermissions = event => {
        let {
            presentations,
        } = this.props;
        let {
            presentationPermissions,
        } = this.state;

        let permission = event.target.value;
        this.setState({
            permission,
            ...this.validatePresentations(presentations, presentationPermissions, permission),
        });
    }

    handleOk = async () => {
        let {
            folder,
            callback,
            isDummy = false
        } = this.props;
        let {
            permission,
            validPresentations,
        } = this.state;

        if (!isDummy && validPresentations.length) {
            await TeamFoldersController.addPresentationsToTeamFolder(validPresentations, folder.id, permission);
        }
        callback && callback(permission);
        this.props.closeDialog();
    }

    render() {
        let {
            folder,
            presentations,
        } = this.props;
        let {
            members,
            isLoading,
            permission,
        } = this.state;

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <Loadable isLoading={isLoading}>
                    <DialogTitle>Are you sure you want to share {presentations.length == 1 ? "this presentation" : "these presentations"} with {folder.get("name")}?</DialogTitle>
                    <DialogContent>
                        <label>
                            These team members will have
                            <Select value={permission} onChange={this.handleChangePermissions} variant="outlined" style={{ margin: "0px 10px" }}>
                                <MenuItem value="read">View</MenuItem>
                                <MenuItem value="write">Edit</MenuItem>
                            </Select>
                            access:</label>
                        <Gap20 />
                        <ScrollBox fillWidth style={{ maxHeight: 370 }}>
                            <GridBox rows>
                                {members.map(member => (
                                    <UserProfile
                                        key={member.id}
                                        profile={member}
                                    />
                                ))}
                            </GridBox>
                        </ScrollBox>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            id="btnCancel"
                            onClick={this.props.closeDialog}
                        >Cancel</Button>
                        <Button
                            id="btnOk"
                            color="primary"
                            onClick={this.handleOk}
                        >Ok</Button>
                    </DialogActions>
                </Loadable>
            </BeautifulDialog>
        );
    }
}

const FilterInput = styled(TextField)`
    .MuiOutlinedInput-adornedEnd {
        padding-right: 8px;
    }
    .MuiIcon-root {
        opacity: 0.33;
        cursor: pointer;
    }
`;

class TeamMemberFilterableList extends Component {
    state = {
        filter: ""
    }

    handleKeyDown = event => {
        const {
            inviteContext,
            onSelect,
        } = this.props;

        switch (event.keyCode) {
            case Key.ENTER:
            case Key.UP_ARROW:
            case Key.DOWN_ARROW:
                // Prevent further events
                event.nativeEvent.stopImmediatePropagation();
                event.nativeEvent.preventDefault();

                switch (event.keyCode) {
                    case Key.ENTER:
                        let item = inviteContext.selectPendingSelection();
                        item && onSelect && onSelect(item);
                        break;
                    case Key.UP_ARROW:
                        inviteContext.pendingSelectPrev();
                        break;
                    case Key.DOWN_ARROW:
                        inviteContext.pendingSelectNext();
                        break;
                }
                break;
        }
    }

    handleChangeFilter = event => {
        let { inviteContext } = this.props;
        inviteContext.setFilter(event.target.value);
    }

    handleClearFilter = () => {
        let { inviteContext } = this.props;
        inviteContext.setFilter("");
    }

    render() {
        let {
            inviteContext,
            placeholder,
            onSelect,
        } = this.props;

        return (
            (
                !!inviteContext.filteredAllSuggestions.length ||
                !!inviteContext.filter
            ) &&
            <>
                <Gap30 />
                <FlexBox vertical left top fill>
                    <FilterInput
                        id="filter"
                        fullWidth
                        value={inviteContext.filter}
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleChangeFilter}
                        variant="outlined"
                        placeholder={placeholder}
                        autoFocus
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon onClick={this.handleClearFilter}>close</Icon>
                                </InputAdornment>
                            )
                        }}
                    />

                    <Gap10 />

                    <InviteSuggestions
                        inviteContext={inviteContext}
                        onSelect={onSelect}
                        sourceName="ManageTeamFolderMembersDialog"
                    />
                </FlexBox>
            </>
        );
    }
}
