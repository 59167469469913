import Api from "js/core/api";
import * as googleAuth from "js/core/oauth/googleAuth";
import { GOOGLE_ASSET_MIMETYPES, GOOGLE_VIDEO_MIMETYPES } from "common/constants";
import { FileType } from "js/core/utilities/fileSourcesAndTypes";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.GDRIVE);

export const SCOPES = [
    "openid",
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/drive.file",
    "https://www.googleapis.com/auth/drive.install",
    "https://www.googleapis.com/auth/drive.readonly",
    "https://www.googleapis.com/auth/spreadsheets",
];

/**
 * Grants our app offline access to user's Google Drive
 */
export function grantOfflineAccess() {
    return googleAuth.grantOfflineAccess(SCOPES.join(" "));
}

/**
 * Grants our app offline access to user's Google Drive.
 * If the popup is blocked, uses app.dialogManager to call grantOfflineAccess() synchronously to avoid the popup being blocked
 */
export function grantOfflineAccessWithDialogIfNeeded() {
    return new Promise((resolve, reject) => {
        grantOfflineAccess()
            .then(() => resolve())
            .catch(err => {
                if (!(err instanceof googleAuth.GoogleAuthPopupBlockedError)) {
                    reject(err);
                    return;
                }

                ShowConfirmationDialog({
                    title: "Connect Google Drive",
                    message: "Beautiful.ai needs access to your Google Drive, press OK to open Google's consent page",
                    acceptCallback: () => {
                        grantOfflineAccess()
                            .then(() => resolve())
                            .catch(err => reject(err));
                    },
                    cancelCallback: () => {
                        reject();
                    }
                });
            })
            .catch(err => reject(err));
    });
}

/**
 * Creates Google Picker UI to display user's files
 */
export async function preparePickerUI(accessToken, callback, fileTypes) {
    const firebaseConfig = window.baiFirebase.config;
    const apiKey = firebaseConfig.apiKey;
    const appId = firebaseConfig.appId;

    const mimeTypes = [];
    for (const fileType of fileTypes) {
        if (fileType === FileType.Image) {
            mimeTypes.push(GOOGLE_ASSET_MIMETYPES);
        } else if (fileType === FileType.Video) {
            mimeTypes.push(GOOGLE_VIDEO_MIMETYPES);
        } else if (fileType === FileType.Spreadsheet) {
            mimeTypes.push("application/vnd.google-apps.spreadsheet");
        }
    }

    const google = window.google;
    const view = new google.picker.View(google.picker.ViewId.DOCS);
    view.setMimeTypes(mimeTypes.join(","));

    const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .setDeveloperKey(apiKey)
        .setAppId(appId)
        .setOAuthToken(accessToken)
        .addView(view)
        .setCallback(callback)
        .build();

    return picker;
}

/**
 * Saves a presentation with the specified id into the specified Google Drive folder,
 * if no folder specified, the presentation will be saved in the Google Drive root
 */
export async function savePresentation(presentationId, folderId = null) {
    const { fileId } = await Api.gDrivePresentation.post({ presentationId, folderId });
    return fileId;
}

/**
 * Removes a presentation with the specified id from Google Drive
 */
export async function removePresentation(presentationId) {
    await Api.gDrivePresentation.delete({ presentationId });
}

/**
 * Returns an url to a presentation related to the specified GDrive file depengind on
 * permissions the calling user has (player or editor)
 */
export async function getPresentationUrl(fileId) {
    const { presentationUrl } = await Api.gDrivePresentationUrl.get({ fileId });
    return presentationUrl;
}

/**
 * Returns latest list of spreadsheets from user's Google Sheets account
 */
export async function getSpreadsheets() {
    return await Api.gDriveSpreadsheets.get();
}

/**
 * Fetch spreadsheet data for the given spreadsheet ID
 */
export async function getSpreadsheetData(spreadsheetId) {
    return await Api.gDriveSpreadsheets.get({ spreadsheetId });
}

/**
 * Creates Beautiful.ai folder and saves all the user's presentations into it
 * The calling user must have GDrive offline access granted to B.ai
 */
export async function enable() {
    return Api.gDriveEnable.post();
}

/**
 * Removes Beautiful.ai folder and all saved presentations
 * Also removes GDrive offline access tokens for the calling user
 */
export async function disable() {
    await Api.gDriveDisable.post();
}

/**
 * Returns the current state of GDrive integration for the calling user
 */
export async function getState() {
    return Api.gDriveState.get();
}

/**
 * Returns the id of an associted with the supplied presentation GDrive file
 */
export async function getFileId(presentationId) {
    try {
        const { fileId } = await Api.gDriveFileId.get({ presentationId });
        return fileId;
    } catch (err) {
        if (err.code === "notfound") {
            return null;
        }
        logger.error(err, "getFileId() failed", { presentationId });
        throw err;
    }
}
