import React, { Component } from "react";
import { Icon, TextField } from "@material-ui/core";
import moment from "moment";
import styled from "styled-components";
import { toLower, trim, uniqBy } from "lodash";

import { _ } from "js/vendor";
import Api from "js/core/api";
import { ds } from "js/core/models/dataService";
import getUserProfile from "js/core/services/userProfiles";
import { LibraryItem } from "js/core/models/libraryItem";
import { caseInsensitiveSort, trackActivity } from "js/core/utilities/utilities";
import AppController from "js/core/AppController";
import { themeColors } from "js/react/sharedStyles";
import {
    UIPane,
    UIPaneHeader,
    UIPaneContents,
    Section,
    BlueButton,
} from "js/react/components/UiComponents";
import Loadable from "js/react/components/Loadable";
import Avatar from "js/react/components/Avatar";
import { RedSwitch } from "js/react/components/Switch";
import TagsList from "js/react/components/TagsList";
import { ShowDialog, BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import ConfirmationCheckboxDialog from "js/react/components/Dialogs/ConfirmationCheckboxDialog";
import { SlideThumbnail } from "js/react/views/AddSlide/Panes/Components/ThumbnailGrid.js";
import AddTagsDialog from "js/react/views/UserOptions/dialogs/AddTagsDialog";
import { FlexBox, ScrollBox } from "js/react/components/LayoutGrid";
import { Gap10, Gap20, Gap30 } from "js/react/components/Gap";
import { PresentationLibraryList } from "js/editor/PresentationLibrary/PresentationLibraryList";

const SlideThumbnailContainer = styled.div`
    width: 640px;
    height: 360px;
    position: relative;
    
    .edit-message {
        opacity: 0;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items:  center;
        justify-content: center;
        padding: 50px;
        div {
          color: white;
          font-size: 18px;
          font-weight: 600;
          padding: 15px 20px;
          background: ${themeColors.ui_blue};
          text-align: center;
        }
    }
    &:hover {
      .edit-message {
        opacity: 1;
      }
    }
`;

const StatsContainer = styled.div`
    background: #f1f1f1;   
    width: 100%;
    padding: 15px;
    font-weight: 600;
    .MuiIcon-root {
        margin-right: 10px;
    }
    & > div + div {
        margin-top: 10px;
    }
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    label {
        font-size: 13px;
        width: 80px;
    }
    .avatar {
        margin-right: 10px;
    }
    .name {
        font-size: 14px;
        font-weight: 600;
        color: #333;
    }
    .date { 
        margin-left: 20px;
        font-size: 14px;
        color: #666;
    }
`;

export default class TeamSlideDialog extends Component {
    state = {
        isLoading: true,
        isLoadingUsedPresentations: true,
        usedPresentationsSort: { field: "name", reverse: false },
        libraryItem: { createdBy: {}, createdAt: "", modifiedBy: {}, modifiedAt: "" },
        usedPresentations: [],
        backboneLibraryItem: {}
    };

    async componentDidMount() {
        const { libraryItemId } = this.props;
        const defaultTeam = ds.teams.defaultTeamForOrg(AppController.orgId);
        let backboneLibraryItem = defaultTeam.libraryItems.get(libraryItemId);
        //in case the library item is not loaded and added to the default team yet load the library item
        if (!backboneLibraryItem) {
            backboneLibraryItem = new LibraryItem({ id: libraryItemId });
            await backboneLibraryItem.load();
        }

        const updatedLib = await this.sanitizeLibraryResponse(backboneLibraryItem);
        this.setState({ libraryItem: updatedLib, isLoading: false, backboneLibraryItem });
        this.loadSlideUsedContainer(Object.keys(backboneLibraryItem.attributes.usedInPresentations || {}), backboneLibraryItem);
    }

    componentWillUnmount() {
        this.props.callback && this.props.callback();
    }

    async loadSlideUsedContainer(presentationIds, libraryItem) {
        //get all presentations
        let presentations = [];
        if (presentationIds.length) {
            const response = await Api.libraryItems.get({
                type: "presentations",
                libraryItemId: libraryItem.get("id"),
                teamId: libraryItem.get("teamId")
            });
            presentations = response.body;
        }

        for (let presentation of presentations) {
            // presentationlibrarylist expects a getThumbnailUrl function
            presentation.getThumbnailUrl = () => presentation.imageURL;
        }

        this.setState({ isLoadingUsedPresentations: false, usedPresentations: presentations });
    }

    async sanitizeLibraryResponse(libraryItem) {
        const response = Object.assign({}, libraryItem.attributes);
        response.usedByCount = Object.keys(libraryItem.attributes.usedByUsers || {}).length;
        response.ratedByCount = Object.keys(libraryItem.attributes.ratedByUsers || {}).length;
        response.presentationCount = Object.keys(libraryItem.attributes.usedInPresentations || {}).length;
        response.tagsList = Object.keys(libraryItem.attributes.tags || {});
        const [createdUser, modifiedUser] = await Promise.all([
            getUserProfile(libraryItem.attributes.createdBy),
            libraryItem.attributes.modifiedBy && getUserProfile(libraryItem.attributes.modifiedBy)
        ]);

        response.createdBy = createdUser;
        response.modifiedBy = modifiedUser;
        return response;
    }

    handleSortUsedPresentations = sort => {
        let presentations = this.state.usedPresentations;

        let sortedData;
        sortedData = _.sortBy(presentations, o => {
            if (typeof o[sort.field] == "string") {
                return o[sort.field].toLowerCase();
            } else {
                return o[sort.field];
            }
        });

        if (sort.reverse) {
            sortedData = sortedData.reverse();
        }

        this.setState({ usedPresentationsSort: sort, usedPresentations: sortedData });
    }

    renderName(user) {
        if (user.displayName) {
            return <span className="fullName">{user.displayName}</span>;
        } else {
            return <span className="fullName">{user.email}</span>;
        }
    }

    handleEditSlide = async () => {
        const { closeDialog, resourceType } = this.props;
        const { backboneLibraryItem } = this.state;

        closeDialog();

        AppController.showSlideEditor({
            slideId: backboneLibraryItem.get("contentId"),
            resourceType
        });
    }

    handleDisableSlideToggle = isDisabled => {
        const { backboneLibraryItem } = this.state;

        if (!isDisabled) {
            ShowDialog(ConfirmationCheckboxDialog, {
                title: "Are you sure you want to hide this Shared Slide?",
                message: "Hiding a Shared Slide prevents it from being used in future presentations.",
                okButtonLabel: "OK",
                acceptCallback: () => {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            libraryItem:
                            {
                                ...prevState.libraryItem,
                                isDisabled: !isDisabled
                            }
                        };
                    }, () => {
                        backboneLibraryItem.update({ isDisabled: !isDisabled });
                        const props = {
                            slide_id: backboneLibraryItem.get("contentId"),
                        };
                        trackActivity("SharedSlideLibrary", "SlideDisabled", null, null, props);
                    });
                }
            });
            return;
        }
        this.setState(prevState => {
            return {
                ...prevState,
                libraryItem:
                {
                    ...prevState.libraryItem,
                    isDisabled: !isDisabled
                }
            };
        }, () => {
            backboneLibraryItem.update({ isDisabled: !isDisabled });
        });
    }

    handleTextChange(text, type) {
        this.setState(prevState => {
            return {
                ...prevState,
                libraryItem: {
                    ...prevState.libraryItem,
                    [type]: text
                }
            };
        });
    }

    handleTextBlur(text, type) {
        if (type !== "description" && type !== "name") {
            return;
        }
        const { backboneLibraryItem } = this.state;
        if (backboneLibraryItem.get(type) === text) {
            return;
        }
        backboneLibraryItem.update({ [type]: text });
    }

    handleRemoveTag = inputTag => {
        const { backboneLibraryItem } = this.state;
        this.setState(prevState => {
            return {
                ...prevState,
                libraryItem: {
                    ...prevState.libraryItem,
                    tagsList: prevState.libraryItem.tagsList.filter(tag => tag !== inputTag)
                }
            };
        }, () => {
            backboneLibraryItem.update({ tags: { [inputTag]: null } });
        });
    }

    handleAddTag = () => {
        const { backboneLibraryItem, libraryItem } = this.state;

        //get all tags from user's teams
        let existingTags = [];
        const defaultTeam = AppController.currentTeam;
        defaultTeam.libraryItems.forEach(libraryItem => {
            existingTags.push(Object.keys(libraryItem.get("tags") || {}));
        });

        //remove duplicates
        existingTags = uniqBy(existingTags.flat().map(trim), toLower);

        ShowDialog(AddTagsDialog, {
            activeTags: (libraryItem.tagsList || []).map(_.trim),
            existingTags,
            acceptCallback: newTags => {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        libraryItem: {
                            ...prevState.libraryItem,
                            tagsList: newTags
                        }
                    };
                }, () => {
                    newTags.map(newTag => backboneLibraryItem.update({ tags: { [newTag]: true } }));
                });
            },
        });
    }

    render() {
        const { isLoading, isLoadingUsedPresentations, usedPresentations, usedPresentationsSort, isSlideUsedLoading, libraryItem } = this.state;
        const { isTemplate, createdAt, modifiedAt, modifiedBy, createdBy, isDisabled } = libraryItem;
        const tagsList = caseInsensitiveSort([...(libraryItem.tagsList || [])], _.trim);

        return (
            <BeautifulDialog maxWidth="lg" closeDialog={this.props.closeDialog} closeButton>
                <UIPane style={{ overflow: "hidden" }}>
                    <UIPaneHeader>{isTemplate ? "Slide Template" : "Shared Slide"} Properties</UIPaneHeader>
                    <ScrollBox id="shared-slide-properties-content">
                        <UIPaneContents style={{ minHeight: 200 }}>
                            <Loadable isLoading={isLoading}>
                                <Section>
                                    <Gap10 />
                                    <FlexBox top left>
                                        <SlideThumbnailContainer>
                                            <SlideThumbnail slideId={libraryItem.contentId} highQuality />
                                            <div className="edit-message">
                                                <BlueButton onClick={this.handleEditSlide}>Edit Slide...</BlueButton>
                                            </div>
                                            {/*}*/}
                                        </SlideThumbnailContainer>
                                        <Gap30 />
                                        <FlexBox column top left width={500}>
                                            <TextField
                                                id="library-name"
                                                autoFocus
                                                label="Name"
                                                fullWidth
                                                value={libraryItem.name || ""}
                                                onChange={event => this.handleTextChange(event.target.value, "name")}
                                                onBlur={event => this.handleTextBlur(event.target.value, "name")}
                                            />
                                            <Gap30 />

                                            <UserInfo>
                                                <label>Created by</label>
                                                <Avatar url={createdBy.photoURL} />
                                                <div className="name">{this.renderName(createdBy)}</div>
                                                <div className="date">{moment.utc(createdAt).local().format("LL")}</div>
                                            </UserInfo>
                                            <Gap20 />
                                            {modifiedBy &&
                                                <UserInfo>
                                                    <label>Modified by</label>
                                                    <Avatar url={modifiedBy.photoURL} />
                                                    <div className="name">{this.renderName(modifiedBy)}</div>
                                                    <div className="date">{moment.utc(modifiedAt).local().format("LL")}</div>
                                                </UserInfo>
                                            }

                                            <Gap20 />

                                            <StatsContainer>
                                                <FlexBox left middle>
                                                    <Icon>star</Icon>
                                                    {libraryItem.ratedByCount} {"like".pluralize(libraryItem.ratedByCount != 1)}
                                                </FlexBox>

                                                {!isTemplate &&
                                                    <FlexBox left middle>
                                                        <Icon>person</Icon>
                                                        Used
                                                        by {libraryItem.usedByCount} {libraryItem.usedByCount == 1 ? "person" : "people"}
                                                    </FlexBox>
                                                }

                                                {!isTemplate &&
                                                    <FlexBox left middle>
                                                        <Icon>cloud_download</Icon>
                                                        Used
                                                        in {libraryItem.presentationCount} {"presentation".pluralize(libraryItem.presentationCount !== 1)}
                                                    </FlexBox>
                                                }
                                            </StatsContainer>
                                            <Gap20 />
                                            <RedSwitch
                                                id="hideSlideSwitch"
                                                checked={isDisabled}
                                                onChange={() => this.handleDisableSlideToggle(isDisabled)}
                                                value="disableSlide"
                                                label="Hide This Slide"
                                            />
                                        </FlexBox>
                                    </FlexBox>
                                    <Gap20 />
                                    <TextField multiline
                                        fullWidth
                                        value={libraryItem.description}
                                        placeholder="Add a description or instructions for this slide"
                                        variant="outlined"
                                        onChange={event => this.handleTextChange(event.target.value, "description")}
                                        inputProps={{
                                            onBlur: event => this.handleTextBlur(event.target.value, "description")
                                        }}
                                    />

                                </Section>
                                <Section>
                                    <FlexBox left middle fillWidth>
                                        <div className="section-title">Tags</div>
                                        <Gap20 />
                                        <TagsList
                                            tags={tagsList}
                                            onRemove={this.handleRemoveTag}
                                            onClick={this.handleAddTag}
                                        />
                                    </FlexBox>
                                </Section>
                                {!isTemplate &&
                                    <Section title="Where is this slide used">
                                        <Loadable isLoading={isLoadingUsedPresentations}>
                                            {(usedPresentations.length > 0) &&
                                                <PresentationLibraryList items={usedPresentations}
                                                    sort={usedPresentationsSort}
                                                    onSort={this.handleSortUsedPresentations}
                                                    readOnly />
                                            }
                                            {(usedPresentations.length == 0) &&
                                                <label>This team slide is not used in any presentations.</label>
                                            }
                                        </Loadable>
                                    </Section>
                                }
                            </Loadable>
                        </UIPaneContents>
                    </ScrollBox>
                </UIPane>
            </BeautifulDialog>
        );
    }
}
