import React, { useEffect, useState } from "react";

import { _ } from "js/vendor";
import AppController from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";

import { PaneContainer } from "../../components/PaneComponents";
import DocToDeckView from "./components/DocToDeckView";
import PromptView from "./components/PromptView";
import GeneratingSlidesView from "./components/GeneratingSlidesView";
import GeneratingOutlineView from "./components/GeneratingOutlineView";
import EditingOutlineView from "./components/EditingOutlineView";
import ChooseGenerationTypeView from "./components/ChooseGenerationTypeView";

import GeneratePresentationController, { GeneratePresentationState, PresentationGenerationStep } from "./controllers/GeneratePresentationController";

const logger = getLogger(LogGroup.AI);

interface GeneratePresentationPaneProps extends GeneratePresentationState {
    onCreate: (params: {
        type: string,
        defaultSlides: Object[],
        theme: Object,
        name: string
    }) => void;
}

const GeneratePresentationPane = GeneratePresentationController.withState(
    function GeneratePresentationPane({
        step,
        onCreate
    }: GeneratePresentationPaneProps) {
        const [fetching, setFetching] = useState<boolean>(true);

        useEffect(() => {
            (async () => {
                await GeneratePresentationController.reset(AppController.workspaceId, onCreate);
                setFetching(false);

                const searchParams = new URLSearchParams(location.search);
                const queryPrompt = searchParams.get("prompt");

                if (queryPrompt) {
                    await GeneratePresentationController.generatePresentationOutline({ prompt: queryPrompt, files: [], documentText: "", contextUrls: [] });
                }
            })().catch(err => logger.error(err, "[GeneratePresentationPane] Error initializing"));
        }, []);

        return (<PaneContainer cta="PresentationBot" fetching={fetching}>
            {step === PresentationGenerationStep.CHOOSE_FLOW &&
                <ChooseGenerationTypeView />
            }
            {step === PresentationGenerationStep.DOC2DECK &&
                <DocToDeckView />
            }
            {step === PresentationGenerationStep.PROMPT &&
                <PromptView />
            }
            {step === PresentationGenerationStep.GENERATING_OUTLINE &&
                <GeneratingOutlineView />
            }
            {step === PresentationGenerationStep.EDITING_OUTLINE &&
                <EditingOutlineView />
            }
            {(step === PresentationGenerationStep.GENERATING_SLIDES || step === PresentationGenerationStep.DONE) &&
                <GeneratingSlidesView />
            }
        </PaneContainer>);
    }
);

export default GeneratePresentationPane;
