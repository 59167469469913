import fallbackTTF from "arraybuffer-loader!./fallback.ttf";
import { opentype } from "js/vendor";

import { TextStyleEnum } from "common/constants";
import { builtInFontFiles, builtInFonts, cjkFallbackFonts, builtInFontExportPackagesMap, getFontWeightName, calculateFallbackRangeStart, fallbackRanges } from "common/fontConstants";
import { blobToDataURL, toDataUrl } from "js/core/utilities/utilities";
import { getStaticUrl } from "js/config";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import { ShowErrorDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { _ } from "js/vendor";

const logger = getLogger(LogGroup.FONTS);

app.glyphCache = {};
app.wordWidthCache = {};

const NOTO_H_HEIGHT = 71.4;

class FontManager {
    constructor() {
        this.loadOpentypeFontPromises = {};
        this.loadCssFontPromises = {};
        this.loadFontPromises = {};
        this.loadCJKFallbackCSSFontsPromise = null;
    }

    get fallbackFont() {
        if (!this._fallbackFont) {
            this._fallbackFont = opentype.parse(fallbackTTF);
            this._fallbackFont.isFallback = true;
            this._fallbackFont.name = "Fallback";
            app.glyphCache["Fallback"] = {};
        }
        return this._fallbackFont;
    }

    get fallbackFontDef() {
        if (!this._fallbackFontDef) {
            const imageData = getStaticUrl(`/fonts/images/Fallback.png`);
            this._fallbackFontDef = {
                isFallbackFont: true,
                label: "Fallback",
                imageData,
                styles: [{
                    italic: false,
                    weight: 400,
                    font: this.fallbackFont,
                    // We don't want to use this font as a css font, so we just hardcode this to true
                    // to avoid loading
                    cssFontLoaded: true,
                    fontFaceName: "Fallback",
                    label: "Fallback",
                    imageData
                }]
            };
        }
        return this._fallbackFontDef;
    }

    drawTextToBlob(font, text, { maxWidth = 400, height = 48, fontSize = 32, x = 0, y = 32, color = "black" } = {}) {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const measuredWidth = font.getAdvanceWidth(text, fontSize);
        canvas.width = Math.min(measuredWidth, maxWidth);
        canvas.height = height;
        const path = font.getPath(text, x, y, fontSize);
        path.fill = color;
        path.draw(ctx);

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(new Error("No blob created by drawTextToBlob()"));
                }
            });
        });
    }

    hasNaNWidthGlyphs(font) {
        return Object.values(font.glyphs.glyphs).some(glyph => Number.isNaN(glyph.advanceWidth));
    }

    assembleFallbackFontPath(fallbackData, textStyle) {
        let path, name;
        if (fallbackData.isCJK) {
            path = fallbackData.path[app.currentTheme.get("cjkFont") || "jp"];
        } else {
            path = fallbackData.path;
        }
        if (textStyle.equalsAnyOf(TextStyleEnum.BOLD, TextStyleEnum.BOLDITALIC) && fallbackData.hasBold) {
            path = path + "-Bold";
            name = fallbackData.name + "-Bold";
        } else {
            path = path + "-Regular";
            name = fallbackData.name + "-Regular";
        }
        path = path + (fallbackData.isCJK ? ".otf" : ".ttf");
        return { path, name };
    }

    fontHasGlyphForChar(font, char) {
        const glyph = font.charToGlyph(char);
        if (glyph.index === 0 || glyph.name === ".notdef") {
            return false;
        }
        return true;
    }

    /**
     * Returns a fallback font if the supplied font doesn't have a glyph for the char
     */
    async getFallbackFontIfNeeded(font, char, textStyle, loadCssFont = false) {
        if (!this.fontHasGlyphForChar(font, char)) {
            // Figure out which fallback font to use for this glpyh
            const codePoint = char.codePointAt(0);
            const rangeStart = calculateFallbackRangeStart(codePoint);
            const start = rangeStart.toUpperCase();
            const fallbackData = fallbackRanges[start];
            if (!fallbackData) {
                return this.fallbackFont;
            }

            const { name, path } = this.assembleFallbackFontPath(fallbackData, textStyle);
            const font = await this.loadOpentypeFont(name, getStaticUrl(`/fonts/${path}`), false);

            if (loadCssFont) {
                let weight = 400;
                let italic = false;
                if (textStyle.equalsAnyOf(TextStyleEnum.BOLD, TextStyleEnum.BOLDITALIC)) {
                    weight = 700;
                }
                if (textStyle.equalsAnyOf(TextStyleEnum.ITALIC, TextStyleEnum.BOLDITALIC)) {
                    italic = true;
                }
                await this.loadCssFont(name, getStaticUrl(`/fonts/${path}`), weight, italic);
            }

            return font;
        }
        return null;
    }

    getFontStyle(styles, weight, italic) {
        let foundStyle;
        // Looking up a style with the closest weight
        styles
            .filter(style => style.italic === italic)
            .forEach(style => {
                if (!foundStyle) {
                    foundStyle = style;
                    return;
                }

                // Current style has closer weight?
                if (Math.abs(style.weight - weight) < Math.abs(foundStyle.weight - weight)) {
                    foundStyle = style;
                }
            });

        // Fallback in case there are no italic/non-italic styles
        if (!foundStyle) {
            return this.getFontStyle(styles, weight, !italic);
        }

        return foundStyle;
    }

    async loadCJKFallbackCSSFonts() {
        if (!this.loadCJKFallbackCSSFontsPromise) {
            this.loadCJKFallbackCSSFontsPromise = (async () => {
                await Promise.all(cjkFallbackFonts.map(({ fontFaceName, weight, italic, fontFilePath }) =>
                    // Note: we don't use fetch for these fonts because they're only loaded directly and never via fetch
                    this.loadCssFont(fontFaceName, getStaticUrl(fontFilePath), weight, italic, false)
                ));
            })();
        }

        return this.loadCJKFallbackCSSFontsPromise;
    }

    /**
     * Loads font and all its styles
     */
    async loadFont(fontId) {
        // Gets the current stack trace before promises cause indirection
        const trace = new Error().stack;

        if (!this.loadFontPromises[fontId]) {
            this.loadFontPromises[fontId] = (async () => {
                // Loading the font
                const loadedFont = await (async () => {
                    const builtInFontDefinition = builtInFonts[fontId];
                    if (!builtInFontDefinition) {
                        // Font definition not found in built in fonts -> custom font
                        try {
                            // If this is the fallback font, return
                            //   early before we trigger an error
                            if (fontId === `"Source Sans Pro", sans-serif`) {
                                return this.fallbackFontDef;
                            }

                            const fontAsset = await ds.assets.getAssetById(fontId, "font");

                            // Loading font styles
                            const styles = fontAsset.getAvailableFontStyles();
                            await Promise.all(styles.map(async style => {
                                style.getFontUrl = () => {
                                    if (!style.fontUrlPromise) {
                                        style.fontUrlPromise = fontAsset.getBaseUrl(style.weight, style.italic, style.obsoleteFontType);
                                    }
                                    return style.fontUrlPromise;
                                };

                                // Checking if the font has label and image for the given style, generating and saving them if not
                                const { label, imageData } = fontAsset.get(fontAsset.getStyleAttrKey(style.weight, style.italic)) || {};
                                if (label && imageData) {
                                    style.label = label;
                                    style.imageData = imageData;
                                } else {
                                    // The asset doesn't have label and image data for the style
                                    const fontUrl = await style.getFontUrl();
                                    style.font = await this.loadOpentypeFont(style.fontFaceName, fontUrl, true);
                                    style.label = `${this.getFamilyName(style.font)} ${getFontWeightName(style.weight)}${style.italic ? " Italic" : ""}`;
                                    const imageBlob = await this.drawTextToBlob(style.font, style.label);
                                    style.imageData = await blobToDataURL(imageBlob);

                                    if (fontAsset.canUpdate()) {
                                        // Saving label and image data into the asset
                                        await fontAsset.safeUpdate({ [fontAsset.getStyleAttrKey(style.weight, style.italic)]: { label: style.label, imageData: style.imageData } });
                                    }
                                }
                            }));

                            // Checking if the font asset has base image and label (for the whole family)
                            let label = fontAsset.get("label");
                            let imageData = fontAsset.get("imageData");
                            if (!label || !imageData) {
                                // The asset doesn't have base label and image, generating them and saving into the asset
                                const baseStyle = this.getFontStyle(styles, 400, false);

                                if (!baseStyle.font) {
                                    // Load opentype font if it hasn't been loaded yet
                                    const fontUrl = await baseStyle.getFontUrl();
                                    baseStyle.font = await this.loadOpentypeFont(baseStyle.fontFaceName, fontUrl, true);
                                }

                                label = this.getFamilyName(baseStyle.font);
                                const imageBlob = await this.drawTextToBlob(baseStyle.font, label);
                                imageData = await blobToDataURL(imageBlob);

                                if (fontAsset.canUpdate()) {
                                    // Saving base label and image data into the asset
                                    await fontAsset.safeUpdate({ label, imageData });
                                }
                            }

                            return {
                                isCustomFont: true,
                                label,
                                imageData,
                                styles
                            };
                        } catch (err) {
                            logger.error(err, `loadFont() failed to load font asset ${fontId}, will use fallback font`, { fontId, trace });

                            return this.fallbackFontDef;
                        }
                    }

                    // Loading built in font
                    const entichedStyles = _.cloneDeep(builtInFontDefinition.styles);
                    await Promise.all(entichedStyles.map(async style => {
                        // Async is to mimic the cusom fonts schema
                        style.getFontUrl = () => {
                            if (!style.fontUrlPromise) {
                                style.fontUrlPromise = Promise.resolve(getStaticUrl(`/fonts/${builtInFontFiles[style.fontFaceName]}`));
                            }

                            return style.fontUrlPromise;
                        };
                        style.imageData = getStaticUrl(`/fonts/images/${style.fontFaceName}.png`);
                    }));

                    return {
                        ...builtInFontDefinition,
                        imageData: getStaticUrl(`/fonts/images/${fontId}-base.png`),
                        styles: entichedStyles
                    };
                })();

                // Adding functions for loading opentype and css fonts
                for (const style of loadedFont.styles) {
                    style.loadOpentypeFont = async () => {
                        if (style.font) {
                            return;
                        }

                        const fontUrl = await style.getFontUrl();
                        style.font = await this.loadOpentypeFont(style.fontFaceName, fontUrl, loadedFont.isCustomFont);
                    };

                    style.loadCssFont = async () => {
                        if (style.cssFontLoaded) {
                            return;
                        }

                        const fontUrl = await style.getFontUrl();
                        await this.loadCssFont(fontId, fontUrl, style.weight, style.italic);
                    };
                }

                // Font style lookup function
                loadedFont.getStyle = (weight, italic) => this.getFontStyle(loadedFont.styles, weight, italic);

                // Setting base style
                loadedFont.baseStyle = loadedFont.getStyle(400, false);

                // Adding font id
                loadedFont.id = fontId;

                return loadedFont;
            })();
        }

        return this.loadFontPromises[fontId];
    }

    async loadCssFont(fontId, fontUrl, weight, italic, useFetch = true) {
        const cssFontKey = `${fontId}-${weight}-${italic}`;
        if (!this.loadCssFontPromises[cssFontKey]) {
            this.loadCssFontPromises[cssFontKey] = (async () => {
                // By default we're using fetch to avoid CORS errors
                if (useFetch) {
                    fontUrl = await toDataUrl(fontUrl);
                }
                const cssFont = new FontFace(fontId, `url("${fontUrl}")`, { weight, style: italic ? "italic" : "normal" });
                document.fonts.add(cssFont);
                try {
                    await cssFont.load();
                } catch (err) {
                    logger.error(err, "loadCssFont() failed to load css font", { fontId, fontUrl, weight, italic });
                }
                await document.fonts.ready;
            })();
        }

        await this.loadCssFontPromises[cssFontKey];
    }

    async loadOpentypeFont(fontFaceName, fontUrl, isCustomFont = false) {
        if (!this.loadOpentypeFontPromises[fontFaceName]) {
            this.loadOpentypeFontPromises[fontFaceName] = (async () => {
                const fontArrayBuffer = await fetch(fontUrl).then(res => res.arrayBuffer());
                const font = opentype.parse(fontArrayBuffer);

                font.isCustomFont = isCustomFont;
                font.name = fontFaceName;

                font.cachedMetrics = font.charToGlyph("H").getMetrics();

                // Add to the glyph cache
                app.glyphCache[font.name] = {};

                const hGlyph = font.charToGlyph("H");
                const hGlyphPath = hGlyph.getPath(0, 0, 100);
                let fontHeight;
                if (hGlyphPath.commands.length) {
                    let minPt = _.minBy(hGlyphPath.commands, pt => pt.y).y;
                    let maxPt = _.maxBy(hGlyphPath.commands, pt => pt.y).y;
                    fontHeight = maxPt - minPt;
                } else {
                    fontHeight = NOTO_H_HEIGHT;
                }

                font.fontHeight = fontHeight;

                this.loadOpentypeFontPromises[fontFaceName] = font;

                if (this.hasNaNWidthGlyphs(font)) {
                    ShowWarningDialog({
                        title: "Text rendering may look incorrect.",
                        message: `Your font ${fontFaceName} may be corrupted, and text rendering may not look correct. Try another font if you see problems.`,
                    });
                }

                return font;
            })();
        }

        return this.loadOpentypeFontPromises[fontFaceName];
    }

    getFamilyName(font) {
        let familyName = font.names.preferredFamily?.en;
        if (!familyName) {
            familyName = font.names.fontFamily?.en;
        }
        if (!familyName) {
            familyName = font.names.fullName.en;
        }
        return familyName;
    }

    exportFontPackage(fontPackageName) {
        return fetch(getStaticUrl(`/fonts/FontPackages/${builtInFontExportPackagesMap[fontPackageName]}.zip`))
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const aNode = document.createElement("a");
                aNode.style.display = "none";
                aNode.download = `${builtInFontExportPackagesMap[fontPackageName]}.zip`;
                aNode.href = url;
                document.body.appendChild(aNode);
                aNode.click();
                window.URL.revokeObjectURL(url);
                aNode.remove();
            }).catch(() => {
                ShowErrorDialog({
                    error: "Unable to download font package",
                    message: "An error occurred while downloading the font package. Please contact Beautiful.ai" +
                        " support for more assistance"
                });
            });
    }
}

const fontManager = new FontManager();

export { fontManager };
