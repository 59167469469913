// given a "full" model with special encoding of arrays, derive a "simple" JSON model
// does not modify the input, returns a deep-copy with arrays simplified
// e.g. {foo: 1, bar: {__array__: ["1af234", "459d12"], "1af234": 2, "459d12": 3}} -> {foo: 1, bar: [2,3]}
function deriveSimpleModel(fullModel) {
    if (!fullModel) return null;
    let ret = {};
    if (fullModel.__array__) {
        ret = [];
        fullModel.__array__.map(key => {
            if (fullModel.hasOwnProperty(key)) {
                if (fullModel[key] && typeof fullModel[key] === "object") {
                    ret.push(deriveSimpleModel(fullModel[key]));
                } else {
                    ret.push(fullModel[key]);
                }
            }
        });
    }
    for (let key of Object.keys(fullModel)) {
        if (key !== "__array__" && (fullModel.__array__ || []).indexOf(key) === -1) {
            if (fullModel[key] && typeof fullModel[key] === "object") {
                ret[key] = deriveSimpleModel(fullModel[key]);
            } else {
                ret[key] = fullModel[key];
            }
        }
    }
    return ret;
}

module.exports = deriveSimpleModel;
