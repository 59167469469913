import { $ } from "js/vendor";
import _sanitizeHtml, { IOptions } from "sanitize-html";

// Overriding the toString() method of Range to make it respect
// line breaks
Range.prototype.toString = function toString() {
    const wrapperDiv = document.createElement("div");
    wrapperDiv.style.setProperty("position", "absolute");
    wrapperDiv.style.setProperty("left", "0px");
    wrapperDiv.style.setProperty("top", "0px");
    wrapperDiv.style.setProperty("display", "block");
    wrapperDiv.style.setProperty("hyphens", "manual");
    wrapperDiv.style.setProperty("overflow-wrap", "normal");
    const rangeFragment = this.cloneContents();
    wrapperDiv.append(rangeFragment);
    document.body.appendChild(wrapperDiv);
    const text = wrapperDiv.innerText;
    wrapperDiv.remove();
    return text;
};

interface ExtendedSelection extends Selection {
    modify: (
        command: "move" | "extend",
        direction: "forward" | "backward" | "left" | "right",
        unit: "character" | "word" | "sentence" | "line" | "paragraph" | "lineboundary" | "sentenceboundary" | "paragraphboundary" | "documentboundary"
    ) => void;
}

/**
 * Convenience wrapper around sanitize-html
 */
function sanitizeHtml(html: string, options: IOptions) {
    return _sanitizeHtml(html, {
        // Extending the default config to allow the 'bai' protocol
        // Do not overuse to avoid security issues XXS attacks
        allowedSchemes: ["http", "https", "ftp", "mailto", "tel", "bai"],
        ...options
    })
        // By some reason self-closing br tags break contenteditable
        // when there's a blank line
        .replace(/<br\s*\/>/g, "<br>");
}

export function getSelection() {
    const selection = window.getSelection() as ExtendedSelection;

    if ($(selection.anchorNode).hasClass("input-field") || $(selection.anchorNode).hasClass("MuiInputBase-root")) {
        return null;
    }

    if (selection.type !== "None") {
        return selection;
    }

    return null;
}

export function convertSpansToFontElements(element: HTMLElement | string) {
    // if using a string, convert to an element
    if (typeof element === "string") {
        const str = element;
        element = document.createElement("pre");
        element.innerHTML = str;
    }

    element.querySelectorAll("span").forEach(spanElement => {
        const fontElement = document.createElement("font");
        fontElement.setAttribute("style", spanElement.getAttribute("style"));
        fontElement.replaceChildren(...spanElement.childNodes);
        spanElement.replaceWith(fontElement);
    });

    return element.innerHTML;
}

export function sanitizePastedHtmlText(html: string) {
    const sanitizedHtmlText = sanitizeHtml(html, {
        allowedTags: ["b", "i", "u", "strong", "sub", "sup", "strike", "a", "br", "font"],
        allowedAttributes: {
            "a": ["href"],
            "font": ["class"]
        },
        allowedClasses: {
            "font": ["emphasized", "color-*"]
        }
    });

    // Special case for leftovers from empty lines
    if (sanitizedHtmlText === "<br>") {
        return "";
    }

    return sanitizedHtmlText;
}

export function sanitizeHtmlText(html: string, include: { font?: boolean, bold?: boolean, color?: boolean } = {}) {
    include = {
        font: false,
        bold: true,
        color: true,
        ...include,
    };

    // Filter which styles we keep
    const allowedStyles = {
        "*": {
            "font-size": [/./],
            "font-family": [/./],
            "font-weight": [/./],
            "color": [/./],
        }
    };
    if (!include.font) {
        delete allowedStyles["*"]["font-size"];
        delete allowedStyles["*"]["font-family"];
    }
    if (!include.bold) {
        delete allowedStyles["*"]["font-weight"];
    }
    if (!include.color) {
        delete allowedStyles["*"]["color"];
    }

    const sanitizedHtmlText = sanitizeHtml(html, {
        allowedTags: ["b", "i", "u", "em", "strong", "sub", "sup", "strike", "a", "font", "br", "div", "span"],
        allowedAttributes: {
            "a": ["href", "target", "style", "class", "id"],
            "font": ["style", "class", "data-uid", "data-inner-text"],
            "span": ["style", "class", "contenteditable"]
        },
        allowedStyles,
    });

    // Special case for leftovers from empty lines
    if (sanitizedHtmlText === "<br>") {
        return "";
    }

    return sanitizedHtmlText;
}

export function removeUrlsFromText(text: string) {
    return text
        .replace(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()'@:%_\+.~#?!&//=]*)/gi, "")
        .replace(/\s+/g, " ")
        .trim();
}

export function removeFormattingFromHtmlText(html: string, allowedTags: string[] = [], allowedAttributes: IOptions["allowedAttributes"] = {}) {
    return sanitizeHtml(html, {
        allowedTags: ["br", ...allowedTags],
        allowedAttributes
    });
}

export function htmlToText(html: string) {
    return sanitizeHtml(html, {
        allowedTags: [],
        allowedAttributes: {}
    });
}

export interface SelectionState {
    start: number;
    end: number;
    textLength: number;
    isAllSelected: boolean;
    isAtStart: boolean;
    isAtEnd: boolean;
}

function getUnicodeSafeStringLength(str: string) {
    return [...str].length;
}

export function getSelectionState(element: HTMLElement) {
    const selectionState: SelectionState = {
        start: null,
        end: null,
        textLength: null,
        isAllSelected: false,
        isAtStart: false,
        isAtEnd: false
    };

    const selection = getSelection();
    if (!selection) {
        return selectionState;
    }

    const range = selection.getRangeAt(0);
    const selectedLength = getUnicodeSafeStringLength(range.toString());
    const preCaretRange = document.createRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);

    const preCaretRangeText = preCaretRange.toString();
    selectionState.start = getUnicodeSafeStringLength(preCaretRangeText) - selectedLength;
    selectionState.end = getUnicodeSafeStringLength(preCaretRangeText);

    let elementText = element.innerText;
    if (elementText.endsWith("\n")) {
        elementText = elementText.slice(0, -1);
    }
    selectionState.textLength = getUnicodeSafeStringLength(elementText);

    if (selectionState.start === 0 && selectionState.end === selectionState.textLength) {
        selectionState.isAllSelected = true;
    }

    if (selectionState.start === selectionState.end) {
        selectionState.isAtStart = selectionState.start === 0;

        // See if moving one step further won't change the selection text
        // to handle special cases when element inner html ends with empty divs
        // that can't be selected but affect the text length
        if (selectionState.start === selectionState.textLength - 1) {
            selection.modify("move", "forward", "character");
            const extendedRange = selection.getRangeAt(0);
            const extendedPreCaretRange = document.createRange();
            extendedPreCaretRange.selectNodeContents(element);
            extendedPreCaretRange.setEnd(extendedRange.endContainer, extendedRange.endOffset);
            if (extendedPreCaretRange.toString() === preCaretRangeText) {
                selectionState.isAtEnd = true;
            }
            selection.modify("move", "backward", "character");
        } else if (selectionState.start === selectionState.textLength) {
            selectionState.isAtEnd = true;
        }
    }

    return selectionState;
}

export function setSelection(selectionState: SelectionState, element: HTMLElement) {
    const selection = window.getSelection() as ExtendedSelection;
    if (!selection) {
        return;
    }

    selection.removeAllRanges();
    selection.selectAllChildren(element);
    selection.collapseToStart();
    const { start, end } = selectionState;
    for (let i = 0; i < start; i++) {
        selection.modify("move", "forward", "character");
    }
    for (let i = 0; i < end - start; i++) {
        selection.modify("extend", "forward", "character");
    }
    return selection;
}

export function runForNodeAndAllChildNodes(parentNode: Node, callback: (node: Node) => void) {
    callback(parentNode);
    for (const child of parentNode.childNodes) {
        runForNodeAndAllChildNodes(child, callback);
    }
}
