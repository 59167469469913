import React, { Component } from "reactn";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import { DialogContent, BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";

export default class MandatoryUpdateDialog extends Component {
    render() {
        const { message } = this.props;
        return (
            <BeautifulDialog>
                <DialogTitle>Refresh Required</DialogTitle>
                <DialogContent>
                    {message}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => window.location.reload()}
                    >
                        Refresh
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
