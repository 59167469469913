import React, { Component } from "react";
import { Icon, Menu, MenuItem } from "@material-ui/core";
import { FlexBox } from "js/react/components/LayoutGrid";
import { FlexSpacer } from "js/react/components/Gap";
import styled from "styled-components";

export const NestedMenu = styled(Menu)`
  &.small {
    .MuiList-padding {
      padding: 0px;
    }

    .MuiMenuItem-root {
      padding: 10px 15px 10px 10px;
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-weight: 600;
    }

    .MuiIcon-root {
      font-size: 1.3rem;
      margin-right: 10px;
      color: #666;
    }
  }
`;

export class NestedMenuItem extends Component {
    state = {
        anchorEl: null
    }

    constructor() {
        super();
        this.ref = React.createRef();
        this.nestedRef = React.createRef();
    }

    handleShowMenu = () => {
        this.setState({ anchorEl: this.ref.current });
    }

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    }

    handleNestedScrolling = event => {
        const scrollable = this.nestedRef.current?.querySelector(".MuiPaper-root");
        // If the nested menu is not scrollable, we don't want to scroll it
        if (!scrollable) {
            return;
        }
        scrollable.scrollTop += event.deltaY;
    }

    render() {
        let {
            id,
            icon,
            label,
            contents,
            divider,
            showNestedOnClick = false,
            disabled,
            nestedMenuClassName = "",
            style = { paddingRight: 5, display: "block" },
            className = "",
            onChildClick,
            anchorOrigin,
            transformOrigin,
        } = this.props;
        let { anchorEl } = this.state;

        if (anchorEl && anchorEl.getBoundingClientRect().right > window.innerWidth - 200) {
            anchorOrigin = {
                vertical: "top",
                horizontal: "left",
                ...anchorOrigin,
            };
            transformOrigin = {
                vertical: "top",
                horizontal: "right",
                ...transformOrigin,
            };
        } else {
            anchorOrigin = {
                vertical: "top",
                horizontal: "right",
                ...anchorOrigin,
            };
            transformOrigin = {
                vertical: "top",
                horizontal: "left",
                ...transformOrigin,
            };
        }

        return (
            <MenuItem
                id={id}
                ref={this.ref}
                className={className}
                onMouseDown={showNestedOnClick ? this.handleShowMenu : null}
                onMouseEnter={showNestedOnClick ? null : this.handleShowMenu}
                onMouseLeave={showNestedOnClick ? null : this.handleCloseMenu}
                onWheel={this.handleNestedScrolling}
                style={style}
                disabled={disabled}
                divider={divider}
            >
                <FlexBox fillWidth left middle
                >
                    {icon}
                    {label}
                    {contents}
                    <FlexSpacer />
                    <Icon>keyboard_arrow_right</Icon>
                </FlexBox>

                <NestedMenu
                    className={`nested-menu ${nestedMenuClassName}`}
                    style={{ pointerEvents: showNestedOnClick ? "auto" : "none" }}
                    open={Boolean(anchorEl)}
                    ref={this.nestedRef}
                    anchorEl={anchorEl}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    container={this.ref.current}
                    onClose={this.handleCloseMenu}
                >
                    <div style={{ pointerEvents: "auto" }}>
                        {React.Children.map(this.props.children, child => {
                            if (child) {
                                if (child.props.onClick) {
                                    return React.cloneElement(child, {
                                        onClick: () => {
                                            onChildClick && onChildClick();
                                            child.props.onClick();
                                        }
                                    });
                                } else {
                                    return React.cloneElement(child, { onClick: onChildClick });
                                }
                            }
                        })}
                    </div>
                </NestedMenu>
            </MenuItem>
        );
    }
}
