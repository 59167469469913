import { IGenericModelFields } from "../IGenericFields";

export enum WorkspacePermissionObjectType {
    RESOURCE_FOLDER = "resourceFolder",
    WORKSPACE_RESOURCE = "workspaceResource"
}

export enum WorkspacePermissionSubjectType {
    USER = "user",
    USER_GROUP = "userGroup"
}

export interface IWorkspacePermission extends IGenericModelFields {
    readonly workspaceId: string;
    readonly objectId: string;
    readonly objectType: WorkspacePermissionObjectType;
    readonly subjectId: string;
    readonly subjectType: string;
    read: boolean;
    write: boolean;
    owner: boolean;
}
