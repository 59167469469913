import { presentations as presentationsApi } from "apis/callables";
import { FeatureType } from "js/core/models/features";
import { app } from "js/namespaces";

class Quotas {
    static async get(workspaceId) {
        const slideLimit = app.user.features.getFeatureProps(FeatureType.CREATE_SLIDES, workspaceId).limit;
        // Expensive, but accurate
        const { slideCount } = await presentationsApi.getSlideCount({ workspaceId, filter: "all" });

        return {
            slides: {
                limit: slideLimit,
                count: slideCount
            }
        };
    }
}

export default Quotas;
