import { BehaviorSubject, combineLatest, of } from "rxjs";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";

import { slides as slidesApi, presentations as presentationsApi } from "apis/callables";
import { isOwnerOrLibrarian } from "common/utils/roles";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import pusher from "js/core/services/pusher";
import db from "js/db";
import { app } from "js/namespaces";

const logger = getLogger(LogGroup.OBSERVABLES);

// Using this as a static entity for caching user profiles
const userProfiles = {};

const getObservableMock = () => ({
    subscribe: () => ({ unsubscribe: () => { } })
});

export class Observables {
    constructor(dataService) {
        // Avoiding circular dependency
        this.dataService = dataService;

        this._slideStatus$ = null;
        this._assignedToSlideUser$ = null;
        this._collaboratorsAndTeammates$ = null;
        this._mapComments$ = null;
        this._collaborators$ = null;
        this._commentsAreByViewer$ = new BehaviorSubject(false);
    }

    get slideStatus$() {
        if (!this._slideStatus$) {
            this._slideStatus$ = window.mockObservables ? getObservableMock() : this._getSlideStatusObservable();
        }

        return this._slideStatus$;
    }

    get assignedToSlideUser$() {
        if (!this._assignedToSlideUser$) {
            this._assignedToSlideUser$ = window.mockObservables ? getObservableMock() : this._getAssignedToSlideUserObservable();
        }

        return this._assignedToSlideUser$;
    }

    get collaboratorsAndTeammates$() {
        if (!this._collaboratorsAndTeammates$) {
            this._collaboratorsAndTeammates$ = window.mockObservables ? getObservableMock() : this._getCollaboratorsAndTeammatesObservable();
        }

        return this._collaboratorsAndTeammates$;
    }

    setCommentsAreByViewer(byViewers) {
        this._commentsAreByViewer$.next(byViewers);
    }

    get areCommentsByViewer$() {
        this._initCommentsObservables();
        return this._mapComments$.commentsAreByViewer$;
    }

    get allComments$() {
        this._initCommentsObservables();
        return this._mapComments$.allComments$;
    }

    get slideComments$() {
        this._initCommentsObservables();
        return this._mapComments$.slideComments$;
    }

    get collaborators$() {
        if (!this._collaborators$) {
            this._collaborators$ = window.mockObservables ? getObservableMock() : this._getCollaboratorsObservable();
        }

        return this._collaborators$;
    }

    _initCommentsObservables() {
        if (!this._mapComments$) {
            // Whether we only want viewer or internal comments (defaults to true)
            const commentsAreByViewer$ = this._commentsAreByViewer$.asObservable().pipe(
                distinctUntilChanged(),
            );

            const _presentation$ = new BehaviorSubject(null);
            const presentation$ = _presentation$.asObservable().pipe(
                distinctUntilChanged(),
            );

            // Current slide id
            const _slideId$ = new BehaviorSubject(this.dataService.selection?.slide.id);
            const slideId$ = _slideId$.asObservable().pipe(
                distinctUntilChanged(),
            );

            const _slidesChanged$ = new BehaviorSubject(true);
            const slidesChanged$ = _slidesChanged$.asObservable();

            const slidesMetadata$ = combineLatest([
                presentation$,
                slidesChanged$, // This is need to trigger a data refresh every time the slide changes
            ]).pipe(
                switchMap(([
                    presentation,
                ]) => {
                    let slidesMetadataById = null;
                    if (presentation && presentation.get("isDummy") !== true) {
                        slidesMetadataById = presentation.slidesMetadata;
                        if (!slidesMetadataById) {
                            return presentationsApi.getSlidesMetadata({ id: presentation.id });
                        }
                    }
                    return of(slidesMetadataById || {});
                }),
                map(slidesMetadataById => {
                    const slidesMetadata = Object.entries(slidesMetadataById).map(([id, data]) => ({
                        id,
                        ...data,
                    }));
                    return slidesMetadata;
                }),
                distinctUntilChanged(),
            );

            // All commetns for the current presentation
            let _allCommentsById = {};
            const _allCommentsById$ = new BehaviorSubject(_allCommentsById);
            const allCommentsById$ = _allCommentsById$.asObservable();
            const allComments$ = combineLatest([
                commentsAreByViewer$,
                slidesMetadata$,
                allCommentsById$,
            ]).pipe(
                map(([
                    byViewer,
                    slidesMetadata,
                    commentsById,
                ]) => {
                    let comments = Object.values(commentsById);
                    comments = comments
                        .filter(comment => {
                            const result = (
                                !!comment.byViewer === byViewer &&
                                slidesMetadata.some(slide =>
                                    (
                                        // If we're showing the player, we exclude skipped slides
                                        !window.isPlayer ||
                                        !slide.isSkipped
                                    ) &&
                                    slide.id === comment.slideId
                                )
                            );
                            return result;
                        })
                        .sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
                    return comments;
                }),
            );

            // Current slide index
            const slideIndex$ = combineLatest([
                presentation$,
                slideId$,
            ]).pipe(
                map(([presentation, slideId]) => presentation?.getSlideIndex(slideId)),
            );

            // Current slide comments
            const slideComments$ = combineLatest([
                slideId$,
                allComments$
            ]).pipe(
                map(([slideId, comments]) => comments.filter(comment => comment.slideId === slideId))
            );

            let slidesRef;
            let commentsRefs = {};
            const onPresentationChanged = async presentation => {
                _presentation$.next(presentation);
                const presentationId = presentation?.id;

                // Unsubscribing from all existing comments listeners
                if (slidesRef) {
                    slidesRef.off();
                }
                Object.values(commentsRefs)
                    .forEach(commentsRef => commentsRef.off());
                commentsRefs = {};

                // Reset all comments to empty
                _allCommentsById = [];

                // No presentation or slide selected
                if (!presentationId) {
                    _allCommentsById$.next(_allCommentsById);
                    return;
                }

                // Slide comments ref
                slidesRef = db("comments").child(presentationId);

                // Subscribing for changes from the Firebase
                slidesRef.on("child_added", snapshot => {
                    _slidesChanged$.next(true);

                    const slideId = snapshot.key;
                    const slideIndex = presentation.getSlideIndex(slideId);

                    // Clear any existing listeners on the comments ref
                    let commentsRef = commentsRefs[slideId];
                    commentsRef && commentsRef.off();

                    // Handle comment add/update
                    const snapshotUpdateComment = async snapshot => {
                        try {
                            const commentId = snapshot.key;
                            let comment = snapshot.val();

                            // Enrich the comment
                            const author = await getCommentAuthor(presentationId, slideId, commentId, comment);
                            comment = {
                                ...comment,
                                id: commentId,
                                slideId,
                                slideIndex,
                                author,
                            };

                            _allCommentsById[commentId] = comment;
                            _allCommentsById$.next(_allCommentsById);
                        } catch (err) {
                            logger.error(err, "_initCommentsObservables() snapshotUpdateComment() failed");
                        }
                    };

                    commentsRef = slidesRef.child(slideId);
                    commentsRef.on("child_added", snapshotUpdateComment);
                    commentsRef.on("child_changed", snapshotUpdateComment);
                    commentsRef.on("child_removed", async snapshot => {
                        // Handle comment delete
                        const commentId = snapshot.key;
                        delete _allCommentsById[commentId];
                        _allCommentsById$.next(_allCommentsById);
                    });

                    // Store the ref so we can stop listening later
                    commentsRefs[slideId] = commentsRef;
                });
                slidesRef.on("child_removed", snapshot => {
                    _slidesChanged$.next(true);

                    const slideId = snapshot.key;

                    // Stop listening to comment changes for the deleted slide
                    const commentsRef = commentsRefs[slideId];
                    commentsRef && commentsRef.off();
                    delete commentsRefs[slideId];

                    // Remove all comments for the deleted slide
                    Object.values(_allCommentsById)
                        .forEach(comment => {
                            if (comment.slideId === slideId) {
                                delete _allCommentsById[comment.id];
                            }
                        });
                    _allCommentsById$.next(_allCommentsById);
                });
            };

            const onSlideChanged = slide => {
                const slideId = slide?.id;
                _slideId$.next(slideId);
            };

            const getCommentAuthor = async (presentationId, slideId, commentId, comment) => {
                // Checking cache
                if (userProfiles[comment.authorUid]) {
                    return userProfiles[comment.authorUid];
                }

                const author = await Api.commentAuthor.get({ presentationId, slideId, commentId });
                // Saving cache
                userProfiles[comment.authorUid] = author;

                return author;
            };

            this.dataService.selection.on("change:presentation", selection => onPresentationChanged(selection?.presentation));
            this.dataService.selection.on("change:slide", selection => onSlideChanged(selection?.slide));
            this.dataService.selection.on("firstSlideSet", () => onSlideChanged(this.dataService.selection?.slide));
            document.addEventListener("player:change:slide", evt => onSlideChanged(evt.detail?.slide));

            const presentation = this.dataService.selection?.presentation;
            onPresentationChanged(presentation);

            this._mapComments$ = {
                commentsAreByViewer$,
                allComments$,
                slideId$,
                slideIndex$,
                slideComments$,
            };
        }
    }

    _getSlideStatusObservable() {
        // Subject and observable, will be starting from null
        const _slideStatus$ = new BehaviorSubject(null);
        const slideStatus$ = _slideStatus$.asObservable();

        let currentSlide;
        const onSelectionChanged = selection => {
            const slide = selection.slide;
            if (slide === currentSlide) {
                return;
            }

            // Unsubscribing from current slide
            if (currentSlide) {
                currentSlide.off("change", onSlideChanged);
            }
            currentSlide = slide;

            if (!slide) {
                _slideStatus$.next(null);
                return;
            }

            // The player doesn't allow listening, so we skip listening to the slide status
            if (slide.on) {
                // Subscribing for new slide
                slide.on("change", onSlideChanged);

                // Triggering onSlideChanged() to set current slide status value
                onSlideChanged(slide);
            }
        };
        const onSlideChanged = async slide => {
            try {
                // Make sure the model loaded
                await slide.loadPromise;
                // Wait for the update to propagate to the db
                // (if triggered by a local update)
                if (slide.updatePromise) {
                    await slide.updatePromise;
                }

                if (currentSlide.id !== slide.id) {
                    return;
                }

                const slideStatus = slide.get("status");
                if (slideStatus) {
                    _slideStatus$.next(slideStatus);
                } else {
                    _slideStatus$.next(null);
                }
            } catch (err) {
                logger.error(err, "_getSlideStatusObservable() onSlideChanged() failed");
            }
        };

        this.dataService.selection.on("change:slide", onSelectionChanged);
        this.dataService.selection.on("firstSlideSet", () => onSelectionChanged(this.dataService.selection));

        // Fetching the initial state
        onSelectionChanged(this.dataService.selection);

        return slideStatus$;
    }

    _getAssignedToSlideUserObservable() {
        // Subject and observable, will be starting from null
        const _assignedToSlideUser$ = new BehaviorSubject(null);
        const assignedToSlideUser$ = _assignedToSlideUser$.asObservable();

        let currentSlide;
        const onSelectionChanged = selection => {
            const slide = selection.slide;
            if (slide === currentSlide) {
                return;
            }

            // Unsubscribing from current slide
            if (currentSlide) {
                currentSlide.off("change", onSlideChanged);
            }
            currentSlide = slide;

            if (!slide) {
                _assignedToSlideUser$.next(null);
                return;
            }

            // The player doesn't allow listening, so we skip the loading assignees
            if (slide?.on) {
                // Subscribing for new slide
                slide.on("change", onSlideChanged);

                // Triggering onSlideChanged() to set current assigned user value
                onSlideChanged(slide);
            }
        };
        const onSlideChanged = async slide => {
            try {
                // Make sure the model loaded
                await slide.loadPromise;
                // Wait for the update to propagate to the db
                // (if triggered by a local update)
                if (slide.updatePromise) {
                    // No error logging here, we're just waiting for the update to propagate
                    await slide.updatePromise.catch(() => { });
                }

                const assignedUserUid = slide.get("assignedUser");
                const pendingUserEmail = slide.get("assignedPendingUser");

                if (assignedUserUid) {
                    if (assignedUserUid === app.user.id) {
                        // Optimization to avoid calling server to get current user
                        _assignedToSlideUser$.next(app.user.getAuthUser());
                    } else if (userProfiles[assignedUserUid]) {
                        // Found in cache
                        _assignedToSlideUser$.next(userProfiles[assignedUserUid]);
                    } else {
                        const user = await slidesApi.getSlideOwner({
                            presentationId: this.dataService.selection.presentation.id,
                            id: slide.id
                        });

                        // Caching
                        userProfiles[assignedUserUid] = user;

                        _assignedToSlideUser$.next(user);
                    }
                } else if (pendingUserEmail) {
                    _assignedToSlideUser$.next({ email: pendingUserEmail, isPending: true });
                } else {
                    _assignedToSlideUser$.next(null);
                }
            } catch (err) {
                logger.error(err, "_getAssignedToSlideUserObservable() onSlideChanged() failed");
            }
        };

        this.dataService.selection.on("change:slide", onSelectionChanged);
        this.dataService.selection.on("firstSlideSet", () => onSelectionChanged(this.dataService.selection));

        // Fetching the initial state
        onSelectionChanged(this.dataService.selection);

        return assignedToSlideUser$;
    }

    _getCollaboratorsAndTeammatesObservable() {
        // Subject and observable
        const _collaboratorsAndTeammates$ = new BehaviorSubject({ collaborators: [], teammates: [] });
        const collaboratorsAndTeammates$ = _collaboratorsAndTeammates$.asObservable();

        const fetchCollaborators = async presentation => {
            const { permissions: presentationPermissions } = await Api.permissions.get({ id: presentation.id });
            const collaborators = presentationPermissions
                .map(permission => ({
                    uid: permission.id,
                    email: permission.email,
                    displayName: permission.displayName,
                    permissionType: permission.type,
                    photoURL: permission.photoURL,
                    isPending: permission.pending
                }));
            return collaborators;
        };

        const fetchTeammates = async (presentation, collaborators) => {
            const teammates = [];
            const workspaceId = presentation.getWorkspaceId();
            if (workspaceId !== "personal") {
                const isTemplate = presentation.get("isTemplate");
                const defaultTeam = this.dataService.teams.defaultTeamForOrg(workspaceId);
                const memberRoles = defaultTeam.get("members");
                let teamMembers = await Api.teamMembers.get({ teamId: defaultTeam.id });
                teamMembers = teamMembers.filter(teamMember => {
                    const role = memberRoles[teamMember.uid]?.role;
                    // Don't list non-librarian team members when we're dealing with a presentation template
                    return (
                        !isTemplate ||
                        isOwnerOrLibrarian(role)
                    );
                });
                teammates.push(...teamMembers);

                for (let index = collaborators.length - 1; index > -1; --index) {
                    const collaborator = collaborators[index];
                    const role = memberRoles[collaborator.uid]?.role;
                    if (
                        isTemplate &&
                        !isOwnerOrLibrarian(role)
                    ) {
                        // Don't list non-librarian collaborators when we're dealing with a presentation template
                        collaborators.splice(index, 1);
                    } else if (teammates.some(teammate => teammate.uid === collaborator.uid)) {
                        collaborator.teamName = defaultTeam.get("name");
                    }
                }
            }
            return teammates;
        };

        const onPresentationChanged = async presentation => {
            try {
                // No presentation or dummy presentation, set empty state
                if (!presentation || presentation.get("isDummy") === true || presentation.disconnected) {
                    _collaboratorsAndTeammates$.next({ collaborators: [], teammates: [] });
                    return;
                }

                // Making sure we have write permissions
                await presentation.getUserPermissions(true);
                if (!presentation.permissions.write) {
                    _collaboratorsAndTeammates$.next({ collaborators: [], teammates: [] });
                    return;
                }

                // Fetch collaborators and teammates
                const collaborators = await fetchCollaborators(presentation);
                const teammates = await fetchTeammates(presentation, collaborators);
                _collaboratorsAndTeammates$.next({ collaborators, teammates });
            } catch (err) {
                logger.error(err, "_getCollaboratorsAndTeammatesObservable() onPresentationChanged() failed");
            }
        };

        this.dataService.selection.on("change:presentation", selection => onPresentationChanged(selection.presentation));

        // This will be used to trigger refresh when a collaborator was added to a presentation because we can't
        // listen to that ourselves
        this.dataService.on("presentationPermissionsChanged", () => onPresentationChanged(this.dataService.selection.presentation));

        // Fetching the initial state
        onPresentationChanged(this.dataService.selection.presentation);

        return collaboratorsAndTeammates$;
    }

    _getCollaboratorsObservable() {
        // Subject and observable, will be starting from null
        const _collaborators$ = new BehaviorSubject({});
        const collaborators$ = _collaborators$.asObservable();

        let pusherChannel;
        const leaveChannel = () => {
            pusherChannel.unbind_all();
            pusher.unsubscribe(pusherChannel.name);
            pusherChannel = null;
        };

        // This will be storing the current collaborators state
        let collaborators = {};

        const onSlideChanged = slide => {
            if (!pusherChannel) {
                return;
            }

            // Let everybody know the slide we're on
            pusherChannel.trigger("client-on-slide", { slideId: slide?.id });
            // Trigger refresh to allow the collaboration view to refresh on slide changes
            _collaborators$.next(collaborators);
        };

        let currentPresentationId;
        const onPresentationChanged = async presentation => {
            // No presentation or slide, or presentation is dummy, ignore
            if (!presentation || presentation.get("isDummy") === true) {
                // Leave current channel
                if (pusherChannel) {
                    leaveChannel();
                }
                // Set empty state
                collaborators = {};
                _collaborators$.next(collaborators);
                return;
            }

            try {
                // Presentation changed or there's no channel, then subscribe to channel
                if (currentPresentationId !== presentation.id || !pusherChannel) {
                    // Set empty state, will report below
                    collaborators = {};

                    if (pusherChannel) {
                        // The existing channel listens to another presentation, leave the channel
                        leaveChannel();
                    }

                    // Making sure we have write permissions
                    await presentation.getUserPermissions(true);
                    if (!presentation.permissions.write) {
                        _collaborators$.next(collaborators);
                        return;
                    }

                    // Connect to new channel and binding events
                    pusherChannel = await pusher.subscribe(`presence-presentation-collaboration-${presentation.id}`);
                    if (!pusherChannel) {
                        // Didn't subscribe (pusher is disabled)
                        _collaborators$.next(collaborators);
                        return;
                    }

                    pusherChannel.bind("pusher:member_added", ({ id: uid, info: user }) => {
                        // Let new user know which slide we're on
                        pusherChannel.trigger("client-on-slide", { slideId: this.dataService.selection.slide?.id });

                        // Add new user
                        collaborators[uid] = user;
                        _collaborators$.next(collaborators);
                    });

                    pusherChannel.bind("pusher:member_removed", ({ id: uid }) => {
                        // Remove user
                        delete collaborators[uid];
                        _collaborators$.next(collaborators);
                    });

                    pusherChannel.bind("client-on-slide", ({ slideId }, { user_id: uid }) => {
                        // Updating the slide id value on the collaborator (can be null)
                        collaborators[uid].slideId = slideId;
                        _collaborators$.next(collaborators);
                    });

                    // Filling the initial state
                    pusherChannel.members.each(({ id: uid, info: user }) => {
                        collaborators[uid] = user;
                    });
                    _collaborators$.next(collaborators);

                    currentPresentationId = presentation.id;

                    // Force updating current slide (if present)
                    onSlideChanged(this.dataService.selection.slide);
                }
            } catch (err) {
                logger.error(err, "_getCollaboratorsObservable() onPresentationChanged() failed");
            }
        };

        this.dataService.selection.on("change:presentation", selection => onPresentationChanged(selection.presentation));
        this.dataService.selection.on("change:slide", selection => onSlideChanged(selection.slide));
        this.dataService.selection.on("firstSlideSet", () => onSlideChanged(this.dataService.selection.slide));

        // Fetching the initial state
        onPresentationChanged(this.dataService.selection.presentation);

        return collaborators$;
    }
}
