import React, { Fragment } from "react";
import styled from "styled-components";
import { Popover } from "@material-ui/core";
import slug from "slug";
import * as social from "js/core/utilities/social";
import { sanitizeSvg } from "js/core/utilities/dompurify";
import { ds } from "js/core/models/dataService";

const PopupContentContainer = styled.div`
  background: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
`;

const ShareMessage = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #444;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  margin-bottom: 10px;
`;

const SocialNetworkButton = styled.div`
  cursor: pointer;
`;

export function SocialPopupButtons({
    onClose = () => { },
    largeButtons = false,
}) {
    let presentation = ds.selection.presentation;
    let presentationName = presentation?.get("name") || "";
    let linkId = presentation?.get("link")?.id || "";
    return (
        <Fragment>
            {social.networks.map(({ name, icon }) => (
                <SocialNetworkButton
                    key={name}
                    large={largeButtons}
                    dangerouslySetInnerHTML={{ __html: sanitizeSvg(icon) }}
                    onClick={event => {
                        event.stopPropagation();

                        social.createSharePresentationPopup(
                            name,
                            `${window.location.origin}/player/${linkId}/${slug(presentationName)}`,
                            presentationName
                        );

                        onClose();
                    }}
                />
            ))}
        </Fragment>
    );
}

export default function SocialPopup({ isOpen, onClose, anchorEl, presentation }) {
    let linkId = presentation.id;
    const link = presentation.get("link");
    link && (linkId = link.id);

    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            disableEnforceFocus
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            onClose={onClose}
        >
            <PopupContentContainer>
                <ShareMessage>Share this deck</ShareMessage>
                <SocialPopupButtons
                    onClose={onClose}
                    linkId={linkId}
                    presentationName={presentation.get("name")}
                />
            </PopupContentContainer>
        </Popover>
    );
}
