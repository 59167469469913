const SlideRecordingType = {
    NONE: "none",
    AUDIO: "audio",
    VIDEO: "video"
};

const ContentBlockType = {
    TEXT: "text",
    BIGTEXT: "bigtext",
    HEADLINE: "headline",
    HEADING: "heading",
    TITLE: "title",
    BODY: "body",
    LABEL: "label",
    CAPTION: "caption",
    DIVIDER: "divider",
    ICON: "icon",
    LIST: "list",
    BULLET_LIST: "bulleted",
    NUMBERED_LIST: "numbered",
    BULLET_POINT: "bulletpoint",
};

const ParagraphStyle = {
    PARAGRAPH: "paragraph",
    BULLET_LIST: "bullet_list",
    NUMBERED_LIST: "numbered_list"
};

const TextFocusType = {
    START: "start",
    END: "end",
    ALL: "all",
    POSITION: "position"
};

const ListStyleType = {
    TEXT: "no_bullets",
    BULLET: "bullets",
    NUMBERED: "numbered",
    CHECKBOX: "checkbox",
    ICON: "icon"
};

const TextBreakType = {
    DEFAULT: "default",
    EVEN: "even"
};

const TextBlockPreset = {
    DEFAULT: [],
    NODE_ELEMENT: [ContentBlockType.HEADING, ContentBlockType.TITLE, ContentBlockType.BODY, ContentBlockType.CAPTION, ContentBlockType.DIVIDER],
    TITLE_AND_BODY: [ContentBlockType.TITLE, ContentBlockType.BODY],
    TITLE_BODY_AND_LIST: [ContentBlockType.TITLE, ContentBlockType.BODY, ContentBlockType.BULLET_LIST, ContentBlockType.NUMBERED_LIST],
    HEADLINE: [ContentBlockType.HEADLINE, ContentBlockType.HEADING, ContentBlockType.TITLE, ContentBlockType.BODY, ContentBlockType.CAPTION, ContentBlockType.DIVIDER, ContentBlockType.ICON]
};

const AuthoringElementType = {
    PATH: "path",
    SHAPE: "shape",
    CONTENT: "content",
    TEXT: "text",
    COMPONENT: "component",
    ANIMATION: "animation",
    HEADER: "header",
    FOOTER: "footer",
    SVG: "svg",
    RADIAL_BAR: "radial_bar",
    VIDEO: "video",
};

const AuthoringBlockType = {
    TEXT: "text",
    CODE: "code",
    EQUATION: "equation",
    DIVIDER: "divider",
    ELEMENT: "element",
    MEDIA: "media",
    // ICON: "icon",
    CARDS: "cards",
    REGION_START: "region-start",
    REGION_END: "region-end"
};

const BlockStructureType = {
    FREEFORM: "none", // content blocks and document view
    SINGLE_BLOCK: "single_block", // single block of text like arrow bars
    TITLE_AND_BODY: "title_and_body", // text groups
    HEADER: "header" // label, heading, body
};

const BlockStyleType = {
    NONE: "none",
    INSET: "inset",
    COLOR: "color"
};

const AuthoringShapeType = {
    NONE: "none",
    RECT: "rect",
    ELLIPSE: "ellipse",
    ARROW: "arrow",
    CHEVRON: "chevron",
    CHEVRON_START: "chevron_start",
    TRIANGLE: "triangle",
    CALLOUT_BUBBLE: "callout_bubble",
    CALLOUT_BOX: "callout_box",
    STAR: "star",
    CAPSULE: "capsule",
    POLYGON: "polygon",
    DIAMOND: "diamond",
    PLUS: "plus",
    MINUS: "minus",
    TIMES: "times",
    DIVIDED: "divided",
    EQUALS: "equals",
    GREATER_THAN: "greater_than",
    LESS_THAN: "less_than",
    RAW_SVG: "raw_svg"
};

const TextStyleType = {
    BIG_TEXT: "bigtext",
    HEADLINE: "headline",
    HEADING: "heading",
    TITLE: "title",
    BODY: "body",
    LABEL: "label",
    CAPTION: "caption",
    BULLET_LIST: "bulleted",
    NUMBERED_LIST: "numbered",
    AGENDA_ITEM: "agendaitem",
    SECTION: "section"
};

const TextEditorEvent = {
    ENTER_KEY: "enterKey",
    ENTER_ON_BLANK_LINE: "enterkeyonblankline",
    BACKSPACE_ON_BLANK: "backspaceonblank",
    LEFT_ARROW: "leftarrow",
    RIGHT_ARROW: "rightarrow",
    UP_ARROW: "uparrow",
    DOWN_ARROW: "downarrow",
    SELECTION_CHANGED: "selectionchanged"
};

const ASSET_SIZES = {
    [240 * 240]: "xsmall",
    [360 * 360]: "small",
    [720 * 720]: "medium",
    [1080 * 1080]: "large",
    [1600 * 1600]: "xlarge"
};
const ASSET_LOAD_TIMEOUT = 30 * 1000;
const ASSET_CACHE_TTL_SECONDS = 60 * 60 * 24 * 90; // 90 days
const ASSET_AREAS = Object.keys(ASSET_SIZES).reduce((acc, area) => {
    const key = ASSET_SIZES[area];
    acc[key] = parseInt(area);
    return acc;
}, {});

const ASSET_SIZE_REGEX = /-(\d+)$/;
const OLD_ASSET_SIZES = ["240", "360", "720", "1080", "1600"];

const THUMBNAIL_SIZES = {
    small: {
        width: 480,
        suffix: null
    },
    large: {
        width: 1920,
        suffix: "large"
    }
};
const THUMBNAIL_CACHE_TTL_SECONDS = 60 * 60 * 24 * 90; // 90 days

const LOGO_CACHE_TTL_SECONDS = 60 * 60 * 24 * 90; // 90 days

const ACCEPTED_DATE_FORMATS = [
    "MMM",
    "MMM D",
    "MMM Do",
    "MMM YY",
    "MMM YYYY",
    "ll",
    "LL",
    "M/D",
    "MM/DD/YY",
    "M/D/YYYY",
    "L",
    "YYYY",
    "YYYY-MM",
    "YYYY-MM-DD",
    "MM-DD-YYYY",
    "MM.DD.YYYY"
];

const CHART_DATE_FORMATS = [
    "none",
    "abbr-month",
    "abbr-month-year",
    "MMM",
    "MMM D",
    "MMM Do",
    "MMM YY",
    "MMM YYYY",
    "ll",
    "M/D",
    "MM/DD/YY",
    "M/D/YYYY",
    "MM-DD-YYYY",
    "MM.DD.YYYY"
];

const APP_UNLIMITED_SLIDE_COUNT = 9999;

// NB: firbase requires these to be string keys
const PERMISSION_RESOURCE_TYPE = {
    PRESENTATION: "0",
    ORG: "1",
    THEME: "2",
    TEAM: "3",
    SHARED_THEME: "4",
    USER_FOLDER: "5",
    TEAM_ASSET: "6",
};

const ElementEventType = {
    LOADED: "loaded",
    SELECTED: "selected",
    UNSELECTED: "unselected",
    CHANGE: "change",
    RESIZE: "resize",
    LAYOUT: "layout",
    POSITIONED: "positioned",
    REMOVED: "removed",
    REFRESH: "refresh",
    EDIT: "edit",
    CONTENT_CHANGED: "contentChanged",
    INVALID_LAYOUT: "invalidLayout",
    SLIDE_LAYOUT: "slideLayout"
};

const position = {
    TOP: "top",
    BOTTOM_CENTER: "bottom",
    BOTTOM_LEFT: "bottom_left",
    BOTTOM_RIGHT: "bottom_right",
    CONTROL: "control"
};

const ConnectorType = {
    STRAIGHT: "straight",
    ANGLE: "angle",
    STEP: "step",
    ARC: "arc"
};

const UndoType = {
    SLIDE_DATA: "slideData",
    SLIDE_ORDER: "slideOrder",
    DELETE_SLIDE: "deleteSlide",
    ADD_SLIDE: "addSlide",
};

const ViewName = {
    NONE: "none",
    PRESENTATIONS: "presentations",
    EDITOR: "editor",
    SLIDE_DEMO: "slide-demo",
    AUTH: "auth",
};

const AdvanceSlideOnType = {
    CLICK: "click",
    DELAY: "delay",
    AUDIO: "audio",
    VIDEO: "video"
};

const ForeColorType = {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    SLIDE: "slide",
    NEUTRAL: "neutral",
    COLORFUL: "colorful",
    TRAY: "tray"
};

const PaletteColorType = {
    PRIMARY_LIGHT: "primary_light",
    PRIMARY_DARK: "primary_dark",
    SECONDARY_LIGHT: "secondary_light",
    SECONDARY_DARK: "secondary_dark",
    THEME: "theme",
    HILITE: "hilite",
    ACCENT1: "accent1",
    ACCENT2: "accent2",
    ACCENT3: "accent3",
    ACCENT4: "accent4",
    ACCENT5: "accent5",
    ACCENT6: "accent6",
    ACCENT7: "accent7",
    ACCENT8: "accent8",
    BACKGROUND_DARK: "background_dark",
    BACKGROUND_LIGHT: "background_light",
    BACKGROUND_ACCENT: "background_accent",
    POSITIVE: "positive",
    NEGATIVE: "negative",
    HYPERLINK: "hyperlink",
};

const BackgroundStyleType = {
    LIGHT: "backgroundLight",
    DARK: "backgroundDark",
    ACCENT: "backgroundAccent",
    COLOR: "backgroundColor",
    IMAGE: "backgroundImage",
    GRADIENT: "backgroundGradient",
};

const AssetType = {
    IMAGE: "image",
    ICON: "icon",
    LOGO: "logo",
    AUDIO: "audio",
    MAP: "map",
    FONT: "font",
    SVG: "svg",
    VIDEO: "video",
    STOCK_VIDEO: "stock_video",
};

const IMAGE_ASSET_TYPES = [AssetType.IMAGE, AssetType.LOGO, AssetType.MAP];

const ShowFooterType = {
    THEME: "theme",
    OFF: "off",
    ON: "on"
};

const ContentFrameType = {
    RECT: "rect",
    CIRCLE: "circle",
    FLEX_CIRCLE: "flexCircle",
    CALLOUT_BOX: "callout_box",
    CALLOUT_BUBBLE: "callout_bubble",
    PENTAGON: "pentagon",
    HEXAGON: "hexagon",
    OCTAGON: "octagon",
    NONE: "none"
};

const ASSET_FILETYPE = {
    JPG: "jpg",
    PNG: "png",
    SVG: "svg",
    GIF: "gif",
    JPEG: "jpeg",

    MP4: "mp4",
    MOV: "mov",
    WMV: "wmv",
    AVI: "avi",
    MKV: "mkv",
    WEBM: "webm",
};

const ASSET_ABBREVIATIONS = {
    [AssetType.IMAGE]: "I",
    [AssetType.ICON]: "C",
    [AssetType.LOGO]: "L",
    [AssetType.AUDIO]: "A",
    [AssetType.MAP]: "M",
    [AssetType.FONT]: "F",
    [AssetType.SVG]: "S",
    [AssetType.VIDEO]: "V",
    [AssetType.STOCK_VIDEO]: "S_V",
};

const ASSET_FILETYPE_TO_MIMETYPE = {
    jpg: "image/jpeg",
    png: "image/png",
    svg: "image/svg+xml",
    gif: "image/gif"
};

const ASSET_MIMETYPE_TO_FILETYPE = {
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/svg+xml": "svg",
    "image/gif": "gif"
};

const GOOGLE_ASSET_MIMETYPES = Object.keys(ASSET_MIMETYPE_TO_FILETYPE);

const GOOGLE_VIDEO_MIMETYPES = [
    "video/mp4",
    "video/mov",
    "video/wmv",
    "video/avi",
    "video/mkv",
    "video/webm"
];

const ASSET_RESOLUTION = {
    XSMALL: "xsmall",
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
    XLARGE: "xlarge"
};

const ASSET_MAX_AREA = 1500 * 1500;
const ASSET_MAX_SIZE = 52484800; // 1024 * 1024 * 50 for 50mb
const ASSET_MAX_UPLOAD_MEGAPIXELS = 200; // 200 megapixels

const ASSET_BREAKPOINTS = [
    { area: 1080 * 1080, size: "xlarge" },
    { area: 720 * 720, size: "large" },
    { area: 360 * 360, size: "medium" },
    { area: 240 * 240, size: "small" },
    { area: 0, size: "xsmall" }
];

const JPEG_QUALITY = 95;

const HiliteType = {
    DEEMPHASIZED: -1,
    NONE: 0,
    EMPHASIZED: 1
};

const TextRegionType = {
    TEXT: "text",
    ICON_LIST: "icon_list",
    BULLETS: "bullets",
    NUMBERED: "numbered"
};

const ElementRegionType = {
    TITLE: "title",
    CAPTION: "caption",
    ATTRIBUTION: "attribution",
    IMAGE: "image",
    CONTENT: "content"
};

const TrayType = {
    NONE: "none",
    TOP_INLINE: "top_inline",
    BOTTOM_INLINE: "bottom_inline",
    LEFT_INLINE: "left_inline",
    RIGHT_INLINE: "right_inline",
    LEFT_TRAY: "left_tray",
    RIGHT_TRAY: "right_tray",
    BOTTOM_TRAY: "bottom_tray",
    TOP_TRAY: "top_tray",
    BACKGROUND: "background"
};

const TrayElementType = {
    NONE: "None",
    TEXT: "Text",
    IMAGE: "Image",
    ICON: "Icon",
    HEADLINE: "Headline",
    QUOTE: "Quote",
    BLOCK_TEXT: "Block Text",
    STREET_MAP: "Street Map",
    ICON_LIST: "Icon List",
    BULLET_LIST: "Bullets",
    NUMBERED_LIST: "Numbered List"
};

const ElementTextBlockPositionType = {
    NONE: "none",
    ABOVE: "above",
    BELOW: "below",
    INLINE: "inline",
    TRAY: "tray"
};

const HeaderPositionType = {
    NONE: "none",
    TOP: "top",
    LEFT: "left",
    RIGHT: "right",
    BOTTOM: "bottom",
    CENTER: "center"
};

const HorizontalAlignType = {
    LEFT: "left",
    CENTER: "center",
    RIGHT: "right",
    JUSTIFY: "justify"
};

const VerticalAlignType = {
    TOP: "top",
    MIDDLE: "middle",
    BOTTOM: "bottom"
};

const VerticalBlockAlignType = {
    TOP: "top",
    MIDDLE: "middle",
    BOTTOM: "bottom",
    MIDDLE_OR_TOP: "middle-or-top",
    MIDDLE_TITLE: "center-title"
};

const VerticalPositionType = {
    ABOVE: "above",
    BELOW: "below"
};

const WidgetPositionType = {
    ABOVE: "above",
    BELOW: "below",
    LEFT: "left",
    RIGHT: "right",
    CENTER: "center",
    UPPER_RIGHT: "upper_right",
    UPPER_LEFT: "upper_left",
    BOTTOM_LEFT: "bottom_left",
    BOTTOM_RIGHT: "bottom_right",
    DRAG_HANDLE: "drag_handle",
    DRAG_HANDLE_INSIDE: "inside_drag_handle",
    DELETE_BUTTON: "delete_button"
};

const DragType = {
    SWAP: "swap",
    INSERT_HORIZONTAL: "insert-horizontal",
    INSERT_VERTICAL: "insert-vertical",
    VARIABLE_ITEM_HEIGHT: "variable-item-height"
};

const OrientationType = {
    VERTICAL: "vertical",
    HORIZONTAL: "horizontal"
};

const IdeaType = {
    TEMPLATE: "template",
    LAYOUT: "layout",
    HELP: "help"
};

const DirectionType = {
    LEFT: "left",
    RIGHT: "right",
    BOTTOM: "bottom",
    TOP: "top",
    AUTO: "auto"
};

const ArrowDirection = {
    LEFT: "left",
    RIGHT: "right",
    DOWN: "down",
    UP: "up"
};

const PositionType = {
    CENTER: "center",
    TOP: "top",
    BOTTOM: "bottom",
    LEFT: "left",
    RIGHT: "right",
    TOP_LEFT: "top_left",
    TOP_RIGHT: "top_right",
    BOTTOM_LEFT: "bottom_left",
    BOTTOM_RIGHT: "bottom_right",
    BACKGROUND: "background",
    FILL: "fill",
    USER: "user",
    OFF: "off"
};

const StylesMenuType = {
    STYLE: "style",
    PROPERTIES: "properties",
    TEMPLATE: "template",
    CONTROL: "control"
};

const ShapeType = {
    NONE: "none",
    CIRCLE: "circle",
    RECT: "rect",
    ROUNDED_RECT: "rounded_rect",
    OCTAGON: "octagon"
};

const ThemeStyleWeight = {
    THIN: "thin",
    MEDIUM: "medium",
    HEAVY: "heavy"
};

const PaintStyle = {
    FILL: "fill",
    STROKE: "stroke",
    NONE: "none"
};

const ShapeColorStyle = {
    THEME_COLOR: "theme_color",
    ACCENT_COLOR: "accent_color",
    COLORFUL: "colorful",
    NO_COLOR: "no_color",
    USER_DEFINED: "user_defined"
};

const IconStyle = {
    THIN: "thin",
    CHUNKY: "chunky",
    DOODLE: "doodle",
    BASIC: "basic"
};

const TextStyleEnum = {
    REGULAR: "regular",
    BOLD: "bold",
    ITALIC: "italic",
    BOLDITALIC: "bold italic",
    LINK: "link"
};

const CanvasEventType = {
    ELEMENT_ADDED: "elementAdded",
    ELEMENT_REMOVED: "elementRemoved",
    REFRESH: "refresh",
    BEFORE_MODEL_CHANGE: "beforeModelChange",
    REFRESH_REVERTED: "refreshReverted",
    SELECT_NOTHING: "selectNothing",
    DATA_STATE_UPDATED: "dataStateUpdated",
    RENDER: "render"
};

const FormatType = {
    NONE: "none",
    TEXT: "text",
    NUMBER: "number",
    CURRENCY: "currency",
    DATE: "date",
    PERCENT: "percent",
    ICON: "icon"
};

const ImageErrorType = {
    BAD_DATA: "bad-data",
    SIZE: "size"
};

const API_ERROR_CODE = {
    FORBIDDEN: "forbidden",
    UNAUTHORIZED: "unauthorized"
};

const LibraryView = {
    LIBRARY: "library",
    TEAM_RESOURCES: "team-resources",
    MY_RESOURCES: "my-resources"
};

const LIBRARY_RECENT_MAX = 200;

/**
 * @enum {PresentationFilter}
 */
const PresentationFilters = {
    ALL_PRESENTATIONS: "all_presentations",
    RECENT: "recent",
    OWNED_BY_ME: "owned_by_me",
    SHARED_WITH_ME: "shared_with_me",
    VIEW_ONLY: "view_only",
    FOLDER: "folder",
    TEAM_FOLDER: "team_folder",
    TRASH: "trash"
};

const CustomerType = {
    TEAM: "team",
    INDIVIDUAL: "individual"
};

const DEFAULT_ADDRESS = "251 S. Van Ness Ave. San Francisco, CA 94103";

const LimitErrors = {
    SLIDE_COUNT_LIMIT_REACHED: "slideCountLimitReached"
};

const SubscriptionStatus = {
    PAST_DUE: "past_due",
    ACTIVE: "active",
    TRIALING: "trialing",
    CANCELED: "canceled",
};

const ELEMENT_IDS = {
    SLIDE_NUM: "slideNum"
};

const PLAN_NICKNAMES = {
    PRO_MONTH: "Pro-month",
    PRO_ANNUAL: "Pro-annual",
    PRO_MONTH_45: "Pro-month-45",
    PROM_MONTH_90: "Pro-month-90",
    TEAM_MONTH_50: "Team-month-50",
    TEAM_ANNUAL_480: "Team-annual-480",
    TEAM_MONTH_MIGRATED: "Team-month-migrated",
    TEAM_ANNUAL_MIGRATED: "Team-Annual-migrated",
    TEAM_MONTH: "Team-month",
    TEAM_ANNUAL: "Team-annual",
    PRICE_ENTERPRISE_50_MONTH: "price-enterprise-50-month",
    PRICE_ENTERPRISE_480_ANNUAL: "price-enterprise-480-year",
};

const CellChangeStyle = {
    ARROWS: ">",
    NONE: "none",
    PLUS_MINUS: "+"
};

const NodeType = {
    BOX: "box",
    FLEX_CIRCLE: "flex-circle",
    CIRCLE: "circle",
    DIAMOND: "diamond",
    CAPSULE: "capsule",
    TEXT: "text",
    BULLET_TEXT: "bullet-text",
    CONTENT_AND_TEXT: "content-and-text",
    NUMBERED_TEXT: "numbered-text",
    LETTERED_TEXT: "lettered-text",
    CONTENT: "content",
    LINE: "line"
};

const PRESENTATION_LINK_TYPE = {
    PUBLIC: "public",
    PROTECTED: "protected",
    INVITE: "invite",
    EMBED: "embed",
    SOCIAL: "social",
    MEETING: "meeting",
    INTEGRATOR: "integrator",
};

const VALID_INTEGRATOR_TYPES = {
    MOCK_LOCAL: "mock-local",
    MONDAY: "monday"
};

const PresentationPrivacyType = {
    PRIVATE: "private",
    SECURED: "secured",
    PUBLIC: "public"
};

const PREDEFINED_PALETTES = [{
    id: "heyday",
    name: "Heyday",
    colors: {
        theme: "#FFBF35",
        accent1: "#FFABAB",
        accent2: "#FE5D26",
        accent3: "#0099BF",
        accent4: "#00AD85",
        background_light: "#ffffff",
        background_dark: "#28282E",
        background_accent: "#EFF5F3",
        primary_light: "#ffffff",
        primary_dark: "#28282e",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(0,0,0,0.8)",
        chart1: "#0099BF",
        chart2: "#FFBF35",
        chart3: "#00AD85",
        chart4: "#A5D8CC",
        chart5: "#FFABAB",
        chart6: "#FE5D26",
        hyperlink: "#11a9e2"
    }
}, {
    id: "blues",
    name: "Blues",
    colors: {
        theme: "#0058BF",
        accent1: "#008FD8",
        accent2: "#B9DAE3",
        background_light: "#ffffff",
        background_dark: "#00143F",
        background_accent: "#F4F9FF",
        primary_light: "#ffffff",
        primary_dark: "#00143F",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(0,10,68,0.8)",
        chart1: "#008FD8",
        chart2: "#9FD0DE",
        chart3: "#024090",
        chart4: "#86BD9B",
        chart5: "#ECA400",
        chart6: "#FF702C",
        hyperlink: "#11a9e2"
    }
}, {
    id: "pop",
    name: "Pop",
    colors: {
        theme: "#DAB5DC",
        accent1: "#7B92E8",
        accent2: "#FF4F2D",
        accent3: "#FDA594",
        background_light: "#ffffff",
        background_dark: "#130C27",
        background_accent: "#E6EBFF",
        primary_light: "#ffffff",
        primary_dark: "#3B2C67",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(31,35,72,0.8)",
        chart1: "#7B92E8",
        chart2: "#DAB5DC",
        chart3: "#FF6546",
        chart4: "#FDA594",
        chart5: "#7ECBD0",
        chart6: "#FFCD66",
        hyperlink: "#11a9e2"
    }
}, {
    id: "desert",
    name: "Desert",
    colors: {
        theme: "#F6C340",
        accent1: "#DD720E",
        accent2: "#D44040",
        accent3: "#E7F0C8",
        background_light: "#F9F5F0",
        background_dark: "#293345",
        background_accent: "#F5F0E5",
        primary_light: "rgba(255,255,255,0.9)",
        primary_dark: "rgba(41,51,69,0.9)",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(41,51,69,0.8)",
        chart1: "#B4DACE",
        chart2: "#306E96",
        chart3: "#A6AC2D",
        chart4: "#F6C340",
        chart5: "#FEA078",
        chart6: "#D44040",
        hyperlink: "#11a9e2"
    }
}, {
    id: "greens",
    name: "Greens",
    colors: {
        theme: "#97AA0F",
        accent1: "#58B368",
        accent2: "#DAD873",
        accent3: "#1CA4AB",
        background_light: "#ffffff",
        background_dark: "#080B12",
        background_accent: "#F2F7F5",
        primary_light: "#ffffff",
        primary_dark: "#080B12",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(0,0,0,0.8)",
        chart1: "#1CA4AB",
        chart2: "#97AA0F",
        chart3: "#DAD873",
        chart4: "#F39C12",
        chart5: "#4A62A8",
        chart6: "#E74C3C",
        hyperlink: "#11a9e2"
    }
}, {
    id: "newsprint",
    name: "Newsprint",
    colors: {
        theme: "#7A807C",
        accent1: "#A5AEA9",
        accent2: "#CFDBD5",
        background_light: "#ffffff",
        background_dark: "#242423",
        background_accent: "#F0F3ED",
        primary_light: "#ffffff",
        primary_dark: "#242423",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(0,0,0,0.8)",
        chart1: "#02555A",
        chart2: "#9EBDAD",
        chart3: "#86B366",
        chart4: "#E8C66B",
        chart5: "#D87E54",
        chart6: "#6D9BD0",
        hyperlink: "#11a9e2"
    }
}, {
    id: "magazine",
    name: "Magazine",
    colors: {
        theme: "#004F98",
        accent1: "#2896BB",
        accent2: "#9AD2CB",
        accent3: "#CB934C",
        background_light: "#ffffff",
        background_dark: "#111627",
        background_accent: "#EDF1EB",
        primary_light: "#ffffff",
        primary_dark: "rgba(24,24,24,0.8)",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(24,24,24,0.8)",
        chart1: "#2896BB",
        chart2: "#9AD2CB",
        chart3: "#9BA64B",
        chart4: "#E2B543",
        chart5: "#DE7B49",
        chart6: "#A44A7D",
        hyperlink: "#11a9e2"
    }
}, {
    id: "classic",
    name: "Classic",
    colors: {
        theme: "#143F9C",
        accent1: "#283D6B",
        accent2: "#A4BAB7",
        accent3: "#C8D0AB",
        background_light: "#ffffff",
        background_dark: "#071023",
        background_accent: "#EAE9E6",
        primary_light: "#ffffff",
        primary_dark: "#000000",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(0,0,0,0.8)",
        chart1: "#587EB1",
        chart2: "#143F9C",
        chart3: "#F1B95E",
        chart4: "#E8765E",
        chart5: "#9A2866",
        chart6: "#A4BAB7",
        hyperlink: "#11a9e2"
    }
}, {
    id: "block",
    name: "Block",
    colors: {
        theme: "#A61E51",
        accent1: "#BAD6CD",
        accent2: "#3066BE",
        accent3: "#923871",
        background_light: "#FFFeFD",
        background_dark: "#120309",
        background_accent: "#F9F9F8",
        primary_light: "#ffffff",
        primary_dark: "#000000",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(61,61,61,0.8)",
        chart1: "#A61E51",
        chart2: "#A4889F",
        chart3: "#BAD6CD",
        chart4: "#3066BE",
        chart5: "#739FC5",
        chart6: "#614F98",
        hyperlink: "#11a9e2"
    }
}, {
    id: "inc",
    name: "Inc",
    colors: {
        theme: "#EB5D48",
        accent1: "#F5A58D",
        accent2: "#BDBDAC",
        accent3: "#C9DABF",
        background_light: "#ffffff",
        background_dark: "#28282E",
        background_accent: "#F1F5EE",
        primary_light: "#ffffff",
        primary_dark: "#28282E",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(0,0,0,0.8)",
        chart1: "#285C6D",
        chart2: "#C6DABF",
        chart3: "#8CB369",
        chart4: "#FFB20F",
        chart5: "#EB5D48",
        chart6: "#87255B",
        hyperlink: "#11a9e2"
    }
}, {
    id: "dark",
    name: "Dark",
    colors: {
        theme: "#90D3C5",
        accent1: "#AED5C3",
        accent2: "#DAE0C9",
        background_light: "#ffffff",
        background_dark: "#000000",
        background_accent: "#1c1c1c",
        primary_light: "rgba(255,255,255,0.8)",
        primary_dark: "rgba(0,0,0,0.8)",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(0,0,0,0.8)",
        chart1: "#AED5C3",
        chart2: "#5DA6B5",
        chart3: "#145E80",
        chart4: "#BBC796",
        chart5: "#ECB65A",
        chart6: "#DD720E",
        hyperlink: "#11a9e2"
    }
}, {
    id: "cheeky",
    name: "Cheeky",
    colors: {
        theme: "#FFA300",
        accent1: "#A68118",
        accent2: "#FDD16E",
        accent3: "#F87C57",
        accent4: "#3D4176",
        background_light: "#FDFDFD",
        background_dark: "#1C2F36",
        background_accent: "#FDFAF2",
        primary_light: "#FDFDFD",
        primary_dark: "rgba(27,47,53,0.9)",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(28,47,53,0.7)",
        chart1: "#8ED6C9",
        chart2: "#079854",
        chart3: "#FDD16E",
        chart4: "#FFA300",
        chart5: "#F87C57",
        chart6: "#3D4176",
        hyperlink: "#11a9e2"
    }
}, {
    id: "museum",
    name: "Museum",
    colors: {
        theme: "#DC582A",
        accent1: "#009CA6",
        accent2: "#F1B434",
        accent3: "#E6D69A",
        background_light: "#ffffff",
        background_dark: "#000000",
        background_accent: "#F4F7F8",
        primary_light: "#ffffff",
        primary_dark: "#0F3332",
        secondary_light: "rgba(255,255,255,0.9)",
        secondary_dark: "rgba(0,0,0,0.8)",
        chart1: "#009CA6",
        chart2: "#006196",
        chart3: "#92124F",
        chart4: "#DC582A",
        chart5: "#F1B434",
        chart6: "#BAC676",
        hyperlink: "#11a9e2"
    }
}, {
    id: "space",
    name: "Space",
    colors: {
        theme: "#B9D5B1",
        accent1: "#F66A2E",
        accent2: "#F3AE37",
        accent3: "#143642",
        background_light: "#FAFBF7",
        background_dark: "#071023",
        background_accent: "#EFF1EB",
        primary_light: "#F5F6F2",
        primary_dark: "#071023",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(0,0,0,0.8)",
        chart1: "#493658",
        chart2: "#8CADA7",
        chart3: "#BCDAB4",
        chart4: "#F3B61F",
        chart5: "#F0672D",
        chart6: "#FFC2B4",
        hyperlink: "#11a9e2"
    }
}, {
    id: "bold",
    name: "Bold",
    colors: {
        theme: "#466AAD",
        accent1: "#1D3557",
        accent2: "#A8DADC",
        accent3: "#E76F51",
        background_light: "#ffffff",
        background_dark: "#000000",
        background_accent: "#F3F7F1",
        primary_light: "rgba(255,255,255,0.9)",
        primary_dark: "rgba(0,0,0,0.8)",
        secondary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(0,0,0,0.8)",
        chart1: "#1D3557",
        chart2: "#2A9D8F",
        chart3: "#E9C46A",
        chart4: "#E76F51",
        chart5: "#A8DADC",
        chart6: "#466AAD",
        hyperlink: "#11a9e2"
    },
}, {
    id: "minimal",
    name: "Minimal",
    colors: {
        theme: "#E09F3E",
        accent1: "#14558C",
        accent2: "#D6CD95",
        background_light: "#ffffff",
        background_dark: "#000000",
        background_accent: "#F3F0EC",
        primary_light: "#ffffff",
        primary_dark: "#000000",
        secondary_light: "rgba(255,255,255,0.9)",
        secondary_dark: "rgba(0,0,0,0.9)",
        chart1: "#14558C",
        chart2: "#7CC0C7",
        chart3: "#C0E1C4",
        chart4: "#D6CD95",
        chart5: "#679436",
        chart6: "#E09F3E",
        hyperlink: "#11a9e2"
    },
}, {
    id: "bright",
    name: "Bright",
    colors: {
        theme: "#3E8CE5",
        accent1: "#38DBB9",
        accent2: "#3CD1EA",
        accent3: "#FFC045",
        accent4: "#FF9456",
        accent5: "#E46183",
        background_light: "#fff",
        background_dark: "#30353d",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "colorful",
    name: "Colorful",
    colors: {
        theme: "#00A0B0",
        accent1: "#97AA0F",
        accent2: "#2980B9",
        accent3: "#F39C12",
        accent4: "#BD1550",
        accent5: "#E74C3C",
        background_light: "#fff",
        background_dark: "#333",
        primary_light: "#fff",
        primary_dark: "#333",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(0,0,0,0.6)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "pastels",
    name: "Pastels",
    colors: {
        theme: "#749FC7",
        accent1: "#A77FC7",
        accent2: "#E48AA9",
        accent3: "#F2BA00",
        accent4: "#FF9C00",
        accent5: "#AA6469",
        background_light: "#fff",
        background_dark: "#444",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "cool_grays",
    name: "Cool Grays",
    colors: {
        theme: "#506E7B",
        accent1: "#8DA5AF",
        background_light: "#fff",
        background_dark: "#252b32",
        primary_light: "#fff",
        primary_dark: "#1e343b",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "moody_blues",
    name: "Moody Blues",
    colors: {
        theme: "#A0C5E8",
        accent1: "#6B94BA",
        accent2: "#407096",
        background_light: "#fff",
        background_dark: "#383a40",
        primary_light: "#fff",
        primary_dark: "#1e343b",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "taxicab",
    name: "Taxicab",
    colors: {
        theme: "#FFCB4F",
        accent1: "#908E89",
        background_light: "#fff",
        background_dark: "#444",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "monochrome",
    name: "Monochrome",
    colors: {
        theme: "#353535",
        accent1: "#7B7B7B",
        background_light: "#fff",
        background_dark: "#7B7B7B",
        primary_light: "#fff",
        primary_dark: "#353535",
        secondary_light: "#f1f1f1",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}];

const PREDEFINED_PALETTES2 = [{
    id: "bright",
    name: "Bright",
    colors: {
        theme: "#3E8CE5",
        accent1: "#38DBB9",
        accent2: "#3CD1EA",
        accent3: "#FFC045",
        accent4: "#FF9456",
        accent5: "#E46183",
        background_light: "#fff",
        background_dark: "#30353d",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "colorful",
    name: "Colorful",
    colors: {
        theme: "#00A0B0",
        accent1: "#97AA0F",
        accent2: "#2980B9",
        accent3: "#F39C12",
        accent4: "#BD1550",
        accent5: "#E74C3C",
        background_light: "#fff",
        background_dark: "#333",
        primary_light: "#fff",
        primary_dark: "#333",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(0,0,0,0.6)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "pastels",
    name: "Pastels",
    colors: {
        theme: "#749FC7",
        accent1: "#A77FC7",
        accent2: "#E48AA9",
        accent3: "#F2BA00",
        accent4: "#FF9C00",
        accent5: "#AA6469",
        background_light: "#fff",
        background_dark: "#444",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "old_school",
    name: "Old School",
    colors: {
        theme: "#4472C4",
        accent1: "#ED7D31",
        accent2: "#A5A5A5",
        accent3: "#FFC003",
        accent4: "#5B9BD5",
        accent5: "#71AE47",
        background_light: "#fff",
        background_dark: "#35373a",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "red_shift",
    name: "Red Shift",
    colors: {
        theme: "#2E5390",
        accent1: "#404176",
        accent2: "#A20E57",
        accent3: "#F10032",
        background_light: "#fff",
        background_dark: "#1a182c",
        primary_light: "#fff",
        primary_dark: "#44417A",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "candy",
    name: "Candy",
    colors: {
        theme: "#DA63B9",
        accent1: "#B7DA63",
        accent2: "#3AD1ED",
        accent3: "#FFCB4F",
        background_light: "#fff",
        background_dark: "#783665",
        primary_light: "#fff",
        primary_dark: "#4C627A",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "hooli",
    name: "Hooli",
    colors: {
        theme: "#3786F9",
        accent1: "#00AA4A",
        accent2: "#FFB900",
        accent3: "#F92F31",
        background_light: "#fff",
        background_dark: "#383a40",
        primary_light: "#fff",
        primary_dark: "#757575",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "autumn",
    name: "Autumn",
    colors: {
        theme: "#FF904F",
        accent1: "#F3735B",
        accent2: "#C94160",
        accent3: "#93654F",
        background_light: "#fff",
        background_dark: "#302c2c",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "reds",
    name: "Reds",
    colors: {
        theme: "#F35B5B",
        accent1: "#C03F3F",
        accent2: "#8e2c2d",
        background_light: "#fff",
        background_dark: "#232323",
        primary_light: "#fff",
        primary_dark: "#2d2d2d",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "greens",
    name: "Greens",
    colors: {
        theme: "#91C765",
        accent1: "#B7DA63",
        accent2: "#E4E56A",
        background_light: "#fff",
        background_dark: "#595D65",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "cool_grays",
    name: "Cool Grays",
    colors: {
        theme: "#506E7B",
        accent1: "#8DA5AF",
        background_light: "#fff",
        background_dark: "#252b32",
        primary_light: "#fff",
        primary_dark: "#1e343b",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "moody_blues",
    name: "Moody Blues",
    colors: {
        theme: "#A0C5E8",
        accent1: "#6B94BA",
        accent2: "#407096",
        background_light: "#fff",
        background_dark: "#383a40",
        primary_light: "#fff",
        primary_dark: "#1e343b",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "blue_skies",
    name: "Blue Skies",
    colors: {
        theme: "#64C3FF",
        accent1: "#2EA2D9",
        accent2: "#09789E",
        background_light: "#fff",
        background_dark: "#44464A",
        primary_light: "#fff",
        primary_dark: "#004256",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "purples",
    name: "Purples",
    colors: {
        theme: "#9652FD",
        accent1: "#6152FD",
        accent2: "#4339B3",
        background_light: "#fff",
        background_dark: "#3E3354",
        primary_light: "#fff",
        primary_dark: "#44417A",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "minty",
    name: "Minty",
    colors: {
        theme: "#00AC8E",
        accent1: "#AADA63",
        accent2: "#AEBEBB",
        background_light: "#fff",
        background_dark: "#1e343b",
        primary_light: "#fff",
        primary_dark: "#1e343b",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "cool_startup",
    name: "Cool Startup",
    colors: {
        theme: "#65C7B3",
        accent1: "#476B93",
        accent2: "#B1BBC7",
        background_light: "#fff",
        background_dark: "#174769",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "taxicab",
    name: "Taxicab",
    colors: {
        theme: "#FFCB4F",
        accent1: "#908E89",
        background_light: "#fff",
        background_dark: "#444",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "monochrome",
    name: "Monochrome",
    colors: {
        theme: "#353535",
        accent1: "#7B7B7B",
        background_light: "#fff",
        background_dark: "#7B7B7B",
        primary_light: "#fff",
        primary_dark: "#353535",
        secondary_light: "#f1f1f1",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "mai_tai",
    name: "Mai Tai",
    colors: {
        theme: "#ffae3b",
        // accent1: "#FF748D",
        accent1: "#E75BBC",
        background_light: "#fff",
        background_dark: "#b74a00",
        primary_light: "#fff",
        primary_dark: "#b73854",
        secondary_light: "#f1f1f1",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}, {
    id: "merry",
    name: "Merry",
    colors: {
        theme: "#ff5646",
        // accent1: "#FF748D",
        accent1: "#519041",
        background_light: "#fff",
        background_dark: "#595D65",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: "#11a9e2"
    }
}];

const TEAM_USER_ROLES = {
    MEMBER: "member",
    MEMBER_LICENSED: "member-licensed",
    LIBRARIAN: "librarian",
    OWNER: "owner"
};

const TEAM_USER_LICENSE_STATUS = {
    TEAM_PRO: "team_pro",
    FREE: "free"
};

const KlaviyoLists = {
    TEAM: "Rc9qx3",
    USER: "H8Jw8B",
    INVITED: "SwzxZt"
};

const AnalyticsRolesAndLicensesMap = {
    "member": "team-member",
    "member-licensed": "team-member",
    "librarian": "librarian",
    "owner": "owner",
    "team_pro": "organization-pro",
    "free": "basic"
};

const OLD_BASIC_CUTOFF = 1935448200000; // May 1, 2031 5:30pm

const CHECKOUT_DIALOG_A_ID = "202308_checkout_dialog_A";
const CHECKOUT_DIALOG_B_ID = "202308_checkout_dialog_B";
const CHECKOUT_DIALOG_C_ID = "202308_checkout_dialog_C";
const CHECKOUT_DIALOG_CONTROL_ID = "202308_checkout_dialog_control";

const BEAUTIFUL_CHECKOUT_DIALOG_ID = "beautifulCheckoutDialogId";
const BEAUTIFUL_WORKSPACE_ID = "beautifulWorkspaceId";
const BEAUTIFUL_WORKSPACE_ACTION = "beautifulWorkspaceAction";

const WorkspaceAction = {
    CREATED: "CREATED",
    JOINED: "JOINED",
};

const SlideLockType = {
    //use SlideLockType.COLLABORATION to lock the editor during collaboration
    COLLABORATION: "collaboration",
    //use SlideLockType.TEAM_MEMBER to lock the editor for a library slide that a member-licensed, member, or collaborator is viewing
    TEAM_MEMBER: "teamMember",
    //use SlideLockType.ORG to lock the editor for a library slide for the entire org and collaborators
    ORG: "org",
    //use SlideLockType.OBSOLETE_TEMPLATE to lock the editor when a template is obsolete and needs to be updated,
    OBSOLETE_TEMPLATE: "obsoleteTemplate"
};

const ErrorMessageType = {
    DUPLICATE_SESSION_ERROR: "duplicate-session-error"
};

const CANVAS_WIDTH = 1280;
const CANVAS_HEIGHT = 720;

const TaskType = {
    VIDEO_UPLOAD: "videoUpload",
    PPT_IMPORT: "pptImport",
    PPT_PREVIEW: "pptPreview",
    PRESENTATION_EXPORT: "presentationExport",
    AUDIO_UPLOAD: "audioUpload"
};

const TaskState = {
    PREPARING: "preparing",
    PROCESSING: "processing",
    FINISHED: "finished",
    CANCELLED: "cancelled",
    ERROR: "error"
};

const SnapLineDirection = {
    VERTICAL: "vertical",
    HORIZONTAL: "horizontal"
};

const SnapLineBindingPoint = {
    CENTER: "center",
    START: "start",
    END: "end"
};

const SNAP_TOLERANCE = 5;

const SearchThrottleWait = 1000;

const SeriesTypeLabels = {
    line: "Line",
    column: "Column",
    area: "Area",
    spline: "Spline",
    areaspline: "Area Spline",
    pie: "Pie",
    donut: "Donut",
    bar: "Bar"
};

const Notification = {
    TaskType: {
        CREATE: "create",
        SEND: "send"
    },
    EventType: {
        COMMENT: "comment",
        EDIT: "edit",
        PERMISSION: "permission",
        TEAM: "team",
        PRESENTATION_LINK: "presentationLink",
        SLACK: "slack",
        REFERRAL: "referral",
        ASSIGN: "assign",
        PLAYER_DOWNLOAD: "playerDownload",
    },
    EventAction: {
        COMMENT_ADDED: "commentAdded",

        SLIDE_EDITED: "slideEdited",
        SLIDE_ADDED: "slideAdded",
        SLIDE_MOVED: "slideMoved",
        SLIDE_REMOVED: "slideRemoved",
        SLIDE_STATUS_CHANGED: "slideStatusChanged",
        PRESENTATION_RENAMED: "presentationRenamed",
        PRESENTATION_THEME_CHANGED: "presentationThemeChanged",
        PRESENTATION_PRIVACY_CHANGED: "presentationPrivacyChanged",

        PERMISSION_GRANTED: "permissionGranted",
        PERMISSION_REQUESTED: "permissionRequested",

        INVITED_TO_TEAM: "invitedToTeam",
        ADDED_TO_TEAM: "addedToTeam",
        ACCEPTED_INVITE_TO_TEAM: "acceptedInviteToTeam",

        REQUESTED_ACCESS_TO_TEAM_FOLDER: "requestedAccessToTeamFolder",
        GRANTED_ACCESS_TO_TEAM_FOLDER: "grantedAccessToTeamFolder",

        PRESENTATION_LINK_CREATED: "presentationLinkCreated",

        SLACK_ENABLED: "slackEnabled",

        CONVERSION: "conversion",

        ASSIGNED_TO_SLIDE: "assignedToSlide",

        PRESENTATION_EXPORTED: "presentationExported",
    },
    NotificationState: {
        READ: "read",
        UNREAD: "unread"
    }
};

const PPTImportWarningType = {
    ELEMENTS_TRUNCATED: "elementsTruncated"
};

const AnimationsState = {
    NONE: "none",
    ON_CLICK: "onClick",
    AUTO: "auto",
    CUSTOM: "custom",
    CUSTOM_OLD_TIMELINE: "customOldTimeline"
};

const PusherEventType = {
    DATA_RECORD_CREATED: "dataRecordCreated",
    DATA_RECORD_UPDATED: "dataRecordUpdated",
    DATA_RECORD_DELETED: "dataRecordDeleted"
};

// These properties will not be saved as revision commands.
const SLIDE_COMMAND_BLACKLIST = [
    "presentationId",
    "thumbnails",
    "createdAt",
    "modifiedAt",
    "slideNotes",
    "isSkipped",
    "libraryItemId",
    "isGallerySlide",
    "assignedUser",
    "assignedPendingUser",
    "pendingUserAssignedBy",
    "createdBy"
];

const SLIDE_MIGRATION_TIMEOUT_MS = 2 * 60 * 1000; // 2 minutes

const GALLERY_SLIDES_METADATA_CACHE_TTL_SECONDS = 60 * 60 * 24 * 30; // 30 days
const AnimationsArrangementType = {
    SEQUENTIAL: "sequential",
    OVERLAPPING: "overlapping",
    SIMULTANEOUS: "simultaneous"
};

const DEFAULT_PROGRESS_DURATION_MS = 2000;

const DEFAULT_ANIMATION_DURATION_MS = 600;

const MIN_ANIMATION_DURATION_MS = 100;

const PPT_SLIDE_PREVIEW_IMAGE_SIZE = {
    width: 1920,
    height: 1080
};

const URLS = {
    SUGGEST_TEMPLATE_FORM: "https://beautifulai.typeform.com/to/dPFFbXuy",
    SUGGEST_ICON_FORM: "https://beautifulai.typeform.com/to/pnealyaC",
    POWERPOINT_ADDIN_URL: "http://beautiful.ai/integrations/powerpoint"
};

const TYPEFORM_IDS = {
    CHURN_SURVEY: "01J3ZTXRDM1VR6DAAZ2QS8XNFQ",
};

const DocumentType = {
    PRESENTATION: "presentation",
    DOCUMENT: "document"
};

const DecorationType = {
    RECT: "rect",
    CIRCLE: "circle",
    OCTAGON: "octagon",
    PATH: "path",
    POLYGON: "polygon",
    POLYLINE: "polyline"
};

const DEFAULT_BLOCK_ELEMENT_HEIGHT = 300;

const BLOCK_INSET_GAP = 30;

const AdminPermission = {
    OWNER: "owner",
    APP_MANAGER: "appManager",
    ENTERPRISE_MANAGER: "enterpriseManager",
    MIGRATION: "migration"
};

const AI_DEMO_USER_ID_COOKIE_NAME = "bai-ai-demo-user-id";

const InviteStatus = {
    PENDING: "pending",
    ACCEPTED: "accepted"
};

const FirebaseRealTimeDatabaseInstanceType = {
    DEFAULT_DATABASE: "DEFAULT_DATABASE",
    USER_DATABASE: "USER_DATABASE"
};

const AppView = {
    LIBRARY: "library",
    TEAM_RESOURCES: "team-resources",
    MY_RESOURCES: "my-resources",
    PRESENTATION_EDITOR: "presentation-editor",
    DOCUMENT_EDITOR: "document-editor",
    SLIDE_EDITOR: "slide-editor",
    PLAYER: "player",
    THEME_EDITOR: "theme-editor",
    CREATE_PRESENTATION: "create-presentation",
    ACCOUNT: "account",
    PRESENTATION_SETTINGS: "presentation-settings"
};

const LINK_PASSWORD_PREFIX = "password-protected-link-";

const ThemeType = {
    PRESENTATION: "presentation",
    TEAM: "team",
    USER: "user",
    CREATE_PRESENTATION: "create-presentation",
    BUILT_IN: "built-in",
    PPT_THEME: "ppt-theme"
};

const SLIDE_DATA_STATE_FIELDS = [
    "template_id", "layout", "states", "animations", "version", "migrationVersion"
];

const SlideStatus = {
    NONE: "none",
    TODO: "todo",
    IN_PROGRESS: "in_progress",
    NEEDS_REVIEW: "needs_review",
    ON_HOLD: "on_hold",
    DONE: "done"
};

const SlideStatusProps = {
    [SlideStatus.NONE]: {
        icon: "status-none",
        label: "None"
    },
    [SlideStatus.TODO]: {
        icon: "status-todo",
        label: "To Do"
    },
    [SlideStatus.IN_PROGRESS]: {
        icon: "status-in-progress",
        label: "In Progress"
    },
    [SlideStatus.ON_HOLD]: {
        icon: "status-hold",
        label: "On Hold"
    },
    [SlideStatus.DONE]: {
        icon: "status-done",
        label: "Done"
    }
};

const PdfCompressionTypes = {
    LOW: "LOW",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH"
};

const SLIDES_METADATA_FIELDS = [
    "isSkipped", "modifiedAt", "template_id", "version"
];

module.exports = {
    DecorationType,
    DocumentType,
    SearchThrottleWait,
    CANVAS_WIDTH,
    CANVAS_HEIGHT,
    ACCEPTED_DATE_FORMATS,
    CHART_DATE_FORMATS,
    ASSET_SIZES,
    ASSET_LOAD_TIMEOUT,
    ASSET_CACHE_TTL_SECONDS,
    THUMBNAIL_SIZES,
    THUMBNAIL_CACHE_TTL_SECONDS,
    LOGO_CACHE_TTL_SECONDS,
    APP_UNLIMITED_SLIDE_COUNT,
    PERMISSION_RESOURCE_TYPE,
    ASSET_AREAS,
    ASSET_SIZE_REGEX,
    OLD_ASSET_SIZES,
    DEFAULT_ADDRESS,
    ConnectorType,
    ElementEventType,
    FormatType,
    position,
    UndoType,
    ViewName,
    ForeColorType,
    PaletteColorType,
    BackgroundStyleType,
    AssetType,
    ShowFooterType,
    ContentFrameType,
    API_ERROR_CODE,
    ASSET_FILETYPE,
    ASSET_FILETYPE_TO_MIMETYPE,
    ASSET_MIMETYPE_TO_FILETYPE,
    ASSET_MAX_AREA,
    ASSET_MAX_SIZE,
    ASSET_MAX_UPLOAD_MEGAPIXELS,
    ASSET_RESOLUTION,
    ASSET_BREAKPOINTS,
    JPEG_QUALITY,
    ASSET_ABBREVIATIONS,
    AdvanceSlideOnType,
    HiliteType,
    TextRegionType,
    ElementRegionType,
    TrayType,
    TrayElementType,
    ElementTextBlockPositionType,
    HeaderPositionType,
    HorizontalAlignType,
    VerticalAlignType,
    VerticalBlockAlignType,
    VerticalPositionType,
    WidgetPositionType,
    OrientationType,
    IdeaType,
    DirectionType,
    ArrowDirection,
    PositionType,
    StylesMenuType,
    ShapeType,
    PaintStyle,
    ShapeColorStyle,
    IconStyle,
    ThemeStyleWeight,
    TextStyleEnum,
    CanvasEventType,
    ImageErrorType,
    LibraryView,
    LIBRARY_RECENT_MAX,
    PresentationFilters,
    LimitErrors,
    CustomerType,
    SubscriptionStatus,
    KlaviyoLists,
    AnalyticsRolesAndLicensesMap,
    ELEMENT_IDS,
    CellChangeStyle,
    NodeType,
    PRESENTATION_LINK_TYPE,
    PREDEFINED_PALETTES,
    OLD_BASIC_CUTOFF,
    TEAM_USER_ROLES,
    TEAM_USER_LICENSE_STATUS,
    CHECKOUT_DIALOG_A_ID,
    CHECKOUT_DIALOG_B_ID,
    CHECKOUT_DIALOG_C_ID,
    CHECKOUT_DIALOG_CONTROL_ID,
    BEAUTIFUL_CHECKOUT_DIALOG_ID,
    BEAUTIFUL_WORKSPACE_ID,
    BEAUTIFUL_WORKSPACE_ACTION,
    WorkspaceAction,
    TextBreakType,
    ContentBlockType,
    TextBlockPreset,
    TextEditorEvent,
    ParagraphStyle,
    SlideLockType,
    ErrorMessageType,
    SeriesTypeLabels,
    AuthoringElementType,
    AuthoringBlockType,
    BlockStructureType,
    BlockStyleType,
    AuthoringShapeType,
    AuthoringShapeDirection: ArrowDirection,
    TextStyleType,
    TaskType,
    TaskState,
    SnapLineDirection,
    SnapLineBindingPoint,
    SNAP_TOLERANCE,
    Notification,
    PPTImportWarningType,
    IMAGE_ASSET_TYPES,
    PusherEventType,
    SLIDE_COMMAND_BLACKLIST,
    SLIDE_MIGRATION_TIMEOUT_MS,
    GALLERY_SLIDES_METADATA_CACHE_TTL_SECONDS,
    AnimationsArrangementType,
    AnimationsState,
    DEFAULT_ANIMATION_DURATION_MS,
    MIN_ANIMATION_DURATION_MS,
    ListStyleType,
    DragType,
    PPT_SLIDE_PREVIEW_IMAGE_SIZE,
    ...URLS,
    TYPEFORM_IDS,
    TextFocusType,
    DEFAULT_BLOCK_ELEMENT_HEIGHT,
    BLOCK_INSET_GAP,
    AI_DEMO_USER_ID_COOKIE_NAME,
    SlideRecordingType,
    AdminPermission,
    InviteStatus,
    FirebaseRealTimeDatabaseInstanceType,
    AppView,
    LINK_PASSWORD_PREFIX,
    ThemeType,
    GOOGLE_ASSET_MIMETYPES,
    GOOGLE_VIDEO_MIMETYPES,
    PLAN_NICKNAMES,
    SLIDE_DATA_STATE_FIELDS,
    DEFAULT_PROGRESS_DURATION_MS,
    SlideStatus,
    SlideStatusProps,
    PresentationPrivacyType,
    VALID_INTEGRATOR_TYPES,
    PdfCompressionTypes,
    SLIDES_METADATA_FIELDS,
};
