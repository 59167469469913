import React, { Component } from "react";
import styled from "styled-components";

import { getCanvasBundle } from "js/canvas";
import { appVersion } from "js/config";
import getLogger, { LogGroup } from "js/core/logger";
import { Slide } from "js/core/models/slide";
import { $, _ } from "js/vendor";

const logger = getLogger(LogGroup.CANVAS);

const CanvasFrame = styled.div`
  width: 1280px;
  height: 720px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, .2);
  outline: solid 1px #ccc;
`;

class CanvasPreview extends Component {
    constructor() {
        super();

        this.ref = React.createRef();

        this.renderPromise = Promise.resolve();
    }

    componentDidMount() {
        const { slideModel, width, theme, onRendered } = this.props;

        this.renderPromise = (async () => {
            const { SlideCanvas } = await getCanvasBundle(appVersion);

            try {
                const model = new Slide(_.cloneDeep(slideModel), { disconnected: true });
                this.slideCanvas = new SlideCanvas({
                    dataModel: model,
                    canvasWidth: 1280,
                    canvasHeight: 720,
                    editable: false,
                    theme,
                    generateImages: false,
                });
                $(this.ref.current).append(this.slideCanvas.render().$el);

                this.slideCanvas.$el.css({ transform: `scale(${width / 1280})` });

                await this.slideCanvas.renderSlide();

                onRendered && onRendered();
            } catch (err) {
                logger.error(err, "[CanvasPreview] componentDidMount() renderPromise failed", { slideId: this.props.slideModel?.id });
            }
        })();
    }

    componentDidUpdate(prevProps) {
        let { onRendered } = this.props;
        if (prevProps.theme !== this.props.theme) {
            this.renderPromise = this.renderPromise
                .then(() => this.slideCanvas.options.theme = this.props.theme)
                .then(() => this.slideCanvas.loadStyles(true))
                .then(() => {
                    this.slideCanvas.getCanvasElement().markStylesAsDirty();
                    this.slideCanvas.refreshCanvas();
                    onRendered && onRendered();
                })
                .catch(err => logger.error(err, "[CanvasPreview] componentDidUpdate() failed", { slideId: this.props.slideModel?.id }));
        }

        if (prevProps.slideColor !== this.props.slideColor) {
            this.slideCanvas.model.layout.slideColor = this.props.slideColor;
            this.slideCanvas.markStylesAsDirty();
            this.slideCanvas.updateCanvasModel(true);
        }
        if (prevProps.backgroundColor !== this.props.backgroundColor) {
            this.slideCanvas.model.layout.backgroundStyle = this.props.backgroundStyle;
            this.slideCanvas.model.layout.backgroundColor = this.props.backgroundColor;
            this.slideCanvas.markStylesAsDirty();
            this.slideCanvas.updateCanvasModel(true);
        }
    }

    render() {
        const { translateX = 0, translateY = 0, scale = 1, transition, onClick } = this.props;

        return (<CanvasFrame ref={this.ref} className="canvas-frame"
            onClick={onClick}
            style={{
                position: "absolute",
                transform: `translateX(${translateX}px) translateY(${translateY}px) scale(${scale})`,
                transformOrigin: "0 0",
                transition: transition ? "300ms" : "none"
            }}
        />);
    }
}

export default CanvasPreview;
