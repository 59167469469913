import { _ } from "js/vendor";
import { buildVersion, appVersion } from "js/config";
import Api from "js/core/api";
import * as appSearch from "js/core/services/appSearch";
import { getCanvasBundle } from "js/canvas";

import DataService from "./DataService";

interface GallerySlidesDataServiceSearchQuery {
    templateId?: string,
    fullTextSearchQuery?: string,
    size?: number,
    minScore?: number
}

interface GallerySlidesDataServiceDataItem {
    id: string,
    modifiedAt: number,
    templateId: string,
    title: string,
    description: string,
    tags: string,
    score: number
}

class GallerySlidesDataService extends DataService {
    private gallerySlidesData: GallerySlidesDataServiceDataItem[];

    protected async prepare(): Promise<void> {
        const { slideTemplates: { slideTemplates } } = await getCanvasBundle(appVersion);

        // buildVersion is used for cache busting when the app is updated
        const slides = await Api.gallerySlidesMetadata.get({ buildVersion });
        // Enrich slides with the necessary data
        this.gallerySlidesData = slides.map(slide => ({
            modifiedAt: slide.modifiedAt,
            templateId: slide.template_id,
            title: slideTemplates[slide.template_id].title,
            description: slideTemplates[slide.template_id].description,
            tags: slideTemplates[slide.template_id].tags,
            score: Number.POSITIVE_INFINITY,
            id: slide.id
        }));
    }

    public async search(query: GallerySlidesDataServiceSearchQuery): Promise<GallerySlidesDataServiceDataItem[]> {
        const {
            templateId,
            fullTextSearchQuery,
            size = GallerySlidesDataService.SEARCH_QUERY_SIZE,
            minScore = GallerySlidesDataService.SEARCH_QUERY_MIN_SCORE
        } = query;

        let searchResults: GallerySlidesDataServiceDataItem[];
        if (fullTextSearchQuery) {
            const [{ results: appsearchResults }] = await Promise.all([
                // Preparing at the same time when performing full text search - saving time
                appSearch.search({
                    workspaceId: "personal", // Force personal workspace
                    searchEngine: appSearch.SearchEngine.INSPIRATION_GALLERY,
                    query: fullTextSearchQuery,
                    // Always use max size for appsearch to mitigate the subsequent filtering by gallery slides not being removed
                    page: { size: GallerySlidesDataService.SEARCH_QUERY_SIZE },
                    filters: templateId ? { template: [templateId] } : {}
                }),
                this.ensurePrepared()
            ]);

            searchResults = appsearchResults
                // Filter by score
                .filter(result => result._meta.score >= minScore)
                // Map to gallery slides
                .map(({ id: { raw: slideId }, _meta: { score } }): GallerySlidesDataServiceDataItem | null => {
                    const gallerySlide = this.gallerySlidesData.find(({ id }) => id === slideId);
                    if (!gallerySlide) {
                        return null;
                    }
                    // Not forgetting score
                    return { ..._.cloneDeep(gallerySlide), score };
                });
        } else {
            await this.ensurePrepared();
            searchResults = _.cloneDeep(this.gallerySlidesData);
        }

        return searchResults
            // Remove non existing slides
            .filter(item => !!item)
            // Filter by template id (if requested, for appsearch request this would have been done already)
            .filter(template => !templateId || (template.templateId.toLowerCase() === templateId.toLowerCase()))
            // Sort by template id
            .sort((a, b) => a.templateId.localeCompare(b.templateId))
            // Sort by score desc
            .sort((a, b) => b.score - a.score)
            // Respect size
            .slice(0, size);
    }
}

export default GallerySlidesDataService;
