import React from "react";
import styled from "styled-components";

import Spinner from "js/react/components/Spinner";
import { Gap20 } from "js/react/components/Gap";
import CanvasPreview from "js/react/components/CanvasPreview";
import { sanitizeHtml } from "js/core/utilities/dompurify";

import GeneratePresentationController, { GeneratePresentationState } from "../controllers/GeneratePresentationController";

const InnerContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
`;

const SlideCanvases = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
`;

const CanvasFrame = styled.div`
    position: relative;
    width: 320px;
    height: 180px;
    border: solid 1px #ccc;
    background: white;
`;

const SpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
        position: absolute;
        top: 0px;
        padding: 20px;
        font-weight: 600;

        >b {
            font-weight: 900;
        }
    }
`;

const StatusLabel = styled.label`
    font-size: 16px;
    font-weight: bold;
`;

type Props = GeneratePresentationState & {
    excludeStatusLabel?: boolean,
};

const GeneratingSlidesView = GeneratePresentationController.withState(
    function GeneratingSlidesView({
        slideOutlines,
        slideModels,
        theme,
        excludeStatusLabel = false,
    }: Props) {
        return (
            <InnerContainer>
                {
                    !excludeStatusLabel &&
                    <>
                        <StatusLabel>Generating slides...</StatusLabel>
                        <Gap20 />
                    </>
                }
                <SlideCanvases>
                    {slideOutlines.map((slideOutline, idx) => (
                        <CanvasFrame key={idx}>
                            {slideModels[idx] &&
                                <CanvasPreview
                                    slideModel={slideModels[idx]}
                                    theme={theme}
                                    scale={0.25}
                                />
                            }
                            {!slideModels[idx] &&
                                <SpinnerContainer>
                                    <label dangerouslySetInnerHTML={{ __html: sanitizeHtml((slideOutline.prompt ?? slideOutline.title).replace(/\*([^*]+)\*/g, "<b>$1</b>")) }}></label>
                                    <Spinner />
                                </SpinnerContainer>
                            }
                        </CanvasFrame>
                    ))}
                </SlideCanvases>
            </InnerContainer>
        );
    }
);

export default GeneratingSlidesView;
