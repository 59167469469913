import React, { Component } from "react";

import classNames from "classnames";

import { DialogTitle } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { dialogTheme } from "js/react/materialThemeOverrides";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import sanitizeRelativeUrl from "common/utils/sanitizeRelativeUrl";

import stripeProducts from "common/stripeProducts";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import Spinner from "js/react/components/Spinner";

import PaymentForm from "./PaymentForm";

import "css/billing.scss";

export class RenewSubscription extends Component {
    constructor() {
        super();
        this.state = {
            isSubmitting: false
        };
    }

    refreshApp = () => {
        setTimeout(() => {
            const continueUrl = sanitizeRelativeUrl(new URLSearchParams(window.location.search).get("continue") ?? "/");
            window.location.href = continueUrl;
        }, 50);
    };

    handleOnBeforeCharge = () => this.setState({ isSubmitting: true });

    handleOnFailure = () => this.setState({ isSubmitting: false });

    handlePromoChanged = promo => this.setState({ promo });

    render() {
        const { subscription, teamName, orgId } = this.props;
        const { isSubmitting } = this.state;

        const billingInterval = subscription?.interval ?? "year";

        // we need to determine the product name based on the subscription, but based on stripe retention
        // Stripe's test mode subscription data retention policy automatically cancels test mode subscriptions created 90 days ago and deletes them 30 days after cancelation.
        const productName = subscription ? Object.entries(stripeProducts).find(([, product]) => product.productId === subscription.product)[0] : (
            orgId ? "team" : "pro"
        );

        const { amount, planId } = stripeProducts[productName].plans.find(plan => plan.isDefault && plan.interval === billingInterval);

        return (
            <MuiThemeProvider theme={dialogTheme}>
                <BeautifulDialog preventClose={isSubmitting} >
                    <DialogTitle>Reactivate canceled account</DialogTitle>
                    <DialogContent>
                        <div
                            className={classNames({
                                disabled: isSubmitting
                            })}
                        >
                            {isSubmitting && <Spinner />}
                            <PaymentForm
                                organizationId={orgId}
                                teamName={teamName}
                                seatCount={subscription?.quantity}
                                priceId={planId}
                                customerType={productName}
                                onSuccess={() => {
                                    this.refreshApp();
                                }}
                                isReactivating={true}
                                onBeforeSubmit={this.handleOnBeforeCharge}
                                onFailure={this.handleOnFailure}
                                submitLabel="Reactivate account"
                                submitButtonStyle={{ marginTop: 30 }}
                                hasTakenTrial={true}
                                onPromoChanged={this.handlePromoChanged}
                            />
                        </div>
                    </DialogContent>
                </BeautifulDialog>
            </MuiThemeProvider>
        );
    }
}

export default withFirebaseUser(RenewSubscription);
