import React, { Component } from "react";

import { _ } from "js/vendor";
import getLogger from "js/core/logger";
import { ds } from "js/core/models/dataService";
import * as geom from "js/core/utilities/geom";
import { trackActivity } from "js/core/utilities/utilities";
import { TaskType, TaskState, AssetType, ImageErrorType } from "common/constants";
import TaskProgressDialog from "js/react/components/Dialogs/TaskProgressDialog";
import { FileSource, FileType } from "js/core/utilities/fileSourcesAndTypes";
import ImportFileDialog from "js/react/views/ImportFile/ImportFileDialog";
import { getImageType, isValidMediaType } from "js/core/utilities/imageUtilities";
import { getVideoAsset } from "js/core/utilities/videoUtilities";
import { uploadFileAndCreateTask } from "js/core/services/tasks";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";

interface Props {
    fileTypes: [FileType];
    imageAsLogo?: boolean;
    backgroundVideoOnly?: boolean;
    closeDialog: () => void;
    onSuccess: (asset: any) => void;
}

interface State {
    file: File;
    url: string;
    progress: number;
    uploadState: string; // typeof TaskState;
    asset: any; // instanceof uploaded Asset model;
    label: string;
    error: string;
    serviceName: string;
}

const logger = getLogger();

export default class ImportMediaDialog extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            file: null,
            url: null,
            progress: null,
            uploadState: null,
            asset: null,
            label: "",
            error: null,
            serviceName: null
        };
    }

    handleSuccess = () => {
        const { onSuccess, closeDialog } = this.props;
        const { asset, error, serviceName } = this.state;

        if (error && !asset) {
            this.setState({ url: null });
            // @ts-ignore
            ShowWarningDialog({
                title: "Can't add video",
                message: `${serviceName} returned message: ${error}`
            });
            return;
        } else {
            onSuccess(asset);
        }
        closeDialog && closeDialog();
    }

    handleFilePicked = (file: File, fileSource: FileSource) => {
        this.setState({ file, progress: 0, label: file.name, uploadState: TaskState.PREPARING });

        try {
            const { isValidImage, isValidVideo } = isValidMediaType(file);

            if (isValidImage) {
                this.uploadImageOrLogo(file, fileSource);
            } else if (isValidVideo) {
                this.uploadVideo(file);
            }
        } catch (err) {
            logger.error(err, `[ImportMediaDialog] handleFilePicked() failed`);
        }
    }

    handleAddedVideoUrl = async (url: string) => {
        this.setState({ progress: 0, url, label: "Video", uploadState: TaskState.PREPARING });

        getVideoAsset(url)
            .then(({ asset, error, serviceName }) => {
                this.setState({
                    progress: 100,
                    uploadState: TaskState.FINISHED,
                    asset,
                    error,
                    serviceName
                }, () => this.handleSuccess());
            })
            .catch(err => {
                this.setState({
                    progress: 0,
                    uploadState: TaskState.ERROR,
                    label: "We are unable to process this video URL",
                });
                logger.error(err, "[ImportMediaDialog] handleAddedVideoUrl() failed");
            });
    }

    uploadImageOrLogo = (file: File, fileSource: FileSource) => {
        const { imageAsLogo } = this.props;

        const fileName = file.name;

        const props = {
            "slide_id": ds.selection.slide?.id,
            "uploaded_from": fileSource || FileSource.Local,
        };
        if (imageAsLogo) {
            trackActivity("Logo", "Upload", fileName, null, props, { audit: true, skipAmplitude: false });
        } else {
            trackActivity("Image", "Upload", fileName, null, props, { audit: true, skipAmplitude: false });
        }

        let fileType = getImageType(file);
        const imageProperties = {} as any;
        if (imageAsLogo) {
            // force all logos have solid white backgrounds
            imageProperties.hasSolidBackground = true;
            imageProperties.imageBackgroundColor = "#FFFFFF";
        }

        ds.assets
            .getOrCreateImage({
                file,
                name: fileName,
                fileType,
                assetType: imageAsLogo ? AssetType.LOGO : AssetType.IMAGE,
                metadata: {
                    source: "upload"
                },
                ...(imageAsLogo ? {
                    hasSolidBackground: true,
                    imageBackgroundColor: "#FFFFFF"
                } : {}),
            })
            .then(asset => {
                this.setState({
                    progress: 100,
                    uploadState: TaskState.FINISHED,
                    asset: {
                        url: asset.getUrl && asset.getUrl(),
                        ...asset,
                        ...asset.attributes,
                        type: asset.type,
                        id: asset.id,
                        size: new geom.Size(asset.get("w"), asset.get("h")),
                    },
                }, () => this.handleSuccess());
            })
            .catch(err => {
                let label;
                if (err.type === ImageErrorType.SIZE) {
                    label = err.message;
                } else if (err.type === ImageErrorType.BAD_DATA) {
                    label = "We were unable to upload this image. Please try again.";
                } else {
                    label = "We are unable to process and read this image.";
                }
                this.setState({
                    progress: 0,
                    uploadState: TaskState.ERROR,
                    label,
                });
                logger.error(err, "[ImportMediaDialog] uploadFile() failed");
            });
    }

    uploadVideo = (file: File) => {
        uploadFileAndCreateTask(file, TaskType.VIDEO_UPLOAD, async task => {
            if (task.state === TaskState.ERROR) {
                this.setState({
                    progress: 0,
                    uploadState: TaskState.ERROR,
                    label: task.errorMessage
                });
                return;
            }

            if (task.state === TaskState.PREPARING) {
                this.setState({
                    progress: task.stateProgressPercents / 2,
                    uploadState: TaskState.PREPARING
                });
                return;
            }

            if (task.state === TaskState.PROCESSING) {
                this.setState({
                    progress: task.stateProgressPercents / 2 + 50,
                    uploadState: TaskState.PROCESSING
                });
                return;
            }

            if (task.state === TaskState.FINISHED) {
                this.setState({
                    progress: 100,
                    uploadState: TaskState.FINISHED
                });

                getVideoAsset(null, task.videoAssetId)
                    .then(({ asset, error }) =>
                        this.setState({ asset, error }, () => this.handleSuccess())
                    );
                return;
            }
        });
    }

    getUploadStateText = () => {
        const { uploadState } = this.state;

        switch (uploadState) {
            case TaskState.PREPARING:
                return "Uploading Media";
            case TaskState.PROCESSING:
                return "Processing Media";
            case TaskState.ERROR:
                return "Error";
            case TaskState.FINISHED:
                return "Your Media Is Ready";
            default:
                return null;
        }
    }

    render() {
        const { fileTypes, closeDialog } = this.props;
        const { file, url, progress, uploadState, label } = this.state;

        const isVideoOnly = fileTypes.length === 1 && fileTypes.includes(FileType.Video);
        const isImageOnly = fileTypes.length === 1 && fileTypes.includes(FileType.Image);

        if (file || url) {
            let error = {};
            if (uploadState === TaskState.ERROR) {
                error = {
                    onAction: closeDialog,
                    actionLabel: "OK"
                };
            }

            return (
                <TaskProgressDialog
                    label={label}
                    titleLabel={this.getUploadStateText()}
                    progress={progress}
                    taskState={uploadState}
                    {...error}
                />
            );
        }

        return (
            <ImportFileDialog
                title={`Import ${isVideoOnly ? "Video" : "Media"}`}
                description={!isVideoOnly && (
                    isImageOnly ? "Import an image or GIF" : "Import a video, image, or gif"
                )}
                fileTypes={fileTypes}
                onSuccess={(fileOrVideoUrl: File | string, fileSource?: FileSource) => {
                    if (fileOrVideoUrl instanceof File) {
                        this.handleFilePicked(fileOrVideoUrl, fileSource);
                    } else {
                        this.handleAddedVideoUrl(fileOrVideoUrl);
                    }
                }}
                closeDialog={closeDialog}
            />
        );
    }
}
