import { IWorkspace } from "../../common/interfaces";

import { getCallable } from "../_sdk/client";
import {
    GetWorkspacesRequest,
    GetWorkspacesResponse,
    GetWorkspaceRequest,
    GetWorkspaceResponse,
    UpdateWorkspaceRequest,
    GetWorkspaceResourcesRequest,
    GetWorkspaceResourcesResponse,
    GetWorkspaceResourceRequest,
    GetWorkspaceResourceResponse
} from "./types";
import { endpoints, apiName } from "./endpoints";

export { apiName };

const getWorkspaces = getCallable<GetWorkspacesRequest, GetWorkspacesResponse>(apiName, "getWorkspaces", {
    ...endpoints.getWorkspaces,
    composer: request => {
        const searchParameters = new URLSearchParams();
        if (request.filter?.roles) {
            searchParameters.set("roles", request.filter.roles.join(","));
        }

        return {
            url: `/workspaces?${searchParameters.toString()}`,
            options: {
                method: "GET"
            }
        };
    }
});

const getWorkspace = getCallable<GetWorkspaceRequest, GetWorkspaceResponse>(apiName, "getWorkspace", {
    ...endpoints.getWorkspace,
    composer: request => {
        return {
            url: `/workspaces/${encodeURIComponent(request.id)}`,
            options: {
                method: "GET"
            }
        };
    }
});

const updateWorkspace = getCallable<UpdateWorkspaceRequest, IWorkspace>(apiName, "updateWorkspace", {
    ...endpoints.updateWorkspace,
    composer: request => {
        return {
            url: `/workspaces/${encodeURIComponent(request.id)}`,
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const getWorkspaceResources = getCallable<GetWorkspaceResourcesRequest, GetWorkspaceResourcesResponse>(apiName, "getWorkspaceResources", {
    ...endpoints.getWorkspaceResources,
    composer: request => {
        let url = `/workspaces/${encodeURIComponent(request.id)}/resources`;
        if (request.filter) {
            if (request.filter.permissionId) {
                url += `?permissionId=${encodeURIComponent(request.filter.permissionId)}`;
            }
        }

        return {
            url,
            options: {
                method: "GET"
            }
        };
    }
});

const getWorkspaceResource = getCallable<GetWorkspaceResourceRequest, GetWorkspaceResourceResponse>(apiName, "getWorkspaceResource", {
    ...endpoints.getWorkspaceResource,
    composer: request => {
        return {
            url: `/workspaces/${encodeURIComponent(request.workspaceId)}/resources/${encodeURIComponent(request.resourceId)}`,
            options: {
                method: "GET"
            }
        };
    }
});

export const callables = {
    getWorkspaces,
    getWorkspace,
    updateWorkspace,
    getWorkspaceResources,
    getWorkspaceResource
};
