import { _ } from "js/vendor";
import React, { Component } from "react";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import { BeautifulDialog, DialogContent, ShowDialog, ShowDialogAsync, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { FlexSpacer, Gap10, Gap20 } from "js/react/components/Gap";
import Spinner from "js/react/components/Spinner";
import { app } from "js/namespaces";
import { trackActivity } from "js/core/utilities/utilities";
import TextArea from "js/react/components/TextArea";
import { ToggleAllowCommenting } from "../components/ToggleAllowCommenting";
import PreviewEmailDialog from "./PreviewEmailDialog";
import LinkExpiration from "js/react/components/LinkExpiration";
import { createInviteSuggestionContext } from "js/react/components/InviteInput/InviteContext";
import { InviteTagsInput } from "js/react/components/InviteInput/InviteTagsInput";
import { ConfirmInviteDialog } from "js/react/components/InviteInput/ConfirmInviteDialog";
import linkDS, { LinkType } from "js/react/views/PresentationSettings/dataservices/LinkDataService";
import permissionsDS from "../dataservices/PermissionsDataService";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.INVITES);

export default class CreateInviteDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inviteContext: null,
            customMessage: "",
            showSpinner: false,
            hideLinkSettings: props.hideLinkSettings || false,
            allowCommenting: props.allowCommenting || false,
            linkExpiresAt: props.linkExpiresAt || null,
            linkShouldExpire: props.linkShouldExpire || false,
            linkHasExpired: props.linkHasExpired || false,
        };
    }

    static get type() {
        return "CreateInvite";
    }

    async componentDidMount() {
        const { presentation } = this.props;

        const inviteContext = createInviteSuggestionContext({
            includeFolders: false,
            allowOldSelection: true,
            presentationId: presentation.id,
            saveContext: inviteContext => this.setState({ inviteContext }),
        });

        this.setState({ inviteContext });
    }

    componentWillUnmount() {
        this.state.inviteContext.destroy();
    }

    handleAccept = async () => {
        const { presentation } = this.props;
        const {
            inviteContext,
            customMessage,
            allowCommenting,
            linkExpiresAt,
        } = this.state;

        try {
            this.setState({ showSpinner: true });

            // We're calling getSelectionWithRedundanciesRemoved() instead of getSelectedUniqueUsers() because
            //   we are not suggesting folders and we want to to ensure non-redundant invitations
            const users = inviteContext.getSelectionWithRedundanciesRemoved();

            await ShowDialogAsync(ConfirmInviteDialog, {
                title: "Invite the following viewers?",
                includeSubheader: false,
                hasPermissionEditing: false,
                users,
                callback: async permission => {
                    const emails = users.map(user => user.email);

                    const asyncInvites = emails.map(email => {
                        return linkDS.createInviteLink(
                            presentation,
                            email,
                            customMessage,
                            linkExpiresAt,
                            allowCommenting
                        )
                            .then(link => {
                                return linkDS
                                    .saveLink(presentation, link)
                                    .then(() => link.id);
                            });
                    });

                    const linkIds = await Promise.all(asyncInvites);
                    await permissionsDS.fetchPermissions(this.props.presentation.id);

                    const analyticsProps = {
                        presentation_id: presentation.id,
                        link_ids: linkIds,
                        is_new: true,
                        type: "email",
                        sender_email: app.user.getEmail(),
                        recipient_emails: emails,
                        workspace_id: presentation.getWorkspaceId()
                    };
                    trackActivity("Sharing", "EmailLink", null, null, analyticsProps, { audit: true });

                    // Close next frame to avoid React errors
                    setTimeout(() => {
                        this.props.closeDialog();
                        this.props.callback();
                    });
                },
            });
        } catch (err) {
            ShowErrorDialog({
                error: "Error while sharing",
                message: err.message
            });
            logger.error(err, "[CreateInviteDialog] handleAccept() failed", { presentationId: presentation?.id });
        } finally {
            this.setState({ showSpinner: false });
        }
    };

    handleUpdateLinkExpiration = ({
        linkExpiresAt,
        linkShouldExpire,
        linkHasExpired,
    }) => {
        this.setState({
            linkExpiresAt,
            linkShouldExpire,
            linkHasExpired,
        });
    }

    handleChangeCustomMessage = customMessage => {
        this.setState({
            customMessage
        });
    };

    handlePreviewEmail = async () => {
        ShowDialog(PreviewEmailDialog, {
            presentation: this.props.presentation,
            customMessage: this.state.customMessage
        });
    };

    toggleAllowCommenting = event => {
        const { allowCommenting } = this.state;
        this.setState({ allowCommenting: !allowCommenting });
        this.forceUpdate();
    }

    render() {
        const { presentation } = this.props;
        const {
            inviteContext,
            customMessage,
            showSpinner,
            hideLinkSettings,
            allowCommenting,
            linkExpiresAt,
            linkShouldExpire,
            linkHasExpired,
        } = this.state;

        const isSendDisabled = !inviteContext?.selection.length || (linkShouldExpire && linkHasExpired);

        return (
            <BeautifulDialog
                maxWidth="md"
                closeDialog={this.props.closeDialog}
            >
                {showSpinner && <Spinner />}
                <DialogTitle>
                    Send Email Invitation
                </DialogTitle>
                <DialogContent>
                    <Gap10 />
                    {
                        inviteContext &&
                        <InviteTagsInput
                            inviteContext={inviteContext}
                            sourceName="CreateInviteDialog"
                        />
                    }
                    <Gap10 />
                    <TextArea
                        isVisible
                        text={customMessage}
                        onChange={customMessage => this.handleChangeCustomMessage(customMessage)}
                        placeholder="Add a note..."
                    />

                    {!hideLinkSettings && (
                        <>
                            <Gap20 />
                            <ToggleAllowCommenting
                                allowCommenting={allowCommenting}
                                onToggle={this.toggleAllowCommenting}
                            />
                            <LinkExpiration
                                expiresAt={linkExpiresAt}
                                onUpdate={this.handleUpdateLinkExpiration}
                                presentation={presentation}
                                stacked
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handlePreviewEmail}>Preview Email</Button>
                    <FlexSpacer />
                    <Button onClick={this.props.closeDialog}>
                        Cancel
                    </Button>
                    <Button disabled={isSendDisabled} onClick={this.handleAccept} color="primary">
                        Send Email
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
