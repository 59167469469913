/**
 * Gets rectangles' top-left and bottom-right coordinates with filled 1s in a 2D array
 * https://www.geeksforgeeks.org/find-rectangles-filled-0/
 */

function findend(i, j, a, output, index) {
    // flags to check column & row edge cases, initializing with 0
    let flagc = 0, flagr = 0;

    let m, n;
    for (m = i; m < a.length; m++) {
        // loop breaks where first 0 encounters
        if (a[m][j] === 0) {
            flagr = 1;
            break;
        }

        // pass because already processed
        if (a[m][j] === 5) {
            continue;
        }

        for (n = j; n < a[0].length; n++) {
            // loop breaks where first 0 encounters
            if (a[m][n] === 0) {
                flagc = 1;
                break;
            }

            // fill rectangle elements with any number so that we can exclude next time
            a[m][n] = 5;
        }
    }

    output[index].push(Math.min(flagr === 1 ? m - 1 : m, a.length - 1));
    output[index].push(Math.min(flagc === 1 ? n - 1 : n, a[0].length - 1));
}

function getRectangleCoordinates(a) {
    // output array where we are going to store our output
    let output = [];

    // will be used for storing start and end location in the same index
    let index = -1;

    for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < a[0].length; j++) {
            // check if both right & bottom cells or the cell itself is 0
            if (a[i][j] === 1) {
                // storing initial position of rectangle
                output.push([i, j]);

                // will be used for the last position
                index = index + 1;
                findend(i, j, a, output, index);
            }
        }
    }

    return output;
}

export default getRectangleCoordinates;
