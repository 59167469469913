const theme = {
    isBuiltIn: true,
    id: "bold",
    name: "Bold",
    showLogo: true,
    logoScale: 1,
    logoPosition: "left",
    logoOffset: 0,
    showMessage: true,
    showPageNum: true,
    logo: null,
    logo_dark: null,
    showFooterByDefault: null,
    palette_name: "bold",
    defaultSlideColor: "theme",
    defaultBackgroundColor: "background_light",
    styleFonts: 1,
    styleFontWeight: "heavy",
    styleElementStyle: "filled",
    styleEffect: "none",
    styleDesign: 1,
    styleDecoration: "none_center",
    styleHeaderAlignment: "left",
    styleShape: "rect",
    styleColor: "slide",
    styleBackground: "none",
    styleTitleFont: "bebasneue",
    styleBodyFont: "sourcesanspro",
    styleWeight: "light",
    styleTitleSlide: "bar_left",
    fontScale: 1,
    iconStyle: "chunky",
    cjkFont: "jp",
    fontHeaderFontId: "bebasneue",
    fontHeaderWeight: 700,
    fontHeaderLetterSpacing: 0.01,
    fontHeaderLineHeight: 1.3,
    fontHeaderScaling: 125,
    fontHeaderTextTransform: "auto",
    fontTitleFontId: "sourcesanspro",
    fontTitleWeight: 400,
    fontTitleLetterSpacing: 0.02,
    fontTitleLineHeight: 1.8,
    fontTitleScaling: 90,
    fontTitleTextTransform: "auto",
    fontBodyFontId: "sourcesanspro",
    fontBodyWeight: 400,
    fontBodyLetterSpacing: 0.02,
    fontBodyLineHeight: 2,
    fontBodyScaling: 90,
    fontBodyTextTransform: "auto",
};

module.exports = theme;
