export const themeColors = {
    ui_blue: "#11a9e2",
    darkBlue: "#14516e",
    selection: "#DAE9F0",
    rollover: "#E8F7FD",
    lightBlue: "#DAE9F0",
    hoverBlue: "#F7FAFC",
    darkHoverBlue: "#d6eff9",
    lighterGray: "#AAAA",
    lightGray: "#eeeeee",
    mediumGray: "#666",
    gray: "#999",
    darkGray: "#222",
    yellow: "#ffaa00",
    warning: "orangered",
    textSelection: "rgba(17,169,226,0.3)",
    ui_blue50percent: "rgba(17, 169, 226, 0.5)",
    contextBlue: "rgba(195,233,251,0.22)",
    aiColor: "purple",

    // color defaults for
    authoringThemeBaseColor: "#96BB47",
    smartSlideThemeBaseColor: "#11a9e2",
};
