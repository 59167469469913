import React, { Component } from "react";
import styled from "styled-components";
import { Icon } from "@material-ui/core";

import { getStaticUrl } from "js/config";
import Logos from "js/core/models/logos";
import { Thumbnail, ThumbnailContainer } from "js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import { _ } from "js/vendor";
import { PREDEFINED_PALETTES } from "common/constants";
import { GenerateThemeThumbnail } from "../GenerateThemeThumbnail";

import getLogger from "js/core/logger";

const logger = getLogger();

const ColorChits = styled.div`
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: flex-start;
    padding: 0px 10px;
    width: 100%;
    gap: 4px;
`;

const ColorChit = styled.div`
    border-radius: 50%;
    width: 14px;
    height: 14px;
    
`;

const ThumbnailBar = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    label {
        font-size: 14px;
        letter-spacing: 0px;
        color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        flex-grow: 2;
        text-transform: none;
        margin: 0px;
    }

    button {
        padding: 2px;
    }
`;

const LogoImg = styled.div`
    position: absolute;
    top: 0px;
    width: 100%;
    height: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        position: initial !important;
        width: 70% !important;
        height: 60%;
        object-fit: contain;
    }
`;

const HiddenSlideBadge = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    z-index: 100;
    border-radius: 50%;
    background: orangered;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: white;
        font-size: 20px;
    }
`;

export class ThemeThumbnail extends Component {
    lastFetchedLogoPath;

    state = {
        url: null,
        logoURL: null,
        isRollover: false
    }

    constructor() {
        super();

        this.ref = React.createRef();
    }

    async componentDidMount() {
        let theme = this.props.item;

        if (theme.get("isBuiltIn")) {
            const url = getStaticUrl(`/builtinThemes/new/${theme.id}.svg`);
            this.setState({ url });
        } else {
            try {
                // if we do not have a thumbnail we need to generate one
                let thumbnailId = theme.get("thumbnailId");
                if (!thumbnailId) {
                    thumbnailId = await GenerateThemeThumbnail(theme.attributes);
                    theme.update({ thumbnailId });
                }

                // We shouldn't encounter a case of a theme without a thumbnail, but just in case
                const url = thumbnailId ? await Logos.getSignedUrlAndLoad(thumbnailId) : null;

                this.setState({ url });
            } catch (err) {
                logger.error(err, "ThemeThumbnail componentDidMount() failed", { themeId: theme?.id });
            }
        }
    }

    handleDeleteTheme = () => {
        this.props.onDeleteTheme(this.props.item);
    }

    handleMouseEnter = event => {
        this.setState({ isRollover: true });
    }

    handleMouseLeave = event => {
        this.setState({ isRollover: false });
    }

    render() {
        let { item: theme, onClick, onDoubleClick, selected } = this.props;
        let { url, logoUrl, isRollover } = this.state;

        let backgroundColor = theme.getColors()[theme.get("defaultBackgroundColor") ?? "background_light"];

        let palette;
        if (theme.get("palette_name") != "custom") {
            let predefinedPalette = _.find(PREDEFINED_PALETTES, { id: theme.get("palette_name") || "colorful" });
            if (!predefinedPalette) {
                predefinedPalette = _.find(PREDEFINED_PALETTES, { id: "colorful" });
            }

            palette = predefinedPalette.colors;
        } else {
            palette = theme.get("colors");
        }

        let colors = [palette.theme];
        for (let key of Object.keys(palette)) {
            if (key.startsWith("accent")) {
                colors.push(palette[key]);
            }
        }

        const showBar = !theme.get("isBuiltIn");
        const menu = this.props.menu;

        return (
            <ThumbnailContainer
                data-item-id={theme.id}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                ref={this.ref}
            >

                <Thumbnail
                    selected={selected}
                    url={url}
                    style={{ background: backgroundColor, border: "solid 1px #ccc", boxShadow: "0px 0px 20px rgba(0,0,0,.05)" }}
                    showSpinner={false}

                >
                    {theme.get("hidden") && theme.has("orgId") &&
                        <HiddenSlideBadge><Icon>visibility_off</Icon></HiddenSlideBadge>}

                    {
                        // We are generating a thumbnail image for non built-in themes, but not for built-in ones
                        // so we need to present the theme colors
                        theme.get("isBuiltIn") &&
                        <ColorChits>
                            {colors.map(color => <ColorChit key={_.uniqueId()} style={{ background: color }} />)}
                        </ColorChits>
                    }
                </Thumbnail>

                {showBar && (
                    <ThumbnailBar>
                        <label>{theme.get("name")}</label>
                        {menu}
                    </ThumbnailBar>
                )}

            </ThumbnailContainer>
        );
    }
}

