import { Component } from "reactn";
import React from "reactn";
import Spinner from "js/react/components/Spinner";

export default class Loadable extends Component {
    render() {
        let {
            isLoading = true,
        } = this.props;

        if (isLoading) {
            return <div style={{ minHeight: 100 }}><Spinner /></div>;
        } else {
            return this.props.children || null;
        }
    }
}
