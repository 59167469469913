import React, { Component, Fragment } from "react";
import { Gap10, Gap30 } from "js/react/components/Gap";
import { ThemeType } from "common/constants";
import { ds } from "js/core/models/dataService";
import styled from "styled-components";
import { $ } from "js/vendor";
import { IconMenu } from "js/react/components/UiComponents";
import { Icon, MenuItem } from "@material-ui/core";
import { ThemeThumbnail } from "js/editor/ThemeEditor/components/ThemeThumbnail";
import { ScrollBox } from "js/react/components/LayoutGrid";
import { SimpleLabel } from "js/react/components/SimpleLabel";
import AppController from "js/core/AppController";
import { delay } from "js/core/utilities/promiseHelper";

const ThumbnailScrollBox = styled(ScrollBox)`
    padding: 0 20px 0px 20px;
    background: #f1f1f1;
`;

export class ThemeThumbnailList extends Component {
    state = {
        teamThemes: [],
        userThemes: [],
        builtInThemes: []
    }

    componentDidMount() {
        const teamThemes = ds.sharedThemes.getThemesInWorkspace(AppController.orgId).sort((a, b) => a.id.localeCompare(b.id)); // lexicographic sort
        const userThemes = ds.userThemes.getThemesInWorkspace(AppController.orgId);
        const builtInThemes = ds.builtInThemes.models;

        this.setState({
            teamThemes,
            userThemes,
            builtInThemes,
        });
    }

    render() {
        const {
            styles,
            selectedTheme,
            requireTeamTheme,
            onClick,
            onDoubleClick,
            width = "100%",
            pptTheme,
        } = this.props;
        const {
            teamThemes,
            userThemes,
            builtInThemes,
        } = this.state;

        return (
            <ThumbnailScrollBox id="theme-thumbnail-list" fillHeight fillWidth style={styles}>
                {pptTheme && (
                    <Fragment>
                        <SimpleLabel>POWERPOINT THEME</SimpleLabel>
                        <Gap10 />
                        <ThemeThumbnailGrid
                            type={ThemeType.PPT_THEME}
                            themes={[pptTheme]}
                            width={width}
                            selectedTheme={selectedTheme}
                            onClick={theme => onClick(theme, ThemeType.TEAM)}
                            onDoubleClick={theme => onDoubleClick(theme, ThemeType.TEAM)}
                        />
                        <Gap30 />
                    </Fragment>
                )}
                {teamThemes.length > 0 && (
                    <Fragment>
                        <SimpleLabel>
                            TEAM THEMES
                        </SimpleLabel>
                        <Gap10 />
                        <ThemeThumbnailGrid
                            type={ThemeType.TEAM}
                            themes={teamThemes}
                            width={width}
                            selectedTheme={selectedTheme}
                            onClick={theme => onClick(theme, ThemeType.TEAM)}
                            onDoubleClick={theme => onDoubleClick(theme, ThemeType.TEAM)}
                        />
                        <Gap30 />
                    </Fragment>
                )}

                {!requireTeamTheme && userThemes.length > 0 && (
                    <Fragment>
                        <SimpleLabel>
                            SAVED THEMES
                        </SimpleLabel>
                        <Gap10 />
                        <ThemeThumbnailGrid
                            type={ThemeType.USER}
                            themes={userThemes}
                            width={width}
                            selectedTheme={selectedTheme}
                            onClick={theme => onClick(theme, ThemeType.USER)}
                            onDoubleClick={theme => onDoubleClick(theme, ThemeType.USER)}
                        />
                        <Gap30 />
                    </Fragment>
                )}
                {!requireTeamTheme && (
                    <Fragment>
                        <SimpleLabel>BEAUTIFUL.AI THEMES</SimpleLabel>
                        <Gap10 />
                        <ThemeThumbnailGrid
                            type={ThemeType.BUILT_IN}
                            themes={builtInThemes}
                            width={width}
                            selectedTheme={selectedTheme}
                            onClick={theme => onClick(theme, ThemeType.BUILT_IN)}
                            onDoubleClick={theme => onDoubleClick(theme, ThemeType.BUILT_IN)}
                        />
                    </Fragment>
                )}
            </ThumbnailScrollBox>
        );
    }
}

const ThemeThumbnailGridContainer = styled.div`
    position: relative;
    width: 100%;
    transition: 500ms;
`;

const ThemeThumbnailWrapper = styled.div.attrs(props => ({
    style: {
        ...props.bounds
    }
}))`
    position: absolute;
    transition: ${props => props.transition ? 500 : 0}ms;
    width: 200px;
    height: 180px;
`;

class ThemeThumbnailGrid extends Component {
    constructor() {
        super();
        this.ref = React.createRef();

        this.state = {
            width: null,
            transforms: null,
            transition: false
        };

        this.isUnmounted = false;
    }

    componentDidMount() {
        this.setState({ transition: true });

        $(window).on("resize.theme-thumbnail-grid", () => {
            if (!this.ref.current || this.isUnmounted) {
                return;
            }

            this.layout(this.ref.current.clientWidth);
        });
        // Wait a frame to ensure the ref has been setup, then layout
        window.requestAnimationFrame(() => {
            if (!this.ref.current || this.isUnmounted) {
                return;
            }

            this.layout(this.ref.current.clientWidth);
        });
    }

    componentWillUnmount() {
        this.isUnmounted = true;

        $(window).off(".theme-thumbnail-grid");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.width !== prevProps.width) {
            this.layout(this.props.width);
        }
    }

    layout(width) {
        const { themes, maxColumnWidth = 250 } = this.props;

        if (!width || !themes.length) {
            return {};
        }

        const gap = 10;

        const columns = Math.max(3, Math.ceil(width / (maxColumnWidth)));
        let colWidth = (width - gap * (columns - 1)) / columns;
        let rowHeight = colWidth * 0.5625;

        if (!themes.at(0).get("isBuiltIn")) {
            rowHeight += 30;
        }

        let height = 0;
        let transforms = [];
        for (let i = 0; i < themes.length; i++) {
            let col = i % columns;
            let row = Math.floor(i / columns);

            let x = (colWidth + gap) * col;
            let y = (rowHeight + gap) * row;

            transforms.push({
                left: x,
                top: y,
                width: colWidth,
                height: rowHeight,
            });

            height = y + rowHeight + gap;
        }

        this.setState({ transforms, width, height });
    }

    getMenu = theme => {
        switch (this.props.type) {
            case ThemeType.USER:
                return (
                    <IconMenu>
                        <MenuItem onClick={() => this.handleEdit(theme)}><Icon>edit</Icon>Edit Theme...</MenuItem>
                        <MenuItem onClick={() => this.handleRename(theme)}><Icon>label</Icon>Rename</MenuItem>
                        <MenuItem key="delete"
                            onClick={() => this.handleDelete(theme)}><Icon>delete</Icon>Delete...</MenuItem>
                    </IconMenu>
                );
        }
    }

    render() {
        const { themes, selectedTheme, onClick, onDoubleClick } = this.props;
        const { transforms, height, transition } = this.state;

        return (
            <ThemeThumbnailGridContainer ref={this.ref} style={{ height }}>
                {transforms && themes.map((theme, index) => {
                    return (
                        <ThemeThumbnailWrapper
                            id={`theme-thumbnail-${theme.id}`}
                            key={index}
                            bounds={transforms[index]}
                            onClick={() => onClick(theme)}
                            onDoubleClick={() => onDoubleClick(theme)}
                            transition={transition}
                        >
                            <ThemeThumbnail
                                item={theme}
                                selected={theme == selectedTheme}
                            />
                        </ThemeThumbnailWrapper>
                    );
                })}
            </ThemeThumbnailGridContainer>
        );
    }
}

