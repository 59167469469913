// Utility functions used for commands

// each browser session will generate a unique ID, which is only used to break ties on clock tick + timestamp
function makeSession() {
    let sessionString = "";
    for (let ii = 0; ii < 8; ii++) {
        sessionString += "" + Math.floor(Math.random() * 16).toString(16);
    }
    return sessionString;
}

function padLeft(str) {
    return "00000000".slice(str.length) + str;
}

// ID comprises (1) a Lamport clock tick (2) a timestamp and (3) a session ID, all in hex code fixed 8-character width
function parseId(id) {
    let endTS = id.length - 8;
    return {
        tick: parseInt(id.slice(0, 8), 16),
        timestamp: parseInt(id.slice(8, endTS), 16),
        session: id.slice(endTS)
    };
}

function getInitialId() {
    return makeId(0, 0, "00000000");
}

function makeId(tick, timestamp, session) {
    return padLeft(tick.toString(16)) + padLeft(timestamp.toString(16)) + session;
}

function makeArrayKey() {
    let key = "";
    for (let ii = 0; ii < 16; ii++) {
        key += "" + Math.floor(Math.random() * 16).toString(16);
    }
    return key;
}

// Given a function f : [xs] -> ()
// Create a new function f' : [xs] -> Promise()
// f' will collect all arguments during a single stack-frame, then call f with a timeout
// so if you run f'([1]) f'([2]) f'([3]), then f will be called once with [1,2,3]
// this is used to collect all updates during a single stack frame and send just one command
// f' will return a promise which resolves after f actually runs
function collectArguments(fn) {
    let args = [];
    let promise = null;
    return function(nextArgs) {
        args = args.concat(nextArgs);
        if (args.length && !promise) {
            promise = new Promise(
                resolve => setTimeout(function() {
                    let fnArgs = args;
                    args = [];
                    promise = null;
                    fn(fnArgs);
                    resolve();
                }, 0)
            );
        }
        return promise;
    };
}

function isEqual(left, right) {
    if (left == null || right == null) return left == null && right == null;
    if (left === right || Number.isNaN(left) && Number.isNaN(right)) return true;
    if (typeof left != "object" || typeof right != "object") {
        return false;
    }
    for (let key of new Set([...Object.keys(left), ...Object.keys(right)])) {
        if (left[key] == null && right[key] == null) {
            continue;
        }
        if (!left.hasOwnProperty(key) || !right.hasOwnProperty(key) || !isEqual(left[key], right[key])) {
            return false;
        }
    }
    return true;
}

// delete all empty keys, recursively (e.g. {foo: {bar: null, baz: {}}} -> null).
// For arrays, normalize empty entries to null
//
// plainArrays flag determines if we are operating on simple
// JSON-style data, or a commands model with arrays encoded using
// special __array__ key
//
// Warning: This function modifies its argument
function simplifyNulls(model, plainArrays) {
    for (let key of Object.keys(model)) {
        if (key === "__array__") continue;
        if (model[key] && typeof model[key] === "object") {
            simplifyNulls(model[key], plainArrays);
        }
        if (model[key] == null || typeof model[key] === "object" && Object.keys(model[key]).length === 0) {
            if (plainArrays ? Array.isArray(model) : (model.__array__ || []).indexOf(key) > -1) {
                model[key] = null;
            } else {
                delete model[key];
            }
        }
    }
    return model;
}

function getTick(commandList, snap) {
    let tick = commandList.filter(cmd => !cmd.queued).reduce((t, cmd) => Math.max(parseId(cmd.id).tick, t), 0);
    return Math.max(tick, parseId(snap.id).tick);
}

module.exports = {
    makeSession,
    parseId,
    makeId,
    getInitialId,
    makeArrayKey,
    collectArguments,
    isEqual,
    simplifyNulls,
    getTick,
};
