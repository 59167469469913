export type ReadOnlyLocation = Pick<Location, "href" | "origin" | "protocol" | "host" | "hostname" | "port" | "pathname" | "search" | "hash">

class LocationObserver {
    private _observer: MutationObserver
    private _currentLocation: ReadOnlyLocation

    private _listenToChangesOnFields: Array<keyof ReadOnlyLocation>
    private _onLocationChange: (newLocation: ReadOnlyLocation, currentLocation: ReadOnlyLocation) => void

    constructor(listenToChangesOnFields: Array<keyof ReadOnlyLocation>, onLocationChange: (newLocation: ReadOnlyLocation, currentLocation: ReadOnlyLocation) => void) {
        this._observer = new MutationObserver(this._onMutation);
        this._currentLocation = this._getCurrentLocation();

        this._listenToChangesOnFields = listenToChangesOnFields;
        this._onLocationChange = onLocationChange;

        if (document.readyState !== "complete") {
            document.addEventListener("DOMContentLoaded", () => {
                this._startObserving();
            });
            return;
        }

        this._startObserving();
    }

    private _getCurrentLocation(): ReadOnlyLocation {
        return {
            href: document.location.href,
            origin: document.location.origin,
            protocol: document.location.protocol,
            host: document.location.host,
            hostname: document.location.hostname,
            port: document.location.port,
            pathname: document.location.pathname,
            search: document.location.search,
            hash: document.location.hash
        };
    }

    private _startObserving() {
        const body = document.querySelector("body");
        if (!body) {
            return;
        }

        this._observer.observe(body, { childList: true, subtree: true });
    }

    private _onMutation = (mutations: MutationRecord[], observer: MutationObserver) => {
        mutations.forEach(() => {
            const hasChanges = this._listenToChangesOnFields.reduce((hasChanges, fieldName) => hasChanges || this._currentLocation[fieldName] !== window.location[fieldName], false);
            if (hasChanges) {
                const newLocation = this._getCurrentLocation();
                this._onLocationChange(newLocation, this._currentLocation);
                this._currentLocation = newLocation;
            }
        });
    }

    public get currentLocation() {
        return this._currentLocation;
    }

    public dispose() {
        this._observer.disconnect();
    }
}

export default LocationObserver;
