import React from "react";
import styled from "styled-components";
import { _ } from "js/vendor";
import { getSymbolCmdOrCtrl, getSymbolOptOrAlt } from "../core/utilities/utilities";
import isConnected from "js/core/utilities/isConnected";
import { ShowOfflineDialog } from "js/react/components/Dialogs/BaseDialog";
import { SearchBar } from "js/react/views/AddSlide/Panes/Components/SearchBox";

const Block = styled.div`
    background: #FFF;
    padding: 10px;
    margin-bottom: 10px;
`;

const ScrollBox = styled.div`
    height: calc(100% - 90px);
    overflow-y: auto;
    margin: 10px 0;
`;

const Button = styled.button`
    width: 100%;
    height: auto;
    color: #11a9e2;
    padding: 10px 16px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    border: none;
    cursor: pointer;
    background: #fff;
    text-transform: uppercase;
    font-weight: 600;

    &:hover {
        color: #333;
    }
`;

const Section = styled.div`
    padding-bottom: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
`;

const ShortcutItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
`;

const ShortcutItemLabel = styled.div`
    color: #777;    
`;

const ShortcutItemShortcut = styled.div`
    color: #11a9e2;
    margin-left: 20px;
`;

const ShortcutItemSeparator = styled.span`
    font-size: 12px;
    color: #aaa;
    margin: 0 4px;
`;

export class ShortcutContent extends React.Component {
    categories = {
        "Essentials": {
            // "Add Item": `${getSymbolCmdOrCtrl()} D`,
            // "Add Slide": `${getSymbolCmdOrCtrl()} 4`,
            "Copy": `${getSymbolCmdOrCtrl()} C`,
            "Cut": `${getSymbolCmdOrCtrl()} X`,
            "Paste": `${getSymbolCmdOrCtrl()} V`,
            "Undo": `${getSymbolCmdOrCtrl()} Z`,
            "Redo": `${getSymbolCmdOrCtrl()} Shift Z`,
        },
        "Text": {
            "Bold": `${getSymbolCmdOrCtrl()} B`,
            "Italicize": `${getSymbolCmdOrCtrl()} I`,
            "Add Link": `${getSymbolCmdOrCtrl()} K`,
        },
        "Navigation": {
            "Next Slide": (
                <>
                    <span>&#9654;</span>
                    <ShortcutItemSeparator>OR</ShortcutItemSeparator>
                    <span>&#9660;</span>
                </>
            ),
            "Prev Slide": (
                <>
                    <span>&#9664;</span>
                    <ShortcutItemSeparator>OR</ShortcutItemSeparator>
                    <span>&#9650;</span>
                </>
            ),
            "Close Present Mode": `Esc`,
            "Next Item": `Tab`,
            "Org View": `Space`,
        },
        "Classic Slides": {
            "Add Text": `T`,
            "Add Rectangle": `R`,
            "Add Oval/Circle": `O`,
            "Add Path": `P`,
            "Duplicate Element": `${getSymbolCmdOrCtrl()} D`,
            "Group Elements": `${getSymbolCmdOrCtrl()} G`,
            "Ungroup Elements": `${getSymbolCmdOrCtrl()} Shift G`,
            "Send To Back": `${getSymbolCmdOrCtrl()} ${getSymbolOptOrAlt()} [`,
            "Bring To Front": `${getSymbolCmdOrCtrl()} ${getSymbolOptOrAlt()} ]`,
        },
        // "Presentation Editor": {
        //     "Show Colors": `${getSymbolCmdOrCtrl()} 1`,
        //     "Show Layout": `${getSymbolCmdOrCtrl()} 2`,
        //     "Toggle Notes": `${getSymbolCmdOrCtrl()} 5`,
        // }
    };

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: "",
        };
    }

    getFilteredCategories() {
        const searchTerm = this.state.searchTerm.toLowerCase();
        const results = {};

        Object.entries(this.categories).forEach(([categoryName, entries]) => {
            const category = {};
            const matchCategoryName = categoryName.toLowerCase().includes(searchTerm);
            Object.entries(entries).forEach(([label, shortcut]) => {
                const match = (
                    matchCategoryName ||
                    label.includes && label.toLowerCase().includes(searchTerm) ||
                    shortcut.includes && shortcut.toLowerCase().includes(searchTerm)
                );
                if (match) category[label] = shortcut;
            });
            if (!_.isEmpty(category)) results[categoryName] = category;
        });

        return results;
    }

    handleSearch(value) {
        this.setState({
            searchTerm: value,
        });
    }

    render() {
        const categories = this.getFilteredCategories();

        return (<>
            <Block>
                <SearchBar
                    onSearch={value => this.handleSearch(value)}
                    style={{ padding: "10px 0" }}
                />
            </Block>

            <ScrollBox>
                {
                    Object.entries(categories).map(([categoryName, category]) => (
                        <Block key={categoryName}>
                            <Section>{categoryName}</Section>
                            {
                                Object.entries(category).map(([label, shortcut]) => (
                                    <ShortcutItem key={label}>
                                        <ShortcutItemLabel>{label}</ShortcutItemLabel>
                                        <ShortcutItemShortcut>{shortcut}</ShortcutItemShortcut>
                                    </ShortcutItem>
                                ))
                            }
                        </Block>
                    ))
                }
            </ScrollBox>

            <Button
                onClick={() => {
                    if (!isConnected.connected) {
                        ShowOfflineDialog();
                        return;
                    }
                    window.open("https://support.beautiful.ai/hc/en-us/articles/360000193552-Keyboard-Shortcuts", "_blank", "noopener");
                }}
            >See FAQ</Button>
        </>);
    }
}
