import styled from "styled-components";
import React, { Component } from "react";

export const LinearGradient = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(${props => props.angle ?? 0}deg, ${props => props.stops.map(stop => `${stop.color} ${stop.position}%`).join(", ")});
    filter: ${props => props.noise > 0 ? `url(#${props.id}-noiseFilter)` : "none"};
`;

export const RadialGradient = styled.div`
    width: 100%;
    height: 100%;
    background: radial-gradient(${props => props.stops.map(stop => `${stop.color} ${stop.position}%`).join(", ")});
    filter: ${props => props.noise > 0 ? `url(#${props.id}-noiseFilter)` : "none"};
`;

export const FourCornerGradient = styled.div`
    width: 100%;
    height: 100%;

    background: linear-gradient(90deg, ${props => props.stops[0].color}, ${props => props.stops[1].color});
    filter: ${props => props.noise > 0 ? `url(#${props.id}-noiseFilter)` : "none"};

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        mask-image: linear-gradient(to bottom, transparent, black);
        background: linear-gradient(90deg, ${props => props.stops[2].color}, ${props => props.stops[3].color});
    }
`;

const NoiseOverlay = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
`;

export class Gradient extends Component {
    render() {
        const { gradient } = this.props;

        if (!gradient) {
            return null;
        }

        return (
            <div style={{ width: "100%", height: "100%", position: "relative" }}>
                {gradient.noise > 0 && (
                    <NoiseOverlay>
                        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                            <filter id={`${gradient.id}-noiseFilter`}>
                                <feTurbulence
                                    type="fractalNoise"
                                    baseFrequency={0.5}
                                    numOctaves={1}
                                    stitchTiles="stitch" />
                                <feColorMatrix in="composite" values={`1 0 0 0 0
                                                                              0 1 0 0 0
                                                                              0 0 1 0 0
                                                                              0 0 0 ${gradient.noise} 0`} />
                                <feComposite operator="in" in="turbulence" in2="SourceAlpha" result="composite" />
                                {/*<feColorMatrix in="composite" type="luminanceToAlpha" />*/}
                                <feBlend in="SourceGraphic" in2="composite" mode="multiply" />
                            </filter>
                        </svg>
                    </NoiseOverlay>
                )}
                {gradient.type == "four-corner" && (
                    <FourCornerGradient {...gradient} />
                )}
                {gradient.type == "linear" && (
                    <LinearGradient {...gradient} />
                )}
                {gradient.type == "radial" && (
                    <RadialGradient {...gradient} />
                )}
            </div>
        );
    }
}

