import firebase from "firebase/compat/app";
import {
    initializeAuth,
    browserLocalPersistence,
    indexedDBLocalPersistence,
    browserSessionPersistence,
    signInWithCredential,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    getAdditionalUserInfo,
    unlink,
    sendPasswordResetEmail,
    updateProfile,
    sendEmailVerification,
    updateEmail,
    reauthenticateWithCredential,
    updatePassword
} from "firebase/auth";
import type {
    Auth,
    Persistence,
    Dependencies,
    UserCredential,
    AdditionalUserInfo,
    AuthCredential,
    User,
    ActionCodeSettings,
    UserProfile
} from "firebase/auth";

/**
 * Backwards compatible auth instance
 */
interface AuthCompat extends Auth {
    createUserWithEmailAndPassword(email: string, password: string): Promise<UserCredential>;
    signInWithCredential(credential: AuthCredential): Promise<UserCredential>;
    signInWithCustomToken(token: string): Promise<UserCredential>;
    signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential>;
    getAdditionalUserInfo(userCredential: UserCredential): AdditionalUserInfo | null;
    unlinkProvider(user: User, providerId: string): Promise<User>;
    sendPasswordResetEmail(email: string, actionCodeSettings?: ActionCodeSettings): Promise<void>;
    updateProfile(user: User, updates: Partial<UserProfile>): Promise<void>;
    sendEmailVerification(user: User, actionCodeSettings?: ActionCodeSettings): Promise<void>;
    updateEmail(user: User, newEmail: string): Promise<void>;
    reauthenticateWithCredential(user: User, credential: AuthCredential): Promise<UserCredential>;
    updatePassword(user: User, newPassword: string): Promise<void>;
}

declare global {
    interface Window {
        auth: (app?: firebase.app.App) => Auth;
    }
}

function getPersistence(): Persistence[] | null {
    let localStorage;
    try {
        localStorage = window.localStorage;
    } catch {
        // ignore
    }

    const persistence = localStorage?.getItem("firebase:auth:persistence");
    if (persistence === "session") {
        return [browserSessionPersistence];
    } else if (persistence === "local-indexeddb") {
        return [indexedDBLocalPersistence];
    } else if (persistence === "local-localstorage") {
        return [browserLocalPersistence];
    } else if (persistence === "local") {
        return [browserLocalPersistence, indexedDBLocalPersistence];
    } else if (persistence === "none") {
        return [];
    }

    return [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence];
}

const authInstances: { [key: string]: AuthCompat } = {};

/**
 * Enriches the auth instance with the missing methods to make it backwards
 * compatible with the legacy code.
 */
function getAuthCompat(auth: Auth): AuthCompat {
    const authCompat = auth as AuthCompat;
    authCompat.createUserWithEmailAndPassword = (email, password) => createUserWithEmailAndPassword(auth, email, password);
    authCompat.signInWithCredential = credential => signInWithCredential(auth, credential);
    authCompat.signInWithCustomToken = token => signInWithCustomToken(auth, token);
    authCompat.signInWithEmailAndPassword = (email, password) => signInWithEmailAndPassword(auth, email, password);
    authCompat.getAdditionalUserInfo = userCredential => getAdditionalUserInfo(userCredential);
    authCompat.unlinkProvider = (user, providerId) => unlink(user, providerId);
    authCompat.sendPasswordResetEmail = (email, actionCodeSettings) => sendPasswordResetEmail(auth, email, actionCodeSettings);
    authCompat.updateProfile = (user, updates) => updateProfile(user, updates);
    authCompat.sendEmailVerification = (user, actionCodeSettings) => sendEmailVerification(user, actionCodeSettings);
    authCompat.updateEmail = (user, newEmail) => updateEmail(user, newEmail);
    authCompat.reauthenticateWithCredential = (user, credential) => reauthenticateWithCredential(user, credential);
    authCompat.updatePassword = (user, newPassword) => updatePassword(user, newPassword);
    return authCompat;
}

/**
 * Returns the auth instance for the given app.
 */
export function auth(app: firebase.app.App = firebase.app()) {
    if (!authInstances[app.name]) {
        const deps: Dependencies = {};

        const persistence = getPersistence();
        if (persistence) {
            deps.persistence = persistence;
        }

        authInstances[app.name] = getAuthCompat(initializeAuth(app, deps));
    }

    return authInstances[app.name];
}

/**
 * Ensures user is signed into all initialized apps, uses credentials from
 * default app.
 */
export async function ensureSignedIntoAllInstances() {
    const defaultApp = firebase.app();

    const defaultInstanceAuth = auth(defaultApp);
    if (!defaultInstanceAuth.currentUser) {
        return;
    }

    for (const app of firebase.apps) {
        if (app === defaultApp) {
            continue;
        }

        await auth(app).updateCurrentUser(defaultInstanceAuth.currentUser);
    }
}

// Debug
window.auth = auth;
