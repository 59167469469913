import React from "react";
import { Icon, Tooltip } from "@material-ui/core";
import { _ } from "js/vendor";
import { FlexBox } from "js/react/components/LayoutGrid";
import { BlueSwitch } from "js/react/components/Switch";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";

export const ToggleAllowCommenting = props => {
    const {
        allowCommenting,
        onToggle,
    } = props;

    return (
        <FlexBox left middle>
            <WithLabel label="Allow commenting" toolTip="Viewers will be able to view and add comments to the presentation." gap={20}>
                <ToggleSwitch value={allowCommenting} onChange={onToggle} />
            </WithLabel>
        </FlexBox>
    );
};
