import React, { Component } from "reactn";
import { _ } from "js/vendor";
import Spinner from "js/react/components/Spinner";
import { ds } from "js/core/models/dataService";
import styled from "styled-components";
import { Icon } from "@material-ui/core";
import * as geom from "js/core/utilities/geom";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.ASSETS);

const CenterFrame = styled.div`
position: absolute;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
top: 0px;
left: 0px;
`;
const TopRightFrame = styled.div`
position: absolute;
top: 0;
right: 0;
cursor: pointer;
`;

const PlayButton = styled.div`
background: white;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
padding: 10px;
.MuiIcon-root {
  font-size: 34px;
  color: #555;
}
`;
const MuteButton = styled.div`
color: white;
padding: 10px;

.MuiIcon-root {
  font-size: 34px;
}
`;
const ErrorToken = styled.div`
  background: #111;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  .MuiIcon-root {
      font-size: 34px;
      color: #f11;
  }
`;

export class VideoPreview extends Component {
    constructor(props) {
        super(props);
        this.videoRef = new React.createRef();
        this.pausePending = false;
        this.state = {
            isPlaying: false,
            isLoading: false,
            hasError: false,
            videoProps: props.videoProps,
        };
    }

    get videoElement() {
        return this.videoRef?.current;
    }

    setStateSafe = diff => {
        if (this._isMounted) {
            this.setState(diff);
        }
    }

    componentDidMount = () => {
        this._isMounted = true;
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    downloadVideo = async () => {
        const {
            addAssetCallback,
            handleConfirm,
            muted,
            backgroundVideoOnly = false
        } = this.props;

        let { asset } = this.props;

        const {
            videoProps,
            videoProps: {
                duration,
            },
        } = this.state;

        const controls = !backgroundVideoOnly && duration > 30;

        if (!asset) {
            asset = await ds.assets.getOrCreateStockVideo(videoProps);
        }

        const model = {
            type: asset.type,
            assetName: asset.get("assetName"),
            tags: asset.get("tags"),
            url: asset.get("url"),
            id: asset.id,
            previewUrl: asset.get("previewUrl"),
            size: new geom.Size(asset.get("w") || asset.get("width"), asset.get("h") || asset.get("height")),
            assetProps: {
                controls,
                loop: !controls,
                autoPlay: !controls,
                duration,
                startTime: 0,
                endTime: duration,
                speed: 1,
                muted: true,
            },
        };

        addAssetCallback(model);
        handleConfirm();
    };

    onLoadedMetaData = () => {
        if (this.videoElement) {
            const {
                updateLayout,
            } = this.props;

            let {
                videoProps,
                videoProps: {
                    previewVideo,
                },
            } = this.state;

            if (previewVideo?.width !== this.videoElement.videoWidth) {
                videoProps = {
                    ...videoProps,
                    duration: this.videoElement.duration,
                    previewVideo: {
                        url: videoProps.url,
                        ...previewVideo,
                        width: this.videoElement.videoWidth,
                        height: this.videoElement.videoHeight,
                    },
                };
                this.setStateSafe({
                    videoProps,
                });
            }
            this.loadedMetaData = true;
            updateLayout && updateLayout();
            this.triggerPendingPlayback();
        }
    }

    onStalled = () => {
        if (this.videoElement) {
            this.videoElement.currentTime = 0;
        }
    }

    triggerPendingPlayback = async () => {
        if (this.playbackPending && !this.state.hasError) {
            try {
                this.setStateSafe({
                    isLoading: true,
                    isPlaying: true,
                });
                if (this.loadedMetaData) {
                    this.playbackPending = false;
                    await this.videoElement.play();
                    this.setStateSafe({ isLoading: false });
                    // this.pausePending && setTimeout(() => this.hidePlayback());
                }
            } catch (err) {
                this.setStateSafe({ isLoading: false });
            }
        }
    }

    queuePlayback = () => {
        // Ensure all other playbacks are hidden before showing this one.
        this.props.hideAllPlaybacks(this);
        if (this.videoElement && !this.state.hasError) {
            // Delay the playback for a bit to ensure that the user
            //   isn't just moving their mouse across the screen.
            this.playbackPending = true;
            setTimeout(this.triggerPendingPlayback, 500);
        }
    }

    hidePlayback = () => {
        this.playbackPending = false;
        const isPlaying = !!this.videoElement && !this.videoElement.paused;
        if (isPlaying) {
            this.videoElement.pause();
            this.videoElement.currentTime = 0.0;
            const src = this.videoElement.src;
            this.videoElement.src = "";
            this.videoElement.load();
            this.videoElement.src = src;
            this.videoElement.load();
            this.setStateSafe({
                isLoading: false,
                isPlaying: false,
            });
        }
    }

    toggleMuted = event => {
        event.stopPropagation();
        event.preventDefault();
        this.props.toggleMuted && this.props.toggleMuted();
    }

    render() {
        const {
            isPlaying,
            isLoading,
            hasError,
            videoProps,
        } = this.state;
        const {
            muted,
            width,
            showMenu,
        } = this.props;

        // Use the preview video if we have it
        const previewProps = videoProps.previewVideo || videoProps;

        const scale = width / (previewProps.width || 1);
        const height = (previewProps.height || 1) * scale;

        return (
            <div
                className="previously-used-asset-wrapper stock-photo-wrapper"
                style={{
                    width,
                    height,
                }}
                onClick={() => this.downloadVideo()}
                onMouseEnter={this.queuePlayback}
                onMouseLeave={this.hidePlayback}
            >
                <div
                    className="attribution"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtmlText(videoProps.metadata?.attribution)
                    }}
                    onClick={e => e.stopPropagation()}
                />

                <video
                    ref={this.videoRef}
                    src={previewProps.url}
                    poster={videoProps.previewUrl}
                    // preload="metadata"
                    loop
                    muted={muted}
                    width={width}
                    onLoadedMetadata={this.onLoadedMetaData}
                    onStalled={this.onStalled}
                    onError={event => {
                        this.setStateSafe({ hasError: true });
                        logger.error(this.videoElement?.error ?? {}, "[VideoPreview] video error", { src: previewProps?.url });
                    }}
                />
                {
                    !hasError &&
                    <>
                        {
                            isPlaying &&
                            <>
                                {
                                    isLoading &&
                                    <CenterFrame>
                                        <Spinner />
                                    </CenterFrame>
                                }
                                <TopRightFrame onClick={this.toggleMuted}>
                                    <MuteButton>
                                        <Icon>{muted ? "volume_off" : "volume_up"}</Icon>
                                    </MuteButton>
                                </TopRightFrame>
                            </>
                        }
                        {
                            !isPlaying &&
                            <CenterFrame>
                                <PlayButton>
                                    <Icon>play_arrow</Icon>
                                </PlayButton>
                            </CenterFrame>
                        }
                        {
                            showMenu &&
                            <div
                                className="menu-button"
                                onClick={showMenu}
                                style={{
                                    opacity: isPlaying ? 1 : 0,
                                    transition: "opacity 200ms"
                                }}
                            >
                                <i data-id="options-icon" className="micon menu">more_vert</i>
                            </div>
                        }
                    </>
                }
                {
                    hasError &&
                    <CenterFrame>
                        <ErrorToken>
                            <Icon>error_outline</Icon>
                        </ErrorToken>
                    </CenterFrame>
                }
            </div>
        );
    }
}
