import { getCallable } from "../_sdk/client";
import { WriteLogsRequest, WriteLogsResponse } from "./types";
import { endpoints, apiName } from "./endpoints";

const writeLogs = getCallable<WriteLogsRequest, WriteLogsResponse>(apiName, "writeLogs", {
    ...endpoints.writeLogs,
    composer: request => {
        return {
            url: "/clientLogs",
            options: {
                method: "POST",
                body: JSON.stringify(request)
            }
        };
    }
}, false);

export const callables = {
    writeLogs
};
