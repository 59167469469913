import firebase from "firebase/compat/app";

import getLogger, { LogGroup } from "js/core/logger";
import isConnected from "js/core/utilities/isConnected";

const logger = getLogger(LogGroup.DATA_SERVICE);

class TimeOffsetService {
    private _offsetMs: number = 0;

    constructor() {
        if (!isConnected.connected) {
            return;
        }

        firebase.database().ref(".info").child("serverTimeOffset").once("value")
            .then(snapshot => this._offsetMs = snapshot.val())
            .catch(err => logger.error(err, "[TimeOffsetService] couldn't get server time offset"));
    }

    public get offsetMs() {
        return this._offsetMs;
    }

    public now() {
        return Date.now() + this._offsetMs;
    }
}

export default new TimeOffsetService();
