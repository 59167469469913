import React, { Component } from "react";
import styled from "styled-components";
import { DialogContent, DialogActions, Button } from "@material-ui/core";
import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import { getStaticUrl } from "../../../config";

const FlexBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Title = styled.div`
    color: #222222;
    font-family: "Source Sans Pro";
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.26px;
`;

const Message = styled.div`
    margin-top: 15px;
    color: #999999;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.5px;
    text-align: center;
`;

interface Props {
    title: string;
    message: string;
    submessage?: React.ReactNode;
    firstButtonLabel?: string;
    secondButtonLabel?: string;
    onFirstButtonClick?: () => void;
    onSecondButtonClick?: () => void;
    closeDialog?: () => void;
    onSubMessageClick?: () => void;
}

const StyledButton = styled(Button)`
 &&& {
    position: "absolute";
     left: "26px"; 
     bottom: "16px";
 }`;

const StyledImage = styled.img`
    margin-top: 15px;
    margin-bottom: 15px;
 `;

const StyledDialogActions = styled(DialogActions)`
    margin-top: 40px;
 `;

export default class VersionConflictDialog extends Component<Props> {
    render() {
        const {
            title,
            message,
            submessage,
            firstButtonLabel,
            secondButtonLabel,
            onFirstButtonClick,
            onSecondButtonClick,
            onSubMessageClick,
            closeDialog
        } = this.props;

        return (
            <BeautifulDialog maxWidth="md">
                <DialogContent>
                    <FlexBox>
                        <StyledImage
                            src={getStaticUrl(`/images/bai-bot/bai-bot-neutral.svg`)}
                            width={60}
                            height={60}
                        />
                        <Title>{title}</Title>
                        {message && <Message>{message}</Message>}
                        {submessage && (
                            <Message>
                                {React.isValidElement(submessage)
                                    ? React.cloneElement(submessage as React.ReactElement, {
                                        children: React.Children.map((submessage as React.ReactElement).props.children, child => {
                                            if (React.isValidElement(child) && child.type === "a" && (child as React.ReactElement).props.children === "team Owner") {
                                                return React.cloneElement(child as React.ReactElement<any>, {
                                                    onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                                        e.preventDefault();
                                                        onSubMessageClick && onSubMessageClick();
                                                        closeDialog && closeDialog();
                                                    }
                                                });
                                            }
                                            return child;
                                        })
                                    })
                                    : submessage
                                }
                            </Message>
                        )}
                    </FlexBox>
                </DialogContent>
                <StyledDialogActions>
                    {onFirstButtonClick && (
                        <StyledButton
                            onClick={() => {
                                onFirstButtonClick();
                                closeDialog && closeDialog();
                            }}
                            color="primary"
                        >
                            {firstButtonLabel}
                        </StyledButton>
                    )}
                    {onSecondButtonClick && (
                        <StyledButton
                            onClick={() => {
                                onSecondButtonClick();
                                closeDialog && closeDialog();
                            }}
                            color="primary"
                        >
                            {secondButtonLabel}
                        </StyledButton>
                    )}
                </StyledDialogActions>
            </BeautifulDialog>
        );
    }
}
