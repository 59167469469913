const theme = {
    isBuiltIn: true,
    id: "museum",
    name: "Museum",
    showLogo: true,
    logoScale: 1,
    logoPosition: "left",
    logoOffset: 0,
    showMessage: true,
    showPageNum: true,
    logo: null,
    logo_dark: null,
    showFooterByDefault: null,
    palette_name: "museum",
    defaultSlideColor: "theme",
    defaultBackgroundColor: "background_light",
    styleFonts: 1,
    styleFontWeight: "heavy",
    styleElementStyle: "outlined",
    styleEffect: "none",
    styleDesign: 1,
    styleDecoration: "none_left",
    styleHeaderAlignment: "left",
    styleShape: "none",
    styleColor: "slide",
    styleBackground: "none",
    styleTitleFont: "sourcesanspro",
    styleBodyFont: "sourcesanspro",
    styleWeight: "light",
    styleTitleSlide: "bar_left",
    fontScale: 1,
    iconStyle: "chunky",
    cjkFont: "jp",
    fontHeaderFontId: "roboto",
    fontHeaderWeight: 700,
    fontHeaderLetterSpacing: 0,
    fontHeaderLineHeight: 1.5,
    fontHeaderScaling: 80,
    fontHeaderTextTransform: "auto",
    fontTitleFontId: "lato",
    fontTitleWeight: 400,
    fontTitleLetterSpacing: 0.02,
    fontTitleLineHeight: 1.9,
    fontTitleScaling: 85,
    fontTitleTextTransform: "auto",
    fontBodyFontId: "lato",
    fontBodyWeight: 400,
    fontBodyLetterSpacing: 0.02,
    fontBodyLineHeight: 2,
    fontBodyScaling: 85,
    fontBodyTextTransform: "auto",
};
module.exports = theme;
