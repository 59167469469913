/**
 * Removes trailing nulls from arrays in the supplied object
 */
function removeTrailingNullsFromArrays(object) {
    if (!object) {
        return object;
    }

    if (Array.isArray(object)) {
        while (object.length > 0 && object[object.length - 1] === null) {
            object.pop();
        }
    }

    if (typeof object === "object") {
        Object.values(object).forEach(removeTrailingNullsFromArrays);
    }

    return object;
}

module.exports = removeTrailingNullsFromArrays;
