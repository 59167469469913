import React, { Component } from "react";
import styled from "styled-components";

import { getStaticUrl } from "js/config";
import { BlueButton } from "js/react/components/UiComponents";
import { Gap30 } from "js/react/components/Gap";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { downloadFileFromBynder } from "js/core/utilities/downloadFile";
import { FileType } from "js/core/utilities/fileSourcesAndTypes";
import BynderFilePickerDialog from "js/react/components/Dialogs/BynderFilePickerDialog";

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #A9A9A9;
    padding: 30px 50px;
    margin-bottom: 30px;
`;

interface Props {
    fileTypes: FileType[],
    handleImportedFile: (file: File) => void,
}

export default class BynderPicker extends Component<Props> {
    openPicker = () => {
        ShowDialog(BynderFilePickerDialog, {
            callback: async (assets, additionalInfo) => {
                const file = await downloadFileFromBynder({
                    url: assets[0].__typename === "Video" ? assets[0].previewUrls[0] : additionalInfo.selectedFile.url,
                    name: (assets as unknown)?.[0]?.name ?? ""
                });
                this.props.handleImportedFile(file);
            },
        });
    }

    render() {
        return (
            <Container>
                <img src={getStaticUrl("/images/file_hosting/bynder-icon.svg")} width={50} height={50} />
                <Gap30 />
                <BlueButton /** @ts-ignore */
                    onClick={this.openPicker}
                >
                    Select File from Bynder
                </BlueButton>
            </Container>
        );
    }
}
