import React from "react";
import { GlobalStateController } from "bai-react-global-state";
import { _ } from "js/vendor";
import { app } from "js/namespaces";
import { debug as debugApi } from "apis/callables";

const initialState = {
    referenceScreenshots: [],
    fetching: false,
    debugButtonVisible: false
};

export class DebugController extends GlobalStateController {
    constructor(initialState) {
        super(initialState);
    }

    ////////////////////////////////
    /// Reference Screenshots
    ////////////////////////////////

    get referenceScreenshots() {
        return this._state.referenceScreenshots;
    }

    get debugButtonVisible() {
        return this._state.debugButtonVisible;
    }

    showDebugButton() {
        this._updateState({ debugButtonVisible: true });
    }

    hideDebugButton() {
        this._updateState({ debugButtonVisible: false });
    }

    referenceScreenshotsFetching() {
        return this._state.fetching;
    }

    referenceScreenshotsExpired() {
        return this._state.referenceScreenshots.some(s => s.expires < Date.now());
    }

    async fetchReferenceScreenshots(id) {
        const isEmpty = _.isEmpty(this._state.referenceScreenshots.filter(s=> s.slide == id));
        if (!this._state.fetching && (isEmpty || this.referenceScreenshotsExpired())) {
            await this._updateState({ fetching: true });
            let screenshots = await debugApi.getReferenceScreenshots({ id });
            return await this._updateState(({ referenceScreenshots, ...state })=>{
                return {
                    ...state,
                    fetching: false,
                    referenceScreenshots: screenshots
                };
            });
        }
    }
}

const debugController = new DebugController(_.cloneDeep(initialState));
export default debugController;

app.debugController = debugController;
