import Api from "js/core/api";
import { loadImage } from "js/core/utilities/promiseHelper";
import { TaskState } from "common/constants";
import getLogger, { LogGroup } from "js/core/logger";
import ApiError from "common/ApiError";
import pusher from "js/core/services/pusher";

const logger = getLogger(LogGroup.THUMBNAIL);

/**
 * Fetches a signed url and loads the image from the url for the given slide
 * Note: timestamp is a parameter used only for the cache management purposes
 */
export async function getSignedUrlAndLoad(slideId, timestamp, presentationOrLinkId = null, suffix = null, slideStageIndex = 0, reloadCache = false) {
    const handleUrl = async url => {
        try {
            await loadImage(url);
        } catch (err) {
            if (reloadCache) {
                throw new ApiError("Thumbnail not found", 404);
            }
            logger.info(`getSignedUrlAndLoad() failed to load thumbnail for ${slideId} slide, fetching a new url without cache and trying again`, { slideId, presentationOrLinkId, suffix, slideStageIndex, timestamp });
            return getSignedUrlAndLoad(slideId, timestamp, presentationOrLinkId, suffix, slideStageIndex, true);
        }
        return url;
    };

    const { url, channelId } = await Api.thumbnails.get({ slideId, slideStageIndex, timestamp, suffix, presentationOrLinkId }, reloadCache);
    if (url) {
        return handleUrl(url);
    }

    const event = await pusher.waitForEvent(channelId, TaskState.FINISHED, TaskState.ERROR, 2 * 60 * 1000);
    return handleUrl(event.url);
}

export default {
    getSignedUrlAndLoad
};
