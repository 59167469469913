import { app } from "js/namespaces";
import ReferenceCollection from "js/core/storage/referenceCollection";
import { Theme } from "legacy-js/core/models/theme";

const SharedTheme = Theme.extend({
    root: "sharedThemes",
});

const SharedThemes = ReferenceCollection.extend({
    model: SharedTheme,
    referenceRoot: "users",
    getReferenceId: function() {
        return `${this.userId}/sharedThemes`;
    },

    initialize() {
        this.type = "SharedThemes";
        this.userId = app.user.id;
    },

    getThemesInWorkspace(orgId) {
        return this.filter(theme => {
            const inOrg = theme.get("orgId") == orgId;
            const isHidden = theme.get("hidden");
            return inOrg && !isHidden;
        });
    },

    saveSharedTheme: function(theme, name, id) {
        let existingTheme;
        if (id) {
            existingTheme = this.find({ id: id });
        }

        if (existingTheme) {
            const updates = { ...theme.attributes, isDefault: false, isBuiltIn: false, name };
            existingTheme.replace(updates);
            return existingTheme;
        }
    }
});

export { SharedThemes, SharedTheme };
