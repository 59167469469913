import React, { Component, Fragment } from "reactn";
import { DialogTitle, DialogActions } from "@material-ui/core";
import { Button } from "@material-ui/core";
import TextArea from "js/react/components/TextArea";
import CheckBox from "js/react/components/CheckBox";
import { BeautifulDialog, DialogContent, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { Gap10 } from "js/react/components/Gap";
import { UIController } from "js/editor/dialogs/UIController";
import Api from "js/core/api";

export default class FeedbackDialog extends Component {
    state = {
        message: "",
        copySlide: false,
    };

    handleChange = value => {
        this.setState({ message: value });
    }

    handleAccept = () => {
        const {
            message,
            copySlide,
        } = this.state;

        const orgId = UIController.getOrganizationId();
        const payload = {
            userId: app.user.id,
            email: app.user.getEmail() || "noemail:" + app.user.id,
            message,
            plan: orgId ? "team" : app.user.analyticsPersonalPlan,
            slideId: copySlide ? ds.selection.slide.id : null,
            userAgent: window.navigator.userAgent,
            platform: window.navigator.platform,
            resolution: `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}x${window.devicePixelRatio}`
        };

        if (orgId) {
            const defaultTeam = ds.teams.defaultTeamForOrg(orgId);
            payload.teamId = defaultTeam.id;
            payload.userRole = defaultTeam.getUserRole();
        }

        Api.reportBug.post(payload).then(() => {
            ShowWarningDialog({
                title: "Feedback submitted",
                message: "Thanks!",
            });
        }, error => {
            ShowWarningDialog({
                title: "Unable to submit feedback",
                message: error.responseText,
            });
        });

        this.props.closeDialog();
    }

    render() {
        const {
            message,
            copySlide,
        } = this.state;

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>We want to hear from you!</DialogTitle>
                <DialogContent>
                    <p>Have an idea for new functionality? Thoughts on how we can improve the product? Your feedback directly informs our product development—let us know!</p>
                    <Gap10 />
                    <TextArea
                        isVisible
                        autoFocus={true}
                        text={message}
                        fullWidth={true}
                        onChange={this.handleChange}
                        placeholder="Your feedback..."
                    />
                    {
                        ds.selection.slide &&
                        <Fragment>
                            <Gap10 />
                            <CheckBox
                                checked={copySlide}
                                value="copySlide"
                                label="Attach a copy of current slide"
                                className="control"
                                onChange={value => this.setState({ copySlide: value })}
                            />
                        </Fragment>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.closeDialog()}>Cancel</Button>
                    <Button onClick={() => this.handleAccept()} className="text-blue">Send</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
