export function stopPropagation(handler = () => { }, stopNativeEvent = true) {
    return event => {
        if (!event) {
            handler();
            return;
        }
        event.stopPropagation();
        if (stopNativeEvent) {
            event.nativeEvent?.stopImmediatePropagation();
        }
        handler(event);
    };
}
