import React, { Component } from "react";
import { dialogTheme } from "js/react/materialThemeOverrides";
import styled, { keyframes } from "styled-components";
import { ThemeProvider } from "@material-ui/core/styles";
import { _, $ } from "js/vendor";
import { Key } from "js/core/utilities/keys";

const flyIn = keyframes`
  from {
    left: -100px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
}
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  animation: ${flyIn} 500ms;
  display: flex;
`;

export class AnimatedFullScreenContainer extends Component {
    componentDidMount() {
        $(window).on("keydown.fullScreenContainer", event => {
            if (event.which == Key.ESCAPE) {
                this.props.onEscapeKey && this.props.onEscapeKey();
            }
        });
    }

    componentWillUnmount() {
        $(window).off(".fullScreenContainer");
    }

    render() {
        return (
            <ThemeProvider theme={dialogTheme}>
                <Container className={this.props.className}>{this.props.children}</Container>
            </ThemeProvider>
        );
    }
}

