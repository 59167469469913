import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import Api from "js/core/api";
import { $, _ } from "js/vendor";
import perf from "js/core/utilities/perf";
import { getGlobal } from "reactn";
import getLocalStorage from "js/core/utilities/localStorage";
import getLogger from "js/core/logger";
import { getDebugAnalyticsState } from "js/analytics";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { CustomStyleSheetDialog } from "js/editor/ThemeEditor/CustomStyleSheetDialog";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import AppController from "./AppController";
import DebugController from "./DebugController";

const localStorage = getLocalStorage();

class Debugger {
    get analytics() {
        return getDebugAnalyticsState();
    }

    get app() {
        return app.appController;
    }

    get debugController() {
        return DebugController;
    }

    get global() {
        // eslint-disable-next-line no-console
        console.log(getGlobal());
    }

    get perf() {
        return perf;
    }

    get slide() {
        return PresentationEditorController.currentSlide;
    }

    get element() {
        return app.currentCanvas.layouter.elements.primary.model;
    }

    get tray() {
        return app.currentCanvas.layouter.elements.tray.model;
    }

    get header() {
        return app.currentCanvas.layouter.elements.header.model;
    }

    get elements() {
        _.each(app.currentCanvas.layouter.elements, element => {
            // eslint-disable-next-line no-console
            console.log(element.elements);
        });
    }

    get selection() {
        return PresentationEditorController.selectionLayerController.selectedElements;
    }

    get layouter() {
        return app.currentCanvas.layouter;
    }

    get presentation() {
        return PresentationEditorController.presentation;
    }

    get model() {
        return app.currentCanvas.model;
    }

    get theme() {
        return app.currentTheme.attribute;
    }

    get layout() {
        // eslint-disable-next-line no-console
        console.log(app.currentCanvas.layouter.layout);
    }

    get canvas() {
        return app.currentCanvas;
    }

    get selectionLayer() {
        return PresentationEditorController.selectionLayerController;
    }

    get logger() {
        return getLogger();
    }

    get tree() {
        let logNodes = (element, indent) => {
            indent += 1;

            let log = "";
            for (let i = 0; i < indent; i++) {
                log += "----";
            }
            // eslint-disable-next-line no-console
            console.log(log + " " + element.toString());

            _.each(element.elements, element => logNodes(element, indent));
            indent -= 1;
        };

        _.each(app.currentCanvas.layouter.elements, element => {
            logNodes(element, 0);
            // eslint-disable-next-line no-console
            console.log("");
        });
    }

    get Api() {
        return Api;
    }

    showLayout() {
        this.eIndex = 0;
        this.showLayoutDebugBox = true;
        app.currentCanvas.refreshCanvasAutoRevert();
    }

    hideLayout() {
        this.showLayoutDebugBox = false;
        app.currentCanvas.refreshCanvasAutoRevert();
    }

    resetFirstTime() {
        app.user.update({ userInfo: null });
        app.user.save();
        window.location.reload();
    }

    get ds() {
        return ds;
    }

    // forceCanvasScale(scale = 1) {
    //     app.mainView.editorView.forceCanvasScale = scale;
    //     app.mainView.editorView.resize(true);
    // }

    forceBrowserHeight(height) {
        if (height) {
            localStorage.setItem("forceBrowserHeight", height);
            $("#mainView").height(height);
        } else {
            localStorage.removeItem("forceBrowserHeight");
        }
    }

    resetUserInfo() {
        app.user.update({ userInfo: null });
        app.user.save();
        // eslint-disable-next-line no-console
        console.log("User info reset");
    }

    save() {
        app.currentCanvas.updateCanvasModel();
    }

    printTheme() {
        let str = "";
        for (let key in app.currentTheme.attributes) {
            let val = app.currentTheme.attributes[key];
            if (typeof (val) == "string") {
                val = `"` + val + `"`;
            }
            if (typeof (val) == "object") {
                val = JSON.stringify(val);
            }
            str += key + ":" + val + ",\n";
        }
        // eslint-disable-next-line no-console
        console.log(str);
    }

    customStyles() {
        ShowDialog(CustomStyleSheetDialog, { theme: app.currentTheme });
    }

    get info() {
        return {
            userId: app.user?.id,
            workspaceId: app.appController?.workspaceId,
            timestamp: Date.now(),
            sessionId: this.logger._transports[1]._context.sessionId
        };
    }
}

const debug = new Debugger();
export default debug;

window.debug = debug;
