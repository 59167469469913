import * as React from "react";
import deepEqual from "fast-deep-equal";
import * as PropTypes from "prop-types";

import { _ } from "js/vendor";
import { getSelectionState, setSelection } from "js/core/utilities/htmlTextHelpers";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";

function normalizeHtml(str: any): string {
    if (typeof str !== "string") {
        str = _.toString(str);
    }
    return str.replace(/&nbsp;|\u202F|\u00A0/g, " ");
}

/**
 * A simple component for an html element with editable contents.
 */
export default class ContentEditable extends React.Component<Props> {
    lastHtml: any = this.props.html;
    el: any = typeof this.props.innerRef === "function" ? { current: null } : React.createRef<HTMLElement>();
    selectionState: any = null;

    getEl = () => (this.props.innerRef && typeof this.props.innerRef !== "function" ? this.props.innerRef : this.el).current;

    render() {
        const { tagName, html, innerRef, disableSelectionHandling, ...domProps } = this.props;

        return React.createElement(
            tagName || "div",
            {
                ...domProps,
                ref: typeof innerRef === "function" ? (current: HTMLElement) => {
                    innerRef(current);
                    this.el.current = current;
                } : innerRef || this.el,
                onInput: this.emitChange,
                onBlur: this.props.onBlur || this.emitChange,
                onKeyUp: this.props.onKeyUp || this.emitChange,
                onKeyDown: this.props.onKeyDown || this.emitChange,
                contentEditable: !this.props.disabled,
                dangerouslySetInnerHTML: { __html: sanitizeHtmlText(html) }
            }
        );
    }

    saveSelectionState() {
        const { disableSelectionHandling } = this.props;
        if (disableSelectionHandling) {
            // Selection handling is disabled, so we don't want to save the selection state
            return;
        }

        const el = this.getEl();
        if (!el || document.activeElement !== el) {
            // Element is missing or inactive, nothing to save
            return;
        }

        const selectionState = getSelectionState(el);
        if (selectionState.start != null && selectionState.end != null) {
            this.selectionState = selectionState;
        }
    }

    shouldComponentUpdate(nextProps: Props): boolean {
        const el = this.getEl();

        // We need not rerender if the change of props simply reflects the user"s edits.
        // Rerendering in this case would make the cursor/caret jump

        // Rerender if there is no element yet... (somehow?)
        if (!el) {
            return true;
        }

        // ...or if html really changed... (programmatically, not by user edit)
        if (normalizeHtml(nextProps.html) !== normalizeHtml(el.innerHTML)) {
            // Save selection state before update so we can restore it later
            this.saveSelectionState();
            return true;
        }

        // ...or if style changed
        if (!deepEqual(this.props.style, nextProps.style)) {
            // Save selection state before update so we can restore it later
            this.saveSelectionState();
            return true;
        }

        // ...or any other render-significant prop changed
        return this.props.disabled !== nextProps.disabled ||
            this.props.tagName !== nextProps.tagName ||
            this.props.className !== nextProps.className ||
            this.props.innerRef !== nextProps.innerRef ||
            this.props.placeholder !== nextProps.placeholder ||
            this.props.spellCheck !== nextProps.spellCheck;
    }

    componentDidUpdate() {
        const el = this.getEl();
        if (!el) {
            // Element is missing, nothing to do
            return;
        }

        if (normalizeHtml(this.props.html) !== normalizeHtml(el.innerHTML)) {
            // This can happen if this.props.html is empty so dangerouslySetInnerHTML doesn't
            // get set correctly on the VDOM element (probably a React bug?)
            el.innerHTML = sanitizeHtmlText(this.props.html, { font: true });
            this.lastHtml = sanitizeHtmlText(this.props.html, { font: true });
        }

        if (this.selectionState) {
            setSelection(this.selectionState, el);
            this.selectionState = null;
        }
    }

    emitChange = (originalEvt: React.SyntheticEvent<any>) => {
        const { onChange } = this.props;

        const el = this.getEl();
        if (!el) {
            // Element is missing, nothing to do
            return;
        }

        const html = el.innerHTML;
        if (onChange && html !== this.lastHtml) {
            // Clone event with Object.assign to avoid
            // "Cannot assign to read only property "target" of object"
            const evt = Object.assign({}, originalEvt, {
                target: {
                    value: html
                }
            });
            onChange(evt);
        }
        this.lastHtml = html;
    }

    static propTypes = {
        html: PropTypes.any.isRequired,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        tagName: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        innerRef: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.func,
        ]),
        disableSelectionHandling: PropTypes.bool,
        spellCheck: PropTypes.bool
    }
}

export type ContentEditableEvent = React.SyntheticEvent<any, Event> & { target: { value: string } };
type Modify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R;
type DivProps = Modify<JSX.IntrinsicElements["div"], { onChange: ((event: ContentEditableEvent) => void) }>;

export interface Props extends DivProps {
    html: any,
    disabled?: boolean,
    tagName?: string,
    className?: string,
    style?: Object,
    innerRef?: React.RefObject<HTMLElement> | Function,
    disableSelectionHandling: boolean,
    spellCheck: boolean
}
