import { TeamMemberRole } from "./ITeam";

export enum TeamMemberAction {
    JOINED = "joined",
    ROLE_CHANGED = "role_changed",
    LICENSE_UPGRADED = "license_upgraded",
    LICENSE_DOWNGRADED = "license_downgraded",
    LEFT = "left",
}

export interface ITeamMembershipHistory {
    id: string
    teamId: string
    memberId: string
    action: TeamMemberAction
    role: TeamMemberRole
    createdAt: number
}
