import React from "react";
import styled from "styled-components";

import { FlexBox } from "js/react/components/LayoutGrid";
import { Gap10 } from "js/react/components/Gap";
import { app } from "js/namespaces";

const StyleOption = styled.div`
  display: flex;
  align-items: center;

  label {
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 7px;
    font-size: 10px !important;
    color: #666;
    margin-right: 10px;
  }
`;

export function StylePresetOption(props) {
    let color;
    if (props.color == "auto" || props.color == null) {
        color = app.currentTheme.palette.getColor(app.currentCanvas.getSlideColor());
    } else {
        color = app.currentTheme.palette.getColor(props.color);
    }

    let isLight = color._r > 245 && color._g > 245 && color._b > 245;

    if (isLight) {
        color = "#ccc";
    } else {
        color = color.toRgbString();
    }

    switch (props.type) {
        case "outlined":
            return (
                <StyleOption onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="22" height="22" stroke={color} strokeWidth="2"/>
                    </svg>
                    <label>{props.label}</label>
                </StyleOption>
            );
        case "filled":
            return (
                <StyleOption onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" fill={color}/>
                    </svg>
                    <label>{props.label}</label>
                </StyleOption>
            );
        case "muted":
            return (
                <StyleOption onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" fill={color} fillOpacity="0.35"/>
                    </svg>
                    <label>{props.label}</label>
                </StyleOption>
            );
        case "fillAndStroke":
            return (
                <StyleOption onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="22" height="22" stroke={color} fill={color}
                            fillOpacity="0.35" strokeWidth="2"/>
                    </svg>
                    <label>{props.label}</label>
                </StyleOption>
            );
    }
}

const DecorationStyles = ({ selectedColor, handleChangePresetOption, style = {}, showLabel = true, allowGap = true, showMutedDecorationStyles = true }) => {
    return (<FlexBox left style={style}>
        {showLabel && <label>Shape Style</label>}
        <StylePresetOption type="outlined" color={selectedColor} label="Outlined"
            onClick={() => handleChangePresetOption("outlined")} />
        {allowGap && <Gap10/>}
        {showMutedDecorationStyles && <StylePresetOption type="muted" color={selectedColor} label="Muted"
            onClick={() => handleChangePresetOption("muted")} />}
        {allowGap && <Gap10/>}
        <StylePresetOption type="filled" color={selectedColor} label="Filled"
            onClick={() => handleChangePresetOption("filled")} />
        {allowGap && <Gap10/>}
        {showMutedDecorationStyles && <StylePresetOption type="fillAndStroke" color={selectedColor} label="Fill & Stroke"
            onClick={() => handleChangePresetOption("fillAndStroke")} />}
    </FlexBox>
    );
};

export default DecorationStyles;

