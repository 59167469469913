export const isChrome = navigator.userAgent.indexOf("Chrome") != -1;
export const isSafari = !isChrome && navigator.userAgent.indexOf("Safari") != -1;
export const isFirefox = navigator.userAgent.indexOf("Firefox") != -1;

export function getChromeVersion() {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    return raw ? parseInt(raw[2], 10) : false;
}

export function getSafariVersion() {
    if (isSafari) {
        return parseInt(navigator.userAgent.match(/Version\/([0-9]+)\./)[1]);
    } else {
        return false;
    }
}

function createRange(node, count, range) {
    if (!range) {
        range = document.createRange();
        range.selectNode(node);
        range.setStart(node, 0);
    }

    if (count === 0) {
        range.setEnd(node, count);
    } else if (node && count > 0) {
        if (node.nodeType === Node.TEXT_NODE) {
            if (node.textContent.length < count) {
                count -= node.textContent.length;
            } else {
                range.setEnd(node, count);
                count = 0;
            }
        } else {
            for (let i = 0; i < node.childNodes.length; i++) {
                range = createRange(node.childNodes[i], count, range);

                if (count === 0) {
                    break;
                }
            }
        }
    }

    return range;
}

export function setCaretPosition(node = document.activeElement, index = 0) {
    if (index <= 0) {
        return;
    }

    const selection = window.getSelection();
    const range = createRange(node.parentNode, index);
    if (range) {
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
    }
}

export function getCaretPosition(node = document.activeElement) {
    let position = 0;
    let selection;
    let range;

    if (window.getSelection) {
        selection = window.getSelection();
        if (selection.rangeCount) {
            range = selection.getRangeAt(0);
            if (range.commonAncestorContainer.parentNode == node) {
                position = range.endOffset;
            }
        }
    } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();

        if (range.parentElement() === node) {
            const el = document.createElement("span");
            node.insertBefore(el, node.firstChild);

            const temp = range.duplicate();
            temp.moveToElementText(el);
            temp.setEndPoint("EndToEnd", range);
            position = temp.text.length;
        }
    }

    return position;
}
