import React, { Component } from "reactn";

import getLogger from "js/core/logger";
import { THUMBNAIL_SIZES } from "common/constants";
import Thumbnails from "js/core/models/thumbnails";
import { ds } from "js/core/models/dataService";
import { UIPane } from "js/react/components/UiComponents";
import Spinner from "js/react/components/Spinner";
import { _ } from "js/vendor";
import { SearchThrottleWait } from "common/constants";
import { SlideSearchPreview } from "js/react/views/AddSlide/Panes/Components/SlideSearchPreview";
import { gallerySlidesDataService } from "js/react/views/AddSlide/DataServices";

import {
    SearchBarContainer,
    SearchBarInnerContainer, UIPaneResultsContainer,
    InnerWhiteFrameContainer,
    SlideSearchInput
} from "./Components/SearchBox";
import { Thumbnail, ThumbnailContainer, ThumbnailGrid } from "./Components/ThumbnailGrid";
import { NoMatchTemplatesNotice } from "js/react/components/Notice";

const logger = getLogger();

export class SlideGalleryPane extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            query: this.global.searchSlidesQuery ?? "",
            searchResults: [],
        };

        this.fetchSearchResultsThrottled = _.throttle(this.fetchSearchResults, SearchThrottleWait, { leading: false });
    }

    componentDidMount() {
        this._isMounted = true;
        const { query } = this.state;
        // Pull default result for initial query
        this.fetchSearchResults(query);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchSearchResults = async (query = null) => {
        const searchResults = await gallerySlidesDataService.search({ fullTextSearchQuery: query, size: 500 });
        if (!this._isMounted) {
            return;
        }
        this.setState({
            searchResults,
            isLoaded: true
        });
    }

    handleSearch = query => {
        this.setState(
            {
                query,
                isLoaded: false
            },
            () => this.fetchSearchResultsThrottled(query)
        );
    }

    render() {
        const { query } = this.state;
        return (
            <UIPane>
                <SearchBarContainer>
                    <SearchBarInnerContainer>
                        <SlideSearchInput
                            query={query}
                            onSearch={this.handleSearch}
                            placeholder="Search for inspiration..."
                            showShortCuts
                        />
                    </SearchBarInnerContainer>
                </SearchBarContainer>
                <UIPaneResultsContainer>
                    {this.renderSlides()}
                </UIPaneResultsContainer>
            </UIPane>
        );
    }

    renderSlides() {
        const { onSelected, onAddBlankClassicSlide } = this.props;
        const { isLoaded, searchResults, query } = this.state;

        if (!isLoaded) {
            return <Spinner />;
        }

        if (!query) {
            return (
                <InnerWhiteFrameContainer>
                    <ThumbnailGrid
                        columns={3}
                        colGap={30}
                        rowGap={20}
                        items={searchResults}
                        thumbnailClass={SlideGalleryPreviewThumbnail}
                        onItemSelected={onSelected}
                    />
                </InnerWhiteFrameContainer>
            );
        }

        if (searchResults.length === 0) {
            return (
                <NoMatchTemplatesNotice
                    onAddClassicSlide={onAddBlankClassicSlide}
                />
            );
        }

        const strongMatches = searchResults.filter(result => result.score > 1);
        const weakMatches = searchResults.filter(result => result.score <= 1);

        return (<>
            {strongMatches.length > 0 &&
                <SlideSearchPreview
                    expanded={true}
                    allowCollapse={false}
                    title=""
                    thumbnail={SlideGalleryPreviewThumbnail}
                    items={strongMatches}
                    onSelected={onSelected}
                />
            }
            {weakMatches.length > 0 &&
                <SlideSearchPreview
                    expanded={true}
                    allowCollapse={false}
                    title={
                        strongMatches.length > 0
                            ? "Similar Results"
                            : `We couldn't find an exact match for "${query}", but these match similar terms.`
                    }
                    thumbnail={SlideGalleryPreviewThumbnail}
                    items={weakMatches}
                    onSelected={onSelected}
                />
            }
        </>);
    }
}

export class SlideGalleryPreviewThumbnail extends Component {
    constructor() {
        super();

        this.state = {
            url: "",
            isVisible: false
        };

        this.ref = React.createRef();
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting === true) {
                this.observer.disconnect();
                this.setState({ isVisible: true });
            }
        });

        this.observer.observe(this.ref.current);
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { showThemeThumbnails, item } = this.props;
        const { isVisible } = this.state;

        if (!isVisible) {
            return;
        }

        if (prevProps.showThemeThumbnails !== showThemeThumbnails || prevState.isVisible !== isVisible) {
            this.loadThumbnail()
                .catch(err => logger.error(err, "[SlideGalleryPane] this.loadThumbnail() failed", { id: item?.id }));
        }
    }

    async loadThumbnail() {
        const { item, highQuality, showThemeThumbnails } = this.props;

        this.setState({ url: "" });

        let url;
        if (showThemeThumbnails && ds.selection.presentation && !ds.selection.presentation.get("isDummy")) {
            const presentationId = ds.selection.presentation.id;
            const firstSlideModifiedAt = ds.selection.presentation.get("firstSlideModifiedAt");
            url = await Thumbnails.getSignedUrlAndLoad(item.id, Math.max(item.modifiedAt, firstSlideModifiedAt), presentationId, THUMBNAIL_SIZES[highQuality ? "large" : "small"].suffix);
        } else {
            url = await Thumbnails.getSignedUrlAndLoad(item.id, item.modifiedAt, null, THUMBNAIL_SIZES[highQuality ? "large" : "small"].suffix);
        }

        this.setState({ url });
    }

    render() {
        const { item, onClick, selected } = this.props;
        const { url } = this.state;

        return (
            <ThumbnailContainer ref={this.ref}>
                <Thumbnail onClick={onClick} url={url} selected={selected} showSpinner={true} style={{ border: "solid 1px #ccc", boxShadow: "0px 0px 20px rgba(0,0,0,0.05)" }} />
                {item.name && <label>{item.name}</label>}
            </ThumbnailContainer>
        );
    }
}

