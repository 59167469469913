import React from "react";
import psl from "psl";
import isEmail from "validator/lib/isEmail";
import { Backdrop } from "@material-ui/core";
import Cookies from "js-cookie";
import firebase from "firebase/compat/app";

import getLogger, { LogGroup } from "js/core/logger";
import Api from "js/core/api";
import * as analytics from "js/analytics";
import { CreateAccount, ResetPassword, SignIn, ResetDone, WaitForGoogle, VerifyEmail } from "./AuthUi";
import { AuthConsumer } from "./AuthConsumer";
import { FirebaseSessionTracker } from "./FirebaseSessionTracker";
import { Experiment } from "../../components/Experiment";
import { FirebaseUserConsumer } from "./FirebaseUserContext";
import { app } from "js/namespaces";
import { RemoveSplashScreen, RestoreSplashScreen } from "js/editor/SplashScreen";
import * as googleAuth from "js/core/oauth/googleAuth";
import { isPPTAddin } from "js/config";
import { initializeAllExperiments } from "js/core/services/experiments";
import Spinner from "js/react/components/Spinner";

const logger = getLogger(LogGroup.AUTH);

// NOTE: we have to have the failsafe logic below because this code fails on
// some versions of Edge in PowerPoint on PC and we don't want this to block
// the sign in process

function removeQueryParam(queryParamName) {
    try {
        if (window.history.pushState) {
            const url = new URL(window.location.href);
            url.searchParams.delete(queryParamName);
            window.history.pushState({ path: url.href }, "", url.href);
        }
    } catch (err) {
        logger.warn(`removeQueryParam() failed to remove query param ${queryParamName}, error: ${err.message}`);
    }
}

function addQueryParam(queryParamName, value) {
    try {
        if (window.history.pushState) {
            const url = new URL(window.location.href);
            url.searchParams.set(queryParamName, value);
            window.history.pushState({ path: url.href }, "", url.href);
        }
    } catch (err) {
        logger.warn(`addQueryParam() failed to add query param ${queryParamName} with value ${value}, error: ${err.message}`);
    }
}

class GoogleRedirectHandler extends React.Component {
    componentDidMount() {
        const { signInWithGoogleIdToken, onError } = this.props;

        const params = new URLSearchParams(window.location.search);
        if (params.has("google_auth_success") && params.get("google_auth_success") === "true") {
            // Won't need access token
            Cookies.remove("google_auth_access_token");

            // Pull id token and remove it from cookies
            const idToken = Cookies.get("google_auth_id_token");
            Cookies.remove("google_auth_id_token");

            // Signing in
            signInWithGoogleIdToken(idToken);
            return;
        }

        if (params.has("google_auth_error")) {
            // Cleanup
            removeQueryParam("google_auth_error");

            onError(params.get("google_auth_error"));
        }
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

export function Authenticate(props) {
    return (
        <AuthConsumer>
            {auth => (
                <FirebaseUserConsumer>
                    {currentUser => (
                        <AuthenticateImplementation {...props} auth={auth} currentUser={currentUser} />
                    )}
                </FirebaseUserConsumer>
            )}
        </AuthConsumer>
    );
}

class AuthenticateImplementation extends React.Component {
    constructor(props) {
        super(props);

        if (props.defaultPage != "signIn" && props.defaultPage != "createAccount") {
            throw new Error(
                "<Authenticate> `defaultPage` prop is required " +
                "(must be `signIn` or `createAccount`)"
            );
        }

        let error;
        let info;
        if (props.readonlyEmail) {
            if (props.presentationPermission === "private") {
                error = "This presentation is private.";
            }
        }

        this._isUnmounted = false;

        this.state = {
            error,
            info,
            loading: false,
            isReady: false,
            page: props.defaultPage || "signIn",
            email: props.readonlyEmail || "",
            password: "",
            ssoState: "none"
        };
    }

    componentDidMount = async () => {
        const { readonlyEmail } = this.props;

        // When the email is readonly, we need to check for SSO up front
        if (readonlyEmail) {
            this.checkEmail(readonlyEmail);
        }

        this.setState({ isReady: true });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.currentUser !== this.props.currentUser && !this.props.currentUser) {
            // Logged out
            this.setState({ page: "signIn" });
        }
    }

    componentWillUnmount = () => {
        this._isUnmounted = true;
    }

    handleSignIn = auth => (async () => {
        const { email, password } = this.state;
        try {
            this.setState({ loading: true, error: undefined, emailError: undefined, passwordError: undefined });

            if (email == "") {
                this.setState({ loading: false, emailError: "Required" });
                return;
            }

            // clicking sign-in an email but no password begins SSO (got this idea from Dropbox)
            if (email) {
                try {
                    const ssoState = this.state.ssoState;
                    if (ssoState === "strict" || (ssoState === "optional" && password === "")) {
                        window.location = `/api/sso/saml/login?email=${encodeURIComponent(email)}`;
                        return;
                    }
                } catch (err) {
                    this.setState({ loading: false, error: err.message });
                    return;
                }
            }

            const userCredential = await auth.signInWithEmailAndPassword(email, password);

            // Ensure all experiments are initialized for the user
            await initializeAllExperiments();

            // resume the loading animation, if any
            RestoreSplashScreen();

            this.trackLogin(userCredential.user);

            if (this._isUnmounted) {
                return;
            }

            this.setState({ loading: false });
        } catch (err) {
            this.trackError(email, err);
            if (err.code == "auth/invalid-email") {
                this.setState({ loading: false, emailError: "Invalid email" });
            } else if (err.code == "auth/user-not-found") {
                this.setState({ loading: false, emailError: "No account for this email" });
            } else if (err.code == "auth/wrong-password") {
                this.setState({ loading: false, passwordError: password == "" ? "Required" : "Incorrect password" });
            } else {
                this.setState({ loading: false, error: err.message });
            }
        }
    })

    // note: for auth with Google, logging in and creating an account are the same thing
    handleGoogle = auth => (async () => {
        const { popup } = this.props;
        const { page: startPage } = this.state;

        this.setState({ page: "waitForGoogle", loading: true, error: undefined, isSigningInToGoogle: true });

        const scope = "profile email";

        if (!popup) {
            // Do proper redirect flow
            googleAuth.authenticate(scope, `${window.location.pathname}${window.location.search}`);
            return;
        }

        // Do fake popup (redirect flow in a child window)
        try {
            const { idToken } = await googleAuth.authenticateWithPopup(scope);
            this.handleGoogleIdToken(auth, idToken);
        } catch (err) {
            const error = err instanceof googleAuth.GoogleAuthFlowWasInterruptedError ? null : err.message;
            this.setState({ page: startPage, loading: false, error, isSigningInToGoogle: false });
        }
    })

    handleGoogleIdToken = async (auth, idToken) => {
        const { page: startPage } = this.state;

        this.setState({ page: "waitForGoogle", loading: true, error: undefined, isSigningInToGoogle: true });

        try {
            await this.signInWithGoogleIdToken(auth, idToken);
        } catch (err) {
            this.setState({ page: startPage, loading: false, error: err.message, isSigningInToGoogle: false });
        }
    }

    signInWithGoogleIdToken = async (auth, idToken) => {
        logger.info("Signing in to Firebase with Google auth credential");
        const credential = firebase.auth.GoogleAuthProvider.credential(idToken);
        const userCredential = await auth.signInWithCredential(credential);
        const additionalUserInfo = auth.getAdditionalUserInfo(userCredential);

        // Ensure all experiments are initialized for the user
        await initializeAllExperiments();

        // resume the loading animation, if any
        RestoreSplashScreen();

        // first sign-in results in account creation
        if (additionalUserInfo?.isNewUser) {
            logger.info("Google user is new, calling registerUser()");

            const relativeUrl = window.location.href.substr(window.location.origin.length);
            const {
                promptForGeneratePresentationTour,
            } = await Api.registerUser.post({ affiliate: this.props.affiliate, provider: "google", referer: relativeUrl });

            // Letting the consumers know that we're signing up a new user
            // we don't need to do that for email/password sign up because
            // it'll be set by the server upon email verification
            addQueryParam("signup", "true");

            this.trackCreateAccount(userCredential.user, promptForGeneratePresentationTour, true);
        } else {
            this.trackLogin(userCredential.user);
        }

        if (this._isUnmounted) {
            return;
        }

        this.setState({ loading: false, isSigningInToGoogle: false });
    }

    handleCreateAccount = auth => (async () => {
        const { email, password } = this.state;

        try {
            this.setState({ loading: true, error: undefined });

            const userCredential = await auth.createUserWithEmailAndPassword(email, password);

            // Ensure all experiments are initialized for the user
            await initializeAllExperiments();

            const relativeUrl = window.location.href.substr(window.location.origin.length);
            const {
                promptForGeneratePresentationTour,
            } = await Api.registerUser.post({
                affiliate: this.props.affiliate,
                provider: "email",
                referer: relativeUrl,
                isPPT: isPPTAddin
            });

            this.trackCreateAccount(userCredential.user, promptForGeneratePresentationTour, false);

            if (!this._isUnmounted) {
                this.setState({ loading: false });
            }
        } catch (err) {
            this.setState({ loading: false, error: err.message });
        }
    })

    trackCreateAccount = (user, promptForGeneratePresentationTour, isGoogleSignup) => {
        // whatever happens next is part of the "signup action"
        analytics.trackState({
            "is_signing_up": true
        });

        analytics.track({
            audit: true,
            category: "Auth",
            action: "signup",
            gtag: "conversion",
            props: {
                email: user.email,
                userId: user.uid,
                workspace_id: "all",
                promptForGeneratePresentationTour,
            }
        });

        analytics.track({
            audit: true,
            category: "SignupFlow",
            action: "NavForward",
            props: {
                step_number: 1,
                step_name: "Account Creation",
                object: "button",
                object_label: "Continue",
                action: "clicked",
                workspace_id: "all"
            }
        });

        if (isGoogleSignup) {
            analytics.track({
                audit: true,
                category: "SignupFlow",
                action: "NavForward",
                props: {
                    step_number: 2,
                    step_name: "Verification",
                    verification_type: "google",
                    object: "button",
                    object_label: "Continue with Google",
                    action: "clicked",
                    workspace_id: "all"
                }
            });
        }
    }

    trackLogin = user => {
        analytics.track({
            audit: true,
            category: "Auth",
            action: "login",
            props: {
                email: user.email,
                userId: user.uid,
                workspace_id: "all"
            }
        });
    }

    trackError = (email, err) => {
        const sanitizedErrorCode = err.code.replace("auth/", "");
        Api.authAudit.post({
            namespace: "analytics",
            email,
            props: {
                category: "Auth",
                action: sanitizedErrorCode
            }
        });
    }

    handleSwitchForm = ({ signIn }) => {
        this.setState({
            page: signIn ? "signIn" : "createAccount",
            error: undefined,
            emailError: undefined,
            passwordError: undefined
        });
    }

    handleClickForgot = () => {
        this.setState({ page: "forgotPassword" });
    }

    handleResetPassword = auth => async () => {
        try {
            await auth.sendPasswordResetEmail(this.state.email);
            this.setState({ page: "resetDone" });
        } catch (err) {
            this.setState({ emailError: err.message });
        }
    }

    handleEmailChange = email => {
        this.setState({ email });
        return this.checkEmail(email);
    }

    handlePasswordChange = password => {
        this.setState({ password });
    }

    checkEmail = email => {
        if (this.debounceEmail) {
            clearTimeout(this.debounceEmail);
            this.debounceEmail = null;
        }

        if (!isEmail(email)) {
            this.setState({ ssoState: "none" });
            return;
        }

        // parse the domain out of the email address
        const domain = email.split("@").pop();

        // check that the domain is includes an actual, listed TLD, this
        // prevents us from sending an excessive number of requests to our API
        const parsed = psl.parse(domain);
        if (parsed.sld && parsed.listed) {
            // debounce for common case of .co -> com
            this.debounceEmail = setTimeout(() => {
                try {
                    Api.ssoStatus.get({ email }).then(({ status }) => {
                        if (status === "strict") {
                            this.setState({ ssoState: status, page: "signIn" });
                            return;
                        } else if (status === "optional") {
                            this.setState({ ssoState: status });
                            return;
                        }
                    });
                } catch (err) {
                    logger.error(err, " Api.ssoStatus.get() failed", { email });
                    return;
                }
            }, 300);
        } else {
            this.setState({ ssoState: "none" });
        }
    }

    handleFirstNameChange = value => {
        this.setState({
            firstName: value
        });
    }

    handleLastNameChange = value => {
        this.setState({
            lastName: value
        });
    }

    handleCompanyChange = value => {
        this.setState({
            company: value
        });
    }

    hero() {
        if (this.props.popup) {
            return null;
        }
        switch (this.props.reason) {
            case "presentation-invite":
                return {
                    image: "presentation-invite",
                    thumbnailUrl: this.props.presentationThumbnailUrl,
                    caption: (
                        <span>
                            <b>{this.props.presentationName}</b>
                            <br />
                            shared by {this.props.presentationSharedBy}
                        </span>
                    )
                };
            default:
                return { image: this.props.reason };
        }
    }

    subheading() {
        if (this.props.popup) {
            return "";
        } else if (this.props.reason == "team-invite") {
            return <span>Join your team, <b>{this.props.teamName}</b>, and start creating beautiful presentations faster.</span>;
        } else if (this.state.page === "signIn" && this.props.reason == "presentation-invite") {
            return "Sign in to access this presentation";
        } else if (this.state.page === "createAccount" && this.props.reason == "presentation-invite") {
            return "Create a free account to access this presentation";
        } else if (this.props.reason == "provisioning") {
            return `${this.state.page === "signIn" ? "Sign in" : "Create an account"} to use Beautiful.ai in ${this.props.provisioningSourceName}`;
        }
        return undefined;
    }

    render() {
        const {
            noVerify,
            popup,
            skipReload,
            backdropVisible,
            onClose,
            auth,
            currentUser
        } = this.props;
        const { isReady } = this.state;

        if (!isReady) {
            return <Spinner />;
        }

        const isLoggedIn = currentUser && !this.state.loading;

        let content = this.props.children;
        if (isLoggedIn) {
            content = (
                <FirebaseSessionTracker user={currentUser}>
                    {this.renderPostAuth(currentUser, noVerify, (
                        popup && !skipReload
                            ? <ForceReload />
                            : content
                    ))}
                </FirebaseSessionTracker>
            );
        } else {
            RemoveSplashScreen();
            content = this.renderAuthContent();
        }

        if (popup) {
            content = (
                <Backdrop
                    invisible={!backdropVisible}
                    open={true}
                    onClick={e => {
                        window.top.postMessage("bai-auth-popup-close", window.location.origin);
                        onClose && onClose(e);
                    }}
                >
                    <div onClick={e => e.stopPropagation()}>
                        {content}
                    </div>
                </Backdrop>
            );
        }

        return (
            <GoogleRedirectHandler
                signInWithGoogleIdToken={idToken => this.handleGoogleIdToken(auth, idToken)}
                onError={error => this.setState({ error })}
            >
                {content}
            </GoogleRedirectHandler>
        );
    }

    renderPostAuth(currentUser, noVerify, children) {
        RemoveSplashScreen();

        return (
            <Experiment id="must_verify_email">
                {isEnabled => {
                    if (isEnabled && !noVerify && !currentUser.emailVerified && !app.integrator) {
                        return (
                            <VerifyEmail
                                email={currentUser.email}
                                noLogo={this.props.popup}
                            >
                                {children}
                            </VerifyEmail>
                        );
                    } else {
                        return children;
                    }
                }}
            </Experiment>
        );
    }

    renderAuthContent() {
        const { auth } = this.props;
        const { error } = this.state;

        switch (this.state.page) {
            case "signIn":
                return (
                    <SignIn
                        loading={this.state.loading}
                        error={error}
                        info={this.state.info}
                        emailError={this.state.emailError}
                        passwordError={this.state.passwordError}
                        defaultEmail={this.props.readonlyEmail}
                        email={this.state.email}
                        password={this.state.password}
                        showOnlyGoogle={this.props.showOnlyGoogle}
                        hero={this.hero()}
                        onClickSubmit={this.handleSignIn(auth)}
                        onClickGoogleButton={this.handleGoogle(auth)}
                        onClickForgot={this.handleClickForgot}
                        onClickSwitchLink={this.handleSwitchForm}
                        noToggle={
                            !!this.props.readonlyEmail ||
                            this.props.noToggle ||
                            this.state.ssoState === "strict"
                        }
                        ssoState={this.state.ssoState}
                        onEmailChange={this.handleEmailChange}
                        onPasswordChange={this.handlePasswordChange}
                        subheading={this.subheading()}
                        noLogo={this.props.popup}
                        hoistTerms={this.props.popup}
                    />
                );
            case "createAccount":
                return (
                    <CreateAccount
                        loading={this.state.loading}
                        error={error}
                        info={this.state.info}
                        defaultEmail={this.props.readonlyEmail}
                        email={this.state.email}
                        password={this.state.password}
                        showOnlyGoogle={this.props.showOnlyGoogle}
                        withCompany={false}
                        onClickSubmit={this.handleCreateAccount(auth)}
                        onClickGoogleButton={this.handleGoogle(auth)}
                        onClickSwitchLink={this.handleSwitchForm}
                        hero={this.hero()}
                        noToggle={
                            !!this.props.readonlyEmail ||
                            this.props.noToggle ||
                            this.state.ssoState === "strict"
                        }
                        onEmailChange={this.handleEmailChange}
                        onPasswordChange={this.handlePasswordChange}
                        onFirstNameChange={this.handleFirstNameChange}
                        onLastNameChange={this.handleLastNameChange}
                        onCompanyChange={this.handleCompanyChange}
                        subheading={this.subheading()}
                        noLogo={this.props.popup}
                        hoistTerms={this.props.popup}
                        noTitle={!this.props.popup}
                    />
                );
            case "forgotPassword":
                return (
                    <ResetPassword
                        email={this.state.email}
                        hero={this.hero()}
                        onClickReset={this.handleResetPassword(auth)}
                        emailError={this.state.emailError}
                        onEmailChange={email => this.setState({ email })}
                        noLogo={this.props.popup}
                    />
                );
            case "resetDone":
                return (
                    <ResetDone
                        hero={this.hero()}
                        noLogo={this.props.popup}
                    />
                );
            case "waitForGoogle":
                return (
                    <WaitForGoogle
                        hero={this.hero()}
                        noLogo={this.props.popup}
                    />
                );
            default:
                throw new Error();
        }
    }
}

// ugly hack to force popup page to reload inside main window
export function ForceReload() {
    const url = new URL(window.location.href);
    const search = new URLSearchParams(url.search);
    search.delete("popup");
    url.search = search.toString();
    window.top.location = url.href;
    return null;
}
