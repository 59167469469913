import React from "react";
import { Tooltip, Icon } from "@material-ui/core";
import { themeColors } from "js/react/sharedStyles";
import { FlexBox } from "./LayoutGrid";
import styled from "styled-components";

const TooltipIcon = styled.div`
&&& {
  background: white;
  font-size: 12px;
  border: solid 1px ${themeColors.ui_blue};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themeColors.ui_blue};
  > .MuiIcon-root {
    font-size: 11px;
    font-weight: 500;
  }
}
`;

export default function InfoToolTip({
    label = null,
    title,
    labelStyle = null,
    gap = 5,
    ...flexProps
}) {
    return (
        <FlexBox center gap={gap} {...flexProps}>
            {
                label &&
                <span style={labelStyle}>{label}</span>
            }
            <Tooltip
                title={title}
                placement="right-start"
            >
                <TooltipIcon>
                    <Icon classes={{
                        root: "MuiIcon-root"
                    }}>question_mark</Icon>
                </TooltipIcon>
            </Tooltip>
        </FlexBox>
    );
}
