import React, { Component, Fragment } from "reactn";
import { Thumbnail, ThumbnailContainer } from "./ThumbnailGrid";
import Thumbnails from "js/core/models/thumbnails";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import { IconMenu } from "js/react/components/UiComponents";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { Icon, MenuItem, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import { THUMBNAIL_SIZES } from "common/constants";
import { _ } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import getUserProfile from "js/core/services/userProfiles";
import { themeColors } from "js/react/sharedStyles";

const logger = getLogger(LogGroup.THUMBNAIL);

const TeamSlideThumbnailBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  label {
    flex-grow: 2;
    text-align: left;
    margin: 0px 10px 0px 0px;
  }
  .MuiIcon-root {
    margin-right: 3px;
    font-size: 18px;
  }
  & > div {
    font-size: 12px;
  }
  .star-button{
    color: ${props => props.liked ? themeColors.yellow : "inherit"};

    &:hover {
      color: ${themeColors.yellow}
    }
  }
  button {
    padding: 2px;
  }
`;

const IconBlueOnHover = styled(Icon)`
  &:hover {
    color: ${themeColors.ui_blue};
  }
`;

const StyledThumbnail = styled(Thumbnail)`
    border: solid 1px #ccc;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.05);
`;

const DisabledSlideIcon = styled.div`
  position:absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  z-index: 100;
  border-radius: 50%;
  background: orangered;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: white;
    font-size: 20px;
  }
`;

export class TeamSlideThumbnail extends Component {
    constructor() {
        super();

        this.state = {
            url: "",
            isVisible: false
        };

        this.ref = React.createRef();
    }

    componentDidMount() {
        this.loadItemInfo();

        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting === true) {
                this.observer.disconnect();
                this.setState({ isVisible: true });
            }
        });

        this.observer.observe(this.ref.current);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { item } = this.props;
        const { isVisible } = this.state;

        if ((prevState.isVisible !== isVisible && isVisible) || (prevProps.item.id !== item.id) || (prevProps.item.modifiedAt !== item.modifiedAt)) {
            this.loadThumbnail()
                .catch(err => logger.error(err, "[TeamSlideThumbnail] this.loadThumbnail() failed", { id: item.id }));
        }

        if (item.description !== prevProps.item.description) {
            this.loadItemInfo();
        }
    }

    componentWillUnmount() {
        this.isUnmounted = true;
        this.observer.disconnect();
    }

    async loadThumbnail() {
        const { item, highQuality, showThemeThumbnails = true } = this.props;
        let url = "";
        this.setState({
            url
        });

        if (showThemeThumbnails && ds.selection.presentation && !ds.selection.presentation.get("isDummy")) {
            const presentationId = ds.selection.presentation.id;
            const firstSlideModifiedAt = ds.selection.presentation.get("firstSlideModifiedAt");
            url = await Thumbnails.getSignedUrlAndLoad(item.id, firstSlideModifiedAt, presentationId, THUMBNAIL_SIZES[highQuality ? "large" : "small"].suffix);
        } else {
            url = await Thumbnails.getSignedUrlAndLoad(item.id, item.modifiedAt);
        }

        if (!this.isUnmounted) {
            this.setState({ url });
        }
    }

    handleLikeItem = () => {
        const { item } = this.props;

        if (item.ratedByUsers && item.ratedByUsers[app.user.id]) {
            item.libraryItem.update({
                ratedByUsers: { [app.user.id]: null }
            });
        } else {
            item.libraryItem.update({
                ratedByUsers: { [app.user.id]: true }
            });
        }

        // this is ugly but we need to update the item props and re-render
        item.ratedByCount = Object.keys(item.libraryItem.attributes.ratedByUsers || {}).length;
        item.ratedByUsers = item.libraryItem.attributes.ratedByUsers;

        this.forceUpdate();
    }

    async loadItemInfo() {
        let { item } = this.props;
        let profile = await getUserProfile(item.libraryItem.get("createdBy"));
        let description = item.libraryItem.get("description");

        let info = (
            <div style={{ padding: 10 }}>
                {description && <div>{description}</div>}
                {description && <Gap10 />}
                <div>Created by <strong>{profile.displayName || profile.email || "Unknown"}</strong></div>
            </div>
        );
        this.setState({
            itemInfo: info
        });
    }

    render() {
        const { item, onDoubleClick, selected, isManageLibrary, onEditSlide, onDeleteSlide, onCopySlide, onDuplicateSlide } = this.props;
        const { url, itemInfo } = this.state;

        let clickProps;
        if (isManageLibrary) {
            clickProps = {
                onDoubleClick: onDoubleClick
            };
        } else {
            clickProps = {
                onClick: onDoubleClick
            };
        }

        const { isTemplate } = item;

        return (
            <ThumbnailContainer ref={this.ref}>
                <StyledThumbnail
                    {...clickProps}
                    url={url} selected={selected} showSpinner={true} >
                    {item.isDisabled && <DisabledSlideIcon><Icon>visibility_off</Icon></DisabledSlideIcon>}
                </StyledThumbnail>
                <TeamSlideThumbnailBar liked={item.ratedByUsers && item.ratedByUsers[app.user.id]}>
                    <label>{item.name}</label>
                    <Icon onClick={this.handleLikeItem} className="star-button">star</Icon>
                    <div>{item.ratedByCount}</div>
                    {!isTemplate &&
                        <>
                            <Gap10 />
                            <Icon>cloud_download</Icon>
                            <div>{item.presentationCount}</div>
                        </>
                    }
                    <Gap10 />
                    <Tooltip
                        title={itemInfo ?? ""}
                        classes={{ popper: "popper-white rounded-5" }}
                        placement="top"
                        arrow
                        interactive
                    >
                        <IconBlueOnHover>info</IconBlueOnHover>
                    </Tooltip>
                    {isManageLibrary &&
                        <Fragment>
                            <Gap20 />
                            <IconMenu>
                                <MenuItem divider onClick={() => onEditSlide(item)}><Icon>edit</Icon>Edit Slide..</MenuItem>
                                <MenuItem divider onClick={onDoubleClick}><Icon>label</Icon>View Properties...</MenuItem>
                                <MenuItem onClick={() => onCopySlide(item)}>
                                    <Icon>change_circle</Icon>
                                    Copy to {isTemplate ? "Shared Slide" : "Slide Template"}s...
                                </MenuItem>
                                <MenuItem divider onClick={() => onDuplicateSlide(item)}><Icon>file_copy</Icon>Duplicate</MenuItem>
                                <MenuItem onClick={() => onDeleteSlide(item)}><Icon>delete</Icon>Delete</MenuItem>
                            </IconMenu>
                        </Fragment>
                    }
                </TeamSlideThumbnailBar>
            </ThumbnailContainer>
        );
    }
}
