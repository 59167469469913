import { BehaviorSubject, Observable } from "rxjs";
import _ from "lodash";

import pusher, { ExtendedPresenceChannel } from "js/core/services/pusher";
import getLogger, { LogGroup } from "js/core/logger";
import timeOffsetService from "js/core/services/timeOffset";
import { auth } from "js/firebase/auth";

const logger = getLogger(LogGroup.COLLABORATION);

export type LockState = {
    lockedBy: string;
    lockedUntil: number;
}

export type LockStates = {
    [slideId: string]: LockState;
}

export type UserInfo = {
    uid: string,
    displayName: string,
    photoURL: string,
    email: string,
}

export type EnrichedLockState = {
    isLocked: boolean,
    isLockedByMe: boolean,
    isLockedForMe: boolean,
    lockedBy?: UserInfo,
    lockedUntil?: number,
}

export type EnrichedLockStates = {
    [slideId: string]: EnrichedLockState;
}

export type SubjectEvent = {
    prev: EnrichedLockStates;
    curr: EnrichedLockStates;
}

export class CollaborationSlidesLockService {
    private _presentationId: string;
    private _lockStates: LockStates
    private _refreshLockTimeouts: {
        [slideId: string]: any;
    }
    private _initializePromise: Promise<void>;
    private _pusherChannel: ExtendedPresenceChannel;
    private _subject: BehaviorSubject<SubjectEvent>;
    private _pusherCallbacks: {
        [eventName: string]: (...args: any[]) => void;
    };

    public observable: Observable<SubjectEvent>;

    constructor(presentationId) {
        this._presentationId = presentationId;
        this._lockStates = {};
        this._initializePromise = Promise.resolve();
        this._refreshLockTimeouts = {};

        this._subject = new BehaviorSubject<SubjectEvent>({
            prev: this._enrichAndCloneLockStates(this._lockStates),
            curr: this._enrichAndCloneLockStates(this._lockStates),
        });
        this.observable = this._subject.asObservable();

        this._pusherCallbacks = {
            "pusher:member_added": () => {
                const slideIdsLockedByMe = Object.keys(this._lockStates).filter(slideId => this._lockStates[slideId].lockedBy === this._uid);
                slideIdsLockedByMe.forEach(slideId => {
                    this._pusherChannel.trigger("client-lock", { slideId, lockedUntil: this._lockStates[slideId].lockedUntil });
                });
            },
            "pusher:member_removed": ({ id: uid }) => {
                Object.keys(this._lockStates).forEach(slideId => {
                    if (this._lockStates[slideId].lockedBy === uid) {
                        this._setLockState(slideId, null);
                    }
                });
            },
            "client-lock": ({ slideId, lockedUntil }, { user_id: uid }) => {
                if (!slideId) {
                    logger.error(null, "[CollaborationsSlideLock] [constructor] [client-lock] missing slideId");
                    return;
                }

                const currentLock = this._lockStates[slideId];
                if (currentLock?.lockedBy === this._uid) {
                    // The slide may be locked by me, this can happen because there's a time gap between when we
                    // render the slide and when the subscription to the channel is established and during this gap
                    // we don't want to block the user from editing because it will deteriorate the UX, so we just
                    // allow this edge case to happen given that it's quite rare
                    return;
                }

                this._setLockState(slideId, { lockedBy: uid, lockedUntil });
            },
            "client-unlock": ({ slideId }) => {
                if (!slideId) {
                    logger.error(null, "[CollaborationsSlideLock] [constructor] [client-lock] missing slideId");
                    return;
                }

                // The slide was unlocked
                this._setLockState(slideId, null);
            }
        };
    }

    private get _uid() {
        return auth().currentUser.uid;
    }

    private _setRefreshLockTimeout(slideId: string, lockedUntil: number) {
        this._clearRefreshLockTimeout(slideId);

        this._refreshLockTimeouts[slideId] = setTimeout(() => {
            this._setLockState(slideId, null);
            delete this._refreshLockTimeouts[slideId];
        }, lockedUntil - timeOffsetService.now());
    }

    private _clearRefreshLockTimeout(slideId: string) {
        if (this._refreshLockTimeouts[slideId]) {
            clearTimeout(this._refreshLockTimeouts[slideId]);
            delete this._refreshLockTimeouts[slideId];
        }
    }

    private _setLockState(slideId: string, lockState: LockState) {
        const prev = this._enrichAndCloneLockStates(this._lockStates);

        if (lockState) {
            this._lockStates[slideId] = lockState;
            this._setRefreshLockTimeout(slideId, lockState.lockedUntil);
        } else {
            delete this._lockStates[slideId];
            this._clearRefreshLockTimeout(slideId);
        }

        this._subject.next({ prev, curr: this._enrichAndCloneLockStates(this._lockStates) });
    }

    private _getUser(uid: string): UserInfo {
        if (uid === this._uid) {
            const currentUser = auth().currentUser;
            return { uid: this._uid, email: currentUser.email, displayName: currentUser.displayName, photoURL: currentUser.photoURL };
        }

        return this._pusherChannel?.members.get(uid)?.info ?? { uid, email: "", displayName: "", photoURL: "" };
    }

    private _enrichAndCloneLockStates(lockStates: LockStates): EnrichedLockStates {
        return Object.entries(lockStates).reduce((acc, [slideId, lockState]) => ({
            [slideId]: {
                isLocked: !!lockState,
                isLockedByMe: lockState?.lockedBy === this._uid,
                isLockedForMe: !!lockState?.lockedBy && lockState?.lockedBy !== this._uid,
                lockedBy: lockState?.lockedBy ? this._getUser(lockState.lockedBy) : null,
                lockedUntil: lockState?.lockedUntil || null,
            } as EnrichedLockState
        }), {});
    }

    public initialize() {
        this._initializePromise = (async () => {
            try {
                this._pusherChannel = (await pusher.subscribe(`presence-slides-lock-${this._presentationId}`)) as ExtendedPresenceChannel;
                if (!this._pusherChannel) {
                    // Didn't subscribe (pusher is disabled)
                    return;
                }

                Object.entries(this._pusherCallbacks).forEach(([eventName, callback]) => {
                    this._pusherChannel.bind(eventName, callback);
                });
            } catch (err) {
                logger.error(err, "[CollaborationSlidesLockService] initialization failed, couldn't subscribe", { presentationId: this._presentationId });
                this._pusherChannel = null;
            }
        })();

        return this._initializePromise;
    }

    /**
     * Get the lock state for the slide
     */
    public getLockState(slideId: string) {
        return this._enrichAndCloneLockStates({ [slideId]: this._lockStates[slideId] })[slideId];
    }

    /**
     * Lock the slide for the given time
     */
    public async lock(slideId: string, lockTimeSeconds: number = 5) {
        await this._initializePromise;

        const currentLock = this._lockStates[slideId];
        if (currentLock && currentLock.lockedBy !== this._uid) {
            return false;
        }

        const lockedUntil = timeOffsetService.now() + lockTimeSeconds * 1000;
        this._setLockState(slideId, {
            lockedBy: this._uid,
            lockedUntil
        });
        this._pusherChannel?.trigger("client-lock", { slideId, lockedUntil });

        return true;
    }

    /**
     * Explicitly unlock the slide (before the lock expires)
     */
    public async unlock(slideId: string) {
        await this._initializePromise;

        const currentLock = this._lockStates[slideId];
        if (currentLock?.lockedBy !== this._uid) {
            return false;
        }

        this._setLockState(slideId, null);
        this._pusherChannel?.trigger("client-unlock", { slideId });

        return true;
    }

    public async dispose() {
        await this._initializePromise;

        if (this._pusherChannel) {
            Object.entries(this._pusherCallbacks).forEach(([eventName, callback]) => {
                this._pusherChannel.unbind(eventName, callback);
            });
            if (!this._pusherChannel.isInUse) {
                pusher.unsubscribe(this._pusherChannel.name);
            }
            this._pusherChannel = null;
        }

        Object.values(this._refreshLockTimeouts).forEach(timeoutId => clearTimeout(timeoutId));
    }
}
