import { tinycolor } from "js/vendor";
const { themeColors: sharedThemeColors } = require("js/react/sharedStyles");

import getDefaultFontWeight from "common/utils/getDefaultFontWeight";
import { PaletteColorType } from "common/constants";

function parsePPTColor(colorElement, pptColors = {}) {
    let name = colorElement.tagName.split(":").pop();

    const childNode = colorElement.firstChild;
    const colorType = childNode.tagName.split(":").pop();

    let color;
    switch (colorType) {
        case "srgbClr":
            color = tinycolor(`#${childNode.getAttribute("val")}`);
            break;
        case "sysClr":
            color = tinycolor(`#${childNode.getAttribute("lastClr")}`);
            break;
        case "prstClr":
            color = tinycolor(`#${childNode.getAttribute("val")}`);
            break;
        case "scrgbClr":
            color = tinycolor.fromRatio({
                r: parseInt(childNode.getAttribute("r")) / 100000,
                g: parseInt(childNode.getAttribute("g")) / 100000,
                b: parseInt(childNode.getAttribute("b")) / 100000
            });
            break;
        case "hslClr":
            color = tinycolor(`hsl(${childNode.getAttribute("hue")}, ${childNode.getAttribute("sat")}, ${childNode.getAttribute("lum")})`);
            break;
        case "schemeClr":
            name = childNode.getAttribute("val");
            color = tinycolor(pptColors[name]);
            break;
    }

    if (color?.isValid()) {
        return { name, value: color.toHexString() };
    }

    return null;
}

function getPPTThemeColors(themeDocument) {
    const colorSchemeElement = themeDocument.querySelector("theme > themeElements > clrScheme");

    const pptThemeColors = {};
    for (const colorElement of Array.from(colorSchemeElement.children)) {
        const color = parsePPTColor(colorElement);
        if (color) {
            pptThemeColors[color.name] = color.value;
        }
    }

    return pptThemeColors;
}

function getThemeSchemeFont(themeDocument, fontName) {
    let fontElement;
    switch (fontName) {
        case "+mj-lt":
            fontElement = themeDocument.querySelector("theme > themeElements > fontScheme > majorFont > latin");
            break;
        case "+mn-lt":
            fontElement = themeDocument.querySelector("theme > themeElements > fontScheme > minorFont > latin");
            break;
    }
    return fontElement.getAttribute("typeface");
}

function getPPTThemeFonts(themeDocument, slideMasterDocument) {
    let titleStyleElement = slideMasterDocument.querySelector("sldMaster > txStyles > titleStyle > lvl1pPr > defRPr > latin");
    let majorFontTypeface = titleStyleElement.getAttribute("typeface");
    if (majorFontTypeface.charAt(0) === "+") {
        majorFontTypeface = getThemeSchemeFont(themeDocument, majorFontTypeface);
    }
    let majorFontWeight = getDefaultFontWeight(majorFontTypeface);

    let bodyStyleElement = slideMasterDocument.querySelector("sldMaster > txStyles > bodyStyle > lvl1pPr > defRPr > latin");
    let minorFontTypeface = bodyStyleElement.getAttribute("typeface");
    if (minorFontTypeface.charAt(0) === "+") {
        minorFontTypeface = getThemeSchemeFont(themeDocument, minorFontTypeface);
    }
    let minorFontWeight = getDefaultFontWeight(minorFontTypeface);

    return {
        major: {
            name: majorFontTypeface,
            weight: majorFontWeight
        },
        minor: {
            name: minorFontTypeface,
            weight: minorFontWeight
        }
    };
}

function getSlideMasterColorsMap(slideMasterDocument) {
    const colorMapElement = slideMasterDocument.getElementsByTagName("p:clrMap")[0];
    const colorsMap = {};
    for (const node of colorMapElement.attributes) {
        colorsMap[node.nodeName] = node.nodeValue;
    }
    return colorsMap;
}

function getSlideMasterCustomBackgroundColor(slideMasterDocument, pptColors) {
    const backgroundElement = slideMasterDocument.querySelector("sldMaster > cSld > bg");
    if (!backgroundElement) {
        return null;
    }

    const solidFillElement = backgroundElement.querySelector("bgPr > solidFill");
    if (solidFillElement) {
        return parsePPTColor(solidFillElement, pptColors);
    }

    const backgrounReferenceElement = backgroundElement.querySelector("bgRef");
    if (backgrounReferenceElement) {
        return parsePPTColor(backgrounReferenceElement, pptColors);
    }

    return null;
}

/**
 * Adjusts brightness of the given color, mutates the original color object
 * @param {tinycolor} color color to adjust
 * @param {array} target aray where first element represents min brightness and second max (0-1)
 * @param {number} step 1-100
 * @returns {tinycolor} adjusted color
 */
function adjustColorBrightness(color, target, step = 1) {
    const [minBrightness, maxBrightness] = target.map(brightness => brightness * 255);
    const currentBrightness = color.getBrightness();
    if (currentBrightness < minBrightness) {
        while (color.getBrightness() < minBrightness) {
            color.lighten(step);
        }
    } else if (currentBrightness > maxBrightness) {
        while (color.getBrightness() > maxBrightness) {
            color.darken(step);
        }
    }
    return color;
}

async function getPPTTheme(zip) {
    const parser = new DOMParser();

    // It's possible to have multiple themes and different
    //   names for those themes, so we just grab the first
    const filesThemes = Object.values(zip.files)
        .filter(file => file.name.startsWith("ppt/theme/theme"));
    const themeXML = await filesThemes[0].async("string");
    const themeDocument = parser.parseFromString(themeXML, "text/xml");

    // It's possible to have multiple slide masters and different
    //   names for those slide masters, so we just grab the first
    const filesSlideMasters = Object.values(zip.files)
        .filter(file => file.name.startsWith("ppt/slideMasters/slideMaster"));
    const slideMasterXML = await filesSlideMasters[0].async("string");
    const slideMasterDocument = parser.parseFromString(slideMasterXML, "text/xml");

    // Extracting ppt colors
    const pptThemeColors = getPPTThemeColors(themeDocument);
    // Slide master defined how theme colors are mapped to the actual slide colors, so we mimic that
    const slideMasterColorsMap = getSlideMasterColorsMap(slideMasterDocument);
    const pptColors = Object.entries(slideMasterColorsMap)
        .sort(([aColorName], [bColorName]) => aColorName.localeCompare(bColorName)) // Sort by name
        .reduce((colors, [masterColor, themeColor]) => ({ ...colors, [masterColor]: pptThemeColors[themeColor] }), {});
    // Pulling slide background color (only applicable if master slide has explicitly defined
    // solid colored background)
    const customBackgroundColor = getSlideMasterCustomBackgroundColor(slideMasterDocument, pptColors);

    // Mapping ppt colors to b.ai theme colors
    // PPT colors description: https://docs.microsoft.com/en-us/dotnet/api/documentformat.openxml.presentation.colormap?view=openxml-2.8.1
    const themeColors = {};// theme.getColors();

    // Mapping main colors (background and primary)
    let lightColors = [pptThemeColors.lt1, pptThemeColors.lt2];
    let darkColors = [pptThemeColors.dk1, pptThemeColors.dk2];

    // Determining main colors (bg, primary, theme)
    const backgroundColor = customBackgroundColor?.value ?? pptColors.bg1;
    const isBackgroundLight = tinycolor(backgroundColor).isLight();
    if (isBackgroundLight) {
        lightColors.push(backgroundColor);
    } else {
        darkColors.push(backgroundColor);
    }

    // Keeping only unique colors
    lightColors = [...new Set(lightColors)];
    darkColors = [...new Set(darkColors)];

    // Making sure there are at least two colors
    if (lightColors.length === 1) {
        lightColors.push(lightColors[0]);
    }
    if (darkColors.length === 1) {
        darkColors.push(darkColors[0]);
    }

    themeColors.background_light = lightColors[0];
    themeColors.background_dark = darkColors[0];

    themeColors.primary_light = lightColors[0];
    themeColors.primary_dark = darkColors[0];

    themeColors.secondary_light = lightColors[1];
    themeColors.secondary_dark = darkColors[1];

    if (!themeColors.hasOwnProperty(PaletteColorType.POSITIVE)) {
        themeColors[PaletteColorType.POSITIVE] = "#54C351";
    }
    if (!themeColors.hasOwnProperty(PaletteColorType.NEGATIVE)) {
        themeColors[PaletteColorType.NEGATIVE] = "#E04C2B";
    }
    if (!themeColors.hasOwnProperty(PaletteColorType.HYPERLINK)) {
        themeColors[PaletteColorType.HYPERLINK] = sharedThemeColors.ui_blue;
    }

    const accentColors = Object.entries(pptColors).filter(([colorName]) => colorName.startsWith("accent")).map(([colorName, colorValue]) => tinycolor(colorValue));

    themeColors.theme = adjustColorBrightness(accentColors[0].clone(), isBackgroundLight ? [0, 0.6] : [0.4, 1], 1).toHexString();
    themeColors.background_accent = isBackgroundLight ? lightColors[1] : darkColors[1];

    accentColors
        .forEach((color, colorIdx) => {
            if (colorIdx > 0) {
                // Filter out theme color (first)
                themeColors[`accent${colorIdx}`] = color.toHexString();
            }
            themeColors[`chart${colorIdx + 1}`] = color.toHexString();
        });

    // Extracting ppt theme fonts
    const { major, minor } = getPPTThemeFonts(themeDocument, slideMasterDocument);

    return {
        colors: themeColors,
        headerFont: major.name,
        headerFontWeight: major.weight,
        bodyFont: minor.name,
        bodyFontWeight: minor.weight,
        defaultBackgroundColor: isBackgroundLight ? "background_light" : "background_dark",
        needsFontsMap: true,
    };
}

export default getPPTTheme;
