import React, { Component } from "reactn";
import { DialogTitle, DialogActions, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import styled from "styled-components";

import { BlueButton } from "js/react/components/UiComponents.js";
import DialogContent from "./DialogContent";
import BeautifulDialog from "./BeautifulDialog";
import { FlexSpacer } from "js/react/components/Gap";
import { _ } from "js/vendor";
import { app } from "js/namespaces";

const DialogHeader = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 20px 0 20px;
`;

export default class MessageDialog extends Component {
    state = {
        optOut: false
    }

    componentDidMount = () => {
        const { optOutProperty, closeDialog } = this.props;

        // Immediately close the dialog with a confirmation
        //   response if the user has opted out of this dialog
        if (app.user.get("messagesSeen")?.[optOutProperty]) {
            closeDialog(true);
        }
    }

    handleChangeOptOutCheckbox = event => {
        this.setState({
            optOut: !!event.target.checked
        });
    }

    handleCloseDialog = result => {
        const { optOutProperty, closeDialog } = this.props;
        const { optOut } = this.state;

        if (optOutProperty) {
            const messagesSeen = _.cloneDeep(app.user.get("messagesSeen")) ?? {};
            if (optOut) {
                messagesSeen[optOutProperty] = true;
            } else {
                delete messagesSeen[optOutProperty];
            }
            app.user.update({ messagesSeen });
        }

        closeDialog(result);
    }

    render() {
        const {
            title,
            message,
            header,
            showCancel = false,
            cancelButtonLabel,
            cancelCallback,
            buttonText,
            acceptOnClose,
            preventClose = true,
            onClick,
            onClose,
            closeDialog,
            align,
            maxWidth,
            buttonOptions = {},
            optOutProperty,
        } = this.props;

        const { optOut } = this.state;

        return (
            <BeautifulDialog
                closeDialog={() => {
                    if (acceptOnClose) {
                        onClick && onClick();
                    }
                    closeDialog();
                }}
                preventClose={preventClose}
                onClose={onClose}
                style={{ textAlign: align }}
                maxWidth={maxWidth || "sm"}
            >
                {header && <DialogHeader>{header}</DialogHeader>}
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>{message}</DialogContent>
                <DialogActions>
                    {!!optOutProperty &&
                        <FormControlLabel
                            label="Don't show me this again"
                            control={
                                <Checkbox checked={optOut} onChange={this.handleChangeOptOutCheckbox} />
                            }
                        />
                    }
                    <FlexSpacer />
                    {showCancel && <Button
                        onClick={() => {
                            cancelCallback && cancelCallback();
                            this.handleCloseDialog(false);
                        }}
                    >
                        {cancelButtonLabel ?? "Cancel"}
                    </Button>}
                    {buttonOptions.color === "blue" ? (
                        <BlueButton
                            onClick={() => {
                                onClick && onClick();
                                this.handleCloseDialog(true);
                            }}
                        >
                            {buttonText || "OK"}
                        </BlueButton>
                    ) : (
                        <Button
                            onClick={() => {
                                onClick && onClick();
                                this.handleCloseDialog(true);
                            }}
                        >
                            {buttonText || "OK"}
                        </Button>
                    )}
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
