import { isPPTAddin } from "js/config";
import { AssetType } from "common/constants";

export const UIActionType = {
    SEARCH_FOR_SLIDE: "searchForSlide",
    ADD_SLIDE: "addSlide",
    ADD_ASSET: "addAssetToSlide"
};

const restrictors = {
    [UIActionType.ADD_ASSET]: [
        ({ assetType }) => isPPTAddin && assetType === AssetType.STOCK_VIDEO,
        ({ assetType, source }) => isPPTAddin && assetType === AssetType.VIDEO && source !== "hosted"
    ],
    [UIActionType.ADD_SLIDE]: [
        ({ templateId }) => isPPTAddin && ["video", "countdown", "timer", "web-view"].includes(templateId)
    ]
};

export function isUIActionRestricted(actionType, actionProps = {}) {
    const actionRestrictors = restrictors[actionType] ?? [];
    return actionRestrictors.reduce((isRestricted, restrictor) => isRestricted || restrictor(actionProps), false);
}
