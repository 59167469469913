import React, { Component } from "reactn";
import { Button, DialogTitle, DialogActions } from "@material-ui/core";
import {
    BeautifulDialog, DialogContent,
} from "js/react/components/Dialogs/BaseDialog";
import { _ } from "js/vendor";
import { GridBox, ScrollBox } from "js/react/components/LayoutGrid";
import { UserProfile } from "js/react/components/Avatar";
import styled from "styled-components";
import Api from "js/core/api";
import { trackActivity } from "js/core/utilities/utilities";
import permissionsDS from "js/react/views/PresentationSettings/dataservices/PermissionsDataService";

const DialogActionsBetween = styled(DialogActions)`
    && {
        justify-content: space-between;
    }
`;

export class ResendInviteDialog extends Component {
    handleSendEmail = async () => {
        let {
            closeDialog,
            onSendEmail,
        } = this.props;

        onSendEmail && await onSendEmail();

        closeDialog();
    }

    handleCopyInviteLink = async () => {
        let {
            closeDialog,
            onCopyInviteLink,
        } = this.props;

        onCopyInviteLink && onCopyInviteLink();

        closeDialog();
    }

    render() {
        let {
            title = "Resend invitation?",
            user,
        } = this.props;

        return (
            <BeautifulDialog id="confirmInvite" closeDialog={this.props.closeDialog}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <ScrollBox fillWidth style={{ maxHeight: 370 }}>
                        <GridBox rows>
                            <UserProfile
                                key={user.id}
                                profile={user}
                            />
                        </GridBox>
                    </ScrollBox>
                </DialogContent>
                <DialogActionsBetween>
                    <Button
                        onClick={this.handleCopyInviteLink}
                    >Copy Invite Link</Button>
                    <Button
                        color="primary"
                        onClick={this.handleSendEmail}
                    >Send Email</Button>
                </DialogActionsBetween>
            </BeautifulDialog>
        );
    }
}
