import React, { Component } from "react";
import { Divider, Tooltip } from "@material-ui/core";
import { $, _ } from "js/vendor";
import { getStaticUrl } from "js/config";
import { PresentationPrivacyType } from "js/core/models/presentation";
import "css/player-settings.scss";
import { BlueSwitch } from "js/react/components/Switch";
import PlayerControls, { ControlStates } from "js/react/views/Player/Components/PlayerControls";
import { PlayerBranding } from "js/react/views/Player/Components/PlayerView";
import EndPage from "js/react/views/Player/Components/EndPage";

class PlayerPreview extends Component {
    constructor() {
        super();
        this.state = {
            previewType: "player",
            actionsMenuIsOpen: false,
        };

        this.endPageRef = React.createRef();
    }

    handleToggleActionsMenu = value => {
        this.setState({ actionsMenuIsOpen: value });
    }

    renderFullPlayer(creator) {
        const {
            actionsMenuIsOpen,
        } = this.state;
        const {
            slideThumbnail,
            slidesCount = 1,
            showBranding,
            showSocialSharing,
            showGetDeckUI,
            allowPdfDownload,
            showAuthor,
            showTitle,
            presentationName,
        } = this.props;

        return (
            <div className="player-preview full-player">
                {showBranding &&
                    <PlayerBranding isLoggedIn={false} />
                }
                <div className="slide-preview" style={{ height: showBranding ? "calc(100% - 50px)" : "100%" }}>
                    <img className="slide-thumbnail" src={slideThumbnail} />
                    {showBranding && (
                        <div className="slide-watermark">
                            <img src={getStaticUrl(`/images/beautifulai-logos/watermark.svg`)} />
                        </div>
                    )}
                    <PlayerControls
                        visible={true}
                        controlState={ControlStates.EXPANDED}
                        actionsMenuIsOpen={actionsMenuIsOpen}
                        onToggleActionsMenu={this.handleToggleActionsMenu}
                        showBranding={showBranding}
                        slidesCount={slidesCount}
                        currentSlideIndex={0}
                        isLoggedIn={false}
                        allowSocialSharing={showSocialSharing}
                        onCopyDeck={showGetDeckUI ? () => { } : false}
                        allowCopyLink
                        onEditPresentation={false}
                        onDownloadPdf={allowPdfDownload ? () => { } : false}
                        advanceToSlide={() => { }}
                        showAuthor={showAuthor}
                        showTitle={showTitle}
                        presentationName={presentationName}
                        creator={creator}
                        isPlayerSettingsPreview
                    />
                </div>
            </div>
        );
    }

    renderEndOfPresentation(creator) {
        let {
            showBranding,
            showSocialSharing,
            showGetDeckUI,
            allowPdfDownload,
            showAuthor,
            showTitle,
            presentationName,
        } = this.props;

        return (
            <div className="end_banner player-preview">
                <EndPage
                    showBranding={showBranding}
                    showAuthor={showAuthor}
                    showTitle={showTitle}
                    presentationName={presentationName}
                    creator={creator}
                    isLoggedIn={false}
                    isPlayerSettingsPreview
                    allowSocialSharing={showSocialSharing}
                    onCopyDeck={showGetDeckUI ? () => { } : false}
                    allowCopyLink
                    onDownloadPdf={allowPdfDownload ? () => { } : false}
                />
            </div>
        );
    }

    render() {
        let {
            creator = {
                displayName: "Author",
                photoURL: null,
            },
        } = this.props;

        let preview;

        switch (this.state.previewType) {
            case "player":
                preview = this.renderFullPlayer(creator);
                break;
            case "end":
                preview = this.renderEndOfPresentation(creator);
                break;
        }

        return (
            <div className="player-preview-frame">
                {preview}

                <div className="toggle-buttons">
                    <div
                        className={this.state.previewType == "player" ? "selected" : ""}
                        onClick={() => this.setState({ previewType: "player" })}
                    >
                        Player
                    </div>
                    <div
                        className={this.state.previewType == "end" ? "selected" : ""}
                        onClick={() => this.setState({ previewType: "end" })}
                    >
                        End of Presentation
                    </div>
                </div>
            </div>
        );
    }
}

export default class PlayerSettings extends Component {
    componentDidMount() {
        _.defer(() => {
            this.updatePreview();
        });
        $(window).on("resize.playersettings", () => {
            this.updatePreview();
        });
    }

    componentDidUpdate() {
        this.updatePreview();
    }

    componentWillUnmount() {
        $(window).off(".playersettings");
    }

    updatePreview() {
        let scale = Math.min(
            ($(".player_settings").width() - 360) / 1280,
            (Math.min(window.innerHeight, 768) - 230) / 720
        );
        $(".player-preview").css("transform", `scale(${scale})`);
        $(".player-preview-frame")
            .width(1280 * scale)
            .height(720 * scale + 30);
    }

    render() {
        let {
            sectionTitle = null,
            privacySetting,
            removeBeautifulBranding,
            showTitle,
            showAuthor,
            requireEmail,
            showSocialSharing,
            showGetDeckUI,
            allowPdfDownload,
            includeAutoPlay = false,
            autoPlay,
            autoPlayDuration,
            autoLoop,
            promptForUpgrade,
            handleChange,
        } = this.props;

        return (
            <div className="contents-frame">
                {
                    sectionTitle &&
                    <div className="section-title">{sectionTitle}</div>
                }
                <div
                    onClick={event => promptForUpgrade && promptForUpgrade(event)}
                    className="switchContainer"
                >
                    {!removeBeautifulBranding && (
                        <>
                            <BlueSwitch
                                id="removeBeautifulBranding"
                                checked={!removeBeautifulBranding}
                                onChange={() => handleChange({ removeBeautifulBranding: !removeBeautifulBranding })}
                                label="Show Beautiful.ai branding"
                                tooltipTitle="Removes Beautiful.ai branding and call-to-actions from shared and embedded presentations."
                            />
                            <Divider />
                        </>
                    )}

                    <BlueSwitch
                        id="showTitle"
                        checked={showTitle}
                        onChange={() => handleChange({ showTitle: !showTitle })}
                        label="Show Title"
                        tooltipTitle="Controls the visibility of the presentation title in shared presentation links."
                    />

                    <BlueSwitch
                        id="showAuthor"
                        checked={showAuthor}
                        onChange={() => handleChange({ showAuthor: !showAuthor })}
                        label="Show Author"
                        tooltipTitle="Controls the visibility of the presentation author name in shared presentation links."
                    />

                    <BlueSwitch
                        id="requireEmail"
                        checked={requireEmail}
                        onChange={() => handleChange({ requireEmail: !requireEmail })}
                        label="Collect Viewer Emails"
                        tooltipTitle="Controls whether anonymous viewers must provide an email for analytics."
                        disabled={privacySetting === PresentationPrivacyType.PRIVATE}
                    />

                    <BlueSwitch
                        id="showSocialSharing"
                        checked={showSocialSharing}
                        onChange={() => handleChange({ showSocialSharing: !showSocialSharing })}
                        label="Allow Social Sharing"
                        tooltipTitle="Controls whether viewers of a presentation can share on social networks. This option is disabled for private or secured presentations."
                        disabled={privacySetting !== PresentationPrivacyType.PUBLIC}
                    />

                    <BlueSwitch
                        id="showGetDeckUI"
                        checked={showGetDeckUI}
                        onChange={() => handleChange({ showGetDeckUI: !showGetDeckUI })}
                        label="Allow Copying Deck"
                        tooltipTitle="Controls whether viewers can make a copy of the presentation to use as a template for their presentations. This option is disabled for private or secured presentations."
                        disabled={privacySetting !== PresentationPrivacyType.PUBLIC}
                    />

                    <BlueSwitch
                        id="allowPdfDownload"
                        checked={allowPdfDownload}
                        onChange={() => handleChange({ allowPdfDownload: !allowPdfDownload })}
                        label="Allow PDF Download"
                        tooltipTitle="Controls whether viewers can download the presentation as a PDF. This option is disabled for private presentations."
                        disabled={privacySetting === PresentationPrivacyType.PRIVATE}
                    />

                    {
                        includeAutoPlay &&
                        <>
                            <Divider />

                            <BlueSwitch
                                id="autoPlay"
                                checked={autoPlay}
                                onChange={() => handleChange({ autoPlay: !autoPlay })}
                                label="Auto advance slides"
                            />

                            <Tooltip title="Sets how many seconds to wait before automatically advancing to the next slide. Specific delay settings on a slide will override this duration.">
                                <div className="inset-control">
                                    <div className="small-label">After</div>
                                    <input
                                        disabled={!autoPlay}
                                        type="number"
                                        min="1"
                                        max="3600"
                                        step="1"
                                        value={autoPlayDuration}
                                        onChange={event =>
                                            handleChange({ autoPlayDuration: event.target.value })
                                        }
                                    />
                                    <div className="small-label">seconds</div>
                                </div>
                            </Tooltip>

                            <BlueSwitch
                                id="autoLoop"
                                checked={autoLoop}
                                onChange={() => handleChange({ autoLoop: !autoLoop })}
                                label="Loop playback"
                                tooltipTitle="Viewer analytics are not available when this setting is enabled."
                            />
                        </>
                    }
                </div>
                <PlayerPreview
                    {...this.props}
                />
            </div>
        );
    }
}
