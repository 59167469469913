const theme = {
    isBuiltIn: true,
    id: "simple",
    name: "Simple",
    cjkFont: "jp",
    colors: {
        background_accent: "rgba(245,245,245,1)",
        background_dark: "rgba(0,0,0,1)",
        background_light: "#ffffff",
        chart1: "rgba(17,169,226,1)",
        chart2: "rgba(44,81,216,1)",
        chart3: "rgba(255,187,67,1)",
        chart4: "rgba(252,114,99,1)",
        chart5: "rgba(207,237,255,1)",
        negative: "rgba(252,114,99,1)",
        positive: "rgba(161,202,85,1)",
        primary_dark: "rgba(0,0,0,1)",
        primary_light: "rgba(255,255,255,1)",
        secondary_dark: "rgba(0,0,0,0.8)",
        secondary_light: "rgba(255,255,255,0.8)",
        theme: "rgba(17,169,226,1)",
        hyperlink: "#11a9e2"
    },
    defaultBackgroundColor: "background_light",
    defaultSlideColor: "theme",
    fontBodyFontId: "roboto",
    fontBodyLetterSpacing: 0.01,
    fontBodyLineHeight: 2,
    fontBodyScaling: 100,
    fontBodyTextTransform: "auto",
    fontBodyWeight: 400,
    fontHeaderFontId: "roboto",
    fontHeaderLetterSpacing: 0,
    fontHeaderLineHeight: 1.6,
    fontHeaderScaling: 100,
    fontHeaderTextTransform: "auto",
    fontHeaderWeight: 700,
    fontScale: 1,
    fontTitleFontId: "roboto",
    fontTitleLetterSpacing: 0.01,
    fontTitleLineHeight: 1.8,
    fontTitleScaling: 100,
    fontTitleTextTransform: "auto",
    fontTitleWeight: 400,
    footerMessage: `${new Date().getFullYear()} Proprietary and Confidential. All Rights Reserved.`,
    iconStyle: "classic",
    logo: null,
    logoOffset: 0,
    logoPosition: "left",
    logoScale: 1,
    logo_dark: null,
    palette_name: "custom",
    showFooterByDefault: true,
    showLogo: true,
    showMessage: false,
    showPageNum: true,
    styleBackground: "none",
    styleBodyFont: "sourcesanspro",
    styleColor: "slide",
    styleDecoration: "none_center",
    styleDesign: 1,
    styleEffect: "none",
    styleElementStyle: "filled",
    styleFontWeight: "heavy",
    styleFonts: 1,
    styleHeaderAlignment: "left",
    styleShape: "circle",
    styleTitleFont: "bebasneue",
    styleTitleSlide: "bar_left",
    styleWeight: "light"
};

module.exports = theme;
