import { _ } from "js/vendor";

/** checks if a slide contains any keys labeled "linkToSlide" */
export const linkedSlides = {

    /** checks to see if a slide contains any lined slides */
    detect: slide => {
        let result;

        _.traverse(slide.attributes, (value, key) => {
            if (!result && key === "linkToSlide") {
                result = true;
            }
        });

        return result;
    },

    /** removes all linked slides, if any */
    remove: slide => {
        _.traverse(slide.dataState, (value, key, parent) => {
            if (key === "linkToSlide") {
                delete parent[key];
            }
        }, {
            maxDepth: 8,
            ignoreKeys: [
                "presentation",
                "adapter",
                "events"
            ]
        });
    }
};
