import React, { Fragment, Component } from "react";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import { _ } from "js/vendor";

export default class ExportToPPTWarningDialog extends Component {
    render() {
        const { failedSlideIndexes, exportWarnings } = this.props;

        return (
            <BeautifulDialog
                classes={{ paper: "error-dialog" }}
                maxWidth="md"
                closeDialog={this.props.closeDialog}
            >
                <DialogTitle>There were some issues with your export</DialogTitle>
                <DialogContent>
                    {failedSlideIndexes.length > 0 &&
                        <Fragment>
                            <h2>The following slides were not exported due to unexpected errors</h2>
                            <ul>
                                <li>Slide {failedSlideIndexes.map(i => i + 1).join(",")}</li>
                            </ul>
                        </Fragment>
                    }
                    {exportWarnings.length > 0 &&
                        <Fragment>
                            <h2>The following issues occurred during your export:</h2>
                            <ul>
                                {exportWarnings.map(warning => <li key={warning}>{warning}</li>)}
                            </ul>
                        </Fragment>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Ok</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
