import Cookies from "js-cookie";

import { auth } from "js/firebase/auth";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import { openWindowAndWaitForItToBeClosed, PopupBlockedError } from "js/core/utilities/selfClosingWindow";

export class GoogleAuthError extends Error { }
export const GoogleAuthPopupBlockedError = PopupBlockedError;
export class GoogleAuthFlowWasInterruptedError extends GoogleAuthError { }

interface GoogleAuthTokens {
    accessToken: string,
    idToken: string,
}

async function _openPopup(scope: string, grantOfflineAccess: boolean): Promise<GoogleAuthTokens> {
    let url = `${window.location.origin}/google/auth` +
        `?scope=${encodeURIComponent(scope)}` +
        `&continueUrl=${encodeURIComponent("/close")}`;

    if (grantOfflineAccess) {
        const userIdToken = await auth().currentUser.getIdToken();
        Cookies.set("user_id_token", userIdToken, { sameSite: "strict", expires: new Date(Date.now() + 60 * 1000) });

        url += "&requestOfflineAccess=true";
    }

    const lastSearchParams = await openWindowAndWaitForItToBeClosed(url);

    if (lastSearchParams.has("google_auth_error")) {
        throw new GoogleAuthError(lastSearchParams.get("google_auth_error"));
    }

    if (lastSearchParams.get("google_auth_success") !== "true") {
        throw new GoogleAuthFlowWasInterruptedError();
    }

    const accessToken = Cookies.get("google_auth_access_token");
    Cookies.remove("google_auth_access_token");

    const idToken = Cookies.get("google_auth_id_token");
    Cookies.remove("google_auth_id_token");

    return { accessToken, idToken };
}

/**
 * Grants our app offline access for the given scope, executes the redirect ouath flow in a separate window
 * and saves the tokens
 */
export async function grantOfflineAccess(scope: string): Promise<GoogleAuthTokens> {
    return _openPopup(scope, true);
}

/**
 * Grants our app offline access to user's Google Drive.
 * If the popup is blocked, uses app.dialogManager to call grantOfflineAccess() synchronously to avoid the popup being blocked
 */
export function grantOfflineAccessWithDialogIfNeeded(scope: string): Promise<GoogleAuthTokens> {
    return new Promise((resolve, reject) => {
        grantOfflineAccess(scope)
            .then(tokens => resolve(tokens))
            .catch(err => {
                if (!(err instanceof GoogleAuthPopupBlockedError)) {
                    reject(err);
                    return;
                }

                ShowConfirmationDialog({ // TODO: get title/message customizable
                    title: "Connect Google Drive",
                    message: "Beautiful.ai needs access to your Google Drive, press OK to open Google's consent page",
                    acceptCallback: () => {
                        grantOfflineAccess(scope)
                            .then(tokens => resolve(tokens))
                            .catch(err => reject(err));
                    },
                    cancelCallback: () => {
                        reject(new GoogleAuthFlowWasInterruptedError());
                    }
                });
            })
            .catch(err => reject(err));
    });
}

/**
 * Does google sign in flow using redirect
 */
export function authenticate(scope: string, continueUrl: string): void {
    window.location.href = `/google/auth?scope=${encodeURIComponent(scope)}&continueUrl=${encodeURIComponent(continueUrl)}`;
}

/**
 * Does google sign in flow using redirect in a separate window (faux popup), returns id token
 */
export async function authenticateWithPopup(scope: string): Promise<GoogleAuthTokens> {
    return _openPopup(scope, false);
}
