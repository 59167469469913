import React, { Component } from "reactn";
import { Snackbar as MuiSnackbar } from "@material-ui/core";
import { renderReactDialogFromJSX } from "js/react/renderReactRoot";
import styled from "styled-components";

const StyledSnackbar = styled(MuiSnackbar)`
    .MuiSnackbarContent-message {
        margin: 0 auto;
    }
`;

export class SnackBar extends Component {
    render() {
        const props = this.props;
        return (
            <StyledSnackbar {...props}></StyledSnackbar>
        );
    }
}

export const ShowSnackBar = props => {
    let allowClickaway = false;
    let dialog;
    props = {
        open: true,
        onClose: (event, reason) => {
            if (reason !== "clickaway" || allowClickaway) {
                dialog && dialog.close();
            }
        },
        ...props,
    };
    // Allow explicitly setting 'null' as a value.
    if (props.autoHideDuration === undefined) {
        props.autoHideDuration = 4000;
    }
    setTimeout(() => allowClickaway = true, 100);
    dialog = renderReactDialogFromJSX(
        <SnackBar {...props} />,
        {
            overlayIsUnblocking: true, // Allows clicks to pass thru the overlay
            unmountOnReroute: false, // Allows the snackbar to persist across reroutes
            registerDialog: false, // Allows key input to work in the editor while snackbar is visible
        },
    );
    return dialog;
};
