import { getCallable } from "../_sdk/client";
import {
    CreateScreenshotRequest, CreateScreenshotResponse,
    GetReferenceScreenshotsRequest, GetReferenceScreenshotsResponse
} from "./types";
import { endpoints, apiName } from "./endpoints";

const getReferenceScreenshots = getCallable<GetReferenceScreenshotsRequest, GetReferenceScreenshotsResponse>(apiName, "getScreenshots", {
    ...endpoints.getReferenceScreenShots,
    composer: request => {
        const { id } = request;
        return {
            url: `/debug/screenshots/${encodeURIComponent(id)}`,
            options: {
                method: "GET"
            }
        };
    }
});

const createScreenshot = getCallable<CreateScreenshotRequest, CreateScreenshotResponse>(apiName, "createScreenshot", {
    ...endpoints.createScreenshot,
    composer: request => {
        return {
            url: `/debug/screenshot/`,
            options: {
                method: "POST",
                body: JSON.stringify(request)
            }
        };
    }
});

export const callables = {
    getReferenceScreenshots,
    createScreenshot,
};
