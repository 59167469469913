import isConnected from "js/core/utilities/isConnected";

export class ServerOrClientOfflineError extends Error {
    constructor(message: string, public originalError?: Error) {
        super(message);
        this.name = "ServerOrClientOfflineError";
    }
}

// eslint-disable-next-line no-undef
async function fetchAndTrackOfflineStatus(input: RequestInfo | URL, init?: RequestInit) {
    try {
        let response: Response;
        try {
            response = await fetch(input, init);
        } catch (err) {
            if (err instanceof TypeError && err.message === "Failed to fetch") {
                // fetch() failed with a "Failed to fetch" error, so we're offline or the server is unreachable
                throw new ServerOrClientOfflineError(err.message, err);
            }

            // Some other error (e.g. bad argument), rethrow and assume offline status is unchanged
            throw err;
        }

        // Check for "502 Bad Gateway" which means the server is most likely down
        if (response.status === 502) {
            throw new ServerOrClientOfflineError("Server returned 502, assuming server offline");
        }

        // Received some response, so assuming we're online
        isConnected.triggerIsOnlineChange(true);

        return response;
    } catch (err) {
        if (err instanceof ServerOrClientOfflineError) {
            // Report offline
            isConnected.triggerIsOnlineChange(false);
        }

        throw err;
    }
}

export default fetchAndTrackOfflineStatus;
