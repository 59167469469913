import React, { Component } from "react";
import { LinkType } from "js/react/views/PresentationSettings/dataservices/LinkDataService";
import { DialogTitle, Button } from "@material-ui/core";
import {
    BeautifulDialog,
    DialogContent
} from "js/react/components/Dialogs/BaseDialog";
import { trackActivity } from "js/core/utilities/utilities";
import { FlexSpacer } from "js/react/components/Gap";
import { _ } from "js/vendor";
import Icon from "js/react/components/Icon";
import Spinner from "js/react/components/Spinner";
import styled from "styled-components";
import ErrorDialog from "js/react/components/Dialogs/ErrorDialog";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";

const Link = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;

  &:hover {
    background: #f1f1f1;
  }

  .name {
    margin-left: 10px;
    font-weight: 600;
  }

  input {
    opacity: 0;
    position: absolute;
  }
`;

export default class ShareViewOnlyPresentationDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            publicLinks: [],
            isLoaded: false
        };
    }

    componentDidMount() {
        let { presentation } = this.props;

        presentation.loadPresentationLinks();

        presentation.links.on("modelsLoaded", () => {
            let publicLinks = _.filter(
                presentation.links.models,
                link => link.get("type") == LinkType.PUBLIC && link.get("enabled") && !link.isLinkExpired()
            );

            this.setState({ publicLinks, isLoaded: true });
        });
    }

    render() {
        let { presentation } = this.props;
        let { publicLinks, isLoaded } = this.state;

        if (isLoaded && publicLinks.length == 0) {
            return (
                <ErrorDialog
                    closeDialog={this.props.closeDialog}
                    title="No public links have been created for this presentation so you won't be able to
                        share it externally."
                />
            );
        } else {
            return (
                <BeautifulDialog closeDialog={this.props.closeDialog}>
                    <DialogTitle>These links are available to share this presentation</DialogTitle>
                    <DialogContent>
                        {!isLoaded && <Spinner />}
                        {publicLinks.map(link => <PublicLink key={link.id} presentation={presentation} link={link} closeDialog={this.props.closeDialog} />)}
                    </DialogContent>
                </BeautifulDialog>
            );
        }
    }
}

class PublicLink extends Component {
    constructor() {
        super();
        this.ref = React.createRef();
    }

    analyticsProps(link) {
        return {
            link_ids: [link.get("id")],
            is_new: false,
            type: "public_link",
            ...this.props.presentation.getAnalytics()
        };
    }

    copyToClipboard = () => {
        const { link } = this.props;

        clipboardWrite({
            [ClipboardType.TEXT]: this.ref.current.value,
        });
        // this.setGlobal({
        //     alertMessage: "Shareable URL was copied to the clipboard"
        // });
        const props = this.analyticsProps(link);
        trackActivity("Sharing", "CopyLink", null, null, props, { audit: true });

        this.props.closeDialog();
    };

    render() {
        let { link } = this.props;

        return (
            <Link>
                <Icon iconName="link" />
                <div className="name">{link.get("name")}</div>
                <FlexSpacer />
                <input
                    ref={this.ref}
                    className="link-url"
                    type="text"
                    value={link.getLinkURL()}
                    readOnly
                />
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={link.isLinkExpired()}
                    onClick={this.copyToClipboard}
                >
                    Copy Link
                </Button>
            </Link>
        );
    }
}
