import { TaskState } from "common/constants";
import Api from "js/core/api";
import pusher from "js/core/services/pusher";
import genExportChannelId from "common/utils/genExportChannelId";

export async function requestExportCache(onEvent, taskProps) {
    const channelId = genExportChannelId(taskProps);

    const channel = await pusher.subscribe(channelId);
    if (!channel) {
        throw new Error("Pusher is disabled");
    }

    const unsubscribe = () => {
        channel.unbind_all();
        pusher.unsubscribe(channelId);
    };

    const bindEvent = state => {
        channel.bind(state, data => {
            if ([TaskState.FINISHED, TaskState.ERROR].includes(state)) {
                unsubscribe();
            }

            onEvent({
                ...data,
                state,
            });
        });
    };
    bindEvent(TaskState.PREPARING);
    bindEvent(TaskState.PROCESSING);
    bindEvent(TaskState.FINISHED);
    bindEvent(TaskState.ERROR);

    await Api.exportCache.post(taskProps);

    return { cancel: unsubscribe };
}
