const theme = {
    isBuiltIn: true,
    id: "dark",
    name: "Dark",
    showLogo: true,
    logoScale: 1,
    logoPosition: "left",
    logoOffset: 0,
    showMessage: true,
    showPageNum: true,
    logo: null,
    logo_dark: null,
    showFooterByDefault: null,
    palette_name: "custom",
    defaultSlideColor: "theme",
    defaultBackgroundColor: "background_dark",
    styleFonts: 1,
    styleFontWeight: "heavy",
    styleElementStyle: "filled",
    styleEffect: "none",
    styleDesign: 1,
    styleDecoration: "underline_left",
    styleHeaderAlignment: "left",
    styleShape: "none",
    styleColor: "slide",
    styleBackground: "none",
    styleTitleFont: "sourcesanspro",
    styleBodyFont: "sourcesanspro",
    styleWeight: "light",
    styleTitleSlide: "bar_left",
    fontScale: 1,
    iconStyle: "chunky",
    cjkFont: "jp",
    colors: {
        accent1: "rgba(155,203,185,1)",
        accent2: "#DAE0C9",
        background_accent: "rgba(12,12,12,1)",
        background_dark: "#000000",
        background_light: "#ffffff",
        chart1: "#AED5C3",
        chart2: "#5DA6B5",
        chart3: "#145E80",
        chart4: "#BBC796",
        chart5: "#ECB65A",
        chart6: "#DD720E",
        primary_dark: "rgba(0,0,0,0.8)",
        primary_light: "rgba(255,255,255,0.8)",
        secondary_dark: "rgba(0,0,0,0.8)",
        secondary_light: "rgba(255,255,255,0.8)",
        theme: "rgba(93,166,181,1)",
        hyperlink: "#11a9e2"
    },
    fontHeaderFontId: "robotoslab",
    fontHeaderWeight: 400,
    fontHeaderLetterSpacing: 0.04,
    fontHeaderLineHeight: 1.6,
    fontHeaderScaling: 75,
    fontHeaderTextTransform: "auto",
    fontTitleFontId: "roboto",
    fontTitleWeight: 700,
    fontTitleLetterSpacing: 0.1,
    fontTitleLineHeight: 1.9,
    fontTitleScaling: 70,
    fontTitleTextTransform: "uppercase",
    fontBodyFontId: "robotoslab",
    fontBodyWeight: 400,
    fontBodyLetterSpacing: 0.02,
    fontBodyLineHeight: 2,
    fontBodyScaling: 85,
    fontBodyTextTransform: "auto",
};

module.exports = theme;
