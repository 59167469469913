import React from "reactn";
import { getStaticUrl } from "js/config";

const StockMediaCategoryImage = ({ handleClick, title, urlFragment }) => (
    <div onClick={handleClick}>
        <img src={getStaticUrl(`/images/${urlFragment}`)} />
        <label>{title}</label>
    </div>
);

export default StockMediaCategoryImage;
