import React, { Component } from "reactn";
import styled from "styled-components";
import sanitizeHtml from "sanitize-html";
import moment from "moment";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Avatar,
    Button,
    Icon,
    IconButton,
    Paper,
    FormLabel,
    Checkbox
} from "@material-ui/core";
import ContentEditable from "js/react/components/ContentEditable";
import getLogger, { LogGroup } from "js/core/logger";
import { app } from "js/namespaces";
import { $ } from "js/vendor";
import { themeColors } from "js/react/sharedStyles";
import theme from "js/react/materialThemeOverrides";
import { ds } from "js/core/models/dataService";
import { AssignCollaboratorOrTeammateDropdown } from "js/editor/components/AssignCollaboratorOrTeammateDropdown";
import { ShowDialogAsync, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { ShareDialog } from "js/react/views/PresentationSettings/dialogs/ShareDialog";
import { Key } from "js/core/utilities/keys";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import { trackActivity } from "js/core/utilities/utilities";
import NotificationsService from "js/core/services/notifications";
import InviteEditCollaboratorDialog from "js/react/components/Dialogs/InviteEditCollaboratorDialog";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import CommentMenu from "./CommentMenu";

const logger = getLogger(LogGroup.COMMENTS);

const MAX_COMMENT_TEXT_LENGTH = 500;
const styles = {
    headerTitle: {
        fontWeight: 600,
        letterSpacing: 0,
        fontSize: "14px",
        lineHeight: 1.3,
        wordBreak: "break-word",
        margin: "5px 0",
        ".editor &": {
            color: "#222",
        },
        ".viewer &": {
            color: "#fff",
        },
    },
    headerSubheader: {
        fontWeight: 400,
        letterSpacing: 0,
        fontSize: "11px",
        color: "#A9A9A9"
    }
};

const Container = styled.div`
    position: relative;
    transition: "opacity 500ms";
`;
const CommentCard = styled(Card)`
    margin-bottom: 10px !important;
    border-radius: 6px;
    
    .editor & {
        background: #eee;
    }
    .editor .isNew &,
    .editor .isEditing & {
        background: #fff;
    }
    .viewer & {
        background: #444;
    }
    .viewer .isNew &,
    .viewer .isEditing & {
        background: #666666;
    }
`;
const CommentCardHeader = styled(CardHeader)`
    padding: 0 10px !important;
`;

const CommentAvatar = styled(Avatar)`
    width: 30px;
    height: 30px;
    color: #ccc;
`;
const CommentCardContent = styled(CardContent)`
    padding: 10px !important;
    user-select: text;

    &:last-child {
        padding-bottom: 10px !important;
    }
`;
const CommentCardActions = styled(CardActions)`
    padding: 0 8px !important;
    justify-content: flex-end;
    margin-bottom: 6px !important;
`;
const CommentButton = styled(Button)`
    .editor & {
        color: #666666;
    }
    .viewer & {
        color: #fff;
    }
`;
const PostButton = styled(CommentButton)`
    .editor & {
        color: ${theme.palette.primary[500]} !important;
    }
`;
const ContextBar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 10px;
    margin-top: 5px;

    .editor & {
        color: #11a9e2;
    }
    .viewer & {
        color: #fff;
    }
`;
const SlideTag = styled.div`
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 10px;

    .editor & {
        background: #F7FAFC;
    }
    .viewer & {
        background: #666666;
    }
`;
const EditorTag = styled.span`
    border-radius: 3px;
    padding: 2px 5px;
    background: #222222;
    font-size: 10px;

    .editor & {
        display: none;
    }
`;
const StyledIconButton = styled(IconButton)`
&&&{
    padding: 0;
}`;

const StyledIcon = styled(Icon)`
    color: ${props => props.$byViewer ? "white" : "#11a9e2"};
    font-size: 12px !important;
    padding: 3px !important;
    border-radius: 50%;
    cursor: pointer;
`;
const CommentPaper = styled(Paper)`
    background: #eee;
`;
const CommentFormLabel = styled(FormLabel)`
    &&& {
        color: #666666;
        font-size: 12px;
        text-transform: none;
        word-break: break-word;
    }
`;

const EditContentEditable = styled(ContentEditable)`
    width: 100%;
    height: 100%;
    font-size: 13px;
    font-family: "Source Sans Pro";
    letter-spacing: 0;
    padding: 0 !important;
    margin: 0 !important;
    border: none;
    background: transparent;
    resize: none;
    overflow: hidden;
    outline: none;
    caret-color: ${themeColors.ui_blue};
    user-select: text;
    word-break: break-word;
    
    .editor & {
        color: #222;
    }
    .viewer & {
        color: #fff;
    }

   :empty:before {
        pointer-events: none;
        display: block;
        font-style: italic;
        color: #888;
        opacity: 1;
        .editor & {
            content: "Post comment or @mention to tag a collaborator...";
        }
        .viewer & {
            content: "Post comment in the player...";
        }
    }

    ::selection{
        background: ${themeColors.textSelection};
    }
    ::-moz-selection{
        background: ${themeColors.textSelection};
    }
      
    * {
      &::selection{
        background: ${themeColors.textSelection};
      }
      &::-moz-selection{
        background: ${themeColors.textSelection};
      }
    }
`;

const AssignToSlideDivider = styled.hr`
    margin-top: 15px;
`;

const AssignToSlideContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: -10px;
`;

const AssignToSlideCheckbox = styled(Checkbox)`
    &&& {
        margin-left: -11px !important;
    }
`;

function sanitizeCommentHtmlText(html) {
    return sanitizeHtml(html, {
        allowedTags: ["br", "font"],
        allowedAttributes: {
            "font": ["data-uid", "data-pending-email", "data-inner-text", "class"]
        }
    })
        // By some reason self-closing br tags break contenteditable
        // when there's a blank line
        .replace(/<br\s*\/>/g, "<br>");
}

function stringToHex(string) {
    return Array.from(new TextEncoder().encode(string))
        .map(byte => byte.toString(16).padStart(2, "0"))
        .join("");
}

class Comment extends Component {
    constructor(props) {
        super(props);

        let text = props.text || "";
        // Migrating text to html
        if (text.includes("\n")) {
            text = text.replace(/\n/g, "<br>");
        }

        this.state = {
            text,
            popupAnchorEl: null,
            taggedUserAnchorEl: null,
            search: "",
            isAssignUserToSlideSelected: false,
            isEditing: false,
            isShowingDialog: false
        };

        this.textRef = React.createRef();

        this.currentSelectionPosition = { start: null, end: null };
    }

    componentDidMount() {
        const { isNew, isRead, isMy, markAsRead } = this.props;
        if (!isNew && !isRead && !isMy) {
            // If the comment is not my and user hasn't read it, then mark it as read
            // upon mount
            markAsRead();
        }

        if (isNew) {
            this.textRef.current?.focus();
        }
    }

    componentDidUpdate(prevProps) {
        // Text can be update from outside (by a change in the Firebase)
        // so we have to update the state correspondingly
        const { text } = this.props;
        if (prevProps.text !== text) {
            this.setState({ text });
        }
    }

    /**
     * Returns a LIST of tagged users (including pending ones)
     */
    getTaggedUsers = () => {
        const { collaborators, teammates } = this.props;
        const { text } = this.state;

        const taggedUsers = [];
        [...collaborators, ...teammates]
            .forEach(user => {
                const indexInText = text.indexOf(user.isPending ? `data-pending-email="${user.email}"` : `data-uid="${user.uid}"`);
                if (indexInText < 0) {
                    return;
                }

                if (!taggedUsers.some(taggedUser => taggedUser.uid === user.uid)) {
                    taggedUsers.push({ ...user, indexInText });
                }
            });

        // Sorting so the first tagged user has the first index
        taggedUsers.sort((a, b) => a.indexInText - b.indexInText);

        return taggedUsers;
    }

    /**
     * Returns current selection object and makes sure there's a selection
     * and it belongs to the comment text element
     */
    getSelection = () => {
        const selection = window.getSelection();
        if (selection.type === "None") {
            return null;
        }

        // Check if selection is within the comment
        if (!$(this.textRef.current).has(selection.anchorNode)) {
            return null;
        }

        return selection;
    }

    /**
     * Returns start and end offsets of the current selection in relation
     * to the comment text element
     */
    getSelectionPosition = () => {
        const selectionState = {
            start: null,
            end: null
        };

        const selection = this.getSelection();
        if (!selection) {
            return selectionState;
        }

        const range = selection.getRangeAt(0);
        const selectedLength = range.toString().length;
        const preCaretRange = document.createRange();
        preCaretRange.selectNodeContents(this.textRef.current);
        preCaretRange.setEnd(range.endContainer, range.endOffset);

        selectionState.start = preCaretRange.toString().length - selectedLength;
        selectionState.end = preCaretRange.toString().length;

        return selectionState;
    }

    /**
     * Sets the selection to the supplied position
     */
    setSelectionPosition = (start, end) => {
        const selection = window.getSelection();
        selection.selectAllChildren(this.textRef.current);
        selection.collapseToStart();
        let selectionLength = end - start;
        while (start > 0) {
            selection.modify("move", "right", "character");
            start--;
        }
        while (selectionLength > 0) {
            selection.modify("extend", "right", "character");
            selectionLength--;
        }
    }

    /**
     * Selects the word before caret, returns true if a word was selected
     * WARNING: if the word has leading space or linebreak, it will be selected
     * as well
     */
    selectLastWord = () => {
        const selection = this.getSelection();
        if (selection && selection.isCollapsed) {
            let prevSelectionText;
            let currentSelectionText;
            while (true) {
                prevSelectionText = currentSelectionText;
                selection.modify("extend", "left", "character");
                currentSelectionText = selection.toString();

                // Caret is on a space or a line break
                if (/[\s\n]$/.test(currentSelectionText)) {
                    // Restore the selection
                    selection.collapseToEnd();
                    return false;
                }

                // Reached a space or a line break hence the beginning of the word
                if (/^[\s\n]/.test(currentSelectionText)) {
                    return true;
                }

                // Reached the beginning of the container
                if (currentSelectionText === prevSelectionText) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Returns the selected node (either a font node or the comment text element)
     */
    getSelectedNode = () => {
        const selection = this.getSelection();
        if (!selection) {
            return null;
        }

        let selectedNode = selection.getRangeAt(0).commonAncestorContainer;
        while (selectedNode.tagName.toLowerCase() !== "font" && selectedNode !== this.textRef.current) {
            selectedNode = selectedNode.parentElement;
        }

        return selectedNode;
    }

    assignUserToSlide = async user => {
        if (!user) {
            return false;
        }

        const slide = ds.selection.slide;

        const assignUser = () => {
            if (user.isPending && slide.get("assignedPendingUser") !== user.email) {
                slide.update({ assignedUser: null, assignedPendingUser: user.email, pendingUserAssignedBy: app.user.id });
            } else if (slide.get("assignedUser") !== user.uid) {
                slide.update({ assignedUser: user.uid, assignedPendingUser: null, pendingUserAssignedBy: null });
                // Notifying the assignee
                NotificationsService.notifyOnUserAssignedToSlide(user.uid, ds.selection.presentation.id, slide.id)
                    .catch(err => logger.error(err, "NotificationsService.notifyOnUserAssignedToSlide() failed", { uid: user?.uid, presentationId: ds.selection?.presentation?.id, slideId: slide?.id }));
            }
            const props = {
                slide_id: slide.id,
                email: user.email,
                user_id: user.uid || null
            };
            trackActivity("Collab", "Assign", null, null, props, { audit: true });
        };

        if (slide.isLibrarySlide()) {
            return new Promise(resolve => ShowConfirmationDialog({
                title: "Assigning user will save this Team Slide, are you sure?",
                message: "Changes will be applied to all presentations that use this slide.",
                cancelButtonLabel: "Cancel",
                acceptCallback: () => {
                    assignUser();
                    resolve(true);
                },
                cancelCallback: () => {
                    resolve(false);
                }
            }));
        }

        assignUser();
        return true;
    }

    cancelAddingComment = () => {
        this.setState({
            text: "",
        });
    }

    postComment = async () => {
        const { isAssignUserToSlideSelected, text } = this.state;
        const { post } = this.props;

        const taggedUsers = this.getTaggedUsers();

        if (isAssignUserToSlideSelected) {
            if (!await this.assignUserToSlide(taggedUsers[0])) {
                return;
            }
        }

        const taggedUsersFirebaseObject = {};
        const pendingTaggedUsersFirebaseObject = {};
        taggedUsers.forEach(user => {
            if (user.isPending) {
                pendingTaggedUsersFirebaseObject[stringToHex(user.email)] = true;
            } else {
                taggedUsersFirebaseObject[user.uid] = true;
            }
        });
        post(text, taggedUsersFirebaseObject, pendingTaggedUsersFirebaseObject);
        this.setState({
            isAssignUserToSlideSelected: false,
            text: "",
        });
        if (taggedUsers.length) {
            const props = {
                slide_id: ds.selection.slide.id,
                emails: taggedUsers.map(user => user.email),
                user_ids: taggedUsers.map(user => user.uid || null)
            };
            trackActivity("Collab", "Mention", null, null, props, { audit: true });
        }
    }

    updateComment = () => {
        const { update } = this.props;
        const { text } = this.state;

        const taggedUsers = this.getTaggedUsers();

        const taggedUsersFirebaseObject = {};
        const pendingTaggedUsersFirebaseObject = {};
        taggedUsers.forEach(user => {
            if (user.isPending) {
                pendingTaggedUsersFirebaseObject[stringToHex(user.email)] = true;
            } else {
                taggedUsersFirebaseObject[user.uid] = true;
            }
        });
        update(text, taggedUsersFirebaseObject, pendingTaggedUsersFirebaseObject);

        this.setState({ isEditing: false });
    }

    handleStopEditingComment = () => {
        const { text } = this.props;
        // Reverting text
        this.setState({ isEditing: false, text });
    }

    closeCollaboratorDropdown = () => {
        this.setState({ taggedUserAnchorEl: null });
    }

    showDialog(...args) {
        this.setState({ isShowingDialog: true });

        return ShowDialogAsync(...args)
            .then(dialogResult => {
                this.setState({ isShowingDialog: false });
                return dialogResult;
            });
    }

    handleTagCollaborator = user => {
        if (user.permissionType === "view") {
            this.showDialog(InviteEditCollaboratorDialog, {
                title: `Would you like to give ${user.displayName || user.email} edit permission?`,
                message: `This collaborator currently has view only access. Only collaborators with edit permission can comment on presentations.`,
                user,
                old_value: user.permissionType
            }).then(dialogResult => {
                if (dialogResult === true) {
                    this.closeCollaboratorDropdown();
                    this.updateTextWithTaggedUser(user);
                }
            });
        } else {
            // If collaborator with edit permission then just tag them
            this.closeCollaboratorDropdown();
            this.updateTextWithTaggedUser(user);
        }
    }

    handleTagTeammate = user => {
        this.showDialog(InviteEditCollaboratorDialog, {
            title: `Would you like to invite ${user.displayName || user.email} to collaborate on and edit this presentation?`,
            message: `Only collaborators with edit permission can comment on presentations.`,
            user
        }).then(dialogResult => {
            if (dialogResult === true) {
                this.closeCollaboratorDropdown();
                this.updateTextWithTaggedUser(user);
            }
        });
    }

    /**
     * Adds a font node with the tagged user
     */
    updateTextWithTaggedUser = user => {
        this.setSelectionPosition(this.currentSelectionPosition.start, this.currentSelectionPosition.end);

        if (this.selectLastWord()) {
            const innerText = `@${user.displayName || user.email}`
                // Will collapse multiple spaces to allow correct matching
                // of the font node's innerText with the data-inner-text value
                // (multiple spaces are collapsed when rendered so node.innerText
                // will always have single spaces)
                .replace(/\s+/g, " ");
            // data-inner-text will be used to figuring our if the inner text of the font element is changed
            let html = `<font ${user.isPending ? `data-pending-email=${user.email}` : `data-uid="${user.uid}"`} data-inner-text="${innerText}" class="comment-text-hilited">${innerText}</font>&nbsp;`;
            const selectionText = this.getSelection().toString();
            // If starts with a line break or space, add it to the new html
            if (!selectionText.startsWith("@")) {
                html = `${selectionText[0]}${html}`;
            }
            document.execCommand("insertHTML", false, html);
        }
    }

    handleTagSelect = item => {
        let {
            collaborators,
            teammates,
        } = this.props;
        let user = collaborators.find(x => x.email === item.email);
        if (user) {
            this.handleTagCollaborator(user);
            return;
        }
        user = teammates.find(x => x.uid === item.id);
        if (!user) {
            user = {
                uid: item.uid || item.id,
                email: item.email,
                isPending: true,
            };
        }
        this.handleTagTeammate(user);
    }

    handleAddCollaborator = async (email = "", onInviteSuccess = null) => {
        if (await this.showDialog(ShareDialog, {
            emails: [this.state.search],
            presentation: ds.selection.presentation,
            selectedPanel: "collaborate",
            location: "mention"
        })) {
            onInviteSuccess && onInviteSuccess();
        }
    }

    handleTextChange = event => {
        const text = sanitizeCommentHtmlText(event.target.value);

        const newState = {
            text,
            taggedUserAnchorEl: null,
            search: ""
        };

        // Will just strip the text if it exceeds the max length after the change,
        // stripped/malformed html nodes will be removed by sanitizeCommentHtmlText()
        if (text.length > MAX_COMMENT_TEXT_LENGTH) {
            newState.text = sanitizeCommentHtmlText(text.slice(0, MAX_COMMENT_TEXT_LENGTH));
            this.setState(newState);
            return;
        }

        // This logic removes font tags for tagged users which text was changed
        // i.e. when you backspace to a tagged user
        let shouldUpdateText = false;
        // This callback will be run after the setState() call
        let postSetStateCallback = () => { };
        const { start, end } = this.getSelectionPosition();
        Array.from(this.textRef.current.querySelectorAll("font"))
            .forEach(fontNode => {
                // data-inner-text contains the desired value of the element's inner text,
                // if it doesn't match => it was changed
                if (fontNode.getAttribute("data-inner-text") !== fontNode.innerText) {
                    fontNode.replaceWith(fontNode.innerText);
                    shouldUpdateText = true;
                }
            });
        if (shouldUpdateText) {
            if (start && end) {
                postSetStateCallback = () => {
                    // Will be restoring the selection state after refresh
                    this.setSelectionPosition(start, end);
                };
            }

            newState.text = this.textRef.current.innerHTML;
        }

        // Don't bother with searching for collaborators if we're in viewer mode
        if (!this.props.byViewer) {
            if (this.selectLastWord()) {
                const selection = this.getSelection();
                // trim() because selectLastWord() also selects the leading space or linebreak
                const selectionText = selection.toString().trim();
                // Restore the selection
                selection.collapseToEnd();

                if (selectionText.startsWith("@")) {
                    newState.taggedUserAnchorEl = this.textRef.current;
                    // Will be using for restoring selection position
                    this.currentSelectionPosition = { start, end };
                    if (selectionText.length > 1) {
                        // slice() to remove the leading @
                        newState.search = selectionText.slice(1).toLowerCase();
                    }
                }
            }
        }

        this.setState(newState, postSetStateCallback);
    }

    handleTextKeyDown = event => {
        const { isNew } = this.props;
        const { text, taggedUserAnchorEl } = this.state;

        // Prevent further input if the text exceeds the maximum length
        if (text.length >= MAX_COMMENT_TEXT_LENGTH && !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(event.key)) {
            event.preventDefault();
        }

        if (!taggedUserAnchorEl && event.which === Key.ENTER && !event.shiftKey) {
            event.preventDefault();
            if (isNew) {
                this.postComment();
            } else if (text.length > 0) {
                this.updateComment();
            }
        }
    }

    handleClickAssignUser = () => {
        const setIsAssignUserToSlideSelected = () => {
            const { isAssignUserToSlideSelected } = this.state;
            this.setState({ isAssignUserToSlideSelected: !isAssignUserToSlideSelected });
        };

        if (PresentationEditorController.getCurrentSlide().isLibrarySlide()) {
            ShowErrorDialog({ title: "Unable to assign user to this slide", message: "This is shared team slide and can not be assigned to a user" });
        } else {
            setIsAssignUserToSlideSelected();
        }
    }

    render() {
        const {
            classes,
            isNew,
            isMy,
            byViewer,
            slideIndex,
            canEditPresentation,
            remove,
            author,
            createdAt,
            assignedUser,
            goToSlide,
            canEdit,
            isPresentationOwner
        } = this.props;
        const {
            search,
            text,
            taggedUserAnchorEl,
            isAssignUserToSlideSelected,
            isEditing,
            isShowingDialog
        } = this.state;

        const taggedUsers = this.getTaggedUsers();
        const assignToUser = taggedUsers[0];

        const showAssignCheck = (
            !isEditing &&
            assignToUser &&
            (
                (
                    assignToUser.uid &&
                    assignedUser?.uid !== assignToUser.uid
                ) ||
                (
                    assignToUser.email &&
                    assignedUser?.email !== assignToUser.email
                )
            )
        );

        const getAvatar = () => (
            <CommentAvatar
                src={author.photoURL}
                alt={author.displayName || author.email}
            >
                {
                    !author.photoURL &&
                    <Icon>person</Icon>
                }
            </CommentAvatar>
        );

        const hasMenu = isNew || (byViewer && canEditPresentation) || (isMy && !isEditing);

        const presentation = ds.selection.presentation;

        const menuItems = [
            {
                condition: hasMenu && isNew,
                onClick: this.cancelAddingComment,
                text: "Cancel adding comment",
            },
            {
                condition: hasMenu && (!isNew && isMy && !isEditing),
                onClick: () => this.setState({ isEditing: true }),
                text: "Edit comment",
                testId: "edit-comment-menu-item"
            },
            {
                // If 'hasMenu' is true, the menu will be displayed if the comment is not new and the user can edit the presentation,
                // or if the comment was made by the viewer and the viewer is the "owner" of the comment.
                // If 'hasMenu' is false, the menu will be displayed if the user is the owner of the presentation.
                condition: hasMenu ? (!isNew && canEditPresentation || byViewer && isMy) : isPresentationOwner,
                onClick: remove,
                text: "Delete comment",
                testId: "delete-comment-menu-item"
            },

        ].filter(item => item.condition);

        return (
            <Container
                className={classNames({
                    [`comment`]: true,
                    [`isNew`]: isNew,
                    [`isEditing`]: !isNew && isEditing,
                    [`isReadonly`]: !isNew && !isEditing,
                })}
            >
                <CommentCard
                    onClick={() => goToSlide && goToSlide(slideIndex)}
                >

                    <CommentMenu
                        hasMenu={hasMenu}
                        isPresentationOwner={isPresentationOwner}
                        text={text}
                        slideIndex={slideIndex}
                        menuItems={menuItems}
                    />
                    {
                        !(isNew && text.length === 0) &&
                        <CommentCardHeader
                            avatar={getAvatar()}
                            title={(
                                <>
                                    <span>{author.displayName || author.email} </span>
                                    {isMy && <span>(You) </span>}
                                    {(author.isOwner || author.isCollabEditor) && <EditorTag>Editor</EditorTag>}
                                </>
                            )}
                            titleTypographyProps={{ className: classes.headerTitle }}
                            subheaderTypographyProps={{ className: classes.headerSubheader }}
                            subheader={moment(createdAt).fromNow()}
                        />
                    }
                    {
                        (isEditing || isNew) &&
                        <>
                            <CommentCardContent>
                                <EditContentEditable
                                    onFocus={() => PresentationEditorController.selectionLayerController.setSelectedElements([])}
                                    className="commentText"
                                    innerRef={this.textRef}
                                    html={text}
                                    onKeyDown={this.handleTextKeyDown}
                                    onChange={this.handleTextChange}
                                    style={{ cursor: "text" }}
                                    disabled={!canEdit}
                                />
                                <AssignCollaboratorOrTeammateDropdown
                                    sourceName="Comment"
                                    anchorEl={taggedUserAnchorEl}
                                    presentation={presentation}
                                    filter={search}
                                    showAssignedUser
                                    showInviteCollaboratorButton
                                    onSelect={this.handleTagSelect}
                                    onAddCollaboratorClick={this.handleAddCollaborator}
                                    onClose={() => {
                                        if (!isShowingDialog) this.closeCollaboratorDropdown();
                                    }}
                                />
                                {
                                    showAssignCheck &&
                                    <>
                                        <AssignToSlideDivider />
                                        <AssignToSlideContainer>
                                            <AssignToSlideCheckbox
                                                color="primary"
                                                checked={isAssignUserToSlideSelected}
                                                onClick={this.handleClickAssignUser}
                                            />
                                            <CommentFormLabel>
                                                Assign slide to {assignToUser.uid === app.user.id ? "me" : (assignToUser.displayName || assignToUser.email)}
                                            </CommentFormLabel>
                                        </AssignToSlideContainer>
                                    </>
                                }
                            </CommentCardContent>
                            {
                                !(isNew && text.length === 0) &&
                                <CommentCardActions>
                                    {
                                        isNew &&
                                        <>
                                            <CommentButton
                                                variant="text"
                                                size="small"
                                                onClick={this.cancelAddingComment}
                                            >Cancel</CommentButton>
                                            <PostButton
                                                variant="text"
                                                size="small"
                                                disabled={text.length === 0}
                                                onClick={this.postComment}
                                            >Post</PostButton>
                                        </>
                                    }
                                    {
                                        !isNew && isEditing &&
                                        <>
                                            <CommentButton
                                                variant="text"
                                                size="small"
                                                onClick={this.handleStopEditingComment}
                                            >Cancel</CommentButton>
                                            <PostButton
                                                variant="text"
                                                size="small"
                                                disabled={text.length === 0}
                                                onClick={this.updateComment}
                                            >Update</PostButton>
                                        </>
                                    }
                                </CommentCardActions>
                            }
                        </>
                    }
                    {
                        !isEditing && !isNew &&
                        <CommentCardContent>
                            <EditContentEditable
                                className="commentText"
                                innerRef={this.textRef}
                                html={text}
                                disabled={true}
                                style={{ cursor: "default" }}
                            />
                        </CommentCardContent>
                    }
                </CommentCard>
            </Container >
        );
    }
}

export default withStyles(styles)(Comment);
