import moment from "moment";

import { TaskState } from "common/constants";
import Api from "js/core/api";
import db from "js/db";
import { app } from "js/namespaces";
import { uploadBlobToSignedUrl } from "js/core/utilities/uploadBlobToSignedUrl";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.TASK);

/**
 * Subscribes the supplied callback to changes on the supplied task ref
 */
function subscribeForTaskChanges(taskRef, onTaskChanged) {
    taskRef.on("value", snap => {
        const updatedTask = snap.val();

        // Unsubscribe if the task is finished, failed or cancelled
        if (updatedTask.state === TaskState.FINISHED || updatedTask.state === TaskState.ERROR || updatedTask.state === TaskState.CANCELLED) {
            taskRef.off();
        }

        onTaskChanged(updatedTask);
    });
}

/**
 * Creates a new task for the supplied file from the tamp assets storage,
 * the "onTaskChanged" callback is invoked with the updated task object upon each task update.
 * The task goes throught these states: "preparing", "processing", "finished" or "error".
 * Please note that when the task is in "preparing" or "processing" state it has "stateProgressPercents"
 * property that represents the progress of the current state in percents.
 */
export async function createTask(taskType, onTaskChanged, taskProperties = {}) {
    const newTaskRef = db("tasks").child(app.user.id).push();
    const taskId = newTaskRef.key;

    const task = {
        id: taskId,
        createdAt: moment().valueOf(),
        type: taskType,
        state: TaskState.PREPARING,
        stateProgressPercents: 0,
        ...taskProperties
    };
    await newTaskRef.set(task);

    subscribeForTaskChanges(newTaskRef, onTaskChanged);

    await Api.processTask.post({ taskType: taskType, taskId });

    return {
        ...task,
        cancel: () => newTaskRef.update({ state: TaskState.CANCELLED })
    };
}

/**
 * Creates a new task for the supplied file (blob), the promise is resolved
 * when the task is created, the "onTaskChanged" callback is invoked with the updated task object upon each task update.
 * The task goes throught these states: "preparing", "processing", "finished" or "error".
 * Please note that when the task is in "preparing" or "processing" state it has "stateProgressPercents"
 * property that represents the progress of the current state in percents.
 */
export async function uploadFileAndCreateTask(fileBlob, taskType, onTaskChanged, taskProperties = {}, taskId = null) {
    let newTaskRef;
    if (taskId) {
        newTaskRef = db("tasks").child(app.user.id).child(taskId);
    } else {
        newTaskRef = db("tasks").child(app.user.id).push();
        taskId = newTaskRef.key;
    }

    // Requesting a write url
    const { writeUrl, fileName: rawFileName } = await Api.tempAssets.post();

    let isCancelled = false;

    const task = {
        id: taskId,
        createdAt: moment().valueOf(),
        type: taskType,
        state: TaskState.PREPARING,
        stateProgressPercents: 0,
        rawFileName,
        ...taskProperties
    };
    return new Promise((resolve, reject) => {
        newTaskRef.set(task)
            .then(() => {
                // Resolving the promise immediately after the task is created
                // all future updates will be submitted via the onTaskChanged callback
                resolve({
                    ...task,
                    cancel: async () => {
                        await newTaskRef.update({ state: TaskState.CANCELLED });
                        isCancelled = true;
                    }
                });

                subscribeForTaskChanges(newTaskRef, onTaskChanged);

                uploadBlobToSignedUrl(
                    fileBlob,
                    writeUrl,
                    progressPercents => {
                        if (!isCancelled) {
                            newTaskRef.update({ stateProgressPercents: progressPercents })
                                .catch(err => {
                                    logger.error(err, "[TasksService] uploadFileAndCreateTask() newTaskRef.update() failed", { taskType, taskId });
                                });
                        }
                    }
                )
                    .then(() => {
                        if (isCancelled) {
                            return newTaskRef.remove();
                        }

                        return Api.processTask.post({ taskType, taskId });
                    })
                    .catch(err => {
                        logger.error(err, "[TasksService] uploadFileAndCreateTask() Api.processTask.post() failed", { taskType, taskId });

                        if (!isCancelled) {
                            newTaskRef.update({ state: TaskState.ERROR, errorMessage: "Error uploading file" })
                                .then(newTaskRef.remove())
                                .catch(err => {
                                    logger.error(err, "[TasksService] uploadFileAndCreateTask() newTaskRef.update() failed", { taskType, taskId });
                                });
                        }
                    });
            })
            .catch(err => reject(err));
    });
}
