import React, { Component } from "react";
import styled from "styled-components";

import { Icon, IconButton } from "@material-ui/core";
import { PresentationLibraryFilter, PresentationLibraryPresentation } from "js/controllers/PresentationLibraryController";
import getLogger from "js/core/logger";
import { getPresentation } from "js/core/models/presentation";
import { getPresentationContextMenuItems } from "js/editor/PresentationLibrary/PresentationContextMenu";
import { app } from "js/namespaces";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { IconMenu } from "js/react/components/UiComponents";
import { themeColors } from "js/react/sharedStyles";
import {
    MultiSelectThumbnailGrid,
    Thumbnail,
    ThumbnailContainer
} from "js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import { ShareDialog } from "js/react/views/PresentationSettings/dialogs/ShareDialog";
import { _ } from "js/vendor";

const logger = getLogger();

const ThumbnailGridContainer = styled.div`
    background: white;
    padding: 20px;
    margin: 0px 0px 20px;
    overflow: hidden;

    .dark-mode & {
        background: #333;
    }
`;

interface PresentationLibraryGridProps {
    items: PresentationLibraryPresentation[];
    onSelectedPresentations: (presentations: PresentationLibraryPresentation[]) => void;
    onDoubleClick: (presentation: PresentationLibraryPresentation) => void;
    onStartDragItems: (items: PresentationLibraryPresentation[]) => void;
    onDropDragItems: (items: PresentationLibraryPresentation[]) => void;
    readOnly: boolean;
    filter: PresentationLibraryFilter;
}

export class PresentationLibraryGrid extends Component<PresentationLibraryGridProps> {
    render() {
        const { items, onSelectedPresentations, onDoubleClick, onStartDragItems, onDropDragItems, filter, readOnly } = this.props;

        return (
            <ThumbnailGridContainer>
                <MultiSelectThumbnailGrid
                    items={items}
                    columns={3}
                    colGap={30}
                    rowGap={30}
                    readOnly={readOnly}
                    allowDragging={!readOnly}
                    allowMultiSelect={!readOnly}
                    thumbnailClass={PresentationLibraryPreviewThumbnail}
                    thumbnailProps={{ filter }}
                    onItemSelected={items => onSelectedPresentations(items)}
                    onDoubleClick={onDoubleClick}
                    onStartDragItems={onStartDragItems}
                    onDropDragItems={onDropDragItems}
                    renderContextMenuItems={selectedPresentations => getPresentationContextMenuItems({ selectedPresentations, currentFilter: filter })}
                />
            </ThumbnailGridContainer>
        );
    }
}

const ThumbnailBar = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    label {
        font-size: 14px;
        letter-spacing: 0px;
        color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        flex-grow: 2;
        text-transform: none;
        margin: 0px;
    }
    button {
        padding: 2px;
    }

    .dark-mode & {
        label {
            color: #ddd;
        }
    }
`;

const PresentationThumbnailContainer = styled(ThumbnailContainer)`
`;

const SharedPresentationIcon = styled.div`
    position:absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    z-index: 100;
    border-radius: 50%;
    background: ${themeColors.ui_blue};
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        color: white;
        font-size: 20px;
    }
`;

const PlaceholderContainer = styled.div`
    width: 100%;
`;

const PlaceholderImage = styled.div`
    width: 100%;
    aspect-ratio: 16/9;
`;

const PlaceholderLabel = styled.div`
    width: 100%;
    height: 24px;
    margin-top: 20px;
`;

interface PresentationLibraryPreviewThumbnailProps {
    item: PresentationLibraryPresentation;
    onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
    onDoubleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    onContextMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
    selected: boolean;
    readOnly: boolean;
    allSelected: PresentationLibraryPresentation[];
    filter: PresentationLibraryFilter
}

interface PresentationLibraryPreviewThumbnailState {
    thumbnailUrl: string;
    visible: boolean;
}

export class PresentationLibraryPreviewThumbnail extends Component<PresentationLibraryPreviewThumbnailProps, PresentationLibraryPreviewThumbnailState> {
    ref: React.RefObject<HTMLDivElement>;
    observer: IntersectionObserver;

    constructor(props: PresentationLibraryPreviewThumbnailProps) {
        super(props);

        this.state = {
            thumbnailUrl: null,
            visible: false,
        };

        this.ref = React.createRef();
    }

    fetchThumbnailUrl() {
        const { item: { getThumbnailUrl } } = this.props;
        const { thumbnailUrl: currentThumbnailUrl } = this.state;

        this.setState({ thumbnailUrl: null });

        getThumbnailUrl()
            .then(thumbnailUrl => this.setState({ thumbnailUrl }))
            .catch(err => {
                logger.error(err, "[PresentationLibraryPreviewThumbnail] presentationModel.getThumbnailUrl() failed");

                if (currentThumbnailUrl) {
                    // Restore previous thumbnail
                    this.setState({ thumbnailUrl: currentThumbnailUrl });
                }
            });
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting === true) {
                if (_.isEmpty(this.state.thumbnailUrl)) {
                    this.fetchThumbnailUrl();
                }
                if (!this.state.visible) {
                    this.setState({ visible: true });
                }
            } else {
                if (this.state.visible) {
                    this.setState({ visible: false });
                }
            }
        });

        this.observer.observe(this.ref.current);
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item.firstSlideModifiedAt !== this.props.item.firstSlideModifiedAt || prevProps.item.slideIds[0] !== this.props.item.slideIds[0]) {
            this.fetchThumbnailUrl();
        }
    }

    handleSharePresentation = async () => {
        const { item } = this.props;

        const presentation = await getPresentation(item.id, { permission: "write", autoSync: true });
        ShowDialog(ShareDialog, { presentation, onClose: () => presentation.disconnect() });
    }

    render() {
        const { item, onMouseDown, onDoubleClick, onContextMenu, selected, readOnly, allSelected, filter } = this.props;
        const { thumbnailUrl, visible } = this.state;

        return (
            <PresentationThumbnailContainer
                data-item-id={item.id}
                className="thumbnail-grid-item"
                onMouseDown={onMouseDown}
                onDoubleClick={onDoubleClick}
                onContextMenu={onContextMenu}
                ref={this.ref}
            >
                {!visible && <PlaceholderContainer><PlaceholderImage /><PlaceholderLabel /></PlaceholderContainer>}
                {visible && <>
                    <Thumbnail
                        selected={selected}
                        url={thumbnailUrl}
                        showSpinner={true}
                        style={{ border: "solid 1px #ccc", boxShadow: "0px 0px 20px rgba(0,0,0,.05)" }}
                    >
                        {!item.permissions.owner && item.permissions.write && <SharedPresentationIcon><Icon>people</Icon></SharedPresentationIcon>}
                        {!item.permissions.owner && !item.permissions.write && <SharedPresentationIcon><Icon>play_arrow</Icon></SharedPresentationIcon>}
                    </Thumbnail>

                    <ThumbnailBar>
                        <label className="presentation_item_name">{item.name}</label>
                        {
                            !app.isConstrained &&
                            !readOnly &&
                            <IconMenu>
                                {getPresentationContextMenuItems({ selectedPresentations: allSelected, currentFilter: filter })}
                            </IconMenu>
                        }
                        {
                            app.isMobileOrTablet &&
                            !readOnly &&
                            item.permissions.write &&
                            <IconButton
                                className="text-blue"
                                onMouseDown={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                onMouseUp={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                onDoubleClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    this.handleSharePresentation();
                                }}
                            >
                                <Icon>share</Icon>
                            </IconButton>
                        }
                    </ThumbnailBar>
                </>}
            </PresentationThumbnailContainer>
        );
    }
}
