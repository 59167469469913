import { _, Backbone } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import Adapter from "./adapter";
import { computeChangeSet } from "common/utils/changeset";

const logger = getLogger(LogGroup.STORAGE);

function toUpdateObject(models) {
    const update = {};
    models.forEach(model => {
        let attrs;
        if (model instanceof Backbone.Model) {
            attrs = Object.assign({}, model.attributes);
        } else {
            attrs = Object.assign({}, model);
        }
        delete attrs.id;
        update[model.id] = attrs;
    });
    return update;
}

function toRemoveObject(models) {
    const remove = {};
    models.forEach(model => {
        remove[model.id] = null;
    });
    return remove;
}

const StorageCollection = Backbone.Collection.extend({

    query: null,

    getQuery: function() {
        return this.query;
    },

    createAdapter(options) {
        return Adapter.create(options);
    },

    constructor: function(models, options) {
        Backbone.Collection.call(this, null, options);

        options = Object.assign({ autoLoad: true }, options);

        this.adapter = options.adapter || this.createAdapter({
            autoSync: options.autoSync
        });
        this.query = options.query || this.getQuery();

        this._initialModels = models;

        if (options.autoLoad) {
            this.load();
        }
    },

    load: function() {
        if (!this.loadPromise) {
            //normalize models to have ids.
            let models = null;
            if (this._initialModels) {
                models = this.normalizeModels(this._initialModels);
                this.set(models, { silent: true });
                this.loaded = true;
            } else {
                this.loaded = false;
            }

            this.loadPromise = new Promise((resolve, reject) => {
                this.once("load", () => {
                    this.off("loaderror");
                    resolve(this);
                });
                this.once("loaderror", err => {
                    this.off("load");
                    reject(err);
                });
            });

            let data = null;
            if (models) {
                data = {};
                this.each(model => {
                    const attrs = Object.assign({}, model.attributes);
                    delete attrs.id;
                    data[model.id] = attrs;
                });
            }

            this.adapter.connect({
                query: this.query,
                data: data,
                onRemoteChange: this.handleRemoteChange.bind(this),
                onRemoteError: this.handleRemoteError.bind(this)

            });

            if (this.loaded) {
                this.trigger("load", this);
            }
        }
        return this.loadPromise;
    },

    handleRemoteError: function(err) {
        if (!this.loaded) {
            this.trigger("loaderror", err);
        } else {
            logger.error(err, "StorageCollection handleRemoteError()");
        }
    },

    handleRemoteChange: function(type, data) {
        switch (type) {
            case Adapter.TYPE.initialize:
                this.set(Object.keys(data).filter(key => {
                    return data[key] != null && key !== "modifiedAt" && key !== "_changeId";
                }).map(key => {
                    return Object.assign({}, data[key], { id: key });
                }), {
                    initialize: true
                });
                if (!this.loaded) {
                    this.loaded = true;
                    this.trigger("load", this);
                }
                break;
            case Adapter.TYPE.replace:
                this.set(Object.keys(data).filter(key => {
                    return data[key] != null;
                }).map(key => {
                    return Object.assign({}, data[key], { id: key });
                }), {
                    remoteChange: true
                });
                break;
            case Adapter.TYPE.update:
                Object.keys(data).forEach(key=>{
                    const model = data[key];
                    if (!model) {
                        this.remove(key, {
                            remoteChange: true
                        });
                    } else {
                        this.add(Object.assign({}, model, { id: key }), {
                            remoteChange: true,
                            merge: true
                        });
                    }
                });
                break;
            case Adapter.TYPE.remove:
                this.set([], { remoteChange: true });
                break;
            default:
                logger.error(new Error(`Unsupported type ${type} from adapter ${this.adapter.toString()}`), "handleRemoteChange() failed");
        }
    },

    add: function(models, options) {
        options = options || {};
        const singular = !_.isArray(models);
        models = this.normalizeModels(models);
        if (!options.initialized && !options.remoteChange) {
            const update = toUpdateObject(models);
            const original = toUpdateObject(this.models);
            const changeSet = computeChangeSet(original, update);
            if (changeSet.hasUpdates) {
                this.adapter.update(Adapter.TYPE.update, changeSet.update, changeSet.original);
            }
        }
        const results = Backbone.Collection.prototype.add.call(this, models, options);
        return singular ? results[0] : results;
    },

    //Note - the change event only has a model and an option parameter, hence this does not need to figure out what
    //parameter is the options parameter.
    _onModelEvent: function(event, model, options) {
        if (model && event === "change") {
            const changeSet = computeChangeSet(model.previousAttributes(), model.attributes, true);
            if (!options || !options.remoteChange) {
                this.adapter.update(Adapter.TYPE.update, { [model.id]: changeSet.update }, { [model.id]: changeSet.original });
            }
        }
        return Backbone.Collection.prototype._onModelEvent.apply(this, arguments);
    },

    remove: function(models, options) {
        options = options || {};
        const singular = !_.isArray(models);
        models = this.normalizeModels(models);
        if (!options.initialized && !options.remoteChange) {
            const update = toRemoveObject(models);
            const original = toUpdateObject(this.models);
            const changeSet = computeChangeSet(original, update);
            if (changeSet.hasUpdates) {
                this.adapter.update(Adapter.TYPE.update, changeSet.update, changeSet.original);
            }
        }
        const results = Backbone.Collection.prototype.remove.call(this, models, options);
        return singular ? results[0] : results;
    },

    disconnect: function() {
        this.adapter.disconnect();
        this.off();
    },

    normalizeModels: function(models) {
        if (!Array.isArray(models)) {
            models = [models];
        }
        return models.map(model => {
            if (typeof (model) === "string") {
                model = this.get(model);
            }
            if (model instanceof Backbone.Model && !model.id) {
                model.set("id", this.adapter.createUid(this.query.root));
            } else if (!model.id) {
                if (!(model instanceof Backbone.Model)) {
                    model = Object.assign({}, model);
                }
                model.id = this.adapter.createUid(this.query.root);
            }
            return model;
        });
    }

});

StorageCollection.fetch = function(options) {
    const collection = new StorageCollection(null, Object.assign({}, options, { autoSync: false }));
    return collection.load().catch(err => {
        return Promise.reject(err);
    });
};

StorageCollection.fetchData = function(options) {
    return StorageCollection.fetch(options).then(collection => {
        collection.disconnect();
        return collection.map(model => {
            return Object.assign({ id: model.id }, model.attributes);
        });
    });
};

export default StorageCollection;
