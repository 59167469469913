import { getFeatures, FeatureType, BrandingPermission } from "common/features";
import { ds } from "./dataService";
import Api from "../api";

export { FeatureType };

export async function getUserOrgPermissions(orgId) {
    await ds.teams.loadModels();
    const team = ds.teams.defaultTeamForOrg(orgId);

    // Migrate `isSharedThemeRequired` to `brandingPermissions.canAccessCustomThemes`
    if (team.get("isSharedThemeRequired")) {
        await team.update({
            isSharedThemeRequired: null,
            workspaceSettings: {
                brandingPermissions: {
                    customThemes: BrandingPermission.NONE,
                },
            }
        });
    }

    const role = team.getUserRole();
    const isEnterprise = await Api.orgIsEnterprise.get({ orgId });
    const workspaceSettings = team.get("workspaceSettings") || {};

    const permissions = getFeatures(role, isEnterprise, workspaceSettings);

    return permissions;
}

export async function getUserPlanPermissions() {
    await ds.userPlans.loadModels();
    let role = ds.userPlans.getPersonalPlan();
    let workspaceSettings = {};
    const permissions = getFeatures(role, false, workspaceSettings);
    return permissions;
}
