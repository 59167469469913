import { $, Backbone } from "js/vendor";
import getLocalStorage from "js/core/utilities/localStorage";

import "css/profiler-view.scss";

const localStorage = getLocalStorage();

let enabled = localStorage.getItem("profiler") === "true";
let debugMode = localStorage.getItem("profilerDebug") === "true";

window.baiProfiler = {
    isEnabled() {
        return enabled;
    },
    enable() {
        localStorage.setItem("profiler", "true");
    },
    disable() {
        localStorage.removeItem("profiler");
    },
    enableDebugMode() {
        localStorage.setItem("profilerDebug", "true");
    },
    disableDebugMode() {
        localStorage.removeItem("profilerDebug");
    }
};

class Timer {
    constructor(options) {
        this.id = 0;
        this.name = options.name;
        this.type = options.type || 0;
        this.warnCount = options.warnCount || Number.POSITIVE_INFINITY;
        this.warnTime = options.warnTime || Number.POSITIVE_INFINITY;
        this.startTimes = {};
        this.profiles = {};
        this.startTime = 0;
        this.endTime = 0;
        this.enabled = enabled;
        this.callCount = 0;
        this.finishedCount = 0;
        this.elapsedTimes = [];
        this.sorted = false;
        this.showCount = options.showCount !== false;
    }

    profile(id, func) {
        const pid = this.start(id);
        const result = func();
        if (result.then) {
            result.then(() => {
                this.end(pid);
            });
        } else {
            this.end(pid);
        }
        return result;
    }

    start(name) {
        if (!this.enabled) {
            return 0;
        }
        const startTime = window.performance.now();
        const id = this.id++;

        this.startTimes[id] = {
            startTime: startTime,
            name: name
        };
        if (this.startTime === 0) {
            this.startTime = startTime;
        }
        this.callCount++;
        if (debugMode) {
            // eslint-disable-next-line no-console
            console.log("PROFILER START", this.name, name, "Start time", startTime);
        }
        return id;
    }

    end(id) {
        if (!this.enabled) {
            return;
        }

        this.finishedCount++;

        const startTime = this.startTimes[id];
        if (!startTime) {
            return;
        }

        let calls = this.profiles[startTime.name];

        if (!calls) {
            calls = [];
            this.profiles[startTime.name] = calls;
        }

        const endTime = window.performance.now();
        const elapsedTime = endTime - startTime.startTime;
        this.endTime = Math.max(this.endTime, endTime);
        calls.push({
            startTime: startTime.startTime,
            endTime: endTime,
            elapsedTime: elapsedTime
        });
        if (debugMode) {
            // eslint-disable-next-line no-console
            console.log("PROFILER END", this.name, startTime.name, "Start time", startTime.startTime, "End time", endTime, "Elapsed time", elapsedTime);
        }
        this.elapsedTimes.push(elapsedTime);
        this.sorted = false;
    }

    get stable() {
        return this.callCount === this.finishedCount;
    }

    reset() {
        if (!this.enabled) {
            return;
        }

        this.startTimes = {};
        this.profiles = {};
        this.startTime = 0;
        this.endTime = 0;
        this.callCount = 0;
        this.finishedCount = 0;
        this.elapsedTimes = [];
        this.sorted = false;
    }

    get elapsedTime() {
        return this.endTime - this.startTime;
    }

    get p50() {
        return this.getMedianTime(50);
    }

    getMedianTime(percentile) {
        if (!this.sorted) {
            this.elapsedTimes.sort((a, b) => a - b);
            this.sorted = true;
        }
        const medianIndex = Math.floor(this.elapsedTimes.length * percentile / 100);
        return this.elapsedTimes[medianIndex] || 0;
    }
}

const timers = [];

class Sampler {
    constructor(minTime, callback) {
        this.minTime = minTime;
        this.startTime = 0;
        this.samples = 0;
        this.callback = callback;
    }

    start() {
        this.startTime = (new Date()).getTime();
        this.callback(0, 0, 0);
        this.samples = 0;
    }

    tick() {
        const now = (new Date()).getTime();
        const elapsedTime = now - this.startTime;
        if (elapsedTime > this.minTime) {
            this.callback(this.samples / elapsedTime, this.samples, elapsedTime);
            this.samples = 0;
            this.startTime = now;
        }
        this.samples++;
    }
}

const ProfilerView = Backbone.View.extend({
    initialize: function() {
        this.enabled = enabled;
        this.timeTimers = timers.filter(profiler => {
            return profiler.type === Profiler.TIMER;
        });
        this.loadTimers = timers.filter(profiler => {
            return profiler.type === Profiler.AVG_LOAD_TIME;
        });
        this.funcTimers = timers.filter(profiler => {
            return profiler.type === Profiler.FUNC_PROFILE;
        });

        this.rendered = false;
        this.stats = {};
        this.prevHeap = 0;

        this.samplers = [
            // FPS/Mem sampler
            new Sampler(1000, rate => {
                this.updateStat("FPS", (rate * 1000).toFixed(2), rate * 1000 < 20);
                const memory = window.performance.memory ? window.performance.memory.usedJSHeapSize : 0;
                const heap = (memory / 1048576);
                if (this.prevHeap !== heap) {
                    this.updateStat("Heap", heap.toFixed(2) + "mb", heap > 150, true);
                    this.prevHeap = heap;
                }
            }),
            // Timers
            new Sampler(500, () => {
                this.timeTimers.forEach(timer => {
                    if (timer.stable && timer.callCount !== 0) {
                        this.updateStat(
                            timer.name + " time",
                            timer.elapsedTime.toFixed(2) + "ms",
                            timer.warnTime < timer.elapsedTime,
                            true
                        );
                        timer.reset();
                    }
                });
            }),
            // Load times
            new Sampler(100, () => {
                this.loadTimers.forEach(timer => {
                    if (timer.stable && timer.callCount !== 0) {
                        this.updateStat(
                            timer.name + " p50",
                            timer.p50.toFixed(2) + "ms",
                            timer.warnTime < timer.p50,
                            true
                        );
                        this.updateStat(
                            timer.name + " loads",
                            timer.callCount,
                            timer.warnCount < timer.callCount,
                            true
                        );
                        timer.reset();
                    }
                });
            }),
            // Function times
            new Sampler(200, () => {
                this.funcTimers.forEach(timer => {
                    if (timer.stable && timer.callCount !== 0) {
                        this.updateStat(
                            timer.name + " time",
                            timer.elapsedTime.toFixed(2) + "ms",
                            timer.warnTime < timer.elapsedTime,
                            true
                        );
                        this.updateStat(
                            timer.name + " calls",
                            timer.callCount,
                            timer.warnCount < timer.callCount,
                            true
                        );
                        timer.reset();
                    }
                });
            })
        ];
    },
    render: function() {
        if (this.rendered) {
            return this;
        }

        if (this.enabled) {
            this.rendered = true;
            this.addStat("FPS");
            this.addStat("Heap");
            this.timeTimers.forEach(timer => {
                this.addStat(timer.name + " time");
            });
            this.loadTimers.forEach(timer => {
                this.addStat(timer.name + " p50");
                if (timer.showCount) {
                    this.addStat(timer.name + " loads");
                }
            });
            this.funcTimers.forEach(timer => {
                this.addStat(timer.name + " time");
                if (timer.showCount) {
                    this.addStat(timer.name + " calls");
                }
            });
            this.samplers.forEach(sampler => {
                sampler.start();
            });

            this.$el.show();
            this.samplers.forEach(sampler => {
                sampler.start();
            });
            this.update();
        } else {
            this.$el.hide();
        }

        return this;
    },

    addStat: function(label, defaultVal = "...") {
        const stat = $.span("stat");
        stat.addEl(`<span class='label'>${label}</span>`);
        this.stats[label] = stat.addEl($.span("value")).html(defaultVal);
        this.$el.addEl(stat);
    },

    updateStat: function(label, value, warn, indicateChange) {
        if (this.stats[label]) {
            const stat = this.stats[label];
            stat.html(value);
            if (warn) {
                stat.addClass("warn");
            } else {
                stat.removeClass("warn");
            }
            if (indicateChange) {
                stat.removeClass("fade-changed");
                stat.addClass("stat-changed");
                clearTimeout(stat.timeoutId);
                stat.timeoutId = setTimeout(function() {
                    stat.addClass("fade-changed");
                    stat.removeClass("stat-changed");
                }, 2000);
            }
        }
    },

    className: "profiler-view",
    update: function() {
        if (!this.enabled) {
            return;
        }
        this.samplers.forEach(sampler => {
            sampler.tick();
        });
        window.requestAnimationFrame(() => {
            this.update();
        });
    }

});

const Profiler = {
    create: function(options) {
        const profiler = new Timer(options || {});
        timers.push(profiler);
        return profiler;
    },
    createView: function() {
        return new ProfilerView();
    },
    TIMER: 0,
    AVG_LOAD_TIME: 1,
    FUNC_PROFILE: 2
};

export default Profiler;
