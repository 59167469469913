import getLogger from "js/core/logger";

const logger = getLogger();

export async function requestWakeLock() {
    try {
        const wakeLock = await navigator.wakeLock.request("screen");
        logger.info("requestWakeLock() wake lock acquired");

        return wakeLock;
    } catch (err) {
        if (err.name === "NotAllowedError") {
            // The wake lock request was denied by the user or the platform.
            logger.warn(`requestWakeLock() denied: ${err.message}`);
            return;
        }

        // The wake lock request fails - usually system-related, such as low battery.
        logger.warn(`requestWakeLock() failed: ${err.message}`);
    }
}

// eslint-disable-next-line no-undef
export async function releaseWakeLock(wakeLock: WakeLockSentinel) {
    try {
        if (wakeLock.released) {
            logger.warn("releaseWakeLock() already released");
            return;
        }

        await wakeLock.release();
        logger.info("releaseWakeLock() wake lock released");
    } catch (err) {
        logger.warn(`releaseWakeLock() failed: ${err.message}`);
    }
}
