class DbRouter {
    constructor(firebase, routes, config) {
        this._routes = routes || {};
        this._apps = {};
        this._firebase = firebase;

        Object.keys(this._routes).forEach(key => {
            const route = this._routes[key];
            if (!this._apps[route]) {
                this._apps[route] = this._firebase.initializeApp(
                    Object.assign({}, config, { databaseURL: route }),
                    route
                );
            }
        });
    }
    getDbInstance(path) {
        const match = /^\/?([^\/]+)(\/|$)/.exec(path);
        if (!match) {
            throw new Error("Path is not valid: " + path);
        }
        const root = match[1];
        const route = this._routes[root];
        const app = this._apps[route];
        return this._firebase.database(app);
    }
    db(ref) {
        return this.getDbInstance(ref).ref(ref);
    }
    isDefaultDb(path) {
        const match = /^\/?([^\/]+)(\/|$)/.exec(path);
        if (!match) {
            throw new Error("Path is not valid: " + path);
        }
        const root = match[1];
        const route = this._routes[root];
        return this._apps[route] === null;
    }
    getApps() {
        return Object.keys(this._apps).map(app => this._apps[app]);
    }
}

module.exports = DbRouter;
