import React, { Component, Fragment } from "react";
import {
    DialogTitle,
    DialogContentText,
    DialogActions,
    IconButton,
    Checkbox,
    FormControlLabel,
    Select,
    MenuItem,
    Icon,
    Button,
} from "@material-ui/core";
import { _, opentype } from "js/vendor";
import Api from "js/core/api";
import { loadFileAsArrayBuffer } from "js/core/utilities/promiseHelper";
import { FlexSpacer, Gap20, Gap30, Gap5 } from "js/react/components/Gap";
import { blobToDataURL } from "js/core/utilities/utilities";
import { trackActivity } from "js/core/utilities/utilities";
import { FlexBox } from "js/react/components/LayoutGrid";
import styled from "styled-components";
import { themeColors } from "js/react/sharedStyles";
import { BeautifulDialog, DialogContent, ShowDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { AssetType } from "common/constants";
import { ds } from "js/core/models/dataService";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { fontManager } from "js/core/services/fonts";
import { getFontWeightName } from "common/fontConstants";

export class UploadFontModal extends Component {
    constructor(props) {
        super(props);

        this.fileRef = React.createRef();

        if (props.font) {
            this.state = {
                uploadingFonts: false,
                uploadError: null,
                fontConfig: [],
                fontFamilyName: this.props.font.get("name")
            };
        } else {
            this.state = {
                uploadingFonts: false,
                uploadError: null,
                fontConfigs: [],
                fontFamilyName: null
            };
        }
    }

    setErrorsAndWarnings() {
        const { fontConfigs, fontFamilyName } = this.state;

        for (const font of fontConfigs) {
            font.error = null;
            if (fontConfigs.filter(f => f.weight == font.weight && f.italic == font.italic).length > 1) {
                font.error = "Duplicate weight and style";
            }
            if (fontManager.getFamilyName(font.font) != fontFamilyName) {
                font.error = "This typeface appears to belong to a different font family";
            }
        }

        this.setState({ fontConfigs: [...fontConfigs] });
    }

    uploadFiles = async files => {
        this.setState({ uploadingFonts: true });

        for (const file of files) {
            await this.loadFile(file);
        }

        this.setErrorsAndWarnings();

        if (this.fileRef.current) {
            this.fileRef.current.value = null;
        }

        this.setState({ uploadingFonts: false });
    }

    getFontWeight = font => {
        const subfamily = font.names.fontSubfamily.en.toLowerCase();

        let weight = parseInt(font.tables.os2.usWeightClass.toString().charAt(0) + "00");
        if (!weight.toString().endsWith("00")) {
            if (subfamily == "thin" || subfamily == "hairline") {
                weight = 100;
            } else if (subfamily == "extra light" || subfamily == "ultra light") {
                weight = 200;
            } else if (subfamily == "light") {
                weight = 300;
            } else if (subfamily == "normal" || subfamily == "regular") {
                weight = 400;
            } else if (subfamily == "medium") {
                weight = 500;
            } else if (subfamily.contains("semi") || subfamily.contains("demi")) {
                weight = 600;
            } else if (subfamily == "bold") {
                weight = 700;
            } else if (subfamily == "extra bold" || subfamily == "ultra bold") {
                weight = 800;
            } else if (subfamily == "black" || subfamily == "heavy") {
                weight = 900;
            } else {
                weight = 400;
            }
        }

        return weight;
    }

    loadFile = async file => {
        const { fontConfigs } = this.state;

        const fontConfig = {};

        const arrayBuffer = await loadFileAsArrayBuffer(file);

        const font = opentype.parse(arrayBuffer);

        let fontFamilyName = this.state.fontFamilyName;
        if (!fontFamilyName) {
            fontFamilyName = fontManager.getFamilyName(font);
        }

        fontConfig.file = file;
        fontConfig.font = font;
        fontConfig.fileName = file.name;
        fontConfig.name = font.names.fullName.en;
        fontConfig.weight = this.getFontWeight(font);
        fontConfig.italic = font.names.fontSubfamily.en.toLowerCase().contains("italic");
        fontConfig.label = `${fontManager.getFamilyName(fontConfig.font)} ${getFontWeightName(fontConfig.weight)}${fontConfig.italic ? " Italic" : ""}`;

        if (fontManager.hasNaNWidthGlyphs(fontConfig.font)) {
            throw new Error(
                `Font has NaN width glyphs - ${fontConfig.name}`
            );
        }

        const blob = await fontManager.drawTextToBlob(fontConfig.font, fontConfig.label);
        fontConfig.imageData = await blobToDataURL(blob);

        this.setState({
            fontConfigs: [...fontConfigs, fontConfig],
            fontFamilyName
        });

        const props = {
            file_name: fontConfig.file.name
        };
        trackActivity("CustomFonts", "UploadFontFile", null, null, props);
    };

    getUniqueName = fontFamilyName => {
        const { customFontList } = this.props;

        const num = _.max(
            customFontList
                .filter(font => {
                    return (
                        font.has("name") &&
                        font.get("name").startsWith(fontFamilyName)
                    );
                })
                .map(font => {
                    if (font.get("name") === fontFamilyName) {
                        return 1;
                    } else {
                        return parseInt(
                            font
                                .get("name")
                                .substr(`${fontFamilyName} (#`.length)
                        );
                    }
                })
        );

        if (num) {
            fontFamilyName += ` (#${num + 1})`;
        }

        return fontFamilyName;
    };

    handleCreateFontFamily = async () => {
        const {
            onSetCustomFont,
            closeDialog,
        } = this.props;
        const {
            fontFamilyName,
            fontConfigs,
        } = this.state;

        if (fontConfigs.length == 0) {
            return ShowWarningDialog({
                title: "You must add at least one font to the font family"
            });
        }

        this.setState({ uploadingFonts: true });

        const dialog = ShowDialog(ProgressDialog, {
            title: "Uploading Fonts...",
        });

        const findFont = italic => _.find(fontConfigs, { weight: 400, italic }) ||
            _.find(fontConfigs, { weight: 500, italic }) ||
            _.find(fontConfigs, { weight: 600, italic }) ||
            _.find(fontConfigs, { weight: 300, italic }) ||
            _.find(fontConfigs, { weight: 700, italic }) ||
            _.find(fontConfigs, { weight: 200, italic }) ||
            _.find(fontConfigs, { weight: 800, italic }) ||
            _.find(fontConfigs, { weight: 100, italic }) ||
            _.find(fontConfigs, { weight: 900, italic });

        const baseFont = findFont(false) || findFont(true);

        // Uploading font styles to temp assets storage
        const files = await Promise.all(fontConfigs.map(async ({ file, weight, italic }) => {
            const suffix = `${weight}-${italic ? "italic" : "normal"}`;
            const { fileName: tempFileName } = await ds.assets.uploadTempAssetFile(file);
            return {
                suffix,
                tempFileName
            };
        }));

        // Build base font label and image
        const label = fontManager.getFamilyName(baseFont.font);
        const blob = await fontManager.drawTextToBlob(baseFont.font, label);
        const imageData = await blobToDataURL(blob);

        // Composing the model
        const assetModel = {
            name: fontFamilyName,
            label,
            imageData,
            type: AssetType.FONT
        };
        // Adding all styles to the model
        fontConfigs.forEach(({ weight, italic, label, imageData }) => {
            assetModel[`font-${weight}-${italic ? "italic" : "normal"}`] = {
                label,
                imageData
            };
        });

        // Creating font asset
        const { id } = await Api.assets.post({
            assetModel,
            files
        });

        // Loading the created asset model (this will also add it to ds.assets),
        // note the retries parameter
        const asset = await ds.assets.getAssetById(id, AssetType.FONT, 10);

        // Adding asset to user assets
        asset.addAssetToUserAssets();

        await onSetCustomFont(asset.id, baseFont.weight);

        dialog.props.closeDialog();
        closeDialog();
    };

    handleUpdateFont = () => {
        this.setErrorsAndWarnings();
    }

    handleDeleteFont = font => {
        const { fontConfigs } = this.state;
        fontConfigs.remove(font);
        this.setErrorsAndWarnings();
    }

    render() {
        const { closeDialog } = this.props;
        const { fontConfigs, fontFamilyName, uploadingFonts, uploadError } = this.state;
        const isDisabled = uploadingFonts;

        return (
            <BeautifulDialog maxWidth="md">
                <DialogTitle>
                    Upload Font Family
                </DialogTitle>
                <DialogContent>
                    {!fontFamilyName &&
                        <DialogContentText>
                            Upload your own .ttf or .otf font files to create a
                            custom font family to use in your presentations. You can upload multiple font weights and styles
                            within a font family.
                        </DialogContentText>
                    }

                    {fontFamilyName &&
                        <Fragment>
                            <Gap20 />
                            <div style={{ fontSize: 20, fontWeight: 600 }}>
                                Font Family Name: {fontFamilyName}
                            </div>
                            <Gap20 />
                        </Fragment>
                    }

                    <div style={{ overflowY: "scroll", maxHeight: "calc(100vh - 400px)" }}>
                        {_.sortBy(fontConfigs, ["weight", "italic"]).map(font => <FontConfig key={font.name} font={font} onUpdate={this.handleUpdateFont} onDeleteFont={() => this.handleDeleteFont(font)} />)}
                    </div>

                    <Gap20 />
                    <AddFileDiv>
                        <Button color="primary"><Icon>cloud_upload</Icon>Add Fonts...</Button>
                        <input
                            type="file"
                            ref={this.fileRef}
                            multiple
                            accept=".otf,.ttf"
                            onChange={e => this.uploadFiles(e.currentTarget.files)}
                        />
                    </AddFileDiv>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button
                        disabled={isDisabled}
                        onClick={this.handleCreateFontFamily}
                    >
                        {uploadError ? (
                            <div>{uploadError}</div>
                        ) : ("Create Font Family")}
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}

const AddFileDiv = styled.div`
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
`;

const FontConfigFrame = styled.div`
  width: 100%;
  font-size: 16px;
  padding: 10px 0px;
  border-bottom: solid 1px #ddd;
  img {
    height: 30px;
  }

  .delete-button {
    opacity: 0;
  }
  
  &:hover {
    background: ${themeColors.rollover};
    .delete-button {
      opacity: 1;
    }
  }
  
  .error {
    display: flex;
    font-size: 13px;
    color: orangered;
    .MuiIcon-root {
      font-size: 22px;
    }
  }
  .MuiFormControlLabel-label {
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
  }
`;

class FontConfig extends Component {
    handleSetItalic = event => {
        this.props.font.italic = event.target.checked;
        this.props.onUpdate();
    }

    handleSetFontWeight = event => {
        this.props.font.weight = event.target.value;
        this.props.onUpdate();
    }

    render() {
        const { font, onDeleteFont } = this.props;

        return (
            <FontConfigFrame>
                <FlexBox>
                    <div style={{ width: 200 }}>{font.fileName}</div>
                    <Gap30 />
                    <div style={{ width: 250, height: 30 }}>
                        <img src={font.imageData} />
                    </div>
                    <Gap30 />
                    <Select
                        value={font.weight}
                        onChange={this.handleSetFontWeight}
                        disableUnderline
                        variant="outlined"
                        style={{ width: 160 }}
                    >
                        <MenuItem value={100}>Thin (100)</MenuItem>
                        <MenuItem value={200}>Extra Light (200)</MenuItem>
                        <MenuItem value={300}>Light (300)</MenuItem>
                        <MenuItem value={400}>Regular (400)</MenuItem>
                        <MenuItem value={500}>Medium (500)</MenuItem>
                        <MenuItem value={600}>Semi Bold (600)</MenuItem>
                        <MenuItem value={700}>Bold (700)</MenuItem>
                        <MenuItem value={800}>Extra Bold (800)</MenuItem>
                        <MenuItem value={900}>Black (900)</MenuItem>
                    </Select>
                    <Gap30 />
                    <FormControlLabel label="ITALIC"
                        control={
                            <Checkbox checked={font.italic} onChange={this.handleSetItalic} />
                        }
                    />

                    <FlexSpacer />
                    <IconButton
                        className="delete-button"
                        onClick={() => onDeleteFont(font)}
                    >
                        <Icon color="secondary">close</Icon>
                    </IconButton>
                </FlexBox>
                {font.error && <div className="error"><Icon>warning</Icon><Gap5 />{font.error}</div>}
            </FontConfigFrame>

        );
    }
}

