import { ds } from "js/core/models/dataService";

/**
 * Opens dropbox file selection UI. Currently only allows selection of one file at a time.
 */
function chooseFile(extensions) {
    return new Promise((resolve, reject) => {
        window.Dropbox.choose({
            success: files => resolve({
                id: files[0].id,
                name: files[0].name,
                url: files[0].link,
                size: files[0].bytes,
                success: true,
                source: "dropbox"
            }),
            cancel: () => {
                resolve({ cancelled: true });
            },
            linkType: "direct",
            multiselect: false,
            extensions,
            folderselect: false,
            sizeLimit: 32 * 1024 * 1024
        });
    });
}

/**
 * Opens dropbox file selection UI and fetches the file. Currently only allows selection of one file at a time.
 */
async function chooseAndGetFile(extensions) {
    const dropboxResp = await chooseFile(extensions);
    if (dropboxResp.cancelled) {
        return { cancelled: true };
    }
    const fetchResp = await fetch(dropboxResp.url);
    const data = await fetchResp.blob();

    return new File([data], dropboxResp.name, {
        type: data.type
    });
}

/**
 * Saves a Google Cloud Storage asset to a user's selected Dropbox destination.
 */
function saveFileFromUrl(url, fileName) {
    return new Promise((resolve, reject) => {
        window.Dropbox.save({
            // Replacing "/" in filename so Dropbox don't threat them as folder splitters
            files: [{ url, filename: fileName.replace(/\//g, " ") }],
            success: () => {
                resolve({ success: true });
            },
            progress: () => { },
            cancel: () => {
                resolve({ cancelled: true });
            },
            error: errorMessage => {
                reject(new Error(errorMessage));
            }
        });
    });
}

/**
 * Saves a blob asset to a user's selected Dropbox destination
 */
async function saveFileFromBlob(blob, fileName, contentType) {
    const { readUrl } = await ds.assets.uploadTempAssetFile(blob, contentType);
    return saveFileFromUrl(readUrl, fileName);
}

module.exports = { chooseFile, chooseAndGetFile, saveFileFromUrl, saveFileFromBlob };
export default { chooseFile, chooseAndGetFile, saveFileFromUrl, saveFileFromBlob };
