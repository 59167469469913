import React, { Component } from "react";
import styled from "styled-components";

import { Menu, MenuItem } from "@material-ui/core";
import { isFullsceenAllowed, isFullscreen, toggleFullscreen } from "../helpers/fullscreen";

import {
    ViewModule as ViewModuleIcon,
    Fullscreen as FullscreenIcon,
    FullscreenExit as FullscreenExitIcon,
    ChromeReaderMode as ChromeReaderModeIcon,
    ModeCommentOutlined as ModeCommentIcon,
    PhoneAndroid,
    PictureAsPdf as PictureAsPdfIcon,
    OpenInNew as OpenInNewIcon,
    Edit as EditIcon,
    List as ListIcon,
    Link as LinkIcon,
    ExitToAppOutlined as ExitToAppOutlinedIcon,
} from "@material-ui/icons";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import { NestedMenuItem } from "js/react/components/NestedMenuItem";
import { PlayerShortcutView } from "./PlayerShortcutView";

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    background: #222;
  }

  & > .MuiPaper-root {
    & > .MuiList-padding {
        padding-top: 0;
        padding-bottom: 0;
    }
  }

  .MuiMenuItem-root {
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-weight: 600;
    padding: 15px;
    color: #eee;

    .MuiIcon-root,
    .MuiSvgIcon-root {
      font-size: 24px;
      margin-right: 15px;
      color: #eee;
      fill: #eee;
    }

    &:hover {
        background: #333;
        color: #11a9e2;
    
        .MuiIcon-root,
        .MuiSvgIcon-root {
          color: #11a9e2;
          fill: #11a9e2;
        }
    }

    @media (max-width: 800px) {
        padding: 10px;
        font-size: 14px;

        .MuiIcon-root,
        .MuiSvgIcon-root {
            font-size: 20px;
            margin-right: 10px;
        }
    }
  }
`;

export const MenuDivider = styled.hr`
    border: 1px solid #676767;
    margin: 0;
`;

class PresentationActionsMenu extends Component {
    closeAfterAction = action => {
        const {
            onClose,
            registerUserInteraction,
        } = this.props;

        if (action) {
            action();
            onClose();
            registerUserInteraction?.();
        }
    }

    render() {
        const {
            open,
            onClose,
            anchorEl,
            anchorPosition,
            onExit,
            currentSlideIndex = null,
            showThumbnailsPane,
            isPresenterAvailable,
            togglePresenter,
            hasViewerComments,
            toggleComments,
            toggleNotes,
            isPlayerSettingsPreview,
            onDownloadPdf,
            onCopyDeck,
            allowCopyLink,
            onEditPresentation,
            onSwitchPresentation,
            onControlRemotely,
            username,
            isRemoteControlLeader,
            canControlRemotely
        } = this.props;

        const onEditSlide = (currentSlideIndex !== null && onEditPresentation)
            ? () => onEditPresentation(currentSlideIndex)
            : null;

        const allowFullscreenOption = isFullsceenAllowed();
        const fullscreen = isFullscreen();

        return (
            <StyledMenu
                open={open}
                anchorReference={anchorPosition ? "anchorPosition" : "anchorEl"}
                anchorPosition={anchorPosition}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                disableEnforceFocus
                style={{ zIndex: 999999 }}
                onClose={onClose}
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                {
                    canControlRemotely &&
                    <MenuItem
                        key="control-from-phone"
                        onClick={() => this.closeAfterAction(onControlRemotely)}
                    >
                        <PhoneAndroid />
                        Control from Phone
                    </MenuItem>
                }
                {
                    canControlRemotely &&
                    <MenuDivider />
                }

                <MenuItem
                    key="slide-grid"
                    onClick={() => this.closeAfterAction(showThumbnailsPane)}
                >
                    <ViewModuleIcon />
                    Slide Grid
                </MenuItem>

                {
                    allowFullscreenOption &&
                    <MenuItem
                        key="fullscreen"
                        onClick={() => !isPlayerSettingsPreview ? this.closeAfterAction(toggleFullscreen) : null}
                    >
                        {
                            fullscreen
                                ? <>
                                    <FullscreenExitIcon />
                                    Exit Fullscreen
                                </>
                                : <>
                                    <FullscreenIcon />
                                    Fullscreen
                                </>
                        }
                    </MenuItem>
                }
                {
                    (
                        isPresenterAvailable ||
                        isRemoteControlLeader
                    ) &&
                    <MenuDivider />
                }
                {
                    isPresenterAvailable &&
                    !isRemoteControlLeader &&
                    <MenuItem
                        key="presenter"
                        onClick={() => this.closeAfterAction(togglePresenter)}
                    >
                        <ChromeReaderModeIcon />
                        Presenter
                    </MenuItem>
                }
                {
                    isRemoteControlLeader &&
                    <MenuItem
                        key="show-notes"
                        onClick={() => this.closeAfterAction(toggleNotes)}
                    >
                        <ChromeReaderModeIcon />
                        Show Notes
                    </MenuItem>
                }
                {
                    hasViewerComments &&
                    <MenuDivider />
                }
                {
                    hasViewerComments &&
                    <MenuItem
                        key="comments"
                        onClick={() => this.closeAfterAction(toggleComments)}
                    >
                        <ModeCommentIcon />
                        Comments
                    </MenuItem>
                }
                {
                    (
                        allowCopyLink ||
                        onDownloadPdf ||
                        onCopyDeck ||
                        onEditSlide
                    ) &&
                    <MenuDivider />
                }
                {
                    allowCopyLink &&
                    <MenuItem
                        key="copy-link"
                        onClick={() => this.closeAfterAction(() => {
                            clipboardWrite({
                                [ClipboardType.TEXT]: window.location.href,
                            });
                        })}
                    >
                        <LinkIcon />
                        Copy Link
                    </MenuItem>
                }
                {
                    onDownloadPdf &&
                    <MenuItem
                        key="download-pdf"
                        onClick={() => this.closeAfterAction(() => onDownloadPdf(username))}
                    >
                        <PictureAsPdfIcon />
                        Download as PDF
                    </MenuItem>
                }
                {
                    onCopyDeck &&
                    <MenuItem
                        key="copy-deck"
                        onClick={() => this.closeAfterAction(onCopyDeck)}
                    >
                        <OpenInNewIcon />
                        Copy to Library
                    </MenuItem>
                }
                {
                    onEditSlide &&
                    <MenuItem
                        key="edit-presentation"
                        onClick={() => this.closeAfterAction(onEditSlide)}
                    >
                        <EditIcon />
                        Edit Presentation
                    </MenuItem>
                }

                <MenuDivider />
                <NestedMenuItem
                    key="keyboard-shortcuts"
                    label="Keyboard Shortcuts"
                    transformOrigin={{ vertical: "center" }}
                    showNestedOnClick
                >
                    <PlayerShortcutView
                        exclude={onExit ? [] : ["Exit Player"]}
                    />
                </NestedMenuItem>

                {
                    (
                        onSwitchPresentation ||
                        onExit
                    ) &&
                    <MenuDivider />
                }
                {
                    onSwitchPresentation &&
                    <MenuItem
                        key="switch-presentation"
                        onClick={() => this.closeAfterAction(onSwitchPresentation)}
                    >
                        <ListIcon />
                        Go to Library
                    </MenuItem>
                }
                {
                    onExit &&
                    <MenuItem
                        key="exit-player"
                        onClick={() => this.closeAfterAction(onExit)}
                    >
                        <ExitToAppOutlinedIcon />
                        Exit Player
                    </MenuItem>
                }
            </StyledMenu>
        );
    }
}

export default PresentationActionsMenu;
