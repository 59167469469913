import { Button, Icon, IconButton, Menu, MenuList, Popover } from "@material-ui/core";
import classNames from "classnames";
import { getStaticUrl } from "js/config";
import { app } from "js/namespaces";
import { Gap5 } from "js/react/components/Gap";
import { themeColors } from "js/react/sharedStyles";
import { $ } from "js/vendor";
import React, { Component, Fragment } from "reactn";
import styled from "styled-components";

export const UIContainerContext = React.createContext(true);

export class UIContainer extends Component {
    state = {
        shouldRender: true,
        isOpening: false
    };

    constructor() {
        super();
        this.containerRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isOpen && !this.props.isOpen) {
            this.props.onClose();
        }
    }

    render() {
        if (this.state.shouldRender) {
            return (
                <UIContainerContext.Provider value={this.state.isOpening}>
                    <div
                        ref={this.containerRef}
                        tabIndex="-1" // Needed to give default focus so 'event.relatedTarget' has something to work with
                        className={`beautiful-ui ui-container ${this.props.direction} ${this.props.className}`}
                    >
                        {this.props.children}
                    </div>
                </UIContainerContext.Provider>
            );
        } else {
            return null;
        }
    }
}

export class UIPane extends Component {
    render() {
        const { containerRef, className, children, ...props } = this.props;

        return (
            <div {...props} ref={containerRef} className={`ui-pane ${className ?? ""}`}>
                {children}
            </div>
        );
    }
}

export class UIPaneWithSideBar extends Component {
    render() {
        const { className, children, ...props } = this.props;

        return (
            <UIPane {...props} className={`with-sidebar ${className ?? ""}`}>
                {children}
            </UIPane>
        );
    }
}

export class UIPaneHeader extends Component {
    render() {
        const { className, onClose, children } = this.props;
        return (
            <div className={`ui-pane-header ${className ?? ""}`}>
                <Fragment>{children}</Fragment>
                {
                    onClose && (<IconButton className="exit-button" onClick={onClose}>
                        <Icon>close</Icon>
                    </IconButton>)
                }
            </div>
        );
    }
}

const Resizer = styled.div`
  position: absolute;
  height: 100%;
  top: 0px;
  width: 5px;
  right: 0px;
  cursor: col-resize;
`;

export const WarningBox = styled.div`
  color: white;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
  text-transform: initial;
`;

export const StyledWarningIcon = styled(Icon)`
    color: #FF4500;
    font-size: 18px;
`;

export const StyledPanel = styled.div`
    display: flex;
    flex-direction: column;
`;

export class UIPaneSideBar extends Component {
    constructor() {
        super();
        this.ref = React.createRef();
    }

    render() {
        const show = this.props.show ? "show" : "";
        return (
            <div
                ref={this.ref}
                className={`ui-pane-sidebar ${show}`}
                onClick={e => {
                    e.stopPropagation();
                    this.props.onHide && this.props.onHide();
                }}
            >
                <StyledPanel
                    className="panel"
                    style={this.props.style}
                    onClick={e => e.stopPropagation()}
                >{this.props.children}</StyledPanel>

                {
                    !!this.props.onResize &&
                    <UIPaneSideBarResizer
                        sideBarRef={this.ref}
                        onResize={this.props.onResize}
                    />
                }
            </div>
        );
    }
}

export class UIPaneSideBarResizer extends Component {
    handleMouseDown = event => {
        let { sideBarRef, onResize } = this.props;

        $("body").on("mousemove.drag", event => {
            onResize(event.pageX - sideBarRef.current.getBoundingClientRect().left);
        });
        $("body").on("mouseup.drag", event => {
            $("body").off(".drag");
        });
    }

    render() {
        return (
            <Resizer onMouseDown={this.handleMouseDown} />
        );
    }
}

export class UIPaneContents extends Component {
    render() {
        const { children, onScroll, style, containerRef } = this.props;

        return (
            <div className="ui-inner-pane" onScroll={onScroll} style={style} ref={containerRef}>
                {children}
            </div>
        );
    }
}

const StyledUINavigationBackButton = styled.div`
    width: 100%;
    
    background-color: ${props => props.setAsPlaceholder ? "none" : "#222"};
    cursor: ${props => props.setAsPlaceholder ? "auto" : "pointer"};

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 50px;

    img {
      height: 15px;
    }

    &:hover {
      background: $newBlue;
    }
`;

export class UINavigation extends Component {
    static contextType = UIContainerContext;

    render() {
        const { onBack, setAsPlaceholder = false } = this.props;

        let onSelect = event => {
            this.props.onSelect(event.currentTarget.id);
        };

        return (
            <div className={`ui-navigation ${this.props.className || ""} animate-in`}>
                <StyledUINavigationBackButton
                    id="ui-navigation-back-button"
                    setAsPlaceholder={setAsPlaceholder}
                    onClick={() => {
                        if (onBack) onBack();
                    }}
                >
                    {!setAsPlaceholder && <img src={getStaticUrl("/images/backIcon.svg")} />}
                </StyledUINavigationBackButton>
                <div className={"ui-navigation-items " + (this.props.fullHeight ? "full-height" : "")}>
                    {React.Children && React.Children.map(this.props.children, child => {
                        if (child) {
                            return React.cloneElement(child, {
                                key: child.props.id,
                                selected: this.props.selected,
                                onSelect
                            });
                        }
                    })}
                </div>
            </div>
        );
    }
}

export class UINavigationItemHeader extends Component {
    render() {
        const { children, fullHeight, title, selected, onSelect } = this.props;
        return (
            <div className={"ui-navigation-item-header " + (fullHeight ? "full-height" : "")}>
                {title && (
                    <span className="title">{title}</span>
                )}
                {React.Children.map(children, child => {
                    if (child) {
                        return React.cloneElement(child, {
                            selected,
                            onSelect
                        });
                    }
                })}
            </div>
        );
    }
}

export const UINavigationSectionLabel = styled.div`
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 6px 10px;
`;

export class UINavigationItem extends Component {
    render() {
        const { selected: selectedOption, id, onCustomSelect, onSelect, hidden, vertical, styles, icon, iconLabel, image, title, proBadge, children } = this.props;
        const selected = selectedOption === id;
        // If there is a custom select on UINavigationItem use that otherwise use onSelect from UINavigation
        const handleClick = onCustomSelect ?? onSelect;

        if (hidden) return null;

        return (
            <div
                id={id}
                className={`ui-navigation-item ${selected ? "selected" : ""} ${vertical ? "vertical" : ""}`}
                onClick={handleClick}
                style={styles}
            >
                <div className={classNames("nav-label")}>
                    {icon && <div className="icon-container">
                        <Icon>{icon}</Icon>
                        {iconLabel && <span className="icon-label">{iconLabel}</span>}
                    </div>}
                    {image && <div className="icon-container">{image}</div>}
                    {title}
                </div>
                {proBadge && proBadge}
                {React.Children.map(children, child => {
                    if (child) {
                        return React.cloneElement(child, {
                            selected: selectedOption,
                            onSelect: onSelect
                        });
                    }
                })}
            </div>
        );
    }
}

export class Section extends Component {
    render() {
        return (
            <section id={this.props.id}>
                {
                    this.props.title &&
                    <div className="section-title">{this.props.title}{this.props.proBadge || null}</div>
                }
                {!this.props.title && <Gap5 />}
                {
                    this.props.description &&
                    <div className="section-description">{this.props.description}</div>
                }

                {this.props.children}
            </section>
        );
    }
}

export class Field extends Component {
    render() {
        return (
            <section className="field">
                {this.props.title && <div className="field-label">{this.props.title}</div>}
                {this.props.children}
            </section>
        );
    }
}

export class BlueButton extends Component {
    render() {
        return (
            <Button variant="contained" color="primary" {...this.props}>
                {this.props.children}
            </Button>
        );
    }
}

export class WarningButton extends Component {
    render() {
        return (
            <Button variant="contained" color="primary" {...this.props} className="warning">
                {this.props.children}
            </Button>
        );
    }
}

export class GreenButton extends Component {
    render() {
        const authoringStyle = {
            backgroundColor: themeColors.authoringThemeBaseColor,
            color: "white"
        };
        return (
            <Button variant="contained" style={authoringStyle} {...this.props}>
                {this.props.children}
            </Button>
        );
    }
}

export class BlueOutlinedButton extends Component {
    render() {
        return (
            <Button variant="outlined" color="primary" {...this.props}>
                {this.props.children}
            </Button>
        );
    }
}

export class YellowButton extends Component {
    render() {
        return (
            <Button variant="contained" {...this.props} className="yellow">
                {this.props.children}
            </Button>
        );
    }
}

export class ScaryButton extends Component {
    render() {
        return (
            <Button variant="contained" {...this.props} className="orange">
                {this.props.children}
            </Button>
        );
    }
}

export class DarkButton extends Component {
    render() {
        return (
            <Button variant="contained" {...this.props} className="dark">
                {this.props.children}
            </Button>
        );
    }
}

export class TextButton extends Component {
    render() {
        return (
            <Button variant="text" {...this.props} className="transparent">
                {this.props.children}
            </Button>
        );
    }
}

export const WhiteIconButton = styled(IconButton)`
  .MuiIcon-root {
    color: white;
    transition: color 300ms;
  }

  &:hover {
    .MuiIcon-root {
      color: ${themeColors.ui_blue};
    }
  }
`;

export const RoundIconButton = styled(IconButton)`
  .MuiIcon-root {
    color: white;
    font-size: 20px;
  }

  &&& {
    background: ${themeColors.ui_blue};
    padding: 4px;
  }
`;

export const WhiteTextButton = styled(Button)`
    &&& {
        color: white;
  .MuiIcon-root {
    color: white;
  }

  .MuiButton-label {
    color: white;
  }
}`;

const StyledIconButton = styled(IconButton)`
   &&& {

        > span {
            ${({ $menuIsOpen }) => $menuIsOpen ? "opacity:1" : ""};
        }


        &:hover {
            background-color: transparent;

            span > span.MuiIcon-root{
                color: ${({ $colorOnHover }) => $colorOnHover ?? "inherit"};
            }
        }
    }
`;

const StyledMenu = styled(Menu)`
    .MuiList-padding{
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
        background: #fff;
        border: solid 1px #bbb;
        border-radius: 2px;
        box-shadow: 0px 0px 50px rgb(0 0 0 / 25%);
    }
`;

// https://stackoverflow.com/questions/49834251/how-to-extend-styled-component-without-passing-props-to-underlying-dom-element
// Adding $ to the props that throws the following error:
// React does not recognize the `{prop name}` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell
// it as lowercase `{prop name}` instead. If you accidentally passed it from a parent component, remove it from the DOM element.
export class IconMenu extends Component {
    state = {
        anchorEl: null
    }

    constructor(props) {
        super(props);
    }

    setAncholElState = state => this.setState({ anchorEl: state });

    handleShowMenu = event => {
        this.setAncholElState(event.currentTarget);
    }

    handleCloseMenu = () => {
        this.setAncholElState(null);
    }

    handleMouseDown = event => {
        event.stopPropagation();
    }

    handleMenuItemClick = (callback, event) => {
        if (callback) {
            event.stopPropagation();
            this.handleCloseMenu();
            callback(event);
        }
    }

    render() {
        const {
            icon = "more_vert",
            ariaLabel, size = "medium",
            iconButtonColor = "default",
            disabled,
            children,
            classes,
            buttonLabel,
            transformOrigin = {
                vertical: "top",
                horizontal: "center"
            },
            anchorOrigin = {
                vertical: "bottom",
                horizontal: "center"
            },
            ...rest
        } = this.props;
        const { anchorEl } = this.state;

        const isMenuOpen = !!anchorEl;

        return (
            <Fragment>
                <StyledIconButton
                    classes={classes}
                    aria-haspopup="true"
                    aria-label={ariaLabel}
                    onClick={this.handleShowMenu}
                    color={iconButtonColor}
                    disabled={disabled}
                    size={size}
                    {...rest}
                >
                    <Icon fontSize="small">{icon}</Icon>
                    {buttonLabel && <label>{buttonLabel}</label>}
                </StyledIconButton>
                <StyledMenu
                    variant="menu"
                    anchorEl={this.state.anchorEl}
                    getContentAnchorEl={null}
                    open={isMenuOpen}
                    onClose={this.handleCloseMenu}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                >
                    {React.Children.map(children, child =>
                        child ? React.cloneElement(child, {
                            onClick: event => this.handleMenuItemClick(child.props.onClick, event),
                            onMouseDown: this.handleMouseDown,
                        }) : null
                    )}
                </StyledMenu>
            </Fragment>
        );
    }
}

export class ContextMenu extends Component {
    handleCloseMenu = () => {
        this.props.onClose && this.props.onClose();
    }

    handleMenuItemClick = (callback, event) => {
        this.handleCloseMenu();
        callback(event);
    }

    render() {
        const { x, y, children } = this.props;

        return (
            <Popover
                open={true}
                anchorReference="anchorPosition"
                anchorPosition={{ top: y, left: x }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={this.handleCloseMenu}
            >
                <MenuList
                    variant="menu"
                >
                    {React.Children.map(children, child =>
                        child ? React.cloneElement(child, {
                            onClick: event => this.handleMenuItemClick(child.props.onClick, event),
                        }) : null
                    )}
                </MenuList>
            </Popover>
        );
    }
}

export const Divider = styled.hr`
    // Adding &&& to override the material ui style
    &&& {
        display: ${props => props.hidden ? "none" : "block"};
        border: none;
        height: 1px;
        background: ${props => props.color ?? "#eee"};
        width: 100%;
        margin-top: ${props => props.margin != null ? props.margin : 30}px;
        margin-bottom: ${props => props.margin != null ? props.margin : 30}px;
    }
`;
