import React from "react";
import { v4 as uuid } from "uuid";

import { app } from "js/namespaces";
import getLogger, { LogGroup } from "js/core/logger";
import db from "js/db";
import { isPPTAddin } from "js/config";
import getLocalStorage from "js/core/utilities/localStorage";
import { ErrorMessageType } from "common/constants";
import { trackActivity } from "js/core/utilities/utilities";

const SESSION_ID_LOCAL_STORAGE_KEY = "bai-session";

const logger = getLogger(LogGroup.AUTH);

const localStorage = getLocalStorage();

/**
 * Tracks user session, makes sure user has only one active session
 */
export class FirebaseSessionTracker extends React.Component {
    constructor(props) {
        super(props);

        this.destroy = null;
    }

    async trackSession(user) {
        const { claims } = await user.getIdTokenResult();

        if (!user.emailVerified) {
            logger.info(`[FirebaseSessionTracker] ${user.uid} user has not verified email, won't be tracking session`, { uid: user.uid });
            return;
        }

        if (claims.session_tracking_disabled) {
            logger.info(`[FirebaseSessionTracker] ${user.uid} user has the "session_tracking_disabled" custom claim set to true, won't be tracking session`, { uid: user.uid });
            return;
        }

        if (app.integrator) {
            logger.info(`[FirebaseSessionTracker] ${user.uid} user - integrator skipping session tracking`, { uid: user.uid, integratorType: app.integrator.type });
            return;
        }

        let sessionId;
        const currentSessionId = localStorage.getItem(SESSION_ID_LOCAL_STORAGE_KEY);
        if (!currentSessionId) {
            sessionId = uuid();
            localStorage.setItem(SESSION_ID_LOCAL_STORAGE_KEY, sessionId);
            logger.info(`[FirebaseSessionTracker] set new session ${sessionId}`, { uid: user.uid, sessionId });
        } else {
            sessionId = currentSessionId;
            logger.info(`[FirebaseSessionTracker] using existing session ${sessionId}`, { uid: user.uid, sessionId });
        }

        let sessionType = "desktop";
        if (app.isMobileOrTablet) {
            sessionType = "mobile";
        } else if (isPPTAddin) {
            sessionType = "ppt-addin";
        }
        const sessionRef = db("sessions").child(user.uid).child(sessionType);

        // Saving session id
        await sessionRef.set(sessionId);

        // Listening to changes in the session object
        sessionRef.on("value", snap => {
            const newSessionId = snap.val();
            // A new tab was opened in the same browser
            if (newSessionId === sessionId) {
                return;
            }

            logger.info(`[FirebaseSessionTracker] new session ${newSessionId} detected, logging out`, { uid: user.uid, newSessionId });

            localStorage.removeItem(SESSION_ID_LOCAL_STORAGE_KEY);

            trackActivity("Auth", "DuplicateSessionLogOut", null, null, { workspace_id: "all" }, { audit: false });

            window.location = `/logout?continue=${encodeURIComponent(`/error/${ErrorMessageType.DUPLICATE_SESSION_ERROR}`)}`;
        });

        // We're going to need this to unmount correctly
        this.destroy = () => sessionRef.off();
    }

    componentWillUnmount() {
        if (this.destroy) {
            this.destroy();
        }
    }

    componentDidMount() {
        const { user } = this.props;

        this.trackSession(user)
            .catch(err => {
                logger.error(err, "[FirebaseSessionTracker] trackSession() failed", { uid: user?.uid });
            });
    }

    render() {
        const { children } = this.props;
        return <>{children}</>;
    }
}
