const diff = require("./diff");
const applyCommands = require("./applyCommands");
const { getInitialId } = require("./utils");

// initial data is set on the special key (all zeroes) so that it comes before all other updates
const specialInitialID = getInitialId();

function createFromData(data) {
    let updates = diff({}, data || {});
    let cmd = { id: specialInitialID, updates: JSON.stringify(updates) };
    let snapModel = applyCommands({}, updates);
    let stringifiedSnapModel = JSON.stringify(snapModel);
    return { cmd, snapModel, stringifiedSnapModel };
}

module.exports = createFromData;
