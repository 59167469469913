export enum PermissionType {
    OWNER = "owner",
    READ = "read",
    WRITE = "write",
    COPY = "copy",
    PLAYBACK = "playback",
    PUBLIC = "public",
    DOWNLOAD = "download",
    IGNORE = "ignore" // This will skip the permission check.
}

export const PermissionResourceType = {
    PRESENTATION: "0",
    ORG: "1",
    THEME: "2",
    TEAM: "3",
    SHARED_THEME: "4",
    USER_FOLDER: "5",
    TEAM_ASSET: "6",
    TEAM_FOLDER: "7",
};

export const ResourceTypeToPathName = {
    0: "presentations",
    1: "orgs",
    2: "user_themes",
    3: "teams",
    4: "sharedThemes",
    5: "userFolders",
    6: "assets",
    7: "teamFolders"
};

export interface IUserPermission {
    displayName: string,
    email: string,
    photoURL: string,
    read: boolean,
    write: boolean,
    public: boolean,
    admin: boolean,
    pending: boolean
}

export interface IPermissionObj {
    write: boolean;
    read: boolean;
}

export interface IPermission {
    [userId: string]: IPermissionObj;
}
