import { EndpointDefinition } from "../_sdk/types/common";

export const apiName = "slides";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getSlide: {
        route: "/slides/:id",
        method: "GET",
    },
    createSlide: {
        route: "/slides/:id",
        method: "POST",
    },
    updateSlide: {
        route: "/slides/:id",
        method: "PUT",
    },
    getSlideRevisions: {
        route: "/slides/:id/revisions",
        method: "GET",
    },
    getSlideOwner: {
        route: "/slides/:id/owner",
        method: "GET",
    },
    migrateModel: {
        route: "/slides/migrate/model",
        method: "POST",
    }
};
