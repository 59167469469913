import { _ } from "js/vendor";

class Perf {
    constructor() {
        this.timers = {};
        this.logs = {};
    }

    start(type) {
        this.timers[type] = performance.now();
    }

    stop(type, verboseResult = "") {
        const resultingType = `${type}${verboseResult ? `:${verboseResult}` : ""}`;
        if (!this.logs[resultingType]) {
            this.logs[resultingType] = [];
        }
        this.logs[resultingType].push(performance.now() - this.timers[type]);
        delete this.timers[type];
    }

    reset() {
        this.logs = {};
        this.timers = {};
    }

    print(detailed) {
        const records = Object.entries(this.logs).map(([type, logs]) => {
            const record = {
                type,
                count: logs.length,
                avg: _.sum(logs) / logs.length,
            };

            if (detailed) {
                record.raw = logs.join(", ");
                logs.sort((a, b) => a - b);
                const mid = logs.length / 2;
                const med = mid % 1 ? logs[mid - 0.5] : (logs[mid - 1] + logs[mid]) / 2;
                record.med = med;
                record.max = _.max(logs);
                record.min = _.min(logs);
            }

            return record;
        });

        records.sort((a, b) => b.avg - a.avg);

        records.forEach(record => {
            /* eslint-disable no-console */
            console.log("----------------------------------");
            Object.entries(record).forEach(([key, value]) => console.log(`${key}: ${value}`));
            /* eslint-disable no-console */
        });
    }
}

export default new Perf();
