export const punctuationRegex = new RegExp(/[\u2000-\u200F\u201A-\u206F\u2E00-\u2E7F\\!"#$%&()*+,.:;<=>?@\[\]^_`{|}~]/g);
export const singleQuoteRegex = new RegExp(/^[']+|[']+$/g);
export const alternateSingleQuoteRegex = new RegExp(/[\u2018\u2019]/g);
export const htmlRegex = new RegExp(/(<([^>]+)>)/ig);
export const hyphenRegex = new RegExp(/[\u2010-\u2015\-/]/g);
export const firebaseRegex = new RegExp(/[.#$/\[\]]/g);
export const startsWithNumberRegex = new RegExp(/^\d/);
export const startsWithLowercase = new RegExp(/^[a-z]/);
export const numberRegex = new RegExp(/^(-?)[$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]?(\s\()?(([1-9][0-9]{0,2}([,.][0-9]{3})*)|[0-9]+)?([,.][0-9]{1,})?(\)?)%?$/);
export const negativeNumberParanthesisRegex = new RegExp(/(\$)\(((\d|,|\.)+)\)/g);
export const spacesRegex = new RegExp(/ {4,}/g);
export const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const delimiterRegex = new RegExp(/[\s\n,;]+/);
export const urlRegex = new RegExp(/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/);
