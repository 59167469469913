import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { CircularProgress, Select, MenuItem, Avatar } from "@material-ui/core";

import getLogger, { LogGroup } from "js/core/logger";
import Api from "js/core/api";
import { ds } from "js/core/models/dataService";
import { getStaticUrl } from "js/config";
import { FirebaseUserConsumer } from "js/react/views/Auth/FirebaseUserContext";
import { getPresentation } from "js/core/models/presentation";

import ErrorDialog from "js/react/components/Dialogs/ErrorDialog";
import { BlueButton, BlueOutlinedButton } from "js/react/components/UiComponents";
import BeautifulDialog from "js/react/components/Dialogs/BeautifulDialog";
import { app } from "js/namespaces";

const logger = getLogger(LogGroup.PERMISSIONS);

const ContentContainer = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    font-family: Source Sans Pro;
    line-height: 120%;
    letter-spacing: 0.5px;

    >p {
        max-width: calc(100% - 100px);
    }

    p {
        overflow-wrap: break-word; 
    }
`;

const FetchingClickShield = styled.div.attrs(({ visible }) => ({
    style: {
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? "auto" : "none"
    }
}))`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.8);
    border-radius: 4px;
    z-index: 9999;
    transition: opacity 500ms;
`;

const ErrorDialogMessage = styled.p`
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;     
    letter-spacing: 0.5px;
`;

const ErrorDialogInfo = styled.p`
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
`;

const RequestAccessTitle = styled.p`
    width: 400px;
    margin-top: 35px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #222222;
    line-height: 1.2;
`;

const RequestAccessSubtitle = styled.p`
    margin-top: 20px;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
    text-align: center;
    color: #222222;
`;

const RequestAccessNote = styled.p`
    margin-top: 7px;
    margin-bottom: -21px;
    font-weight: 400;
    font-size: 14px;
    font-style: italic;
    color: #666666;
`;

const CustomMessageContainer = styled.div`
    margin-top: 15px;
    width: 500px;
    background: #F7FAFC;
    border-radius: 2px;
    padding: 10px;

    >p {
        font-family: Source Sans Pro;
        font-size: 16px;
        line-height: 125%;
        color: #666666;
    }

    @media (max-width: 600px) {
        max-width: 400px;
    }
`;

const AccessSelectorContainer = styled.div`
    margin-top: 20px;

    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    >span {
        margin-right: 10px;
        font-weight: 600;
        font-size: 14px;
        color: #222222;
    }
`;

const ActionButtonsContainer = styled.div`
    margin: 40px 50px;
    width: calc(100% - 100px);
    height: 40px;

    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
`;

const StyledDialog = styled(BeautifulDialog)`
    ${props => props.isMobileOrTablet && css`
        &&& {
        .MuiDialog-paper {
            margin: 0;
            width: 100%;
        }
        } : null
    `}
`;

const CancelButton = styled(BlueOutlinedButton)`
    &&& {
        height: 40px;
        color: #666666;
        border: none;
    }
`;

const CloseButton = styled(BlueOutlinedButton)`
    &&& {
        margin: 40px 50px;
        height: 40px;
        border: none;
    }
`;

const OpenPresentationButton = styled(BlueOutlinedButton)`
    &&& {
        margin-right: 20px;
        height: 40px;
        border: none;
    }
`;

const ProvideAccessButton = styled(BlueButton)`
    &&& {
        height: 40px;
        border-radius: 1px;
    }
`;

const DoneImage = styled.img`
    margin-top: 50px;
    height: 50px;
`;

const UserPhotoWrapper = styled.div`
    position: relative;
    margin-top: 40px;
    width: 64px;
    height: 64px;
`;

const UserPhoto = styled(Avatar)`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`;

const VerificationIconHeader = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: ${({ isRequesterInTheSameWorkspace }) => isRequesterInTheSameWorkspace ? "#96BB47" : "#FFAA00"};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
`;

const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    font-size: 14px;
    color: #222222;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 10px;

    span {
        font-family: Source Sans Pro;
        font-weight: 400;
        font-size: 12px;
        color: #999999;
    }
`;

const VerificationIcon = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
    background-color: ${({ isRequesterInTheSameWorkspace }) => isRequesterInTheSameWorkspace ? "#96BB47" : "#FFAA00"};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
`;

class ProvideAccessDialogContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            thumbnailUrl: null,
            existingPermission: null,
            selectedPermission: props.notification.event.permissionType,
            error: null,
            permissionProvided: false,
            showWarningMesage: false,
            isRequesterInTheSameWorkspace: false,
            teamName: null
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const {
            notification: {
                event: {
                    presentationId,
                    triggeredByUserUid
                }
            }
        } = this.props;
        let {
            selectedPermission
        } = this.state;

        try {
            await Promise.all([
                (async () => {
                    const presentation = await getPresentation(presentationId, { permission: "write", autoSync: false });
                    const workspaceId = presentation.getWorkspaceId();
                    presentation.disconnect();
                    let teamName, isRequesterInTheSameWorkspace;
                    if (workspaceId !== "personal") {
                        this.setState({ showWarningMessage: true });
                        const defaultTeam = ds.teams.defaultTeamForOrg(workspaceId);
                        const teamMemberIds = Object.keys(defaultTeam.get("members"));
                        isRequesterInTheSameWorkspace = teamMemberIds.includes(triggeredByUserUid);
                        teamName = defaultTeam.get("name");
                        this.setState({ teamName, isRequesterInTheSameWorkspace });
                    }
                })(),
                (async () => {
                    const { permissions } = await Api.permissions.get({ id: presentationId });
                    let existingPermission = permissions.find(({ uid }) => uid === triggeredByUserUid)?.type;
                    if (existingPermission) {
                        existingPermission = existingPermission === "edit" ? "write" : existingPermission === "view" ? "read" : null;
                    }

                    if (selectedPermission === existingPermission) {
                        selectedPermission = selectedPermission === "write" ? "read" : "write";
                    }
                    this.setState({ existingPermission, selectedPermission });
                })()
            ]);

            this.setState({ fetching: false });
        } catch (err) {
            logger.error(err, "ProvideAccessDialogContent load() failed", { presentationId, triggeredByUserUid });

            this.setState({
                fetching: false,
                error: {
                    message: "Oops! Our server had an error.",
                    info: "Try again later."
                }
            });
        }
    }

    handleProvideAccess = async () => {
        const {
            notification: {
                event: {
                    triggeredByUser: { email },
                    presentationId
                }
            }
        } = this.props;
        const {
            selectedPermission
        } = this.state;

        this.setState({ fetching: true });

        try {
            await Api.permissions.post({
                id: presentationId,
                customMessage: "",
                emails: [email],
                permission: selectedPermission
            });
            this.setState({ fetching: false, permissionProvided: true });
        } catch (err) {
            logger.error(err, "ProvideAccessDialogContent handleProvideAccess() failed", { presentationId, email, selectedPermission });

            this.setState({
                fetching: false,
                error: {
                    message: "Oops! Our server had an error.",
                    info: "Try again later."
                }
            });
        }
    }

    render() {
        const {
            notification: {
                event: {
                    presentationName,
                    triggeredByUser: { displayName, email, photoURL },
                    customMessage,
                    presentationUrl
                }
            },
            closeDialog
        } = this.props;
        const {
            fetching,
            selectedPermission,
            existingPermission,
            error,
            permissionProvided,
            showWarningMessage,
            isRequesterInTheSameWorkspace,
            teamName
        } = this.state;

        if (error) {
            return (<ErrorDialog
                message={(<>
                    <ErrorDialogMessage>{error.message}</ErrorDialogMessage>
                    <ErrorDialogInfo>{error.info}</ErrorDialogInfo>
                </>)}
                closeDialog={closeDialog}
            />);
        }

        if (permissionProvided) {
            return (<BeautifulDialog closeDialog={closeDialog}>
                <ContentContainer>
                    <DoneImage src={getStaticUrl("/images/auth/done-check.gif")} />
                    <RequestAccessTitle>Success</RequestAccessTitle>
                    <RequestAccessSubtitle>You granted {displayName} access to <b>{selectedPermission === "write" ? "edit" : "view"}</b> {presentationName} presentation</RequestAccessSubtitle>
                    <CloseButton onClick={closeDialog}>
                        close
                    </CloseButton>
                </ContentContainer>
            </BeautifulDialog>);
        }

        return (<StyledDialog isMobileOrTablet={app.isMobileOrTablet} closeDialog={closeDialog}>
            <ContentContainer>
                <FetchingClickShield visible={fetching}><CircularProgress /></FetchingClickShield>
                <UserPhotoWrapper>
                    <UserPhoto src={photoURL} alt={`${displayName}'s photo`} />
                    {showWarningMessage && (
                        <VerificationIconHeader isRequesterInTheSameWorkspace={isRequesterInTheSameWorkspace}>
                            {isRequesterInTheSameWorkspace ? "✓" : "!"}
                        </VerificationIconHeader>
                    )}
                </UserPhotoWrapper>
                <RequestAccessTitle>
                    {displayName} (<span style={{ color: "#11A9E2" }}>{email}</span>) <span style={{ fontWeight: "400" }}>is requesting access to</span> <a href={presentationUrl}>{presentationName}</a>
                    {showWarningMessage && ` in ${teamName}`}
                    {customMessage && ":"}
                </RequestAccessTitle>
                {customMessage && <CustomMessageContainer>
                    <p>{customMessage}</p>
                </CustomMessageContainer>}
                {showWarningMessage && (
                    <UserInfoContainer>
                        <VerificationIcon isRequesterInTheSameWorkspace={isRequesterInTheSameWorkspace}>
                            {isRequesterInTheSameWorkspace ? "✔" : "!"}
                        </VerificationIcon>
                        <span>
                            {isRequesterInTheSameWorkspace
                                ? "This user is a part of your workspace"
                                : "Make sure you trust this external user before sharing any content"}
                        </span>
                    </UserInfoContainer>
                )}
                <AccessSelectorContainer>
                    <span>Allow {displayName} to</span>
                    <Select
                        variant="outlined"
                        value={selectedPermission}
                        onChange={event => this.setState({ selectedPermission: event.target.value })}
                    >
                        {existingPermission !== "write" && <MenuItem value="write">EDIT</MenuItem>}
                        {existingPermission !== "read" && <MenuItem value="read">VIEW</MenuItem>}
                    </Select>
                </AccessSelectorContainer>
                {existingPermission && <RequestAccessNote>{displayName} already has <b>{existingPermission === "write" ? "edit" : "view"}</b> access to presentation</RequestAccessNote>}
                <ActionButtonsContainer>
                    {app.isMobileOrTablet
                        ? <ProvideAccessButton onClick={this.handleProvideAccess}>
                            {existingPermission ? "update access" : "allow access"}
                        </ProvideAccessButton>
                        : <>
                            <CancelButton onClick={closeDialog}>
                                cancel
                            </CancelButton>
                            <div>
                                <OpenPresentationButton onClick={() => window.open(presentationUrl, "_blank")}>
                                    open presentation
                                </OpenPresentationButton>
                                <ProvideAccessButton onClick={this.handleProvideAccess}>
                                    {existingPermission ? "update access" : "allow access"}
                                </ProvideAccessButton>
                            </div>
                        </>
                    }
                </ActionButtonsContainer>
            </ContentContainer>
        </StyledDialog>);
    }
}

class ProvideAccessDialog extends React.Component {
    static propTypes = {
        notification: PropTypes.object.isRequired,
        closeDialog: PropTypes.func.isRequired,
    }

    render() {
        const { notification, closeDialog } = this.props;

        return (<FirebaseUserConsumer>
            {firebaseUser => (
                <ProvideAccessDialogContent
                    notification={notification}
                    firebaseUser={firebaseUser}
                    closeDialog={closeDialog}
                />
            )}
        </FirebaseUserConsumer>);
    }
}

export default ProvideAccessDialog;
