import React, { Component } from "react";
import styled from "styled-components";
import { DialogTitle, DialogContent, DialogActions, Button, LinearProgress } from "@material-ui/core";

import { TaskState } from "common/constants";
import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import { themeColors } from "js/react/sharedStyles";

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    gap: 10px;
`;

const FileNameText = styled.div`
    font-size: 20px;
    font-weight: 600;
`;

const ProgressText = styled.div`
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    color: #666;
    padding-top: 20px;
`;

const StyledLinearProgress = styled(LinearProgress)`
    &.MuiLinearProgress-colorPrimary {
        background-color: ${themeColors.ui_blue50percent};
        
        .MuiLinearProgress-barColorPrimary {
            background-color: ${themeColors.ui_blue};
        }
    }
`;

interface Props {
    label: string;
    titleLabel: string;
    progress: number;
    taskState: string; // instance of TaskState
    actionLabel?: string;
    onAction?: () => void;
    onCancel?: () => void;
}

export default class TaskProgressDialog extends Component<Props> {
    render() {
        const { progress, label, titleLabel, actionLabel, taskState, onAction, onCancel } = this.props;

        return (
            <BeautifulDialog maxWidth="md">
                <DialogTitle>{titleLabel}</DialogTitle>
                <DialogContent>
                    <ContentContainer>
                        <FileNameText>{label}</FileNameText>
                        <StyledLinearProgress value={progress} color={"primary"} variant="determinate" />
                        {taskState === TaskState.PREPARING &&
                            <ProgressText>
                                Hang tight. This might take a minute or so. Please do not close or refresh the browser while your media is uploading.
                            </ProgressText>
                        }
                    </ContentContainer>
                </DialogContent>
                <DialogActions>
                    {onCancel && <Button onClick={onCancel}>Cancel</Button>}
                    {onAction && actionLabel && <Button
                        color="primary"
                        disabled={taskState !== TaskState.FINISHED && taskState !== TaskState.ERROR }
                        onClick={() => onAction()}
                    >
                        {actionLabel}
                    </Button>}
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
