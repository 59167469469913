/**
 * Interface for creating a database connection to load and save our various data.
 */
class Adapter {
    /**
     *
     * @param {Object} options - The root reference, ie: 'slides' or 'presentations'.
     * @param {string} [options.root] - The root reference, ie: 'slides' or 'presentations'.
     * @param {Object} [options.query] - This is a query structure that is interpretted by some adapters.
     * @param {string} [options.id] - The id of the reference. If missing, a new id will be generated.
     * @param {Object} [options.data] - The initial data to set.
     * @param {boolean} [options.ensurePersisted] - This should make sure that the data is saved vs. eventually being saved.
     * @param {boolean} [options.autoSync] - This makes the adapter automatically listen for database changes. Setting this to false
     * will disable saving and make data only load once.
     * @param {boolean} [options.readonly] - This will prevent saving any data out
     */
    connect(options) {
        throw new Error("Adapter.connect not implemented");
    }

    /**
     * Handle for refreshing data.
     */
    refresh() {
        //VOID
    }

    /**
     * @returns {boolean} true if the adapter is connected.
     */
    isConnected() {
        throw new Error("Adapter.isConnected not implemented");
    }

    /**
     * Disconnects the adapter by stopping any listeners, cleaning up state, etc.
     */
    disconnect() {
        throw new Error("Adapter.disconnect not implemented");
    }

    /**
     * Method used to create a new uid for a new object.
     * @param root
     * @returns {string}
     */
    createUid(root = "") {
        throw new Error("Adapter.createUid not implemented");
    }

    /**
     * @param {number} type - the type of action.
     * @param {Object} newData - The new data to change to. Null values mean remove the attribute.
     * @param {Object} originalData - The old data. Null values means the attribute did not exist before.
     */
    update(type, newData, originalData) {
        throw new Error("Adapter.update not implemented");
    }

    /**
     * @param {boolean} autoSync
     * @returns {Promise<void>}
     */
    setAutoSync(autoSync) {
        throw new Error("Adapter.setAutoSync not supported or not implemented");
    }
}

Adapter.TYPE = {
    initialize: 0,
    replace: 1,
    update: 2,
    remove: 3
};

let defaultAdapterClass = null;
let defaultAdapterOptions = null;

Adapter.create = function(options) {
    if (defaultAdapterClass === null) {
        throw new Error("Default Adapter not set.");
    }
    return new defaultAdapterClass(Object.assign({}, defaultAdapterOptions, options));
};

Adapter.setDefaultClass = function(defaultClass, defaultOptions) {
    defaultAdapterClass = defaultClass;
    defaultAdapterOptions = defaultOptions;
};

export default Adapter;
