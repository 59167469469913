const theme = {
    isBuiltIn: true,
    id: "block",
    name: "Block",
    defaultBackgroundColor: "background_light",
    defaultSlideColor: "theme",
    fontBodyFontId: "lato",
    fontBodyWeight: 400,
    fontBodyLetterSpacing: 0.02,
    fontBodyLineHeight: 2,
    fontBodyScaling: 90,
    fontBodyTextTransform: "auto",
    fontHeaderFontId: "lato",
    fontHeaderWeight: 700,
    fontHeaderLetterSpacing: 0.01,
    fontHeaderLineHeight: 1.6,
    fontHeaderScaling: 60,
    fontHeaderTextTransform: "uppercase",
    fontScale: 1,
    fontTitleFontId: "lato",
    fontTitleWeight: 400,
    fontTitleLetterSpacing: 0.02,
    fontTitleLineHeight: 1.9,
    fontTitleScaling: 90,
    fontTitleTextTransform: "auto",
    iconStyle: "classic",
    logoOffset: 0,
    logoPosition: "left",
    logoScale: 1,
    palette_name: "block",
    showFooterByDefault: false,
    showLogo: true,
    showMessage: true,
    showPageNum: true,
    styleBackground: "none",
    styleBodyFont: "sourcesanspro",
    styleColor: "slide",
    styleDecoration: "block_left",
    styleDesign: 1,
    styleEffect: "none",
    styleElementStyle: "outlined",
    styleFontWeight: "heavy",
    styleFonts: 1,
    styleHeaderAlignment: "left",
    styleShape: "none",
    styleTitleFont: "bebasneue",
    styleTitleSlide: "bar_left",
    styleWeight: "light"
};
module.exports = theme;
