import axios from "axios";

/**
 * Uploads a blob into a signed GCS url
 */
export async function uploadBlobToSignedUrl(blob, signedUrl, onProgress = null, contentType = null) {
    const requestOptions = {
        headers: {
            "Content-Type": contentType ?? blob.type
        }
    };

    if (onProgress) {
        requestOptions.onUploadProgress = progressEvent => {
            const percentsCompleted = progressEvent.loaded / progressEvent.total * 100;
            onProgress(percentsCompleted);
        };
    }

    await axios.put(signedUrl, blob, requestOptions);
}
