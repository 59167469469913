import React, { Component } from "reactn";
import { MenuItem, Select, Button, DialogTitle, DialogActions } from "@material-ui/core";
import { Gap20 } from "js/react/components/Gap";
import {
    BeautifulDialog, DialogContent,
} from "js/react/components/Dialogs/BaseDialog";
import { _ } from "js/vendor";
import { GridBox, ScrollBox } from "js/react/components/LayoutGrid";
import { UserProfile } from "js/react/components/Avatar";

export class ConfirmInviteDialog extends Component {
    state = {
        permission: "write",
    }

    handleChangePermissions = event => {
        this.setState({
            permission: event.target.value
        });
    }

    handleAccept = async () => {
        let {
            callback,
            closeDialog,
        } = this.props;
        let {
            permission
        } = this.state;

        callback && callback(permission);
        closeDialog();
    }

    render() {
        let {
            title = "Add the following collaborators?",
            includeSubheader = true,
            hasPermissionEditing = false,
            users,
            closeDialog,
        } = this.props;
        let {
            permission,
        } = this.state;

        return (
            <BeautifulDialog id="confirmInvite" closeDialog={this.props.closeDialog}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {
                        (
                            includeSubheader ||
                            hasPermissionEditing
                        ) &&
                        <>
                            <label>
                                {
                                    hasPermissionEditing &&
                                    <>
                                        These collaborators will have
                                        <Select
                                            value={permission}
                                            onChange={this.handleChangePermissions}
                                            variant="outlined"
                                            style={{ margin: "0px 10px" }}
                                        >
                                            <MenuItem value="read">View</MenuItem>
                                            <MenuItem value="write">Edit</MenuItem>
                                        </Select>
                                        access:
                                    </>
                                }
                                {
                                    !hasPermissionEditing &&
                                    <>
                                        These collaborators will be invited:
                                    </>
                                }
                            </label>
                            <Gap20/>
                        </>
                    }
                    <ScrollBox fillWidth style={{ maxHeight: 370 }}>
                        <GridBox rows>
                            {users.map(member => (
                                <UserProfile
                                    key={member.id}
                                    profile={member}
                                />
                            ))}
                        </GridBox>
                    </ScrollBox>
                </DialogContent>
                <DialogActions>
                    <Button id="btnCancel" onClick={() => closeDialog()}>Cancel</Button>
                    <Button id="btnOk" color="primary" onClick={this.handleAccept}>Ok</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
