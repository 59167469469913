import moment from "moment";
import { GlobalStateController } from "bai-react-global-state";

import { _ } from "js/vendor";
import Api from "js/core/api";
import { FetchStatus } from "js/react/constants";
import { dummyAnalyticsData } from "js/react/views/Analytics/dummyAnalytics";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.ANALYTICS);

const defaultState = {
    analytics: {
        status: {
            type: FetchStatus.UNSET
        }
    }
};

class AnalyticsController extends GlobalStateController {
    get showSelectedLink() {
        return this._state.analytics.showSelectedLink;
    }

    reset() {
        return this._updateState(() => _.cloneDeep(defaultState));
    }

    setPresentation(presentation) {
        return this._updateState({ presentation });
    }

    setDummyData() {
        const dummyData = _.cloneDeep(dummyAnalyticsData);

        dummyData.analytics.viewsPerDay = [];
        for (let day = 0; day < 10; day++) {
            dummyData.analytics.viewsPerDay.push({
                day: moment()
                    .subtract(day, "day")
                    .format("YYYY-MM-DD"),
                views: Math.random() * 20 + 5
            });
        }

        return this._updateState(dummyData);
    }

    async fetchPresentationAnalytics(presentation) {
        let timeZone;
        try {
            timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (err) {
            logger.error(err, "Intl.DateTimeFormat().resolvedOptions().timeZone failed");
        }

        await this._updateState({
            analytics: {
                status: {
                    type: FetchStatus.LOADING
                }
            }
        });

        try {
            await presentation.load();

            const [
                summary,
                viewsPerDay,
                downloadsPerDay,
                aggregates,
                viewsPerSlide,
                views,
                downloads,
            ] = await Promise.all([
                Api.playerAnalytics.post({
                    presentationId: presentation.id,
                    queryName: "summary"
                }),
                Api.playerAnalytics.post({
                    presentationId: presentation.id,
                    queryName: "viewsPerDay",
                    timeZone
                }),
                Api.playerAnalytics.post({
                    presentationId: presentation.id,
                    queryName: "downloadsPerDay",
                    timeZone
                }),
                Api.playerAnalytics.post({
                    presentationId: presentation.id,
                    queryName: "aggregates"
                }),
                Api.playerAnalytics.post({
                    presentationId: presentation.id,
                    queryName: "viewingAggregates"
                }),
                Api.playerAnalytics.post({
                    presentationId: presentation.id,
                    pageSize: 20,
                    pageOffset: 0,
                    queryName: "recentPlays",
                    timeZone
                }),
                Api.playerAnalytics.post({
                    presentationId: presentation.id,
                    pageSize: 20,
                    pageOffset: 0,
                    queryName: "recentDownloads",
                    timeZone
                }),
            ]);

            await this._updateState({
                presentation,
                analytics: {
                    viewTimes: summary[0],
                    completion: summary[1],
                    viewsPerDay,
                    downloadsPerDay,
                    aggregates: aggregates[0],
                    viewsPerSlide: viewsPerSlide,
                    views: {
                        data: views,
                        status: {
                            type: FetchStatus.SUCCESS
                        }
                    },
                    downloads: {
                        data: downloads,
                        status: {
                            type: FetchStatus.SUCCESS
                        }
                    },
                    status: { type: FetchStatus.SUCCESS }
                }
            });
        } catch (err) {
            logger.error(err, "Failed to fetch analytics");
            await this._updateState({
                analytics: {
                    status: {
                        type: FetchStatus.ERROR,
                        message: err.message
                    }
                }
            });
        }
    }

    async fetchMoreRecentViews(pageOffset, pageSize) {
        const { presentation, analytics } = this._state;

        await this._updateState({
            analytics: {
                ...analytics,
                views: {
                    ...analytics.views,
                    status: {
                        type: FetchStatus.LOADING_MORE
                    }
                }
            }
        });

        try {
            const result = await Api.playerAnalytics.post({
                queryName: "recentPlays",
                presentationId: presentation.id,
                pageSize,
                pageOffset,
            });

            const views = {
                data: analytics.views.data.concat(result),
                status: {
                    type: FetchStatus.SUCCESS
                }
            };

            await this._updateState({
                analytics: {
                    ...analytics,
                    views,
                }
            });
        } catch (err) {
            await this._updateState({
                analytics: {
                    ...analytics,
                    views: {
                        status: {
                            type: FetchStatus.ERROR,
                            message: err
                        }
                    }
                }
            });
        }
    }

    async fetchMoreRecentDownloads(pageOffset, pageSize) {
        const { presentation, analytics } = this._state;

        this._updateState({
            analytics: {
                ...analytics,
                downloads: {
                    ...analytics.downloads,
                    status: {
                        type: FetchStatus.LOADING_MORE
                    }
                }
            }
        });

        try {
            const result = await Api.playerAnalytics.post({
                queryName: "recentPlays",
                presentationId: presentation.id,
                pageSize,
                pageOffset,
            });

            const downloads = {
                data: analytics.downloads.data.concat(result),
                status: {
                    type: FetchStatus.SUCCESS
                }
            };

            await this._updateState({
                analytics: {
                    ...analytics,
                    downloads,
                }
            });
        } catch (err) {
            await this._updateState({
                analytics: {
                    ...analytics,
                    downloads: {
                        status: {
                            type: FetchStatus.ERROR,
                            message: err
                        }
                    }
                }
            });
        }
    }

    setSelectedLink(linkId, sessionId) {
        const { analytics } = this._state;

        return this._updateState({
            analytics: {
                ...analytics,
                showSelectedLink: true,
                selectedLink: {
                    status: { type: FetchStatus.LOADING },
                    linkid: linkId,
                    selectedSessionId: sessionId
                }
            }
        });
    }

    clearSelectedLink() {
        const { analytics } = this._state;

        return this._updateState({
            analytics: {
                ...analytics,
                showSelectedLink: false
            }
        });
    }

    async fetchLinkAnalytics() {
        const { presentation, analytics } = this._state;
        const selectedLink = analytics.selectedLink;

        await this._updateState({
            analytics: {
                ...analytics,
                selectedLink: {
                    ...selectedLink,
                    status: { type: FetchStatus.LOADING }
                }
            }
        });

        try {
            const result = await Promise.all([
                Api.playerAnalytics.post({
                    queryName: "linkSummary",
                    presentationId: presentation.id,
                    linkid: selectedLink.linkid
                }),
                Api.playerAnalytics.post({
                    queryName: "recentPlays",
                    linkid: selectedLink.linkid,
                    presentationId: presentation.id,
                    pageSize: 50,
                    pageOffset: 0
                })
            ]);

            let summary = result[0][0][0];
            let completed = result[0][1][0];
            let viewTimes = result[0][2];
            let views = result[1];

            await this._updateState({
                analytics: {
                    ...analytics,
                    selectedLink: {
                        ...selectedLink,
                        totalViews: parseInt(summary.totalviews),
                        uniqueViewers: parseInt(summary.uniqueviewers),
                        totalTime: parseInt(summary.totaltime),
                        completed: parseInt(completed.count),
                        viewTimes: viewTimes,
                        views: {
                            status: { type: FetchStatus.SUCCESS },
                            data: views
                        },
                        status: { type: FetchStatus.SUCCESS }
                    }
                }
            });
        } catch (err) {
            await this._updateState({
                analytics: {
                    ...analytics,
                    selectedLink: {
                        ...selectedLink,
                        status: {
                            type: FetchStatus.ERROR,
                            message: err.message
                        }
                    }
                }
            });
        }
    }

    async fetchViewsForUser(pageSize, pageOffset) {
        const { presentation, analytics } = this._state;
        const selectedLink = analytics.selectedLink;
        const views = (selectedLink.views && selectedLink.views.data) || [];

        await this._updateState({
            analytics: {
                ...analytics,
                selectedLink: {
                    ...selectedLink,
                    views: {
                        status: { type: FetchStatus.LOADING },
                        data: views
                    }
                }
            }
        });

        try {
            const result = await Api.playerAnalytics.post({
                linkid: selectedLink.linkid,
                presentationId: presentation.id,
                pageSize: pageSize,
                pageOffset: pageOffset,
                queryName: "recentPlays"
            });

            await this._updateState({
                analytics: {
                    ...analytics,
                    selectedLink: {
                        ...selectedLink,
                        selectedSessionId: result[0].groupid,
                        views: {
                            status: { type: FetchStatus.SUCCESS },
                            data: views.concat(result)
                        }
                    }
                }
            });
        } catch (err) {
            await this._updateState({
                analytics: {
                    ...analytics,
                    selectedLink: {
                        ...selectedLink,
                        views: {
                            status: {
                                type: FetchStatus.ERROR,
                                message: err.message
                            }
                        }
                    }
                }
            });
        }
    }

    setActiveSession(sessionId) {
        const { analytics } = this._state;

        return this._updateState({
            analytics: {
                ...analytics,
                selectedLink: {
                    ...analytics.selectedLink,
                    selectedSessionId: sessionId,
                    sessionDetail: {
                        status: { type: FetchStatus.LOADING },
                        data: null
                    }
                }
            }
        });
    }

    async fetchSessionData(sessionId) {
        const { presentation, analytics } = this._state;

        await this._updateState({
            analytics: {
                ...analytics,
                selectedLink: {
                    ...analytics.selectedLink,
                    selectedSessionId: sessionId,
                    sessionDetail: {
                        status: { type: FetchStatus.LOADING },
                        data: null
                    }
                }
            }
        });

        try {
            const result = await Api.playerAnalytics.post({
                presentationId: presentation.id,
                sessionId: sessionId,
                queryName: "sessionDetail"
            });

            await this._updateState({
                analytics: {
                    ...analytics,
                    selectedLink: {
                        ...analytics.selectedLink,
                        selectedSessionId: sessionId,
                        sessionDetail: {
                            status: { type: FetchStatus.SUCCESS },
                            data: result
                        }
                    }
                }
            });
        } catch (err) {
            await this._updateState({
                analytics: {
                    ...analytics,
                    selectedLink: {
                        ...analytics.selectedLink,
                        selectedSessionId: sessionId,
                        sessionDetail: {
                            status: {
                                type: FetchStatus.ERROR,
                                message: err.message
                            }
                        }
                    }
                }
            });
        }
    }
}

export default new AnalyticsController(_.cloneDeep(defaultState));
