import React from "react";
import ReactDOM from "react-dom";

//
// entry point
//

const root = document.createElement("div");
root.id = "react-root";
root.style.display = "contents";
document.body.appendChild(root);

(async () => {
    const { init } = await import(/* webpackMode: "eager" */ "js/firebase/init");
    await init();

    await import(/* webpackMode: "eager" */ "js/preload");

    const { default: Adapter } = await import(/* webpackMode: "eager" */ "js/core/storage/adapter");
    const { default: FirebaseAdapter } = await import(/* webpackMode: "eager" */ "js/core/storage/firebaseAdapter");
    Adapter.setDefaultClass(FirebaseAdapter);

    const { default: PPTAddin } = await import(/* webpackMode: "eager" */ "legacy-js/react/views/PPTAddin/PPTAddin");
    ReactDOM.render(<PPTAddin />, root);
})();

