/* eslint-disable no-console */

import { Backbone, _ } from "js/vendor";

const OFFLINE_CHECK_INTERVAL_SECONDS = 3;

const isConnected = _.extend({ connected: !window.isOfflinePlayer, checkInterval: null, api: null, triggerIsOnlineChange: connected => { } }, Backbone.Events);

isConnected.triggerIsOnlineChange = function(connected) {
    if (connected === isConnected.connected) {
        // No change, ignoring
        return;
    }

    isConnected.connected = connected;
    isConnected.trigger("change");

    if (connected) {
        // Became connected
        clearInterval(isConnected.checkInterval);
        isConnected.checkInterval = null;

        return;
    }

    // Became disconnected
    console.log(`[isConnected] offline... will ping server every ${OFFLINE_CHECK_INTERVAL_SECONDS} seconds`);
    isConnected.checkInterval = setInterval(
        async () => {
            const visibilityState = document.visibilityState;
            if (visibilityState !== "visible") {
                // Don't ping server when tab is hidden
                return;
            }

            if (!isConnected.api) {
                // Deferred import to avoid circular dependency
                const { default: api } = await import("js/core/api");
                isConnected.api = api;
            }

            try {
                await isConnected.api.dbHealth.get();
            } catch {
                console.log(`[isConnected] offline... continuing to ping server every ${OFFLINE_CHECK_INTERVAL_SECONDS} seconds`);
                return;
            }

            console.log("[isConnected] back online!");
            isConnected.triggerIsOnlineChange(true);
        },
        OFFLINE_CHECK_INTERVAL_SECONDS * 1000
    );
};

export default isConnected;
