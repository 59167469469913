import Api from "js/core/api";
import { ds } from "js/core/models/dataService";
import * as geom from "js/core/utilities/geom";
import { AssetType } from "common/constants";
import { trackActivity } from "js/core/utilities/utilities";
import getLogger from "js/core/logger";

const logger = getLogger();

export async function getVideoAsset(hostedVideoUrl, videoAssetId, backgroundVideoOnly = false) {
    let error;

    const isUpload = !!videoAssetId;
    const isYoutube = !!hostedVideoUrl && (hostedVideoUrl.includes("youtube") || hostedVideoUrl.includes("youtu.be"));
    const isVimeo = !!hostedVideoUrl && hostedVideoUrl.includes("vimeo");
    const serviceName = (isVimeo ? "Vimeo" : (isYoutube ? "Youtube" : "Server"));
    const videoType = isUpload ? "upload" : (isVimeo ? "vimeo" : (isYoutube ? "youtube" : hostedVideoUrl));

    // Default the video size
    let size = new geom.Size(800, 450);
    let duration = 100;

    const assetAttributes = {};

    if (isUpload) {
        try {
            const assetModel = await ds.assets.getAssetById(videoAssetId, "video");
            await assetModel.load();
            Object.assign(assetAttributes, assetModel.attributes);
            size = new geom.Size(assetAttributes.w, assetAttributes.h);
            duration = assetAttributes.duration;
        } catch (err) {
            logger.error(err, "[ImportMediaDialog] getVideoAsset() failed to load video asset", { videoAssetId });
            error = err.message;
        }
    } else {
        try {
            const oembedUrl = isVimeo
                ? `https://www.vimeo.com/api/oembed.json?url=${encodeURIComponent(hostedVideoUrl)}&byline=false&portrait=false&title=false`
                : `https://www.youtube.com/oembed?url=${encodeURIComponent(hostedVideoUrl)}&format=json`;
            const response = await Api.oembed.post({ url: oembedUrl });
            size = new geom.Size(response.width, response.height);
            duration = response.duration || duration;
            assetAttributes.url = hostedVideoUrl;
        } catch (err) {
            // Since we are processing a user supplied url, we're going to display errors as warnings
            logger.warn("[ImportMediaDialog] Api.oembed.post() failed", { errorMessage: err.message });
            if (err.message === "Bad oembed request: Bad Request") {
                error = "Invalid video url";
            } else {
                error = err.message;
            }
        }
    }

    if (error) {
        return { asset: null, error, serviceName };
    }

    trackActivity("Video", "AddedToSlide", null, null, {
        "slide_id": ds.selection.slide?.id,
        "video_type": videoType,
        "url": hostedVideoUrl,
        "id": videoAssetId,
    }, { audit: true });

    const controls = !backgroundVideoOnly && duration > 30;

    return {
        asset: {
            ...assetAttributes,
            hostedVideoUrl,
            id: videoAssetId,
            type: AssetType.VIDEO,
            size,
            assetProps: {
                muted: true,
                duration,
                startTime: 0,
                endTime: duration,
                autoPlay: !controls,
                loop: !controls,
                speed: 1.0,
                controls,
            },
        },
        error: null,
    };
}
