import { GlobalStateController } from "bai-react-global-state";
import { v4 as uuid } from "uuid";

import { TextFocusType } from "common/constants";
import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";
import { ShowWarningDialog } from "../../react/components/Dialogs/BaseDialog";

export class SelectionLayerController extends GlobalStateController {
    constructor({ canvasController }) {
        super({
            canvasController,
            selectedElements: [],
            elementsWithSelection: [],
            selectedElementsSelectionStates: {},
            rolloverElements: [],
            primaryElement: canvasController.primaryElement,
            selectionLayerController: null,
            dragBlockState: null,
            dragElementState: null,
            freezeSelection: false,
            hilitedElements: [],
            useCanvasDimmerForHilitedElements: false,
            freezeSelectionLayer: false
        });
        this._state.selectionLayerController = this;

        this._state.canvasController.onStateDidUpdate(this._onCanvasControllerStateDidUpdate);

        this.instanceId = uuid();

        this._onSelectionChangedCallbacks = [];

        this._prevMouseDownAt = 0;
        this._prevMouseDownPosition = new geom.Point(0, 0);
    }

    get canvasController() {
        return this._state.canvasController;
    }

    get selectedElements() {
        return this._state.selectedElements;
    }

    get elementsWithSelection() {
        return this._state.elementsWithSelection;
    }

    get rolloverElements() {
        return this._state.rolloverElements;
    }

    get isDraggingBlock() {
        return !!this._state.dragBlockState;
    }

    get isDraggingElement() {
        return !!this._state.dragElementState;
    }

    get dragElementType() {
        return this._state.dragElementState?.dragProps?.dragType ?? null;
    }

    get draggingElements() {
        return this._state.dragElementState?.sourceElements ?? [];
    }

    get draggingBlocks() {
        return this._state.dragBlockState?.sourceBlocks ?? [];
    }

    get dragElementOpacity() {
        return this._state.dragElementState?.dragProps?.dragOpacity ?? 1;
    }

    get prevMouseDownAt() {
        return this._prevMouseDownAt;
    }

    set prevMouseDownAt(prevMouseDownAt) {
        this._prevMouseDownAt = prevMouseDownAt;
    }

    get prevMouseDownPosition() {
        return this._prevMouseDownPosition;
    }

    set prevMouseDownPosition(prevMouseDownPosition) {
        this._prevMouseDownPosition = prevMouseDownPosition;
    }

    onSelectionChanged(callback) {
        this._onSelectionChangedCallbacks.push(callback);

        return () => {
            this._onSelectionChangedCallbacks = this._onSelectionChangedCallbacks.filter(c => c !== callback);
        };
    }

    offSelectionChanged(callback) {
        this._onSelectionChangedCallbacks = this._onSelectionChangedCallbacks.filter(c => c !== callback);
    }

    setFreezeSelectionLayer(freezeSelectionLayer) {
        return this._updateState({ freezeSelectionLayer });
    }

    _filterOutInvalidElements(elements) {
        const { canvasController } = this._state;

        if (elements.length === 0) {
            return [];
        }

        if (!canvasController.canvas.layouter) {
            return [];
        }

        const allValidElements = canvasController.canvas.getElements().filter(element => !element.isDeleted && !element.isReplaced);

        const validElements = _.intersection(elements, allValidElements).filter(Boolean);
        const invalidElements = _.without(elements, ...allValidElements).filter(Boolean);

        if (invalidElements.length === 0) {
            // All good, all elements are still there
            return validElements;
        }

        invalidElements
            .filter(element => element.isReplaced && element.originalId && allValidElements.includes(element.parentElement))
            .forEach(element => {
                const parentElement = element.parentElement;
                const newElement = parentElement.elements[element.originalId];
                if (newElement && allValidElements.includes(newElement)) {
                    // Replace the invalid element with the new element
                    validElements.push(newElement);
                }
            });

        return _.uniq(validElements);
    }

    _onCanvasControllerStateDidUpdate = ({ isCanvasGenerating: wasCanvasGenerating }, { isCanvasGenerating, isCanvasRendered }) => {
        const { selectedElements, rolloverElements, hilitedElements, canvasController, primaryElement, selectedElementsSelectionStates, elementsWithSelection } = this._state;

        // Was generating and finished generation
        if (wasCanvasGenerating && !isCanvasGenerating && isCanvasRendered) {
            const stateUpdates = {
                selectedElements: this._filterOutInvalidElements(selectedElements),
                rolloverElements: this._filterOutInvalidElements(rolloverElements),
                hilitedElements: this._filterOutInvalidElements(hilitedElements),
                primaryElement: canvasController.primaryElement
            };

            if (
                !_.isEqual(stateUpdates.selectedElements.map(el => el.uniquePath), selectedElements.map(el => el.uniquePath)) ||
                !_.isEqual(stateUpdates.rolloverElements.map(el => el.uniquePath), rolloverElements.map(el => el.uniquePath)) ||
                !_.isEqual(stateUpdates.hilitedElements.map(el => el.uniquePath), hilitedElements.map(el => el.uniquePath)) ||
                stateUpdates.primaryElement !== primaryElement
            ) {
                if (stateUpdates.selectedElements.length === 0 && elementsWithSelection.length > 0) {
                    const selectableElement = this._filterOutInvalidElements(elementsWithSelection).find(element => element.canSelect || element.doubleClickToSelect);
                    if (selectableElement) {
                        stateUpdates.selectedElements = [selectableElement];
                    }
                }

                this._updateState({ ...stateUpdates, selectedElementsSelectionStates: _.pick(selectedElementsSelectionStates, stateUpdates.selectedElements.map(el => el.uniquePath)) ?? {} });
            }
        }
    }

    _stateDidUpdate(prevState) {
        const { selectedElements, primaryElement } = this._state;
        const { selectedElements: prevSelectedElements } = prevState;

        if (prevSelectedElements.length !== selectedElements.length || !prevSelectedElements.every((element, index) => element === selectedElements[index])) {
            this._onSelectionChangedCallbacks.forEach(callback => callback(prevSelectedElements, selectedElements));

            if (selectedElements.filter(element => element !== primaryElement).length === 0) {
                if (this.canvasController.isLockedForCollaborators()) {
                    this.canvasController.unlockSlideForCollaborators();
                }
                return;
            }

            if (!this.canvasController.isLockedForCollaborators()) {
                this.canvasController.lockSlideForCollaborators(15);
            }
        }
    }

    _stateWillUpdate(nextState) {
        const { selectedElements, primaryElement } = nextState;
        const { selectedElements: prevSelectedElements } = this._state;

        if (prevSelectedElements.length !== selectedElements.length || !prevSelectedElements.every((element, index) => element === selectedElements[index])) {
            // All elements that need UI to be rendered
            const elementsWithSelection = [];
            selectedElements.forEach(element => {
                for (const parentElement of element.getElementPath()) {
                    if (parentElement === element) {
                        if (!element.passThroughSelection) {
                            break;
                        }

                        continue;
                    }

                    if ((element.canSelect || element.doubleClickToSelect) && parentElement.canSelect) {
                        elementsWithSelection.push(parentElement);
                    } else if (parentElement.isInstanceOf("TextElement")) {
                        elementsWithSelection.push(parentElement);
                    }

                    if (!parentElement.passThroughSelection) {
                        break;
                    }
                }
            });

            if (selectedElements.length === 0) {
                // Always show primary element selection when nothing is selected
                elementsWithSelection.push(primaryElement);
            }

            nextState.elementsWithSelection = elementsWithSelection;
        }

        return nextState;
    }

    setFreezeSelection(freezeSelection) {
        return this._updateState({ freezeSelection });
    }

    reset() {
        return this._updateState({
            selectedElements: [],
            rolloverElements: []
        });
    }

    setSelectedElements(elements) {
        const { selectedElements } = this._state;

        elements = this._filterOutInvalidElements(elements);

        if (selectedElements.length === elements.length && selectedElements.every((element, index) => element === elements[index])) {
            return;
        }

        return this._updateState({ selectedElements: elements, selectedElementsSelectionStates: {} });
    }

    setHilitedElements(elements) {
        const { hilitedElements } = this._state;

        elements = this._filterOutInvalidElements(elements);

        if (hilitedElements.length === elements.length && hilitedElements.every((element, index) => element === elements[index])) {
            return;
        }

        return this._updateState({ hilitedElements: elements });
    }

    setSelectedElementState(elementUniquePath, state) {
        const { selectedElements, selectedElementsSelectionStates } = this._state;

        if (!selectedElements.some(el => el.uniquePath)) {
            throw new Error(`Element with unique path ${elementUniquePath} is not selected`);
        }

        return this._updateState({ selectedElementsSelectionStates: { ...selectedElementsSelectionStates, [elementUniquePath]: state } });
    }

    setRolloverElements(elements) {
        const { rolloverElements } = this._state;

        elements = this._filterOutInvalidElements(elements);

        if (rolloverElements.length === elements.length && (rolloverElements.length === 0 || rolloverElements.every((element, index) => element === elements[index]))) {
            return;
        }

        return this._updateState({ rolloverElements: elements });
    }

    getElementsForSelection(event, isDoubleClick) {
        const { canvasController, selectedElements } = this._state;

        const canvas = canvasController.canvas;

        const position = new geom.Point(event.clientX, event.clientY);

        // Special case for text elements as contenteditable "sensitive" area can extend beyond the text element selection bounds
        if (event.target.tagName === "DIV" && event.target.getAttribute("contenteditable") === "true") {
            const textElement = canvas.getElementsByType("TextElement", true).find(element =>
                element.blockContainerRef?.current?.blocks.some(block => block.ref.current === event.target)
            );
            if (textElement?.canSelect && textElement.getElementPath().every(element => element.canSelectChildElements)) {
                return [textElement];
            }
        }

        const elementsUnderMouse = canvas.findSelectableElementsAtPoint(position.x, position.y);
        const elementUnderMouse = elementsUnderMouse[0];

        // Handle double clicks
        if (isDoubleClick) {
            // Double click on a double clickable element
            const doubleClickableElement = canvas.findDoubleClickableElementsAtPoint(position.x, position.y)[0];

            if (doubleClickableElement) {
                if (
                    !elementUnderMouse ||
                    !selectedElements.some(sel => sel === elementUnderMouse || elementUnderMouse.isChildOf(sel)) ||
                    (elementUnderMouse && doubleClickableElement.isChildOf(elementUnderMouse))
                ) {
                    return [doubleClickableElement];
                }
            }

            // Double click of an element which is a part of a selected group
            if (elementUnderMouse && elementUnderMouse.groupId && selectedElements.every(element => element.groupId === elementUnderMouse.groupId)) {
                return [elementUnderMouse];
            }
        }

        // No elements under mouse
        if (!elementUnderMouse) {
            // Keep selection if click is inside the selection bounds
            if (selectedElements.length > 1) {
                const selectionBounds = selectedElements
                    .filter(element => !!element.calculatedProps)
                    .reduce((bounds, element) => bounds ? bounds.union(element.selectionBounds.inflate(element.rolloverPadding)) : element.selectionBounds.inflate(element.rolloverPadding), null);
                if (canvas.isPointInsideBounds(selectionBounds, position.x, position.y)) {
                    return selectedElements;
                }
            }

            return [];
        }

        // Already selected as a part of multiple selection
        if (selectedElements.includes(elementUnderMouse)) {
            if (event.shiftKey && !event.altKey) {
                // Shift click to remove from selection
                return selectedElements.filter(element => element !== elementUnderMouse);
            }
            return selectedElements;
        }

        if (elementUnderMouse.groupId) {
            // Single click within the same group -> select the element
            const selectedGroups = [...new Set(selectedElements.map(element => element.groupId))];
            if (selectedGroups.length === 1 && selectedGroups[0] === elementUnderMouse.groupId) {
                return [elementUnderMouse];
            }

            // Single click within a different group -> select the group
            return elementUnderMouse.getGroupElements();
        }

        if (event.shiftKey && !event.altKey) {
            // Shift click to add to selection
            if (selectedElements.length > 0 && selectedElements.every(element => element.canMultiSelect) && elementUnderMouse.canMultiSelect) {
                return [...selectedElements, elementUnderMouse];
            }
        }

        return [elementUnderMouse];
    }

    getElementsForRollover(event) {
        const { canvasController } = this._state;

        const elements = canvasController.canvas.findRolloverElementsAtPoint(event.clientX, event.clientY);
        return elements.slice(0, 1);
    }

    async selectTextElementBlock(textElement, blockId, focusType = TextFocusType.START) {
        const { selectedElements } = this._state;

        if (!blockId) {
            blockId = textElement.model.text.blocks[0].id;
        }

        if (selectedElements.length !== 1 || selectedElements[0] !== textElement) {
            await this.setSelectedElements([textElement]);
        }

        await textElement.uiRefs.selectionRef.current.focusBlock(blockId, focusType);
    }

    async registerBlockDrag(sourceAuthoringBlockEditor, sourceElement, sourceBlocks, rolloverBlock) {
        await this._updateState({
            dragBlockState: {
                sourceAuthoringBlockEditor,
                sourceElement,
                sourceBlocks,
                rolloverBlock
            },
            rolloverElements: []
        });

        if (!this.canvasController.isCanvasGenerating) {
            this.canvasController.refreshRender();
        }
    }

    async registerElementDrag(sourceElements, dragProps, startDragEvent = null) {
        await this._updateState({
            dragElementState: {
                sourceElements,
                dragProps,
                startDragEvent
            },
            rolloverElements: []
        });

        if (!this.canvasController.isCanvasGenerating) {
            this.canvasController.refreshRender();
        }
    }

    async unregisterBlockDrag() {
        await this._updateState({ dragBlockState: null });

        if (!this.canvasController.isCanvasGenerating) {
            this.canvasController.refreshRender();
        }
    }

    async unregisterElementDrag() {
        await this._updateState({ dragElementState: null });

        if (!this.canvasController.isCanvasGenerating) {
            this.canvasController.refreshRender();
        }
    }

    showLayoutError(err) {
        if (err.name === "LayoutNotFitError") {
            ShowWarningDialog({ title: "Your change doesn't fit on this slide.", message: "Try adjusting any headers, sidebars, trays, or other layout elements before making this change." });
        } else {
            ShowWarningDialog({ title: "An unexpected error occurred on this slide.", message: err.message });
        }
    }
}
