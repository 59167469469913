import { EndpointDefinition } from "../_sdk/types/common";

export const apiName = "experiments";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getExperiments: {
        route: "/experiments",
        method: "GET"
    },
    initializeAllExperiments: {
        route: "/experiments/initializeAll",
        method: "POST"
    }
};
