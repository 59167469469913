// Everything in this file must be imported or prefixed with window
// to avoid "Uncaught ReferenceError: <variable> is not defined"

const $ = window.$;
const _ = window._;
const Backbone = window.Backbone;
const Hammer = window.Hammer;
const numeral = window.numeral;
const Papa = window.Papa;
const SVG = window.SVG;
const tinycolor = window.tinycolor;
const WordCloud = window.WordCloud;
const Lethargy = window.Lethargy;
const PptxGenJS = window.PptxGenJS;
const opentype = window.opentype;
const JSZip = window.JSZip;

export {
    $,
    _,
    Backbone,
    Hammer,
    Lethargy,
    numeral,
    Papa,
    SVG,
    tinycolor,
    WordCloud,
    PptxGenJS,
    opentype,
    JSZip
};
