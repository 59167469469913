import { EndpointDefinition } from "../_sdk/types/common";

export const apiName = "workspaces";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getWorkspaces: {
        route: "/workspaces",
        method: "GET"
    },
    getWorkspace: {
        route: "/workspaces/:id",
        method: "GET"
    },
    updateWorkspace: {
        route: "/workspaces/:id",
        method: "PUT"
    },
    getWorkspaceResources: {
        route: "/workspaces/:workspaceId/resources",
        method: "GET"
    },
    getWorkspaceResource: {
        route: "/workspaces/:workspaceId/resources/:resourceId",
        method: "GET"
    }
};
