// because we mix namespaces will ES6 modules, certain modules need to be pre-loaded
// in a given order, these are listed below

import "js/core/utilities/extensions";
import "js/core/debug"; // window
import "js/core/profiler"; // window
import "js/core/utilities/audioUtilities"; // global state
import "js/core/utilities/colorpicker"; // third party module
import "js/core/utilities/extensions"; // extensions
import "js/core/utilities/isConnected"; // window
import "js/core/utilities/svg_extensions"; // extensions
