import Cookies from "js-cookie";

import { auth } from "js/firebase/auth";
import { openWindowAndWaitForItToBeClosed } from "js/core/utilities/selfClosingWindow";

export class OneDriveAuthError extends Error { }
export class OneDriveAuthFlowWasInterruptedError extends OneDriveAuthError { }

interface OneDriveAuthTokens {
    accessToken: string,
    idToken: string,
}

export const SCOPES = ["openid", "profile", "offline_access", "User.Read", "Files.Read.All"];

/**
 * Grants our app offline access for the given scope, executes the redirect ouath flow in a separate window
 * and saves the tokens
 */
export async function grantOfflineAccess(): Promise<OneDriveAuthTokens> {
    const userIdToken = await auth().currentUser.getIdToken();
    Cookies.set("user_id_token", userIdToken, { sameSite: "strict", expires: new Date(Date.now() + 60 * 1000) });

    const url = `${window.location.origin}/oneDrive/auth` +
        `?continueUrl=${encodeURIComponent("/close")}` +
        "&requestOfflineAccess=true";

    const lastSearchParams = await openWindowAndWaitForItToBeClosed(url);

    if (lastSearchParams.has("onedrive_auth_error")) {
        throw new OneDriveAuthError(lastSearchParams.get("onedrive_auth_error"));
    }

    if (lastSearchParams.get("onedrive_auth_success") !== "true") {
        throw new OneDriveAuthFlowWasInterruptedError();
    }

    const accessToken = Cookies.get("onedrive_access_token");
    Cookies.remove("onedrive_access_token");

    const idToken = Cookies.get("onedrive_id_token");
    Cookies.remove("onedrive_id_token");

    return { accessToken, idToken };
}
