import React, { Component } from "react";
import { Select } from "@material-ui/core";
import styled from "styled-components";

const StyledSelect = styled(Select)`
    .MuiSelect-root {
        background: white;
        font-family: "Source Sans Pro", sans-serif;
    }

    .MuiSelect-select {
        text-transform: capitalize;
        padding: ${props => props.small ? "5px 20px 5px 10px" : "6px 32px 6px 12px"};
        font-size: ${props => props.small ? "11px" : "13px"};

        .tooltip-badge {
            display: none;
        }
    }
`;

export class Dropdown extends Component {
    render() {
        const { onChange, overrideStyledSelect = {}, children, ...props } = this.props;
        return (
            <StyledSelect
                style={{
                    ...overrideStyledSelect
                }}
                variant="outlined"
                {...props}
                onChange={event => onChange(event.target.value)}
            >
                {children}
            </StyledSelect>
        );
    }
}

