import React, { Component } from "reactn";
import { withStyles } from "@material-ui/core/styles";
import {
    Snackbar,
    Avatar,
    Button,
    Icon
} from "@material-ui/core";

import { Notification, SlideStatusProps } from "common/constants";
const { EventType, EventAction } = Notification;
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import { getStaticUrl } from "js/config";
import ProvideAccessDialog from "js/react/views/ProvideAccessDialog/ProvideAccessDialog";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import getLogger, { LogGroup } from "js/core/logger";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import AppController from "js/core/AppController";
import { renderReactDialogFromJSX } from "../renderReactRoot";

const logger = getLogger(LogGroup.NOTIFICATION);

const logo = getStaticUrl("/images/beautifulai-logos/beautifulai-avatar.svg");

const styles = {
    snackBar: {
        pointerEvents: "auto"
    },
    contentContainer: {
        background: "#313131",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        fontFamily: "Source Sans Pro",
        borderRadius: "4px",
        padding: "6px 24px",
        boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)"
    },
    messageAndActionsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    author: {
        color: "#ccc",
        marginLeft: "15px",
        maxWidth: "150px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    avatar: {
        color: "#ccc",
        backgroundColor: "#424242"
    },
    textContainer: {
        marginLeft: "15px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "600px"
    },
    resourceName: {
        marginLeft: "4px",
        color: "#fff",
        fontWeight: 800
    },
    buttonsContainer: {
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginLeft: "15px",
        width: "150px",
        "& button": {
            fontSize: "9px",
            height: "20px",
            padding: 0
        }
    },
    buttonIcon: {
        fontSize: "18px",
        marginRight: "2px",
        height: "16px"
    },
    openPresentationButton: {
        color: "#ccc"
    },
    markAsReadButton: {
        color: "#ccc"
    }
};

class NotificationAlert extends Component {
    state = {
        isOpen: true
    };

    markAsRead() {
        const { id, closeDialog } = this.props;

        AppController.notificationsService.markAsRead(id)
            .then(() => {
                this.setState({ isOpen: false });
                closeDialog?.();
            })
            .catch(err => logger.error(err, "[NotificationAlert] AppController.notificationsService.markAsRead() failed", { id }));
    }

    render() {
        const {
            classes,
            closeDialog,
            userPresentationId,
            event: {
                type,
                action,
                triggeredByUser,
                senderIsBai,
                presentationName,
                presentationPrivacy,
                presentationId,
                presentationUrl,
                slideId,
                slideIndex,
                slideStatus,
                permissionType,
                teamName,
                teamInviteUrl,
                isPresentationLinkDeleted,
                amount,
                isTaggedInComment,
                isAssignedToSlide,
                presentationLinkName,
                username,
                exportType,
            }
        } = this.props;
        const { isOpen } = this.state;

        const isEditorOpen = !!app.mainView.editorView;
        const isPresentationSettingsOpen = isEditorOpen ? app.mainView.editorView.isPresentationSettingsOpen : false;
        const isCommentsOpen = app.mainView.editorView ? app.mainView.editorView.isCommentsOpen : false;

        const currentPresentationId = ds.selection.presentation ? ds.selection.presentation.id : null;
        const currentSlideId = ds.selection.slide ? ds.selection.slide.id : null;

        const editedPresentationId = userPresentationId || presentationId;

        const getTextSpan = () => {
            let slideName;
            if (isEditorOpen && !isPresentationSettingsOpen && editedPresentationId === currentPresentationId && slideId === currentSlideId) {
                slideName = "this slide";
            } else {
                slideName = typeof slideIndex === "number"
                    ? `slide #${slideIndex + 1}`
                    : "a removed slide";
            }

            let presentationText;
            if (editedPresentationId !== currentPresentationId) {
                presentationText = `${slideName} of ${presentationName}`;
            } else {
                presentationText = slideName;
            }

            if (type === EventType.ASSIGN) {
                return <span className={classes.textContainer}><span>assigned you to</span><span className={classes.resourceName}>{presentationText}</span></span>;
            }

            // Adjusting slide name and presentations text if user is assigned to it
            if (isAssignedToSlide && typeof slideIndex === "number") {
                slideName = "your slide";

                if (editedPresentationId !== currentPresentationId) {
                    presentationText = `${slideName} of ${presentationName}`;
                } else {
                    presentationText = slideName;
                }
            }

            if (type === EventType.COMMENT) {
                if (isTaggedInComment) {
                    return <span className={classes.textContainer}><span>tagged you in a comment on</span><span className={classes.resourceName}>{presentationText}</span></span>;
                } else {
                    return <span className={classes.textContainer}><span>commented on</span><span className={classes.resourceName}>{presentationText}</span></span>;
                }
            }

            if (type === EventType.EDIT) {
                if (action === EventAction.SLIDE_EDITED) {
                    if (editedPresentationId !== currentPresentationId) {
                        if (userPresentationId) {
                            return <span className={classes.textContainer}><span>updated a team slide that affects</span><span className={classes.resourceName}>{slideName} of {presentationName}</span></span>;
                        } else {
                            return <span className={classes.textContainer}><span>edited</span><span className={classes.resourceName}>{slideName} of {presentationName}</span></span>;
                        }
                    } else {
                        return <span className={classes.textContainer}><span>edited</span><span className={classes.resourceName}>{slideName}</span></span>;
                    }
                }
                if (action === EventAction.SLIDE_MOVED) {
                    if (editedPresentationId !== currentPresentationId) {
                        return <span className={classes.textContainer}><span>moved</span><span className={classes.resourceName}>{slideName} of {presentationName}</span></span>;
                    } else {
                        return <span className={classes.textContainer}><span>moved</span><span className={classes.resourceName}>{slideName}</span></span>;
                    }
                }
                if (action === EventAction.SLIDE_ADDED) {
                    if (editedPresentationId !== currentPresentationId) {
                        return <span className={classes.textContainer}><span>added</span><span className={classes.resourceName}>{slideName} to {presentationName}</span></span>;
                    } else {
                        return <span className={classes.textContainer}><span>added</span><span className={classes.resourceName}>{slideName}</span></span>;
                    }
                }
                if (action === EventAction.SLIDE_REMOVED) {
                    if (editedPresentationId !== currentPresentationId) {
                        return <span className={classes.textContainer}><span>removed</span><span className={classes.resourceName}>a slide from {presentationName}</span></span>;
                    } else {
                        return <span className={classes.textContainer}><span>removed</span><span className={classes.resourceName}>a slide</span></span>;
                    }
                }
                if (action === EventAction.SLIDE_STATUS_CHANGED) {
                    return <span className={classes.textContainer}><span>changed the status of</span><span className={classes.resourceName}>{slideName}</span> to <span className={classes.resourceName}>{SlideStatusProps[slideStatus].label}</span></span>;
                }
                if (action === EventAction.PRESENTATION_RENAMED) {
                    if (editedPresentationId !== currentPresentationId) {
                        return <span className={classes.textContainer}><span>renamed</span><span className={classes.resourceName}>{presentationName}</span></span>;
                    } else {
                        return <span className={classes.textContainer}><span>renamed</span><span className={classes.resourceName}>this presentation</span></span>;
                    }
                }
                if (action === EventAction.PRESENTATION_THEME_CHANGED) {
                    if (editedPresentationId !== currentPresentationId) {
                        return <span className={classes.textContainer}><span>changed the theme of</span><span className={classes.resourceName}>{presentationName}</span></span>;
                    } else {
                        return <span className={classes.textContainer}><span>changed the theme of</span><span className={classes.resourceName}>this presentation</span></span>;
                    }
                }
                if (action === EventAction.PRESENTATION_PRIVACY_CHANGED) {
                    if (editedPresentationId !== currentPresentationId) {
                        return <span className={classes.textContainer}><span>changed the privacy settings of</span><span className={classes.resourceName}>{presentationName}</span> to <span>{presentationPrivacy}</span></span>;
                    } else {
                        return <span className={classes.textContainer}><span>changed the privacy settings of</span><span className={classes.resourceName}>this presentation</span> to <span>{presentationPrivacy}</span></span>;
                    }
                }
            }

            if (type === EventType.PERMISSION) {
                if (action === EventAction.PERMISSION_GRANTED) {
                    const permissionWord = permissionType === "write" ? "edit" : "view";
                    return <span><span className={classes.textContainer}>invited you to {permissionWord}</span><span className={classes.resourceName}>{presentationName}</span></span>;
                }
                if (action === EventAction.PERMISSION_REQUESTED) {
                    const permissionWord = permissionType === "write" ? "edit" : "view";
                    return <span><span className={classes.textContainer}>requested to {permissionWord} </span><span className={classes.resourceName}>{presentationName}</span></span>;
                }
            }

            if (type === EventType.TEAM) {
                if (action === EventAction.INVITED_TO_TEAM) {
                    return <span><span className={classes.textContainer}>invited you to join</span><span className={classes.resourceName}>{teamName} team</span></span>;
                }
                if (action === EventAction.ADDED_TO_TEAM) {
                    return <span><span className={classes.textContainer}>added you to</span><span className={classes.resourceName}>{teamName} team</span></span>;
                }
                if (action === EventAction.ACCEPTED_INVITE_TO_TEAM) {
                    return <span><span className={classes.textContainer}>has joined your</span><span className={classes.resourceName}>team, {teamName}</span></span>;
                }
            }

            if (type === EventType.PRESENTATION_LINK) {
                if (action === EventAction.PRESENTATION_LINK_CREATED) {
                    if (isPresentationLinkDeleted) {
                        return <span className={classes.textContainer}><span>shared a removed link</span></span>;
                    } else {
                        return <span className={classes.textContainer}><span>shared a link to</span><span className={classes.resourceName}>{presentationName}</span></span>;
                    }
                }
            }

            if (type === EventType.REFERRAL) {
                if (action === EventAction.CONVERSION) {
                    return <span className={classes.textContainer}>You just earned <strong>${amount / 100}</strong> in referral credit!</span>;
                }
            }

            if (type === EventType.PLAYER_DOWNLOAD) {
                if (action === EventAction.PRESENTATION_EXPORTED) {
                    const displayUsername = username !== "logged out" ? username : "Anonymous viewer";
                    return <span className={classes.textContainer}><strong>{displayUsername}</strong> downloaded {exportType} file from <span className={classes.resourceName}>{presentationName} ({presentationLinkName})</span></span>;
                }
            }
        };

        const getActionButton = () => {
            if (type === EventType.PERMISSION && action === EventAction.PERMISSION_REQUESTED) {
                return (<Button
                    variant="text"
                    size="small"
                    className={classes.openPresentationButton}
                    onClick={() => {
                        this.markAsRead();

                        const notification = { ...this.props };
                        delete notification.closeDialog;
                        ShowDialog(ProvideAccessDialog, { notification });
                    }}>
                    <Icon className={classes.buttonIcon} fontSize="small">settings</Icon>
                    Edit access
                </Button>);
            } else if (type === EventType.TEAM) {
                if (teamInviteUrl) {
                    return (<Button
                        variant="text"
                        size="small"
                        className={classes.openPresentationButton}
                        onClick={() => {
                            window.open(teamInviteUrl);
                            this.markAsRead();
                        }}>
                        <Icon className={classes.buttonIcon} fontSize="small">open_in_new</Icon>
                        Join team
                    </Button>);
                }
            } else {
                if (editedPresentationId !== currentPresentationId && presentationUrl) {
                    return (<Button
                        variant="text"
                        size="small"
                        className={classes.openPresentationButton}
                        onClick={() => {
                            window.open(presentationUrl);
                            this.markAsRead();
                        }}>
                        <Icon className={classes.buttonIcon} fontSize="small">open_in_new</Icon>
                        Open presentation
                    </Button>);
                } else if (isEditorOpen && !isPresentationSettingsOpen && editedPresentationId === currentPresentationId && slideId && slideId !== currentSlideId) {
                    if (typeof slideIndex === "number") {
                        return (<Button
                            variant="text"
                            size="small"
                            className={classes.openPresentationButton}
                            onClick={() => {
                                PresentationEditorController.setCurrentSlideByIndex(slideIndex);
                                if (type === EventType.COMMENT && !isCommentsOpen) {
                                    PresentationEditorController.toggleComments();
                                }
                                this.markAsRead();
                            }}>
                            <Icon className={classes.buttonIcon} fontSize="small">open_in_new</Icon>
                            Navigate to slide
                        </Button>);
                    }
                } else if (isEditorOpen && !isPresentationSettingsOpen && editedPresentationId === currentPresentationId && slideId === currentSlideId) {
                    if (type === EventType.COMMENT && !isCommentsOpen) {
                        return (<Button
                            variant="text"
                            size="small"
                            className={classes.openPresentationButton}
                            onClick={() => {
                                this.markAsRead();
                                app.mainView.editorView.toggleComments();
                            }}>
                            <Icon className={classes.buttonIcon} fontSize="small">open_in_new</Icon>
                            Open comments
                        </Button>);
                    }
                }
            }
        };

        const textSpan = getTextSpan();
        const actionButton = getActionButton();

        return (<Snackbar
            className={classes.snackBar}
            open={isOpen}
            onClose={() => {
                this.setState({ isOpen: false });
                closeDialog?.();
            }}
            autoHideDuration={10000}
        >
            <div className={classes.contentContainer}>
                {senderIsBai ? (
                    <>
                        <Avatar
                            src={logo}
                            alt="Beautiful.ai"
                        />
                        <span className={classes.author}>Beautiful.ai</span>
                    </>
                ) : (
                    <>
                        <Avatar
                            className={classes.avatar}
                            src={triggeredByUser.photoURL}
                            alt={triggeredByUser.displayName}
                        >
                            {!triggeredByUser.photoURL && <Icon>person</Icon>}
                        </Avatar>
                        <span className={classes.author}>{triggeredByUser.displayName}</span>
                    </>
                )}
                <div className={classes.messageAndActionsContainer}>
                    {textSpan && textSpan}
                    <div className={classes.buttonsContainer}>
                        {actionButton && actionButton}
                        <Button
                            variant="text"
                            size="small"
                            className={classes.markAsReadButton}
                            onClick={() => this.markAsRead()}>
                            <Icon fontSize="small">check</Icon>
                            Close and mark as read
                        </Button>
                    </div>
                </div>
            </div>
        </Snackbar >);
    }
}

const StyledNotificationAlert = withStyles(styles)(NotificationAlert);

export const ShowNotificationAlert = notification => {
    // NOTE: This mimics ShowSnackBar but lets NotificationAlert manage most of the props
    const dialog = renderReactDialogFromJSX(
        <StyledNotificationAlert {...notification} />,
        {
            overlayIsUnblocking: true, // Allows clicks to pass thru the overlay
            unmountOnReroute: false, // Allows the snackbar to persist across reroutes
            registerDialog: false, // Allows key input to work in the editor while snackbar is visible
        },
    );
    return dialog;
};
