import React, { useState, useRef } from "react";
import styled from "styled-components";

import { isFullsceenAllowed, toggleFullscreen } from "../helpers/fullscreen";
import { stopPropagation } from "../helpers/stopPropagation";

import {
    ArrowDropDown as ArrowDropDownIcon,
    Close as CloseIcon,
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon,
    ViewModule as ViewModuleIcon,
    Fullscreen as FullscreenIcon,
    ChromeReaderMode as ChromeReaderModeIcon,
    ModeComment as ModeCommentIcon, PhoneAndroid
} from "@material-ui/icons";
import { FlexBox } from "js/react/components/LayoutGrid";
import { FlexSpacer, Gap20 } from "js/react/components/Gap";

import PresentationActionsMenu from "./PresentationActionsMenu";

const SlideIndex = styled.div`
  user-select: none;
  text-transform: uppercase;
  font-size: 3em;
  color: #fff;
  margin-left: 8px;
  margin-right: 8px;
`;

const MobilePlayerControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  width: 100%;

  .MuiSvgIcon-root {
    font-size: 6em;
  }
`;

function getStyledIconButton(Icon, style = {}) {
    return styled(Icon).attrs(() => ({ style }))`
      color: #ccc;
    `;
}

const PrevSlideButton = getStyledIconButton(NavigateBeforeIcon);
const NextSlideButton = getStyledIconButton(NavigateNextIcon);
const ThumbnailsButton = getStyledIconButton(ViewModuleIcon);

function MobilePlayerControls({
    showThumbnailsPane,
    advanceToSlide,
    currentSlideIndex,
    slidesCount = 1,
    trackSlideNavigation
}) {
    return (
        <MobilePlayerControlsContainer>
            <PrevSlideButton onClick={() => {
                advanceToSlide(-1);
                trackSlideNavigation({ method: "buttons", deviceType: "mobile" });
            }
            } />
            <SlideIndex>{currentSlideIndex + 1} / {slidesCount}</SlideIndex>
            <NextSlideButton onClick={() => {
                advanceToSlide(1);
                trackSlideNavigation({ method: "buttons", deviceType: "mobile" });
            } } />
            <FlexSpacer />
            <ThumbnailsButton onClick={showThumbnailsPane} />
        </MobilePlayerControlsContainer>
    );
}

export default MobilePlayerControls;
