import { app } from "js/namespaces";
import { getStaticUrl } from "../../config";
import { $, _ } from "../../vendor";
import Api from "../api";
import { trackActivity } from "js/core/utilities/utilities";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { ds } from "js/core/models/dataService";
import { ASSET_FILETYPE, AssetType } from "common/constants";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.ASSETS);

export class SearchAssets {
    constructor() {
        this.isSearching = false;
        this.searchServices = [];
        this.results = [];
    }

    clearServices() {
        this.searchServices = [];
    }

    async searchStockImages(query, {
        useUnsplash = true,
        usePixabay = true,
        usePexels = true
    } = {}) {
        trackActivity("Image", "Search", query);

        this.isSearching = true;
        this.clearServices();

        useUnsplash && this.searchServices.push(new UnSplashSearchService(query));
        usePixabay && this.searchServices.push(new PixabayImageSearchService(query));
        usePexels && this.searchServices.push(new PexelsImageSearchService(query));

        return await this.getNextPage();
    }

    async searchWebService(query, filters) {
        trackActivity("Web Image", "Search", query);

        this.isSearching = true;
        this.clearServices();

        this.searchServices.push(new WebSearchService(query, filters));

        return await this.getNextPage();
    }

    async searchWebImages(options) {
        trackActivity("Image", "WebSearch", options.search);

        const results = await Api.searchWebImages.get(options);
        return results;
    }

    async createAssetFromGoogleImageDataUrl(name, dataUrl, assetType = AssetType.IMAGE) {
        const asset = await ds.assets.getOrCreateImage({
            url: dataUrl,
            name,
            fileType: ASSET_FILETYPE.PNG,
            assetType: assetType,
            metadata: {
                source: "google"
            },
        });

        return asset;
    }

    async searchVideos(query) {
        trackActivity("Video", "Search", query);

        this.isSearching = true;
        this.clearServices();

        this.searchServices.push(new PexelsVideoSearchService(query));

        return await this.getNextPage();
    }

    async getNextPage() {
        const handleUnreachableError = () => {
            const title = "Sorry!";
            const message = "We seem to be having trouble accessing our media services. Please try again later.";
            this.loading = false;
            ShowWarningDialog({
                title,
                message,
            });
        };

        // check if there's already a search in progress
        if (this._pendingRequest) {
            this._pendingRequest.resolve({ canceled: true });
            delete this._pendingRequest;
        }

        // perform a new search
        return new Promise((resolve, reject) => {
            // save this as the currently waiting request
            const ts = Date.now();
            this._pendingRequest = { ts, resolve, reject };

            // finish a request
            const finalize = (success, ...args) => {
                // if the timestamp has changed, then a new request
                // has probably come in - cancel the attempt
                if (this._pendingRequest && this._pendingRequest.ts !== ts) {
                    this._pendingRequest.resolve({ canceled: true });
                    return;
                }

                // perform normal resolution
                delete this._pendingRequest;
                return (success ? resolve : reject)(...args);
            };

            let searchServiceErrors = 0;
            return Promise.all(this.searchServices.map(service => service.getNextPage().catch(err => {
                logger.error(err, "[SearchAssets] getNextPage() failed");

                searchServiceErrors++;
                if (searchServiceErrors === this.searchServices.length) {
                    handleUnreachableError();
                    finalize(false, { error: "Unreachable", results: [], complete: false });
                }
            }))).then(services => {
                const results = services.reduce(function(acc, curService) {
                    return curService ? acc.concat(curService.results) : acc;
                }, []);

                if (this._pendingRequest === ts) {
                    this.loading = false;
                }

                finalize(true, { results, complete: true });
            });
        });
    }
}

// Base MediaSearchService class.
class MediaSearchService {
    constructor(query, filters = {}) {
        this.query = query;
        this.page = 1;
        this.isComplete = false;
        this.totalResults = 0;
        this.filters = filters;
    }

    getNextPage() {
        if (this.isComplete) {
            return Promise.resolve({
                results: [],
                complete: true
            });
        }

        return this.doSearch(this.query, this.filters, this.page)
            .then(search => {
                this.page++;
                this.totalResults = search?.total ?? 0;
                if (search?.complete) {
                    this.isComplete = true;
                }

                // Scramble the results if this is not the first page of results
                if (this.page > 2 && search?.results) {
                    search.results
                        .sort(() => (Math.random() - 0.5))
                        .sort(() => (Math.random() - 0.5))
                        .sort(() => (Math.random() - 0.5));
                }

                return search;
            });
    }

    doSearch(query, filters, page) {
    }

    getDownloadUrl(props) {
        return Promise.resolve(props.url);
    }
}

export class UnSplashSearchService extends MediaSearchService {
    doSearch(query, filters, page) {
        const params = {
            query,
            client_id: "b4eb00f4ad6bd57ba39a0cef3a70effecaf5cfd48ce089cf0e11ad075be7ea1e",
            per_page: 30,
            page,
        };
        const url = `https://api.unsplash.com/search/photos?${$.param(params)}`;

        return fetch(url)
            .then(response => response.json())
            .then(response => {
                let data = [];
                for (let photo of response.results) {
                    data.push({
                        title: photo.id,
                        service: this,
                        api: photo.links.download_location,
                        thumbnailUrl: photo.urls.small,
                        previewUrl: photo.urls.regular,
                        metadata: {
                            source: "unsplash",
                            link: "",
                            attribution: `<a href="https://unsplash.com/@${photo.user.username}?utm_source=beautifulai&utm_medium=referral" rel="noreferer, ,noopener" target="_blank">${photo.user.name}</a> on <a target="_blank" href="https://unsplash.com/?utm_source=beautifulai&utm_medium=referral">Unsplash</a>`,
                            name: photo.description || photo.alt_description,
                        },
                        width: photo.width,
                        height: photo.height
                    });
                }

                return {
                    results: data,
                    page,
                    total: response.total,
                    complete: data.length < 30
                };
            }).catch(err => logger.error(err, "[UnSplashSearchService] error searching"));
    }

    getDownloadUrl(props) {
        let params = {
            client_id: "b4eb00f4ad6bd57ba39a0cef3a70effecaf5cfd48ce089cf0e11ad075be7ea1e"
        };
        let queryChar = props.api.indexOf("?") > -1 ? "&" : "?";
        let url = `${props.api}${queryChar}${$.param(params)}`;

        return fetch(url)
            .then(response => response.json())
            .then(response => response.url)
            .catch(err => logger.error(err, "[UnSplashSearchService] error getting download url"));
    }
}

class PixabayImageSearchService extends MediaSearchService {
    doSearch(query, filters, page) {
        let params = {
            key: "4694549-0da57ae110b39b5742fdc949f",
            q: query,
            page,
            image_type: "photo",
            min_width: 500,
            min_height: 500,
            per_page: 30,
            safesearch: true
        };
        let url = `https://pixabay.com/api/?${$.param(params)}`;

        return fetch(url)
            .then(response => response.json())
            .then(results => {
                let data = [];
                for (let photo of results.hits) {
                    data.push({
                        title: photo.id,
                        service: this,
                        url: photo.largeImageURL,
                        thumbnailUrl: photo.webformatURL,
                        previewUrl: photo.webformatURL,
                        metadata: {
                            source: "pixabay",
                            link: "",
                            attribution: `<a rel="noreferer, ,noopener" target="_blank" href="${photo.pageURL}">${photo.user}</a> on <a target="_blank" href="https://pixabay.com">Pixabay</a>`,
                            name: photo.tags || null,
                            tags: photo.tags,
                        },
                        width: 960,
                        height: Math.round((960 / photo.imageWidth) * photo.imageHeight)
                    });
                }
                return {
                    results: data,
                    page,
                    total: results.total,
                    complete: results.hits.length < 30
                };
            });
    }
}

class PexelsImageSearchService extends MediaSearchService {
    doSearch(query, filters, page) {
        let params = {
            query,
            page,
            per_page: 30,
        };
        let url = `https://api.pexels.com/v1/search?${$.param(params)}`;

        return fetch(url, {
            headers: {
                "Authorization": "563492ad6f917000010000013956ad4226b74890a45b8e86a062f0fc"
            }
        })
            .then(response => response.json())
            .then(results => {
                let data = [];
                for (let photo of results.photos) {
                    data.push({
                        title: photo.id,
                        service: this,
                        url: photo.src.large2x || photo.src.large,
                        thumbnailUrl: photo.src.medium,
                        previewUrl: photo.src.medium,
                        metadata: {
                            source: "pexels",
                            link: "photographer_url",
                            attribution: `<a rel="noreferer, ,noopener" target="_blank" href="${photo.url}">${photo.photographer}</a> on <a target="_blank" href="https://pexels.com">Pexels</a>`,
                            name: photo.alt,
                        },
                        width: photo.width,
                        height: photo.height
                    });
                }
                return {
                    results: data,
                    page,
                    total: results.total_results,
                    complete: results.next_page == ""
                };
            });
    }
}

class PixabayVideoSearchService extends MediaSearchService {
    doSearch(query, filters, page) {
        const pageSize = 12;

        const params = {
            key: "4694549-0da57ae110b39b5742fdc949f",
            q: query,
            page,
            video_type: "photo",
            min_width: 500,
            min_height: 500,
            per_page: pageSize,
            safesearch: true,
        };
        const url = `https://pixabay.com/api/videos/?${$.param(params)}`;

        return fetch(url)
            .then(response => response.json())
            .then(async results => {
                let data = [];
                for (let video of results.hits) {
                    const item = {
                        title: video.id,
                        service: this,
                        url: video.videos?.large?.url || video.videos?.medium?.url || video.videos?.small?.url || video.videos?.tiny?.url,
                        thumbnailUrl: `https://i.vimeocdn.com/video/${video.picture_id}_960x540.jpg`,
                        previewUrl: `https://i.vimeocdn.com/video/${video.picture_id}_960x540.jpg`,
                        metadata: {
                            source: "pixabay",
                            link: "videographer_url",
                            attribution: `<a rel="noreferer, ,noopener" target="_blank" href="${video.pageURL}">${video.user}</a> on <a target="_blank" href="https://pixabay.com">Pixabay</a>`
                        },
                        width: 960,
                        height: 540,
                        duration: video.duration,
                    };
                    data.push(item);
                }
                // // Filter out bad urls (namely due to CORS issues).
                // data = await Promise.all(
                //     data.map(item => {
                //         return fetch(item.url)
                //             .then(() => item)
                //             .catch(() => null);
                //     })
                // );
                // data = data.filter(x => !!x);
                return {
                    results: data,
                    page,
                    total: results.total,
                    complete: results.hits.length < pageSize,
                };
            });
    }
}

export class PexelsVideoSearchService extends MediaSearchService {
    get headers() {
        return {
            "Authorization": "563492ad6f917000010000013956ad4226b74890a45b8e86a062f0fc"
        };
    }

    createVideoItem(video) {
        let previewUrl = video.image.split("?")[0];

        const highestResFile = video.video_files.reduce((result, next) => {
            return next.width > (result.width || 0) ? next : result;
        }, {});

        const previewFile = video.video_files.reduce((result, next) => {
            return next.width >= 960 && next.width < (result.width || Number.POSITIVE_INFINITY) ? next : result;
        }, {});

        // NOTE: 'profile_id' can cause videos to fail on mobile, so we remove it
        const videoUrl = highestResFile.link.replace(/profile_id=(.*?)\&/g, "");

        // Construct a preview video object if we have data for it.
        const previewVideo = previewFile?.link ? {
            url: previewFile.link,
            width: previewFile.width,
            height: previewFile.height,
        } : null;

        return {
            title: video.id,
            service: this,
            url: videoUrl,
            thumbnailUrl: previewUrl,
            previewUrl,
            metadata: {
                source: "pexels",
                link: "videographer_url",
                attribution: `<a rel="noreferer, ,noopener" target="_blank" href="${video.user.url}">${video.user.name}</a> on <a target="_blank" href="https://pexels.com">Pexels</a>`
            },
            width: highestResFile.width,
            height: highestResFile.height,
            previewVideo,
            duration: video.duration,
        };
    }

    doSearch(query, page, retryCounter = 0) {
        const pageSize = 12 + retryCounter;

        let params = {
            query,
            page,
            per_page: pageSize,
        };
        const url = `https://api.pexels.com/videos/search?${$.param(params)}`;

        // Retry the asset request a few times if it fails.
        //   The 'per_page' param is fault prone.
        const retry = err => {
            logger.error(err, "[PexelsVideoSearchService] failed");

            if (retryCounter < 3) {
                ++retryCounter;
                return this.doSearch(query, page, retryCounter);
            } else {
                throw err;
            }
        };

        const response = fetch(url, { headers: this.headers })
            .then(response => {
                if (response.status > 299) {
                    return retry(`api.pexels.com | Code ${response.status}: ${response.statusText}`);
                }
                return response;
            })
            .then(response => {
                const result = response.json();
                return result;
            })
            .then(async results => {
                let data = [];
                for (let video of results.videos) {
                    const item = this.createVideoItem(video);
                    data.push(item);
                }
                return {
                    results: data,
                    page,
                    total: results.total_results,
                    complete: results.videos.length < pageSize,
                };
            })
            .catch(retry);
        return response;
    }

    doVideoSearch(videoId) {
        const url = `https://api.pexels.com/videos/videos/${videoId}`;

        return fetch(url, { headers: this.headers })
            .then(async response => {
                return await response.json();
            })
            .then(video => this.createVideoItem(video));
    }
}

class WebSearchService extends MediaSearchService {
    doSearch(query, filters, page) {
        return Api.searchWebImages.get({
            q: query,
            type: "photo",
            filters: JSON.stringify(filters),
        })
            .then(async results => {
                let data = await Promise.all(results.map(({
                    url,
                    attribution,
                }) => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.onload = () => {
                            resolve({
                                title: "",
                                service: this,
                                api: "bing",
                                thumbnailUrl: url,
                                previewUrl: url,
                                metadata: {
                                    source: "bing",
                                    link: null,
                                    attribution,
                                    name: null,
                                },
                                width: img.width,
                                height: img.height,
                            });
                        };
                        img.onerror = err => {
                            // Return a blank object which will get
                            //   filtered out in the next step
                            resolve({});
                        };
                        img.src = url;
                    });
                }));

                // Filter out blank results
                data = data.filter(x => !!x.previewUrl);

                return {
                    results: data,
                    page,
                    total: data.length,
                    complete: true
                };
            });
    }

    async getDownloadUrl(props) {
        return props.previewUrl;
    }
}
