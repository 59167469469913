const theme = {
    isBuiltIn: true,
    id: "ppt",
    name: "PowerPoint",
    cjkFont: "jp",
    colors: {
        accent1: "rgba(237,125,49,1)",
        accent2: "rgba(165,165,165,1)",
        accent3: "rgba(255,192,0,1)",
        accent4: "rgba(91,155,213,1)",
        background_accent: "rgba(231,230,230,1)",
        background_dark: "rgba(0,0,0,1)",
        background_light: "#ffffff",
        chart1: "rgba(68,114,196,1)",
        chart2: "rgba(237,125,49,1)",
        chart3: "rgba(165,165,165,1)",
        chart4: "rgba(255,192,0,1)",
        primary_dark: "rgba(0,0,0,1)",
        primary_light: "#ffffff",
        secondary_dark: "rgba(68,84,106,1)",
        secondary_light: "rgba(231,230,230,1)",
        theme: "rgba(68,114,196,1)",
        hyperlink: "#11a9e2"
    },
    defaultBackgroundColor: "background_light",
    defaultSlideColor: "theme",
    fontBodyFontId: "carlito",
    fontBodyLetterSpacing: 0,
    fontBodyLineHeight: 1.6,
    fontBodyScaling: 100,
    fontBodyTextTransform: "auto",
    fontBodyWeight: 400,
    fontHeaderFontId: "carlito",
    fontHeaderLetterSpacing: 0,
    fontHeaderLineHeight: 1.6,
    fontHeaderScaling: 100,
    fontHeaderTextTransform: "auto",
    fontHeaderWeight: 400,
    fontScale: 1,
    fontTitleFontId: "carlito",
    fontTitleLetterSpacing: 0,
    fontTitleLineHeight: 1.6,
    fontTitleScaling: 100,
    fontTitleTextTransform: "auto",
    fontTitleWeight: 400,
    footerMessage: `${new Date().getFullYear()} Proprietary and Confidential. All Rights Reserved.`,
    iconStyle: "chunky",
    logo: null,
    logoOffset: 0,
    logoPosition: "left",
    logoScale: 1,
    logo_dark: null,
    palette_name: "custom",
    showFooterByDefault: null,
    showLogo: true,
    showMessage: true,
    showPageNum: true,
    styleBackground: "none",
    styleColor: "slide",
    styleDecoration: "none_center",
    styleDesign: 1,
    styleEffect: "none",
    styleElementStyle: "filled",
    styleFontWeight: "heavy",
    styleFonts: 1,
    styleHeaderAlignment: "left",
    styleShape: "circle",
    styleTitleSlide: "bar_left",
    styleWeight: "light"
};

module.exports = theme;
