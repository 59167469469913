import { _ } from "js/vendor";
import Adapter from "./adapter";
import { CommandsModel, createFromData, writeFromData, getInitialId } from "./commandsModel";
import { mergeChanges } from "common/utils/changeset";
import db from "js/db";

// initial data is set on the special key (all zeroes) so that it comes before all other updates
const specialInitialID = getInitialId();

class CommandsAdapter extends Adapter {
    constructor(options) {
        super(options);

        // Whether to continue sending updates after the initial sync
        this.autoSync = options ? options.autoSync !== false : true;

        // Which properties to persist as plain firebase key-value pairs
        // so that firebase rules can operate on them, so that they can be
        // directly queried, etc.
        this.plainProperties = options && options.plainProperties || [];
        this.writablePlainProperties = options && options.writablePlainProperties || [];
        this.userId = options.userId;
    }

    splitPlainProperties(object) {
        // Take just the properties that are used as plain firebase values out of
        // object. modifies object
        let res = {};
        for (let prop of this.plainProperties) {
            if (object.hasOwnProperty(prop)) {
                res[prop] = object[prop];
                delete object[prop];
            }
        }
        return res;
    }

    connect({ root, id, onRemoteChange, onRemoteError, data, waitForPlainProperties }) {
        this.plainModel = this.splitPlainProperties(data || {});
        const isNew = id == null;
        let restoring = data && id;

        let shouldCreateId = (data && !id);
        if (shouldCreateId) {
            id = db(root).push().key;
        }
        let parentRef = db(root).child(id);
        this.plainRefs = this.plainProperties.map(prop => {
            return parentRef.child(prop);
        });
        if (shouldCreateId) {
            const updates = this.plainProperties.reduce((acc, prop) => {
                acc[prop] = this.plainModel[prop] || null;
                return acc;
            }, {});

            let { cmd, snapModel, stringifiedSnapModel } = createFromData(data);
            updates[`cmds/${specialInitialID}`] = { updates: cmd.updates, userId: this.userId };
            updates["snap"] = { id: specialInitialID, model: stringifiedSnapModel };
            writeFromData(root, id, cmd, snapModel, stringifiedSnapModel, this.userId, true);
            parentRef.update(updates);
        }

        let watchMethod = this.autoSync ? "on" : "once";
        this.connected = true;

        let loading = waitForPlainProperties ? this.plainProperties.length + 1 : this.writablePlainProperties.length + 1;

        this.plainProperties.map((key, ii) => {
            let loaded = false;
            this.plainRefs[ii][watchMethod]("value", data => {
                const val = data.val();
                if (val !== undefined) {
                    this.plainModel[key] = val;
                } else {
                    delete this.plainModel[key];
                }
                if (!loaded) {
                    loaded = true;
                    if (waitForPlainProperties || this.writablePlainProperties.indexOf(key) > -1) {
                        loading--;
                    }
                }
                sendUpdate.call(this);
            });
        });

        let commandsLoaded = false;
        this.commandsModel = new CommandsModel({
            root,
            id,
            isNew,
            onRemoteChange: model => {
                if (!commandsLoaded) {
                    commandsLoaded = true;
                    loading--;
                }
                setTimeout(() => {
                    sendUpdate.call(this);
                }, 0);
            },
            onRemoteError,
            autoSync: this.autoSync,
            userId: this.userId
        });

        let initialized = false;
        function sendUpdate() {
            if (this.isUpdating) return;
            if (loading > 0) return;

            if (!initialized) {
                initialized = true;
                if (restoring) {
                    //run an update with the new data
                    this.update(Adapter.TYPE.replace, data, null);
                    onRemoteChange(Adapter.TYPE.initialize, data);
                    if (!this.autoSync) {
                        this.commandsModel.disconnect();
                    }
                } else {
                    onRemoteChange(Adapter.TYPE.initialize, this._combinedModel());
                    if (!this.autoSync) {
                        this.commandsModel.disconnect();
                    }
                }
            } else {
                if (this.commandsModel.getCurrentModel()) {
                    onRemoteChange(Adapter.TYPE.replace, this._combinedModel());
                } else {
                    onRemoteChange(Adapter.TYPE.remove, null);
                }
            }
        }

        return id;
    }

    _combinedModel() {
        let model = Object.assign({}, this.plainModel, this.commandsModel.getCurrentModel());
        delete model["id"];
        return model;
    }

    disconnect() {
        if (this.connected) {
            this.commandsModel.disconnect();
        }
        this.connected = false;
    }

    update(type, newData, currentData) {
        if (!this.connected) {
            return Promise.reject(new Error("Adapter is not connected."));
        }
        let promise;
        newData = _.cloneDeep(newData);
        switch (type) {
            case Adapter.TYPE.remove:
                this.commandsModel.updateModel(null);
                promise = Promise.resolve();
                break;
            case Adapter.TYPE.replace:
                this.plainModel = this.splitPlainProperties(newData);
                this.plainProperties.map((prop, ii) => this.plainRefs[ii].set(this.plainModel[prop] || null));

                promise = this.commandsModel.loadedPromise.then(() => {
                    this.commandsModel.updateModel(newData);
                });
                break;
            case Adapter.TYPE.update:
                let plainUpdates = this.splitPlainProperties(newData);
                this.plainModel = Object.assign(this.plainModel, plainUpdates);
                this.isUpdating = true;
                const updatePromisesArray = [];
                this.plainProperties.map(
                    (prop, ii) => {
                        if (prop in plainUpdates) {
                            updatePromisesArray.push(this.plainRefs[ii].set(plainUpdates[prop]));
                        }
                    });
                this.isUpdating = false;
                if (Object.keys(newData).length > 0) {
                    // only update the commands model if there are actually updates
                    // this way renderer will never trash the model
                    promise = this.commandsModel.loadedPromise.then(() => {
                        let newModel = mergeChanges(this.commandsModel.getCurrentModel(), newData);
                        this.commandsModel.updateModel(newModel);
                    });
                } else {
                    promise = Promise.resolve();
                }
                updatePromisesArray.push(promise);
                promise = Promise.all(updatePromisesArray);
                break;
            case Adapter.TYPE.initialize:
                promise = Promise.reject(new Error(`Cannot set type to initialize for update.`));
                break;
            default:
                promise = Promise.reject(new Error(`Unhandled type: ${type}`));
        }
        return promise;
    }

    isConnected() {
        return this.connected;
    }
}

export default CommandsAdapter;
