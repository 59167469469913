class ApiError extends Error {
    constructor(message, status = 500, code = null) {
        super(message);

        // Making the status always visible
        this.name = `ApiError${status}`;

        this.status = status;

        if (code) {
            this.code = code;
        } else {
            switch (status) {
                case 403:
                    this.code = "forbidden";
                    break;
                case 404:
                    this.code = "notfound";
                    break;
                case 400:
                    this.code = "badrequest";
                    break;
                case 401:
                    this.code = "unauthorized";
                    break;
                case 402:
                    this.code = "paymentrequired";
                    break;
                case 409:
                    this.code = "conflict";
                    break;
                case 429:
                    this.code = "toomanyrequests";
                    break;
                default:
                    this.code = "servererror";
            }
        }
    }

    toJSON() {
        return {
            name: this.name,
            message: this.message,
            code: this.code,
            status: this.status
        };
    }
}

module.exports = ApiError;
