import React, { useState } from "react";
import styled from "styled-components";

import { themeColors } from "js/react/sharedStyles";
import { ContextSource } from "js/react/views/AIGeneration/panes/GeneratePresentationPane/controllers/GeneratePresentationController";

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    
    > textarea {
        width: 100%;
        overflow: auto;
        border: none;
        resize: none;

        font-family: 'Source Sans Pro';
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: black;

        &:focus {
            outline: 1px solid ${themeColors.ui_blue};
        }
    }

    > textarea::placeholder {
        font-style: italic;
        color: ${themeColors.gray};
    }
`;

interface DocumentTextInputProps {
    contextSource: ContextSource;
}

function DocumentTextInput({ contextSource }: DocumentTextInputProps) {
    const [text, setText] = useState(contextSource.text);

    const handleChangeDocumentText = event => {
        setText(event.target.value);
        contextSource.text = event.target.value;
    };

    return (
        <Container>
            <textarea
                value={text}
                onChange={handleChangeDocumentText}
                placeholder="Type or paste additonal context for the AI to use..."
            />
        </Container>
    );
}

export default DocumentTextInput;
