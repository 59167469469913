import Api from "js/core/api";
import ApiError from "common/ApiError";
import getLogger from "js/core/logger";

const logger = getLogger();

const userProfileRequests = {};

const emptyProfile = {
    photoUrl: null,
    email: ""
};

async function getUserProfile(uid) {
    if (!uid) {
        return { ...emptyProfile };
    }

    if (!userProfileRequests[uid]) {
        userProfileRequests[uid] = Api.getAuthUser.get({ uid })
            .catch(err => {
                logger.error(err, "getUserProfile() failed to load user profile", { uid });

                // Return empty profile if user is not found
                if (err instanceof ApiError && err.status === 404) {
                    return { ...emptyProfile };
                }

                throw err;
            });
    }

    return await userProfileRequests[uid];
}

export default getUserProfile;
