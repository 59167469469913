import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.LOCAL_STORAGE);

class MockLocalStorage {
    constructor() {
        this.store = {};
    }
    setItem(key, val) {
        this.store[key] = val;
    }
    getItem(key) {
        return this.store[key];
    }
    removeItem(key) {
        delete this.store[key];
    }
}

const mockLocalStorage = new MockLocalStorage();

function getLocalStorage() {
    let localStorage;
    try {
        localStorage = window.localStorage || mockLocalStorage;
    } catch (err) {
        localStorage = mockLocalStorage;
        logger.error(err, "getLocalStorage() failed");
    }
    return localStorage;
}

export default getLocalStorage;
