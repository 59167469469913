import React, { Component } from "react";
import styled from "styled-components";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";

import { ds } from "js/core/models/dataService";
import getUserProfile from "js/core/services/userProfiles";
import { TEAM_USER_ROLES } from "common/constants";
import { UserProfile } from "js/react/components/Avatar";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import Loadable from "js/react/components/Loadable";
import { isOwnerOrLibrarian } from "common/utils/roles";

const FlexDiv = styled.div`
    display: flex;
`;

const SpacedPTag = styled.p`
    letter-spacing: .1px;
`;

const ProfileContainer = styled.div`
    display: flex;
    padding: 10px;
`;

const OwnersAndLibrariansProfilesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
`;

const StyledDialogTitle = styled(DialogTitle)`
&&& {
padding: 34px 34px 0;
}`;

const StyledDialogContent = styled(DialogContent)`
&&& {
padding: 0 34px 0;
}`;

export default class ManageTeamFolderDialog extends Component {
    state = {
        isLoading: true,
        ownersAndLibrariansProfiles: [],
    }

    async componentDidMount() {
        const { organizationId } = this.props;
        const defaultTeam = ds.teams.defaultTeamForOrg(organizationId);

        const ownersAndLibrariansIds = Object.entries(defaultTeam.get("members"))
            .filter(([, { role }]) => isOwnerOrLibrarian(role))
            .map(([uid]) => uid);

        const ownersAndLibrariansProfiles = await Promise.all(ownersAndLibrariansIds.map(memberId => getUserProfile(memberId)));
        this.setState({ ownersAndLibrariansProfiles, isLoading: false });
    }

    render() {
        const { isLoading, ownersAndLibrariansProfiles } = this.state;
        const { closeDialog } = this.props;

        return (
            <BeautifulDialog closeDialog={closeDialog} >
                <StyledDialogTitle>Sorry, you don’t have permission to manage folder access.</StyledDialogTitle>
                <StyledDialogContent>
                    <Loadable isLoading={isLoading}>
                        <SpacedPTag>This folder’s settings only allow Owners to update folder access. You can reach out to the following team members for help:</SpacedPTag>
                        <FlexDiv>
                            <OwnersAndLibrariansProfilesContainer>
                                {
                                    ownersAndLibrariansProfiles.map(user => {
                                        return (
                                            <ProfileContainer key={user.uid}>
                                                <UserProfile profile={user} />
                                            </ProfileContainer>
                                        );
                                    })
                                }
                            </OwnersAndLibrariansProfilesContainer>
                        </FlexDiv>
                    </Loadable>
                </StyledDialogContent>
                <DialogActions>
                    <Button color="primary" onClick={closeDialog} >
                        Got It
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
