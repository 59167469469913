/**
 * We no longer use indexDB with the commands adapter, but the code is too tangled to just remove. Use
 * js/core/utilities/storage.js instead (a promise backed implementation that always resolves).
 */
let mockDB = {};

function idbWrite(key, value, cb) {
    mockDB[key] = value;
    cb();
}

function idbRead(key, cb) {
    const value = mockDB[key];
    cb(value);
}

export { idbRead, idbWrite };
