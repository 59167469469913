import React, { useEffect, useState, forwardRef } from "react";
import styled from "styled-components";

import {
    Button,
    DialogActions,
    DialogTitle,
    DialogContent
} from "@material-ui/core";

import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";

import Api from "js/core/api";
import { CustomerType, SubscriptionStatus } from "common/constants";
import BillingDataService from "js/react/views/UserOptions/dataservices/billingDataService";
import AppController from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import ErrorDialog from "js/react/components/Dialogs/ErrorDialog";
import checkPastDue from "js/core/billing/checkPastDue";

import "css/billing.scss";

const logger = getLogger(LogGroup.BILLING);

const ChargeInfoMessage = styled.span`
    margin-left: 12px;
    font-size: 14px;
`;

const CloseDialogButton = styled(Button)`
    &&& {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;

// Simplified subscription object that we receive from the backend
interface Subscription {
    status: typeof SubscriptionStatus[keyof typeof SubscriptionStatus];
    upcomingInvoice?: {
        amount_due: number
    };
}

interface EndTrialDialogProps {
    closeDialog: (result: boolean) => void;
}

const EndTrialDialog = forwardRef(function EndTrialDialog(props: EndTrialDialogProps, ref: any) {
    const { closeDialog } = props;

    const [subscription, setSubscription] = useState<Subscription>(null);
    const [hasEndedTrial, setHasEndedTrial] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>(null);

    const organizationId = AppController.workspaceId === "personal" ? null : AppController.workspaceId;

    useEffect(() => {
        (async () => {
            const subscription: Subscription = await BillingDataService.fetchSubscription(organizationId);
            if (!subscription) {
                setErrorMessage("You do not have a subscription.");
                return;
            }
            if (subscription.status !== SubscriptionStatus.TRIALING) {
                setErrorMessage("Your trial has already ended.");
                return;
            }
            if (!subscription.upcomingInvoice) {
                setErrorMessage("Unable to get upcoming invoice.");
                return;
            }

            setSubscription(subscription);
            setFetching(false);
        })().catch(err => logger.error(err, "[EndTrialDialog] Error getting subscription"));
    }, []);

    const handleEndTrial = async () => {
        setFetching(true);

        try {
            await Api.subscriptions.put({
                type: "end_trial",
                orgId: organizationId,
                customerType: organizationId ? CustomerType.TEAM : CustomerType.INDIVIDUAL
            });

            const isPastDue = await checkPastDue(AppController.workspaceId);
            if (isPastDue) {
                setErrorMessage("Please update your payment method.");
                return;
            }

            setHasEndedTrial(true);
            setFetching(false);
        } catch (err) {
            logger.error(err, "[EndTrialDialog] Error ending trial");
            setErrorMessage("Sorry, something went wrong. Please try again later.");
        }
    };

    if (errorMessage) {
        return (<ErrorDialog
            ref={ref}
            // @ts-ignore
            preventClose
            title="Error"
            message={errorMessage}
            closeDialog={() => closeDialog(false)}
        />);
    }

    const upcomingInvoice = subscription?.upcomingInvoice ?? { amount_due: 0 };

    return (
        <BeautifulDialog preventClose ref={ref}>
            <FetchingClickShield visible={fetching} backgroundColor={"white"} />

            {!hasEndedTrial && <>
                <DialogTitle>
                    Are you sure you want to end your trial now?
                </DialogTitle>
                <DialogContent>
                    <ChargeInfoMessage>
                        You will be charged <strong>${(upcomingInvoice.amount_due / 100).toFixed(2)}</strong> and will have access to unlimited AI credits immediately.
                    </ChargeInfoMessage>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog(false)}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleEndTrial}>
                        Upgrade now
                    </Button>
                </DialogActions>
            </>}

            {hasEndedTrial && <>
                <DialogContent>
                    {/* Styles reused from the "successful purchase" dialog */}
                    <div id="successful_purchase">
                        <div id="big_icon">
                            <i className="micon">check</i>
                        </div>
                        <div id="message">
                            You successfully started your subscription.
                        </div>
                        <div className="receipt">
                            A receipt will be sent to your email shortly.
                        </div>
                        <CloseDialogButton
                            onClick={() => closeDialog(true)}
                            color="primary"
                        >
                            Close
                        </CloseDialogButton>
                    </div>
                </DialogContent>
            </>}
        </BeautifulDialog >
    );
});

export default EndTrialDialog;
