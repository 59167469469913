import db from "js/db";
import elasticlunr from "elasticlunr";

let iconData, manifestLoc, searchIndex;
export async function fetchIconData() {
    manifestLoc = await db("config")
        .once("value")
        .then(snapshot => snapshot.val());

    const response = await fetch(manifestLoc.iconManifest);
    if (!response.ok) {
        throw Error(response);
    }
    iconData = await response.json();
    return { iconData, manifestLoc };
}

export function createSearchableIconData(iconData) {
    searchIndex = elasticlunr(function() {
        this.addField("name");
        this.addField("tags");
        this.setRef("key");
    });
    Object.keys(iconData).forEach(iconKey => {
        const doc = iconData[iconKey];
        searchIndex.addDoc(doc);
    });
    return searchIndex;
}

export async function iconSearch(rawInput) {
    if (!iconData) await fetchIconData();
    if (!searchIndex) createSearchableIconData(iconData);

    let query = rawInput.toLowerCase().trim();
    let results;
    if (query.length === 0) {
        results = null;
    } else {
        results = searchIndex.search(query, {
            bool: "AND",
            expand: true
        });
        if (results.length === 0) {
            results = searchIndex.search(query, {
                bool: "OR",
                expand: true
            });
        }
        if (results.length === 0) {
            results = searchIndex.search(query.split(" ")[0], {
                bool: "OR",
                expand: true
            });
        }
    }
    return { results, query };
}
