export enum SourceType {
    Google = "google",
    OneDrive = "onedrive",
    Dropbox = "dropbox",
    Box = "box",
    Airtable = "airtable",
    Smartsheet = "smartsheet",
    Zoho = "zoho",
}

export interface IDataSource {
    id: string;
    ownerIds: [string];
    spreadsheetData: {
        id: number;
        index: number;
        title: string;
        sheetType: string;
        gridProperties: {
            rowCount: number;
            colCount: number;
        };
        data: {
            range: string, // A1 notation - eg: "Sheet1!A1:E1000"
            values: string[][];
        };
    }[];
    sourceType: SourceType;
    sourceMetadata: {
        id: string;
        title: string;
        url: string;
        metadata: object;
    };
    oauthDisconnected: boolean;
    createdBy: string;
    lastSyncedAt: number;
    createdAt: number;
    modifiedAt: number;
}
