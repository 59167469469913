import { _ } from "js/vendor";
import db from "js/db";
import Adapter from "./adapter";
import getLogger, { LogGroup } from "js/core/logger";
import { isRenderer } from "js/config";

const logger = getLogger(LogGroup.ADAPTER);

function flattenData(data) {
    const flattenedData = {};
    forEach(data, (key, value) => {
        if (_.isObject(value)) {
            forEach(flattenData(value), (nestedKey, nestedValue) => {
                flattenedData[`${key}/${nestedKey}`] = nestedValue;
            });
        } else {
            flattenedData[key] = value;
        }
    });
    return flattenedData;
}

function forEach(obj, callback) {
    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            callback(i, obj[i]);
        }
    } else {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                callback(key, obj[key]);
            }
        }
    }
}

// Remove this once we know all data has been migrated.
function migrate(root, id) {
    if (db.isDefaultDb(root)) {
        return Promise.resolve(null);
    }
    const defaultDb = db.getDbInstance();
    return defaultDb.ref(root).child(id).once("value").then(snapshot => {
        const val = snapshot.val();
        if (!val) {
            return null;
        }
        return db(root).child(id).update(snapshot.val()).then(() => {
            return val;
        });
    });
}

/**
 * An adapter that connects to firebase. This will only push changes to each property that has changed to minimize
 * offline/online conflicts. Without that, the entire object could be overridden.
 */
class FirebaseAdapter extends Adapter {
    constructor(options) {
        super(options);
        this.autoSync = options ? options.autoSync !== false : true;
        this.readonly = options && options.readonly;
    }

    connect(options) {
        if (!options) {
            throw new Error("options must be passed in with at least a root or query parameter");
        }
        this.disconnect();
        this.connected = true;
        this.resetSyncState();
        this.changeId = `${new Date().getTime()}-${Math.floor(Math.random() * 1000)}`;
        this.query = false;
        this.collection = false;

        const root = options.root;
        if (options.root) {
            this.rootRef = db(root);
            if (options.id) {
                this.ref = this.rootRef.child(options.id);
            } else {
                this.ref = this.rootRef.push();
            }
            this.writeRef = this.ref;
            this.query = false;
        } else if (options.query) {
            this.query = true;
            this.rootRef = db(options.query.root);
            this.ref = this.rootRef;

            //determine orderby.
            if (options.query.attribute != null) {
                this.ref = this.ref.orderByChild(options.query.attribute);
            } else if (options.query.id != null) {
                this.ref = this.ref.orderByKey();
            } else if (options.query.value != null) {
                this.ref = this.ref.orderByValue();
            }

            //determine comparison.
            let comparison = options.query.gt ? "startAt" : (options.query.lt ? "endAt" : "equalTo");
            if (options.query.value != null) {
                this.ref = this.ref[comparison](options.query.value);
            } else if (options.query.id != null) {
                this.ref = this.ref[comparison](options.query.id);
            }

            //determine limits.
            if (options.query.limit) {
                this.ref = this.ref.limitToFirst(options.query.limit);
            }
            this.writeRef = this.rootRef;
            this.collection = true;
        } else {
            throw new Error("options must be passed in with at least a root or query parameter");
        }
        this.onRemoteChange = options.onRemoteChange;
        this.onRemoteError = options.onRemoteError || function(err) {
            logger.error(err, "FirebaseAdapter onRemoteError()");
        };

        this.firebaseError = err => {
            let error = new Error(`${err.message} on adapter ${this.toString()}`);
            if (err.code === "PERMISSION_DENIED") {
                error.statusCode = 403;
            } else {
                error.statusCode = 500;
            }
            this.onRemoteError(error);
        };

        this.notFoundError = () => {
            const error = new Error("Reference does not exist: " + this.ref);
            error.statusCode = 404;
            this.onRemoteError(error);
        };

        this.syncState.loading = this.query || options.ensurePersisted || (options.id != null && options.data == null);

        // Preventing the renderer from autosyncing presentation models is necessary otherwise exports would emit 100% completion after every screenshot
        if (options.data && !options.id && !isRenderer && !window.isPlayer) {
            const data = this.normalizeData(options.data);
            this.setData(data);
        }

        const sync = data => {
            let val = data.val();
            if (val === null && this.syncState.loading) {
                this.syncState.loading = false;
                if (options.defaultValue !== undefined) {
                    this.onRemoteChange(Adapter.TYPE.initialize, options.defaultValue);
                } else if (this.query) {
                    this.onRemoteChange(Adapter.TYPE.initialize, {}); //Queries can be empty.
                } else {
                    //Try migrating
                    if (options.root && options.id) {
                        migrate(options.root, options.id).then(val => {
                            if (val) {
                                this.onRemoteChange(Adapter.TYPE.initialize, val);
                            } else {
                                this.notFoundError();
                            }
                        });
                    } else {
                        this.notFoundError();
                    }
                }
            } else if (this.syncState.loading) {
                this.syncState.loading = false;
                val = Object.assign({}, val);
                delete val.id;
                delete val._changeId;
                this.onRemoteChange(Adapter.TYPE.initialize, val);
            } else if (!this.syncState.removing && val === null) {
                this.onRemoteChange(Adapter.TYPE.remove, val);
            } else if (val._changeId !== this.changeId) {
                val = Object.assign({}, val);
                delete val.id;
                delete val._changeId;
                this.onRemoteChange(Adapter.TYPE.replace, val);
            }
            this.resetSyncState();
        };

        if (this.autoSync) {
            if (this.query) {
                this.ref.once("value", sync, this.firebaseError);
                const childSync = (data, val) => {
                    if (this.syncState.loading || this.syncState.removing) {
                        return;
                    }
                    if (val && val._changeId && val._changeId !== this.changeId) {
                        val = Object.assign({}, val);
                        delete val._changeId;
                        this.onRemoteChange(Adapter.TYPE.update, {
                            [data.key]: val
                        });
                    } else if (val == null && !this.syncState.removingKey[data.key]) {
                        this.onRemoteChange(Adapter.TYPE.update, {
                            [data.key]: val
                        });
                    }
                    delete this.syncState.removingKey[data.key];
                };
                this.ref.on("child_changed", data => {
                    let val = data.val();
                    childSync(data, val);
                });
                this.ref.on("child_added", data => {
                    let val = data.val();
                    childSync(data, val);
                });
                this.ref.on("child_removed", data => {
                    childSync(data, null);
                });
            } else {
                this.ref.on("value", sync, this.firebaseError);
            }
        } else if (this.syncState.loading) {
            this.ref.once("value", sync, this.firebaseError);
        }

        return this.ref.key;
    }

    isConnected() {
        return this.connected;
    }

    setData(data) {
        if (this.readonly) {
            const ref = this.ref.key || this.ref.ref.key;
            return Promise.resolve();
        }
        if (this.query) {
            return this.ref.once("value").then(snapshot => {
                const val = snapshot.val();
                if (!val) {
                    return;
                }
                const update = Object.assign({}, data);
                Object.keys(val).forEach(key => {
                    if (!update[key]) {
                        update[key] = null;
                    }
                });
                return this.writeRef.update(update);
            }).catch(err => {
                this.firebaseError(err);
                return Promise.reject(err);
            });
        } else {
            return this.writeRef.set(data).catch(err => {
                this.firebaseError(err);
                return Promise.reject(err);
            });
        }
    }

    disconnect() {
        if (this.connected) {
            const ref = this.ref.key || this.ref.ref.key;
            this.ref.off();
            this.ref = null;
            this.rootRef = null;

            this.onRemoteChange = function() { };
            this.onRemoteError = function(err) {
                logger.error(err, "FirebaseAdapter onRemoteError()");
            };
        }
        this.connected = false;
    }

    update(type, newData, currentData) {
        if (this.readonly) {
            logger.warn("FirebaseAdapter trying to update a readonly model");
            return Promise.resolve();
        }
        if (!this.connected) {
            return Promise.reject(new Error("Adapter is not connected."));
        }
        let promise;
        switch (type) {
            case Adapter.TYPE.remove:
                this.removing = true;
                if (this.query) {
                    return this.setData(null);
                } else {
                    promise = this.writeRef.remove();
                }
                break;
            case Adapter.TYPE.replace:
                newData = this.normalizeData(newData);
                promise = this.setData(newData);
                break;
            case Adapter.TYPE.update:
                newData = this.normalizeData(newData, true);
                promise = this.writeRef.update(newData).catch(err => {
                    this.firebaseError(err);
                    return Promise.reject(err);
                });
                break;
            default:
                promise = Promise.reject(new Error(`Unsupported type ${type} for adapter ${this.toString()}`));
        }
        return promise;
    }

    resetSyncState() {
        this.syncState = {
            loading: false,
            removing: false,
            removingKey: {}
        };
    }

    createUid(root) {
        return db(root).push().key;
    }

    normalizeData(data, flatten = false) {
        if (this.collection) {
            data = Object.assign({}, data);
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    const val = data[key];
                    if (val) {
                        data[key] = Object.assign({}, data[key], { _changeId: this.changeId });
                    } else {
                        this.syncState.removingKey[key] = true;
                        data[key] = null;
                    }
                }
            }
        } else {
            data = Object.assign({}, data);
        }
        if (flatten) {
            data = flattenData(data);
        }
        if (!this.collection) {
            data._changeId = this.changeId;
            data.id = this.ref.key;
        }
        return data;
    }

    toString() {
        return "FirebaseAdapter@" + (this.ref ? this.ref.toString() : "<not-connected>");
    }
}

export default FirebaseAdapter;
