const genExportChannelId = payload => {
    const {
        assetType,
        presentationId,
        presentationLinkId,
        hideBaiBranding,
        hideSmartSlideWatermark,
        isTeamUser,
        forPrinting
    } = payload;

    const branding = hideBaiBranding ? "no-branding" : "branding";
    const watermark = hideSmartSlideWatermark ? "no-watermark" : "watermark";
    const team = isTeamUser ? "team" : "no-team";
    const printing = forPrinting ? "printing" : "no-printing";
    const linkId = presentationLinkId || "no-link-id";

    return `private-export-${assetType}@${presentationId}@${linkId}@${branding}@${watermark}@${team}@${printing}`;
};

module.exports = genExportChannelId;
