import { getCallable } from "../_sdk/client";
import {
    GetExperimentsRequest,
    GetExperimentsResponse,
    InitializeAllExperimentsRequest,
    InitializeAllExperimentsResponse
} from "./types";
import { endpoints, apiName } from "./endpoints";

const getExperiments = getCallable<GetExperimentsRequest, GetExperimentsResponse>(apiName, "getExperiments", {
    ...endpoints.getExperiments,
    composer: request => {
        return {
            url: `/experiments?ids=${encodeURIComponent(request.ids.join(","))}&browserId=${encodeURIComponent(request.browserId)}&migrationStates=${encodeURIComponent(JSON.stringify(request.migrationStates))}`,
            options: {
                method: "GET"
            }
        };
    }
}, false);

const initializeAllExperiments = getCallable<InitializeAllExperimentsRequest, InitializeAllExperimentsResponse>(apiName, "initializeAllExperiments", {
    ...endpoints.initializeAllExperiments,
    composer: request => {
        return {
            url: `/experiments/initializeAll`,
            options: {
                method: "POST",
                body: JSON.stringify(request)
            }
        };
    }
}, false);

export const callables = {
    getExperiments,
    initializeAllExperiments
};
