import StorageModel from "js/core/storage/storageModel";
import ReferenceCollection from "js/core/storage/referenceCollection";
import { app } from "js/namespaces";

const Plan = StorageModel.extend({
    root: "plans"
});

const UserPlans = ReferenceCollection.extend({
    model: Plan,
    referenceRoot: "userPlans",

    initialize: function() {
        this.userId = app.user.id;
    },

    getReferenceId: function() {
        return this.userId;
    },

    getPersonalPlan() {
        if (this.models.length == 0) {
            // new user with no plan
            return "free";
        }
        const planId = this.models[0].id;
        if (planId == "-LfRhaqvuLMLJ0qs8aNJ") {
            return "free";
        } else {
            return this.models[0].id;
            // return "pro";
        }
    },
});

export { Plan, UserPlans };
