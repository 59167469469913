import { EndpointDefinition } from "../_sdk/types/common";

export const apiName = "debug";

export const endpoints: { [key: string]: EndpointDefinition } = {
    createScreenshot: {
        route: "/debug/screenshot/",
        method: "POST"
    },
    getReferenceScreenshots: {
        route: "/debug/screenshots/:id",
        method: "GET"
    },
};
