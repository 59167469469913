import React, { Component } from "reactn";
import {
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "../../../components/UiComponents";
import Loadable from "js/react/components/Loadable";

export default class LoadingPane extends Component {
    render() {
        return (
            <UIPane>
                <UIPaneHeader>Loading...</UIPaneHeader>
                <UIPaneContents>
                    <Loadable isLoading={true}>
                    </Loadable>
                </UIPaneContents>
            </UIPane>
        );
    }
}
