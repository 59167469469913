import { createTheme } from "@material-ui/core/styles";
import { themeColors } from "js/react/sharedStyles";

const padding = "8px 35px 8px 14px";

const muiList = {
    root: {
        fontSize: 14
    },
    padding: {
        paddingTop: 0,
        paddingBottom: 0
    }
};
const overrides = {
    MuiModal: {
        root: {
            zIndex: 99999
        }
    },
    MuiInput: {
        underline: {
            "&:before": {
                borderBottom: "solid 1px rgba(255,255,255,0.15)"
            }
        }
    },
    MuiInputBase: {
        root: {
            lineHeight: 1
        }
    },
    MuiTab: {
        root: {
            background: "transparent",
            height: 30,
            fontWeight: 600,
            minWidth: "140px !important",
        },
        textColorInherit: {
            opacity: 0.5
        }
    },
    MuiOutlinedInput: {
        root: {
            backgroundColor: "#fff",
            borderRadius: 0,
            height: 34
        },
        notchedOutline: {
            borderRadius: 0,
            border: "solid 1px #ccc"
        },
        input: {
            padding: "10px 10px",
            fontSize: 14,
            color: "#333 !important",
            fontWeight: 500,
        },
        adornedEnd: {
            paddingRight: 2
        }
    },
    MuiSelect: {
        icon: {
            fill: "#aaa"
        },
        select: {
            color: "#333",
            fontSize: 14,
            fontWeight: 600,
            //textTransform: "uppercase",
            borderRadius: 0,
            // border: "solid 1px #aaa",
            border: "none",
            padding: "8px 25px 8px 12px",
            background: "white !important"
        },
        outlined: {
            padding: padding,
        }
    },
    MuiSelectOutlined: {
        root: {
            backgroundColor: "#fff",
            fontFamily: "inherit"
        },
        notchedOutline: {
            borderRadius: 0
        },
        input: {
            padding: "2px 25px 2px 10px !important",
            fontSize: "0.825em !important",
            color: "#333 !important",
            margin: "5px 0 !important",
            fontWeight: 500,
            fontFamily: "inherit"
        }
    },
    MuiCheckbox: {
        label: {
            fontWeight: 600
        }
    },
    MuiSnackbarContent: {
        root: {
            backgroundColor: "#333"
        },
        message: {
            color: "white",
            fontWeight: 600
        }
    },
    MuiButton: {
        root: {
            borderRadius: 0,
            color: "white",
            fontWeight: 600,
            letterSpacing: "0.05em",
            padding: "10px 25px"
        },
        contained: {
            boxShadow: "none",
            background: "white",
            color: "#333",
            height: "44px",
            borderRadius: 2
        },
        containedPrimary: {
            color: "white"
        },
        label: {
            display: "grid",
            gridAutoFlow: "column",
            columnGap: 10
        },
        textPrimary: {
            color: "white",
            i: {
                color: "white"
            }
        },
        sizeSmall: {
            fontSize: 11,
            padding: "6px 12px"
        },
        sizeLarge: {
            fontSize: 16
        },
        containedSizeSmall: {
            fontSize: 11,
            padding: "6px 12px"
        }
    },
    MuiToggleButton: {
        root: {
            borderRadius: 0,
            padding: 8,
            // marginRight: 5,
            "&:hover": {
                // backgroundColor: themeColors.rollover,
                color: themeColors.ui_blue
            },
            "&$selected": {
                backgroundColor: themeColors.selection,
                color: "#333"
            }
        }
    },
    MuiDialog: {
        paper: {
            position: "inherit"
        }
    },
    MuiDialogContentText: {
        root: {
            // p: {
            //     marginBottom: 10
            // }
        }
    },
    MuiMenu: {
        paper: {
            background: "white",
            borderRadius: 0
        }
    },
    MuiMenuItem: {
        root: {
            color: "#333",
            fontFamily: "Source Sans Pro",
            fontSize: 14,
            fontWeight: 600,
            "&:hover": {
                color: "#333",
                backgroundColor: themeColors.selection
            },
            "&$selected": {
                color: "#333",
                backgroundColor: themeColors.selection,
                "&:hover": {
                    backgroundColor: `${themeColors.selection} !important`,
                    color: themeColors.ui_blue
                }
            },
            "& .MuiSvgIcon-root": {
                marginRight: 8,
                opacity: 0.8,
                fontSize: "1.25em"
            },
            "& .MuiIcon-root": {
                marginRight: 8,
                opacity: 0.8,
                fontSize: "1.25em",
                color: "currentColor"
            }
        },
    },

    MuiList:
        muiList,
    MuiListItem: {
        root: {
            fontFamily: "inherit !important"
        },
        divider: {
            borderBottomColor: "rgba(0,0,0,.12)"
        }
    },
    MuiPopover: {
        paper: {
            backgroundColor: "#fff"
        }
    },
    MuiInputAdornment: {
        root: {
            color: "#333"
        }
    },
    MuiSwitch: {
        switchBase: {
            height: 32
        }
    },

    MuiFormControlLabel: {
        label: {
            fontSize: 14,
            fontWeight: 600
        }
    },

    MuiSlider: {
        valueLabel: {
            color: "#d6eff9"
        },
        thumb: {
            boxShadow: "none !important"
        }
    }
};

const props = {
    MuiTooltip: {
        enterDelay: 750,
        arrow: true
    }
};

const theme = createTheme({
    typography: {
        useNextVariants: true, //Used to prevent typography migration error from material-ui (https://material-ui.com/style/typography#migration-to-typography-v2)
        fontFamily: "Source Sans Pro"
    },
    palette: {
        type: "light",
        primary: {
            50: "#e2f5fc",
            100: "#b8e5f6",
            200: "#88d4f1",
            300: "#58c3eb",
            400: "#35b6e6",
            500: "#11a9e2",
            600: "#0fa2df",
            700: "#0c98da",
            800: "#0a8fd6",
            900: "#057ecf",
            A100: "#f7fcff",
            A200: "#c4e5ff",
            A400: "#91cfff",
            A700: "#78c3ff",
            contrastDefaultColor: "dark"
        },
        secondary: {
            50: "#fafbfc",
            100: "#f4f6f7",
            200: "#ecf0f1",
            300: "#e4eaeb",
            400: "#dfe6e7",
            500: "#d9e1e3",
            600: "#d5dde0",
            700: "#cfd9dc",
            800: "#cad5d8",
            900: "#c0cdd0",
            A100: "#ffffff",
            A200: "#ffffff",
            A400: "#ffffff",
            A700: "#ffffff",
            contrastDefaultColor: "dark"
        },
        grey: {
            50: "#e6e6e6",
            100: "#c2c2c2",
            200: "#999999",
            300: "#707070",
            400: "#515151",
            500: "#323232",
            600: "#2d2d2d",
            700: "#282828",
            800: "#1f1f1f",
            900: "#131313",
            contrastDefaultColor: "light"
        }
    },
    overrides: overrides,
    props: props
});

export default theme;

export const dialogTheme = createTheme({
    typography: {
        useNextVariants: true, //Used to prevent typography migration error from material-ui (https://material-ui.com/style/typography#migration-to-typography-v2)
        fontFamily: "Source Sans Pro",
        h2: {
            fontSize: 40
        },
        h6: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: 1.2
        }
    },
    palette: {
        type: "light",
        primary: {
            main: "#11a9e2"
        },
        secondary: {
            main: "#777"
        }
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                padding: "34px 34px 30px"
            }
        },
        MuiDialogContentText: {
            root: {
                color: "rgba(0,0,0,.75)"
            }
        },
        MuiDialogActions: {
            root: {
                margin: "8px 12px 12px"
            }
        },
        MuiMenu: {
            paper: {
                background: "white",
                borderRadius: 0
            }
        },
        MuiMenuItem: {
            root: {
                color: "#333",
                fontFamily: "Source Sans Pro",
                fontSize: 14,
                fontWeight: 600,
                "&:hover": {
                    backgroundColor: "#50bbe6",
                    color: "white"
                },
                "&$selected": {
                    color: "#50bbe6",
                    backgroundColor: "white !important",
                    "&:hover": {
                        backgroundColor: "#50bbe6 !important",
                        color: "white"
                    }
                }
            },
        },
        MuiSelect: {
            select: {
                color: "#333",
                fontSize: 14,
                fontWeight: 600,
                textTransform: "uppercase",
                borderRadius: 0,
                // border: "solid 1px #aaa",
                border: "none",
                padding: "8px 25px 8px 12px"
            }
        },
        MuiButton: {
            root: {
                borderRadius: 0
            },
            text: {
                fontWeight: 500
            },
            label: {
                fontWeight: 600,
                letterSpacing: 0.5,
                display: "grid",
                gridAutoFlow: "column",
                columnGap: 10
            },
            contained: {
                boxShadow: "none",
                height: "44px",
                borderRadius: 2
            },
            containedPrimary: {
                color: "white"
            },
            containedSizeSmall: {
                fontSize: 11,
                padding: "0px 16px",
                height: 37
            }
        },
        MuiInput: {
            underline: {
                "&:before": {
                    borderBottom: "solid 1px #ccc",
                    borderBottomWidth: "1px !important"
                },
                "&:after": {
                    borderBottom: "solid 1px #11a9e2"
                }
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        MuiListItem: {
            root: {
                "&$selected": {
                    background: "red"
                },
            },
            divider: {
                borderBottomColor: "rgba(0,0,0,.12)"
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderRadius: 0
            },
            input: {
                padding: 10
            }
        },
        MuiTable: {
            root: {
                borderRight: "solid 1px #ccc"
            }
        },
        MuiTableHead: {
            root: {
                background: "#f9f9f9"
            }
        },
        MuiTableCell: {
            head: {
                color: "black",
                fontWeight: 600
            }
        },
    }
});

export const darkTheme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#11A9E2",
        },
    },
    overrides: {
        MuiPaper: {
            root: {
                color: "rgba(255, 255, 255, 0.87)",
                backgroundColor: "#4C4E55",
            },
        },
        MuiButton: {
            root: {
                color: "rgba(255, 255, 255, 0.87)",
                "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.04)",
                },
            },
            outlined: {
                borderColor: "rgba(255, 255, 255, 0.23)",
            },
        },
        MuiInputBase: {
            input: {
                // backgroundColor: "#222",
                color: "rgba(255, 255, 255, 0.87)",
            },
        },
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.23)",
                },
                "&:hover $notchedOutline": {
                    borderColor: "white",
                },
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "rgba(255, 255, 255, 0.12)"
            },
        },
    }
});
