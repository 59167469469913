import React, { Component } from "reactn";
import styled from "styled-components";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";

import { getStaticUrl } from "js/config";
import DialogContent from "./DialogContent";
import BeautifulDialog from "./BeautifulDialog";

const SadRobotIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
    width: 100%;

    img {
        height: 55px;
    }
`;

const Title = styled(DialogTitle)`
    &&& {
        padding: 20px 34px 20px;
        text-align: center;
    }
`;

const Content = styled(DialogContent)`
    &&& {
        text-align: center;

        p {
            margin-bottom: 20px;

            &.bold {
                font-weight: 700;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
`;

const Actions = styled(DialogActions)`
    &&& {
        margin: 0 12px 12px;
        padding-top: 0;
    }
`;

const OkButton = styled(Button)`
    &&& {
        color: #11A9E2;
    }
`;

export default class ErrorDialog extends Component {
    render() {
        const {
            error,
            title,
            message,
            acceptCallback,
            acceptOnClose,
            closeDialog,
            acceptButtonText,
            preventClose = false,
            hideBackdrop = false,
            icon = "/images/bai-bot/bai-bot-sad.png",
            zIndex = 10000
        } = this.props;
        return (
            <BeautifulDialog
                hideBackdrop={hideBackdrop}
                classes={{ paper: "error-dialog" }}
                closeDialog={() => {
                    if (acceptOnClose) {
                        acceptCallback && acceptCallback();
                    }
                    closeDialog();
                }}
                preventClose={preventClose}
                style={{ zIndex }}
            >
                <SadRobotIconContainer>
                    <img src={getStaticUrl(icon)}></img>
                </SadRobotIconContainer>
                <Title>{error || title}</Title>
                <Content>
                    {message instanceof Array && message.map(message => <p>{message}</p>)}
                    {message instanceof Error && message.message}
                    {message && message.type === React.Fragment && message}
                    {typeof message === "string" && message}
                </Content>
                <Actions>
                    <OkButton
                        onClick={() => {
                            acceptCallback && acceptCallback();
                            closeDialog();
                        }}
                    >
                        {acceptButtonText ?? "Ok"}
                    </OkButton>
                </Actions>
            </BeautifulDialog>
        );
    }
}
