import React, { Component } from "react";
import Spinner from "./Spinner";
import ErrorMessage from "js/react/components/ErrorMessage";
import { FetchStatus } from "js/react/constants";

export default class Table extends Component {
    render() {
        let {
            columns,
            rows,
            status,
            errorTitle,
            noRowsMessage,
            onRowClick = false
        } = this.props;

        let contents;
        switch (status.type) {
            case FetchStatus.LOADING:
                contents = <Spinner />;
                break;
            case FetchStatus.ERROR:
                contents = (
                    <ErrorMessage
                        title={
                            errorTitle || "An error occurred while loading data"
                        }
                        message={status.message}
                    />
                );
                break;
            case FetchStatus.SUCCESS:
            default:
                if (rows && rows.length) {
                    contents = (
                        <table >
                            <thead>
                                <tr>
                                    {columns.map(({ name, width }, i) => (
                                        <th
                                            key={i}
                                            style={
                                                typeof width !== "undefined" ? { width } : null
                                            }
                                        >{name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className={onRowClick ? "allow-clicks" : ""}>
                                {rows.map((row, i) => (
                                    <tr
                                        key={i}
                                        className={
                                            row.isSelected === true
                                                ? "selected"
                                                : ""
                                        }
                                        onClick={e => onRowClick && (onRowClick(row.originalData))}
                                    >
                                        {row.map((col, i) => (
                                            <td key={i}>{col}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    );
                } else {
                    contents = (
                        <div className="table-no-rows">
                            {noRowsMessage || "No Data"}
                        </div>
                    );
                }
        }

        return <div className="table">{contents}</div>;
    }
}
