import { IGenericModelFields } from "./IGenericFields";

export enum PresentationLinkType {
    PUBLIC = "public",
    PROTECTED = "protected",
    INVITE = "invite",
    EMBED = "embed",
    SOCIAL = "social",
    MEETING = "meeting",
    INTEGRATOR = "integrator",
}

export interface IPresentationLink extends IGenericModelFields {
    presentationId: string;
    type: PresentationLinkType;
    views: number;
    enabled: boolean;
    name: string;
    // used for "embed" type
    embedOptions?: {
        width: number;
        height: number;
        useCustomSize: boolean;
    };
    // used for "protected" type
    passwordUid?: string;
    // used for "invite" type
    email?: string;
    customMessage?: string;
    salesforce?: {
        [ContactEmail: string]: {
            ContactId: string;
            PresentationLinkId: string;
            PresentationViewId: string;
            views: {
                [PresentationViewId: string]: number
            }
        }
    };
    expiresAt: number;
    createdAt: number;
    modifiedAt: number;
    createdBy: string;
}
