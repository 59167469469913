export interface IPresentationActivityLog {
    id: string;
    presentationId: string;
    userId?: string;
    activity: PresentationActivityType;
    activityData: any;
    createdAt: number;
    modifiedAt: number;
}

export enum PresentationActivityType {
    CREATED = "created",
    EDITED = "edited",
    VIEWED = "viewed",
    COLLABORATOR_ADDED = "collaboratorAdded",
    LINK_CREATED = "linkCreated",
    COMMENT_ADDED = "commentAdded",
    EDITOR_OPENED = "editorOpened",
    EDITOR_CLOSED = "editorClosed",
    TRASHED = "trashed",
    UNTRASHED = "untrashed",
    DECK_COPIED = "deckCopied",
    PDF_DOWNLOAD = "pdfDownload"
}
