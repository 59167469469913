import React, { Component } from "react";
import Spinner from "js/react/components/Spinner";
import { FetchStatus } from "js/react/constants";
import ErrorMessage from "js/react/components/ErrorMessage";
import LinkViews from "../Components/LinkViews";
import { LinkStats } from "../Components/Stats";
import linkDS, {
    LinkType
} from "js/react/views/PresentationSettings/dataservices/LinkDataService";
import {
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "js/react/components/UiComponents";
import AnalyticsController from "js/react/views/Analytics/AnalyticsController";

class LinkAnalyticsPane extends Component {
    componentDidMount() {
        AnalyticsController.fetchLinkAnalytics();
    }

    handleBackButton() {
        // don't set active user to null or animation will crash
        AnalyticsController.clearSelectedLink();
    }

    render() {
        let selectedLink = this.props.analytics.selectedLink;
        let { status } = selectedLink;

        return (
            <UIPane className="player_analytics analytics-userpage">
                <UIPaneHeader> {this.renderHeader()}</UIPaneHeader>
                <UIPaneContents>
                    <div className="header-block user-view"></div>
                    <div className="contents-block">
                        {status.type === FetchStatus.LOADING && <Spinner />}
                        {status.type === FetchStatus.ERROR && (
                            <ErrorMessage
                                title="An error occurred while loading analytics"
                                message={status.message}
                            />
                        )}
                        {status.type === FetchStatus.SUCCESS && (
                            <div className="userpage-contents">
                                <LinkStats />
                                <LinkViews />
                            </div>
                        )}
                    </div>
                </UIPaneContents>
            </UIPane>
        );
    }

    renderHeader() {
        let selectedLink = this.props.analytics.selectedLink;

        if (selectedLink.linkid) {
            let link = linkDS.getLinkById(this.props.presentation, selectedLink.linkid);

            if (link) {
                let linkName;
                switch (link.get("type")) {
                    case LinkType.PUBLIC:
                        linkName = "Shareable Link: " + link.getLinkName();
                        break;
                    case LinkType.INVITE:
                        linkName = "Invite Link: " + link.getLinkName();
                        break;
                    case LinkType.EMBED:
                        linkName = "Embedded";
                        break;
                    case LinkType.SOCIAL:
                        linkName = `Social Link on ${link.getLinkName()}`;
                        break;
                    case LinkType.INTEGRATOR:
                        linkName = "Integration: " + link.getLinkName();
                        break;
                }

                return <div className="user-header">{linkName}</div>;
            } else {
                return <div className="user-header">Deleted Link</div>;
            }
        } else {
            return <div className="user-header">Link</div>;
        }
    }
}

export default AnalyticsController.withState(LinkAnalyticsPane);
