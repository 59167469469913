import { $, _ } from "js/vendor";
import { HiliteType } from "common/constants";
import getLogger from "js/core/logger";
import { themeColors } from "js/react/sharedStyles";

const logger = getLogger();

export function parseStylesSheetValue(val) {
    if (val.has("%") || (val.has(" ") && isNaN(parseFloat(val)))) {
        return val;
    } else if (val == "true") {
        return true;
    } else if (val == "false") {
        return false;
    } else if (isNaN(parseFloat(val))) {
        return val;
    } else {
        if (val.indexOf(" + ") > -1 || val.indexOf(" - ") > -1 || val.indexOf(" * ") > -1 || val.indexOf(" / ") > -1) {
            return parseFloat(eval(val));
        } else {
            return parseFloat(val);
        }
    }
}

class StyleSheet {
    constructor(theme, str) {
        // strip comments
        str = str.replace(/\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm, "");

        // find variables
        var variables = [];
        let m;
        var regex = /(\$.*?):(.*?);/g;
        while ((m = regex.exec(str)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            variables.push({
                m: m[0],
                name: m[1].trim(),
                value: m[2].trim()
            });
        }

        this.variables = {};

        // update variables with theme settings

        // inject styleguide properties into stylesheet variables
        for (let variable of variables) {
            if (theme.has(variable.name.substr(1))) {
                // if the theme has overridden this variable, use it's value to override the stylesheet default value
                variable.value = theme.get(variable.name.substr(1));
            }
            this.variables[variable.name.substr(1)] = this.parseValue(variable.value);
        }

        // create variables for all the theme colors
        _.each(theme.get("colors"), (value, key) => {
            this.variables["color_" + key] = value;
        });

        // set shape variables based on theme style settings
        if (theme.has("styleShape")) {
            switch (theme.get("styleShape")) {
                case "rounded_rect":
                    this.variables["shapeType"] = "rect";
                    break;
                case "rect":
                default:
                    this.variables["shapeType"] = theme.get("styleShape");
                    this.variables["shapeCornerRadius"] = 0;
            }
        }

        let colors = _.cloneDeep(theme.get("colors"));

        // ensure we have at least 10 chart colors, apply in cycle
        let chartColorNames = Object.keys(colors).filter(key => key.startsWith("chart")).sort();
        _.range(0, 10).forEach(idx => {
            colors[`chart${idx + 1}`] = colors[chartColorNames[idx % chartColorNames.length]];
        });

        // ensure we have at least 10 accent colors, use theme color for non-existent ones to mimic palette behavior
        let accentColorNames = Object.keys(colors).filter(key => key.startsWith("accent")).sort();
        if (accentColorNames.length < 10) {
            _.range(accentColorNames.length, 10).forEach(idx => {
                colors[`accent${idx + 1}`] = colors.theme;
            });
        }

        // delete any existing theme-colors stylesheet
        document.getElementById("theme-colors")?.remove();

        // append a stylesheet to body that has classes for the theme colors so that textblocks can reference them
        let style = document.createElement("style");
        style.id = "theme-colors";
        style.type = "text/css";
        style.innerHTML = "";
        _.each(colors, (value, key) => {
            // add a class for the color with the word "color" prepended to it
            style.innerHTML += `.color-${key} { color: ${value}; }\n`;
        });
        document.head.appendChild(style);

        this.parseStyleSheet(this, str.trim());
    }

    applyStyleSheetVariables(variables, styleSheet = this, node = this) {
        // apply variables to variable values first
        for (let variable of Object.keys(variables)) {
            let value = variables[variable];
            if (value && typeof (value) == "string" && value.startsWith("$")) {
                variables[variable] = variables[value.substring(1)];
            }
        }

        styleSheet.decorationVariables = _.pick(variables, ["shapeFillColor", "shapeStrokeColor", "shapeCornerRadius", "shapeStrokeWidth", "shapeEffect", "iconStyle", "iconShapeType", "iconFrameFillColor", "iconFrameStrokeColor", "iconFrameStrokeWidth", "iconFrameCornerRadius", "iconFillColor", "connectorStrokeWidth", "connectorDashArray", "primaryConnectorStrokeWidth", "primaryConnectorStrokeColor", "radialBarCircleStrokeWidth", "radialBarCircleStrokeColor", "radialBarCircleDash", "radialBarPathStrokeWidth", "radialBarPathStrokeColor", "radialBarPathDash", "emphasizedFillColor", "emphasizedStrokeColor", "emphasizedStrokeWidth", "deemphasizedFillColor", "deemphasizedStrokeColor", "deemphasizedStrokeWidth", "chartLineWidth", "chartMarkerRadius"]);
        let activeVariables = _.omit(variables, ["shapeFillColor", "shapeStrokeColor", "shapeCornerRadius", "shapeStrokeWidth", "shapeEffect", "iconStyle", "iconShapeType", "iconFrameFillColor", "iconFrameStrokeColor", "iconFrameStrokeWidth", "iconFrameCornerRadius", "iconFillColor", "connectorStrokeWidth", "connectorDashArray", "primaryConnectorStrokeWidth", "primaryConnectorStrokeColor", "radialBarCircleStrokeWidth", "radialBarCircleStrokeColor", "radialBarCircleDash", "radialBarPathStrokeWidth", "radialBarPathStrokeColor", "radialBarPathDash", "emphasizedFillColor", "emphasizedStrokeColor", "emphasizedStrokeWidth", "deemphasizedFillColor", "deemphasizedStrokeColor", "deemphasizedStrokeWidth", "chartLineWidth", "chartMarkerRadius"]);

        for (var key of Object.keys(node)) {
            if (typeof (node[key]) == "object" && key != "palette" && key != "variables") {
                this.applyStyleSheetVariables(variables, styleSheet, node[key]);
            } else {
                if (typeof node[key] === "string") {
                    if (key == "fontStyle") {
                        let fontStyle = node[key]; // body|header|display|title
                        let isAlwaysEmphasized = false;
                        if (fontStyle.includes("Emphasized")) {
                            fontStyle = fontStyle.split("Emphasized")[0];
                            isAlwaysEmphasized = true;
                            node.defaultFormat = "bold";
                        }

                        node["fontId"] == undefined && (node["fontId"] = activeVariables[`${fontStyle}FontId`]);

                        if (node["fontBoldWeight"] == undefined && activeVariables[`${fontStyle}FontBoldWeight`]) {
                            node["fontBoldWeight"] = activeVariables[`${fontStyle}FontBoldWeight`];
                        }

                        if (node["fontWeight"] == undefined) {
                            if (isAlwaysEmphasized) {
                                if (node["fontBoldWeight"] != undefined) {
                                    node["fontWeight"] = node["fontBoldWeight"];
                                } else {
                                    // At least 700
                                    node["fontWeight"] = Math.min(activeVariables[`${fontStyle}FontWeight`], 700);
                                }
                            } else {
                                node["fontWeight"] = activeVariables[`${fontStyle}FontWeight`];
                            }
                        }

                        node["lineHeight"] == undefined && (node["lineHeight"] = activeVariables[fontStyle + "FontLineHeight"]);
                        node["letterSpacing"] == undefined && (node["letterSpacing"] = activeVariables[fontStyle + "FontLetterSpacing"] || 0);
                        node["textTransform"] == undefined && (node["textTransform"] = activeVariables[fontStyle + "FontTextTransform"] || "auto");
                        node["fontScaling"] == undefined && (node["fontScaling"] = activeVariables[fontStyle + "FontScaling"] || 100);
                        node["filter"] == undefined && (node["filter"] = activeVariables[fontStyle + "FontEffect"] || "none");
                        if (node["bold"] == undefined) {
                            node["bold"] = {};
                        }
                        (node["bold"]["fontColor"] == undefined && activeVariables[fontStyle + "FontBoldColor"] != undefined) && (node["bold"]["fontColor"] = activeVariables[fontStyle + "FontBoldColor"]);
                    }

                    node[key] = node[key].replace(/(\$.*?)(?= |'|"|;|$|})/g, $.proxy(function(match, p1) {
                        if (activeVariables[p1.substr(1)]) {
                            return activeVariables[p1.substr(1)];
                        } else {
                            return p1;
                        }
                    }, styleSheet));

                    node[key] = this.parseValue(node[key]);
                }
            }
        }
    }

    // ppt exporter doesn't support alpha in a fillColor or strokeColor so we are pre-processing to split out those colors into strokeColor/strokeOpacity fillColor/fillOpacity
    processFillAndStroke(node = this) {
        for (let key of Object.keys(node)) {
            if (typeof (node[key]) == "object" && key != "palette" && key != "variables") {
                this.processFillAndStroke(node[key]);
            } else {
                if (key == "fillColor" || key == "strokeColor") {
                    let value = node[key];
                    if (value.contains(" ") && !isNaN(value.split(" ")[1])) {
                        node[key] = value.split(" ")[0];
                        node[key.replace("Color", "Opacity")] = parseFloat(value.split(" ")[1]);
                    } else if (node[key.replace("Color", "Opacity")] == undefined) {
                        node[key.replace("Color", "Opacity")] = 1;
                    }
                }
            }
        }
    }

    parseValue(val) {
        if (val.has("%") || (val.has(" ") && isNaN(parseFloat(val)))) {
            return val;
        } else if (val == "true") {
            return true;
        } else if (val == "false") {
            return false;
        } else if (isNaN(parseFloat(val))) {
            return val;
        } else {
            if (val.indexOf(" + ") > -1 || val.indexOf(" - ") > -1 || val.indexOf(" * ") > -1 || val.indexOf(" / ") > -1) {
                return parseFloat(eval(val));
            } else {
                return parseFloat(val);
            }
        }
    }

    parseStyleSheet(node, str) {
        var selector;
        var rules;
        var property;
        var value;
        var pos = 0;
        var chunk = "";
        var indent = 0;

        var inString = false;

        while (pos < str.length) {
            var ch = str[pos];
            chunk += ch;
            pos++;

            if (!inString && ch == "{" && indent == 0) {
                selector = chunk.replace("{", "").trim();
                node[selector] = {};
                chunk = "";
                indent = 1;
            } else if (!inString && ch == "{") {
                indent++;
            } else if (!inString && ch == "}") {
                indent--;
                if (indent == 0) {
                    rules = chunk.substr(1);
                    chunk = "";
                    this.parseStyleSheet(node[selector], rules);
                }
            } else if (!inString && ch == ":" && indent == 0) {
                property = chunk.replace(":", "").trim();
                if (property.has("-")) {
                    property = property.split("-")[0] + property.split("-")[1].toTitleCase();
                }

                chunk = "";
            } else if (!inString && ch == ";" && indent == 0) {
                value = chunk.replace(/;$/, "").replace(/"||'/g, "").trim();
                chunk = "";

                switch (property) {
                    case "margin":
                        var margins = value.split(" ");
                        switch (margins.length) {
                            case 1:
                                node.marginLeft = node.marginRight = node.marginTop = node.marginBottom = parseFloat(value);
                                break;
                            case 2:
                                node.marginTop = node.marginBottom = parseFloat(margins[0]);
                                node.marginLeft = node.marginRight = parseFloat(margins[1]);
                                break;
                            case 3:
                                node.marginTop = parseFloat(margins[0]);
                                node.marginLeft = node.marginRight = parseFloat(margins[1]);
                                node.marginBottom = parseFloat(margins[2]);
                                break;
                            default:
                                node.marginTop = parseFloat(margins[0]);
                                node.marginRight = parseFloat(margins[1]);
                                node.marginBottom = parseFloat(margins[2]);
                                node.marginLeft = parseFloat(margins[3]);
                        }
                        break;
                    case "padding":
                        var padding = value.split(" ");
                        switch (padding.length) {
                            case 1:
                                node.paddingLeft = node.paddingRight = node.paddingTop = node.paddingBottom = parseFloat(value);
                                break;
                            case 2:
                                node.paddingTop = node.paddingBottom = parseFloat(padding[0]);
                                node.paddingLeft = node.paddingRight = parseFloat(padding[1]);
                                break;
                            case 3:
                                node.paddingTop = parseFloat(padding[0]);
                                node.paddingLeft = node.paddingRight = parseFloat(padding[1]);
                                node.paddingBottom = parseFloat(padding[2]);
                                break;
                            default:
                                node.paddingTop = parseFloat(padding[0]);
                                node.paddingRight = parseFloat(padding[1]);
                                node.paddingBottom = parseFloat(padding[2]);
                                node.paddingLeft = parseFloat(padding[3]);
                        }
                        break;
                    case "fontSize":
                        node[property] = this.parseValue(value);
                        break;
                    case "strokeDash":
                        node[property] = value;
                        break;
                    case "fill":
                    case "stroke":
                        logger.warn("[StyleSheet] parseStyleSheet() BSS should not set fill or stroke directly. Use fillColor or strokeColor instead");
                        break;
                    default:
                        node[property] = this.parseValue(value);
                }
            } else if (ch == "\"") {
                inString = !inString;
            }
        }
        // create margins and padding objects
        if (node) {
            Object.defineProperty(node, "margins", {
                get() {
                    return {
                        left: node.marginLeft || 0,
                        right: node.marginRight || 0,
                        top: node.marginTop || 0,
                        bottom: node.marginBottom || 0
                    };
                }
            });
            Object.defineProperty(node, "padding", {
                get() {
                    return {
                        left: node.paddingLeft || 0,
                        right: node.paddingRight || 0,
                        top: node.paddingTop || 0,
                        bottom: node.paddingBottom || 0
                    };
                }
            });
        }
        return node;
    }

    getStyleNode(path, leafElement) {
        if (typeof path == "string") {
            path = path.split("/");
        }

        // if a leafElement isn't provided we will always return the styles at the provided path
        var foundStylesForElement = leafElement == null;

        var node = this; // start searching at the root style node

        // move through the chunks of the path, checking to see if a style node exists for that chunk
        // if a leafElement was provided, we will only return a style node for that matches that leafElement - there is no inheritance from parent style nodes
        for (var i of path) {
            if (i.construtor?.isElement) {
                var foundNode = null;
                if (node[i.type + "." + i.id]) {
                    foundNode = node[i.type + "." + i.id];
                } else if (node[i.id]) {
                    foundNode = node[i.id];
                } else if (node[i.type]) {
                    foundNode = node[i.type];
                }

                if (foundNode) {
                    node = foundNode;
                    if (i == leafElement) {
                        foundStylesForElement = true;
                    }
                } else {
                    // we will continue past missing intermediate nodes if we didn't find a match in path
                    // but we don't continue if we didn't find the root of the path
                    if (i == path[0]) {
                        break;
                    }
                }
            } else {
                if (node[i]) {
                    node = node[i];
                }
            }
        }

        if (!leafElement || foundStylesForElement) {
            return _.cloneDeep(node);
        } else {
            return null;
        }
    }

    applyHiliteStyles(styles, hiliteState) {
        switch (hiliteState) {
            case HiliteType.DEEMPHASIZED:
                if (styles.deemphasized) {
                    return _.merge(styles, styles.deemphasized);
                } else {
                    return styles;
                }
            case HiliteType.EMPHASIZED:
                if (styles.emphasized) {
                    return _.merge(styles, styles.emphasized);
                } else {
                    return styles;
                }
            case HiliteType.NONE:
                return styles;
        }
        return styles;
    }
}

class ElementStyles {
    constructor(styles = null) {
        // this.font = "SourceSansPro-Regular";
        // this.fontWeight = 500;
        // this.fontSize = 20;

        this.marginLeft = 0;
        this.marginRight = 0;
        this.marginTop = 0;
        this.marginBottom = 0;
        this.paddingLeft = 0;
        this.paddingRight = 0;
        this.paddingTop = 0;
        this.paddingBottom = 0;

        if (styles) {
            // TODO potential performance issue
            // we have to deepclone because the passed in styles may contain child style objects and we need to have
            // our own copy of the entire tree
            Object.assign(this, _.cloneDeep(styles));
        }
    }

    get margins() {
        return {
            left: this.marginLeft,
            right: this.marginRight,
            top: this.marginTop,
            bottom: this.marginBottom
        };
    }

    get padding() {
        return {
            left: this.paddingLeft,
            right: this.paddingRight,
            top: this.paddingTop,
            bottom: this.paddingBottom
        };
    }

    replaceVariable(variable, value) {
        let stylesAsJSON = JSON.stringify(this);
        // Javascript does nor allow shorthand regex for global replace
        // based on a variable pattern -- need to instantiate an actual
        // instance of the RegExp object in order to do so.
        const variableReplacer = new RegExp(variable, "g");
        stylesAsJSON = stylesAsJSON.replace(variableReplacer, value);
        _.extend(this, JSON.parse(stylesAsJSON));
    }

    scaleTextStyles(scale) {
        let scaleProps = styleNode => {
            _.each(styleNode, (value, property) => {
                if (property.equalsAnyOf("fontSize", "marginTop", "marginBottom", "marginLeft", "marginRight", "width", "height", "vGap")) {
                    styleNode[property] = value * scale;
                }
                if (typeof value == "object") {
                    scaleProps(value);
                }
            });
        };

        scaleProps(this);
    }

    applyStyles(...styles) {
        for (let style of styles) {
            _.merge(this, style);
        }
        return this;
    }

    applyDecorationStyles(element, decorationStyle) {
        let applyStyles = styleNode => {
            _.each(styleNode, (value, property) => {
                if (typeof value === "string" && value.startsWith("$")) {
                    let variable = value.split(" ")[0];
                    if (decorationStyle && decorationStyle != "none") {
                        styleNode[property] = styleNode[property].replace(variable, element.canvas.decorationStyles[decorationStyle].variables[variable.substr(1)] || element.canvas.styleSheet.variables[variable.substr(1)]);
                    } else {
                        styleNode[property] = styleNode[property].replace(variable, element.canvas.styleSheet.decorationVariables[variable.substring(1)] || element.canvas.styleSheet.variables[variable.substr(1)]);
                    }
                    styleNode[property] = parseStylesSheetValue(styleNode[property]);
                }
                if (typeof value == "object") {
                    applyStyles(value);
                }
            });
        };
        applyStyles(this);
    }
}

// function createShadowGlowFilter(styleNode, slideColor, backgroundColoryles) {
//     let filterId = "shadowGlow_" + styleNode.fontSize;
//
//     if ($("#svg-filters").find("#" + filterId).length == 0) {
//         let filterSVG = `<filter id='${filterId}' x='-25%' y='-25%' width='200%' height='200%'>
//              <feGaussianBlur in='SourceAlpha' result='blur-out' stdDeviation='${styleNode.fontSize / 7}' />
//              <feColorMatrix in='blur-out' result='color-out' type='matrix'
//               values='0 0 0 0   0
//                       0 0 0 0   0
//                       0 0 0 0   0
//                       0 0 0 .5 0'/>
//              <feBlend in='SourceGraphic' in2='color-out' mode='normal'/>
//           </filter>`;
//
//         SVG(filtersNode).svg(`<defs>${filterSVG}</defs>`);
//     }
//     return filterId;
// }
//
// function createTextStrokeFilter(styleNode, slideColor, backgroundColor) {
//     let filterId = "textStroke_" + backgroundColor.toHexString();
//
//     if ($("#svg-filters").find("#" + filterId).length == 0) {
//         let filterSVG = `<filter id='${filterId}' x='-50%' y='-50%' width='200%' height='200%'>
//             <feMorphology in='SourceAlpha' result='DILATED' operator='dilate' radius='4 2'></feMorphology>
//
//             <feFlood flood-color='${backgroundColor.toHexString()}' flood-opacity='1' result='WHITE'></feFlood>
//             <feComposite in='WHITE' in2='DILATED' operator='in' result='OUTLINE'></feComposite>
//
//             <feMerge>
//                 <feMergeNode in='OUTLINE'/>
//                 <feMergeNode in='SourceGraphic'/>
//             </feMerge>
//           </filter>`;
//
//         SVG(filtersNode).svg(`<defs>${filterSVG}</defs>`);
//     }
//     return filterId;
// }

const predefinedThemeColors = [{
    theme: "#64C3FF",
    palettes: [{
        name: "Colorful",
        colors: ["#00A0B0", "#97AA0F", "#2980B9", "#F39C12", "#BD1550", "#E74C3C"]
    }, {
        name: "Shades",
        colors: ["#00A0B0", "#97AA0F", "#2980B9", "#F39C12", "#BD1550", "#E74C3C"]
    }, {
        name: "Complementary",
        colors: ["#91C765"]
    }]
},
{
    theme: "#3E8CE5",
    palettes: [{}]
},
{ theme: "#2E5390", palettes: [{}] },
{ theme: "#9652FD", palettes: [{}] },
{ theme: "#DA63B9", palettes: [{}] },
{ theme: "#F35B5B", palettes: [{}] },
{ theme: "#FF904F", palettes: [{}] },
{ theme: "#FFCB4F", palettes: [{}] },
{ theme: "#91C765", palettes: [{}] },
{ theme: "#00AC8E", palettes: [{}] },
{ theme: "#353535", palettes: [{}] }];

const predefinedPalettes = [{
    id: "bright",
    name: "Bright",
    old_id: "Bright",
    colors: {
        theme: "#3E8CE5",
        accent1: "#38DBB9",
        accent2: "#3CD1EA",
        accent3: "#FFC045",
        accent4: "#FF9456",
        accent5: "#E46183",
        background_light: "#fff",
        background_dark: "#30353d",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "colorful",
    name: "Colorful",
    old_id: "Beautiful",
    colors: {
        theme: "#00A0B0",
        accent1: "#97AA0F",
        accent2: "#2980B9",
        accent3: "#F39C12",
        accent4: "#BD1550",
        accent5: "#E74C3C",
        background_light: "#fff",
        background_dark: "#333",
        primary_light: "#fff",
        primary_dark: "#333",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(0,0,0,0.6)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "pastels",
    name: "Pastels",
    old_id: "The Nicest One from PPT",
    colors: {
        theme: "#749FC7",
        accent1: "#A77FC7",
        accent2: "#E48AA9",
        accent3: "#F2BA00",
        accent4: "#FF9C00",
        accent5: "#AA6469",
        background_light: "#fff",
        background_dark: "#444",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "old_school",
    name: "Old School",
    old_id: "Office Default",
    colors: {
        theme: "#4472C4",
        accent1: "#ED7D31",
        accent2: "#A5A5A5",
        accent3: "#FFC003",
        accent4: "#5B9BD5",
        accent5: "#71AE47",
        background_light: "#fff",
        background_dark: "#35373a",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "red_shift",
    name: "Red Shift",
    old_id: "Sexy Blue Purple Red ",
    colors: {
        theme: "#2E5390",
        accent1: "#404176",
        accent2: "#A20E57",
        accent3: "#F10032",
        background_light: "#fff",
        background_dark: "#1a182c",
        primary_light: "#fff",
        primary_dark: "#44417A",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "candy",
    name: "Candy",
    old_id: "Candy",
    colors: {
        theme: "#DA63B9",
        accent1: "#B7DA63",
        accent2: "#3AD1ED",
        accent3: "#FFCB4F",
        background_light: "#fff",
        background_dark: "#783665",
        primary_light: "#fff",
        primary_dark: "#4C627A",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "hooli",
    name: "Hooli",
    old_id: "Google",
    colors: {
        theme: "#3786F9",
        accent1: "#00AA4A",
        accent2: "#FFB900",
        accent3: "#F92F31",
        background_light: "#fff",
        background_dark: "#383a40",
        primary_light: "#fff",
        primary_dark: "#757575",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "autumn",
    name: "Autumn",
    old_id: "Autumn",
    colors: {
        theme: "#FF904F",
        accent1: "#F3735B",
        accent2: "#C94160",
        accent3: "#93654F",
        background_light: "#fff",
        background_dark: "#302c2c",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "reds",
    name: "Reds",
    old_id: "The Red One",
    colors: {
        theme: "#F35B5B",
        accent1: "#C03F3F",
        accent2: "#8e2c2d",
        background_light: "#fff",
        background_dark: "#232323",
        primary_light: "#fff",
        primary_dark: "#2d2d2d",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "greens",
    name: "Greens",
    old_id: "The Green One",
    colors: {
        theme: "#91C765",
        accent1: "#B7DA63",
        accent2: "#E4E56A",
        background_light: "#fff",
        background_dark: "#595D65",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "cool_grays",
    name: "Cool Grays",
    old_id: "Cool Greys",
    colors: {
        theme: "#506E7B",
        accent1: "#8DA5AF",
        background_light: "#fff",
        background_dark: "#252b32",
        primary_light: "#fff",
        primary_dark: "#1e343b",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "moody_blues",
    name: "Moody Blues",
    old_id: "The Muted Blue One",
    colors: {
        theme: "#A0C5E8",
        accent1: "#6B94BA",
        accent2: "#407096",
        background_light: "#fff",
        background_dark: "#383a40",
        primary_light: "#fff",
        primary_dark: "#1e343b",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "blue_skies",
    name: "Blue Skies",
    old_id: "The Bright Blue One",
    colors: {
        theme: "#64C3FF",
        accent1: "#2EA2D9",
        accent2: "#09789E",
        background_light: "#fff",
        background_dark: "#44464A",
        primary_light: "#fff",
        primary_dark: "#004256",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "purples",
    name: "Purples",
    old_id: "The Purple One ",
    colors: {
        theme: "#9652FD",
        accent1: "#6152FD",
        accent2: "#4339B3",
        background_light: "#fff",
        background_dark: "#3E3354",
        primary_light: "#fff",
        primary_dark: "#44417A",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "minty",
    name: "Minty",
    old_id: "Mint",
    colors: {
        theme: "#00AC8E",
        accent1: "#AADA63",
        accent2: "#AEBEBB",
        background_light: "#fff",
        background_dark: "#1e343b",
        primary_light: "#fff",
        primary_dark: "#1e343b",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "cool_startup",
    name: "Cool Startup",
    old_id: "Cool Startup",
    colors: {
        theme: "#65C7B3",
        accent1: "#476B93",
        accent2: "#B1BBC7",
        background_light: "#fff",
        background_dark: "#174769",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "taxicab",
    name: "Taxicab",
    old_id: "German Ad Agency",
    colors: {
        theme: "#FFCB4F",
        accent1: "#908E89",
        background_light: "#fff",
        background_dark: "#444",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "monochrome",
    name: "Monochrome",
    old_id: "The New York Times",
    colors: {
        theme: "#353535",
        accent1: "#7B7B7B",
        background_light: "#fff",
        background_dark: "#7B7B7B",
        primary_light: "#fff",
        primary_dark: "#353535",
        secondary_light: "#f1f1f1",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "mai_tai",
    name: "Mai Tai",
    old_id: "Mai Tai",
    colors: {
        theme: "#ffae3b",
        // accent1: "#FF748D",
        accent1: "#E75BBC",
        background_light: "#fff",
        background_dark: "#b74a00",
        primary_light: "#fff",
        primary_dark: "#b73854",
        secondary_light: "#f1f1f1",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}, {
    id: "merry",
    name: "Merry",
    old_id: "Merry",
    colors: {
        theme: "#ff5646",
        // accent1: "#FF748D",
        accent1: "#519041",
        background_light: "#fff",
        background_dark: "#595D65",
        primary_light: "#fff",
        primary_dark: "#444",
        secondary_light: "rgba(255,255,255,0.5)",
        secondary_dark: "rgba(68,68,68,0.8)",
        hyperlink: themeColors.ui_blue
    }
}];

export { StyleSheet, ElementStyles, predefinedPalettes, predefinedThemeColors };
