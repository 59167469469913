import type { IPermissionObj } from "./IPermission";

export enum SharedResourceType {
    PRESENTATION = "presentation",
    PRESENTATION_TEMPLATE = "presentationTemplate",
    SLIDE = "slide",
    SLIDE_TEMPLATE = "slideTemplate",
    ASSET = "asset",
    THEME = "theme",
}

export interface ISharedResource {
    id: string;
    type: SharedResourceType;
    permission?: IPermissionObj;
    isDefault?: boolean;
}
