import React, { Component } from "react";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import {
    BeautifulDialog,
    DialogContent
} from "js/react/components/Dialogs/BaseDialog";
import { ToggleAllowCommenting } from "../components/ToggleAllowCommenting";

export default class EditSocialShareDialog extends Component {
    toggleAllowCommenting = event => {
        const { link } = this.props;
        if (link) {
            const allowCommenting = !link.get("allowCommenting");
            link.update({ allowCommenting });
            this.forceUpdate();
        }
    }

    render() {
        const { link } = this.props;

        const name = link?.getLinkName();
        const allowCommenting = link?.get("allowCommenting");

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>Edit {name} Link</DialogTitle>
                <DialogContent>
                    <ToggleAllowCommenting
                        allowCommenting={allowCommenting}
                        onToggle={this.toggleAllowCommenting}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Close</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
