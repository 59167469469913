const applyCommands = require("./applyCommands");
const { parseId } = require("./utils");
const deriveSimpleModel = require("./deriveSimpleModel");
const { getInitialId } = require("./utils");

// initial data is set on the special key (all zeroes) so that it comes before all other updates
const specialInitialID = getInitialId();

const processCommands = (commands = {}, snap) => {
    let slideModel = {};
    let cmds = { ...commands };

    if (snap.id === specialInitialID) {
        delete cmds[specialInitialID];
        slideModel = JSON.parse(snap.model);
    }

    const commandIds = Object.keys(cmds || {});

    if (!commandIds.length) {
        return [{
            state: deriveSimpleModel(slideModel)
        }];
    }

    return commandIds.sort().map(cmdId => {
        const cmd = cmds[cmdId];
        const updates = JSON.parse(cmd.updates);
        slideModel = applyCommands(slideModel, updates);
        let { timestamp } = parseId(cmdId);
        let state = deriveSimpleModel(slideModel);
        return { state, timestamp, updates, cmd };
    });
};

module.exports = { processCommands };
