import React, { Component } from "react";
import moment from "moment";
import { formatter } from "js/core/utilities/formatter";
import linkDS, { LinkType } from "js/react/views/PresentationSettings/dataservices/LinkDataService";
import RecentAnalytics from "./RecentAnalytics";
import AnalyticsLinksList from "./AnalyticsLinksList";
import Tabs from "./Tabs";
import { Icon } from "@material-ui/core";
import AnalyticsController from "js/react/views/Analytics/AnalyticsController";

class AnalyticsTabs extends Component {
    constructor() {
        super();
    }

    render() {
        const analytics = this.props.analytics;

        const {
            parentOnClickHandler,
        } = this.props;

        return (
            <div className="list-panel">
                <Tabs parentOnClickHandler={parentOnClickHandler}>
                    <div label="Recent Views">
                        <RecentAnalytics
                            source_view="Recent Views"
                            noRowsMessage="No Recent Views"
                            allowRowClick
                            dataSource={analytics.views}
                            totalCount={parseInt(analytics.aggregates.totalviews)}
                            fetchMore={(pageOffset, pageSize) => AnalyticsController.fetchMoreRecentViews(pageOffset, pageSize)}
                            columnDefs={[
                                {
                                    name: "When",
                                    cellDef: (row, presentation) => (
                                        <div className="timestamp">{this.renderTimeStamp(row.time)}</div>
                                    )
                                },
                                {
                                    name: "Source",
                                    cellDef: (row, presentation) => (
                                        <div className="source">{this.renderLinkSource(row)}</div>
                                    )
                                },
                                {
                                    name: "Viewed By",
                                    cellDef: (row, presentation) => (
                                        <div className="username">{this.renderUsername(row)}</div>
                                    )
                                },
                                {
                                    name: "Duration",
                                    cellDef: (row, presentation) => (
                                        <div className="duration">
                                            {formatter.formatDuration(row.totaltime / 1000, "seconds", "h [hrs] m [mins] s [secs]")}
                                        </div>
                                    )
                                },
                                {
                                    name: "% of Slides Viewed",
                                    cellDef: (row, presentation) => {
                                        const totalSlides = presentation.getSips().length;
                                        return (
                                            <ProgressBar value={Math.min(1, row.viewedslides / totalSlides)} />
                                        );
                                    }
                                },
                                {
                                    name: "Downloaded",
                                    width: 0,
                                    cellDef: (row, presentation) => (
                                        <div className="downloaded">{this.renderDownloadedCheck(row)}</div>
                                    )
                                },
                            ]}
                        />
                    </div>
                    <div label="Shared Links">
                        <AnalyticsLinksList />
                    </div>
                </Tabs>
            </div>
        );
    }

    renderTimeStamp(time) {
        let t = moment(time);

        if (t.isBefore(moment().subtract(1, "w"))) {
            return t.format("MMM DD YYYY");
        } else {
            return t.fromNow();
        }
    }

    renderLinkSource(row) {
        if (row.linkid) {
            let link = linkDS.getLinkById(this.props.presentation, row.linkid);
            if (link) {
                switch (link.get("type")) {
                    case LinkType.PUBLIC:
                        return `Public Link (${link.getLinkName()})`;
                    case LinkType.PROTECTED:
                        return `Protected Link (${link.getLinkName()})`;
                    case LinkType.INVITE:
                        return `Invite (${link.getLinkName()})`;
                    case LinkType.EMBED:
                        return `Embedded`;
                    case LinkType.SOCIAL:
                        return `Social Link (${link.getLinkName()})`;
                    case LinkType.INTEGRATOR:
                        return `Integration (${link.getLinkName()})`;
                }
            } else {
                return "Deleted Link";
            }
        } else {
            return "Link";
        }
    }

    renderUsername(row) {
        if (row.username == "logged out") {
            return "--";
        } else {
            return row.username;
        }
    }

    renderDownloadedCheck(row) {
        if (row.downloads) {
            return <Icon>check</Icon>;
        } else {
            return <span>--</span>;
        }
    }
}

const ProgressBar = ({ value }) => {
    let styles = {
        width: value * 100 + "%"
    };
    return (
        <div className="progress-bar">
            <div className="bar" style={styles}></div>
            <label>{parseInt(value * 100) + "%"}</label>
        </div>
    );
};

export default AnalyticsController.withState(AnalyticsTabs);
