import { Backbone, numeral, $, _, tinycolor } from "js/vendor";
import { app } from "js/namespaces.js";
import * as geom from "js/core/utilities/geom";
import setCursor from "js/core/utilities/cursor";
import { blendColors, getPadding } from "js/core/utilities/utilities";
import { PaletteColorType } from "../../../common/constants";

export function getValueOrDefault(value, defaultValue) {
    return value !== undefined ? value : defaultValue;
}

export function getCollectionPropertyValue(models, property) {
    const values = [...new Set(models.map(model => model[property] ?? false))];
    if (values.length === 1) {
        return values[0];
    } else {
        return null;
    }
}

Backbone.Model.prototype.defineProperty = function(property, readOnly, defaultValue) {
    var obj = this;

    if (readOnly) {
        Object.defineProperty(obj, property, {
            get: function() {
                return obj.get(property) || defaultValue;
            }
        });
    } else {
        Object.defineProperty(obj, property, {
            get: function() {
                return obj.get(property);
            },
            set: function(value) {
                obj.set(property, value);
            }
        });
    }
};

//extend Numeral library because validate() returns false for negative numbers
if (numeral) {
    numeral.validateEx = function(val, culture) {
        let valString = val.toString();
        if (valString.startsWith("-") || valString.startsWith(".")) {
            if (valString.endsWith("%")) return numeral.validate(valString.substring(1, valString.length - 1), culture);
            else return numeral.validate(valString.substring(1), culture);
        } else
        if (valString.startsWith("(") && valString.endsWith(")")) {
            return numeral.validate(valString.substring(1, valString.length - 1), culture);
        } else
        if (valString.endsWith("%")) {
            return numeral.validate(valString.substring(0, valString.length - 1), culture);
        } else {
            return numeral.validate(valString, culture);
        }
    };
}

//extend tinyColor to override isDark method
tinycolor.prototype.isDark = function(backgroundColor) {
    if (this._a < 1 && backgroundColor) {
        let blendedColor = blendColors(this, backgroundColor);
        return blendedColor.getLuminance() < 0.35;
    } else {
        return this.getLuminance() < 0.35;
    }
};

tinycolor.prototype.isNearWhite = function() {
    return this.getLuminance() > 0.85;
};

tinycolor.prototype.mix = function(color, amount) {
    return tinycolor.mix(this, color, amount);
};

// tinycolor.prototype.toRgbaString =  function() {
//     return "rgba(" +
//         mathRound(this._r) +
//         ", " +
//         mathRound(this._g) +
//         ", " +
//         mathRound(this._b) +
//         ", " +
//         this._roundA +
//         ")";
// };

let deepClone = function(obj) {
    return JSON.parse(JSON.stringify(obj || {}));
};

export function isChildOfNode(node, targetNode) {
    if (node.parentNode == targetNode) {
        return true;
    } else if (node.parentNode == null) {
        return false;
    } else {
        return isChildOfNode(node.parentNode, targetNode);
    }
}

Object.defineProperty(Array.prototype, "findById", {
    value: function(id) {
        return this.find(item => item.id == id);
    },
    enumerable: false
});

Object.defineProperty(Array.prototype, "remove", {
    value: function(item) {
        if (this.indexOf(item) > -1) {
            this.splice(this.indexOf(item), 1);
        }
        return this;
    },
    enumerable: false
});

Object.defineProperty(Array.prototype, "has", {
    value: function(item) {
        return this.indexOf(item) != -1;
    },
    enumerable: false
});

Object.defineProperty(Array.prototype, "contains", {
    value: function(item) {
        return this.indexOf(item) != -1;
    },
    enumerable: false
});

Object.defineProperty(Array.prototype, "insert", {
    value: function(item, index) {
        return this.splice(index, 0, item);
    },
    enumerable: false
});

Object.defineProperty(Array.prototype, "shuffle", {
    value: function() {
        let currentIndex = this.length,
            temporaryValue,
            randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = this[currentIndex];
            this[currentIndex] = this[randomIndex];
            this[randomIndex] = temporaryValue;
        }

        return this;
    },
    enumerable: false
});

Object.defineProperty(Array.prototype, "move", {
    value: function(oldIndex, newIndex) {
        if (newIndex >= this.length) {
            let k = newIndex - this.length;
            while (k-- + 1) {
                this.push(undefined);
            }
        }
        this.splice(newIndex, 0, this.splice(oldIndex, 1)[0]);
        return this;
    },
    enumerable: false
});

Object.defineProperty(Array.prototype, "isFirst", {
    value: function(item) {
        return this.indexOf(item) == 0;
    },
    enumerable: false
});

Object.defineProperty(Array.prototype, "isLast", {
    value: function(item) {
        return this.indexOf(item) == this.length - 1;
    },
    enumerable: false
});

String.prototype.toTitleCase = function() {
    return this.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

String.prototype.has = function(str) {
    return this.indexOf(str) > -1;
};

String.prototype.contains = function(str) {
    return this.indexOf(str) > -1;
};

String.prototype.equalsAnyOf = function(...values) {
    return values.has(this.toString());
};

String.prototype.pluralize = function(plural) {
    return plural ? this + "s" : this;
};

Math.clamp = function(value, min, max) {
    return Math.min(Math.max(value, min), max);
};

Math.degreesToRadians = function(degrees) {
    return degrees * Math.PI / 180;
};

Math.radiansToDegrees = function(radians) {
    return radians * 180 / Math.PI;
};

Math.toLogValue = function(value) {
    // position will be between 0 and 100
    let minp = 0;
    let maxp = 100;

    // The result should be between 100 an 10000000
    let minv = Math.log(1);
    let maxv = Math.log(100);

    // calculate adjustment factor
    let scale = (maxv - minv) / (maxp - minp);

    return (Math.log(value) - minv) / scale + minp;
};

let ANIMATION_DURATION = 300;
let ANIMATION_OPTIONS = {
    duration: ANIMATION_DURATION,
    //easing: "easeInOutSine",
    // easing: "myCustomEasing",
    easing: "linear",
    queue: false
};

let SVG_ANIMATION_OPTIONS = {
    duration: ANIMATION_DURATION,
    ease: "-"
};

//SVG.Element.prototype.setSize = function(width, height) {
//
//};

let baseVelocityFlushTransformCache = $.Velocity.CSS.flushTransformCache;
$.Velocity.CSS.flushTransformCache = function(element) {
    let data = $.data(element, "velocity");
    if (data.transformCache.hasOwnProperty("scale")) {
        let scale = data.transformCache.scale;
        delete data.transformCache.scale;
        data.transformCache.scale = scale;
    }
    baseVelocityFlushTransformCache(element);
};

$.div = function(className, contents) {
    return $("<div/>").addClass(className).html(contents);
};

$.label = function(text) {
    return $("<label/>").text(text);
};

$.section = function(text) {
    return $("<section/>").text(text);
};

$.gap = function(width, height) {
    return $("<div/>").css({ width, height });
};

$.span = function(className, contents) {
    return $("<span/>").addClass(className).html(contents);
};

$.form = function(className) {
    return $("<form/>").addClass(className);
};

$.icon = function(icon, className) {
    if (icon.startsWith("http")) {
        return $.img(icon);
    } else {
        return $("<i class='micon'>" + icon + "</i>").addClass(className);
    }
};

$.img = function(src, className) {
    return $("<img src='" + src + "'/>").addClass(className);
};

$.button = function(label) {
    return $("<button class='button'>" + label + "</button>");
};

$.input = function(type, placeholder) {
    var $input = $("<input type='" + type + "'/>");
    if (placeholder) {
        $input.attr("placeholder", placeholder);
    }
    return $input;
};

$.textarea = function(placeholder) {
    var $textarea = $("<textarea/>");
    if (placeholder) {
        $textarea.attr("placeholder", placeholder);
    }
    return $textarea;
};

$.grid = function(direction = "row", gap = 10) {
    return $(`<div class="grid" style="display: grid; grid-auto-flow: ${direction}; gap: ${gap}px"/>`);
};

$.hr = function() {
    return $("<hr/>");
};

$.table = function(className) {
    return $("<table/>").addClass(className);
};

$.tr = function(className) {
    return $("<tr></tr>").addClass(className);
};

$.th = function(className) {
    return $("<th></th>").addClass(className);
};

$.td = function(className) {
    return $("<td></td>").addClass(className);
};

$.ul = function(className) {
    return $("<ul></ul>").addClass(className);
};

$.li = function(className) {
    return $("<li></li>").addClass(className);
};

$.fn.removeClassWith = function(className) {
    if (this.length) {
        var matches = _.filter(this.attr("class").split(" "), function(clsName) {
            return clsName.startsWith(className);
        });
        this.removeClass(matches.join(" "));
    }
};

$.fn.replaceClass = function(className) {
    this.removeClassWith(className.split("-")[0] + "-");
    this.addClass(className);
};

$.fn.addRow = function(className) {
    return $(this).addEl($.tr(className));
};

$.fn.addCell = function(contents, className) {
    $(this).addEl($.td(className).html(contents));
    return $(this);
};

$.fn.addHeader = function(contents, className) {
    $(this).addEl($.th(className).html(contents));
    return $(this);
};

$.fn.addDiv = function(className) {
    return $(this).addEl($.div(className));
};

$.fn.opacity = function(value) {
    if (value != undefined) {
        $(this).css("opacity", value);
        return this;
    } else {
        return $(this).css("opacity");
    }
};

$.fn.left = function(value) {
    if (value != undefined) {
        $(this).css("left", value + "px");
        return this;
    } else {
        return $(this).position().left;
    }
};

$.fn.right = function(value) {
    if (value != undefined) {
        $(this).css("right", value + "px");
        return this;
    } else {
        return $(this).position().left + $(this).width();
    }
};

$.fn.bottom = function(value) {
    if (value != undefined) {
        $(this).css("bottom", value + "px");
        return this;
    } else {
        return $(this).position().top + $(this).height();
    }
};

$.fn.zIndex = function(value) {
    if (value != undefined) {
        $(this).css("z-index", value);
        return this;
    } else {
        return $(this).css("z-index");
    }
};

$.fn.center = function(x, y) {
    var $el = $(this);

    if (x instanceof geom.Point) {
        y = x.y;
        x = x.x;
    }

    $el.left(x - $el.width() / 2);
    $el.top(y - $el.height() / 2);

    return this;
};

$.fn.padding = function(padding) {
    if (padding) {
        // do nothing
    } else {
        padding = {};

        padding.left = parseInt(this.css("padding-left")) || 0;
        padding.top = parseInt(this.css("padding-top")) || 0;
        padding.bottom = parseInt(this.css("padding-bottom")) || 0;
        padding.right = parseInt(this.css("padding-right")) || 0;

        return padding;
    }
};

$.fn.margins = function(margins) {
    if (margins) {
        // do nothing
    } else {
        margins = {};

        margins.left = parseInt(this.css("margin-left")) || 0;
        margins.top = parseInt(this.css("margin-top")) || 0;
        margins.bottom = parseInt(this.css("margin-bottom")) || 0;
        margins.right = parseInt(this.css("margin-right")) || 0;

        return margins;
    }
};

$.fn.marginLeft = function(value) {
    if (value) {
        $(this).css("margin-left", value + "px");
    } else {
        if ($(this).css("margin-left") == "") {
            return 0;
        } else {
            return parseInt($(this).css("margin-left"));
        }
    }
};

$.fn.marginRight = function(value) {
    if (value) {
        $(this).css("margin-right", value + "px");
    } else {
        if ($(this).css("margin-right") == "") {
            return 0;
        } else {
            return parseInt($(this).css("margin-right"));
        }
    }
};

$.fn.marginTop = function(value) {
    if (value) {
        $(this).css("margin-top", value + "px");
    } else {
        if ($(this).css("margin-top") == "") {
            return 0;
        } else {
            return parseInt($(this).css("margin-top"));
        }
    }
};

$.fn.marginBottom = function(value) {
    if (value) {
        $(this).css("margin-bottom", value + "px");
    } else {
        if ($(this).css("margin-bottom") == "") {
            return 0;
        } else {
            return parseInt($(this).css("margin-bottom"));
        }
    }
};

$.fn.paddingLeft = function(value) {
    if (value) {
        $(this).css("padding-left", value + "px");
    } else {
        if ($(this).css("padding-left") == "") {
            return 0;
        } else {
            return parseInt($(this).css("padding-left"));
        }
    }
};

$.fn.paddingRight = function(value) {
    if (value) {
        $(this).css("padding-right", value + "px");
    } else {
        if ($(this).css("padding-right") == "") {
            return 0;
        } else {
            return parseInt($(this).css("padding-right"));
        }
    }
};

$.fn.paddingTop = function(value) {
    if (value) {
        $(this).css("padding-top", value + "px");
    } else {
        if ($(this).css("padding-top") == "") {
            return 0;
        } else {
            return parseInt($(this).css("padding-top"));
        }
    }
};

$.fn.paddingBottom = function(value) {
    if (value) {
        $(this).css("padding-bottom", value + "px");
    } else {
        if ($(this).css("padding-bottom") == "") {
            return 0;
        } else {
            return parseInt($(this).css("padding-bottom"));
        }
    }
};

$.fn.top = function(value) {
    if (value != undefined) {
        $(this).css("top", value + "px");
        return this;
    } else {
        return $(this).position().top;
    }
};

$.fn.addEl = function($el) {
    $(this).append($el);
    return $el;
};

$.fn.getTransform = function() {
    let transform = this[0].style.transform;
    if (transform == "") {
        return {
            x: 0,
            y: 0,
            scale: 1
        };
    } else {
        return {
            x: /translateX\((.*?)px\)/.test(transform) ? parseFloat(/translateX\((.*?)px\)/.exec(transform)[1]) : 0,
            y: /translateY\((.*?)px\)/.test(transform) ? parseFloat(/translateY\((.*?)px\)/.exec(transform)[1]) : 0,
            scale: /scale\((.*?)\)/.test(transform) ? parseFloat(/scale\((.*?)\)/.exec(transform)[1]) : 1,
        };
    }
};

$.fn.transform = function(params) {
    if (!params) {
        return $(this).getTransform();
    } else {
        let $el = $(this);

        var props = "";
        if (params.translateX != undefined) {
            props += " translateX(" + params.translateX + "px)";
        }
        if (params.translateY != undefined) {
            props += " translateY(" + params.translateY + "px)";
        }

        if (params.x != undefined) {
            props += " translateX(" + params.x + "px)";
        }
        if (params.y != undefined) {
            props += " translateY(" + params.y + "px)";
        }

        if (params.scale != undefined) {
            props += " scale(" + params.scale + ")";
        }
        if (params.scaleX != undefined) {
            props += " scaleX(" + params.scaleX + ")";
        }
        if (params.scaleY != undefined) {
            props += " scaleY(" + params.scaleY + ")";
        }
        if (params.rotate != undefined) {
            props += " rotate(" + params.rotate + "deg)";
        }
        if (params.skewX != undefined) {
            props += " skewX(" + params.skewX + "deg)";
        }
        if (params.skewY != undefined) {
            props += " skewY(" + params.skewY + "deg)";
        }

        $el.css("transform", props);
        //
        // if (params.width != undefined){
        //     $el.width(params.width);
        // }
        // if (params.height != undefined){
        //     $el.height(params.height);
        // }

        return $(this);
    }
};

$.fn.clickShield = function(callback, transparent, zIndex) {
    if (callback) {
        if (this.$shield) {
            if (transparent) {
                if (app) app.clickShieldEnabled = false;
                this.$shield.remove();
            } else {
                this.$shield.velocity("transition.fadeOut", {
                    complete: () => {
                        if (app) app.clickShieldEnabled = false;
                        this.$shield.remove();
                    }
                });
            }
        }
        this.$shield = $.div("click_shield");
        if (app) app.clickShieldEnabled = true;
        if (zIndex != undefined) {
            this.$shield.css("z-index", zIndex);
        }
        this.$shield.toggleClass("transparent", transparent);
        this.$shield.insertBefore($(this));

        if (!transparent) {
            this.$shield.velocity("transition.fadeIn");
        }
        this.$shield.on("mousedown touchend", event => {
            event.stopImmediatePropagation();
            event.preventDefault();
            // $(this).remove();
            if (app) app.clickShieldEnabled = false;
            callback.call(this);
        });
        this.$shield.one("contextmenu", function(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            $(this).remove();
            if (app) app.clickShieldEnabled = false;
            callback.call(this);
        });
    } else {
        if (this.$shield) {
            if (this.$shield.hasClass("transparent")) {
                this.$shield.remove();
                if (app) app.clickShieldEnabled = false;
            } else {
                this.$shield.velocity("transition.fadeOut", {
                    complete: () => {
                        this.$shield.remove();
                        if (app) app.clickShieldEnabled = false;
                    }
                });
            }
        }
    }
};

let uiLock = false;

$.fn.acquireUiLock = function() {
    if (uiLock) {
        return false;
    }
    const $shield = $.div("click_shield transparent").attr("id", "uiLock");
    $("#mainView").addEl($shield);
    uiLock = true;
    return true;
};

$.fn.releaseUiLock = function() {
    uiLock = false;
    const $shield = $("#uiLock");
    $shield.remove();
};

$.fn.spinner = function(show, label) {
    this.each(function() {
        $(this).find("> .spinner").remove();
    });

    if (show) {
        return this.each(function() {
            var size = 20;
            var $container = $(this).addEl($.div("spinner"));
            $container.addEl($('<svg class="circular"><circle class="path" cx="' + size + '" cy="' + size + '" r="' + (size - 5) + '"/></svg>'));
            label && $container.addEl($(`<div class="label">${label}</div>`));
        });
    } else {
        return this;
    }
};

$.fn.tabs = function() {
    var $tabs = $(this);
    var $tabButtons = $tabs.find(".tab");

    $tabButtons.on("click", function() {
        $tabButtons.removeClass("selected");
        $(this).addClass("selected");

        $(".tab_content").removeClass("selected");

        var $tab = $("#" + $(this).data("tab"));
        $tab.addClass("selected");

        $tab.trigger("show");
    });
};

$.fn.hasScroll = function(axis) {
    var overflow = this.css("overflow"),
        overflowAxis;

    if (typeof axis == "undefined" || axis == "y") overflowAxis = this.css("overflow-y");
    else overflowAxis = this.css("overflow-x");

    var bShouldScroll = this.get(0).scrollHeight > this.innerHeight();

    var bAllowedScroll = (overflow == "auto" || overflow == "visible") ||
        (overflowAxis == "auto" || overflowAxis == "visible");

    var bOverrideScroll = overflow == "scroll" || overflowAxis == "scroll";

    return (bShouldScroll && bAllowedScroll) || bOverrideScroll;
};

$.getScrollBarWidth = function() {
    let $outer = $("<div>").css({ visibility: "hidden", width: 100, overflow: "scroll" }).appendTo("body"),
        widthWithScroll = $("<div>").css({ width: "100%" }).appendTo($outer).outerWidth();
    $outer.remove();
    return 100 - widthWithScroll;
};

$.fn.fileDrop = function(config) {
    let $target = $(this);
    if (!config.proxy) {
        $target.addClass("drop-target");
    }
    let droppedFiles = false;

    function getCorrectEvent(e1, e2) {
        // allow native events as 1st arg, or when jquery.trigger is used, pass native event as 2nd arg
        // this is a hacky way of allowing our selectionLayer to trigger events on other editor UI views
        if (e1 && e2) {
            return e2;
        } else if (e1[0] && e1[1]) {
            return e1[1];
        } else {
            return e1;
        }
    }

    // Make sure to eliminate yellow drag target whenever the mouse leaves the bounds of the browser
    // window.
    $(window).off("mouseenter");
    $(window).mouseenter(() => {
        $(window).mouseleave(() => {
            $target && $target.removeClass("is-dragover");
        });
    });

    $target.on("dragover dragenter dragleave drop", function(e) {
        e.preventDefault();
        e.stopPropagation();
    })
        .on("dragover dragenter", function(e1, e2) {
            $target.addClass("is-dragover");
            let e = getCorrectEvent(e1, e2);
            config.over && config.over(e);
        })
        .on("dragleave drop", function(e1, e2) {
            let e = getCorrectEvent(e1, e2);
            if (e.target !== $target[0] && !$target.find(e.target).length) {
                // only remove class if e.target is not $target and is not a child of $target
                $target.removeClass("is-dragover");
            }
            config.leave && config.leave(e);
        })
        .on("drop", function(e1, e2) {
            $target.removeClass("is-dragover");
            let e = getCorrectEvent(e1, e2);
            if (config.proxy) {
                config.drop(e);
            } else {
                droppedFiles = e.originalEvent.dataTransfer.files;
                config.drop([...droppedFiles]);
                if (e.originalEvent.dataTransfer.items) {
                    e.originalEvent.dataTransfer.items.clear();
                } else {
                    e.originalEvent.dataTransfer.clearData();
                }
            }
        });
};

$.fn.makeDraggable = function(config) {
    let $el = $(this);

    $el.on("mousedown.drag", event => {
        event.stopPropagation();
        if (event.button != 0) return;

        if (config.check && !config.check(event)) {
            return;
        }

        config.axis = config.axis || "both";

        let detectAxis = config.axis == "detect";

        const startDragPoint = new geom.Point(event.pageX, event.pageY);

        // calculate the offset from the point where we started the drag action to the position of the dragged element
        const dragOffset = new geom.Point(event.pageX - $el.offset().left, event.pageY - $el.offset().top);

        // get the canvas offset so we can adjust to drag position to be relative to the canvas instead of the page
        const canvasOffset = $("#selection_layer").offset();

        const startOffset = $el.offset();
        const canvasScale = app.currentCanvas.getScale();

        let constraints;
        if (config.constrainDrag) {
            constraints = config.constrainDrag.multiply(canvasScale).offset(canvasOffset);
        } else if (config.constraints) {
            constraints = config.constraints;
        }

        function calcScreenPosition(event) {
            const screenPosition = new geom.Point(
                config.axis == "y" ? startOffset.left : event.pageX - dragOffset.x,
                config.axis == "x" ? startOffset.top : event.pageY - dragOffset.y
            );

            if (constraints) {
                screenPosition.x = Math.clamp(screenPosition.x, constraints.left, constraints.right);
                screenPosition.y = Math.clamp(screenPosition.y, constraints.top, constraints.bottom);
            }

            return screenPosition;
        }

        const startScreenPosition = calcScreenPosition(event);
        const startCanvasPosition = startScreenPosition.minus(canvasOffset.left + dragOffset.x, canvasOffset.top + dragOffset.y).multiply(1 / canvasScale);
        const startElementPosition = config.element ? new geom.Point(config.element.bounds.left, config.element.bounds.top) : startCanvasPosition;

        // function to calculate the drag position relative to the canvas and relative to the parent container element
        const calcDragPosition = event => {
            const screenPosition = calcScreenPosition(event);

            // const canvasDelta = screenPosition.minus(startScreenPosition).multiply(1 / canvasScale);
            // const canvasPosition = startCanvasPosition.plus(canvasDelta);
            const canvasPosition = screenPosition.minus(canvasOffset.left, canvasOffset.top).multiply(1 / canvasScale);

            const elementDelta = screenPosition.minus(startScreenPosition).multiply(1 / canvasScale);
            const elementPosition = startElementPosition.plus(elementDelta);

            // const uiPosition = screenPosition.minus(dragOffset);
            const uiPosition = screenPosition;

            return { screenPosition, canvasPosition, elementPosition, uiPosition, axis: config.axis };
        };

        let _isDragging = false;
        const onStartDrag = event => {
            //check if this meets the drag threshold
            if (startDragPoint.distance(new geom.Point(event.pageX, event.pageY)) < (config.dragDistance || 0)) {
                return;
            }
            if (config.axis == "detect") {
                if (Math.abs(event.pageX - startDragPoint.x) > Math.abs(event.pageY - startDragPoint.y)) {
                    config.axis = "x";
                } else {
                    config.axis = "y";
                }
            }

            _isDragging = true;
            setCursor(config.cursor || "-webkit-grabbing");
            app.isDraggingItem = true; // i guess this is a bit ugly but we need a quick way for canvas and baseelement to know if we are dragging and prevent us from losing rollover focus if the mouse moves too fast
            $el.css("position", "absolute");
            config.start && config.start(event, config.axis);

            document.removeEventListener("mousemove", onStartDrag, false);
            document.addEventListener("mousemove", onDrag, false);
        };

        let mouseMoveHandledAt;
        const onDrag = event => {
            if (app.currentCanvas.layouter.isGenerating) return;

            event.stopPropagation();

            // Making dragging smoother
            window.requestAnimationFrame(timestamp => {
                if (_isDragging) {
                    if (mouseMoveHandledAt === timestamp) {
                        return;
                    }

                    mouseMoveHandledAt = timestamp;

                    const position = calcDragPosition(event);
                    $el.offset({ left: position.uiPosition.x, top: position.uiPosition.y });
                    config.drag && config.drag(event, position);
                }
            });
        };

        const onStopDrag = event => {
            document.removeEventListener("mousemove", onStartDrag, false);
            document.removeEventListener("mousemove", onDrag, false);
            document.removeEventListener("mouseup", onStopDrag, false);

            app.isDraggingItem = false;
            if (_isDragging && config.stop) {
                $el.off("mousedown.drag");
                _isDragging = false;
                config.stop(event, calcDragPosition(event));
            }
            setCursor("pointer");

            if (detectAxis) {
                config.axis = "detect";
            }
        };

        document.addEventListener("mousemove", onStartDrag, false);
        document.addEventListener("mouseup", onStopDrag, false);
    });
};

$.fn.extend({
    getPath: function() {
        var path, node = this;
        while (node.length) {
            var realNode = node[0], name = realNode.localName;
            if (!name) break;
            name = name.toLowerCase();

            let parent = node.parent();
            if (realNode.id) {
                name += "#" + realNode.id;
                path = name + (path ? ">" + path : "");
                break;
            } else if (realNode.className != "") {
                name += "." + realNode.className.split(" ").join(".");
            }

            var sameTagSiblings = parent.children(name);
            if (sameTagSiblings.length > 1) {
                var allSiblings = parent.children();
                var index = allSiblings.index(realNode) + 1;
                // if (index > 1) {
                name += ":nth-child(" + index + ")";
                // }
            }

            path = name + (path ? ">" + path : "");
            node = parent;
        }

        return path;
    }
});

$.fn.extend({

    setBounds: function(bounds, animate, options) {
        return this.each(function() {
            var $el = $(this);

            if (animate) {
                var props = {
                    left: bounds.left,
                    top: bounds.top,
                    width: bounds.width,
                    height: bounds.height
                };

                if ($el.is(".velocity-animating") && this.animateTargetProps) {
                    if (_.isEqual(props, this.animateTargetProps)) {
                        return;
                    }
                }

                if (options && options.scale) {
                    props.scale = options.scale;
                }

                if ($el.position().left != props.left || $el.position().top != props.top || $el.width() != props.width || $el.height() != props.height) {
                    $el.velocity(props, ANIMATION_OPTIONS);

                    this.animateTargetProps = props;
                }
            } else {
                $el.css({
                    width: bounds.width + "px",
                    height: bounds.height + "px",
                    left: bounds.left + "px",
                    top: bounds.top + "px"
                });

                if (options && options.scale) {
                    $el.css({
                        "transform": "scale(" + options.scale + ")",
                        "transform-origin": "0 0"
                    });
                }
            }
        });
    },

    getBounds: function() {
        if (this[0] instanceof SVGElement) {
            return geom.Rect.FromBoundingClientRect(this[0].getBoundingClientRect());
        } else {
            return new geom.Rect(this.offset().left, this.offset().top, this.outerWidth(false), this.outerHeight(false));
        }
    },

    getLocalBounds: function() {
        var scale = 1;

        var canvas = this.closest(".slide_canvas");
        if (canvas.length) {
            scale = canvas.getTransform().scale;
        }
        return new geom.Rect(this.position().left / scale, this.position().top / scale, this.outerWidth(false), this.outerHeight(false));
    },

    getInteriorBounds: function() {
        return new geom.Rect(0, 0, this.width(), this.height()).deflate(getPadding(this));
    },

    move: function(x, y, animate) {
        if (x instanceof geom.Point) {
            y = x.y;
            x = x.x;
        }
        return this.each(function() {
            if (animate) {
                $(this).velocity({
                    left: x,
                    top: y
                }, ANIMATION_OPTIONS);
            } else {
                $(this).css("left", x + "px").css("top", y + "px");
            }
        });
    }

});

$.extend({
    getChar: function(e) {
        /*** Convert to Char Code ***/
        var code = e.which;

        //Ignore Shift Key events & arrows
        var ignoredCodes = {
            16: true,
            37: true,
            38: true,
            39: true,
            40: true,
            20: true,
            17: true,
            18: true,
            91: true
        };

        if (ignoredCodes[code] === true) {
            return false;
        }

        //These are special cases that don't fit the ASCII mapping
        var exceptions = {
            186: 59, // ;
            187: 61, // =
            188: 44, // ,
            189: 45, // -
            190: 46, // .
            191: 47, // /
            192: 96, // `
            219: 91, // [
            220: 92, // \
            221: 93, // ]
            222: 39, // '
            //numeric keypad
            96: "0".codePointAt(0),
            97: "1".codePointAt(0),
            98: "2".codePointAt(0),
            99: "3".codePointAt(0),
            100: "4".codePointAt(0),
            101: "5".codePointAt(0),
            102: "6".codePointAt(0),
            103: "7".codePointAt(0),
            104: "8".codePointAt(0),
            105: "9".codePointAt(0)
        };

        if (exceptions[code] !== undefined) {
            code = exceptions[code];
        }

        var ch = String.fromCodePoint(code);

        /*** Handle Shift ***/
        if (e.shiftKey) {
            var special = {
                1: "!",
                2: "@",
                3: "#",
                4: "$",
                5: "%",
                6: "^",
                7: "&",
                8: "*",
                9: "(",
                0: ")",
                ",": "<",
                ".": ">",
                "/": "?",
                ";": ":",
                "'": '"',
                "[": "{",
                "]": "}",
                "\\": "|",
                "`": "~",
                "-": "_",
                "=": "+"
            };

            if (special[ch] !== undefined) {
                ch = special[ch];
            }
        } else {
            ch = ch.toLowerCase();
        }

        return ch.codePointAt(0);
    }
});

_.mixin({
    memoizeThrottle: function(func, wait = 0, options = {}) {
        var mem = _.memoize(function() {
            return _.throttle(func, wait, options);
        }, options.resolver);
        return function() {
            return mem.apply(this, arguments).apply(this, arguments);
        };
    },
    median: function(array) {
        let tmpArray = array.sort();
        if (tmpArray.length % 2 === 0) {
            return (tmpArray[tmpArray.length / 2] + tmpArray[(tmpArray.length / 2) - 1]) / 2;
        } else {
            return tmpArray[(tmpArray.length - 1) / 2];
        }
    },

    traverse: function(obj, action, config, depth = 0) {
        // some objects have cyclical references that don't seem
        // to be detectable (maybe Firebase objects getters that generate
        // brand new objects?) - Adding an option to limit how deep
        // an object can be traversed as an alternative
        if (!isNaN(config?.maxDepth) && depth > config.maxDepth) {
            return;
        }

        // check for keys that should be skipped
        const ignoreKeys = config?.ignoreKeys || [];

        // loop through key/index/value
        _.forEach(obj, (value, key) => {
            // check if skipping
            if (ignoreKeys.includes(key)) {
                return;
            }

            // perform the work
            action(value, key, obj);

            // is a collection
            if (_.isArray(value) || _.isObject(value)) {
                _.traverse(value, action, config, depth + 1);
            }
        });
    }
});

if (typeof Object.assign != "function") {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
        value: function assign(target, varArgs) { // .length of function is 2
            "use strict";
            if (target == null) { // TypeError if undefined or null
                throw new TypeError("Cannot convert undefined or null to object");
            }

            var to = Object(target);

            for (var index = 1; index < arguments.length; index++) {
                var nextSource = arguments[index];

                if (nextSource != null) { // Skip over if undefined or null
                    for (var nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        },
        writable: true,
        configurable: true
    });
}

export { deepClone, ANIMATION_DURATION, ANIMATION_OPTIONS, SVG_ANIMATION_OPTIONS };
