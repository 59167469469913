import React, { Component } from "react";

export default class BackgroundClick extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleDocumentMouseDown, { capture: true });
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleDocumentMouseDown);
    }

    handleDocumentMouseDown = event => {
        if (this.ref.current?.contains(event.target)) {
            return;
        }
        this.props.onBackgroundClick();
    }

    render() {
        return (
            <div ref={this.ref}>
                {this.props.children}
            </div>
        );
    }
}
