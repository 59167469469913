import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, DialogActions, DialogTitle, Chip } from "@material-ui/core";
import TagsInput from "react-tagsinput";
import classNames from "classnames";
import { toLower, trim, uniqBy, debounce } from "lodash";

import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import { Gap20 } from "js/react/components/Gap";
import Icon from "js/react/components/Icon";
import { caseInsensitiveSort } from "js/core/utilities/utilities";
import { TAG_DELIMITERS } from "js/react/constants";

const MAX_TAGS = 50;
// shortcut
const normalizeTag = tag => toLower(trim(tag));

class AddTagsDialog extends Component {
    inputRef = React.createRef();

    constructor(props) {
        super(props);

        const {
            activeTags = [],
            existingTags = [],
        } = props;

        this.state = {
            value: "",
            newTags: activeTags.map(normalizeTag),
            existingTags: existingTags.map(normalizeTag),
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.inputRef && this.inputRef.current.focus();
        }, 500);
    }

    getAvailableTags() {
        let { existingTags, newTags, filter = "" } = this.state;
        const { length: searchLength } = filter;

        const pendingTags = newTags.map(normalizeTag);
        const available = [];
        const disabled = [];

        // filter out some tags
        filter = toLower(filter);
        existingTags = existingTags.forEach(tag => {
            const find = normalizeTag(tag);
            const matchesFilter = find.substr(0, searchLength) === filter;
            const alreadyIncluded = pendingTags.includes(find);
            const target = matchesFilter && !alreadyIncluded ? available : disabled;
            target.push(tag);
        });

        // lastly, sort the tags
        caseInsensitiveSort(available, normalizeTag);
        caseInsensitiveSort(disabled, normalizeTag);

        // give back the tags to work with
        return { available, disabled };
    }

    updateTags = debounce(tags => {
        const { existingTags } = this.state;

        // replace any input tags with matching values from
        let newTags = tags.map(tag => {
            const find = normalizeTag(tag);
            return existingTags.find(tag => normalizeTag(tag) === find) || tag;
        });

        // get only unique tags
        newTags = uniqBy([...this.state.newTags, ...newTags], normalizeTag);

        this.setState({
            newTags,
            filter: "",
        });
    }, 100)

    handleRemoveTag = tagIndex => {
        const newTags = [...this.state.newTags];
        newTags.splice(tagIndex, 1);
        this.setState({ newTags });
    }

    handleChangeTags = tags => {
        // Replace banned characters with dashes
        tags = tags.map(x => x
            .replaceAll(".", "-")
            .replaceAll("#", "-")
            .replaceAll("$", "-")
            .replaceAll("/", "-")
            .replaceAll("[", "-")
            .replaceAll("]", "-")
        );
        this.updateTags(tags);
    };

    handleExistingTagClick = tag => {
        this.updateTags([...this.state.newTags, tag]);

        // refocus after a click
        const { current: tagsInput } = this.inputRef;
        tagsInput.input.focus();
        tagsInput.input.select();
    }

    handleFilterTags = event => {
        const { value: filter } = event.target;
        this.setState({ filter });
    }

    renderInput = ({ addTag, ...props }) => {
        const { classes } = this.props;
        //explicitly differentiating className from other so we can overwrite the default class from react-tagsinput
        const { className, ...other } = props;
        return (
            <input
                className={classes.input}
                style={{ flexGrow: 1 }}
                type="text"
                {...other}
                onKeyUp={this.handleFilterTags}
            />
        );
    }

    render() {
        const { acceptCallback, classes } = this.props;
        const { newTags } = this.state;

        const tags = this.getAvailableTags();
        const hasTags = !!(tags.available.length || tags.disabled.length);

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog} >
                <DialogTitle>Add tags</DialogTitle>
                <DialogContent>
                    <TagsInput
                        addKeys={TAG_DELIMITERS}
                        className={classes.tagsInput}
                        addOnBlur
                        addOnPaste
                        onlyUnique
                        maxTags={MAX_TAGS}
                        value={newTags}
                        ref={this.inputRef}
                        pasteSplit={this.pasteSplit}
                        onChange={this.handleChangeTags}
                        renderTag={props => <StyledTag {...props} keyProp={props.key} onRemove={this.handleRemoveTag} />}
                        renderInput={this.renderInput}
                        renderLayout={(tagComponents, inputComponent) => {
                            return (
                                <div className={classes.tagLayout}>
                                    {tagComponents}
                                    {inputComponent}
                                </div>
                            );
                        }}
                    />
                    <Gap20 />
                    {hasTags ? (
                        <StyledTagsList
                            availableTags={tags.available}
                            disabledTags={tags.disabled}
                            handleClick={this.handleExistingTagClick}
                        />)
                        : null}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            this.props.closeDialog();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            acceptCallback && acceptCallback(newTags);
                            this.props.closeDialog();
                        }}
                    >
                        Add tags
                    </Button>
                </DialogActions>
            </BeautifulDialog>);
    }
}

const StyleAddTagsDialog = withStyles({
    tagsInput: {
        flexGrow: 2,
        padding: 6,
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        overflow: "hidden",
        fontSize: 12,
        height: "100%"
    },
    tagLayout: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: -10
    },
    input: {
        marginBottom: 10,
        background: "transparent",
        border: 0,
        color: "rgb(34, 34, 34)",
        fontFamily: "Source Sans Pro",
        fontWeight: 500,
        outline: "none",
        padding: 5,
        fontSize: "14px",
        width: 100
    }
})(AddTagsDialog);

function TagsList({ tags = [], availableTags = [], disabledTags = [], classes, handleClick }) {
    const activeTags = [...tags, ...availableTags];

    return (
        <div>
            {[activeTags, disabledTags].map((items, index) => {
                const disabled = index === 1;

                return items.map((tag, i) => {
                    return (<Chip
                        key={i}
                        color="default"
                        label={tag}
                        clickable
                        disabled={disabled}
                        onClick={handleClick.bind(this, tag)}
                        classes={{ root: disabled ? classes.tagsRootDisabled : classes.tagsRoot }}
                    />);
                });
            })}
        </div>
    );
}

const tagsRoot = {
    borderRadius: "4px",
    background: "white",
    color: "black",
    margin: "0 10px 10px 0",
    border: "black solid 1px"
};

const StyledTagsList = withStyles({
    tagsRoot,
    tagsRootDisabled: {
        ...tagsRoot,
        color: "lightslategray",
        border: "lightslategray dotted 1px"
    }
})(TagsList);

const Tag = props => {
    const { tag, keyProp, onRemove, getTagDisplayValue, classes } = props;

    return (
        <Chip
            key={keyProp}
            color="primary"
            className={classNames(classes.tag)}
            label={getTagDisplayValue(tag)}
            onDelete={() => onRemove(keyProp)}
            deleteIcon={<Icon small light iconName="close" />}
        />
    );
};

const StyledTag = withStyles({
    tag: {
        height: 28,
        marginRight: 10,
        marginBottom: 10,
        color: "white",
        fontWeight: 600,
        paddingRight: 8
    }
})(Tag);

export default StyleAddTagsDialog;
