import React, { useRef, useState } from "react";
import styled from "styled-components";

import {
    Replay as ReplayIcon,
    ArrowDropDown as ArrowDropDownIcon,
    Person as PersonIcon,
    ExitToAppOutlined as ExitToAppOutlinedIcon,
} from "@material-ui/icons";

import { getStaticUrl } from "js/config";

import { stopPropagation } from "../helpers/stopPropagation";

import SignupButton from "./SignupButton";
import { SocialPopupButtons } from "./SocialPopup";
import { GridBox } from "js/react/components/LayoutGrid";
import { Gap10, Gap20 } from "js/react/components/Gap";
import PresentationActionsMenu from "./PresentationActionsMenu";

const Container = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #222;
  z-index: 100;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  //gap: 70px;
  cursor: default;
`;

const TopFrame = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-height: 500px) {
    transform: scale(0.7);
  }
  @media (max-height: 370px) {
    transform: scale(0.6);
    height: 100px;
  }
`;

const BrandingContainer = styled.div`
  background: #333;
  width: 100%;
  padding: 40px 0px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    color: #999;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.8em;
  }

  > a {
    cursor: pointer;
  }

  img {
    width: 240px;
  }

  @media (max-height: 625px) {
    flex-direction: row;
    padding: 20px 0px;
    img {
      width: 180px;
      margin-right: 10px;
    }
  }
`;

const Label = styled.div`
  font-size: 15px;
  text-transform: uppercase;
  color: #bbb;
  letter-spacing: 0.1em;
  white-space: normal;
  text-wrap: balance;
  text-align: center;
  margin: 5px;
`;

const CreatorInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  line-height: 15px;

  //avatar
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 5px;
  }

  // person icon
  svg {
    padding: 4px;
    background: white;
    border-radius: 50%;
    margin-right: 10px;
  }

  .divider {
    width: 1px;
    height: 25px;
    background: #888;
    margin: 0px 15px;
  }

  // Switch to horizontal instead of vertical
  @media (max-width: 600px) {
    .divider {
      width: 100%;
      height: 1px;
      margin: 5px 0;
    }
  }
`;

const VerticalButtonContainer = styled.div.attrs(({ style }) => ({ style }))`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  text-transform: uppercase;


  span {
    font-size: 24px;
    color: white;
    margin-top: 10px;
    letter-spacing: 0.1em;
  }

  svg {
    background: #333;
    border-radius: 50%;
    padding: 20px;
    font-size: ${({ large }) => large ? 60 : 18}px;
  }

  &:hover {
    color: #11a9e2;
  }
`;

function VerticalButton({ onClick, Icon, text, large, style = {} }) {
    return (
        <VerticalButtonContainer
            onClick={event => stopPropagation(event, onClick)}
            large={large}
            style={style}
        >
            <Icon />
            <span>{text}</span>
        </VerticalButtonContainer>
    );
}

const SocialPopupButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 30px;
  grid-auto-columns: min-content;
  align-items: center;
  justify-content: center;
  margin-top: 0px;

  > div {
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 10px;
  }
`;

const ButtonWithLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    font-size: 12px;
    text-transform: uppercase;
    color: #e1e1e1;
    white-space: nowrap;
    margin-left: 10px;
    user-select: none;
  }

  svg {
    color: #ccc;
    cursor: pointer;

    &:hover {
      color: #11a9e2;
    }
  }
`;

function EndPage({
    onReplayButtonClick,
    onExit,
    showBranding,
    isLoggedIn,
    creator,
    allowSocialSharing,
    onDownloadPdf,
    onCopyDeck,
    allowCopyLink,
    onEditPresentation,
    onSwitchPresentation,
    username,
    showAuthor,
    showTitle,
    showThumbnailsPane,
    presentationName,
}) {
    const [isActionsMenuOpen, setActionsMenuOpen] = useState(false);
    const moreButtonRef = useRef();

    return (
        <Container onClick={event => stopPropagation(event)}>
            <TopFrame>
                <GridBox rows gap={30}>
                    <GridBox columns gap={50} center>
                        <VerticalButton
                            onClick={onReplayButtonClick}
                            Icon={ReplayIcon}
                            text="Replay"
                            large
                        />
                        {onExit && <VerticalButton
                            onClick={onExit}
                            Icon={ExitToAppOutlinedIcon}
                            text="Exit Player"
                            large
                        />}
                    </GridBox>
                    <CreatorInfo>
                        {showAuthor &&
                            <>
                                {creator?.photoURL && <img src={creator?.photoURL}></img>}
                                {!creator?.photoURL && <PersonIcon />}
                                <Label>{creator?.displayName}</Label>
                            </>
                        }
                        {showAuthor && showTitle &&
                            <div className="divider" />
                        }
                        {showTitle &&
                            <Label>{presentationName}</Label>
                        }
                    </CreatorInfo>
                    {allowSocialSharing &&
                        <SocialPopupButtonsContainer className="social-popup-buttons">
                            <SocialPopupButtons largeButtons />
                        </SocialPopupButtonsContainer>
                    }

                    <ButtonWithLabel
                        ref={moreButtonRef}
                        onClick={() => setActionsMenuOpen(true)}
                    >
                        <label>Options</label>
                        <ArrowDropDownIcon />
                    </ButtonWithLabel>

                    <PresentationActionsMenu
                        open={isActionsMenuOpen}
                        anchorEl={moreButtonRef.current}
                        onClose={() => setActionsMenuOpen(false)}
                        onDownloadPdf={onDownloadPdf}
                        onCopyDeck={onCopyDeck}
                        allowCopyLink={allowCopyLink}
                        onEditPresentation={onEditPresentation}
                        onSwitchPresentation={onSwitchPresentation}
                        showThumbnailsPane={showThumbnailsPane}
                        username={username}
                    />
                </GridBox>
            </TopFrame>
            {showBranding &&
                <BrandingContainer>
                    <span>made with</span>
                    <Gap10 />
                    <a href="/?">
                        <img src={getStaticUrl("/images/beautifulai-logos/beautifulai-logo-reverse.svg")}></img>
                    </a>
                    <Gap20 />
                    {!isLoggedIn &&
                        <SignupButton />
                    }
                </BrandingContainer>
            }
        </Container>
    );
}

export default EndPage;
