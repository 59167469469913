import React, { Component } from "reactn";
import { Button } from "@material-ui/core";
import { ds } from "js/core/models/dataService";
import getLogger from "js/core/logger";
import {
    MultiSelectThumbnailGrid,
    Thumbnail,
    ThumbnailContainer
} from "js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import { FlexBox } from "js/react/components/LayoutGrid";
import styled from "styled-components";
import { themeColors } from "js/react/sharedStyles";
import { BlueButton, UIPane } from "js/react/components/UiComponents";
import Spinner from "js/react/components/Spinner";
import {
    SearchBarContainer,
    SearchBarInnerContainer,
    UIPaneResultsContainer, InnerWhiteFrameContainer,
    SlideSearchInput
} from "./Components/SearchBox";
import { FilterDropDown } from "./Components/FilterDropDown";
import { renderReactDialog } from "js/react/renderReactRoot";
import { PresentationLibraryDialog } from "./PresentationLibraryDialog";
import { _ } from "js/vendor";
import { SearchThrottleWait } from "common/constants";
import { FlexSpacer, Gap10, Gap20 } from "js/react/components/Gap";
import { userSlidesDataService } from "js/react/views/AddSlide/DataServices";
import { NoMatchNotice, Notice } from "js/react/components/Notice";
import { getPresentation } from "js/core/models/presentation";

const logger = getLogger();

const CenterAlignContainer = styled.div`
  height: calc(100% - 80px);
  display: flex;
  align-items: center;
`;

const CopySlideMessage = styled.div`
  margin: 0 auto;
  border: 1px solid ${themeColors.ui_blue};
  background: white;
  max-width: 550px;
  text-align: center;
  padding: 40px;
  
  h2 { 
    font-size: 22px;
    margin-bottom: 0;
  }
  h4 {
    font-size: 16px;
    font-weight: lighter;
    margin-top: 5px;
  }
`;

const AddSlideButton = styled(Button)`
  background: #2EB4E5 !important;
  color: white !important;
  min-width: 150px !important;
  margin-bottom: 15px !important;
  padding: 10px 20px !important;

  &:disabled {
    background: white !important;
    color: #A9A9A9 !important;
  }
  
`;

const AddSlideContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  background: ${themeColors.lightGray};
  padding-top: 20px;
  //this is to cover any thumbnail spinners behind the container
  z-index: 1000;
`;

const SearchPreviewTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #111;
`;

const filterOptions = [
    {
        id: "all_content",
        name: "All Content"
    },
    {
        id: "presentation_name",
        name: "Presentation Title"
    },
    {
        id: "text",
        name: "Slide Content"
    },
    {
        id: "template",
        name: "Template"
    },
];

export class CopySlidePane extends Component {
    state = {
        selectedPresentation: null,
        query: this.global.searchSlidesQuery ?? "",
        selectedContent: "all_content",
        sort: null,
        isLoaded: true,
        mainSearch: [],
        secondarySearch: [],
        searchResults: []
    }

    constructor(props) {
        super(props);
        this.fetchSearchResultsThrottled = _.throttle(this.fetchSearchResults, SearchThrottleWait, { leading: false });
    }

    componentDidMount() {
        const { query } = this.state;
        this.handleSearch(query);
    }

    handlePresentationSelected = async presentationId => {
        const { query } = this.state;

        const selectedPresentation = await getPresentation(presentationId, { permission: "read", autoSync: false });

        this.setState({ selectedPresentation }, () => this.handleSearch(query));
    }

    handleBrowsePresentations = () => {
        this.handleClearSelectedPresentation();
        renderReactDialog(PresentationLibraryDialog, {
            onSelectedPresentation: this.handlePresentationSelected,
            maxWidth: true
        });
    }

    fetchSearchResults = async query => {
        const { organizationId } = this.props;
        const { selectedPresentation, selectedContent } = this.state;

        let fullTextSearchByFields;
        if (selectedContent === "all_content") {
            // Include all other options
            fullTextSearchByFields = filterOptions
                .map(({ id }) => id)
                .filter(id => id !== "all_content");
        } else {
            fullTextSearchByFields = [selectedContent];
        }

        const searchResults = await userSlidesDataService.search({
            fullTextSearchQuery: query,
            presentationId: selectedPresentation?.id,
            organizationId,
            fullTextSearchByFields,
        });
        this.setState({
            searchResults,
            isLoaded: true
        });
    }

    handleSearch = query => {
        const { selectedPresentation } = this.state;

        this.setState({ query });
        if (query.length > 0 || selectedPresentation) {
            this.setState({ isLoaded: false });
            this.fetchSearchResultsThrottled(query);
        }
    }

    handleSelectedSlides = (selectedSlides, key) => {
        this.setState({ [key]: selectedSlides });
    }

    handleAddSlides = () => {
        const { mainSearch, secondarySearch } = this.state;
        this.props.onSelected([...mainSearch, ...secondarySearch]);
    }

    handleFilterChange = async event => {
        const { query } = this.state;
        const selectedContent = event.target.value;
        this.setState(
            {
                selectedContent,
                isLoaded: false,
            },
            () => {
                this.fetchSearchResults(query);
            });
    }

    handleClearSelectedPresentation = () => {
        this.setState({ selectedPresentation: null, mainSearch: [], secondarySearch: [] });
    }

    renderSlides = () => {
        const {
            forTeamSlide,
        } = this.props;
        const {
            selectedPresentation,
            query,
            searchResults,
            sort,
            isLoaded,
        } = this.state;

        const allowMultiSelect = !forTeamSlide;

        if (!isLoaded) {
            return <Spinner />;
        } else if (selectedPresentation) {
            return (
                <CopySlideFromPresentationPane
                    presentation={selectedPresentation}
                    onSelected={this.props.onSelected}
                    onAddSlides={this.handleAddSlides}
                    onSelectedSlides={results => this.handleSelectedSlides(results, "mainSearch")}
                    ds={ds}
                    query={query}
                    sort={sort}
                    searchResults={searchResults}
                    forTeamSlide={forTeamSlide}
                />
            );
        } else if (query.length && searchResults.length) {
            const searchMatches = searchResults.filter(result => result.score > 1);
            const similarResults = searchResults.filter(result => result.score <= 1);
            const title = searchMatches.length > 0
                ? "Similar Results"
                : `We couldn't find an exact match for "${query}", but these match similar terms.`;
            return (<>
                {searchMatches.length > 0 &&
                    <InnerWhiteFrameContainer>
                        <MultiSelectThumbnailGrid
                            columns={4}
                            colGap={30}
                            items={searchMatches}
                            allowDragging={false}
                            allowMultiSelect={allowMultiSelect}
                            thumbnailClass={CopySlideThumbnail}
                            onItemSelected={results => this.handleSelectedSlides(results, "mainSearch")}
                            onDoubleClick={results => this.handleSelectedSlides([results], "mainSearch")}
                        />
                    </InnerWhiteFrameContainer>
                }
                {similarResults.length > 0 &&
                    <InnerWhiteFrameContainer>
                        <SearchPreviewTitle>{title}</SearchPreviewTitle>
                        <MultiSelectThumbnailGrid
                            columns={4}
                            colGap={30}
                            items={similarResults}
                            allowDragging={false}
                            allowMultiSelect={allowMultiSelect}
                            thumbnailClass={CopySlideThumbnail}
                            onItemSelected={results => this.handleSelectedSlides(results, "secondarySearch")}
                            onDoubleClick={results => this.handleSelectedSlides([results], "secondarySearch")}
                        />
                    </InnerWhiteFrameContainer>
                }
            </>);
        } else if (query.length && !searchResults.length) {
            return (
                <NoMatchNotice />
            );
        } else {
            return (
                <Notice
                    title="Copy slides from your existing presentations."
                    message={
                        <div>Search for the slide you need or <a onClick={this.handleBrowsePresentations}>browse</a> your presentations.</div>
                    }
                />
            );
        }
    }

    render() {
        const {
            selectedPresentation,
            query,
            selectedContent,
            mainSearch,
            secondarySearch,
        } = this.state;
        const chip = selectedPresentation ? selectedPresentation.get("name").toUpperCase() : null;
        const isAddSlideButtonDisabled = (mainSearch.length < 1 && secondarySearch.length < 1) || (!query.length && !selectedPresentation);

        return (
            <UIPane>
                <SearchBarContainer>
                    <BlueButton onClick={this.handleBrowsePresentations} style={{ height: "100%" }}>
                        Browse Presentations...
                    </BlueButton>
                    <Gap20 />
                    <SearchBarInnerContainer>
                        <SlideSearchInput
                            query={this.global.searchSlidesQuery || query}
                            onSearch={this.handleSearch}
                            placeholder={selectedPresentation ? "Search..." : "Search my presentations for slides..."}
                            chip={chip}
                            onRemoveChip={this.handleClearSelectedPresentation}
                        />
                        <FilterDropDown
                            onChange={this.handleFilterChange}
                            selectedFilter={selectedContent}
                            filterOptions={filterOptions}
                        />
                    </SearchBarInnerContainer>
                </SearchBarContainer>

                <UIPaneResultsContainer>
                    {this.renderSlides()}
                </UIPaneResultsContainer>

                <AddSlideContainer>
                    <AddSlideButton
                        disabled={isAddSlideButtonDisabled}
                        onClick={this.handleAddSlides}
                    >
                        Copy Selected Slides
                    </AddSlideButton>
                </AddSlideContainer>
            </UIPane>
        );
    }
}

class CopySlideFromPresentationPane extends Component {
    render() {
        let {
            onAddSlides,
            onSelectedSlides,
            query,
            searchResults,
            forTeamSlide,
        } = this.props;

        const allowMultiSelect = !forTeamSlide;

        if (query.length && !searchResults.length) {
            return (
                <NoMatchNotice />
            );
        } else {
            return (
                <InnerWhiteFrameContainer>
                    <MultiSelectThumbnailGrid
                        columns={4}
                        colGap={30}
                        items={searchResults}
                        allowDragging={false}
                        allowMultiSelect={allowMultiSelect}
                        thumbnailClass={CopySlideThumbnail}
                        onItemSelected={onSelectedSlides}
                        onDoubleClick={onAddSlides}
                        showThemeThumbnails={true}
                    />
                </InnerWhiteFrameContainer>
            );
        }
    }
}

export class CopySlideThumbnail extends Component {
    constructor() {
        super();

        this.state = {
            url: "",
            isVisible: false
        };

        this.ref = React.createRef();
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting === true) {
                this.observer.disconnect();
                this.setState({ isVisible: true });
            }
        });

        this.observer.observe(this.ref.current);
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { item: { getThumbnailUrl }, showThemeThumbnails, id } = this.props;
        const { isVisible } = this.state;

        if (prevState.isVisible !== isVisible && isVisible) {
            getThumbnailUrl(showThemeThumbnails)
                .then(url => this.setState({ url }))
                .catch(err => logger.error(err, "[CopySlidePane] this.loadThumbnail() failed", { id }));
        }
    }

    render() {
        let { item, onMouseDown, onDoubleClick, selected, onClick } = this.props;
        let { url } = this.state;

        const index = item.index + 1;
        const slideIndexText = isNaN(index) ? "" : `Slide ${index}`;

        return (
            <ThumbnailContainer data-item-id={item.id} ref={this.ref}>
                <Thumbnail
                    onClick={onClick}
                    onMouseDown={onMouseDown}
                    onDoubleClick={onDoubleClick}
                    url={url}
                    selected={selected}
                    showSpinner={true}
                    style={{ border: "solid 1px #ccc", boxShadow: "0px 0px 20px rgba(0,0,0,0.05)" }} />
                <Gap10 />
                <FlexBox fillWidth>
                    <div>{item.presentationName}</div>
                    <FlexSpacer />
                    <div>{slideIndexText}</div>
                </FlexBox>
            </ThumbnailContainer>
        );
    }
}
