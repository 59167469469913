import React from "react";

import { withFirebaseAuth } from "./FirebaseAuthContext";
import getLogger, { LogGroup } from "js/core/logger";
import { auth } from "js/firebase/auth";

const logger = getLogger(LogGroup.AUTH);

const FirebaseUserContext = React.createContext();
export const FirebaseUserConsumer = FirebaseUserContext.Consumer;

/**
 * Context Provider for `auth().currentUser`, available only after Firebase
 * has initalized, so you'll usually want to nest this inside <FirebaseAuthInitalizer>.
 */
class InternalFirebaseUserProvider extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.auth) {
            throw new Error("Cannot use FirebaseUserProvider before FirebaseAuthProvider has initalized");
        }
    }

    componentDidMount() {
        this.unsubscribe = this.props.auth.onAuthStateChanged(() => {
            logger.info("FirebaseUserContext changed");
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    render() {
        const { children, auth } = this.props;
        return (
            <FirebaseUserContext.Provider value={auth.currentUser}>
                {children}
            </FirebaseUserContext.Provider>
        );
    }
}

export const FirebaseUserProvider = withFirebaseAuth(InternalFirebaseUserProvider);

/**
 * Workaround for ad-hoc ReactDOM.render trees within Backbone views,
 * which are unable to access the context tree. You *must* ensure that
 * there's an initalized FirebaseAuthProvider somewhere above this.
 */
export function UNSAFE_FirebaseUserProvider({ children }) {
    return (
        <FirebaseUserContext.Provider value={auth().currentUser}>
            {children}
        </FirebaseUserContext.Provider>
    );
}

/**
 * HOC that injects Firebase UserRecord as `firebaseUser` prop.
 */
export function withFirebaseUser(Component) {
    // eslint-disable-next-line react/display-name
    return React.forwardRef((props, ref) => (
        <FirebaseUserContext.Consumer>
            {currentUser => {
                if (currentUser === undefined) {
                    throw new Error("WithFirebaseUser can't be rendered before FirebaseAuthProvider has initalized");
                }
                return <Component {...props} ref={ref} firebaseUser={currentUser} />;
            }}
        </FirebaseUserContext.Consumer>
    ));
}
