import { LogGroup, LogLevel, LoggerTransportEntryData, LogEntryContext } from "js/core/logger/types";
import LoggerTransport from "js/core/logger/transports/LoggerTransport";

class ConsoleTransport extends LoggerTransport {
    private static _consoleMethodMap = {
        /* eslint-disable no-console */
        [LogLevel.INFO]: console.log,
        [LogLevel.WARNING]: console.warn,
        [LogLevel.ERROR]: console.error,
        /* eslint-disable no-console */
    }

    public log(context: LogEntryContext, group: LogGroup, level: LogLevel, message: string, data: LoggerTransportEntryData, error?: Error) {
        const consoleArgs: any[] = [
            `[${group}] ${message}`
        ];

        if (Array.isArray(data)) {
            consoleArgs.push(...data);
        } else {
            consoleArgs.push(data);
        }

        consoleArgs.push(error);

        ConsoleTransport._consoleMethodMap[level](...consoleArgs.filter(Boolean));
    }
}

export default ConsoleTransport;
