import React, { Component } from "react";
import styled from "styled-components";

import { toLower, trim, uniqBy } from "lodash";
import {
    DialogTitle,
    DialogActions,
    Button
} from "@material-ui/core";

import { AssetType } from "common/constants";
import AppController from "js/core/AppController";
import { FlexBox } from "js/react/components/LayoutGrid";
import { Gap10, Gap20 } from "js/react/components/Gap";
import DropDown from "js/react/components/DropDown";
import { Field } from "js/react/components/UiComponents";

import { BeautifulDialog, DialogContent, ShowConfirmationDialog, ShowDialog } from "../../../components/Dialogs/BaseDialog";
import TagsList from "../../../components/TagsList";
import AddTagsDialog from "../../UserOptions/dialogs/AddTagsDialog";

const ContentItem = styled.label`
    display: block;
    font-weight: 600;
    font-size: 18px;
`;

export class BulkAssetUploadDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            types: props.types ?? "image",
            tags: props.tags ?? []
        };
    }

    handleAccept = () => {
        this.props.closeDialog();
        this.props.onAccept({
            types: this.state.types,
            tags: this.state.tags,
        });
    }

    handleRemoveTag = tag => {
        const { tags } = this.state;
        let index = tags.indexOf(tag);
        if (index > -1) {
            tags.splice(index, 1);
            this.setState({
                tags,
            });
        }
    }

    handleAddTag = () => {
        const { existingTags } = this.props;
        const { tags } = this.state;

        ShowDialog(AddTagsDialog, {
            activeTags: tags,
            existingTags,
            acceptCallback: newTags => {
                this.setState({
                    tags: newTags
                });
            },
        });
    }

    handleSelectType = value => {
        this.setState({
            types: value,
        });
        return true;
    }

    render() {
        const { files } = this.props;
        const { tags, types } = this.state;

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>
                    Bulk upload assets
                </DialogTitle>

                <DialogContent>
                    <h4>You are uploading {files.length} {files.length === 1 ? "asset" : "assets"} to share with your team.</h4>
                    <ContentItem>
                        <FlexBox left>
                            <div>Assets Type</div>
                            <Gap10 />
                            <DropDown
                                variant="outlined"
                                onSelect={this.handleSelectType}
                                items={[
                                    {
                                        label: "Image",
                                        value: AssetType.IMAGE,
                                    },
                                    {
                                        label: "Logo",
                                        value: AssetType.LOGO,
                                    },
                                ]}
                                type="transparent"
                                aboveAll
                            />
                        </FlexBox>
                    </ContentItem>
                    <Gap20 />
                    <Field title="Add tags to facilitate search (optional)">
                        <TagsList
                            tags={tags}
                            onRemove={this.handleRemoveTag}
                            onClick={this.handleAddTag}
                        />
                    </Field>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Cancel</Button>
                    <Button onClick={this.handleAccept} color="primary">Upload Assets</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}

export class BulkSetTagsDialog extends Component {
    state = {
        tags: []
    }

    handleRemoveTag = tag => {
        const { tags } = this.state;
        let index = tags.indexOf(tag);
        if (index > -1) {
            tags.splice(index, 1);
            this.setState({
                tags,
            });
        }
    }

    handleAddTag = () => {
        let {
            existingTags,
        } = this.props;
        let {
            tags,
        } = this.state;

        ShowDialog(AddTagsDialog, {
            activeTags: tags,
            existingTags,
            acceptCallback: newTags => {
                this.setState({
                    tags: newTags
                });
            },
        });
    }

    handleAccept = async () => {
        if (this.state.tags.length === 0) {
            if (!await ShowConfirmationDialog({ title: "No tags selected", message: "You have not selected any tags. This action will remove all existing tags from the selected assets. Are you sure you want to continue?" })) {
                return;
            }
        }
        this.props.closeDialog();
        this.props.onAccept(this.state.tags);
    }

    render() {
        const { selection } = this.props;
        const { tags } = this.state;

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>
                    Set tags on {selection.length} selected assets
                </DialogTitle>

                <DialogContent>
                    <h4>NOTE: Bulk setting of tags will replace any existing tags on the selected assets.</h4>
                    <TagsList
                        tags={tags}
                        onRemove={this.handleRemoveTag}
                        onClick={this.handleAddTag}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Cancel</Button>
                    <Button onClick={this.handleAccept} color="primary">Add Tags</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
