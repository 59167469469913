const { isEqual, simplifyNulls, makeArrayKey } = require("./utils");

function arrayDiff(left, right) {
    // Levenshtein edit distance - see wikipedia for details

    // basically given two arrays, find the minimum sequence of
    // simple operations to transform the first into the second

    let cache = {};
    return go(0, 0).value;
    function go(ii, jj) {
        let key = ii + ":" + jj;
        if (!cache[key]) {
            if (ii === left.length) {
                cache[key] = { cost: right.length - jj, value: right.slice(jj).map(() => "take") };
            } else if (jj === right.length) {
                cache[key] = { cost: left.length - ii, value: left.slice(ii).map(() => "delete") };
            } else {
                let setOption = go(ii + 1, jj + 1);
                if (isEqual(left[ii], right[jj])) {
                    setOption = { cost: setOption.cost, value: ["skip"].concat(setOption.value) };
                } else {
                    setOption = { cost: setOption.cost + 1, value: ["modify"].concat(setOption.value) };
                }

                let deleteOption = go(ii + 1, jj);
                deleteOption = { cost: deleteOption.cost + 1, value: ["delete"].concat(deleteOption.value) };

                let takeOption = go(ii, jj + 1);
                takeOption = { cost: takeOption.cost + 1, value: ["take"].concat(takeOption.value) };

                let minCost = Math.min(setOption.cost, deleteOption.cost, takeOption.cost);
                if (setOption.cost === minCost) {
                    cache[key] = setOption;
                } else if (deleteOption.cost === minCost) {
                    cache[key] = deleteOption;
                } else {
                    cache[key] = takeOption;
                }
            }
        }
        return cache[key];
    }
}

// finds a sequence of operations `ops` such that
//
//  deriveSimpleModel(applyUpdates(model, ops)) === newSimple
function diff(model, newSimple) {
    if (!newSimple) {
        return [{ command: "destroy" }];
    }

    newSimple = simplifyNulls(JSON.parse(JSON.stringify(newSimple)), true);

    let commands = [];

    if (!model) {
        commands.push({ command: "restore" });
        model = {};
    }

    go(model, newSimple, []);

    return commands;

    function go(model, newSimple, path) {
        let leftKeys = Object.keys(model);
        let rightKeys = Object.keys(newSimple);
        for (let key of model.__array__ || []) {
            if (leftKeys.indexOf(key) > -1) {
                leftKeys.splice(leftKeys.indexOf(key));
            }
        }
        if (Array.isArray(newSimple)) {
            for (let ii = 0; ii < newSimple.length; ii++) {
                if (rightKeys.indexOf("" + ii) > -1) {
                    rightKeys.splice(rightKeys.indexOf("" + ii), 1);
                }
            }
        }
        if (model.__array__ || Array.isArray(newSimple)) {
            let arrayKeys = (model.__array__ || []).filter(key => model.hasOwnProperty(key));
            let oldArray = arrayKeys.map(key => model[key]);
            let newArray = Array.isArray(newSimple) ? newSimple : [];
            let diffs = arrayDiff(oldArray, newArray);
            let ii = 0;
            let jj = 0;
            for (let cmd of diffs) {
                switch (cmd) {
                    case "take": {
                        let key = makeArrayKey();
                        commands.push({ command: "insertBefore", key: key, path: path, before: arrayKeys[ii] });
                        if (newSimple[jj] != null && typeof newSimple[jj] === "object") {
                            go({}, newSimple[jj], path.concat([key]));
                        } else {
                            commands.push({ command: "set", key: key, path: path, value: newSimple[jj] });
                        }
                        jj++;
                        break;
                    }
                    case "delete":
                        commands.push({ command: "delete", key: arrayKeys[ii], path: path });
                        ii++;
                        break;
                    case "modify":
                        if (newSimple[jj] != null && typeof newSimple[jj] === "object") {
                            go(model[arrayKeys[ii]] && typeof model[arrayKeys[ii]] === "object" ? model[arrayKeys[ii]] : {},
                                newSimple[jj],
                                path.concat([arrayKeys[ii]]));
                        } else {
                            commands.push({ command: "set", key: arrayKeys[ii], value: newSimple[jj], path: path });
                        }
                        ii++;
                        jj++;
                        break;
                    case "skip":
                        ii++;
                        jj++;
                        break;
                }
            }
        }
        for (let key of new Set([...leftKeys, ...rightKeys])) {
            if (key === "__array__") continue;

            if (model[key] == null || typeof model[key] !== "object") {
                if (newSimple[key] && typeof newSimple[key] === "object") {
                    go({}, newSimple[key], path.concat([key]));
                } else if (model[key] == null && newSimple[key] != null ||
                          model[key] != null && newSimple[key] == null ||
                          model[key] != null && newSimple[key] != null && model[key] !== newSimple[key]) {
                    commands.push({ command: "set", key: key, path: path, value: newSimple[key] });
                }
            } else {
                if (newSimple[key] != null && typeof newSimple[key] === "object") {
                    go(model[key], newSimple[key], path.concat([key]));
                } else {
                    commands.push({ command: "set", key: key, path: path, value: newSimple[key] });
                }
            }
        }
    }
}

module.exports = diff;
