export interface ITeamActivityLog {
    id: string;
    teamId: string;
    userId?: string;
    activity: TeamActivityType;
    activityData: any;
    createdAt: number;
    modifiedAt: number;
}

export enum TeamActivityType {
    CREATED = "created",
    MEMBER_ADDED = "memberAdded",
    MEMBER_REMOVED = "memberRemoved",
    MEMBER_ROLE_CHANGED = "memberRoleChanged",
    MEMBER_LICENSE_CHANGED = "memberLicenseChanged",
    SHARED_RESOURCE_ADDED = "sharedResourceAdded",
    SHARED_RESOURCE_REMOVED = "sharedResourceRemoved",
    SHARED_RESOURCE_UPDATED = "sharedResourceUpdated",
    SHARED_RESOURCE_USED = "sharedResourceUsed",
    FOLDER_ADDED = "folderAdded",
    FOLDER_REMOVED = "folderRemoved",
    FOLDER_RESOURCE_ADDED = "folderResourceAdded",
    FOLDER_RESOURCE_REMOVED = "folderResourceRemoved",
    FOLDER_MEMBER_ADDED = "folderMemberAdded",
    FOLDER_MEMBER_REMOVED = "folderMemberRemoved",
    DELETED = "deleted",
}
