import React, { Component } from "react";
import Avatar from "js/react/components/Avatar";
import { LinkType } from "js/react/views/PresentationSettings/dataservices/LinkDataService";
import Icon from "js/react/components/Icon";
import moment from "moment";
import { trackActivity } from "js/core/utilities/utilities";
import AnalyticsController from "js/react/views/Analytics/AnalyticsController";

class AnalyticsLinksList extends Component {
    componentDidMount() {
        this.props.presentation.loadPresentationLinks();
    }

    renderIcon(link) {
        switch (link.get("type")) {
            case LinkType.PUBLIC:
                return <Icon iconName="link" />;
            case LinkType.PROTECTED:
                return <Icon iconName="lock" />;
            case LinkType.INVITE:
                let contact = link.get("email");
                if (typeof contact == "object") {
                    return <Avatar url={contact.photoUrl} />;
                } else {
                    return <Avatar url="" />;
                }
            case LinkType.EMBED:
                return <Icon iconName="add_to_queue" />;
            case LinkType.SOCIAL:
                return <Icon iconName="share" />;
            case LinkType.INTEGRATOR:
                return <Icon iconName="picture_in_picture" />;
        }
    }

    handleClick = link => {
        const props = {
            presentation_id: this.props.presentation.get("id"),
            link_id: link.get("id"),
            type:
                link.get("type") === "social"
                    ? link.get("name")
                    : link.get("type"),
            source_view: "Shared Links"
        };
        trackActivity("LinkAnalytics", "ShowLinkDetail", null, null, props);
        AnalyticsController.setSelectedLink(link.id);
    };

    render() {
        let presentation = this.props.presentation;
        let links = presentation.links.models;

        if (links.length) {
            return (
                <div className="links">
                    {links.map(link => (
                        <div
                            key={link.id}
                            className="link"
                            onClick={() => this.handleClick(link)}
                        >
                            {this.renderIcon(link)}
                            <div className="name">{link.getLinkName()}</div>
                            <div className="type">{link.get("type")}</div>
                            <div className="created">
                                {moment(link.get("createdAt")).format(
                                    "MMM DD YYYY"
                                )}
                            </div>
                            {/*<div*/}
                            {/*className={`view-state ${viewed ? "viewed" : ""}`}>{viewed ? "Viewed" : "Not Viewed"}</div>*/}
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="empty-list-warning">
                    There are no shared links for this presentation
                </div>
            );
        }
    }
}

export default AnalyticsController.withState(AnalyticsLinksList);
