import React, { Component } from "reactn";
import { DialogTitle, DialogActions } from "@material-ui/core";
import { DialogContent, BeautifulDialog } from "./BaseDialog";
import { TextButton } from "js/react/components/UiComponents.js";

export default class ConfirmationCheckboxDialog extends Component {
    state = {
        isChecked: false
    }

    render() {
        const { title, message, cancelCallback, cancelButtonLabel, acceptCallback, okButtonLabel } = this.props;
        const { isChecked } = this.state;
        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>{message}</DialogContent>
                <DialogActions>
                    <TextButton
                        onClick={() => {
                            cancelCallback && cancelCallback();
                            this.props.closeDialog();
                        }}
                    >
                        {cancelButtonLabel || "Cancel"}
                    </TextButton>
                    <TextButton
                        color="primary"
                        onClick={() => {
                            acceptCallback && acceptCallback(isChecked);
                            this.props.closeDialog();
                        }}
                    >
                        {okButtonLabel || "Ok"}
                    </TextButton>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
