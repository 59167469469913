import { v4 as uuid } from "uuid";
import { appSearchNames, appSearchHost } from "js/config";
import { app } from "js/namespaces";

export enum SearchEngine {
    USER_SLIDES = appSearchNames.slidesSearchEngine,
    SHARED_SLIDES = appSearchNames.sharedSlidesSearchEngine,
    TEAMPLATES = appSearchNames.templatesSearchEngine,
    INSPIRATION_GALLERY = appSearchNames.inspirationGallerySearchEngine
}

export interface SearchPayload {
    workspaceId: string,
    searchEngine: SearchEngine,
    query: string,
    page?: {
        size: number,
        current?: number
    },
    sort?: {
        [fieldName: string]: "desc" | "asc"
    },
    group?: {
        field: string,
        size?: number
    },
    searchFields?: {
        [fieldName: string]: {
            weight?: number
        }
    },
    resultFields?: {
        [fieldName: string]: {
            raw: {
                size?: number
            }
        }
    },
    filters?: {
        [fieldName: string]: any[]
    }
}

export interface SearchResponseMeta {
    warnings: string[],
    request_id: string,
    page: {
        total_pages: number,
        size: number,
        current: number,
        total_results: number
    },
    alerts: string[]
}

export interface SearchResponseResult {
    [fieldName: string]: {
        raw?: any
        score?: number
    }
}

export interface SearchResponse {
    meta: SearchResponseMeta,
    results: SearchResponseResult[],
    errors?: any[]
}

// Used for test environment
const FAKE_RESPONSE: SearchResponse = {
    meta: {
        warnings: [],
        request_id: uuid(),
        page: {
            total_pages: 0,
            size: 0,
            current: 0,
            total_results: 0
        },
        alerts: []
    },
    results: []
};

export function getSearchToken(payload: SearchPayload): string {
    if (!app.user) {
        throw new Error("app.user is not initialized");
    }

    const searchKeys = app.user.get("searchKeys");
    if (!searchKeys) {
        throw new Error(`Search keys is missing from user model ${app.user.id}`);
    }

    const workspaceKeys = searchKeys[payload.workspaceId];
    if (!workspaceKeys) {
        throw new Error(`Keys for workspace ${payload.workspaceId} are missing`);
    }

    const token = workspaceKeys[`${payload.searchEngine}-private-key`] ?? workspaceKeys[`${payload.searchEngine}-public-key`];
    if (!token) {
        throw new Error(`Tokens for search engine ${payload.searchEngine} in workspace ${payload.workspaceId} not found`);
    }

    return token;
}

export async function search(payload: SearchPayload): Promise<SearchResponse> {
    const response: SearchResponse = await fetch(`${appSearchHost}engines/${payload.searchEngine}/search`, {
        method: "POST",
        body: JSON.stringify({
            query: payload.query,
            page: payload.page,
            sort: payload.sort,
            group: payload.group,
            search_fields: payload.searchFields,
            result_fields: payload.resultFields,
            filters: payload.filters
        }),
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getSearchToken(payload)}`
        }
    }).then(res => res.json());

    if (response.errors) {
        throw new Error(`Appsearch responded with errors, full response: ${JSON.stringify(response)}`);
    }

    return response;
}
