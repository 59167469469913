import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { Backbone } from "js/vendor";
import { FeatureType, getUserOrgPermissions, getUserPlanPermissions } from "./planPermissions";
import { isOfflinePlayer } from "js/config";

const logger = getLogger(LogGroup.FEATURES);

const Features = Backbone.Model.extend({
    initialize: function() {
        this._permissions = {};

        this.initializePromise = (async () => {
            if (isOfflinePlayer) {
                // No internet - no permissions
                return;
            }

            const _loadUserPlanPermissions = async () => {
                this._permissions.personal = await getUserPlanPermissions()
                    .catch(err => {
                        logger.error(err, `[Features] _loadUserPlanPermissions() failed`);
                        return {};
                    });
            };

            const _loadUserOrgPermissions = async team => {
                const orgId = team?.get("orgId");
                if (!orgId) {
                    return;
                }

                this._permissions[orgId] = await getUserOrgPermissions(orgId)
                    .catch(err => {
                        logger.error(err, `[Features] _loadUserOrgPermissions() failed`, { orgId });
                        return {};
                    });
            };

            const _setCurrentTeams = async () => {
                if (!this.currentTeams) {
                    this.currentTeams = [];
                }

                // Unsibscribe from old teams
                this.currentTeams.forEach(team => this.stopListening(team, "change"));

                const currentOrgIds = [...new Set(this.currentTeams.map(team => team?.get("orgId")))].filter(Boolean);
                const newOrgIds = [...new Set(ds.teams.models.map(team => team?.get("orgId")))].filter(Boolean);

                // Delete removed teams permissions
                const removedOrgIds = currentOrgIds.filter(orgId => !newOrgIds.includes(orgId));
                removedOrgIds.forEach(orgId => delete this._permissions[orgId]);

                // Add new teams permissions
                const addedOrgIds = newOrgIds.filter(orgId => !currentOrgIds.includes(orgId));
                await Promise.all(addedOrgIds.map(orgId => _loadUserOrgPermissions({ get: () => orgId })));

                // Reassign current teams and subscribe to them
                this.currentTeams = [...ds.teams.models];
                this.currentTeams.forEach(team => this.listenTo(team, "change", _loadUserOrgPermissions));
            };

            await Promise.all([
                (async () => {
                    if (ds.teams) {
                        await _setCurrentTeams();
                        this.listenTo(ds.teams, "change", _setCurrentTeams);
                    }
                })(),
                (async () => {
                    if (ds.userPlans) {
                        await _loadUserPlanPermissions();
                        this.listenTo(ds.userPlans, "change", _loadUserPlanPermissions);
                    }
                })()
            ]);
        })();
    },

    isFeatureEnabled(feature, workspaceId) {
        if (workspaceId === undefined) {
            throw new Error("missing workspaceId");
        }
        const rv = this._getWorkspaceFeatures(workspaceId).hasOwnProperty(feature);
        return rv;
    },

    getFeatureProps(feature, workspaceId) {
        if (workspaceId === undefined) {
            throw new Error("missing workspaceId");
        }
        const featureProps = this._getWorkspaceFeatures(workspaceId)[feature];
        if (!featureProps) {
            logger.warn(`${feature} planPermission does not exist for current user. Please check if the ${feature} planPermission is attached to the plan.`, { feature, workspaceId });
        }
        return featureProps || {};
    },

    /** @private */
    _getWorkspaceFeatures(workspaceId) {
        if (workspaceId === undefined) {
            throw new Error("missing workspaceId");
        }
        // presentations from external workspaces go into the personal workspace
        const features = this._permissions[workspaceId];
        if (!features) {
            return this._permissions["personal"];
        }
        return features;
    }
});

export { FeatureType, Features };

