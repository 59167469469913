const theme = {
    isBuiltIn: true,
    id: "minimal",
    name: "Minimal",
    showLogo: true,
    logoScale: 1,
    logoPosition: "left",
    logoOffset: 0,
    showMessage: true,
    showPageNum: true,
    logo: null,
    logo_dark: null,
    showFooterByDefault: null,
    palette_name: "custom",
    defaultSlideColor: "theme",
    defaultBackgroundColor: "background_light",
    styleFonts: 1,
    styleFontWeight: "heavy",
    styleElementStyle: "outlined",
    styleEffect: "none",
    styleDesign: 1,
    styleDecoration: "none_center",
    styleHeaderAlignment: "left",
    styleShape: "none",
    styleColor: "slide",
    styleBackground: "none",
    styleTitleFont: "bebasneue",
    styleBodyFont: "sourcesanspro",
    styleWeight: "light",
    styleTitleSlide: "bar_left",
    fontScale: 1,
    iconStyle: "classic",
    cjkFont: "jp",
    colors: {
        accent1: "rgba(240,179,86,1)",
        accent2: "rgba(230,105,34,1)",
        accent3: "rgba(20,85,140,1)",
        background_accent: "rgba(245,245,245,1)",
        background_dark: "rgba(0,0,0,1)",
        background_light: "#ffffff",
        chart1: "#14558C",
        chart2: "rgba(98,168,187,1)",
        chart3: "rgba(152,166,77,1)",
        chart4: "rgba(240,179,86,1)",
        chart5: "rgba(230,105,34,1)",
        chart6: "rgba(170,89,134,1)",
        primary_dark: "rgba(42,41,33,1)",
        primary_light: "#ffffff",
        secondary_dark: "rgba(90,90,76,1)",
        secondary_light: "rgba(255,255,255,0.9)",
        theme: "rgba(98,168,187,1)",
        hyperlink: "#11a9e2"
    },
    fontHeaderFontId: "montserrat",
    fontHeaderWeight: 400,
    fontHeaderLetterSpacing: 0,
    fontHeaderLineHeight: 1.8,
    fontHeaderScaling: 85,
    fontHeaderTextTransform: "auto",
    fontTitleFontId: "montserrat",
    fontTitleWeight: 500,
    fontTitleLetterSpacing: 0,
    fontTitleLineHeight: 1.8,
    fontTitleScaling: 80,
    fontTitleTextTransform: "auto",
    fontBodyFontId: "montserrat",
    fontBodyWeight: 400,
    fontBodyLetterSpacing: 0,
    fontBodyLineHeight: 2,
    fontBodyScaling: 80,
    fontBodyTextTransform: "auto",
};

module.exports = theme;

