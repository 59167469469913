import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { TextField } from "@material-ui/core";

import { TAX_ID_DATA } from "common/taxConstants";

const Container = styled.div<{ visible: boolean }>`
&&& {
    display: ${({ visible }) => visible ? "flex" : "none"};
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
}`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`;

const StyledTextField = styled(TextField) <{ warning: boolean }>`
    &&& {
        >:not(.Mui-focused)>.MuiOutlinedInput-notchedOutline {
            border-color: ${({ warning }) => warning ? "red" : "rgba(0, 0, 0, 0.23)"};
            box-shadow: ${({ warning }) => warning ? "0 0 0px 1px #b4000;" : "none"};
        }

        .MuiInput-underline:not(.Mui-disabled)::before {
            ${({ warning }) => warning ? css`
            border-bottom: 2px solid red;
            ` : null};
        }

        .Mui-focused>.MuiOutlinedInput-notchedOutline {
            border-width: 1px;
            border-color: #11a9e2;
            box-shadow: 0 0 0px 1px #11a9e2;
        }

        :hover>:not(.Mui-focused)>.MuiOutlinedInput-notchedOutline {
            border-color: #333;
        }
        input: {
            color: #222;
        }
        input::placeholder {
            font-style: normal;
            color: #666;
        }
        .MuiFormLabel-root {
            color: #666;
        }
        .MuiFormLabel-root.Mui-focused {
            color: #11a9e2;
        }
    }
`;

const ErrorMessage = styled.div`
    margin-top: 4px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.112px;
    color: red;
`;

function getTaxIdProps(countryCode: string): {
    show: boolean;
    name: string;
    placeholder: string;
    validate: (value: string) => boolean;
    type: string | null;
} {
    const taxIdData = TAX_ID_DATA.find(({ countryCode: taxIdCountryCode, isLocal }) => taxIdCountryCode.toLowerCase() === countryCode.toLowerCase() && !isLocal);
    if (!taxIdData) {
        return {
            show: false,
            name: "",
            placeholder: "",
            validate: () => true,
            type: null
        };
    }

    return {
        show: true,
        name: taxIdData.name,
        placeholder: taxIdData.placeholder,
        validate: value => !value || value.trim().length === taxIdData.placeholder.length,
        type: taxIdData.type
    };
}

interface Props {
    countryCode: string;
    initialTaxId: string;
    onChange: (values: { taxId: string, isTaxIdValid: boolean, taxIdType: string | null }) => void;
    disabled: boolean;
    className?: string;
    label?: HTMLLabelElement
    helperText?: string;
}

export function TaxIdForm(props: Props) {
    const {
        countryCode,
        initialTaxId,
        onChange,
        disabled,
        className,
        label,
        helperText
    } = props;
    const [taxId, setTaxId] = useState<string>(initialTaxId);
    const [showError, setShowError] = useState(false);
    const prevCountryCodeRef = useRef(countryCode);

    useEffect(() => {
        if (prevCountryCodeRef.current !== countryCode) {
            setTaxId("");
        }
        prevCountryCodeRef.current = countryCode;
    }, [countryCode]);

    const {
        show: showTaxId,
        name: taxIdName,
        placeholder: taxIdPlaceholder,
        validate: taxIdValidate,
        type: taxIdType
    } = getTaxIdProps(countryCode);

    const isTaxIdValid = taxIdValidate(taxId);

    const handleTaxIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTaxId(event.target.value);
    };

    const handleBlur = () => {
        setShowError(true);
    };

    const handleFocus = () => {
        setShowError(false);
    };

    useEffect(() => {
        onChange({
            taxId: showTaxId ? taxId : "",
            taxIdType: showTaxId ? taxIdType : null,
            isTaxIdValid
        });
    }, [taxId, taxIdType, isTaxIdValid, showTaxId]);

    return (<Container className={className} visible={showTaxId}>
        {label}
        <InputContainer>
            <StyledTextField
                placeholder={taxIdPlaceholder}
                onChange={handleTaxIdChange}
                value={taxId}
                fullWidth
                disabled={disabled}
                autoFocus={false}
                autoComplete="off"
                warning={!isTaxIdValid && showError}
                onBlur={handleBlur}
                onFocus={handleFocus}
                data-testid="minimal-billing-address-form-tax-id"
                label={`${taxIdName} (optional)`}
            />
            {(!isTaxIdValid || helperText) && showError && <ErrorMessage>{helperText ?? "Invalid Tax Id"}</ErrorMessage>}
        </InputContainer>
    </Container>);
}

export default TaxIdForm;
