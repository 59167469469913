import { v4 as uuid } from "uuid";

import { _ } from "js/vendor";
import removeTrailingNullsFromArrays from "common/utils/removeTrailingNullsFromArrays";

import Adapter from "./adapter";

class DummyAdapter extends Adapter {
    constructor(options) {
        super(options);

        this.removeTrailingNullsFromArrays = !!options?.removeTrailingNullsFromArrays;
    }

    connect(options) {
        if (!options) {
            throw new Error("Options must be passed in with at least a data or defaultValue parameter");
        }

        this.connected = true;

        setTimeout(() => {
            let data = _.cloneDeep(options.data ?? options.defaultValue ?? {});
            if (this.removeTrailingNullsFromArrays) {
                data = removeTrailingNullsFromArrays(data);
            }
            options.onRemoteChange(Adapter.TYPE.initialize, data);
        }, 0);

        return options.id ?? uuid();
    }

    disconnect() {
        this.connected = false;
    }

    update(type, newData, currentData) {
        return Promise.resolve();
    }

    createUid(root) {
        return uuid();
    }

    isConnected() {
        return this.connected;
    }
}

export default DummyAdapter;
