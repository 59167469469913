import React from "react";

import { getStaticUrl } from "js/config";

const logoReverse = getStaticUrl("/images/beautifulai-logos/beautifulai-logo-reverse.svg");
const logo = getStaticUrl("/images/beautifulai-logos/beautifulai-logo.svg");

export default function BeautifulLogo({ forceMarketing, noLink, darkOnLight, width = 200 }) {
    let href = forceMarketing ? "/?" : "/";
    if (noLink) {
        href = undefined;
    }
    return (
        <a href={href}>
            <img
                alt="Beautiful.ai"
                src={darkOnLight ? logo : logoReverse}
                width={width}
            />
        </a>
    );
}
