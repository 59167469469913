export enum BillingInterval {
    YEAR = "year",
    MONTH = "month"
}

export enum SubscriptionStatus {
    PAST_DUE = "past_due",
    ACTIVE = "active",
    TRIALING = "trialing",
    CANCELED = "canceled",
    INCOMPLETE = "incomplete",
    INCOMPLETE_EXPIRED = "incomplete_expired",
    PAUSED = "paused",
    UNPAID = "unpaid"
}

export interface IEnterpriseInvite {
    id: string;
    createdAt: number;
    modifiedAt: number;
    invitedBy: string;
    inviteeEmail: string;
    pricePerSeat: number;
    billingInterval: BillingInterval;
    priceId?: string;
    seatCount: number;
    trialDurationDays: number;
    daysUntilInvoiceDue: number;
    notifyInviter: boolean;
    forceLockWorkspace: boolean;
    teamName?: string;
    acceptedAt?: number;
    acceptedBy?: string;
    trialEndsAt?: number;
    createdOrgId?: string;
    subscriptionStatus?: SubscriptionStatus;
    subscriptionStatusModifiedAt?: number;
    invoiceSentAt?: number;
}
