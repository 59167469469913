import { IGenericModelFields } from "../IGenericFields";

export enum WorkspaceResourceType {
    /**
     * Regular presentation
     */
    PRESENTATION = "presentation",
    /**
     * Presentation template
     */
    PRESENTATION_TEMPLATE = "presentationTemplate",
    /**
     * Shared slide
     */
    SLIDE = "slide",
    /**
     * Slide template
     */
    SLIDE_TEMPLATE = "slideTemplate",
    /**
     * Shared asset
     */
    ASSET = "asset",
    /**
     * Shared theme or user theme
     */
    THEME = "theme",
}

export interface IWorkspaceResourceBase extends IGenericModelFields {
    readonly workspaceId: string;
    readonly resourceType: WorkspaceResourceType;
    readonly resourceId: string;
    folderIds: string[];
    tags: string[];
    metadata: Record<string, any>;
}

export interface IWorkspaceResourceSlide extends IWorkspaceResourceBase {
    resourceType: WorkspaceResourceType.SLIDE;
    metadata: {
        // From libraryItems
        contentModifiedAt: number;
        isDisabled: boolean;
        name: string;
        description: string;
        usedByUsers: string[];
        usedInPresentations: string[];
        ratedByUsers: string[];
    }
}

export interface IWorkspaceResourceSlideTemplate extends IWorkspaceResourceBase {
    resourceType: WorkspaceResourceType.SLIDE_TEMPLATE;
    metadata: {
        // From libraryItems
        contentModifiedAt: number;
        isDisabled: boolean;
        name: string;
        description: string;
        usedByUsers: string[];
        usedInPresentations: string[];
        ratedByUsers: string[];
    }
}

export interface IWorkspaceResourcePresentation extends IWorkspaceResourceBase {
    resourceType: WorkspaceResourceType.PRESENTATION;
    metadata: {}
}

export interface IWorkspaceResourcePresentationTemplate extends IWorkspaceResourceBase {
    resourceType: WorkspaceResourceType.PRESENTATION_TEMPLATE;
    metadata: {
        isPublished: boolean;
        isTemplateConfirmed: boolean;
        ratedByUsers: string[];
    }
}

export interface IWorkspaceResourceAsset extends IWorkspaceResourceBase {
    resourceType: WorkspaceResourceType.ASSET;
    metadata: {
        type: string; // type
        createdBy: string; // createdById
        name: string; // assetName
        alt: string; // alt
        isPublished: boolean; // !hidden
    }
}

export interface IWorkspaceResourceTheme extends IWorkspaceResourceBase {
    resourceType: WorkspaceResourceType.THEME;
    metadata: {}
}

export type IWorkspaceResource =
    IWorkspaceResourceSlide |
    IWorkspaceResourceSlideTemplate |
    IWorkspaceResourcePresentation |
    IWorkspaceResourcePresentationTemplate |
    IWorkspaceResourceAsset |
    IWorkspaceResourceTheme;
