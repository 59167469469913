import React, { Component, Fragment } from "react";

import Button from "js/react/components/Button2";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";

class SuccessfulPurchase extends Component {
    fadeOutTimeout = null;
    callToActionTimeout = null;

    renderNonTrialSuccessMessage() {
        const { message, productDisplayName } = this.props;
        if (message) {
            return message;
        }
        return (<Fragment>You have successfully upgraded to <span>{productDisplayName}</span>.</Fragment>);
    }

    async componentDidMount() {
        const { onCallToActionClick } = this.props;
        this.fadeOutTimeout = setTimeout(() => {
            this.setState({ fadeOut: true });
            this.callToActionTimeout = setTimeout(() => {
                onCallToActionClick();
            }, 750);
        }, 1500);
    }

    componentWillUnmount() {
        clearTimeout(this.fadeOutTimeout);
        clearTimeout(this.callToActionTimeout);
    }

    handleButtonClick = () => {
        const { onCallToActionClick } = this.props;
        clearTimeout(this.fadeOutTimeout);
        clearTimeout(this.callToActionTimeout);
        onCallToActionClick();
    }

    render() {
        const { productDisplayName, showTrial, trialExpirationDate, callToActionText, additionalMessage, trialDurationDays = 14 } = this.props;

        if (showTrial) {
            return (
                <BeautifulDialog preventClose >
                    <DialogContent>
                        <div id="successful_purchase">
                            <div id="big_icon">
                                <i className="micon">check</i>
                            </div>
                            <div id="message">Great! You've just started your {trialDurationDays}-day free trial for Beautiful.ai {productDisplayName}.</div>
                            <div className="receipt">
                                Cancel anytime before {trialExpirationDate} and you will not be charged.
                            </div>
                            {additionalMessage && <div className="receipt">{additionalMessage}</div>}
                            <Button onClick={this.handleButtonClick} label={callToActionText} />
                        </div>
                    </DialogContent>
                </BeautifulDialog>

            );
        } else {
            return (
                <BeautifulDialog preventClose>
                    <DialogContent>
                        <div id="successful_purchase">
                            <div id="big_icon">
                                <i className="micon">check</i>
                            </div>
                            <div id="message">
                                {this.renderNonTrialSuccessMessage()}
                            </div>
                            <div className="receipt">A receipt will be sent to your email shortly</div>
                            {additionalMessage && <div className="receipt">{additionalMessage}</div>}
                            <Button onClick={this.handleButtonClick} label={callToActionText} />
                        </div>
                    </DialogContent>
                </BeautifulDialog>
            );
        }
    }
}

export default SuccessfulPurchase;
