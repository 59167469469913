export enum EmailTypes {
    // one-off emails
    REFERRAL = "referral",
    VERIFY_EMAIL = "verifyEmail",
    COMMENTS_AND_EDITS = "commentsAndEdits",
    PRESENTATION_ACCESS_GRANTED = "presentationAccessGranted",
    PRESENTATION_ACCESS_REQUESTED = "presentationAccessRequested",
    PRESENTATION_LINK_CREATED = "presentationLinkCreated",
    TEAM_MEMBER_INVITED = "teamMemberInvited",
    TEAM_MEMBER_ACCEPTED_INVITATION = "teamMemberAcceptedInvitation",
    TEAM_MEMBER_ADDED_TO_FOLDER = "teamMemberAddedToFolder",
    TEAM_MEMBER_REQUESTED_ACCESS_TO_FOLDER = "teamMemberRequestedAccessToFolder",

    // scheduled emails
    REMIND_USER_TO_SHARE_PRESENTATION = "remindUserToSharePresentation",
    REMIND_COLLABORATOR_TO_ACCESS_PRESENTATION = "remindCollaboratorToAccessPresentation",
    REMIND_PRESENTATION_FIRST_EVER_VIEW = "remindPresentationFirstEverView",
    REMIND_PRESENTATION_FIRST_VIEW_AFTER_90_DAYS = "remindPresentationFirstViewAfter90Days",
    REMIND_PRESENTATION_LOW_ENGAGEMENT = "remindPresentationLowEngagement",
    REMIND_TEAM_MEMBER_TO_ACCEPT_INVITE = "remindTeamMemberToAcceptInvitation",
    REMIND_TEAM_OWNER_TO_INVITE_MEMBERS = "remindTeamOwnerToInviteMembers",

    // cron emails
    PRESENTATION_ACTIVITY_WEEKLY_ROUNDUP = "presentationActivityWeeklyRoundup",
}

export enum EmailChartTypes {
    EMAILS_SENT_LAST_HOUR = "EMAILS_SENT_LAST_HOUR",
    EMAILS_SENT_LAST_24_HOURS = "EMAILS_SENT_LAST_24_HOURS",
    EMAILS_SENT_LAST_30_DAYS = "EMAILS_SENT_LAST_30_DAYS",
}

export interface EmailChartData {
    data: {
        emailDeliveryTime: string;
        emailDeliveryLogs: {
            emailType: string;
            receivingUsers: {
                toUserEmail: string;
                emailDeliveryCount: number;
            }[];
            emailDeliveryCount: number;
        }[];
    }[];
}

export interface EmailPayload {
    templateId: string;
    fromBai?: { email: string; };
    fromUser?: { email: string; displayName?: string; id?: string; };
    toUser: { email: string; displayName?: string; id?: string; };
    context: Record<string, any>;
    scheduledTaskId?: string;
}

export interface EmailTriggerPayload {
    userId?: string;
    presentationId?: string;
    linkId?: string;
    collaboratorEmail?: string;
    teamInviteId?: string;
    teamId?: string;
    teamFolderId?: string;
    teamMemberId?: string;
    teamMemberIds?: string[];
    presentationIds?: string[];
    aggregatedActivityCountsPerPresentation?: any;
    context?: Record<string, any>;
}

export interface EmailProcessorRequest {
    emailType: EmailTypes;
    emailPayload: EmailPayload & EmailTriggerPayload;
}

export enum EmailDeliveryStatus {
    PENDING = "PENDING",
    SENT = "SENT",
    FAILED = "FAILED",
}

export interface IEmailDeliveryLog {
    id: string;
    emailType: EmailTypes;
    emailPayload: EmailPayload;
    emailDeliveryId: string;
    emailDeliveryStatus: EmailDeliveryStatus;
    emailDeliveryError: string;
    scheduledTaskId: string;
    fromUserId: string;
    toUserId: string;
    toUserEmail: string;
    createdAt: number;
    modifiedAt: number;
}
