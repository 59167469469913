import React, { Component, Fragment } from "react";
import { DialogTitle, DialogActions, Button, TextField } from "@material-ui/core";
import { ColGrid, RowGrid } from "js/react/components/LayoutGrid";
import {
    BeautifulDialog,
    DialogContent
} from "js/react/components/Dialogs/BaseDialog";
import { Gap20 } from "js/react/components/Gap";
import CheckBox from "js/react/components/CheckBox";
import linkDS from "js/react/views/PresentationSettings/dataservices/LinkDataService";
import { ToggleAllowCommenting } from "../components/ToggleAllowCommenting";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";

export default class EditEmbedCodeDialog extends Component {
    state = {
        useCustomSize: false,
        width: 640,
        height: 360,
    };

    handleAccept = link => {
        linkDS.updateLink(link, { embedOptions: this.state });
        this.copyToClipboard();
        this.props.closeDialog();
        this.props.callback();
    };

    handleOnEnter = embedOptions => {
        this.setState(embedOptions);
    };

    handleNameChange = name => {
        this.props.link.update({ name });
        this.update();
    }

    toggleAllowCommenting = event => {
        const { link } = this.props;
        if (link) {
            const allowCommenting = !link.get("allowCommenting");
            link.update({ allowCommenting });
            this.update();
        }
    }

    copyToClipboard() {
        const {
            link, presentation
        } = this.props;
        const {
            useCustomSize,
            width,
            height,
        } = this.state;
        const embedCode = presentation.getEmbedCode(
            link,
            {
                useCustomSize,
                width,
                height,
            },
        );
        clipboardWrite(
            {
                [ClipboardType.HTML]: embedCode,
                [ClipboardType.TEXT]: embedCode,
            },
            true,
        );
        this.setGlobal({
            alertMessage: `Link for ${link.get("name")} was copied to the clipboard`
        });
    }

    handleCustomSize(value, type) {
        if (type == "width") {
            this.setState({
                width: value,
                height: value * 0.5625
            });
        } else {
            this.setState({
                height: value,
                width: value * 1.7777777778
            });
        }
    }

    update = () => {
        this.forceUpdate();
        this.props.callback();
    }

    render() {
        const { link } = this.props;

        const allowCommenting = link.get("allowCommenting");

        return (
            <BeautifulDialog
                closeDialog={this.props.closeDialog}
                onEnter={() => this.handleOnEnter(link.get("embedOptions"))}
            >
                <DialogTitle>Edit Embed Link</DialogTitle>
                <DialogContent className="dialog-embed">
                    <RowGrid gap={0}>
                        <TextField
                            label="Link Name"
                            value={link.get("name")}
                            onChange={event => this.handleNameChange(event.target.value)}
                        />
                        <Gap20 />
                        <ToggleAllowCommenting
                            allowCommenting={allowCommenting}
                            onToggle={this.toggleAllowCommenting}
                        />
                        <ColGrid>
                            <CheckBox
                                checked={this.state.useCustomSize}
                                value="useCustomSize"
                                label="Use Custom Size"
                                onChange={value =>
                                    this.setState({ useCustomSize: value })
                                }
                            />
                            {this.state.useCustomSize &&
                                <>
                                    <TextField
                                        id="width"
                                        label="Width"
                                        placeholder="auto"
                                        value={this.state.width}
                                        type="number"
                                        className="textField"
                                        onChange={event =>
                                            this.handleCustomSize(event.target.value, "width")
                                        }
                                    />
                                    <TextField
                                        id="height"
                                        label="Height"
                                        placeholder="auto"
                                        value={this.state.height}
                                        type="number"
                                        className="textField"
                                        onChange={event =>
                                            this.handleCustomSize(event.target.value, "height")
                                        }
                                    />
                                </>
                            }
                        </ColGrid>
                    </RowGrid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            this.handleAccept(link);
                        }}
                        color="primary"
                    >
                        Copy Embed Code to Clipboard
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
