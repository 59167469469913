import { getStaticUrl } from "js/config";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.AUDIO);

const audioContext = new (window.AudioContext || window.webkitAudioContext)();

function getAudioBufferFromUrl(url) {
    return fetch(url)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => new Promise((resolve, reject) => {
            audioContext.decodeAudioData(arrayBuffer, resolve, reject);
        }));
}

let emptyAudioBuffer;
async function prepareToUnlockAudio() {
    if (emptyAudioBuffer) {
        return;
    }

    emptyAudioBuffer = await getAudioBufferFromUrl(getStaticUrl("/images/nothing.wav"));
}

let isAudioUnlocked = false;
function unlockAudio() {
    if (!emptyAudioBuffer) {
        throw new Error("Empty audio buffer has't been loaded, call prepareToUnlockAudio() first");
    }

    if (isAudioUnlocked) {
        return;
    }

    const audio = audioContext.createBufferSource();
    audio.buffer = emptyAudioBuffer;
    audio.connect(audioContext.destination);
    audio.start(0);
    audio.onended = () => isAudioUnlocked = true;
    audio.onerror = err => logger.error(err, "unlockAudio() onerror()");
}

export { audioContext, getAudioBufferFromUrl, prepareToUnlockAudio, unlockAudio };
