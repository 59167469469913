import React, { Component } from "react";
import Spinner from "js/react/components/Spinner";
import { formatter } from "js/core/utilities/formatter";
import moment from "moment/moment";
import { Shape } from "js/core/utilities/shapes";
import { FetchStatus } from "js/react/constants";
import AnalyticsController from "js/react/views/Analytics/AnalyticsController";

const StatItem = ({ value, label, format, isLoading }) => {
    if (isLoading) {
        return (
            <div className="stat-item">
                <Spinner />
            </div>
        );
    }

    switch (format) {
        case "number":
            value = formatter.setAbbreviation(value || 0);
            break;
        case "duration":
            value = formatter.formatDuration(
                value || 0,
                "seconds",
                "h[h] m[m] s[s]"
            );
            break;
    }

    return (
        <div className="stat-item">
            <div className="stat-value">{value}</div>
            <label className="stat-label">{label}</label>
        </div>
    );
};

const RadialGraph = ({ value = 0, radius }) => {
    let startAngle = 0 - (90 / 180) * Math.PI;
    let angle = Math.min(359, (360 * value) / 100);
    let endAngle = ((angle - 90) / 180) * Math.PI;
    let arc = Shape.drawArc(
        radius,
        radius,
        radius - 4,
        startAngle,
        endAngle
    ).toPathData();
    return (
        <div className="radial-graph">
            <svg
                width={radius * 2}
                height={radius * 2}
                viewBox={`0 0 ${radius * 2} ${radius * 2}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx={radius} cy={radius} r={radius - 4} />
                <path d={arc} />
            </svg>
        </div>
    );
};

const CompletionRateStat = ({ label, value, isLoading }) => {
    if (isLoading) {
        return (
            <div className="stat-item">
                <Spinner />
            </div>
        );
    }

    return (
        <div className="stat-item with-radial">
            <RadialGraph value={value} radius={26} />
            <div className="stat-item-data">
                <div className="stat-value">{value.toFixed()}%</div>
                <label className="stat-label">{label}</label>
            </div>
        </div>
    );
};

class PresentationStatsComponent extends Component {
    render() {
        let analytics = this.props.analytics;
        if (analytics.status.type !== FetchStatus.SUCCESS) return null;
        let activeUser = analytics.activeUser;

        return (
            <div className="stats-panel">
                <div className="stats">
                    <StatItem
                        label="Unique Viewers"
                        value={analytics.aggregates.uniqueviewers}
                        format="number"
                    />
                    <StatItem
                        label="Average View Time"
                        value={calcAverageViewTime(analytics.viewTimes)}
                        format="duration"
                    />
                    <StatItem
                        label="Total View Time"
                        value={calcSumViewTime(analytics.viewTimes)}
                        format="duration"
                    />
                    <StatItem
                        label="Total Downloads"
                        value={analytics.aggregates.totaldownloads}
                        format="number"
                    />
                    <CompletionRateStat
                        label="Completion Rate"
                        value={calcCompletionRate(analytics.completion)}
                    />
                    <StatItem
                        label="Last Viewed"
                        value={getLastViewedDate(analytics.views)}
                    />
                </div>
            </div>
        );
    }
}

let PresentationStats = AnalyticsController.withState(PresentationStatsComponent);
export { PresentationStats };

export class LinkStatsComponent extends Component {
    render() {
        let analytics = this.props.analytics;
        if (analytics.status.type !== FetchStatus.SUCCESS) return null;
        let selectedLink = analytics.selectedLink;
        return (
            <div className="stats-panel">
                <div className="stats">
                    <StatItem
                        label="Total Views"
                        value={selectedLink.totalViews}
                        format="number"
                    />
                    <StatItem
                        label="Average View Time"
                        value={calcAverageViewTime(selectedLink.viewTimes)}
                        format="duration"
                    />
                    <StatItem
                        label="Total View Time"
                        value={calcSumViewTime(selectedLink.viewTimes)}
                        format="duration"
                    />
                    <CompletionRateStat
                        label="Completion Rate"
                        value={calcLinkCompletionRate(selectedLink)}
                    />
                    {/*<StatItem label="Last Viewed" value={getLastViewedDate(analytics.views)}/>*/}
                </div>
            </div>
        );
    }
}

let LinkStats = AnalyticsController.withState(LinkStatsComponent);
export { LinkStats };

const calcSumViewTime = times => {
    if (times.length) {
        return times
            .map(item => Math.round(item.totaltime / 1000))
            .reduce((acc, item) => acc + item, 0);
    } else {
        return 0;
    }
};

const calcAverageViewTime = times => {
    return Math.round(
        times.length
            ? times
                .map(item => Math.round(item.totaltime / 1000))
                .reduce((acc, item) => acc + item, 0) / times.length
            : 0
    );
};

const calcCompletionRate = completion => {
    return (
        (completion.length === 2
            ? completion[0].count / completion[1].count
            : 0
        ).toFixed(2) * 100
    );
};

const calcLinkCompletionRate = selectedLink => {
    return (
        (selectedLink.totalViews > 0
            ? selectedLink.completed / selectedLink.totalViews
            : 0
        ).toFixed(2) * 100
    );
};

const getLastViewedDate = views => {
    if (
        views &&
        views.status.type == FetchStatus.SUCCESS &&
        views.data.length
    ) {
        return moment(views.data[0].time)
            .format("MMM Do");
    } else {
        return "--";
    }
};

