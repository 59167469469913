import React, { Component } from "react";
import moment from "moment";

import { _ } from "js/vendor";
import ReactChart from "js/react/components/ReactChart";
import PerDayCount from "./PerDayCount";
import { FetchStatus } from "js/react/constants";
import AnalyticsController from "js/react/views/Analytics/AnalyticsController";

class ViewsPerDay extends Component {
    render() {
        const analytics = this.props.analytics;
        const analyticsStatus = analytics.status;

        let content = null;
        if (analyticsStatus.type === FetchStatus.SUCCESS) {
            const {
                viewsPerDay,
                downloadsPerDay,
            } = analytics;

            // Start day will be the minimum of today - 7 days and the first date of the stats
            const startDate = moment(
                Math.min(
                    // NOTE: day here is a YYYY-MM-DD string generated with respect to the user tz
                    _.min([
                        ...viewsPerDay.map(({ day }) => moment(day).valueOf()),
                        ...downloadsPerDay.map(({ day }) => moment(day).valueOf()),
                        moment().valueOf(),
                    ]),
                    moment().subtract(7, "d").valueOf()
                )
            ).startOf("day");

            // Will display the stats until today
            const endDate = moment().startOf("day");

            const getSeriesData = (data, key) => {
                // Filling daily values
                const seriesData = [];
                let currentDay = startDate.clone();
                while (currentDay.isSameOrBefore(endDate)) {
                    const dayData = data.find(({ day }) => moment(day).isSame(currentDay, "day"));
                    seriesData.push(parseInt(dayData ? dayData[key] : 0));
                    currentDay.add(1, "day");
                }
                return seriesData;
            };

            const series = [
                {
                    type: "column",
                    name: "Downloads",
                    data: getSeriesData(downloadsPerDay, "downloads"),
                    pointStart: Date.UTC(
                        startDate.year(),
                        startDate.month(),
                        startDate.date()
                    ),
                    pointInterval: 24 * 3600 * 1000 // 24 hours
                },
                {
                    type: "line",
                    name: "Views",
                    data: getSeriesData(viewsPerDay, "views"),
                    pointStart: Date.UTC(
                        startDate.year(),
                        startDate.month(),
                        startDate.date()
                    ),
                    pointInterval: 24 * 3600 * 1000 // 24 hours
                },
            ];

            content = (
                <>
                    <PerDayCount
                        label="Total views"
                        stat={analytics.aggregates.totalviews}
                    />
                    <div className="per-day-chart">
                        <ReactChart
                            status={analyticsStatus}
                            series={series}
                            height={174}
                            yAxisTitle="Views"
                            yAxis2Title="Downloads"
                            useThumbnailFormatter={false}
                            chartConfig={{
                                plotOptions: {
                                    line: {
                                        color: "white",
                                    }
                                },
                                yAxis: [
                                    {
                                        min: 0,
                                        softMax: 4,
                                        showFirstLabel: false,
                                        tickInterval: 2
                                    }
                                ],
                                xAxis: {
                                    type: "datetime",
                                    tickPixelInterval: 100,
                                    gridLineWidth: 1,
                                    labels: {
                                        y: 30,
                                        style: {
                                            color: "#999"
                                        },
                                        formatter: function() {
                                            return moment
                                                .utc(this.value)
                                                .format("MMM Do");
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                </>
            );
        }

        return (
            <div className="per-day-panel">
                {content}
            </div>
        );
    }
}

export default AnalyticsController.withState(ViewsPerDay);
