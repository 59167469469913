import React, { Component } from "react";
import styled from "styled-components";

import Spinner from "js/react/components/Spinner";

const SpinnerContainer = styled.div.attrs(({ width, height }) => ({
    style: {
        width: `${width}px`,
        height: `${height}px`
    }
}))``;

/**
 * This component is intended to handle rendering JSX after an async event is finished.
 * @param load Required prop to handle some async activity before renderChildren is called.
 * @param onLoaded Optional callback, fired when *load() has resolved* and the *component has refreshed*.
 * @param renderChildren Renders JSX after async load function is finished.
 */
export default class LoadableAsync extends Component {
    constructor(props) {
        if (!props.load) {
            throw Error("load prop must be passed for LoadableAsync component");
        }

        super(props);

        this.state = {
            isLoading: true
        };
    }

    async componentDidMount() {
        const { load, onLoaded } = this.props;

        await load();
        this.setState({ isLoading: false }, () => {
            if (onLoaded) {
                onLoaded();
            }
        });
    }

    render() {
        const {
            renderChildren,
            // Default size mimics context menu size to minimize jumping/resizing after loading
            preloaderSize = { width: 190, height: 200 }
        } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            return (<SpinnerContainer width={preloaderSize.width} height={preloaderSize.height}>
                <Spinner />
            </SpinnerContainer>);
        }

        return renderChildren();
    }
}
