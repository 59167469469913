import { Notification, PusherEventType } from "common/constants";
const { NotificationState, EventType, EventAction } = Notification;
import Api from "js/core/api";
import moment from "moment";
import db from "js/db";
import getLogger, { LogGroup } from "js/core/logger";
import pusher from "js/core/services/pusher";

const logger = getLogger(LogGroup.NOTIFICATION);

// Actions upon receiving a Firebase event
const ADD = "add";
const UPDATE = "update";

function sleep(durationMs) {
    return new Promise(resolve => setTimeout(resolve, durationMs));
}

class NotificationsService {
    constructor(userUid) {
        this._instantiatedAt = moment().valueOf();

        this._userUid = userUid;

        this._read = [];
        this._unread = [];

        this._enrichBatchPromise = null;
        this._enrichQueue = [];

        this._pusherChannel = null;

        this._readRef = db("notifications").child(`${userUid}/read`);
        this._unreadRef = db("notifications").child(`${userUid}/unread`);

        this._onReadChangedCallbacks = [];
        this._onUnreadChangedCallbacks = [];
        this._onNewUnreadCallbacks = [];

        this._readRef.on("child_added", snapshot => {
            this._addOrUpdateNotification(ADD, NotificationState.READ, { ...snapshot.val(), id: snapshot.key })
                .catch(err => logger.error(err, "[NotificationsService] _addOrUpdateNotification() failed", { id: snapshot.key, state: NotificationState.READ, event: "child_added" }));
        });
        this._readRef.on("child_changed", snapshot => {
            this._addOrUpdateNotification(UPDATE, NotificationState.READ, { ...snapshot.val(), id: snapshot.key })
                .catch(err => logger.error(err, "[NotificationsService] _addOrUpdateNotification() failed", { id: snapshot.key, state: NotificationState.READ, event: "child_changed" }));
        });
        this._readRef.on("child_removed", snapshot => {
            this._removeNotification(NotificationState.READ, snapshot.key);
        });

        this._unreadRef.on("child_added", snapshot => {
            this._addOrUpdateNotification(ADD, NotificationState.UNREAD, { ...snapshot.val(), id: snapshot.key })
                .catch(err => logger.error(err, "[NotificationsService] _addOrUpdateNotification() failed", { id: snapshot.key, state: NotificationState.UNREAD, event: "child_added" }));
        });
        this._unreadRef.on("child_changed", snapshot => {
            this._addOrUpdateNotification(UPDATE, NotificationState.UNREAD, { ...snapshot.val(), id: snapshot.key })
                .catch(err => logger.error(err, "[NotificationsService] _addOrUpdateNotification() failed", { id: snapshot.key, state: NotificationState.UNREAD, event: "child_changed" }));
        });
        this._unreadRef.on("child_removed", snapshot => {
            this._removeNotification(NotificationState.UNREAD, snapshot.key);
        });

        pusher.subscribe(`private-user-presentation-updates-${userUid}`)
            .then(channel => {
                this._pusherChannel = channel;
                this._pusherChannel.bind(PusherEventType.DATA_RECORD_UPDATED, this._onPusherEvent);
            });
    }

    dispose() {
        this._readRef.off();
        this._unreadRef.off();

        if (this._pusherChannel) {
            this._pusherChannel.unbind(PusherEventType.DATA_RECORD_UPDATED, this._onPusherEvent);
            if (!this._pusherChannel.isInUse) {
                pusher.unsubscribe(this._pusherChannel.name);
            }
            this._pusherChannel = null;
        }
    }

    /**
     * Enriches the supplied notification object using implicit batching for
     * requests that came within half a second
     */
    async _enrich(notificationState, notification) {
        // Enqueue the request
        this._enrichQueue.push({ notificationState, notificationId: notification.id });

        // If there were no requests during the last 500ms, create a new timer that waits
        // for 500ms and allows all subsequent requests that come within this period
        // to be batched
        if (!this._enrichBatchPromise) {
            this._enrichBatchPromise = new Promise(resolve => setTimeout(resolve, 500))
                .then(async () => {
                    this._enrichedNotifications = await Api.enrichNotifications.post({ notifications: this._enrichQueue });
                    this._enrichQueue = [];
                    this._enrichBatchPromise = null;
                });
        }

        // Waiting for the current batch
        await this._enrichBatchPromise;

        // Grab our notification
        const enrichmentData = this._enrichedNotifications.find(enrichedNotification => enrichedNotification.id === notification.id);
        return { ...notification, ...enrichmentData };
    }

    _onPusherEvent = ({ presentationId }) => {
        this._read.forEach(notification => {
            const notificationPresentationId = notification.userPresentationId || notification.event.presentationId;
            if (notificationPresentationId === presentationId) {
                this._addOrUpdateNotification(UPDATE, NotificationState.READ, notification);
            }
        });

        this._unread.forEach(notification => {
            const notificationPresentationId = notification.userPresentationId || notification.event.presentationId;
            if (notificationPresentationId === presentationId) {
                this._addOrUpdateNotification(UPDATE, NotificationState.UNREAD, notification);
            }
        });
    }

    _removeNotification(notificationState, notificationId) {
        if (notificationState === NotificationState.READ) {
            this._read = this._read.filter(notification => notification.id !== notificationId);
            this._callOnReadChangedCallbacks();
        } else if (notificationState === NotificationState.UNREAD) {
            this._unread = this._unread.filter(notification => notification.id !== notificationId);
            this._callOnUnreadChangedCallbacks();
        }
    }

    async _addOrUpdateNotification(action, notificationState, notification) {
        if (action === UPDATE) {
            this._removeNotification(notificationState, notification.id);
        }

        // Ignoring notifications that are more than 30 days old, they'll be removed by
        // notificationsProcessor when the user receives a new notification
        if (notification.createdAt < moment().subtract(30, "days").valueOf()) {
            return;
        }

        // Ignoring slack notifications since they have different handling
        if (notification.notificationType === "slack") {
            return;
        }

        const enrichedNotification = await this._enrich(notificationState, notification);
        // Ignoring notifications that don't have the event field, this may happen
        // if the notification got broken by multiple calls of notificationsProcessor
        // with the same parameters (before we added requests deduplication to it) or
        // was triggered by a user that doesn't exist anymore
        // Refer to server/api/enrichNotifications.js line 54
        if (!enrichedNotification.event) {
            return;
        }

        if (notificationState === NotificationState.READ) {
            this._read.push(enrichedNotification);
            this._read.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
            this._callOnReadChangedCallbacks();
        } else if (notificationState === NotificationState.UNREAD) {
            this._unread.push(enrichedNotification);
            this._unread.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
            this._callOnUnreadChangedCallbacks();

            if (action === ADD && enrichedNotification.createdAt > this._instantiatedAt) {
                this._callOnNewUnreadCallbacks(enrichedNotification);
            }
        }
    }

    _callOnReadChangedCallbacks() {
        this._callCallbacks(this._onReadChangedCallbacks);
    }

    _callOnUnreadChangedCallbacks() {
        this._callCallbacks(this._onUnreadChangedCallbacks);
    }

    _callOnNewUnreadCallbacks(notification) {
        this._callCallbacks(this._onNewUnreadCallbacks, notification);
    }

    _callCallbacks(callbacks, payload) {
        for (const callback of callbacks) {
            try {
                callback(payload);
            } catch (err) {
                logger.error(err, "[NotificationsService] _callCallbacks() callback failed");
            }
        }
    }

    get read() {
        return this._read;
    }

    get unread() {
        return this._unread;
    }

    /**
     * Subscribes for changes in read notifications, calls the supplied callback whenever read notifications are changed.
     * The callback is called without any arguments, to fetch the updated read notifications please use .read field.
     * Please supply a syncronous callback function.
     */
    onReadChanged(callback) {
        this._onReadChangedCallbacks.push(callback);
    }

    /**
     * Unsubscribes the supplied callback from changes in read notifications.
     */
    offReadChanged(callback) {
        this._onReadChangedCallbacks = this._onReadChangedCallbacks.filter(existingCallback => existingCallback !== callback);
    }

    /**
     * Subscribes for changes in unread notifications, calls the supplied callback whenever unread notifications are changed.
     * The callback is called without any arguments, to fetch the updated unread notifications please use .unread field.
     * Please supply a syncronous callback function.
     */
    onUnreadChanged(callback) {
        this._onUnreadChangedCallbacks.push(callback);
    }

    /**
     * Unsubscribes the supplied callback from changes in unread notifications.
     */
    offUnreadChanged(callback) {
        this._onUnreadChangedCallbacks = this._onUnreadChangedCallbacks.filter(existingCallback => existingCallback !== callback);
    }

    /**
     * Subscribes for new unread notifications.
     * The callback is called with a notification as an argument.
     * Please supply a syncronous callback function.
     */
    onNewUnread(callback) {
        this._onNewUnreadCallbacks.push(callback);
    }

    /**
     * Unsubscribes the supplied callback from new unread notifications.
     */
    offNewUnread(callback) {
        this._onNewUnreadCallbacks = this._onNewUnreadCallbacks.filter(existingCallback => existingCallback !== callback);
    }

    /**
     * Marks all unread notifications as read
     */
    async markAllAsRead() {
        // Not writing notifications from this._unread state directly into the Firebase in case we have an outdated data
        await Promise.all(this._unread.map(notification => this.markAsRead(notification.id)));
    }

    /**
     * Marks a notification with the supplied id as read (moves it into /userUid/read parent)
     */
    async markAsRead(notificationId) {
        const notification = await this._unreadRef.child(notificationId).once("value").then(snap => snap.val());
        if (!notification) {
            return;
        }
        await this._unreadRef.child(notificationId).set(null);
        await this._readRef.child(notificationId).set({ ...notification, readAt: moment().valueOf() });
    }

    /**
     * Looks up a notification about the supplied commentId and marks it as read (moves it into /userUid/read parent) if it's unread
     */
    async markAsReadByCommentId(commentId, attemptsLeft = 30) {
        // The corresponding notification could be read already if it was created by notificationsProcessor after the comment had been marked as read
        if (this._read.some(notification => notification.event.commentId === commentId)) {
            return;
        }

        const unreadNotificationFromState = this._unread.find(notification => notification.event.commentId === commentId);
        if (!unreadNotificationFromState) {
            // We can end up here if notifications are still loading and not all of them have been loaded yet,
            // so we have to wait until the corresponding notification is loaded and enriched.
            // * Not the best approach, but we can't know exactly when all notifications have been loaded and
            // looking up a notification by commentId from the backend is very expensive and would
            // require adding indexes into the Firebase *
            if (attemptsLeft === 0) {
                // This should not be treated as an error because it's possible the user has never received
                // a notification about the comment (i.e. when he joined the presentation after the comment was created)
                logger.info(`markAsReadByCommentId() could not find an unread notification about comment ${commentId}`, { commentId });
                return;
            }

            await sleep(2000);
            return await this.markAsReadByCommentId(commentId, attemptsLeft - 1);
        }

        const notification = await this._unreadRef.child(unreadNotificationFromState.id).once("value").then(snap => snap.val());
        await this._unreadRef.child(notification.id).set(null);
        await this._readRef.child(notification.id).set({ ...notification, readAt: moment().valueOf() });
    }

    static async getNotification(userUid, notificationId) {
        const [read, unread] = await Promise.all([
            db("notifications").child(`${userUid}/read/${notificationId}`).once("value").then(snap => snap.val()),
            db("notifications").child(`${userUid}/unread/${notificationId}`).once("value").then(snap => snap.val())
        ]);

        if (!read && !unread) {
            throw new Error("Notification not found");
        }

        const notification = read ?? unread;
        const notificationState = read ? "read" : "unread";
        const [enrichmentData] = await Api.enrichNotifications.post({ notifications: [{ notificationState, notificationId }] });

        return { ...notification, ...enrichmentData };
    }

    static async notifyOnComment(presentationId, slideId, commentId) {
        const event = {
            type: EventType.COMMENT,
            action: EventAction.COMMENT_ADDED,
            presentationId,
            slideId,
            commentId
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnSlideEdited(presentationId, slideId) {
        const event = {
            type: EventType.EDIT,
            action: EventAction.SLIDE_EDITED,
            presentationId,
            slideId
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnSlideAdded(presentationId, slideId) {
        const event = {
            type: EventType.EDIT,
            action: EventAction.SLIDE_ADDED,
            presentationId,
            slideId
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnSlideRemoved(presentationId, slideId) {
        const event = {
            type: EventType.EDIT,
            action: EventAction.SLIDE_REMOVED,
            presentationId,
            slideId
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnSlideMoved(presentationId, slideId) {
        const event = {
            type: EventType.EDIT,
            action: EventAction.SLIDE_MOVED,
            presentationId,
            slideId
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnSlideStatusChanged(presentationId, slideId, slideStatus) {
        const event = {
            type: EventType.EDIT,
            action: EventAction.SLIDE_STATUS_CHANGED,
            presentationId,
            slideId,
            slideStatus
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnPresentationRenamed(presentationId) {
        const event = {
            type: EventType.EDIT,
            action: EventAction.PRESENTATION_RENAMED,
            presentationId
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnPresentationThemeChanged(presentationId) {
        const event = {
            type: EventType.EDIT,
            action: EventAction.PRESENTATION_THEME_CHANGED,
            presentationId
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnPresentationPrivacyChanged(presentationId, presentationPrivacy) {
        const event = {
            type: EventType.EDIT,
            action: EventAction.PRESENTATION_PRIVACY_CHANGED,
            presentationId,
            presentationPrivacy
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnPresentationLinkCreated(presentationLinkId) {
        const event = {
            type: EventType.PRESENTATION_LINK,
            action: EventAction.PRESENTATION_LINK_CREATED,
            presentationLinkId
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnUserAssignedToSlide(userUid, presentationId, slideId) {
        const event = {
            type: EventType.ASSIGN,
            action: EventAction.ASSIGNED_TO_SLIDE,
            userUid,
            presentationId,
            slideId
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnPlayerDownload(presentationLinkId, username, exportType) {
        const event = {
            type: EventType.PLAYER_DOWNLOAD,
            action: EventAction.PRESENTATION_EXPORTED,
            presentationLinkId,
            username,
            exportType,
        };

        await Api.notificationEvents.post(event);
    }

    static async notifyOnPermissionRequested(presentationOrLinkId, permissionType, customMessage) {
        const event = {
            type: EventType.PERMISSION,
            action: EventAction.PERMISSION_REQUESTED,
            presentationOrLinkId,
            permissionType,
            customMessage,
        };

        await Api.notificationEvents.post(event);
    }
}

export default NotificationsService;
