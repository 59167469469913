const theme = {
    isBuiltIn: true,
    id: "cheeky",
    name: "Cheeky",
    showLogo: false,
    logoScale: 1,
    logoPosition: "left",
    logoOffset: 0,
    showMessage: true,
    showPageNum: true,
    logo: null,
    logo_dark: null,
    showFooterByDefault: false,
    palette_name: "cheeky",
    defaultSlideColor: "theme",
    defaultBackgroundColor: "background_light",
    styleFonts: 1,
    styleFontWeight: "light",
    styleElementStyle: "filled",
    styleEffect: "none",
    styleDesign: 10,
    styleDecoration: "none_center",
    styleHeaderAlignment: "left",
    styleShape: "circle",
    styleColor: "slide",
    styleBackground: "none",
    styleTitleFont: "robotoslab",
    styleBodyFont: "roboto",
    styleWeight: "light",
    styleTitleSlide: "bar_left",
    fontScale: 1,
    iconStyle: "chunky",
    cjkFont: "jp",
    fontHeaderFontId: "trocchi",
    fontHeaderWeight: 400,
    fontHeaderLetterSpacing: 0,
    fontHeaderLineHeight: 1.6,
    fontHeaderScaling: 100,
    fontHeaderTextTransform: "auto",
    fontTitleFontId: "montserrat",
    fontTitleWeight: 300,
    fontTitleLetterSpacing: 0,
    fontTitleLineHeight: 2,
    fontTitleScaling: 80,
    fontTitleTextTransform: "auto",
    fontBodyFontId: "montserrat",
    fontBodyWeight: 400,
    fontBodyLetterSpacing: 0,
    fontBodyLineHeight: 2,
    fontBodyScaling: 80,
    fontBodyTextTransform: "auto",
};

module.exports = theme;
