const theme = {
    isBuiltIn: true,
    id: "classic",
    name: "Classic",
    showLogo: true,
    logoScale: 1,
    logoPosition: "left",
    logoOffset: 0,
    showMessage: true,
    showPageNum: true,
    logo: null,
    logo_dark: null,
    showFooterByDefault: null,
    palette_name: "classic",
    defaultSlideColor: "theme",
    defaultBackgroundColor: "background_light",
    styleFonts: 1,
    styleFontWeight: "heavy",
    styleElementStyle: "outlined",
    styleEffect: "none",
    styleDesign: 1,
    styleDecoration: "none_center",
    styleHeaderAlignment: "left",
    styleShape: "rounded_rect",
    styleColor: "slide",
    styleBackground: "none",
    styleTitleFont: "sourcesanspro",
    styleBodyFont: "sourcesanspro",
    styleWeight: "light",
    styleTitleSlide: "bar_left",
    fontScale: 1,
    iconStyle: "classic",
    cjkFont: "jp",
    fontHeaderFontId: "jost",
    fontHeaderWeight: 300,
    fontHeaderLetterSpacing: 0.03,
    fontHeaderLineHeight: 1.6,
    fontHeaderScaling: 80,
    fontHeaderTextTransform: "uppercase",
    fontTitleFontId: "jost",
    fontTitleWeight: 300,
    fontTitleLetterSpacing: 0.03,
    fontTitleLineHeight: 1.8,
    fontTitleScaling: 90,
    fontTitleTextTransform: "auto",
    fontBodyFontId: "jost",
    fontBodyWeight: 300,
    fontBodyLetterSpacing: 0.03,
    fontBodyLineHeight: 1.9,
    fontBodyScaling: 88,
    fontBodyTextTransform: "auto",
};

module.exports = theme;
