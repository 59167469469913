import * as XLSX from "xlsx";
import { _ } from "js/vendor";
import { parseCSV } from "./utilities";
import * as REGEX from "./regex";
import getRectangleCoordinates from "./getRectangleCoordinates";
import { formatter } from "./formatter";
import { FormatType } from "../../../common/constants";

/**
 * Data structure to be passed to "x-spreadsheet" UI lib per sheet
 */
export interface XSpreadsheetSheetData {
    name: string,
    rows: {
        [rowIndex: number]: {
            cells: {
                [cellIndex: number]: {
                    text: string,
                    merge?: string[],
                }
            }
        }
    },
    merges: string[],
}

/**
 * Data structure received from API for any given external spreadsheet
 */
export interface SpreadsheetData {
    id: string,
    title: string,
    url: string,
    metadata: object,
    sheets: [{
        id: number,
        index: number,
        title: string,
        sheetType: string,
        gridProperties: {
            rowCount: number,
            colCount: number,
        },
        data: {
            range: string, // A1 notation - eg: "Sheet1!A1:E1000"
            values: string[][],
        }
    }],
}

/**
 * Data structure sent to element UI for chart data imports
 */
export interface ImportedChartData {
    categories: string[],
    series: {
        name: string,
        data: (number | "sum")[]
    }[],
    yAxisFormat?: string,
    appendSeries?: boolean,
}

/**
 * Get selected cell range from selected sheet in the given spreadsheet data
 */
export function getSelectedSpreadsheetData(data: SpreadsheetData, selectedSheetIndex: number, selectedCellRange: string) {
    const wb = spreadsheetDataToXlsxWorkbook(data);
    return getSelectedWorkbookData(wb, selectedSheetIndex, selectedCellRange);
}

/**
 * Get selected cell range from selected sheet in the given workbook
 */
export function getSelectedWorkbookData(workbookData: XLSX.WorkBook, selectedSheetIndex: number, selectedCellRange: string) {
    const selectedWs = workbookData.Sheets[workbookData.SheetNames[selectedSheetIndex]];
    selectedWs["!ref"] = selectedCellRange;

    return { worksheetData: selectedWs, csvData: worksheetToCsvData(selectedWs) };
}

/**
 * Set selected index "!ref" field to full range of existing worksheet data
 */
export function setWorkbookRefStrings(workbookData: XLSX.WorkBook, refStrings: string[]) {
    for (const [i, wsName] of workbookData.SheetNames.entries()) {
        workbookData.Sheets[wsName]["!ref"] = refStrings[i];
    }

    return workbookData;
}

/**
 * Get "!ref" fields of each worksheet in the given workbook
 */
export function getWorkbookRefStrings(workbookData: XLSX.WorkBook): string[] {
    const refStrings = [];

    for (const wsName of workbookData.SheetNames) {
        refStrings.push(workbookData.Sheets[wsName]["!ref"]);
    }

    return refStrings;
}

/**
 * Translates DataSource spreadsheet data to XLSX workbook format
 */
export function spreadsheetDataToXlsxWorkbook(data: SpreadsheetData): XLSX.WorkBook {
    const wb = XLSX.utils.book_new();

    for (const sheet of data.sheets) {
        const ws = XLSX.utils.aoa_to_sheet(sheet.data.values || []);
        wb.SheetNames.push(sheet.title);
        wb.Sheets[sheet.title] = ws;
    }

    return wb;
}

/**
 * Translates DataSource spreadsheet data to format expected by "x-spreadsheet" UI grid library
 */
export function spreadsheetDataToXSpreadsheet(data: SpreadsheetData): XSpreadsheetSheetData[] {
    const res: XSpreadsheetSheetData[] = [];

    for (const sheet of data.sheets) {
        const xSheetData: XSpreadsheetSheetData = {
            name: sheet.title,
            merges: [],
            rows: [],
        };

        for (let i = 0; i < (sheet.data.values || []).length; i++) {
            const rowValues = sheet.data.values[i];
            const rowCells = {} as XSpreadsheetSheetData["rows"][0]["cells"];

            for (let j = 0; j < rowValues.length; j++) {
                const cellValue = rowValues[j];
                if (cellValue) {
                    rowCells[j] = { text: cellValue };
                }
            }

            xSheetData.rows[i] = { cells: rowCells };
        }

        res.push(xSheetData);
    }

    return res;
}

/**
 * Returns x-spreadsheet data selected based on the indices
 */
export function selectedRangeXSpreadsheet(
    data: XSpreadsheetSheetData[],
    range: { sri: number, sci: number, eri: number, eci: number },
    sheetIndex: number = 0
): XSpreadsheetSheetData[] {
    const sheetData = data[sheetIndex];
    const selectedSheetData = { ...sheetData, rows: [] } as XSpreadsheetSheetData;

    for (let i = 0; i < Object.keys(sheetData.rows).length; i++) {
        const { cells } = sheetData.rows[i];
        if (i < range.sri || i > range.eri) continue;

        const cellsNew = {} as XSpreadsheetSheetData["rows"][0]["cells"];
        for (let j = 0; j < Object.keys(cells).length; j++) {
            if (j < range.sci || j > range.eci) continue;

            cellsNew[j - range.sci] = cells[j];
        }

        selectedSheetData.rows[i - range.sri] = { cells: cellsNew };
    }

    const selectedData = [...data];
    selectedData[sheetIndex] = selectedSheetData;

    return selectedData;
}

/**
 * Translates XSLX worksheet data to CSV format
 */
export function worksheetToCsvData(ws: XLSX.WorkSheet): string[][] {
    const csvString = XLSX.utils.sheet_to_csv(ws);
    const csvData = parseCSV(csvString, ",") as string[][];
    return csvData;
}

/**
 * Detect & return part of CSV data that is suitable for charts
 */
export function getValidChartDataFromCsv(data: string[][], isPieChart?: boolean, isWaterfall?: boolean): ImportedChartData {
    const isValidCell = cell => _.isNumber(cell) || _.isEmpty(cell) || REGEX.numberRegex.test(cell) || (isWaterfall && cell === "sum");
    const isValidChartData = chartData => chartData && chartData.series?.length && chartData.series[0]?.data.length && (
        chartData.series[0].data.every(cell => (_.isNumber(cell) && !_.isNaN(cell)) || (isWaterfall && cell === "sum"))
    );

    function _getValidChartDataFromCsv(data: string[][]) {
        const coordinates = getRectangleCoordinates(data.map(row => row.map(cell => isValidCell(cell) ? 1 : 0))) || [];

        let chartData: ImportedChartData = { series: [], categories: [] };

        for (const [startRow, startCol, endRow, endCol] of coordinates.filter(coord => _.uniq(coord).length > 1)) {
            for (let row = startRow; row <= endRow; row++) {
                const seriesData = [];
                for (let col = startCol; col <= endCol; col++) {
                    const cell = data[row][col];
                    const num = isWaterfall && cell === "sum" ? cell : parseFloat(_.isString(cell) ? cell.replace(/,/g, "") : cell);
                    seriesData.push(_.isNaN(num) ? 0 : num);
                }

                chartData.series.push({
                    name: data[row][startCol - 1] ?? `Series ${row + 1}`,
                    data: seriesData
                });
            }

            if (data[startRow - 1]?.length) {
                chartData.categories = data[startRow - 1].slice(startCol, endCol + 1);
            }

            if (!isValidChartData(chartData)) {
                chartData = { series: [], categories: [] };
                continue;
            }
            break;
        }

        return chartData;
    }

    let chartData = _getValidChartDataFromCsv(data);
    if (!isValidChartData(chartData) || chartData.series.length > chartData.series[0].data.length) {
        chartData = _getValidChartDataFromCsv(_.zip(...data));
    }

    if (!isValidChartData(chartData)) { // @ts-ignore
        return { error: "No valid chart data found" };
    }
    return chartData;
}

/**
 * Translates already validated CSV data to ImportedChartData format
 */
export function csvDataToChartData(
    data: string[][], useFirstRowAsCategory: boolean, useFirstColAsLegend: boolean, isPieChart: boolean, isWaterfall: boolean
): ImportedChartData {
    const chartData: ImportedChartData = { series: [], categories: [] };

    for (let y = 0; y < data.length; y++) {
        // when useFirstRowAsCategory and cell belongs to first row (y=0)
        if (useFirstRowAsCategory && y === 0) {
            chartData.categories = data[y].slice(useFirstColAsLegend ? 1 : 0);
            continue;
        }

        let seriesName = `Series ${y + 1}`;
        let seriesData = [] as (number | "sum")[];

        for (let x = 0; x < data?.[y]?.length; x++) {
            // default to 0 if cell is empty
            const cell = String(data[y][x]).trim() || "0";

            // when useFirstColAsLegend and cell belongs to first column (x=0)
            if (useFirstColAsLegend && x === 0) {
                seriesName = cell;
                continue;
            }

            if (x >= (useFirstColAsLegend ? 1 : 0)) {
                const format = formatter.detectFormatFromString(cell).format;
                if (!(isWaterfall && cell.toLowerCase() === "sum")) {
                    chartData.yAxisFormat = format;
                }

                let num: number | "sum";
                if (isWaterfall && cell.toLowerCase() === "sum") {
                    num = "sum";
                } else if (format === FormatType.NUMBER || format === FormatType.PERCENT) {
                    num = parseFloat(cell.replace(/,/g, ""));
                } else if (format === FormatType.CURRENCY) {
                    num = Number(cell.replace(/[^0-9.-]+/g, ""));
                }
                seriesData.push(num);
            }
        }

        chartData.series.push({ name: seriesName, data: seriesData });
    }

    // get data values to 0-1 range if yAxisFormat is percentage
    if (chartData.yAxisFormat === FormatType.PERCENT) {
        chartData.series.forEach(series => {
            series.data = series.data.map(value => value === "sum" ? value : value / 100);
        });
    }

    return chartData;
}

/**
 * Validates if given CSV data is valid to be used as Chart data and returns error message if invalid
 */
export function isCsvDataValidForCharts(data: string[][], useFirstRowAsCategory: boolean, useFirstColAsLegend: boolean): string {
    for (let y = 0; y < data.length; y++) {
        for (let x = 0; x < data[y].length; x++) {
            if ((useFirstRowAsCategory && y === 0) || (useFirstColAsLegend && x === 0)) continue;

            const cell = String(data[y][x]).trim() || "0";
            if (!REGEX.numberRegex.test(cell)) {
                return "Can't read non-numeric values";
            }
        }
    }

    if (data.length > 10 + (useFirstColAsLegend ? 1 : 0)) {
        return "Can't add more than 10 chart legends";
    }
    if (data[0].length > 250 + (useFirstRowAsCategory ? 1 : 0)) {
        return "Can't add more than 250 chart data points";
    }
}

/**
 * Validates if given CSV data is valid to be used as PieChart data and returns error message if invalid
 */
export function isCsvDataValidForPieCharts(data: string[][], useFirstRowAsCategory: boolean): string {
    if (data.length !== 2 - (!useFirstRowAsCategory ? 1 : 0)) {
        return "Invalid number of rows";
    }

    for (let y = 0; y < data.length; y++) {
        for (let x = 0; x < data[y].length; x++) {
            const cell = String(data[y][x]).trim() || "0";
            if (y === (useFirstRowAsCategory ? 1 : 0) && !REGEX.numberRegex.test(cell)) {
                return "Can't read non-numeric values";
            }
        }
    }
}

/**
 * Validates if given CSV data is valid to be used as Waterfall Chart data and returns error message if invalid
 */
export function isCsvDataValidForWaterfallCharts(data: string[][], useFirstRowAsCategory: boolean): string {
    if (data.length !== 2 - (!useFirstRowAsCategory ? 1 : 0)) {
        return "Invalid number of rows";
    }

    for (let y = 0; y < data.length; y++) {
        for (let x = 0; x < data[y].length; x++) {
            const cell = data[y][x].trim() || "0";
            if (y === (useFirstRowAsCategory ? 1 : 0) && !(REGEX.numberRegex.test(cell) || cell.toLowerCase() === "sum")) {
                return "Can't read non-numeric values";
            }
        }
    }
}

/**
 * Returns cell range including first block of non-empty cells until we hit an empty row or column
 */
export function getDefaultCellRange(ws: XLSX.WorkSheet): XLSX.Range {
    const data = worksheetToCsvData(ws);
    if (!data.length || !data[0]?.length) return;

    // get coordinates of all rectangles of non-empty cells (allows some empty cells) in data array
    const dataClone = data.map(row => row.slice());
    const coordinates = getRectangleCoordinates(data.map((row, i) => row.map((cell, j) => {
        if (!_.isEmpty(cell)) return 1;

        const aboveCell = data[i - 1]?.[j] || dataClone[i - 1]?.[j] || "";
        const rightCell = data[i]?.[j + 1] || dataClone[i]?.[j + 1] || "";
        const belowCell = data[i + 1]?.[j] || dataClone[i + 1]?.[j] || "";
        const leftCell = data[i]?.[j - 1] || dataClone[i]?.[j - 1] || "";

        // if more than 2 neighbours are non-empty, then this cell is part of a rectangle
        if ([aboveCell, rightCell, belowCell, leftCell].filter(cell => !_.isEmpty(cell)).length >= 2) {
            dataClone[i][j] = "filled";
            return 1;
        }

        return 0;
    })));

    const range: XLSX.Range = XLSX.utils.decode_range(ws["!ref"]);

    if (coordinates && coordinates.length) {
        // worksheet already trims empty rows and columns from outsides, so "data" array doesn't include
        // the initial empty rows & colums, so we need to add them back to the range
        const rowGap = range.s.r;
        const colGap = range.s.c;

        const firstFilledBlock = coordinates[0];
        range.s.r = firstFilledBlock[0] + rowGap;
        range.s.c = firstFilledBlock[1] + colGap;
        range.e.r = firstFilledBlock[2] + rowGap;
        range.e.c = firstFilledBlock[3] + colGap;
    }

    return range;
}

/**
 * Trim empty rows or columns if they are all outside the data, still allows spaces inside data array
 */
export function trimCsvData(data: string[][]) {
    if (!data.length || !data[0]?.length) return;

    let x0 = 0; // first non-empty row
    for (; x0 < data.length; x0++) {
        const isRowEmpty = data[x0].filter(c => !_.isEmpty(c.toString())).length === 0;
        if (!isRowEmpty) break;
    }

    let x1 = data.length - 1; // last non-empty row
    for (; x1 >= 0; x1--) {
        const isRowEmpty = data[x1].filter(c => !_.isEmpty(c.toString())).length === 0;
        if (!isRowEmpty) break;
    }

    if (x0 > x1) return [];

    let minYs = [], maxYs = [];
    for (let x = x0; x <= x1; x++) {
        const row = data[x];

        let minY = 0;
        for (; minY < row.length; minY++) {
            if (!_.isEmpty(row[minY].toString())) break;
        }

        let maxY = row.length - 1;
        for (; maxY >= 0; maxY--) {
            if (!_.isEmpty(row[maxY].toString())) break;
        }

        minYs.push(minY); maxYs.push(maxY);
    }

    let y0 = Math.min(...minYs);
    let y1 = Math.max(...maxYs);

    if (y0 > y1) return [];

    const result = [];
    for (let x = x0; x <= x1; x++) {
        result.push(data[x].slice(y0, y1 + 1));
    }

    return result;
}

/**
 * Ref: https://stackoverflow.com/a/53678158
 * Takes number and returns sheet col, eg: 1 -> A, 27 -> AA
 */
export function columnIndexToLetter(index: number): string {
    const a = Math.floor(index / 26);
    return a >= 0 ? columnIndexToLetter(a - 1) + String.fromCharCode(65 + (index % 26)) : "";
}

/**
 * Takes cellRange obj with start row/col & end row/col indices
 * and returns A1 notation cellRange string
 */
export function cellRangeObjToA1Notation(range: { sri: number, sci: number, eri: number, eci: number }): string {
    const a1Notation = `${columnIndexToLetter(range.sci)}${range.sri + 1}:${columnIndexToLetter(range.eci)}${range.eri + 1}`;
    return a1Notation;
}
