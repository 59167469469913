import Api from "js/core/api";
import Quotas from "js/core/models/quotas";
import { ds } from "js/core/models/dataService";
import { trackActivity } from "js/core/utilities/utilities";
import { CustomerType } from "common/constants";
import stripeProducts from "common/stripeProducts";
import { presentations as presentationsApi } from "apis/callables";

export default class BillingDataService {
    static async fetchSubscription(orgId) {
        const data = await Api.subscriptions.get({
            customer_type: !orgId ? CustomerType.INDIVIDUAL : CustomerType.TEAM,
            organization_id: orgId,
        });
        return data[0];
    }

    static async fetchCustomer(orgId) {
        const customer = await Api.subscriptions.put({
            customerType: !orgId ? CustomerType.INDIVIDUAL : CustomerType.TEAM,
            orgId,
            type: "get_stripe_customer"
        });

        return customer.body;
    }

    static async fetchPaymentMethod(orgId) {
        let paymentMethodsResponse = await Api.paymentMethods.get({
            customer_type: orgId === undefined ? CustomerType.INDIVIDUAL : CustomerType.TEAM,
            organization_id: orgId
        });
        if (paymentMethodsResponse.body) {
            return paymentMethodsResponse.body[0];
        }
        return null;
    }

    static async fetchQuotas(orgId) {
        return Quotas.get(orgId || "personal");
    }

    static async removeTeamSeats(orgId, targetQuantity) {
        await Api.subscriptions.put({
            type: "decrease_quantity",
            quantity: targetQuantity,
            orgId,
            customerType: CustomerType.TEAM
        });
    }

    static async cancelSubscription(subscriptionId, orgId, { immediate } = {}) {
        await Api.subscriptions.delete({
            subscriptionId,
            customerType: orgId === undefined ? CustomerType.INDIVIDUAL : CustomerType.TEAM,
            orgId,
            immediate
        });

        const sub = await this.fetchSubscription(orgId);
        if (sub) {
            const { interval, product, amount, current_period_end } = sub;
            // Expensive, but accurate
            const { slideCount } = await presentationsApi.getSlideCount({ workspaceId: orgId ?? "personal", filter: "all" });
            const eventProps = {
                billing_term: interval,
                current_status: product,
                effective_date: current_period_end,
                amount: amount,
                plan: product,
                current_slide_count: slideCount
            };
            trackActivity("Upgrade", "BillingIntentCancel", null, null, eventProps, { audit: true });
        }
    }

    /**
     * Map (plan, billingTerm) pair to a Stripe 'Price' ID
     * @returns { month: PriceId, year: PriceId }
     */
    static getDefaultPriceIds(productName) {
        const entry = stripeProducts[productName];
        if (entry) {
            return {
                month: entry.plans.find(price => price.isDefault && price.interval === "month").planId,
                year: entry.plans.find(price => price.isDefault && price.interval === "year").planId
            };
        } else {
            throw new Error("Unknown product");
        }
    }

    static applyCoupon(price, promo) {
        if (promo) {
            if (promo.amountOff) {
                const discountPrice = price - (promo.amountOff / 100); // cents -> dollars
                return Math.max(discountPrice, 0);
            } else {
                return price - (price / 100 * promo.percentOff);
            }
        } else {
            return price;
        }
    }
}
