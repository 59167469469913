import { withStyles } from "@material-ui/core/styles";

import theme from "js/react/materialThemeOverrides";
import UploadImage from "js/react/components/UploadImage";

const { primary } = theme.palette;

const uploadLogoStyle = {
    logoParent: {
        "& img": {
            display: "none"
        }
    },
    paper: {
        backgroundColor: "#fff",
        borderRadius: 0
    },
    circleIconParent: {
        backgroundColor: primary[500],
        padding: "7%",
        borderRadius: "50%",
        cursor: "pointer",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#0e90c1"
        }
    },
    spinner: {
        // position: "absolute",
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%, -50%)"
    }
};

export default withStyles(uploadLogoStyle)(UploadImage);
