import { _ } from "js/vendor";
import { ASSET_MAX_SIZE, ASSET_MAX_UPLOAD_MEGAPIXELS, ImageErrorType, AssetType, ASSET_FILETYPE, ASSET_MIMETYPE_TO_FILETYPE } from "common/constants";
import { loadImage, loadFileAsDataUrl } from "js/core/utilities/promiseHelper";

export async function readImage(file, assetType) {
    if (file.size > ASSET_MAX_SIZE) {
        const error = new Error("Your file size should be under " + ASSET_MAX_SIZE / 1024 / 1024 + "MB. Please reduce your file size and try again!");
        error.type = ImageErrorType.SIZE;
        error.title = "Upload Error";
        throw error;
    }

    const dataUrl = await loadFileAsDataUrl(file);
    let image = await loadImage(dataUrl);

    if (assetType === AssetType.LOGO && file.type !== "image/svg+xml") {
        let updatedDataURL = trimWhiteEdges(image);
        image = await loadImage(updatedDataURL);
    }

    const imageMegapixels = (image.naturalHeight * image.naturalWidth) / (1024 * 1024);
    if (imageMegapixels > ASSET_MAX_UPLOAD_MEGAPIXELS) {
        const error = new Error(`Your file size should be under under 200 megapixels, yours is ${imageMegapixels}. Please reduce your file's aspect ratio and try again!`);
        error.type = ImageErrorType.SIZE;
        error.title = "Upload Error";
        throw error;
    }

    const imageProps = getImageProperties(image, file);

    return {
        //since logos will have an updated url due to trimWhiteEdges we need to create a new blob for the logo
        file: assetType === AssetType.LOGO ? (await convertDataURItoBlob(image.src)) : file,
        dataUrl: image.src,
        imageProps
    };
}

export async function convertDataURItoBlob(dataURI) {
    return await fetch(dataURI).then(resp => resp.blob());
}

export function getImageType(file) {
    const mimeType = file.type;
    let fileType = ASSET_MIMETYPE_TO_FILETYPE[mimeType];

    if (fileType) return fileType;

    const fileName = file.name.toLowerCase();
    if (
        _.endsWith(fileName, ".jpg") ||
        _.endsWith(fileName, ".jpeg")
    ) {
        fileType = ASSET_FILETYPE.JPG;
    } else if (_.endsWith(fileName, ".png")) {
        fileType = ASSET_FILETYPE.PNG;
    } else if (_.endsWith(fileName, ".svg")) {
        fileType = ASSET_FILETYPE.SVG;
    } else if (_.endsWith(fileName, ".gif")) {
        fileType = ASSET_FILETYPE.GIF;
    }
    return fileType;
}

export function getVideoType(file) {
    let fileType = null;
    let fileName = file.name.toLowerCase();
    if (_.endsWith(fileName, ".mp4")) {
        fileType = ASSET_FILETYPE.MP4;
    } else if (_.endsWith(fileName, ".mov")) {
        fileType = ASSET_FILETYPE.MOV;
    } else if (_.endsWith(fileName, ".wmv")) {
        fileType = ASSET_FILETYPE.WMV;
    } else if (_.endsWith(fileName, ".avi")) {
        fileType = ASSET_FILETYPE.AVI;
    } else if (_.endsWith(fileName, ".mkv")) {
        fileType = ASSET_FILETYPE.MKV;
    } else if (_.endsWith(fileName, ".webm")) {
        fileType = ASSET_FILETYPE.WEBM;
    }
    return fileType;
}

export function isValidImageType(file) {
    return (
        file.type?.includes("image/") && !!getImageType(file)
    );
}

export function isValidVideoType(file) {
    return (
        file.type?.includes("video/") && !!getVideoType(file)
    );
}

export function isValidMediaType(file) {
    let isValidImage = isValidImageType(file);
    let isValidVideo = isValidVideoType(file);
    let isValidMedia = (
        isValidImage ||
        isValidVideo
    );
    return {
        isValidMedia,
        isValidImage,
        isValidVideo,
    };
}

export function toDataURL(img, outputFormat = "image/jpeg") {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let dataURL;
    canvas.height = img.naturalHeight;
    canvas.width = img.naturalWidth;
    ctx.drawImage(img, 0, 0);
    dataURL = canvas.toDataURL(outputFormat);
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

export function getAverageImageColor(img) {
    const canvas = document.createElement("canvas");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(img, 0, 0);

    const imageData = [
        // To save time and resources will go through 6 lines across the quarters of the image
        ...ctx.getImageData(0, img.naturalHeight / 4, img.naturalWidth, 1).data,
        ...ctx.getImageData(0, img.naturalHeight / 2, img.naturalWidth, 1).data,
        ...ctx.getImageData(0, img.naturalHeight / 4 * 3, img.naturalWidth, 1).data,
        ...ctx.getImageData(img.naturalWidth / 4, 0, 1, img.naturalHeight).data,
        ...ctx.getImageData(img.naturalWidth / 2, 0, 1, img.naturalHeight).data,
        ...ctx.getImageData(img.naturalWidth / 4 * 3, 0, 1, img.naturalHeight).data,
    ];

    let r = 0, g = 0, b = 0;
    let hasAlpha = false;
    let countedPixels = 0;
    for (let idx = 0; idx < imageData.length; idx += 4) {
        const a = imageData[idx + 3];
        if (a < 255) {
            hasAlpha = true;
        }
        // Won't count this pixel if it's transparent
        if (a === 0) {
            continue;
        }

        r += imageData[idx];
        g += imageData[idx + 1];
        b += imageData[idx + 2];
        countedPixels++;
    }

    r /= countedPixels;
    g /= countedPixels;
    b /= countedPixels;

    return { r, g, b, hasAlpha };
}

export function getImageProperties(img, file) {
    let fileType;
    switch (file.type) {
        case "image/jpeg":
            fileType = ASSET_FILETYPE.JPG;
            break;
        case "image/png":
            fileType = ASSET_FILETYPE.PNG;
            break;
        case "image/svg":
        case "image/svg+xml":
            fileType = ASSET_FILETYPE.SVG;
            break;
        case "image/gif":
            fileType = ASSET_FILETYPE.GIF;
            break;
    }

    // don't bother checking for alpha and solid background for JPEGs. Yes, there are cases where a JPEG could have a solid background but i think the hit on hi-res JPEGs isn't worth it
    if (fileType == ASSET_FILETYPE.JPG) {
        return {
            width: img.naturalWidth,
            height: img.naturalHeight,
            fileType: fileType,
            hasAlpha: false,
            hasSolidBackground: false,
            size: file.size
        };
    }

    const canvas = document.createElement("canvas");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    const ctx = canvas.getContext("2d");

    // draw image
    ctx.drawImage(img, 0, 0);

    const updateHistogram = (hist, val, ii) => {
        hist[ii % 4] = hist[ii % 4] || {};
        hist[ii % 4][val] = hist[ii % 4][val] || 0;
        hist[ii % 4][val]++;
        return hist;
    };

    const histogram = [
        ctx.getImageData(0, 0, img.naturalWidth, 1).data,
        ctx.getImageData(0, img.naturalHeight - 1, img.naturalWidth, 1).data,
        ctx.getImageData(0, 0, 1, img.naturalHeight).data,
        ctx.getImageData(img.naturalWidth - 1, 0, 1, img.naturalHeight).data
    ].reduce(
        (hist, arr) => arr.reduce(updateHistogram, hist), {});

    function histSum(fn, hist) {
        return Object.keys(hist).map(val => fn(val) * hist[val]).reduce((l, r) => l + r, 0);
    }

    function histStats(hist) {
        const count = histSum(x => 1, hist);
        const sum = histSum(x => x, hist);
        const avg = sum / count;
        const stddev = Math.sqrt(histSum(x => Math.pow(x - avg, 2), hist) / count);
        return { count, avg, stddev };
    }

    const { stddev, avg } = histStats(histogram[3]);
    // Allow slight deviation from 255 as it sometimes happens
    // with PNG screenshots made on MacOS
    const hasAlpha = !(stddev < 1 && avg > 254);

    const imageBackgroundColor = {
        r: Math.floor(histStats(histogram[0]).avg),
        g: Math.floor(histStats(histogram[1]).avg),
        b: Math.floor(histStats(histogram[2]).avg)
    };

    const hasSolidBackground = [histogram[0], histogram[1], histogram[2]].every(
        hist => histStats(hist).stddev < 15);

    return {
        width: img.naturalWidth,
        height: img.naturalHeight,
        fileType: fileType,
        hasAlpha: hasAlpha,
        hasSolidBackground: hasSolidBackground,
        imageBackgroundColor: imageBackgroundColor,
        size: file.size
    };
}

export function trimWhiteEdges(imageObject) {
    let imgWidth = imageObject.width;
    let imgHeight = imageObject.height;
    let canvas = document.createElement("canvas");
    canvas.setAttribute("width", imgWidth);
    canvas.setAttribute("height", imgHeight);
    let context = canvas.getContext("2d");
    context.drawImage(imageObject, 0, 0);

    let imageData = context.getImageData(0, 0, imgWidth, imgHeight),
        data = imageData.data,
        getRBGA = function(x, y) {
            var offset = imgWidth * y + x;
            return {
                red: data[offset * 4],
                green: data[offset * 4 + 1],
                blue: data[offset * 4 + 2],
                alpha: data[offset * 4 + 3]
            };
        },
        isWhite = function(rgb) {
            if (rgb.alpha == 0) {
                return true;
            } else if (rgb.alpha == 255) {
                // many images contain noise, as the white is not a pure #fff white
                return (rgb.red > 240 && rgb.green > 240 && rgb.blue > 240);
            } else {
                return false;
            }
        },

        scanY = function(fromTop) {
            var offset = fromTop ? 1 : -1;

            // loop through each row
            for (var y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {
                // loop through each column
                for (var x = 0; x < imgWidth; x++) {
                    var rgba = getRBGA(x, y);
                    if (!isWhite(rgba)) {
                        if (fromTop) {
                            return y;
                        } else {
                            return Math.min(y + 1, imgHeight - 1);
                        }
                    }
                }
            }
            return null; // all image is white
        },
        scanX = function(fromLeft) {
            var offset = fromLeft ? 1 : -1;

            // loop through each column
            for (var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {
                // loop through each row
                for (var y = 0; y < imgHeight; y++) {
                    var rgba = getRBGA(x, y);
                    if (!isWhite(rgba)) {
                        if (fromLeft) {
                            return x;
                        } else {
                            return Math.min(x + 1, imgWidth - 1);
                        }
                    }
                }
            }
            return null; // all image is white
        };

    let cropTop = scanY(true) - 5,
        cropBottom = scanY(false) + 5,
        cropLeft = scanX(true) - 5,
        cropRight = scanX(false) + 5,
        cropWidth = cropRight - cropLeft,
        cropHeight = cropBottom - cropTop;

    canvas.setAttribute("width", cropWidth);
    canvas.setAttribute("height", cropHeight);
    // finally crop the guy
    canvas.getContext("2d").drawImage(imageObject,
        cropLeft, cropTop, cropWidth, cropHeight,
        0, 0, cropWidth, cropHeight);

    return canvas.toDataURL();
}

export function getImageBrightnessForRegion(img, contentBounds, bounds) {
    const canvas = document.createElement("canvas");
    canvas.width = contentBounds.width;
    canvas.height = contentBounds.height;
    const ctx = canvas.getContext("2d", { willReadFrequently: true });

    if (typeof img == "string") {
        const image = new Image();
        image.src = img;
        img = image;
    }

    ctx.drawImage(img, 0, 0, contentBounds.width, contentBounds.height);

    // specify the region you want to measure
    const x = bounds.left; // left edge of region
    const y = bounds.top; // top edge of region
    const w = bounds.width; // width of region
    const h = bounds.height; // height of region

    // loop through the pixels within the specified region and sum their brightness
    let brightnessSum = 0;
    const grayscaleValues = [];

    const pixelIter = 2;

    for (let i = x; i < x + w; i += pixelIter) {
        for (let j = y; j < y + h; j += pixelIter) {
            const pixelData = ctx.getImageData(i, j, 1, 1).data;
            const brightness = (pixelData[0] + pixelData[1] + pixelData[2]) / 3;
            brightnessSum += brightness;
            grayscaleValues.push((pixelData[0] + pixelData[1] + pixelData[2]) / 3);
        }
    }

    // calculate the average brightness of pixels in the region
    const pixelCount = grayscaleValues.length;
    const avgBrightness = brightnessSum / pixelCount;

    // Calculate the mean of the values
    const mean = grayscaleValues.reduce((acc, val) => acc + val, 0) / grayscaleValues.length;

    // Calculate the sum of the squared differences from the mean
    const sumOfSquaredDifferences = grayscaleValues.reduce((acc, val) => {
        const difference = val - mean;
        return acc + (difference * difference);
    }, 0);

    // Calculate the standard deviation using the formula
    const standardDeviation = Math.sqrt(sumOfSquaredDifferences / (grayscaleValues.length - 1));

    return { brightness: avgBrightness, noise: standardDeviation };
}
