import firebase from "firebase/compat/app";

import DbRouter from "common/dbRouter";
const baiFirebase = window.baiFirebase || {};

const dbRouter = new DbRouter(firebase, baiFirebase.routes, baiFirebase.config);

function db(ref) {
    return dbRouter.db(ref);
}

db.isDefaultDb = function(path) {
    return dbRouter.isDefaultDb(path);
};

db.getDbInstance = function(path) {
    return dbRouter.getDbInstance(path);
};

export default db;
