import React, { Component } from "react";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import { RowGrid } from "js/react/components/LayoutGrid";
import {
    BeautifulDialog,
    DialogContent
} from "js/react/components/Dialogs/BaseDialog";
import LinkExpiration from "js/react/components/LinkExpiration";
import { Gap20 } from "js/react/components/Gap";
import { ToggleAllowCommenting } from "../components/ToggleAllowCommenting";

export default class EditInviteDialog extends Component {
    state = {}

    componentDidMount() {
        const linkExpiresAt = this.props.link?.get("expiresAt");
        this.setState({ linkExpiresAt });
    }

    handleAccept = link => {
        const { linkExpiresAt } = this.state;
        this.props.closeDialog();
        this.props.callback(linkExpiresAt);
    };

    toggleAllowCommenting = event => {
        const { link } = this.props;
        if (link) {
            const allowCommenting = !link.get("allowCommenting");
            link.update({ allowCommenting });
            this.update();
        }
    }

    handleUpdateLinkExpiration = ({ linkShouldExpire, linkHasExpired, linkExpiresAt }) => {
        this.setState({
            linkShouldExpire,
            linkHasExpired,
            linkExpiresAt
        });
    }

    update = () => {
        this.forceUpdate();
        this.props.callback();
    }

    render() {
        const { presentation, link } = this.props;
        const { linkExpiresAt, linkHasExpired } = this.state;

        const disabled = linkHasExpired || link.isLinkExpired();
        const allowCommenting = link?.get("allowCommenting");

        // find the person this is adjusting
        const contact = link.get("email");
        const contactName =
            typeof contact == "object"
                ? contact.name || contact.email
                : contact;

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>Edit Invite</DialogTitle>
                <DialogContent>
                    <RowGrid gap={0}>
                        <div>
                            This will change the invitation for <strong>{contactName}</strong>
                        </div>
                        <Gap20 />

                        <ToggleAllowCommenting
                            allowCommenting={allowCommenting}
                            onToggle={this.toggleAllowCommenting}
                        />

                        <LinkExpiration
                            expiresAt={linkExpiresAt}
                            presentation={presentation}
                            link={link}
                            onUpdate={this.handleUpdateLinkExpiration}
                            stacked
                        />
                    </RowGrid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Cancel</Button>
                    <Button
                        onClick={() => this.handleAccept(link)}
                        disabled={disabled}
                        color="primary"
                    >
                        Update Invite
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
